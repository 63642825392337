import React from "react";
import { ClickAwayListener } from "@mui/base";
import { useActions } from "../../../hooks/useActions";
import styles from "./SettingsModal.module.css";
import usersSettings from "../../../assets/svg/usersSettings.svg";
import billingSettings from "../../../assets/svg/billingSettings.svg";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../../routes";
import { useTranslation } from "react-i18next";
import useStoreSnippets from "../../../hooks/useStoreSnippets";
import LoginToViewMessage from "../../LoginToViewMessage";

const SettingsModal = () => {
  const { setSettingModal, setProfileModal } = useActions();
  const navigate = useNavigate();

  const handleUsersSelect = () => {
    setSettingModal(false);
    setProfileModal(false);
    navigate(RouteNames.ACCOUNT_USERS);
  };

  const handlePaymentSelect = () => {
    setSettingModal(false);
    setProfileModal(false);
    navigate(RouteNames.PAYMENTS);
  };

  const { t: tSettingsModal } = useTranslation("translation", {
    keyPrefix: "settingsModal",
  });
  const { isGuestAccount } = useStoreSnippets();

  return (
    <ClickAwayListener onClickAway={() => setSettingModal(false)}>
      {
        <div className={styles.modalBody}>
          {isGuestAccount ? (
            <LoginToViewMessage />
          ) : (
            <>
              <h3 className={styles.title}>{tSettingsModal("title")}</h3>
              <div className={styles.wrapper}>
                <h4 className={styles.wrapperTitle}>
                  {tSettingsModal("adminCards.title")}
                </h4>
                <div className={styles.card} onClick={handleUsersSelect}>
                  <img width="40px" height="40px" src={usersSettings} alt="" />
                  <div className={styles.cardText}>
                    <h5> {tSettingsModal("adminCards.card1.title")}</h5>
                    <p> {tSettingsModal("adminCards.card1.description")}</p>
                  </div>
                </div>
              </div>
              <div className={styles.wrapper}>
                <h4 className={styles.wrapperTitle}>
                  {" "}
                  {tSettingsModal("aimapCards.title")}
                </h4>
                <div className={styles.card} onClick={handlePaymentSelect}>
                  <img
                    width="40px"
                    height="40px"
                    src={billingSettings}
                    alt=""
                  />
                  <div className={styles.cardText}>
                    <h5>{tSettingsModal("aimapCards.card1.title")}</h5>
                    <p>{tSettingsModal("aimapCards.card1.description")}</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      }
    </ClickAwayListener>
  );
};

export default SettingsModal;
