import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { colors, icons, zIndexes } from "../../constants";
import CustomButton from "../Button/Button";
import Tag from "../Tag/Tag";
import { notification } from "antd";
import useMobileScreen from "../../hooks/useMobileScreen";
import LinkText from "../../components/LinkText/LinkText";
import { useTipsContext } from "../../hooks/useTips";

export interface NotificationPopupProps {
  title: string;
  id?: string;
  message: string;
  buttonText: string;
  onButtonClick: () => void;
  tagText?: string;
  cornerTopPosition?: number;
  onClose?: () => void;
}

const PopupContainer = styled.div<{ cornerTopPosition?: number }>`
  position: relative;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 400px;
  z-index: ${zIndexes.tooltips};

  padding: 16px 24px 16px 24px;
  gap: 24px;
  border-radius: 4px;
  opacity: 0px;
  cursor: initial !important;
  &::before {
    content: "";
    position: absolute;
    top: ${(props) =>
      props.cornerTopPosition ? props.cornerTopPosition : 20}px;
    left: -4px;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.002em;
  text-align: left;
  color: ${colors.darkD400};
`;

const Message = styled.div`
  margin-bottom: 16px;
  //styleName: Body 3/Regular 14;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: ${colors.darkD400};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: flex-start;
`;

const CloseButton = styled.div`
  cursor: pointer;
`;

const TitleTagDiv = styled.div`
  display: grid;
  gap: 16px;
`;
const NotificationPopup: React.FC<NotificationPopupProps> = ({
  id,
  title,
  message,
  buttonText,
  onButtonClick,
  tagText,
  cornerTopPosition,
}) => {
  const [api, contextHolder] = notification.useNotification();

  const { lastOpenedTipId, setLastOpenedTipId } = useTipsContext();

  const isMobile = useMobileScreen();

  const openAntdNotificationOnMobile = () => {
    api.open({
      message: title,
      description: (
        <div>
          {message} <LinkText noIcon>{buttonText}</LinkText>
        </div>
      ),
      onClick: onButtonClick,
    });
  };

  useEffect(() => {
    if (isMobile && lastOpenedTipId === id) {
      openAntdNotificationOnMobile();
    }
  }, [lastOpenedTipId]);

  useEffect(() => {
    setTimeout(() => {
      setLastOpenedTipId("");
    }, 5000);
  }, []);

  const handleClose = () => {
    setLastOpenedTipId("");
  };

  if (id !== lastOpenedTipId) return null;
  return (
    <PopupContainer cornerTopPosition={cornerTopPosition}>
      {contextHolder}
      <TitleContainer>
        <TitleTagDiv>
          {tagText && (
            <Tag text={tagText} iconText="✋">
              {tagText}
            </Tag>
          )}
          <Title>{title}</Title>
        </TitleTagDiv>
        <CloseButton onClick={handleClose}>
          <img src={icons.CloseSmall} alt="Close" />
        </CloseButton>
      </TitleContainer>
      <Message>{message}</Message>
      <ButtonContainer>
        <CustomButton type="filled" size="small" onClick={onButtonClick}>
          {buttonText}
        </CustomButton>
      </ButtonContainer>
    </PopupContainer>
  );
};

export default NotificationPopup;
