import {
  IMapObjectsInfoState,
  MapActionsEnum,
  MapConfig,
  SetClearMapObjectAction,
  SetMapConfig,
  SetMapObjectAction,
  SetMapObjectsAction,
  SetObjectsLoading,
} from "./types";
import { AppDispatch, RootState, getStoreState } from "../../../../index";
import { mapService } from "../../../../api/MapService";
import { IMapObjectsInfo } from "../../../../../models/MapObjectsInfo";
import { ICurrentObject } from "../../../../../models/CurrentObject";
import { IReqObjects } from "../../../../../models/MapObject";
import {
  OBJECTS_MAP_CONFIG,
  AvailableCC,
  apiUrl,
} from "../../../../../constants/constants";
import { MapInterfaceActionCreator } from "../../mapInterface/action.creators";
import mapConstants from "../../../../../constants/mapConstants";
import { IRegion } from "../../../../../models/map.interface";
import { DownloadActionCreators } from "../../../download/action-creators";

const getParamsForMainMapSearchResults = (page: number) => ({
  account_id: getStoreState().currentAccount.id,
  limit: mapConstants.limit,
  offset: (page - 1) * mapConstants.limit,
  aimap_classifier:
    getStoreState().mapFilters?.typeFilterArray.length > 0
      ? getStoreState().mapFilters.typeFilterArray
      : getStoreState().currentAccount?.subscribe?.permissions?.aimap_classifier
          ?.length > 0
      ? getStoreState().currentAccount.subscribe.permissions.aimap_classifier
      : undefined,
  consequence_class:
    getStoreState().mapFilters?.ccFilterArray.length > 0
      ? getStoreState().mapFilters.ccFilterArray
      : getStoreState().currentAccount?.subscribe?.permissions
          ?.consequence_class?.length > 0
      ? getStoreState().currentAccount.subscribe.permissions.consequence_class
      : undefined,
  stage_documentation:
    getStoreState().mapFilters?.stageDocumentation.length > 0
      ? getStoreState().mapFilters.stageDocumentation
      : getStoreState().currentAccount?.subscribe?.permissions
          ?.stage_documentation?.length > 0
      ? getStoreState().currentAccount.subscribe.permissions.stage_documentation
      : undefined,
  construction_type:
    getStoreState().mapFilters?.constructionType.length > 0
      ? getStoreState().mapFilters.constructionType
      : getStoreState().currentAccount?.subscribe?.permissions
          ?.construction_type?.length > 0
      ? getStoreState().currentAccount.subscribe.permissions.construction_type
      : undefined,
  stage_building:
    getStoreState().mapFilters?.stageBuilding.length > 0
      ? getStoreState().mapFilters.stageBuilding
      : getStoreState().currentAccount?.subscribe?.permissions?.stage_building
          ?.length > 0
      ? getStoreState().currentAccount.subscribe.permissions.stage_building
      : undefined,
  region:
    getStoreState().mapFilters?.region.length > 0
      ? getStoreState().mapFilters.region
      : getStoreState().currentAccount?.subscribe?.permissions?.region?.length >
        0
      ? getStoreState().currentAccount.subscribe.permissions.region
      : undefined,
  date_added__gte: getStoreState().mapFilters.from_date.length
    ? getStoreState().mapFilters.from_date
    : undefined,
  date_added__lte: getStoreState().mapFilters.to_date.length
    ? getStoreState().mapFilters.to_date
    : undefined,
  edrpou_code:
    getStoreState().mapFilters.edrpou_code.length > 0
      ? getStoreState().mapFilters.edrpou_code
      : undefined,
  np_id:
    getStoreState().mapFilters.np_id.length > 0
      ? getStoreState().mapFilters.np_id
      : undefined,
  dk_018_2000:
    getStoreState().mapFilters.dkbs_code.length > 0
      ? getStoreState().mapFilters.dkbs_code
      : undefined,
  lon: getStoreState().mapInterface.viewport.longitude
    ? getStoreState().mapInterface.viewport.longitude
    : undefined,
  lat: getStoreState().mapInterface.viewport.latitude
    ? getStoreState().mapInterface.viewport.latitude
    : undefined,
  keyword:
    getStoreState().mapFilters.keyword.length > 0
      ? getStoreState().mapFilters.keyword
      : undefined,
  object_square_new__gte:
    getStoreState().mapFilters.squareFilter[0] || undefined,
  object_square_new__lte:
    getStoreState().mapFilters.squareFilter[1] || undefined,
});

export const MainMapObjectActionCreators = {
  setFilteredHashIds: (filteredHashIds: number[]) => ({
    type: MapActionsEnum.UPDATE_STATE,
    payload: {
      hash_ids: filteredHashIds,
    },
  }),
  setMapRef: (mapRef: any) => ({
    type: MapActionsEnum.UPDATE_STATE,
    payload: {
      mapRef,
    },
  }),
  setCompanyMapRef: (companyMapRef: any) => ({
    type: MapActionsEnum.UPDATE_STATE,
    payload: {
      companyMapRef,
    },
  }),

  setPersonMapRef: (personMapRef: any) => ({
    type: MapActionsEnum.UPDATE_STATE,
    payload: {
      personMapRef,
    },
  }),

  setSikaMapRef: (sikaMapRef: any) => ({
    type: MapActionsEnum.UPDATE_STATE,
    payload: {
      sikaMapRef,
    },
  }),

  setUSAIDMapRef: (usaidMapRef: any) => ({
    type: MapActionsEnum.UPDATE_STATE,
    payload: {
      usaidMapRef,
    },
  }),
  setMapConfig: (payload: MapConfig): SetMapConfig => ({
    type: MapActionsEnum.SET_MAP_CONFIG,
    payload,
  }),
  setIsLoading: (payload: boolean): SetObjectsLoading => ({
    type: MapActionsEnum.SET_IS_MAIN_LOADING,
    payload,
  }),
  setMapObject: (mapObject: ICurrentObject): SetMapObjectAction => ({
    type: MapActionsEnum.SET_MAP_OBJECT,
    payload: mapObject,
  }),
  clearSelectedMapObject: (): SetClearMapObjectAction => ({
    type: MapActionsEnum.CLEAR_MAP_OBJECT,
  }),
  setMapObjectsInfo: (mapObjects: IMapObjectsInfo): SetMapObjectsAction => ({
    type: MapActionsEnum.SET_MAP_OBJECTS,
    payload: mapObjects,
  }),

  getDefaultResult: () => async (dispatch: AppDispatch) => {
    const page = getStoreState().mapInterface.page;
    const params: IReqObjects = getParamsForMainMapSearchResults(page);
    const countryCode = (getStoreState() as RootState).mapObjectsInfo.config
      .countryCode;

    try {
      dispatch(MapInterfaceActionCreator.setIsLoading(true));
      const response = await mapService.fetchMapObjects(params, countryCode);
      if (response) {
        dispatch(
          MainMapObjectActionCreators.setMapObjectsInfo({
            ...response.data,
            maxSquare: 246000,
          })
        );
      }
      dispatch(MapInterfaceActionCreator.setIsLoading(false));
    } catch (e: string | any) {
      dispatch(
        MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
      );
    }
  },

  refreshFeedForMainMapSearchResults: () => async (dispatch: AppDispatch) => {
    const { isLoading } = (getStoreState() as RootState).mapInterface;

    const prevMapObjectsInfo: IMapObjectsInfoState =
      getStoreState().mapObjectsInfo;
    if (isLoading) {
      return;
    }
    if (!prevMapObjectsInfo.mapObjects.next) {
      return;
    }
    const page = (getStoreState() as RootState).mapInterface.page;
    dispatch(MapInterfaceActionCreator.setPage(page + 1));
    const params: IReqObjects = getParamsForMainMapSearchResults(page + 1);
    const countryCode = (getStoreState() as RootState).mapObjectsInfo.config
      .countryCode;
    try {
      dispatch(MapInterfaceActionCreator.setIsLoading(true));
      const response = await mapService.fetchMapObjects(params, countryCode);
      if (response) {
        const nextMapObjectInfo: IMapObjectsInfo = response.data;

        const resultMapObjects: any = {
          ...nextMapObjectInfo,
          objects: [
            ...prevMapObjectsInfo.mapObjects.objects,
            ...nextMapObjectInfo.objects,
          ],
        };
        dispatch(
          MainMapObjectActionCreators.setMapObjectsInfo(resultMapObjects)
        );
      }
      dispatch(MapInterfaceActionCreator.setIsLoading(false));
    } catch (e: string | any) {
      dispatch(
        MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
      );
    }
  },
  getMapConfig: (regionCode: AvailableCC) => async (dispatch: AppDispatch) => {
    const config = OBJECTS_MAP_CONFIG.get(regionCode) as MapConfig;
    dispatch(MainMapObjectActionCreators.setMapConfig(config));
    localStorage.setItem("map", regionCode);
  },
  getCurrentMapObject:
    (hash_id: number | string, account_id: number) =>
    async (dispatch: AppDispatch) => {
      const countryCode = (getStoreState() as RootState).mapObjectsInfo.config
        .countryCode;
      try {
        dispatch(MapInterfaceActionCreator.setIsLoading(true));
        dispatch(MapInterfaceActionCreator.setIsLoading(true));
        const response = await mapService.fetchMapObject(
          hash_id || "",
          account_id,
          countryCode
        );
        if (response) {
          dispatch(MainMapObjectActionCreators.setMapObject(response.data));
        }
        dispatch(MapInterfaceActionCreator.setIsLoading(false));
        dispatch(MapInterfaceActionCreator.setIsLoading(false));
      } catch (e: string | any) {
        dispatch(
          MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
        );
      }
    },
  downloadObjects:
    (selectedRegion: IRegion) => async (dispatch: AppDispatch) => {
      const data: IReqObjects = {
        account_id: getStoreState().currentAccount.id,
        limit: 20,
        offset: getStoreState().mapInterface.offset,
        aimap_classifier: getStoreState().mapFilters.typeFilterArray.length
          ? getStoreState().mapFilters.typeFilterArray
          : undefined,
        consequence_class:
          getStoreState().mapFilters?.ccFilterArray.length > 0
            ? getStoreState().mapFilters.ccFilterArray
            : undefined,
        stage_documentation:
          getStoreState().mapFilters?.stageDocumentation.length > 0
            ? getStoreState().mapFilters.stageDocumentation
            : undefined,
        construction_type:
          getStoreState().mapFilters?.constructionType.length > 0
            ? getStoreState().mapFilters.constructionType
            : undefined,
        stage_building:
          getStoreState().mapFilters?.stageBuilding.length > 0
            ? getStoreState().mapFilters.stageBuilding
            : undefined,
        region:
          getStoreState().mapFilters?.region.length > 0
            ? getStoreState().mapFilters.region
            : undefined,
        date_added__gte: getStoreState().mapFilters.from_date.length
          ? getStoreState().mapFilters.from_date
          : undefined,
        date_added__lte: getStoreState().mapFilters.to_date.length
          ? getStoreState().mapFilters.to_date
          : undefined,
        edrpou_code:
          getStoreState().mapFilters.edrpou_code.length > 0
            ? getStoreState().mapFilters.edrpou_code
            : undefined,
        np_id:
          getStoreState().mapFilters.np_id.length > 0
            ? getStoreState().mapFilters.np_id
            : undefined,
        lon: getStoreState().mapInterface.viewport.longitude
          ? getStoreState().mapInterface.viewport.longitude
          : undefined,
        lat: getStoreState().mapInterface.viewport.latitude
          ? getStoreState().mapInterface.viewport.latitude
          : undefined,
      };
      try {
        dispatch(MapInterfaceActionCreator.setIsLoading(true));
        const response = await fetch(
          `{process.env.}/${selectedRegion}/download/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(data),
          }
        );
        if (response.status === 200) {
          const blob = await response.blob();
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download = "objects.xlsx";
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
        dispatch(MapInterfaceActionCreator.setIsLoading(false));
      } catch (e: string | any) {
        dispatch(
          MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
        );
      }
    },

  backgroundDownloadObjects:
    (selectedRegion: IRegion) => async (dispatch: AppDispatch) => {
      const data: IReqObjects = {
        account_id: getStoreState().currentAccount.id,
        limit: 20,
        offset: getStoreState().mapInterface.offset,
        aimap_classifier: getStoreState().mapFilters.typeFilterArray.length
          ? getStoreState().mapFilters.typeFilterArray
          : undefined,
        consequence_class:
          getStoreState().mapFilters?.ccFilterArray.length > 0
            ? getStoreState().mapFilters.ccFilterArray
            : undefined,
        stage_documentation:
          getStoreState().mapFilters?.stageDocumentation.length > 0
            ? getStoreState().mapFilters.stageDocumentation
            : undefined,
        construction_type:
          getStoreState().mapFilters?.constructionType.length > 0
            ? getStoreState().mapFilters.constructionType
            : undefined,
        stage_building:
          getStoreState().mapFilters?.stageBuilding.length > 0
            ? getStoreState().mapFilters.stageBuilding
            : undefined,
        region:
          getStoreState().mapFilters?.region.length > 0
            ? getStoreState().mapFilters.region
            : undefined,
        date_added__gte: getStoreState().mapFilters.from_date.length
          ? getStoreState().mapFilters.from_date
          : undefined,
        date_added__lte: getStoreState().mapFilters.to_date.length
          ? getStoreState().mapFilters.to_date
          : undefined,
        edrpou_code:
          getStoreState().mapFilters.edrpou_code.length > 0
            ? getStoreState().mapFilters.edrpou_code
            : undefined,
        np_id:
          getStoreState().mapFilters.np_id.length > 0
            ? getStoreState().mapFilters.np_id
            : undefined,
        lon: getStoreState().mapInterface.viewport.longitude
          ? getStoreState().mapInterface.viewport.longitude
          : undefined,
        lat: getStoreState().mapInterface.viewport.latitude
          ? getStoreState().mapInterface.viewport.latitude
          : undefined,
      };
      // dispatch(MapInterfaceActionCreator.setIsLoading(true));
      dispatch(DownloadActionCreators.setIsLoading(true));
      try {
        // Perform the file download using Fetch API or other suitable method
        const response = await fetch(`${apiUrl}/${selectedRegion}/download/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(data),
        });
        if (response.status === 200) {
          const blob = await response.blob();

          // Create a temporary URL for the downloaded file
          const url = window.URL.createObjectURL(blob);
          dispatch(DownloadActionCreators.setLastDownloadingUrl(url));

          // const a = document.createElement("a");
          // a.href = url;
          // a.download = "objects.xlsx";
          // document.body.appendChild(a);
          // a.click();
          // a.remove();
          dispatch(DownloadActionCreators.setIsLoading(false));
        }
      } catch (e: string | any) {
        dispatch(
          MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
        );
      }
    },
};
