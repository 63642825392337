import styled from "@emotion/styled";
import { Tooltip } from "antd";
import { icons } from "../../../constants";

export const StyledFilterTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.0022em;
  color: var(--secondary-black-color);
`;

const StyledTitleWrapper = styled.div<{ maxWidth: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* height: 26px; */
  margin-bottom: 25px;
  max-width: ${(props) => props.maxWidth};
  img {
    margin-left: 11px;
  }
`;

interface Props {
  info?: string;
  title: string;
  maxWidth?: string;
}
export const FilterTitleWithInfo: React.FC<Props> = ({
  title,
  info = "",
  maxWidth = "initial",
}) => {
  const icon = info ? icons.ExclamationPoint : icons.ExclamationPointDisabled;
  return (
    <StyledTitleWrapper maxWidth={maxWidth}>
      <StyledFilterTitle>{title}</StyledFilterTitle>
      <Tooltip title={info}>
        <img src={icon} alt="info" />
      </Tooltip>
    </StyledTitleWrapper>
  );
};
