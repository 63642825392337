import styled from "@emotion/styled";
import AddMoreFiltersButton from "./AddMoreFiltersButton";
import { FC } from "react";
import TemplateForm from "./TemplateFormModal";
import { FilterIdsEnum } from "../../../store/reducers/_maps/main/mapFilters/types";
import useIsFilterActive from "../../../hooks/useIsFilterActive";
import { useActions } from "../../../hooks/useActions";

const StyledAddMoreFilters = styled.div`
  position: relative;
`;

const AddSavedFilters: FC = () => {
  const filterId: FilterIdsEnum = "addSavedFiltersModal";
  const isModalShown = useIsFilterActive(filterId);
  const { toggleFilter, setSelectedTemplate } = useActions();
  const onClose = () => {
    toggleFilter("");
  };

  const handleToggle = () => {
    toggleFilter(filterId);
    setSelectedTemplate(null);
  };

  return (
    <StyledAddMoreFilters>
      <AddMoreFiltersButton isActive={isModalShown} onClick={handleToggle} />
      <TemplateForm isShown={isModalShown} onClose={onClose} />
    </StyledAddMoreFilters>
  );
};

export default AddSavedFilters;
