import { Select } from "antd";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";

interface ICustomSelectEmail {
  onEmailSelect: (value: string[]) => void;
  isDisabled?: boolean;
  value: string[];
}

const CustomSelectEmail: FC<ICustomSelectEmail> = ({
  onEmailSelect,
  isDisabled = false,
  value,
}) => {
  const { t: tSelect } = useTranslation("translation", {
    keyPrefix: "filterTemplate.form.emailReceivers",
  });

  const dropdownContainerRef = useRef<HTMLDivElement>(null);
  const currentAccount = useTypedSelector((store) => store.currentAccount);
  const accountUserList = currentAccount.users;
  const emailList = accountUserList.map((entry) => {
    return {
      value: entry.user.email,
      label: entry.user.email,
    };
  });

  return (
    <div ref={dropdownContainerRef}>
      <Select
        disabled={isDisabled}
        mode="multiple"
        onChange={onEmailSelect}
        placeholder={tSelect("placeholder")}
        getPopupContainer={() => dropdownContainerRef.current}
        options={emailList}
        value={value}
      />
    </div>
  );
};

export default CustomSelectEmail;
