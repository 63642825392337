import { MapActionsEnum, MapInterfaceAction, IMapInterface } from "./types";

const initialState: IMapInterface = {
  isLoading: false,
  isPulloutDrawerOpen: false,
  isProfileModalActive: false,
  isFilterBarActive: true,
  isDetailsActive: false,
  isSettingsActive: false,
  page: 1,
  offset: 0,
  viewport: { latitude: 50.4501, longitude: 30.5234, zoom: 5 },
  currentMarker: { lon: 0, lat: 0 },
  currentMapRef: null,
};

export default function mapInterfaceReducer(
  state = initialState,
  action: MapInterfaceAction
) {
  switch (action.type) {
    case MapActionsEnum.SET_IS_MAP_INTERFACE_LOADING:
      return { ...state, isLoading: action.payload };

    case MapActionsEnum.SET_CURRENT_MAP_REF:
      return { ...state, currentMapRef: action.payload };
    case MapActionsEnum.SET_IS_PULLOUT_DRAWER_OPEN:
      return { ...state, isPulloutDrawerOpen: action.payload };

    case MapActionsEnum.SET_FILTER_BAR_ACTIVE:
      return { ...state, isFilterBarActive: action.payload };

    case MapActionsEnum.SET_OBJECT_DETAILS_ACTIVE:
      return { ...state, isDetailsActive: action.payload };

    case MapActionsEnum.SET_PROFILE_MODAL_ACTIVE:
      return { ...state, isProfileModalActive: action.payload };

    case MapActionsEnum.SET_SETTINGS_ACTIVE:
      return { ...state, isSettingsActive: action.payload };

    case MapActionsEnum.SET_OFFSET:
      return { ...state, offset: action.payload };

    case MapActionsEnum.SET_PAGE:
      return { ...state, page: action.payload };

    case MapActionsEnum.SET_VIEWPORT:
      return { ...state, viewport: action.payload };

    case MapActionsEnum.SET_CURRENT_MARKER:
      return { ...state, currentMarker: action.payload };

    default:
      return state;
  }
}
