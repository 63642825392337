import styled from "@emotion/styled";
import { breakpoints, colors, zIndexes } from "../../constants";

export const StyledBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndexes.filterBackdrop};
  background-color: none;
  @media (max-width: ${breakpoints.small}px) {
    display: none;
  }
`;

export const StyledIconWrap = styled.div<{ position: "left" | "right" }>`
  ${(props) =>
    props.position === "left" ? "margin-right: 10px" : "margin-left: 10px"};
`;
export const StyledSearchContainer = styled.div<{ isShown?: boolean }>`
  position: relative;
  @media screen and (max-width: ${breakpoints.small}px) {
    ${(props) =>
      props.isShown &&
      `
        padding: 16px;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: ${zIndexes.searchFilterContainer};
        background-color: var(--White-W500, #fff);
        display: flex;
        flex-direction: column;
      `}
    position: ${(props) => (props.isShown ? "fixed" : "initial")};
  }
`;
export const StyledSuggestionContainer = styled.div<{ isShown?: boolean }>`
  @media screen and (max-width: ${breakpoints.small + "px"}) {
    flex: 1;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    display: ${(props) => (props.isShown ? "flex" : "none")};
    ${(props) =>
      props.isShown &&
      `
        margin-top: 24px;
      `}
  }
`;
export const StyledActiveSuggestionWrapper = styled.div<{ isShown?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;

  @media screen and (max-width: ${breakpoints.small + "px"}) {
    display: none;
    flex-wrap: wrap;
    gap: 8px 12px;
    margin-top: 0px;

    ${(props) =>
      props.isShown &&
      `
      display: flex;
    `}
  }
`;
export const StyledSearchInputWrapper = styled.div<{
  isShown?: boolean;
  isDisabled?: boolean;
}>`
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-white-color);
  border: 1px solid var(--fifth-black-color);
  padding: 0 6px;
  border-radius: 4px;
  /* position: ${(props) => (props.isShown ? "relative" : "static")}; */
  position: relative;
  z-index: ${zIndexes.searchInputWrapper};
  ${(props) => props.isDisabled && "background: var(--sixth-black-color);"}

  @media screen and (max-width: ${breakpoints.small + "px"}) {
    z-index: ${zIndexes.searchInputWrapperMobile};
    ${(props) =>
      props.isShown &&
      `
        border-radius: 8px;
        border: 1px solid var(--Dark-D400, #242731);
        padding: 0 8px;
      `}
  }
`;
export const StyledSearchInput = styled.input<{
  isShown?: boolean;
  isDisabled: boolean;
}>`
  font-family: Roboto, serif;
  font-size: 18px;
  line-height: 1.555;
  padding-left: 10px;
  color: var(--second-black-color);
  text-overflow: ellipsis;
  width: 100%;
  background: none;
  /* z-index: ${zIndexes.suggestionWrapper}; */
  @media screen and (max-width: ${breakpoints.small + "px"}) {
    ${(props) =>
      props.isShown &&
      `
        color: var(--Dark-D400, #242731);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 155.556% */
      `}
  }
`;
export const StyledActiveSuggestionCount = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--primary-white-color);
  width: 19px;
  height: 18px;
  background: linear-gradient(109.8deg, #f78d61 0%, #ed6044 100%);
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.571;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
`;
export const StyledActiveSuggestion = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  min-height: 36px;
  padding: 6px 12px;
  /* margin: 16px 16px 0 0; */
  background-color: var(--sixth-black-color);
  border-radius: 4px;
  max-width: 235px;

  @media screen and (max-width: ${breakpoints.small + "px"}) {
    padding: 4px 6px 4px 10px;
    color: var(--Dark-D400, #242731);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    margin: 0;
  }
`;
export const StyledActiveSuggestionBtn = styled(StyledActiveSuggestion)`
  cursor: pointer;
`;
export const StyledActiveSuggestionTextWrapper = styled.p<{ color?: string }>`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: ${(props) => (props.color ? props.color : colors.darkD400)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: ${breakpoints.small + "px"}) {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
`;
export const StyledActiveSuggestionIconWrapper = styled.div`
  flex: 1;
  margin-left: 8px;
  cursor: pointer;
`;
export const StyledSuggestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 45px;
  left: 40px;
  width: 464px;
  padding: 8px 0;
  max-height: 286px;
  background: var(--primary-white-color);
  box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  overflow: auto;
  z-index: ${zIndexes.suggestionWrapper};

  @media screen and (max-width: ${breakpoints.medium + "px"}) {
    width: 100%;
    left: 0;
    right: 0;
  }
  @media screen and (max-width: ${breakpoints.small + "px"}) {
    flex: 1;
    position: static;
    box-shadow: none;
    border: none;
    max-height: 100%;
    overflow: visible;
  }
`;
export const StyledSuggestion = styled.div`
  padding: 8px 8px 8px 42px;
  background-color: var(--primary-white-color);
  border-bottom: 1px solid var(--fifth-black-color);
  cursor: pointer;
  position: relative;
  z-index: ${zIndexes.searchFilterBackdrop};

  &:hover,
  &:focus {
    background-color: var(--sixth-black-color);
  }
`;
export const StyledSuggestionName = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: var(--secondary-black-color);

  @media screen and (max-width: ${breakpoints.small + "px"}) {
    color: var(--Dark-D400, #242731);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.017px;
  }
`;
export const StyledSuggestionCode = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  color: var(--third-black-color);

  @media screen and (max-width: ${breakpoints.small + "px"}) {
    margin-top: 2px;
    color: var(--Dark-D300, #575f6e);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
  }
`;
export const StyledSuggestionImg = styled.img`
  position: absolute;
  top: 10px;
  left: 8px;
`;
