import { useContext } from "react";
import { TipsContext, TipsContextProps } from "../contexts/TipsContext";

export function useTipsContext(): TipsContextProps {
  const context = useContext(TipsContext);
  if (!context) {
    throw new Error("useTipContext must be used within a TipProvider");
  }
  return context;
}
