import { uk, enGB, fr, pl, nb } from "date-fns/locale";
import { Locale } from "date-fns";
import useSelectedLanguage from "./useSelectedLanguage";

interface IUseDateLocale {
  lang: string;
  selectedLocale: Locale;
}

// ex: RETURNS: { lang: "ua", selectedLocale: uk }
const useDateLocale = (): IUseDateLocale => {
  const localesList: Record<string, Locale> = {
    ua: uk,
    en: enGB,
    fr: fr,
    pl: pl,
    no: nb,
  };
  const lang = useSelectedLanguage();
  const selectedLocale = localesList.hasOwnProperty(lang)
    ? localesList[lang]
    : localesList["ua"];

  return {
    lang,
    selectedLocale,
  };
};

export default useDateLocale;
