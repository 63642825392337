import React, { Dispatch, SetStateAction } from "react";
import styles from "./ContractOffer.module.css";
import Modal from "../../share/Modal/Modal";
import Close from "../../assets/svg/CloseBlack.svg";

interface IProps {
  contractOffer: boolean;
  setContractOffer: Dispatch<SetStateAction<boolean>>;
}

const ContractOffer = ({ contractOffer, setContractOffer }: IProps) => {
  return (
    <Modal modalVisible={contractOffer} setModalVisible={setContractOffer}>
      <div className={styles.modalWrapper}>
        <div className={styles.header}>
          <p>Ліцензійний публічний договір</p>
          <img src={Close} alt="close" />
        </div>
        <div className={styles.body}>
          <h1>ЛІЦЕНЗІЙНИЙ ПУБЛІЧНИЙ ДОГОВІР</h1>
          <p>на право використання програмної продукції</p>
          <p>
            Цим публічним ліцензійним договором (надалі – «Договір»),
            визначаються умови надання Ліцензіаром Ліцензіатові (особі, що
            приймає умови цього Договору відповідно до визначених в ньому
            правил) прав на використання програмної продукції, зокрема:
            інформаційно-аналітичної системи пошуку і обробки інформації у сфері
            будівництва та інших видів діяльності «AIMAP» (https://aimapa.com),
            а також інших об’єктів права інтелектуальної власності Ліцензіара.
            Сторонами цього Договору є «Ліцензіар» і «Ліцензіат», іменовані
            разом «Сторони», а окремо – «Сторона».
          </p>
          <h3>Загальні положення</h3>
          <p>
            Права на використання програмної продукції передаються на умовах
            окремих дозволів (ліцензій), що видаються Ліцензіаром Ліцензіатові
            на умовах обраного Ліцензіатом Тарифу.
          </p>
          <p>
            Прийняттям цього Договору Ліцензіат підтверджує повну і безумовну
            згоду з усіма його умовами (акцепт), що засвідчує факт укладання
            Договору між Сторонами. Ліцензіат підтверджує свою згоду сумлінно
            користуватись правами, які він отримує у зв’язку з отриманням
            ліцензії на використання Програмної продукції, а також
            зобов’язується неухильно виконувати передбачені цим Договором
            обов’язки та дотримуватись встановлених ним заборон та обмежень.
          </p>
          <p>
            Ліцензіат надає Ліцензіару відповідь про повне та безумовне
            прийняття (акцепт) пропозиції укласти цей Договір шляхом вчинення
            будь-якої окремої з наступних дій:
          </p>
          <ul>
            <li>реєстрація Ліцензіата в Системі «AIMAP»;</li>
            <li>будь-яке використання Ліцензіатом Системи «AIMAP»;</li>
            <li>
              у випадку укладення окремого договору на придбання ліцензії на
              використання Системи «AIMAP» з Ліцензіаром - шляхом укладення
              такого договору.
            </li>
          </ul>
          <p>
            Ліцензія на використання відповідної Програмної продукції є
            письмовим повноваженням, що надає Ліцензіатові право використовувати
            відповідну Програмну продукцію у якості довідкового ресурсу для
            інформаційно-аналітичного забезпечення своєї підприємницької
            діяльності відповідно до умов цього Договору та умов такої ліцензії.
          </p>
          <p>
            Розширені ліцензійні умови використання Програмної продукції є
            невід’ємною частиною (додатком) відповідної ліцензії на використання
            Програмної продукції. Розширені ліцензійні умови можуть передбачати
            певні додатки, доповнення та/або розширення функціоналу Програмної
            продукції тощо порівняно із умовами ліцензії на її використання.
            Розширені ліцензійні умови використання Програмної продукції діють в
            межах строку дії відповідної ліцензії на використання Програмної
            продукції (і закінчуються одночасно з ним).
          </p>
          <p>
            Користувач Системи «AIMAP» – будь-яка особа, в тому числі Ліцензіат,
            яка використовує Систему «AIMAP».
          </p>
          <h3>
            Права та обов’язки Сторін. Обмеження використання Програмної
            продукції
          </h3>
          <p>
            Протягом строку дії права на використання Програмної продукції,
            Ліцензіат має наступні права:
          </p>
          <ul>
            <li>
              здійснювати доступ до Програмної продукції у спосіб, передбачений
              виданою ліцензією та/або розширеними ліцензійними умовами
              використання Програмної продукції;
            </li>
            <li>
              отримувати за допомогою Програмної продукції інформацію (в тому
              числі – зведену) та відповідні дані повʼязані з будівельними
              обʼєктами, а також використовувати їх для забезпечення своєї
              діяльності;
            </li>
            <li>
              включати інформацію Програмної продукції складовою частиною
              будь-яких збірників, баз даних, антологій, енциклопедій тощо;
            </li>
            <li>
              повідомляти Ліцензіара про недоліки і вимагати забезпечення
              належного функціонування Програмної продукції за адресою
              support@aimapa.com.
            </li>
          </ul>
          <p>Ліцензіат зобов’язаний:</p>
          <ul>
            <li>
              виконувати умови цього Договору та дотримуватись передбачених ним
              заборон і обмежень, дотримуватись ліцензійних умов, розширених
              ліцензійних умов і Правил використання;
            </li>
            <li>
              при формуванні та/або редагувані інформації у розділі Системи
              «AIMAP», призначеному для формування та/або редагування інформації
              Системи «AIMAP», дотримуватися вимог законодавства та
              загальновизнаних етичних норм;
            </li>
            <li>
              формувати та/або редагувати у розділі Системи «AIMAP» інформацію,
              яка забезпечена відповідними формами запитів для формування та/або
              редагування .
            </li>
            <li>
              надавати дійсні, точні та повні відомості про себе та іншу
              інформацію, необхідну для виконання умов цього Договору;
            </li>
            <li>
              забезпечувати схоронність інформації, що необхідна для авторизації
              в Програмній продукції (логіни, паролі доступу тощо);
            </li>
            <li>
              негайно повідомляти Ліцензіара про будь-які порушення його прав
              щодо Програмної продукції з боку третіх осіб (якщо Ліцензіату
              відомо про такі порушення), а також сприяти Ліцензіару в
              оперативному припиненні таких порушень та фіксації доказів їх
              наявності.
            </li>
          </ul>
          <p>Ліцензіату забороняється:</p>
          <ul>
            <li>
              здійснювати декомпіляцію наявних в Програмній продукції баз даних
              з метою перенесення інформації до інших інформаційно-пошукових
              систем або для інших цілей; використовувати у власних
              інформаційних продуктах зображення інтерфейсу Програмної продукції
              без дозволу Ліцензіара;
            </li>
            <li>
              забезпечувати можливості доступу будь-яких третіх осіб (за
              винятком працівників та/або уповноважених представників
              Ліцензіата) до Програмної продукції;
            </li>
            <li>
              розміщувати аналітичні дані Системи «AIMAP» у складі будь-яких
              інших інформаційних-пошукових систем, комп’ютерних програм,
              електронних та/або друкованих видань, а також здійснювати будь-яке
              їх оплатне розповсюдження третім особам (в тому числі, через
              мережу Інтернет);
            </li>
            <li>
              формувати та/або редагувати у розділі Системи «AIMAP»,
              призначеному для формування та/або інформації Системи «AIMAP»,
              неправдиву інформацію, а також інформацію, яка містить
              непристойні, грубі або образливі вирази;
            </li>
            <li>
              перешкоджати функціонуванню Програмної продукції і намагатися
              отримати доступ до неї за допомогою методів, що не відповідають
              визначеним у ній формам запитів для пошуку, формування, отримання
              та редагування інформації (програмний веб-інтерфейс, налаштування
              API тощо), в тому числі за допомогою інших програмних засобів;
            </li>
            <li>
              видавати будь-яким третім особам дозволи (ліцензії, субліцензії)
              на право використання Програмної продукції;
            </li>
            <li>
              використовувати Програмну продукцію всупереч Правил використання,
              а також з метою, що суперечить законодавству України.
            </li>
          </ul>
          <p>Ліцензіар має право:</p>
          <ul>
            <li>
              передбаченими законодавством України способами, контролювати
              обсяги та порядок використання Ліцензіатом Програмної продукції та
              вимагати від Ліцензіата припинити будь-яке використання Програмної
              продукції, що суперечить чинному законодавству України, цьому
              Договору, а також ліцензійним умовам, розширеним ліцензійним
              умовам і Правилами використання;
            </li>
            <li>
              зупинити доступ Ліцензіата до Програмної продукції з технічних
              причин (перебої в роботі офіційних реєстрів тощо);
            </li>
            <li>
              припинити дію виданої ліцензії та/або розширених ліцензійних умов
              використання Програмної продукції у випадку порушення Ліцензіатом
              заборон, передбачених цим Договором, а так само у випадку
              порушення умов та/або строків оплати ліцензійної винагороди,
              передбаченої умовами відповідного Тарифу;
            </li>
            <li>
              модерувати та/або видаляти інформацію, яку формує Ліцензіат у
              розділі Системи «AIMAP», призначеному для формування інформації
              Системи «AIMAP», якщо формування такої інформації суперечить
              положенням цього Договору, Правил використання та/або чинного
              законодавства. Ліцензіар не контролює і не спрямовує дії
              користувачів при формуванні інформації, яка поширюється ними в
              Системі «AIMAP». Формування змісту та поширення зазначеної
              інформації здійснюється користувачами Системи «AIMAP» виключно на
              власний розсуд.
            </li>
          </ul>
          <p>Ліцензіар зобов’язаний:</p>
          <ul>
            <li>
              дотримуватися умов цього Договору (окрім невиконання зобов’язань,
              що сталося внаслідок випадку або дії обставин непереборної сили);
            </li>
            <li>
              здійснювати інформатизаційне обслуговування Програмної продукції,
              забезпечувати її належне функціонування;
            </li>
            <li>
              протягом розумного строку (а за можливості – завчасно), що не може
              перевищувати трьох робочих днів з моменту, коли Ліцензіар дізнався
              про настання відповідних обставин, повідомляти Ліцензіата про
              випадки відсутності доступу до Програмної продукції, причини
              відсутності такого доступу та/або про строки його відновлення.
            </li>
            <li>
              будь-яка із Сторін може розповсюджувати у будь-який спосіб
              (зокрема, але не виключно – в рекламі) інформацію щодо
              співробітництва з іншою Стороною за цим Договором, з урахуванням
              визначених ним обмежень щодо конфіденційності;
            </li>
            <li>
              приймати необхідні правові, організаційні та технічно засоби або
              забезпечувати їх прийняття для захисту персональної інформації від
              неправомірного або випадеового доступу до них, знищення, зміна,
              блокування, копіювання, надання, поширення персональних даних, а
              також інших неправомірних дій стосовно персональної інформації, у
              тому числі:
              <ul>
                <li>використовувати RSA шифрування в Системі «AIMAP»;</li>
                <li>
                  надвати при необхідності двоетапну перевірку для доступу до
                  обліковго запису;
                </li>
                <li>здійснювати захист авторизованих сесій;</li>
                <li>
                  постійно вдосконалювати способи збору, зберігання і обробки
                  даних.
                </li>
              </ul>
            </li>
          </ul>
          <h3>Умови користування</h3>
          <p>
            Порядок і умови доступу та використання відповідної Програмної
            продукції визначаються цим Договором, окремими ліцензіями на
            використання Програмної продукції, розширеними ліцензійними умовами
            та Правилами використання.
          </p>
          <h3>Ліцензійна винагорода</h3>
          <p>
            За надання прав, передбачених цим Договором, зокрема за отримання
            ліцензій на використання Програмної продукції та розширених
            ліцензійних умов її використання, Ліцензіат виплачує Ліцензіарові
            винагороду (ліцензійну винагороду) за умовами обраного Тарифу.
          </p>
          <p>
            Виплата Ліцензіатом ліцензійної винагороди здійснюється шляхом
            безготівкового перерахунку коштів на банківський рахунок Ліцензіара
            (одноразовим платежем всієї суми за певну Програмну продукцію).
          </p>
          <p>
            Отримання ліцензії (ліцензій) на використання Програмної продукції,
            розширених ліцензійних умов використання Програмної продукції та їх
            оплата може підтверджуватись відповідними актами здачі-прийняття, в
            тому числі у формі електронних документів, з використанням
            програмного забезпечення M.E.Doc.
          </p>
          <h3>Політика повернення коштів</h3>
          <p>
            Ліцензіар не повертає кошти, якщо Ліцензіат передумає користуватися
            Системою «AIMAP». Більшість онлайн-продуктів і послуг Системи
            «AIMAP» миттєво постачаються в цифровому вигляді, тому Ліцензіар
            одразу отримає всі переваги Сервісу «AIMAP».
          </p>
          <p>
            Ліцензіар залишає за собою право відмовити в обслуговувані і в цьому
            випадку Ліцензіар негайно повертає Ліцензійну винагороду протягом
            одного робочого дня.
          </p>
          <h3>Конфіденційність</h3>
          <p>
            Сторони зобов’язуються дотримуватися конфіденційності відносно всієї
            технічної, комерційної та іншої інформації, отриманої за цим
            Договором однією Стороною від іншої та вживати розумних заходів для
            попередження несанкціонованого використання або розкриття такої
            інформації третім особам.
          </p>
          <p>
            Ліцензіар зобов’язується зберігати конфіденційність інформації
            Ліцензіата, отриманої ним внаслідок використання Програмної
            продукції Ліцензіатом (в тому числі системних реєстраційних даних,
            інформації щодо обсягу і змісту пошукових запитів та інформації, яка
            була ввдедена користувачем у відповідних розділах Системи «AIMAP»).
          </p>
          <p>
            Сторони не несуть відповідальності за порушення конфіденційності,
            яке відбулося внаслідок випадку, дії обставин непереборної сили,
            протиправних дій третіх осіб, а так само внаслідок порушення
            конфіденційності за вимогою державних органів згідно чинного
            законодавства.
          </p>
          <h3>Відповідальність</h3>
          <p>
            Сторони несуть відповідальність за порушення, невиконання та/або
            неналежне виконання цього Договору, умов виданих ліцензій,
            розширених ліцензійних умов, а також Правил використання відповідно
            до чинного законодавства України та умов цього Договору.
          </p>
          <p>
            Сторони не несуть відповідальності за порушення, невиконання та/або
            неналежне виконання цього Договору, що сталося внаслідок випадку або
            дії обставин непереборної сили, протиправних дій третіх осіб.
          </p>
          <p>
            У разі порушення заборон, передбачених цим Договором, Ліцензіат
            сплачує на користь Ліцензіара штраф у двократному розмірі сплаченої
            протягом строку дії відповідної ліцензії на використання відповідної
            Програмної продукції ліцензійної винагороди. Сплата штрафу не
            звільняє Ліцензіата від обов’язку відшкодування Ліцензіарові шкоди,
            яка виникла у останнього у зв’язку з порушенням зазначених заборон
            Ліцензіатом.
          </p>
          <p>
            У випадку технічної неможливості доступу та/або повної неможливості
            отримання Ліцензіатом інформаційно-аналітичних даних за допомогою
            Програмної продукції відповідно до умов виданої ліцензії та/або
            розширених ліцензійних умов використання Програмної продукції з вини
            Ліцензіара, на підставі обґрунтованої вимоги Ліцензіата, Ліцензіар
            зобов’язаний продовжити дію ліцензії на використання Програмної
            продукції на строк, що відповідає періоду часу дії зазначених
            обставин.
          </p>
          <p>Ліцензіар не несе відповідальності за:</p>
          <ul>
            <li>
              точність, повноту і правильність інформації, отриманої Ліцензіатом
              при використанні Програмної продукції внаслідок здійснення
              неточних, неповних або неправильних запитів;
            </li>
            <li>
              фактичну повноту і достовірність будь-якої інформації (в тому
              числі – зведеної), отриманої з офіційних джерел публічної
              інформації у формі відкритих даних, а також заснованих на такій
              інформації аналітичних даних, що містяться в Програмній продукції;
            </li>
            <li>
              зміст та наслідки формування та/або використання Ліцензіатом
              інформації у розділі Системи «AIMAP», призначеному для формування
              інформації користувачами Системи «AIMAP», в тому числі для
              прийняття управлінських, юридичних, фінансових, організаційних та
              будь-яких інших рішень, дій (або бездіяльності);
            </li>
            <li>
              наслідки використання аналітичних даних, що містяться в Програмній
              продукції для прийняття управлінських, фінансових, організаційних
              та будь-яких інших рішень, дій (або бездіяльності);
            </li>
            <li>
              прямі або непрямі збитки, втрачену вигоду або завдану Ліцензіату
              моральну шкоду, пов’язані із використанням або
              ускладненням/неможливістю використання Програмної продукції, що
              виникли внаслідок обставин, які знаходяться поза межами контролю
              Ліцензіара;
            </li>
            <li>
              припинення дії виданої ліцензії та/або розширених ліцензійних умов
              використання Програмної продукції у випадку порушення Ліцензіатом
              заборон, передбачених цим Договором, а так само у випадку
              порушення умов та/або строків оплати ліцензійної винагороди,
              передбаченої умовами відповідного Тарифу. При цьому сума сплаченої
              ліцензійної винагороди Ліцензіатові не повертається і будь-які
              засоби відповідальності у зв’язку з таким припиненням до
              Ліцензіара не застосовуються.
            </li>
          </ul>
          <p>
            Відповідальність Ліцензіара перед Ліцензіатом за цим Договором, в
            тому числі – щодо відшкодування збитків, обмежується сумою
            ліцензійної винагороди, отриманої Ліцензіаром протягом останнього
            року (останніх 365 днів, що передували настанню обставин, на які
            посилається Ліцензіат як на підставу своїх вимог).
          </p>
          <p>
            Відповідальність, не врегульована цим Договором, регулюється чинним
            законодавством України.
          </p>
          <h3>Строк дії ліцензії. Інші умови</h3>
          <p>
            Строк дії ліцензії на використання Програмної продукції, а також
            початок його перебігу, строк оплати ліцензійної винагороди
            визначаються умовами відповідного Тарифу.
          </p>
          <p>
            Можливість використання Ліцензіатом Програмної продукції
            закінчується одночасно із закінченням строку дії ліцензії на її
            використання.
          </p>
          <p>
            Прийняття умов цього Договору без виплати ліцензійної винагороди дає
            Ліцензіату право доступу до Програмної продукції протягом строку,
            визначеного Ліцензіаром. Вид Програмної продукції та її доступний
            функціонал протягом цього строку визначаються політикою Ліцензіара.
          </p>
          <p>
            Сторони домовились, що Ліцензіар може передавати свої права та
            обов’язки за цим Договором іншій особі, без необхідності отримання
            згоди та/або повідомлення про це Ліцензіата.
          </p>
          <p>
            У випадку укладення Сторонами ліцензійного договору на право
            використання Програмної продукції в письмовій формі, умови
            використання Програмної продукції визначаються договором, укладеним
            в письмовій формі.
          </p>
          <p>
            Впродовж усього строку дії ліцензії на право використання Програмної
            продукції Ліцензіар зберігає право самостійно використовувати
            Програмну продукцію у будь-який спосіб, а також право дозволяти або
            забороняти її використання іншим особам будь-якими способами, що
            належать до числа правомочностей суб’єкта прав інтелектуальної
            власності відповідно до статті 15 Закону України «Про авторське
            право і суміжні права».
          </p>
          <p>
            Видача (та продовження строку дії) ліцензій, розширених ліцензійних
            умов використання Програмної продукції як програмної продукції,
            відповідно до пункту 26¹ підрозділу 2 розділу ХХ «Перехідні
            положення» Податкового кодексу України, є операціями, що
            звільняються від оподаткування ПДВ.
          </p>
          <p>
            Всі суперечки, що виникають між Сторонами вирішуються ними шляхом
            переговорів. У разі недосягнення згоди спір вирішується відповідно
            до чинного законодавства України.
          </p>
          <h3>Скасування ліцензії</h3>
          <p>
            Більшість продуктів і послуг Системи «AIMAP» доставляються миттєво в
            цифровому вигляді, тому Ліцензіар не пропонкє відшкодування, якщо
            Ліцензіат передумає користуватися Системою «AIMAP».
          </p>
          <p>
            Повторювальні транзакції можна в будь-який час скасувати в
            відповідному розділі Системи «AIMAP» або надіславши запит на адресу
            billing@aimapa.com.
          </p>
          <p>
            Цей розділ не впливає на права Ліцензіата у випадку, якщо Ліцензаіт
            має справжню та обґрунтовану скаргу на роботу Системи «AIMAP».
          </p>
          <h3>Внесення змін до договору</h3>
          <p>
            Умови цього Договору можуть бути змінені Ліцензіаром у будь-який час
            шляхом розміщення оновленої версії Договору за адресою:
            https://aimapa.com/about/terms-of-servise. Ліцензіар повідомляє
            Ліцензіату про внесення змін шляхом надсилання йому відповідного
            повідомлення через Систему «AIMAP» під час або після входу у Систему
            та/або надсиланням на електронну пошту Ліцензіата, вказану під час
            реєстрації облікого запису, оновленого Договору. Лцензіат є належно
            повідомленим про зміни умов Договору з моменту надіслання
            Ліцензіаром такого повідомлення.
          </p>
          <p>
            Ліцензіат зобов’язується приймати будь-які зміни, внесені
            Ліцензіаром до умов Договору. У випадку, якщо Ліцензіар не приймає
            такі зміни, він зобов’язаний протягом п’яти днів з моменту
            повідомлення про це Ліцензіата письмово повідомити Ліцензіара про
            неприйняття змін. У випадку, якщо Ліцензіат не повідомив Ліцензіара
            про неприйняття змін до Договору, вважається, що Ліцензіатом
            прийнято такі зміни з шостого дня після повідомлення про них.
          </p>
          <p>
            У випадку неприйняття Ліцензіатом змін до Договру його дія
            припиняється автоматично у момент закінчення терміну дії Ліцензії. У
            цьому випадку Ліцензіат не має права на продовження терміну дії
            Ліцензії. Якщо Ліцензію надано Ліцензату на невизначений строк, дія
            Договору припиняється протягом п’яти днів з моменту повідомлення
            Ліцензіата про зміну умов Договору.
          </p>
          <p>
            Ліцензіат має право отримати цей Договір на свою електронну пошту у
            формі, що унеможливлює зміну її змісту. Отриманий Ліцензіатом
            Договір у такій формі є чинним до моменту внесення змін до Договру у
            порядку, передбаченому цим розділом.
          </p>
          <p>
            Внесення змін до Умов використання відбувається у порядку та на
            умовах, визначених цим розділом шляхом розміщення їх оновленої
            версії за адресою: https://aimapa.com/about/terms-of-use.
          </p>
          <p>
            Ліцензіат має право в односторонньому порядку змінити перелік та
            умови Ліцензійної винагороди шляхом розміщення оновленого переліку
            за адресою: https://aimap.aimarketing.info/uk/prices у строк не
            пізніше чотирнадцяти днів до дати введення в дію таких змін. У
            випадку змін у функціональних можливостях Системи «AIMAP» розміщує
            відповідний оновлений перелік за адресою:
            https://aimap.aimarketing.info/uk/prices.
          </p>
          <div>
            <p>
              Ліцензіар: Товариство з обмеженою відповідальністю "АРТІФІШЛ
              ІНТЕЛІДЖЕНС МЕП" (ТОВ «АІМАПА») Місцезнаходження: 08140, Київська
              область, Києво-Святошинський район, с. Шевченкове, провулок
              Ювілейний, 5, офіс 105 Кур’єрська служба: Нова пошта, м. Київ,
              відділення №285, тел.: +380 95 882 54 89 Код ЄДРПОУ: 43938044
              Банківські реквізити: IBAN UA503206490000026006052739047 в м. Київ
              МФО 320649 Податковий статус: платник єдиного податку 3 групи,
              ставка 5%, без ПДВ Користувач сервісу: ЕДО «Вчасно»
              https://vchasno.ua/ Засоби зв’язку: тел.: +380 44 592 81 81 email:
              ask@aimapa.com
            </p>
          </div>
        </div>
        <div className={styles.footer}>
          <button type="button" onClick={() => setContractOffer(false)}>
            Зрозуміло
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ContractOffer;
