import { AppDispatch, store } from "../../index";
import { userService } from "../../api/UserService";
import { CurrentUserActionsEnum } from "./types";
import { IFetchable } from "../../../models/IFetchable";
import { IUser } from "../../../models/Accounts";

const CurrentUserActionCreator = {
  setIsLoading: (isLoading: boolean) => {
    return {
      type: CurrentUserActionsEnum.SET_IS_USER_LOADING,
      payload: isLoading,
    };
  },
  setError: (e: string) => {
    return {
      type: CurrentUserActionsEnum.SET_ERROR,
      payload: e,
    };
  },
  setUser: (user: Partial<IUser & IFetchable>) => {
    return {
      type: CurrentUserActionsEnum.SET_USER,
      payload: { ...user },
    };
  },
  getUser: () => async (dispatch: AppDispatch) => {
    try {
      dispatch(CurrentUserActionCreator.setIsLoading(true));
      const { data } = await userService.getUser();
      dispatch(CurrentUserActionCreator.setUser(data));
    } catch (e) {
      dispatch(CurrentUserActionCreator.setError(e as string));
    }
    dispatch(CurrentUserActionCreator.setIsLoading(false));
    return {
      type: CurrentUserActionsEnum.GET_USER,
    };
  },
  updateUser:
    (user: Partial<IUser & IFetchable>) => async (dispatch: AppDispatch) => {
      const { currentUser } = store.getState();
      try {
        await userService.updateUser(currentUser.id, { ...user });
        dispatch(CurrentUserActionCreator.setUser({ ...currentUser, ...user }));
      } catch (e) {
        dispatch(CurrentUserActionCreator.setError(e as string));
      }
    },
};

export default CurrentUserActionCreator;
