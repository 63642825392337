import React, { useState } from "react";
import styles from "./UserProfileBar.module.scss";
import ArrowLeft from "../../assets/svg/lilArrowLeft.svg";
import FAQ from "../../assets/svg/FAQ.svg";
import Settings from "../../assets/svg/settings.svg";
import ProfileAvatar from "../../assets/images/Profile image.jpg";
import DefaultProfileAvatar from "../../assets/images/DefaultProfileAvatar.svg";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import UserProfileModal from "./UserProfileModal/UserProfileModal";
import { useNavigate, useLocation } from "react-router-dom";
import SettingsModal from "./SettingsModal/SettingsModal";
import { datasets } from "../../constants/mapConstants";
import useSearchParams from "../../hooks/useSearchParams";
import { useTranslation } from "react-i18next";
import UserProfileBarFilterModal from "./UserProfileBarFilterModal";
import ButtonModal from "../../share/ButtonModal";
import useAppContext from "../../hooks/useAppContext";
import Notification from "../../share/Notification";
import styled from "@emotion/styled";
import { Empty } from "antd";
import { colors, icons } from "../../constants";
import { RouteNames } from "../../routes";
import useStoreSnippets from "../../hooks/useStoreSnippets";
import PopupBar from "../PopupBar/PopupBar";

const StyledProfileButtonWrapper = styled.div``;
const SettingsButtonWrapper = styled.div``;
const FAQButtonWrapper = styled.div``;
const NotificationsButtonWrapper = styled.div``;

interface IProfileMenu {
  isWithFilterPopupButton?: boolean;
}

const StyledNotificationList = styled.div`
  display: grid;
  h3 {
    color: ${colors.darkD400};
    /* Heading 2/Semibold 24 */
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 125% */
    margin-bottom: 24px;
  }
`;

const ProfileMenu: React.FC<IProfileMenu> = ({
  isWithFilterPopupButton = false,
}) => {
  const {
    setIsFilterActive,
    setProfileModal,
    setSettingModal,
    toggleFilter,
    setIsWarned,
  } = useActions();
  const { isFilterBarActive, isProfileModalActive, isSettingsActive } =
    useTypedSelector((state) => state.mapInterface);
  const navigate = useNavigate();
  const { isGuestAccount, isAuthWarned } = useStoreSnippets();

  const {
    typeFilterArray,
    ccFilterArray,
    constructionType,
    stageBuilding,
    stageDocumentation,
    region,
    to_date,
    activeSuggestions,
  } = useTypedSelector((state) => state.mapFilters);
  const [activeFiltersModal, setActiveFiltersModal] = useState(false);
  const location = useLocation();

  const activeFiltersCount =
    typeFilterArray.length +
    ccFilterArray.length +
    constructionType.length +
    stageBuilding.length +
    stageDocumentation.length +
    region.length +
    activeSuggestions.length +
    (to_date ? 1 : 0);

  const handleToggleFilterPanel = () => {
    setIsFilterActive(!isFilterBarActive);
    toggleFilter("");
  };

  const isFiltersPopupButton = !isFilterBarActive && isWithFilterPopupButton;

  const { getSearchParam } = useSearchParams();
  const dataset = getSearchParam("dataset");

  const { t: tFilterAction } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.filtersActions",
  });

  const { t: tNotifications } = useTranslation("translation", {
    keyPrefix: "notifications",
  });

  const { t: tBar } = useTranslation("translation", {
    keyPrefix: "userProfileBar.warningBar",
  });

  const { lastNotifications, isNewNotifications, setIsNewNotifications } =
    useAppContext();

  const handleClickLoginButton = () => {
    setProfileModal(false);
    navigate(RouteNames.LOGIN);
  };

  const isMain = location.pathname === RouteNames.AIMAP;

  return (
    <div
      className={
        isFilterBarActive
          ? `${styles.profileMenu} ${styles.smallerNav}`
          : styles.profileMenu
      }
    >
      <div className={styles.menuBar}>
        {isMain && (
          <div className={styles.profileMenuWrapper}>
            <button
              className={
                isFilterBarActive
                  ? `${styles.filterTriggerBtn} ${styles.smallBtn}`
                  : styles.filterTriggerBtn
              }
              onClick={handleToggleFilterPanel}
            >
              <img
                className={styles.filterTriggerIcon}
                src={ArrowLeft}
                alt="close"
              />
              <span>{tFilterAction("showAllFilters")}</span>
            </button>
            {isFiltersPopupButton && dataset === datasets.objects && (
              <button
                onClick={() => setActiveFiltersModal(true)}
                className={styles.filterTriggerBtn}
              >
                {tFilterAction("filters")}
                {activeFiltersCount > 0 && (
                  <>&nbsp;&bull;&nbsp;{activeFiltersCount}</>
                )}
              </button>
            )}
          </div>
        )}
        <nav
          className={styles.userSettingsMenu}
          onClick={(e) => e.stopPropagation()}
        >
          <NotificationsButtonWrapper>
            <ButtonModal
              triggerReactNode={
                <button
                  onClick={() => {
                    setIsNewNotifications(false);
                    setProfileModal(false);
                    setSettingModal(false);
                    setActiveFiltersModal(false);
                  }}
                  className={styles.userMenuBtn}
                >
                  <img
                    src={
                      isNewNotifications
                        ? icons.ActiveNotification
                        : icons.Notification
                    }
                    alt="Notifications"
                  />
                </button>
              }
              modalId="notifications"
              modalWidth={400}
            >
              {lastNotifications.length ? (
                <StyledNotificationList>
                  <h3>{tNotifications("title")}</h3>
                  {[...lastNotifications]
                    .sort((a, b) => b.timeStamp - a.timeStamp)
                    .map(({ type, args, timeStamp }, index) => (
                      <Notification
                        key={index}
                        type={type}
                        description={args.description}
                        message={args.message}
                        timeStamp={timeStamp}
                      />
                    ))}
                </StyledNotificationList>
              ) : (
                <Empty description="No notifications" />
              )}
            </ButtonModal>
          </NotificationsButtonWrapper>
          <FAQButtonWrapper>
            <button
              onClick={() => {
                setProfileModal(false);
                navigate(RouteNames.FEEDBACK_PAGE);
              }}
              className={styles.userMenuBtn}
            >
              <img src={FAQ} alt="FAQ" />
            </button>
          </FAQButtonWrapper>
          <SettingsButtonWrapper>
            <button
              onClick={() => {
                setSettingModal(!isSettingsActive);
                setProfileModal(false);
              }}
              className={styles.userMenuBtn}
            >
              <img src={Settings} alt="Settings" />
            </button>
          </SettingsButtonWrapper>

          {!isGuestAccount ? (
            <StyledProfileButtonWrapper>
              <button
                onClick={() => {
                  setProfileModal(!isProfileModalActive);
                  setSettingModal(false);
                  toggleFilter("");
                }}
                className={styles.profileSettingsTrigger}
              >
                <img src={ProfileAvatar} alt="UserProfile" />
              </button>
            </StyledProfileButtonWrapper>
          ) : (
            <StyledProfileButtonWrapper>
              <button
                onClick={handleClickLoginButton}
                className={styles.profileSettingsLogin}
              >
                <img src={DefaultProfileAvatar} alt="UserProfile" />
              </button>
            </StyledProfileButtonWrapper>
          )}
        </nav>
        {isSettingsActive && <SettingsModal />}
        {isProfileModalActive && <UserProfileModal />}
        <UserProfileBarFilterModal
          setActiveFiltersModal={setActiveFiltersModal}
          activeFiltersModal={activeFiltersModal}
        />
      </div>
      {/* TODO: when to show? */}
      {isGuestAccount && !isAuthWarned && isMain && (
        <PopupBar
          message={tBar("message")}
          btnText={tBar("btn")}
          onBtnClick={handleClickLoginButton}
          onClose={() => {
            setIsWarned(true);
          }}
        />
      )}
    </div>
  );
};

export default ProfileMenu;
