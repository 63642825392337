import React from "react";
import styled from "@emotion/styled";
import { breakpoints, colors } from "../../../../constants";
import { useTranslation } from "react-i18next";
import PinnedObjectsHeader from "./PinnedObjectsHeader";

const StyledPinnedObjectsSidebar = styled.div`
  display: none;
  @media (max-width: ${breakpoints.small}px) {
    border-top: 1px solid ${colors.darkD100};
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    background: ${colors.darkD75};
    padding: 16px;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }
`;

const StyledPinnedObjectsSidebarList = styled.div`
  overflow-y: auto;
  margin-bottom: 16px;
`;

type Props = {
  handleClose: () => void;
  children: React.ReactNode;
};

const PinnedSelectedMapObjectsList = ({ handleClose, children }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "mapUI" });
  return (
    <StyledPinnedObjectsSidebar>
      {/* <StyledHeadContainer>
        <Heading2>{t("selectedObjects")}:</Heading2>
      </StyledHeadContainer> */}

      <PinnedObjectsHeader
        title={t("selectedObjects")}
        closeDetails={handleClose}
      />

      <StyledPinnedObjectsSidebarList>
        {children}
      </StyledPinnedObjectsSidebarList>
    </StyledPinnedObjectsSidebar>
  );
};

export default PinnedSelectedMapObjectsList;
