import { useState } from "react";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import Details from "./ActiveTabs/Details";
import JpBlue from "../../../../assets/svg/JpBlue.svg";
import NpGreen from "../../../../assets/svg/NpGreen.svg";
import useSearchParams from "../../../../hooks/useSearchParams";
import { ICurrentUSAID } from "../../../../models/CurrentUSAIDObject";
import { useTranslation } from "react-i18next";
import Tab, { TabsWrapper } from "../../../Tab/Tab";
import AnimatedDetailsContainer from "../../../../share/ResultDetails/AnimatedDetailsContainer/AnimatedDetailsContainer";
import DetailsHeader from "../../../../share/ResultDetails/DetailsHeader/DetailsHeader";
import {
  DetailsContainer,
  DetailsTabContent,
  ShowOnMapButton,
  StyledDetailsActions,
} from "../../../../share/ResultDetails/DetailsScroll/DetailsScroll";
import useMobileScreen from "../../../../hooks/useMobileScreen";

const USAIDMapDetails = () => {
  const { usaid_company } = useTypedSelector((state) => state.mapUSAIDInfo);
  const { viewport } = useTypedSelector((state) => state.mapInterface);
  const { setIsDetailsActive, setCurrentMarker, setViewport, setMapUSAID } =
    useActions();
  const [activeTab, setActiveTab] = useState("details");
  const { isDetailsActive } = useTypedSelector((state) => state.mapInterface);

  const { removeQueryParam } = useSearchParams();
  const onCloseDetails = () => {
    setIsDetailsActive(false);
    setCurrentMarker({ lat: 0, lon: 0 });
    setViewport({
      ...viewport,
      latitude: Number(usaid_company.lat),
      longitude: Number(usaid_company.lon),
      zoom: 12,
    });
    removeQueryParam("hash_id");
    setMapUSAID({ usaid_company: {} } as ICurrentUSAID);
  };

  const { t: tDetails } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.objectDetails",
  });
  const title = usaid_company && usaid_company?.name;

  const isMobile = useMobileScreen();

  return (
    <AnimatedDetailsContainer isShown={isDetailsActive}>
      <DetailsHeader
        title={title}
        description={usaid_company && usaid_company?.address}
        closeDetails={onCloseDetails}
        logo={
          usaid_company &&
          usaid_company?.entity_type &&
          usaid_company.entity_type === "Юридична особа" ? (
            <img src={JpBlue} alt="" />
          ) : (
            <img src={NpGreen} alt="" />
          )
        }
      />
      <DetailsContainer>
        <TabsWrapper>
          <Tab
            isActive={activeTab === "details"}
            onClick={() => setActiveTab("details")}
            text={tDetails("details.title")}
          />
        </TabsWrapper>
        <DetailsTabContent>
          {activeTab === "details" ? (
            <Details key={usaid_company && usaid_company.name} />
          ) : (
            <Details key={usaid_company && usaid_company.name} />
          )}
        </DetailsTabContent>
        {isMobile && (
          <StyledDetailsActions>
            <ShowOnMapButton
              lat={usaid_company?.lon}
              lon={usaid_company?.lat}
            />
          </StyledDetailsActions>
        )}
      </DetailsContainer>
    </AnimatedDetailsContainer>
  );
};

export default USAIDMapDetails;
