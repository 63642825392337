import { FilterIdsEnum } from "../store/reducers/_maps/main/mapFilters/types";
import { useTypedSelector } from "./useTypedSelector";

const useIsFilterActive = (filterId: FilterIdsEnum) => {
  const activeFilterId = useTypedSelector(
    (state) => state.mapFilters.activeFilterId
  );
  return activeFilterId === filterId;
};

export default useIsFilterActive;
