import { suggestionTypes } from "./../constants/constants";
import { icons } from "../constants";
import { availableDatasetsRegionMap } from "../constants/mapConstants";
import { IDatasetType, IRegion } from "../models/map.interface";
import mapboxgl from "mapbox-gl";

export function getIsRegionAvailable(
  region: IRegion,
  selectedDataset: IDatasetType | null = null
) {
  if (!selectedDataset) return false;
  return availableDatasetsRegionMap[region as IRegion].includes(
    selectedDataset
  );
}

export function changeMapboxLanguage(map: mapboxgl.Map, language: string) {
  const availableLanguages = ["en", "fr"];
  language = availableLanguages.includes(language) ? language : "en";
  map.getStyle().layers?.forEach((layer) => {
    if (layer.type === "symbol" && layer.layout && layer.layout["text-field"]) {
      map.setLayoutProperty(
        layer.id,
        "text-field",
        language ? `{name_${language}}` : ""
      );
    }
  });
}

export function addPinIconToMapbox(map: any) {
  map.loadImage(
    "https://res.cloudinary.com/dnmn1wkxk/image/upload/v1706602078/pin-icon_vtmds5.png",
    (error: any, image: any) => {
      if (error) throw error;
      if (!map.hasImage("pin-icon")) {
        map.addImage("pin-icon", image);
      }
    }
  );
  map.loadImage(
    "https://res.cloudinary.com/dnmn1wkxk/image/upload/v1707392642/pin-point-mobile-2_wanfyy.png",
    (error: any, image: any) => {
      if (error) throw error;
      if (!map.hasImage("pin-icon-mobile")) {
        map.addImage("pin-icon-mobile", image);
      }
    }
  );
  // add seen icons
  map.loadImage(
    "https://res.cloudinary.com/dnmn1wkxk/image/upload/v1717761894/seen-iconpng_awumpg.png",
    (error: any, image: any) => {
      if (error) throw error;
      if (!map.hasImage("seen-icon10")) {
        map.addImage("seen-icon10", image);
      }
    }
  );
}

export function prepareMapbox(map: any, selectedLanguage: string) {
  if (map) {
    changeMapboxLanguage(map, selectedLanguage);
    addPinIconToMapbox(map);
  }
}

export function getSuggestionImage(suggestionType) {
  const suggestionImages = {
    [suggestionTypes.region]: icons.Region,
    [suggestionTypes.name]: icons.Jp,
    [suggestionTypes.dkbs]: icons.Jp,
    [suggestionTypes.kved]: icons.Kved,
    [suggestionTypes.jp]: icons.Jp,
    [suggestionTypes.np]: icons.Np,
  };
  const imgSrc = suggestionImages[suggestionType];
  if (imgSrc) {
    return imgSrc;
  } else {
    return icons.Kved;
  }
}

export function isObjectSeen(
  hash_id: string | number | undefined,
  dataset: IDatasetType
): boolean {
  if (!hash_id) return false;
  hash_id = hash_id.toString();
  const seenObjects = JSON.parse(
    sessionStorage.getItem(`seenObjects.${dataset}`) || "[]"
  );
  return seenObjects.includes(hash_id);
}

export function addObjectToSeen(
  hash_id: string | number,
  dataset: IDatasetType
) {
  hash_id = hash_id.toString();
  const seenObjects = JSON.parse(
    sessionStorage.getItem(`seenObjects.${dataset}`) || "[]"
  );
  if (!seenObjects.includes(hash_id)) {
    seenObjects.push(hash_id);
    sessionStorage.setItem(
      `seenObjects.${dataset}`,
      JSON.stringify(seenObjects)
    );
  }
}

export function getSeenArray(dataset: IDatasetType): string[] {
  const string = sessionStorage.getItem(`seenObjects.${dataset}`) || "[]";
  return JSON.parse(string);
}

const mapInterfaceUtil = {
  getIsRegionAvailable,
  changeMapboxLanguage,
  addPinIconToMapbox,
  prepareMapbox,
  getSuggestionImage,
  isObjectSeen,
  addObjectToSeen,
  getSeenArray,
};

export default mapInterfaceUtil;
