import styled from "@emotion/styled";
import { FC } from "react";
import { Close } from "../../../../constants/icons";

const StyledHeader = styled.div`
  background-color: var(--sixth-black-color);
  border-bottom: 1px solid var(--fifth-black-color);
`;

const StyledHeaderRow = styled.div`
  display: flex;
  justify-content: flex-end;
  min-height: 32px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledTitle = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.0022em;
  color: var(--secondary-black-color);
  margin-bottom: 4px;
`;

const StyledButton = styled.button`
  width: 32px;
  height: 32px;
  background: var(--primary-white-color);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin-left: auto;
`;

interface IDetailsHeader {
  title?: string;
  closeDetails: () => void;
}

const PinnedObjectsHeader: FC<IDetailsHeader> = ({
  title = "",
  closeDetails,
}) => {
  return (
    <StyledHeader>
      <StyledHeaderRow>
        {<StyledTitle>{title}</StyledTitle>}
        <StyledButton onClick={closeDetails}>
          <img src={Close} alt="close details" />
        </StyledButton>
      </StyledHeaderRow>
    </StyledHeader>
  );
};

export default PinnedObjectsHeader;
