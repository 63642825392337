import { Col, Form, Row, Select } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { AvailableCC } from "../../../../constants/constants";
import {
  FormContainer,
  FormSection,
} from "../../../../share/formComponents/formComponents";
import CustomButton from "../../../../share/Button/Button";
import useMobileProfile from "../useMobileProfile";
const { Option } = Select;

const LanguageScreen: FC = () => {
  const { t: tBaseInfo } = useTranslation("translation", {
    keyPrefix: "profilePage.baseInfoForm",
  });
  const { t: tLang } = useTranslation("translation", {
    keyPrefix: "config.language",
  });

  const {
    contextHolder,
    form,
    handleSubmit,
    setHasFormChanged,
    formInitialValues,
    hasFormChanged,
  } = useMobileProfile();

  return (
    <FormContainer>
      {contextHolder}
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onValuesChange={() => {
          setHasFormChanged(true);
        }}
        initialValues={formInitialValues}
        style={{ width: "100%" }}
      >
        <FormSection headerText={tBaseInfo("appLanguageTitle")}>
          <Row gutter={24}>
            <Col span={24} xs={24}>
              <Form.Item name="language" label={tBaseInfo("languageLabel")}>
                <Select>
                  <Option value={AvailableCC.ua}>{tLang("ukrainian")}</Option>
                  <Option value={AvailableCC.en}>{tLang("english")}</Option>
                  <Option value={AvailableCC.fr}>{tLang("french")}</Option>
                  <Option value={AvailableCC.pl}>{tLang("polish")}</Option>
                  <Option value={AvailableCC.no}>{tLang("norwegian")}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
        <CustomButton
          size={"medium"}
          htmlType="submit"
          disabled={!hasFormChanged}
        >
          {tBaseInfo("submit")}
        </CustomButton>
      </Form>
    </FormContainer>
  );
};

export default LanguageScreen;
