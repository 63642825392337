import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { store } from "../index";
import { AuthActionCreators } from "../reducers/auth/action-creators";
import { apiUrl } from "../../constants/constants";

export class HttpClient {
  axios: AxiosInstance;
  private readonly URL = apiUrl;
  private readonly whiteList: string[] = [
    `${this.URL}auth/users/`,
    `${this.URL}users/activation/`,
    `${this.URL}auth/jwt/create/`,
    `${this.URL}users/registration_details/`,
  ];

  constructor() {
    this.axios = axios.create({
      baseURL: this.URL,
    });
    this.axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        if (config.url && this.whiteList.includes(config.url)) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error.response) {
          if (
            error.response.status === 401 &&
            error.config &&
            !error.config._isRetry
          ) {
            originalRequest._isRetry = true;
            try {
              const response = await axios.post(
                `${this.URL}auth/jwt/refresh/`,
                { refresh: store.getState().auth.token.refresh }
              );
              if (response.status === 401) {
                store.dispatch(AuthActionCreators.logout());
              }
              if (response.status === 200) {
                localStorage.setItem("token", response.data.access);
                store.dispatch(
                  AuthActionCreators.setAccess(response.data.access)
                );
                return await this.axios.request(originalRequest);
              }
            } catch (error) {
              store.dispatch(AuthActionCreators.logout());
              return Promise.reject(error);
            }
          }
        }
        return Promise.reject(error);
      }
    );
  }

  appendAuthorization = async (config?: AxiosRequestConfig) => {
    const conf = config ?? {};
    const token = localStorage.getItem("token");
    if (!token) {
      const newConfWithoutAuthorization = {
        ...conf,
        headers: { ...conf.headers, Authorization: "" },
      };
      return newConfWithoutAuthorization;
    }
    if (!conf.headers) {
      conf.headers = {};
    }
    conf.headers["Authorization"] = `Bearer ${token}`;
    return conf;
  };

  get = async <T = any>(uri: string, conf?: AxiosRequestConfig) =>
    this.axios.get<T>(uri, await this.appendAuthorization(conf));

  post = async <T = any>(uri: string, data: any, conf?: AxiosRequestConfig) => {
    return this.axios.post<T>(uri, data, await this.appendAuthorization(conf));
  };

  put = async <T = any>(uri: string, data: any, conf?: AxiosRequestConfig) => {
    return this.axios.put<T>(uri, data, await this.appendAuthorization(conf));
  };

  patch = async <T = any>(
    uri: string,
    data: any,
    conf?: AxiosRequestConfig
  ) => {
    return this.axios.patch<T>(uri, data, await this.appendAuthorization(conf));
  };

  delete = async (uri: string, conf?: AxiosRequestConfig) =>
    this.axios.delete(uri, await this.appendAuthorization(conf));

  createCancelTokenSrc = () => axios.CancelToken.source();
}

export const httpClient = new HttpClient();
