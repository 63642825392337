import { IUser } from "../../../models/Accounts";

export enum CurrentUserActionsEnum {
  GET_USER = "GET_CURRENT_USER",
  SET_USER = "SET_CURRENT_USER",
  PATCH_USER = "PATCH_CURRENT_USER",
  SET_IS_USER_LOADING = "SET_IS_USER_LOADING",
  SET_ERROR = "SET_ERROR_CURRENT_USER",
}

export interface GetCurrentUser {
  type: CurrentUserActionsEnum.GET_USER;
  payload: Partial<IUser>;
}

export interface SetCurrentUser {
  type: CurrentUserActionsEnum.SET_USER;
  payload: Partial<IUser>;
}
export interface PatchCurrentUserAction {
  type: CurrentUserActionsEnum.PATCH_USER;
  payload: Partial<IUser>;
}

export interface setIsLoadingActionUser {
  type: CurrentUserActionsEnum.SET_IS_USER_LOADING;
  payload: boolean;
}
export interface setError {
  type: CurrentUserActionsEnum.SET_ERROR;
  payload: string;
}
export type CurrentUserActions =
  | GetCurrentUser
  | SetCurrentUser
  | PatchCurrentUserAction
  | setIsLoadingActionUser
  | setError;
