import React, { useEffect, useState } from "react";
import styles from "./AccountUsers.module.scss";
import InfoCircle from "../../../assets/svg/infoCircle.svg";
import Search from "../../../assets/svg/searchBlack.svg";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { InvitedUsers, IUsers } from "../../../models/Accounts";
import ProfileAvatar from "../../../assets/images/Profile image.jpg";
import EmptyAvatar from "../../../assets/svg/EmptyAvatar.svg";
import RockHand from "../../../assets/svg/RockHand.svg";
import Close from "../../../assets/svg/Close_small.svg";
import MenuButton from "../../../assets/svg/Button_menu_grey.svg";
import { CustomInput } from "../../../share/Input/Input";
import UserProfileBar from "../../../components/UserProfileBar/UserProfileBar";
import { RouteNames } from "../../../routes";
import { useNavigate } from "react-router-dom";
import { useActions } from "../../../hooks/useActions";
import { parseISO } from "date-fns";
import { ClickAwayListener } from "@mui/base";
import AccountUsersRoleModal from "../../../components/_auth/AccountUsers/AccountUsersRoleModal/AccountUsersRoleModal";
import { store } from "../../../store";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../../constants";
import UserDetails from "../../../components/UserDetails/UserDetails";
import SimplePage from "../../../share/Page/SimplePage";
import useFormatDistanceToNowLocalized from "../../../hooks/useDateAgoLocale";
import classNames from "classnames";

const LastActivity = (value: any) => {
  const timestamp = parseISO("2024-05-17T21:08:27.288675+03:00");
  const formattedDate = useFormatDistanceToNowLocalized(timestamp);

  return <>{formattedDate}</>;
};

const AccountUsers = () => {
  const isTablet = useMediaQuery({
    query: `(max-width: ${breakpoints.medium}px)`,
  });
  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.small}px)`,
  });
  const isLaptop = useMediaQuery({
    query: `(max-width: ${1399}px)`,
  });

  const { users, subscribe, invites } = useTypedSelector(
    (state) => state.currentAccount
  );
  const { usersInvitedSend } = useTypedSelector(
    (state) => state.accountUtility
  );
  const { t: tUser } = useTranslation("translation", {
    keyPrefix: "accountUsersPage",
  });
  const [isUserDetailsShown, setIsDetailsShown] = useState<boolean>(false);
  const [currentUserId, setCurrentUserId] = useState<number>(-1);
  const [isPending, setIsPending] = useState<boolean>(false);

  const {
    setUsersRequested,
    deleteUserFromAccount,
    getRoles,
    getCurrentAccount,
  } = useActions();
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [showMenuUsers, setShowMenuUsers] = useState(false);
  const [userDeleted, setUserDeleted] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [userId, setUserId] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    getRoles();

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getCurrentAccount(store.getState()?.currentAccount?.id);
    // eslint-disable-next-line
  }, [userDeleted]);
  const usersFilter = users?.filter((user: IUsers) =>
    user.user.full_name.toLowerCase().includes(value.toLowerCase())
  );
  const invitesFilter = invites?.filter((user: InvitedUsers) =>
    user.user_email.toLowerCase().includes(value.toLowerCase())
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleDelete = (id: number) => {
    deleteUserFromAccount(id);
    const account_id = store.getState()?.currentAccount?.id;
    getCurrentAccount(account_id);
    setShowMenuUsers(false);
    setUserDeleted(!userDeleted);
  };

  const handleOpen = (user_id: number) => {
    setShowRoleModal(true);
    setShowMenuUsers(false);
  };

  const handleToggleDetails = () => {
    setIsDetailsShown((state) => !state);
    setShowMenuUsers(false);
  };

  const handleShowDetailsClick = () => {
    setIsDetailsShown((state) => {
      if (userId === currentUserId) {
        return !state;
      } else {
        return true;
      }
    });
    setShowMenuUsers(false);
    setCurrentUserId(userId);
  };

  const handleUser = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    user_id: number
  ) => {
    setIsPending(invitesFilter.map((user) => user.id).includes(user_id));
    setUserId(user_id);
    const newPosition = {
      x: isMobile ? event.pageX - 220 : event.pageX - 240,
      y: event.pageY,
    };
    setPosition(newPosition);
    setShowMenuUsers(!showMenuUsers);
  };

  const toggleDetailsText = (() => {
    if (userId === currentUserId) {
      return !isUserDetailsShown
        ? tUser("toggleDetailsText.show")
        : tUser("toggleDetailsText.hide");
    } else {
      return tUser("toggleDetailsText.show");
    }
  })();

  return (
    <SimplePage title={tUser("usersText")}>
      {!isMobile && <UserProfileBar />}
      <div className={styles.mainWrapper}>
        {isMobile && (
          <span className={styles.usersCount}>
            {tUser("usersText")} ({users.length}/10)
          </span>
        )}
        {!isMobile && (
          <div className={styles.filters}>
            <div className={styles.search}>
              <label className={styles.searchLabel} htmlFor="search">
                {tUser("searchByUserText")}
                <CustomInput
                  name="search"
                  type="text"
                  inputClassName={styles.inputSearch}
                  onChange={(e) => handleChange(e)}
                >
                  <img src={Search} alt="search" />
                </CustomInput>
              </label>
            </div>
            {!isTablet && (
              <button
                onClick={() => navigate(RouteNames.ADDING_USER_TO_ACCOUNT)}
                className={
                  subscribe?.permissions.max_users === 1
                    ? styles.disabledUserButton
                    : styles.addUserButton
                }
                type="button"
                disabled={subscribe?.permissions.max_users === 1}
              >
                {tUser("inviteUsersText")}
              </button>
            )}
          </div>
        )}
        <div className={styles.tableWrap}>
          <table className={styles.table}>
            <thead className={styles.tableHead}>
              <tr className={styles.tableHeadRow}>
                <th>
                  <div className={styles.headCellWrap}>
                    {tUser("usersText")} ({users?.length + invites?.length}/
                    {subscribe?.permissions.max_users})
                    <img src={InfoCircle} alt="Filter arrow" />
                  </div>
                </th>
                {!(isUserDetailsShown && isLaptop) && (
                  <>
                    <th>{tUser("lastActivityText")}</th>
                    <th>{tUser("statusText")}</th>
                  </>
                )}
                <th></th>
              </tr>
            </thead>
            <tbody className={styles.tableBody}>
              {usersFilter?.map((user: IUsers) => (
                <tr
                  key={user.user.id}
                  className={classNames(styles.tableBodyRow, {
                    [styles.selected]:
                      user.user.id === currentUserId && isUserDetailsShown,
                  })}
                >
                  <td>
                    <div className={styles.personCellWrap}>
                      <div className={styles.profileImgContainer}>
                        <img src={ProfileAvatar} alt="" />
                      </div>
                      <div className={styles.profileInfoContainer}>
                        <p className={styles.profileInfoWrap}>
                          {user.user.full_name}
                          {user.role.name === "Admin" && (
                            <span className={styles.admin}>
                              {tUser("adminText")}
                            </span>
                          )}
                        </p>
                        <span>{user.user.email}</span>
                      </div>
                    </div>
                  </td>
                  {!(isUserDetailsShown && isLaptop) && (
                    <>
                      <td className={styles.lastActive}>
                        {user?.user && user?.user?.last_login === null ? (
                          "-"
                        ) : (
                          <LastActivity value={user.user.last_login} />
                        )}
                      </td>
                      <td className={styles.status}>
                        {tUser("accAccessText")}
                      </td>
                    </>
                  )}
                  <td className={styles.menuButton}>
                    <img
                      onClick={(e) => handleUser(e, user.user.id)}
                      src={MenuButton}
                      alt=""
                    />
                  </td>
                </tr>
              ))}
              {invitesFilter?.map((user: InvitedUsers) => (
                <tr
                  key={user.user_email}
                  className={classNames(styles.tableBodyRow, {
                    [styles.selected]:
                      user.id === currentUserId && isUserDetailsShown,
                  })}
                >
                  <td>
                    <div className={styles.personCellWrap}>
                      <div className={styles.profileImgContainer}>
                        <img src={EmptyAvatar} alt="" />
                      </div>
                      <div className={styles.profileInfoContainer}>
                        <p>
                          {user?.user === null ? "-" : user?.user.full_name}
                        </p>
                        <span>{user?.user_email}</span>
                      </div>
                    </div>
                  </td>
                  {!(isUserDetailsShown && isLaptop) && (
                    <>
                      <td className={styles.lastActive}>---</td>
                      <td className={styles.status}>
                        {tUser("requestSentText")}
                      </td>
                    </>
                  )}
                  <td className={styles.menuButton}>
                    <img
                      onClick={(e) => handleUser(e, user.id)}
                      src={MenuButton}
                      alt=""
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {isUserDetailsShown && currentUserId > -1 && (
            <UserDetails
              handleOpen={handleOpen}
              onClose={handleToggleDetails}
              userId={currentUserId}
            />
          )}
        </div>
        {isTablet && (
          <div className={styles.addUserButtonWrap}>
            <button
              onClick={() => navigate(RouteNames.ADDING_USER_TO_ACCOUNT)}
              className={
                subscribe?.permissions.max_users === 1
                  ? styles.disabledUserButton
                  : styles.addUserButton
              }
              type="button"
              disabled={subscribe?.permissions.max_users === 1}
            >
              {tUser("inviteUsersText")}
            </button>
          </div>
        )}
        {showMenuUsers && (
          <ClickAwayListener onClickAway={() => setShowMenuUsers(false)}>
            <div
              className={styles.menu}
              style={{ top: position.y, left: position.x }}
            >
              {isPending ? (
                <>
                  <button
                    className={styles.setAdminButton}
                    type="button"
                    onClick={() => handleOpen(userId)}
                  >
                    {tUser("makeAnAdminText")}
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => handleOpen(userId)}
                    className={styles.setAdminButton}
                    type="button"
                  >
                    {tUser("changeRoleText")}
                  </button>
                </>
              )}
              <button
                onClick={handleShowDetailsClick}
                className={styles.setAdminButton}
                type="button"
              >
                {toggleDetailsText}
              </button>
              <button
                className={styles.deleteButton}
                onClick={() => handleDelete(userId)}
                type="button"
              >
                {tUser("deleteText")}
              </button>
            </div>
          </ClickAwayListener>
        )}
        <AccountUsersRoleModal
          user_id={userId}
          modalVisible={showRoleModal}
          setModalVisible={setShowRoleModal}
          setShowMenuUsers={setShowMenuUsers}
        />
      </div>
      {usersInvitedSend && (
        <div className={styles.addedUserInfo}>
          <img src={RockHand} alt="" />
          <div className={styles.textContainer}>
            <h5>{tUser("usersAdded.title")}</h5>
            <p>{tUser("usersAdded.subtitle")}</p>
          </div>
          <img
            onClick={() => setUsersRequested(false)}
            src={Close}
            alt="close"
          />
        </div>
      )}
    </SimplePage>
  );
};

export default AccountUsers;
