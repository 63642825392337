import styled from "@emotion/styled";
import { colors, icons } from "../../constants";
import useFormatDistanceToNowLocalized from "../../hooks/useDateAgoLocale";

type NotificationProps = {
  key?: any;
  type?: "success" | "info" | "warning" | "error" | "open" | string;
  message: string;
  description?: any;
  timeStamp?: number;
};

const StyledNotification = styled.div`
  align-items: flex-start;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  gap: 12px;
`;

const StyledDate = styled.div`
  font-size: 12px;
  color: #999;
  margin-top: 8px;
  justify-content: flex-end;
`;

const StyledTitle = styled.div`
  color: ${colors.darkD400};
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.026px;
`;

const StyledDescription = styled.p`
  color: ${colors.darkD400};
  /* Body 3/Regular 14 */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  a {
    color: ${colors.redR400};
    text-decoration: None;
    /* Subhead 3/Semibold 14 */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 128.571% */
    letter-spacing: 0.017px;
  }
`;

const renderIcon = (type: string) => {
  switch (type) {
    case "success":
      return <img src={icons.Success} alt="" />;
    case "info":
      return <img src={icons.Info} alt="" />;
    case "warning":
      return <img src={icons.Warning} alt="" />;
    case "error":
      return <img src={icons.Warning} alt="" />;
    case "open":
      //  return  bell
      return <img src={icons.Info} alt="" />;
    default:
      return <img src={icons.Info} alt="" />;
  }
};

const StyledNotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const Notification = ({
  type = "open",
  message,
  description,
  timeStamp,
}: NotificationProps) => {
  const localizedDateFromNow = useFormatDistanceToNowLocalized(
    new Date(timeStamp)
  );

  return (
    <StyledNotification>
      {renderIcon(type)}
      <StyledNotificationContent>
        <StyledTitle>{message}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
        <StyledDate>{localizedDateFromNow} </StyledDate>
      </StyledNotificationContent>
    </StyledNotification>
  );
};

export default Notification;
