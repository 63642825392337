import { DownloadActionCreators } from "./download/action-creators";
import { TemplatesActionCreators } from "./templates/action-creator";
import { USAIDMapObjectActionCreators } from "./_maps/usaid/usaidMap/action-creators";
import { SikaMapObjectActionCreators } from "./_maps/sika/sikaDesigner/action-creators";
import { PersonsMapObjectActionCreators } from "./_maps/persons/person/action-creators";
import { CompanyMapObjectActionCreators } from "./_maps/company/company/action-creators";
import { USAIDFilterActionCreators } from "./_maps/usaid/usaidMapFilters/action-creators";
import { AuthActionCreators } from "./auth/action-creators";
import { MainMapObjectActionCreators } from "./_maps/main/map/action-creators";
import { AccountActionCreators } from "./accounts/action-creators";
import { MapInterfaceActionCreator } from "./_maps/mapInterface/action.creators";
import { MapFilterActionCreators } from "./_maps/main/mapFilters/action-creators";
import { CompanyFilterActionCreators } from "./_maps/company/companyFilters/action-creators";
import { PersonFilterActionCreators } from "./_maps/persons/personFilters/action-creators";
import { SikaDesignerFilterActionCreators } from "./_maps/sika/sikaDesignersFilter/action-creators";
import CurrentUserActionCreator from "./currentUser/action-creator";
import { FeedbackApiActionCreators } from "./feedback/action-creator";

export const allActionCreators = {
  ...AuthActionCreators,
  ...CurrentUserActionCreator,
  ...AccountActionCreators,

  ...MapInterfaceActionCreator,

  ...MapFilterActionCreators,
  ...CompanyFilterActionCreators,
  ...PersonFilterActionCreators,
  ...SikaDesignerFilterActionCreators,
  ...USAIDFilterActionCreators,

  ...MainMapObjectActionCreators,
  ...CompanyMapObjectActionCreators,
  ...PersonsMapObjectActionCreators,
  ...SikaMapObjectActionCreators,
  ...USAIDMapObjectActionCreators,
  ...TemplatesActionCreators,
  ...DownloadActionCreators,
  ...FeedbackApiActionCreators,
};
