import { uniq } from "lodash";
import {
  MapFilterActionsEnum,
  MapFiltersAction,
  MapUsaidFiltersState,
} from "./types";
import { v4 as uuidv4 } from "uuid";

const initialState: MapUsaidFiltersState = {
  isSuggestionsLoading: false,
  selectedDocList: [{ id: uuidv4(), value: "1" }],
  kved: [],
  suggestions: [],
  activeSuggestions: [],
  region: [],
  edrpou: [],
  economic_sector_array: [],
  activity_status_array: [],
  entity_type_array: [],
  category_array: [],
  lat_border__range: undefined,
  lon_border__range: undefined,
  activity_status_2022: undefined,
  dk_018_2000: [],
};

export default function mapFiltersReducer(
  state = initialState,
  action: MapFiltersAction
) {
  switch (action.type) {
    case MapFilterActionsEnum.SET_SUGGESTION_LOADING:
      return { ...state, isSuggestionsLoading: action.payload };
    case MapFilterActionsEnum.SET_USAID_ECONOMIC_SECTOR:
      if (state.economic_sector_array.includes(action.payload)) {
        return {
          ...state,
          economic_sector_array: state.economic_sector_array.filter(
            (item) => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          economic_sector_array: [
            ...state.economic_sector_array,
            action.payload,
          ],
        };
      }
    case MapFilterActionsEnum.CLEAN_USAID_ECONOMIC_SECTOR:
      return { ...state, economic_sector_array: [] };
    case MapFilterActionsEnum.SET_USAID_ENTITY_TYPE:
      if (state.entity_type_array.includes(action.payload)) {
        return {
          ...state,
          entity_type_array: state.entity_type_array.filter(
            (item) => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          entity_type_array: [...state.entity_type_array, action.payload],
        };
      }
    case MapFilterActionsEnum.CLEAN_USAID_ENTITY_TYPE:
      return { ...state, entity_type_array: [] };
    case MapFilterActionsEnum.SET_USAID_CATEGORY:
      if (state.category_array.includes(action.payload)) {
        return {
          ...state,
          category_array: state.category_array.filter(
            (item) => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          category_array: [...state.category_array, action.payload],
        };
      }
    case MapFilterActionsEnum.CLEAN_USAID_CATEGORY:
      return { ...state, category_array: [] };
    case MapFilterActionsEnum.SET_USAID_ACTIVITY_STATUS:
      if (state.activity_status_array.includes(action.payload)) {
        return {
          ...state,
          activity_status_array: state.activity_status_array.filter(
            (item) => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          activity_status_array: [
            ...state.activity_status_array,
            action.payload,
          ],
        };
      }
    case MapFilterActionsEnum.CLEAN_USAID_ACTIVITY_STATUS:
      return { ...state, activity_status_array: [] };
    case MapFilterActionsEnum.SET_USAID_SUGGESTION:
      return { ...state, suggestions: [...action.payload] };
    case MapFilterActionsEnum.SET_USAID_ACTIVE_SUGGESTION:
      const suggestions = [...state.activeSuggestions, action.payload];
      const sudCodes = suggestions.map((item) => item.code);
      const uniqueSudCodes = uniq(sudCodes);
      const uniqSuggestions = uniqueSudCodes.map((item) =>
        suggestions.find((sud) => sud.code === item)
      );

      return {
        ...state,
        activeSuggestions: uniqSuggestions,
      };
    case MapFilterActionsEnum.CLEAN_USAID_SUGGESTION:
      return { ...state, activeSuggestions: [] };
    case MapFilterActionsEnum.DELETE_USAID_ACTIVE_SUGGESTION:
      return {
        ...state,
        activeSuggestions: state.activeSuggestions.filter(
          (item) => item !== action.payload
        ),
      };
    case MapFilterActionsEnum.SET_USAID_REGION:
      return { ...state, region: [...state.region, action.payload] };
    case MapFilterActionsEnum.CLEAN_USAID_REGION:
      return { ...state, region: [] };
    case MapFilterActionsEnum.DELETE_USAID_REGION:
      return {
        ...state,
        region: state.region.filter((item) => item !== action.payload),
      };
    case MapFilterActionsEnum.SET_USAID_EDR_CODE:
      return { ...state, edrpou: [...state.edrpou, action.payload] };
    case MapFilterActionsEnum.CLEAN_USAID_EDR_CODE:
      return { ...state, edrpou: [] };
    case MapFilterActionsEnum.DELETE_USAID_EDR_CODE:
      return {
        ...state,
        edrpou: state.edrpou.filter((item) => item !== action.payload),
      };
    case MapFilterActionsEnum.SET_USAID_KVED:
      return { ...state, kved: [...state.kved, action.payload] };
    case MapFilterActionsEnum.CLEAN_USAID_KVED:
      return { ...state, kved: [] };
    case MapFilterActionsEnum.DELETE_USAID_KVED:
      return {
        ...state,
        kved: state.kved.filter((item) => item !== action.payload),
      };

    default:
      return state;
  }
}
