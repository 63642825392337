import {
  MapObjectAction,
  IMapUSAIDInfoState,
  SikaMapActionsEnum,
} from "./types";

const initialState: IMapUSAIDInfoState = {
  isObjectsLoading: false,
  params: {},
  hash_ids: [],
  usaid_company: null,
  count: 0,
  // list of usaid_company
  usaid_dataset: [],
  next: null,
};

export default function usaidReducer(
  state = initialState,
  action: MapObjectAction
) {
  switch (action.type) {
    case SikaMapActionsEnum.SET_IS_USAID_LOADING:
      return { ...state, isObjectsLoading: action.payload };
    case SikaMapActionsEnum.SET_MAP_USAID:
      return { ...state, ...action.payload };
    case SikaMapActionsEnum.SET_MAP_USAID_LIST:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
