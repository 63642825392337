import {
  IAccount,
  InvitedUsers,
  IRole,
  ISubTemplate,
} from "../../../models/Accounts";
import {
  AccountActionsEnum,
  GetRoles,
  SetAccountAction,
  SetAccountsAction,
  SetInvited,
  SetSubTemplate,
  SetSubType,
  SetUsersRequested,
} from "./types";
import { AppDispatch, store } from "../../index";
import { AuthActionCreators } from "../auth/action-creators";
import { userService } from "../../api/UserService";
import { UnregUserForm } from "../../../components/_componentsForInvitedUsers/UnregisteredUser/UnregisteredUser";

export const AccountActionCreators = {
  setAccounts: (accounts: IAccount[]): SetAccountsAction => ({
    type: AccountActionsEnum.SET_ACCOUNTS,
    payload: accounts,
  }),
  setAccount: (account: IAccount): SetAccountAction => ({
    type: AccountActionsEnum.SET_ACCOUNT,
    payload: account,
  }),
  setTemplates: (payload: ISubTemplate): SetSubTemplate => ({
    type: AccountActionsEnum.SET_SUBSCRIPTION_TEMPLATE,
    payload,
  }),
  setSubType: (payload: string): SetSubType => ({
    type: AccountActionsEnum.SET_SUB_TYPE,
    payload,
  }),
  setRoles: (payload: IRole[]): GetRoles => ({
    type: AccountActionsEnum.GET_ROLES,
    payload,
  }),
  setInvited: (payload: InvitedUsers[]): SetInvited => ({
    type: AccountActionsEnum.SET_INVITED,
    payload,
  }),
  setUsersRequested: (payload: boolean): SetUsersRequested => ({
    type: AccountActionsEnum.SET_USERS_REQUESTED,
    payload,
  }),
  getAccounts: () => async (dispatch: AppDispatch) => {
    try {
      dispatch(AuthActionCreators.setIsAuthLoading(true));
      const getAccResponse = await userService.fetchAccounts();
      if (getAccResponse) {
        dispatch(AccountActionCreators.setAccounts(getAccResponse.data));
      }
      dispatch(AuthActionCreators.setIsAuthLoading(false));
    } catch (e: string | any) {
      dispatch(AuthActionCreators.errorDispatch(e));
    }
  },
  getCurrentAccount: (id: number) => async (dispatch: AppDispatch) => {
    try {
      dispatch(AuthActionCreators.setIsAuthLoading(true));
      const getAccResponse = await userService.fetchCurrentAccounts(id);
      if (getAccResponse) {
        dispatch(AccountActionCreators.setAccount(getAccResponse.data));
      }
      dispatch(AuthActionCreators.setIsAuthLoading(false));
    } catch (e: string | any) {
      dispatch(AuthActionCreators.errorDispatch(e));
    }
  },
  setSubscribeTemplate: (sub_type: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(AuthActionCreators.setIsAuthLoading(true));
      dispatch(AccountActionCreators.setSubType(sub_type));
      const response = await userService.subscribeTemplate(sub_type);
      if (response) {
        dispatch(AccountActionCreators.setTemplates(response.data));
      }
      dispatch(AuthActionCreators.setIsAuthLoading(false));
      return true;
    } catch (e: string | any) {
      dispatch(AuthActionCreators.errorDispatch(e));
    }
  },
  setSubscribe:
    (subscribe_periodicity: string, region: any) =>
    async (dispatch: AppDispatch) => {
      const data = {
        account_id: store.getState()?.currentAccount?.id,
        subscribe_periodicity: subscribe_periodicity,
        sub_type: (store.getState()?.accountUtility as { sub_type?: string })
          ?.sub_type,
        region: [region],
        subscribe_date_start: new Date().toISOString(),
      };
      try {
        dispatch(AuthActionCreators.setIsAuthLoading(true));
        const response = await userService.setSubscribe(data);
        const newData = response.data;
        if (newData) {
          dispatch(AuthActionCreators.setIsAuth(true));
          window.location.href = `https://www.liqpay.ua/api/3/checkout?data=${newData.data}&signature=${newData.signature}`;
        }
        dispatch(AuthActionCreators.setIsAuthLoading(false));
        return true;
      } catch (e: string | any) {
        dispatch(AuthActionCreators.errorDispatch(e));
      }
    },
  unSubscribe: () => async (dispatch: AppDispatch) => {
    const { id } = store.getState().auth.user;
    try {
      dispatch(AuthActionCreators.setIsAuthLoading(true));
      const response = await userService.setUnsubscribe(id);
      if (response) {
        dispatch(AccountActionCreators.setAccount(response.data));
      }
      dispatch(AuthActionCreators.setIsAuthLoading(false));
    } catch (e: string | any) {
      dispatch(AuthActionCreators.errorDispatch(e));
    }
  },
  getRoles: () => async (dispatch: AppDispatch) => {
    try {
      dispatch(AuthActionCreators.setIsAuthLoading(true));
      const response = await userService.getRoles();
      if (response) {
        dispatch(AccountActionCreators.setRoles(response.data));
      }
      dispatch(AuthActionCreators.setIsAuthLoading(false));
    } catch (e: string | any) {
      dispatch(AuthActionCreators.errorDispatch(e));
    }
  },
  inviteUserToAccount:
    (role_id: number, user_emails: string[]) =>
    async (dispatch: AppDispatch) => {
      const data = {
        account_id: store.getState().currentAccount.id,
        role_id: role_id,
        user_emails: user_emails,
      };
      try {
        dispatch(AuthActionCreators.setIsAuthLoading(true));
        const response = await userService.inviteUsers(data);
        if (response) {
          dispatch(AccountActionCreators.setInvited(response.data));
          dispatch(AccountActionCreators.setUsersRequested(true));
        }
        dispatch(AuthActionCreators.setIsAuthLoading(false));
        return true;
      } catch (e: string | any) {
        dispatch(AuthActionCreators.errorDispatch(e));
      }
    },
  inviteForRegisteredUser:
    (uid: string | null, token: string | null) =>
    async (dispatch: AppDispatch) => {
      try {
        dispatch(AuthActionCreators.setIsAuthLoading(true));
        await userService.inviteRegisteredUser(uid, token);
        dispatch(AuthActionCreators.setIsAuthLoading(false));
        return true;
      } catch (e: string | any) {
        dispatch(AuthActionCreators.errorDispatch(e));
      }
    },
  inviteForUnregisteredUser:
    (uid: string | null, token: string | null, data: UnregUserForm) =>
    async (dispatch: AppDispatch) => {
      try {
        dispatch(AuthActionCreators.setIsAuthLoading(true));
        const response = await userService.inviteUnregisteredUser(
          uid,
          token,
          data
        );
        if (response) {
          localStorage.setItem("token", response.data.access);
          dispatch(AuthActionCreators.setUser(response.data.user));
          dispatch(AuthActionCreators.setToken(response.data));
          dispatch(AuthActionCreators.setIsAuth(true));
        }
        dispatch(AuthActionCreators.setIsAuthLoading(false));
        return true;
      } catch (e: string | any) {
        dispatch(AuthActionCreators.errorDispatch(e));
      }
    },
  deleteUserFromAccount: (user_id: number) => async (dispatch: AppDispatch) => {
    const account_id = store.getState()?.currentAccount?.id;
    try {
      dispatch(AuthActionCreators.setIsAuthLoading(true));
      await userService.deleteAccountUser(account_id, user_id);
      dispatch(AuthActionCreators.setIsAuthLoading(false));
    } catch (e: string | any) {
      dispatch(AuthActionCreators.errorDispatch(e));
    }
  },
  updateCurrentUserRole:
    (user_id: number, role_id: number) => async (dispatch: AppDispatch) => {
      const account_id = store.getState()?.currentAccount?.id;
      try {
        dispatch(AuthActionCreators.setIsAuthLoading(true));
        await userService.updateUserRole(account_id, user_id, +role_id);
        dispatch(AuthActionCreators.setIsAuthLoading(false));
      } catch (e: string | any) {
        dispatch(AuthActionCreators.errorDispatch(e));
      }
    },
};
