import { FC } from "react";
import styled from "@emotion/styled";

interface IProps {
  color?: string;
  size?: string;
  padding?: string;
  icon: any;
  onClick?: () => void;
}

const StyledIconContainer = styled.div<{
  color: string;
  size: string;
  padding: string;
}>`
  padding: ${(props) => props.padding};
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  & > svg {
    fill: ${(props) => props.color};
    height: 100%;
    width: 100%;
  }
`;

const CustomIcon: FC<IProps> = ({
  color = "#000",
  size = "24px",
  padding = "0",
  icon: Icon,
  onClick,
}) => {
  return (
    <StyledIconContainer
      onClick={onClick}
      color={color}
      size={size}
      padding={padding}
    >
      <Icon />
    </StyledIconContainer>
  );
};

export default CustomIcon;
