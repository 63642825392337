import styled from "@emotion/styled";
import { colors } from "../../../../constants";

const CustomMapPopupItem = styled.div<{ isSelected: boolean }>`
  background-color: #ffffff;
  padding: 8px;
  margin: 8px 0px;
  border-radius: 8px;
  border: 2px solid ${colors.white};
  //hover
  &:hover {
    border: 2px solid
      ${({ isSelected }) => (isSelected ? colors.redR400 : colors.darkD75)};
    cursor: pointer;
  }
  //isSelected -> border colors.redR400
  ${({ isSelected }) =>
    isSelected &&
    `
    border: 2px solid ${colors.redR400};
  `}
`;

export default CustomMapPopupItem;
