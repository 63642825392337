import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { CustomCheckbox } from "../../../../../share/Input/Input";
import { useTranslation } from "react-i18next";
import { FilterIdsEnum } from "../../../../../store/reducers/_maps/main/mapFilters/types";
import { FilterTitleWithInfo } from "../../../../../share/filters/FilterTitleWithInfo";
import { CheckboxList } from "../../../../../share/filters";
import FilterButtonWithModal from "../../../../../share/filters/FilterButtonWithModal";
import { regionFilterTranslationMap } from "../../../../../constants/filterTranslationMapping";
import useSearchParams from "../../../../../hooks/useSearchParams";
import { AvailableCC } from "../../../../../constants/constants";

const AreaFilter = () => {
  const filterId: FilterIdsEnum = "sikaRegionFilter";

  const { country_part_array } = useTypedSelector(
    (state) => state.sikaDesignerFilters
  );
  const { country_part } = useTypedSelector(
    (state) => state.mapSikaDesignerInfo.params
  );
  const {
    toggleSikaDesignerAreaFilterObject,
    typeSikaDesignerAreaClean,
    applyFilter,
  } = useActions();

  const handleChange = (type: string) => {
    toggleSikaDesignerAreaFilterObject(type);
  };

  const handleClean = () => {
    typeSikaDesignerAreaClean();
  };

  const handleClick = () => {
    applyFilter();
  };

  const { t: tRegion } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.sika.regionFilter",
  });

  const { t: tOption } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.sika.regionFilter.values",
  });

  const title = tRegion("title");
  const description = tRegion("description");
  const pinText = country_part_array.length > 0 && country_part_array.length;

  const isUaRegion =
    useSearchParams().getSearchParams().region === AvailableCC.ua;

  return (
    <FilterButtonWithModal
      title={title}
      description={description}
      filterId={filterId}
      pinText={pinText}
      onClear={handleClean}
      left={-200}
      width={380}
    >
      <FilterTitleWithInfo title={title} />
      <CheckboxList inRow>
        {country_part?.map((type: string) => (
          <CustomCheckbox
            value={type}
            key={type}
            checked={country_part_array.includes(type)}
            onChange={() => handleChange(type)}
            onClick={() => handleClick()}
          >
            {isUaRegion ? tOption(regionFilterTranslationMap[type]) : type}
          </CustomCheckbox>
        ))}
      </CheckboxList>
    </FilterButtonWithModal>
  );
};

export default AreaFilter;
