import styles from "../OtherFilters.module.scss";
import { CustomSelect } from "../../../../../../share/Input/Input";
import { v4 as uuidv4 } from "uuid";
import { useActions } from "../../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import { FilterSection } from "../../../../../../share/filters";
import { useTranslation } from "react-i18next";
import { FilterTitleWithInfo } from "../../../../../../share/filters/FilterTitleWithInfo";
import { IStageDocItem } from "../../../../../../models/MapObject";
import { documentationStageFilterTranslationMap } from "../../../../../../constants/filterTranslationMapping";
import useSearchParams from "../../../../../../hooks/useSearchParams";
import { AvailableCC } from "../../../../../../constants/constants";
import useStoreSnippets from "../../../../../../hooks/useStoreSnippets";
import LoginNotificationPopup from "../../../../../../share/NotificationPopInformation/LoginNotificationPopup/LoginNotificationPopup";
import DisabledDiv from "../../../../../../share/DisabledDiv/DisabledDiv";
import { useTipsContext } from "../../../../../../hooks/useTips";

interface IItem {
  id: string;
  value: string;
}

function sortIDocStageItemsByNumberInc(a: IStageDocItem, b: IStageDocItem) {
  return a.number - b.number;
}
const StageDocumentationFilter = () => {
  const {
    setStageFilterObject,
    deleteStageFilterObject,
    setSelectedDocList,
    setOffset,
    setPage,
  } = useActions();
  const { stage_documentation } = useTypedSelector(
    (state) => state.mapObjectsInfo.mapObjects.params
  );
  const { selectedDocList } = useTypedSelector((state) => state.mapFilters);
  const tipId = "loginToUseStageDocumentationFilter";
  const handleSelectItems = (index: number, value: string) => {
    selectedDocList[index].value = value;
    const setStageFilter = selectedDocList.map((el: IItem) => el.value);
    setStageFilterObject(setStageFilter);
    if (selectedDocList.length - 1 !== index) {
      setSelectedDocList([...selectedDocList]);
      return;
    }
    const newSelect = { id: uuidv4(), value: "1" };
    setSelectedDocList([...selectedDocList, newSelect]);
    setOffset(0);
    setPage(1);
  };

  const handleDeleteSelectItems = (item: IItem, index: number) => {
    setSelectedDocList(
      selectedDocList.slice(0, index).concat(selectedDocList.slice(index + 1))
    );
    deleteStageFilterObject(item.value);
  };
  const { t } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.aimap.otherFilter",
  });

  const { t: tOption } = useTranslation("translation", {
    keyPrefix:
      "mainPage.mapFilters.aimap.otherFilter.documentationStageFilterValues",
  });

  const { t: tMapFilters } = useTranslation("translation", {
    keyPrefix: "mapUI.filters",
  });
  const setLastOpenedTipId = useTipsContext().setLastOpenedTipId;

  const noFilterOptions = tMapFilters("noFilterOptions", "no filter options");
  const { isGuestAccount } = useStoreSnippets();

  const showLoginNotification = () => {
    if (isGuestAccount) {
      setLastOpenedTipId(tipId);
    }
  };
  const { t: tNotification } = useTranslation("translation", {
    keyPrefix: "notifications",
  });

  const isUaRegion =
    useSearchParams().getSearchParams().region === AvailableCC.ua;

  return (
    <FilterSection>
      <LoginNotificationPopup
        id={tipId}
        message={tNotification("loginToUseStageDocumentationFilter")}
        rightPx={-324}
        bottomPx={332}
        cornerTopPosition={88}
      />
      <DisabledDiv onClick={showLoginNotification} isDisabled={isGuestAccount}>
        <FilterTitleWithInfo title={t("documentationStage")} />
        <div className={styles.stageDocWrapper}>
          {selectedDocList?.length ? (
            selectedDocList?.map((item: IItem, index: number) => (
              <CustomSelect
                key={item.id}
                value={item.value}
                classSelectName={styles.select}
                name={`select ${index}`}
                onChange={(e) => handleSelectItems(index, e.target.value)}
                onClick={() => handleDeleteSelectItems(item, index)}
              >
                <option value="1" hidden disabled>
                  {t("chooseDocumentationStage")}
                </option>
                {stage_documentation
                  ?.sort(sortIDocStageItemsByNumberInc)
                  .map((option: IStageDocItem) => (
                    <option
                      disabled={selectedDocList.some(
                        (el: IItem) => el.value === option.value
                      )}
                      className={styles.option}
                      key={option.value}
                      value={option.value}
                    >
                      {option.number} -{" "}
                      {isUaRegion
                        ? tOption(
                            documentationStageFilterTranslationMap[option.value]
                          )
                        : option.value}
                    </option>
                  ))}
              </CustomSelect>
            ))
          ) : (
            <div>{noFilterOptions}</div>
          )}
        </div>
      </DisabledDiv>
    </FilterSection>
  );
};

export default StageDocumentationFilter;
