import {
  DeleteCompanyActiveSuggestion,
  DeleteCompanyEdrCode,
  DeleteCompanyRegion,
  MapFilterActionsEnum,
  SetCompanyActiveSuggestion,
  SetStanFilter,
  SetCleanStanFilter,
  SetCleanCompanyEdrCode,
  SetCompanyCleanRegion,
  SetCompanyCleanSuggestion,
  SetCompanyEdrCode,
  SetCompanyRegion,
  SetSelectedDocList,
  SetCompanySuggestion,
  SetDateAddedLTE,
  SetDateAddedGTE,
  SetImportRange,
  SetExportRange,
  SetCompanyKVED,
  SetCleanCompanyKVED,
  DeleteCompanyKVED,
  SetKvedIsMainAction,
  SetIncomeRange,
  SetIsSuggestionsLoadingAction,
} from "./types";
import { AppDispatch, RootState, store } from "../../../../index";
import { MapInterfaceActionCreator } from "../../mapInterface/action.creators";
import { ISearchRequest, ISearchResponse } from "../../../../../models/Search";
import { ISelectedDocList } from "../../../../../models/MapObject";
import { v4 as uuidv4 } from "uuid";
import { companyService } from "../../../../api/CompanyService";
import { MapFilterActionCreators } from "../../main/mapFilters/action-creators";
import { suggestionTypes } from "../../../../../constants/constants";

export const CompanyFilterActionCreators = {
  setCleanAllCompanyFilters: () => (dispatch: AppDispatch) => {
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
    dispatch(CompanyFilterActionCreators.cleanCompanySuggestions([]));
    dispatch(CompanyFilterActionCreators.cleanCompanyRegion([]));
    dispatch(CompanyFilterActionCreators.cleanCompanyKVED([]));
    dispatch(CompanyFilterActionCreators.setDateAddedLTE(""));
    dispatch(CompanyFilterActionCreators.setDateAddedGTE(""));
    dispatch(CompanyFilterActionCreators.setImportRange(""));
    dispatch(CompanyFilterActionCreators.setExportRange(""));
    dispatch(CompanyFilterActionCreators.setIncomeRange(""));
    dispatch(CompanyFilterActionCreators.setKvedIsMain(false));
    dispatch(MapFilterActionCreators.cleanKeyword([]));
    dispatch(CompanyFilterActionCreators.cleanCompanyEdrCode([]));
    dispatch(
      CompanyFilterActionCreators.setSelectedDocList([
        { id: uuidv4(), value: "1" },
      ])
    );
  },

  setIsSuggestionsLoading: (
    payload: boolean
  ): SetIsSuggestionsLoadingAction => ({
    type: MapFilterActionsEnum.SET_SUGGESTION_LOADING,
    payload,
  }),

  setKvedIsMain: (payload: boolean): SetKvedIsMainAction => ({
    type: MapFilterActionsEnum.SET_KVED_IS_MAIN,
    payload,
  }),

  setImportRange: (payload: string): SetImportRange => ({
    type: MapFilterActionsEnum.SET_IMPORT_RANGE,
    payload,
  }),
  setExportRange: (payload: string): SetExportRange => ({
    type: MapFilterActionsEnum.SET_EXPORT_RANGE,
    payload,
  }),
  setIncomeRange: (payload: string): SetIncomeRange => ({
    type: MapFilterActionsEnum.SET_INCOME_RANGE,
    payload,
  }),
  setDateAddedLTE: (payload: string): SetDateAddedLTE => ({
    type: MapFilterActionsEnum.SET_DATE_ADDED_LTE,
    payload,
  }),

  setDateAddedGTE: (payload: string): SetDateAddedGTE => ({
    type: MapFilterActionsEnum.SET_DATE_ADDED_GTE,
    payload,
  }),
  toggleStanFilterObject: (payload: string): SetStanFilter => ({
    type: MapFilterActionsEnum.TOGGLE_STAN_FILTER,
    payload,
  }),
  cleanStanFilter: (payload: []): SetCleanStanFilter => ({
    type: MapFilterActionsEnum.CLEAN_STAN_FILTER,
    payload,
  }),
  typeStanClean: () => (dispatch: AppDispatch) => {
    dispatch(CompanyFilterActionCreators.cleanStanFilter([]));
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },
  setSelectedDocList: (payload: ISelectedDocList[]): SetSelectedDocList => ({
    type: MapFilterActionsEnum.SET_DOC_LIST,
    payload,
  }),
  setCompanySuggestions: (
    payload: ISearchResponse[]
  ): SetCompanySuggestion => ({
    type: MapFilterActionsEnum.SET_COMPANY_SUGGESTION,
    payload: payload,
  }),
  addCompanyActiveSuggestion:
    (suggestion: ISearchResponse) => (dispatch: AppDispatch) => {
      const activeSuggestions =
        (store.getState() as RootState).companyFilters.activeSuggestions || [];
      if (activeSuggestions.includes(suggestion)) {
        return;
      }
      if (suggestion.type === suggestionTypes.region) {
        dispatch(CompanyFilterActionCreators.setCompanyRegion(suggestion.code));
      }
      if (suggestion.type === suggestionTypes.kved) {
        dispatch(CompanyFilterActionCreators.setCompanyKVED(suggestion.code));
      }
      if (suggestion.type === suggestionTypes.jp) {
        dispatch(
          CompanyFilterActionCreators.setCompanyEdrCode(suggestion.code)
        );
      }
      dispatch(
        CompanyFilterActionCreators.setCompanyActiveSuggestions(suggestion)
      );
    },
  getCompanySuggestions:
    (data: ISearchRequest) => async (dispatch: AppDispatch) => {
      try {
        dispatch(MapFilterActionCreators.setIsSuggestionsLoading(true));
        const response = await companyService.fetchSuggestions(data);
        if (response) {
          const suggestions = response.data;
          const activeSuggestions = (store.getState() as RootState).mapFilters
            .activeSuggestions;
          const suggestionsWithoutActiveSuggestions = suggestions.filter(
            (suggestion: ISearchResponse) =>
              !activeSuggestions.some(
                (activeSuggestion) => activeSuggestion.code === suggestion.code
              )
          );
          dispatch(
            CompanyFilterActionCreators.setCompanySuggestions(
              suggestionsWithoutActiveSuggestions
            )
          );
        }
        dispatch(MapFilterActionCreators.setIsSuggestionsLoading(false));
      } catch (e: string | any) {
        dispatch(
          MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
        );
      }
    },
  setCompanyActiveSuggestions: (
    payload: ISearchResponse
  ): SetCompanyActiveSuggestion => ({
    type: MapFilterActionsEnum.SET_COMPANY_ACTIVE_SUGGESTION,
    payload,
  }),
  cleanCompanySuggestions: (payload: []): SetCompanyCleanSuggestion => ({
    type: MapFilterActionsEnum.CLEAN_COMPANY_SUGGESTION,
    payload,
  }),
  deleteCompanyActiveSuggestion: (
    payload: ISearchResponse
  ): DeleteCompanyActiveSuggestion => ({
    type: MapFilterActionsEnum.DELETE_COMPANY_ACTIVE_SUGGESTION,
    payload,
  }),
  setCompanyRegion: (payload: string): SetCompanyRegion => ({
    type: MapFilterActionsEnum.SET_COMPANY_REGION,
    payload,
  }),
  cleanCompanyRegion: (payload: []): SetCompanyCleanRegion => ({
    type: MapFilterActionsEnum.CLEAN_COMPANY_REGION,
    payload,
  }),
  deleteCompanyRegion: (payload: string): DeleteCompanyRegion => ({
    type: MapFilterActionsEnum.DELETE_COMPANY_REGION,
    payload,
  }),
  setCompanyEdrCode: (payload: string): SetCompanyEdrCode => ({
    type: MapFilterActionsEnum.SET_COMPANY_EDR_CODE,
    payload,
  }),
  cleanCompanyEdrCode: (payload: []): SetCleanCompanyEdrCode => ({
    type: MapFilterActionsEnum.CLEAN_COMPANY_EDR_CODE,
    payload,
  }),
  deleteCompanyEdrCode: (payload: string): DeleteCompanyEdrCode => ({
    type: MapFilterActionsEnum.DELETE_COMPANY_EDR_CODE,
    payload,
  }),
  setCompanyKVED: (payload: string): SetCompanyKVED => ({
    type: MapFilterActionsEnum.SET_COMPANY_KVED,
    payload,
  }),
  cleanCompanyKVED: (payload: []): SetCleanCompanyKVED => ({
    type: MapFilterActionsEnum.CLEAN_COMPANY_KVED,
    payload,
  }),
  deleteCompanyKVED: (payload: string): DeleteCompanyKVED => ({
    type: MapFilterActionsEnum.DELETE_COMPANY_KVED,
    payload,
  }),
};
