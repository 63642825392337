import styled from "@emotion/styled";
import { breakpoints } from "../../constants";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const StyledMapWrapper = styled.div<{ isFilterBarActive: boolean }>`
  width: 100%;
  height: 100%;
  margin-left: auto;
  position: relative;
  ${({ isFilterBarActive }) =>
    isFilterBarActive &&
    `
		width: calc(100% - 560px);
  	transition: width 0.2s ease-out;
		@media (max-width: ${breakpoints.medium}px) {
			width: calc(100% - 360px);
      transition: width 0.2s ease-out;
		}
		@media (max-width: ${breakpoints.small}px) {
			width: 100%;
		}
		`}
`;

const MapWrapper = ({ children }) => {
  const { isFilterBarActive } = useTypedSelector((state) => state.mapInterface);

  return (
    <StyledMapWrapper isFilterBarActive={isFilterBarActive}>
      {children}
    </StyledMapWrapper>
  );
};

export default MapWrapper;
