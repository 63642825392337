import { useState } from "react";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import Details from "./ActiveTabs/Details";
import Contacts from "./ActiveTabs/Contacts";
import Jp from "../../../../assets/svg/jpIcon.svg";
import Objects from "./ActiveTabs/Objects";
import { useTranslation } from "react-i18next";
import useSearchParams from "../../../../hooks/useSearchParams";
import { ICurrentSikaDesigner } from "../../../../models/CurrentSikaDesigner";
import Tab, { TabsWrapper } from "../../../Tab/Tab";
import AnimatedDetailsContainer from "../../../../share/ResultDetails/AnimatedDetailsContainer/AnimatedDetailsContainer";
import DetailsHeader from "../../../../share/ResultDetails/DetailsHeader/DetailsHeader";
import {
  DetailsContainer,
  DetailsTabContent,
  ShowOnMapButton,
  StyledDetailsActions,
} from "../../../../share/ResultDetails/DetailsScroll/DetailsScroll";
import useMobileScreen from "../../../../hooks/useMobileScreen";
const SikaDesignerDetails = () => {
  const { sika_designer } = useTypedSelector(
    (state) => state.mapSikaDesignerInfo
  );
  const { isDetailsActive } = useTypedSelector((state) => state.mapInterface);
  const { setMapSikaDesigner, setIsDetailsActive, setCurrentMarker } =
    useActions();
  const [activeTab, setActiveTab] = useState("details");
  const { removeQueryParam } = useSearchParams();

  const handleClose = () => {
    setIsDetailsActive(!isDetailsActive);
    setCurrentMarker({ lat: 0, lon: 0 });
    removeQueryParam("hash_id");
    setMapSikaDesigner({ sika_designer: {} } as ICurrentSikaDesigner);
  };

  const { t: tDetails } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.objectDetails",
  });
  const title =
    sika_designer && sika_designer?.short_name
      ? sika_designer?.short_name
      : sika_designer?.full_name;

  const isMobile = useMobileScreen();

  // if (!sika_designer) return null;

  return (
    <AnimatedDetailsContainer isShown={isDetailsActive}>
      <DetailsHeader
        title={title}
        description={sika_designer && sika_designer?.address}
        closeDetails={handleClose}
        logo={<img src={Jp} alt="" />}
      />
      <DetailsContainer>
        <TabsWrapper>
          <Tab
            isActive={activeTab === "details"}
            onClick={() => setActiveTab("details")}
            text={tDetails("details.title")}
          />
          <Tab
            isActive={activeTab === "contacts"}
            onClick={() => setActiveTab("contacts")}
            text={tDetails("contacts.title")}
          />
          <Tab
            isActive={activeTab === "objects"}
            onClick={() => setActiveTab("objects")}
            text={tDetails("projects.title")}
          />
        </TabsWrapper>
        <DetailsTabContent>
          {activeTab === "details" ? (
            <Details key={sika_designer && sika_designer.full_name} />
          ) : activeTab === "objects" ? (
            <Objects key={sika_designer && sika_designer.full_name} />
          ) : (
            <Contacts key={sika_designer && sika_designer.full_name} />
          )}
        </DetailsTabContent>
        {isMobile && (
          <StyledDetailsActions>
            <ShowOnMapButton
              lat={sika_designer?.lat}
              lon={sika_designer?.lon}
            />
          </StyledDetailsActions>
        )}
      </DetailsContainer>
    </AnimatedDetailsContainer>
  );
};

export default SikaDesignerDetails;
