import styled from "@emotion/styled";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import CustomIcon from "../CustomIcon/CustomIcon";
import { ReactComponent as CloseIcon } from "../../assets/svg/Close_small_nofill.svg";
import { motion, useAnimation, useMotionValue } from "framer-motion";
import CustomButton from "../Button/Button";
import { useTranslation } from "react-i18next";

import { zIndexes } from "../../constants";
// const dragEndExpandRatio = 0.3
const animationTransitionTime = 0.3;

const StyledMobileModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 24px;
  z-index: ${zIndexes.filtersModalMobile};
  display: flex;
  flex-direction: column-reverse;
`;

const StyledMobileModal = styled.div`
  border-radius: 16px 16px 0 0;
  background: var(--White-W500, #fff);
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
  z-index: 4000;
`;

const StyledMobileModalHeader = styled.div`
  padding: 8px 16px;
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledCloseButton = styled.button`
  height: 32px;
  width: 32px;
  padding: 4px;
  border: none;
  outline: none;
  background: none;
  /* position: absolute; */
  /* top: 50%; */
  /* left: 16px; */
  /* transform: translate(0, -50%); */
`;

const StyledTitle = styled.h3`
  color: var(--Dark-D400, #242731);
  font-family: Montserrat, Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.026px;
  text-align: center;
  flex: 1;
  padding-right: 36px;
`;

const MobileModalBody = styled.div<IMobileModalBodyProps>`
  overflow: auto;
  padding: 16px;
  border-bottom: 1px solid var(--Dark-D100, #e0e0e0);
  border-top: 1px solid var(--Dark-D100, #e0e0e0);
  height: 100%;
  width: 100%;
  flex: 1;
`;

const MobileModalActions = styled.div<IMobileModalActionsProps>`
  display: flex;
  padding: 16px;
  justify-content: space-between;
`;

const StyledBackdrop = styled(motion.div)`
  position: absolute;
  inset: 0 0 0 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(38, 45, 51, 0.5);
`;
interface MobileModalProps {
  onClose: () => void;
  onClear?: () => void;
  onApply?: () => void;
  applyBtnText?: string;
  isApplyBtnDisabled?: boolean;
  isActionsVisible?: boolean;
  children: ReactNode | ReactNode[];
  title: string;
  hasActions?: boolean;
}

const MobileModal: React.FC<MobileModalProps> = ({
  title,
  children,
  applyBtnText = "",
  isApplyBtnDisabled = false,
  onClose,
  onClear = null,
  onApply = null,
  hasActions = true,
}) => {
  const animationShiftControls = useAnimation();
  const animationOpacityControls = useAnimation();

  const modalRef = useRef<HTMLDivElement | null>(null);
  const [, setModalHeight] = useState<number>(0);

  useEffect(() => {
    if (modalRef?.current) {
      setModalHeight(modalRef.current.clientHeight);
      handleModalOpen();
    }
  }, []);

  const modalVerticalShift = useMotionValue("100%");

  const handleModalOverlayAppear = () => {
    return animationOpacityControls.start({
      opacity: 1,
      transition: { ease: "easeOut", duration: animationTransitionTime },
    });
  };

  const handleModalOverlayHide = () => {
    return animationOpacityControls.start({
      opacity: 0,
      transition: { ease: "easeOut", duration: animationTransitionTime },
    });
  };

  const handleModalAppear = () => {
    return animationShiftControls.start({
      y: 0,
      transition: { ease: "easeOut", duration: animationTransitionTime },
    });
  };

  const handleModalHide = () => {
    return animationShiftControls.start({
      y: "100%",
      transition: { ease: "easeOut", duration: animationTransitionTime },
    });
  };

  const handleModalClose = () => {
    Promise.all([handleModalOverlayHide(), handleModalHide()]).then(() => {
      onClose();
    });
  };

  const handleModalOpen = () => {
    handleModalOverlayAppear();
    handleModalAppear();
  };

  // const handleDragEnd = (
  //   _event: MouseEvent | TouchEvent | PointerEvent,
  //   info: PanInfo
  // ) => {
  //   if (info.offset.y > modalHeight * dragEndExpandRatio) {
  //     handleModalClose();
  //   } else {
  //     handleModalOpen();
  //   }
  // };

  const { t: tModal } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.mobileFiltersActions",
  });

  return (
    <StyledMobileModalContainer>
      <StyledBackdrop
        onClick={handleModalClose}
        animate={animationOpacityControls}
      />
      <motion.div
        // drag="y"
        style={{
          zIndex: zIndexes.filtersModalMobile,
          maxHeight: "100%",
          y: modalVerticalShift,
        }}
        animate={animationShiftControls}
        // onDragEnd={handleDragEnd}
        // dragConstraints={{ top: 0, left: 0, right: 0, bottom: modalHeight }}
        // dragElastic={0}
        // dragMomentum={false}
      >
        <StyledMobileModal ref={modalRef}>
          <StyledMobileModalHeader>
            <StyledCloseButton>
              <CustomIcon
                icon={CloseIcon}
                onClick={handleModalClose}
                color={"#242731"}
                size="100%"
              ></CustomIcon>
            </StyledCloseButton>
            <StyledTitle>{title}</StyledTitle>
          </StyledMobileModalHeader>
          {children}
          {hasActions && (
            <MobileModalActions>
              {!!onClear ? (
                <CustomButton
                  onClick={() => {
                    onClear();
                  }}
                  size="medium"
                  htmlType="reset"
                  type="text"
                >
                  {/* // TODO: translate */}
                  {tModal("clearFilter")}
                </CustomButton>
              ) : (
                <div />
              )}
              <CustomButton
                disabled={isApplyBtnDisabled}
                onClick={() => {
                  !!onApply ? onApply() : handleModalClose();
                }}
                size="medium"
                htmlType="submit"
                type={"filled"}
                fullWidth={!onClear}
              >
                {applyBtnText ? applyBtnText : tModal("applyFilter")}
              </CustomButton>
            </MobileModalActions>
          )}
        </StyledMobileModal>
      </motion.div>
    </StyledMobileModalContainer>
  );
};

interface IMobileModalBodyProps {
  children: ReactNode | ReactNode[];
}
interface IMobileModalActionsProps {
  children: ReactNode | ReactNode[];
}

export { MobileModal, MobileModalBody, MobileModalActions };
