import {
  DownloadActionsEnum,
  SetDownloadingUrl,
  SetErrorAction,
  SetIsDownloadingAction,
  SetIsDownloadingFinished,
} from "./types";

export const DownloadActionCreators = {
  setIsLoading: (payload: boolean): SetIsDownloadingAction => ({
    type: DownloadActionsEnum.SET_IS_DOWNLOADING,
    payload,
  }),
  setError: (payload: string): SetErrorAction => ({
    type: DownloadActionsEnum.SET_DOWNLOAD_ERROR,
    payload,
  }),
  setIsDownloadingFinished: (payload: boolean): SetIsDownloadingFinished => ({
    type: DownloadActionsEnum.SET_IS_DOWNLOADING_FINISHED,
    payload,
  }),
  setLastDownloadingUrl: (payload: string): SetDownloadingUrl => ({
    type: DownloadActionsEnum.SET_DOWNLOADING_URL,
    payload,
  }),
};
