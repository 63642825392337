import { useState } from "react";
import styles from "./Payments.module.scss";
import SubscribeManagement from "../../components/Payments/SubscribeManagement/SubscribeManagement";
import { useTranslation } from "react-i18next";
import SimplePage from "../../share/Page/SimplePage";

const getTabTitleKey = (key: string): string => key + ".title";

const Payments = () => {
  const [activeTab, setActiveTab] = useState("subManage");

  const { t } = useTranslation("translation", {
    keyPrefix: "paymentsPage.tabList",
  });

  return (
    <SimplePage isBreadcrumb={true} title={t(getTabTitleKey("subManage"))}>
      <div className={styles.contentNav}>
        <div
          className={`${styles.contentTabLink} ${styles.disabled} ${
            activeTab === "details" && styles.active
          }`}
          onClick={() => setActiveTab("subManage")}
        >
          {t(getTabTitleKey("paymentsHistory"))}
        </div>
        <div
          className={`${styles.contentTabLink} ${styles.disabled} ${
            activeTab === "details" && styles.active
          }`}
          onClick={() => setActiveTab("subManage")}
        >
          {t(getTabTitleKey("approxPaymentAmount"))}
        </div>
        <div
          className={`${styles.contentTabLink} ${styles.disabled} ${
            activeTab === "details" && styles.active
          }`}
          onClick={() => setActiveTab("subManage")}
        >
          {t(getTabTitleKey("overview"))}
        </div>
        <div
          className={`${styles.contentTabLink} ${styles.disabled} ${
            activeTab === "details" && styles.active
          }`}
          onClick={() => setActiveTab("subManage")}
        >
          {t(getTabTitleKey("paymentDetails"))}
        </div>
        <div
          className={`${styles.contentTabLink} ${
            activeTab === "subManage" && styles.active
          }`}
          onClick={() => setActiveTab("subManage")}
        >
          {t(getTabTitleKey("subManage"))}
        </div>
      </div>
      {activeTab === "subManage" && <SubscribeManagement />}
    </SimplePage>
  );
};

export default Payments;
