import { MapActionsEnum, MapObjectAction, IMapPersonsInfoState } from "./types";

const initialState: IMapPersonsInfoState = {
  isObjectsLoading: false,
  params: {},
  hash_ids: [],
  count: 0,
  person: null,
  persons: [],
  next: null,
};

export default function personReducer(
  state = initialState,
  action: MapObjectAction
) {
  switch (action.type) {
    case MapActionsEnum.SET_IS_PERSON_LOADING:
      return { ...state, isObjectsLoading: action.payload };

    case MapActionsEnum.SET_MAP_PERSON:
      return { ...state, ...action.payload };
    case MapActionsEnum.SET_MAP_PERSONS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
