import { useEffect } from "react";
import styles from "./ErrorPage404.module.scss";
import Navbar from "../../components/nav/Navbar/Navbar";
import { RouteNames } from "../../routes";
import ErrorImg from "../../assets/images/ErrorImage.png";
import ErrorImg2 from "../../assets/images/ErrorImage2.png";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useTranslation } from "react-i18next";
import filesUtil from "../../util/filesUtil";
import { store } from "../../store";
import { IFeedbackFormValues } from "../../store/reducers/feedback/types";
import { useActions } from "../../hooks/useActions";
import { useLogger } from "../../contexts/LoggerContext";
import { IFetchable } from "../../models/IFetchable";
import { IUser } from "../../models/Accounts";
import {
  reportTopics,
  reportTypes,
} from "../../store/reducers/feedback/action-creator";

const ErrorPage404 = () => {
  const { isAuth } = useTypedSelector((state) => state.auth);
  const { t: tError } = useTranslation("translation", {
    keyPrefix: "errorPage",
  });

  const { postFeedback } = useActions(); // Replace with your custom hook
  const { errorLogs } = useLogger();
  const currentUser: Partial<IUser & IFetchable> = useTypedSelector(
    (store) => store.currentUser
  );
  const handleFeedback = async () => {
    const errorFile = filesUtil.writeStrToTxtFile(
      JSON.stringify(errorLogs),
      "errorLogs"
    );
    const reduxStoreFile = filesUtil.writeStrToTxtFile(
      JSON.stringify(store.getState()),
      "reduxStore"
    );
    const data: IFeedbackFormValues = {
      email: isAuth ? currentUser?.email : "unauthorized",
      ticketName: "Error page",
      reportType: reportTypes.BUG,
      reportTopic: reportTopics["Account & Profile"],
      description:
        "\n\n" +
        "Error Logs:" +
        "\n" +
        JSON.stringify(errorLogs) +
        "\n\n" +
        "Redux Store:" +
        "\n" +
        JSON.stringify(store.getState()),
      attachments: [...[], errorFile, reduxStoreFile],
    };

    await postFeedback(data, () => {
      console.log("feedback sent");
    });
  };

  useEffect(() => {
    handleFeedback();
  }, []);

  if (isAuth) {
    return (
      <div className={styles.mainWrapper}>
        <Navbar />
        <div className={styles.backgroundWrapper}>
          <div className={styles.pageWrapper}>
            <img src={ErrorImg} alt="error" />
            <h2 className={styles.errorTitle}>{tError("title")}</h2>
            <p className={styles.errorText}>{tError("subTitle.404")}</p>
            <a className={styles.errorLink} href={RouteNames.AIMAP}>
              {tError("toMain")}
            </a>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.mainWrapper}>
        <div className={styles.backgroundWrapper}>
          <div className={styles.pageWrapper}>
            <img src={ErrorImg2} alt="error" />
            <h2 className={styles.errorTitle}>{tError("title")}</h2>
            <p className={styles.errorText}>{tError("subTitle.502")}</p>
            <a href={RouteNames.LOGIN} className={styles.errorLink}>
              {tError("toMain")}
            </a>
          </div>
        </div>
      </div>
    );
  }
};

export default ErrorPage404;
