import React, { useState } from "react";
import RegistrationFirstStep from "./RegistrationFirstStep/RegistrationFirstStep";
import RegistrationSecondStep from "./RegistrationSecondStep/RegistrationSecondStep";
import RegistrationThirdStep from "./RegistrationThirdStep/RegistrationThirdStep";
import styles from "./Registration.module.scss";
import ReusableAuth from "../../../share/ReusableAuth/ReusableAuth";
import { useTranslation } from "react-i18next";

const userData = {
  email: "",
  full_name: "",
  password: "",
  re_password: "",
};

const RegistrationMain = () => {
  const [page, setPage] = useState<number>(1);
  const [user, setUser] = useState(userData);

  const { t } = useTranslation("translation", {
    keyPrefix: "registrationPage",
  });

  return (
    <ReusableAuth page={page}>
      <div className={styles.regContainer}>
        <h1 className={styles.regContainerTitle}>{t("title")}</h1>
        <p className={styles.regContainerText}>{t("description")}</p>
        {page === 1 ? (
          <RegistrationFirstStep
            setPage={setPage}
            setUser={setUser}
            user={user}
          />
        ) : page === 2 ? (
          <RegistrationSecondStep setPage={setPage} user={user} />
        ) : (
          <RegistrationThirdStep />
        )}
      </div>
    </ReusableAuth>
  );
};

export default RegistrationMain;
