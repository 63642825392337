import { UploadFile } from "antd";

export const convertUploadFileToFile = (uploadFile: UploadFile): File => {
  return uploadFile as unknown as File;
};

export const convertUploadFilesToFiles = (
  uploadFiles: UploadFile[]
): File[] => {
  return uploadFiles.map((uploadFile) => convertUploadFileToFile(uploadFile));
};

// writeStrToTxtFile
export const writeStrToTxtFile = (text: string, fileName): UploadFile => {
  const blob = new Blob([text], { type: "text/plain" });
  const file = new File([blob], fileName + ".txt", { type: "text/plain" });
  return file as unknown as UploadFile;
};

export const getIsFileAnImage = (file) =>
  file.type === "image/jpeg" ||
  file.type === "image/png" ||
  file.type === "image/jpg" ||
  file.type === "image/gif" ||
  file.type === "image/svg+xml" ||
  file.type === "image/webp";

const filesUtil = {
  convertUploadFileToFile,
  convertUploadFilesToFiles,
  writeStrToTxtFile,
  getIsFileAnImage,
};

export default filesUtil;
