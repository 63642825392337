import {
  EDRPOU,
  TYPE,
  KVED,
  actual_projects,
  ISelectedDocList,
  finished_projects_2021,
  finished_projects_full,
  region,
  reg_date,
  consequence_class,
  country_part,
  domain,
  role,
  entity_type,
} from "../../../../../models/SikaDesignerObject";

import { ISearchResponse } from "../../../../../models/Search";

export interface MapSikaFiltersState {
  isSuggestionsLoading: boolean;
  is21plus: boolean;
  isIncomeBigger: boolean;
  aimap_classifier_all: TYPE[];
  domain_array: domain[];
  role_array: role[];
  entity_type_array: entity_type[];
  consequence_class_array: consequence_class[];
  country_part_array: country_part[];
  selectedDocList: ISelectedDocList[];
  date_added__lte: reg_date;
  date_added__gte: reg_date;
  suggestions: ISearchResponse[];
  activeSuggestions: ISearchResponse[];
  edrpou_code: EDRPOU[];
  region: region[];
  kved: KVED[];
  finished_projects_full__range?: finished_projects_full;
  actual_projects__range?: actual_projects;
  finished_projects_2021__range?: finished_projects_2021;
  sika_keyword: string[];
}
export enum MapFilterActionsEnum {
  SET_SUGGESTION_LOADING = "SET_SUGGESTION_LOADING",
  SET_SIKA_NUMBER_13_22_RANGE = "SET_SIKA_NUMBER_13_22_RANGE",
  SET_SIKA_NUMBER_02_24_RANGE = "SET_SIKA_NUMBER_02_24_RANGE",
  SET_SIKA_NUMBER_21_22__RANGE = "SET_SIKA_NUMBER_21_22__RANGE",
  SET_IS_21_PLUS = "SET_IS_21_PLUS",
  SET_IS_INCOME_BIGGER = "SET_IS_INCOME_BIGGER",

  SET_DATE_ADDED_LTE = "SET_DATE_ADDED_LTE",
  SET_DATE_ADDED_GTE = "SET_DATE_ADDED_GTE",
  TOGGLE_SIKA_DESIGNER_FILTER_TYPE_FILTER = "TOGGLE_SIKA_DESIGNER_FILTER_TYPE_FILTER",
  CLEAN_SIKA_DESIGNER_FILTER_TYPE_FILTER = "CLEAN_SIKA_DESIGNER_FILTER_TYPE_FILTER",

  TOGGLE_SIKA_DESIGNER_CATEGORY_FILTER = "TOGGLE_SIKA_DESIGNER_CATEGORY_FILTER",
  CLEAN_SIKA_DESIGNER_CATEGORY_FILTER = "CLEAN_SIKA_DESIGNER_CATEGORY_FILTER",
  TOGGLE_SIKA_DESIGNER_ORGANIZATION_FILTER = "TOGGLE_SIKA_DESIGNER_ORGANIZATION_FILTER",
  CLEAN_SIKA_DESIGNER_ORGANIZATION_FILTER = "CLEAN_SIKA_DESIGNER_ORGANIZATION_FILTER",
  TOGGLE_SIKA_DESIGNER_OWNERSHIP_FILTER = "TOGGLE_SIKA_DESIGNER_OWNERSHIP_FILTER",
  CLEAN_SIKA_DESIGNER_OWNERSHIP_FILTER = "CLEAN_SIKA_DESIGNER_OWNERSHIP_FILTER",
  TOGGLE_SIKA_DESIGNER_CC_FILTER = "TOGGLE_SIKA_DESIGNER_CC_FILTER",
  CLEAN_SIKA_DESIGNER_CC_FILTER = "CLEAN_SIKA_DESIGNER_CC_FILTER",
  TOGGLE_SIKA_DESIGNER_AREA_FILTER = "TOGGLE_SIKA_DESIGNER_AREA_FILTER",
  CLEAN_SIKA_DESIGNER_AREA_FILTER = "CLEAN_SIKA_DESIGNER_AREA_FILTER",
  SET_DOC_LIST = "SET_DOC_LIST",
  SET_SIKA_DESIGNER_SUGGESTION = "SET_SIKA_DESIGNER_SUGGESTION",
  SET_SIKA_DESIGNER_ACTIVE_SUGGESTION = "SET_SIKA_DESIGNER_ACTIVE_SUGGESTION",
  DELETE_SIKA_DESIGNER_ACTIVE_SUGGESTION = "DELETE_SIKA_DESIGNER_ACTIVE_SUGGESTION",
  CLEAN_SIKA_DESIGNER_SUGGESTION = "CLEAN_SIKA_DESIGNER_SUGGESTION",
  SET_SIKA_DESIGNER_REGION = "SET_SIKA_DESIGNER_REGION",
  CLEAN_SIKA_DESIGNER_REGION = "CLEAN_SIKA_DESIGNER_REGION",
  DELETE_SIKA_DESIGNER_REGION = "DELETE_SIKA_DESIGNER_REGION",
  SET_SIKA_DESIGNER_EDR_CODE = "SET_SIKA_DESIGNER_EDR_CODE",
  CLEAN_SIKA_DESIGNER_EDR_CODE = "CLEAN_SIKA_DESIGNER_EDR_CODE",
  DELETE_SIKA_DESIGNER_EDR_CODE = "DELETE_SIKA_DESIGNER_EDR_CODE",
  SET_SIKA_DESIGNER_KVED = "SET_SIKA_DESIGNER_KVED",
  CLEAN_SIKA_DESIGNER_KVED = "CLEAN_SIKA_DESIGNER_KVED",
  DELETE_SIKA_DESIGNER_KVED = "DELETE_SIKA_DESIGNER_KVED",
  DELETE_SIKA_DESIGNER_CC = "DELETE_SIKA_DESIGNER_CC",
  DELETE_SIKA_DESIGNER_FILTER_TYPE = "DELETE_SIKA_DESIGNER_FILTER_TYPE",
  DELETE_SIKA_DESIGNER_AREA = "DELETE_SIKA_DESIGNER_AREA",
  SET_SIKA_DESIGNER_KEYWORD = "SET_SIKA_DESIGNER_KEYWORD",
  SET_SIKA_DESIGNER_CLEAN_KEYWORD = "SET_SIKA_DESIGNER_CLEAN_KEYWORD",
  DELETE_SIKA_DESIGNER_KEYWORD = "DELETE_SIKA_DESIGNER_KEYWORD",
  DELETE_SIKA_DESIGNER_CATEGORY = "DELETE_SIKA_DESIGNER_CATEGORY",
  DELETE_SIKA_DESIGNER_ORGANIZATION = "DELETE_SIKA_DESIGNER_ORGANIZATION",
  DELETE_SIKA_DESIGNER_OWNERSHIP = "DELETE_SIKA_DESIGNER_OWNERSHIP",
}

// SetSuggestionLoadingAction
export interface SetIsSuggestionsLoadingAction {
  type: MapFilterActionsEnum.SET_SUGGESTION_LOADING;
  payload: boolean;
}

export interface SetIs21Plus {
  type: MapFilterActionsEnum.SET_IS_21_PLUS;
  payload: boolean;
}
export interface SetIsIncomeBigger {
  type: MapFilterActionsEnum.SET_IS_INCOME_BIGGER;
  payload: boolean;
}

export interface SetDateAddedLTE {
  type: MapFilterActionsEnum.SET_DATE_ADDED_LTE;
  payload: string;
}
export interface SetSikaNumber1322Range {
  type: MapFilterActionsEnum.SET_SIKA_NUMBER_13_22_RANGE;
  payload: string;
}
export interface SetSikaNumber0224Range {
  type: MapFilterActionsEnum.SET_SIKA_NUMBER_02_24_RANGE;
  payload: string;
}
export interface SetSikaNumber2122Range {
  type: MapFilterActionsEnum.SET_SIKA_NUMBER_21_22__RANGE;
  payload: string;
}
export interface SetDateAddedGTE {
  type: MapFilterActionsEnum.SET_DATE_ADDED_GTE;
  payload: string;
}

export interface SetSikaDesignerFilterTypeFilter {
  type: MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_FILTER_TYPE_FILTER;
  payload: string;
}

export interface SetCleanSikaDesignerFilterTypeFilter {
  type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_FILTER_TYPE_FILTER;
  payload: [];
}

export interface SetSikaDesignerCategoryFilter {
  type: MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_CATEGORY_FILTER;
  payload: string;
}

export interface SetCleanSikaDesignerCategoryFilter {
  type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_CATEGORY_FILTER;
  payload: [];
}

export interface SetSikaDesignerOrganizationFilter {
  type: MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_ORGANIZATION_FILTER;
  payload: string;
}

export interface SetCleanSikaDesignerOrganizationFilter {
  type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_ORGANIZATION_FILTER;
  payload: [];
}

export interface SetSikaDesignerOwnershipFilter {
  type: MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_OWNERSHIP_FILTER;
  payload: string;
}

export interface SetCleanSikaDesignerOwnershipFilter {
  type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_OWNERSHIP_FILTER;
  payload: [];
}

export interface SetSikaDesignerCCFilter {
  type: MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_CC_FILTER;
  payload: string;
}

export interface SetCleanSikaDesignerCCFilter {
  type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_CC_FILTER;
  payload: [];
}

export interface SetSikaDesignerAreaFilter {
  type: MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_AREA_FILTER;
  payload: string;
}

export interface SetCleanSikaDesignerAreaFilter {
  type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_AREA_FILTER;
  payload: [];
}

export interface SetSelectedDocList {
  type: MapFilterActionsEnum.SET_DOC_LIST;
  payload: ISelectedDocList[];
}

export interface SetSikaDesignerSuggestion {
  type: MapFilterActionsEnum.SET_SIKA_DESIGNER_SUGGESTION;
  payload: ISearchResponse[];
}

export interface SetSikaDesignerActiveSuggestion {
  type: MapFilterActionsEnum.SET_SIKA_DESIGNER_ACTIVE_SUGGESTION;
  payload: ISearchResponse;
}

export interface DeleteSikaDesignerActiveSuggestion {
  type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_ACTIVE_SUGGESTION;
  payload: ISearchResponse;
}

export interface SetSikaDesignerCleanSuggestion {
  type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_SUGGESTION;
  payload: [];
}

export interface SetSikaDesignerRegion {
  type: MapFilterActionsEnum.SET_SIKA_DESIGNER_REGION;
  payload: string;
}

export interface SetSikaDesignerCleanRegion {
  type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_REGION;
  payload: [];
}

export interface DeleteSikaDesignerRegion {
  type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_REGION;
  payload: string;
}

export interface DeleteSikaDesignerFilterType {
  type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_FILTER_TYPE;
  payload: string;
}

export interface DeleteSikaDesignerCC {
  type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_CC;
  payload: string;
}

export interface DeleteSikaDesignerArea {
  type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_AREA;
  payload: string;
}

export interface SetSikaDesignerEdrCode {
  type: MapFilterActionsEnum.SET_SIKA_DESIGNER_EDR_CODE;
  payload: string;
}

export interface SetCleanSikaDesignerEdrCode {
  type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_EDR_CODE;
  payload: [];
}

export interface DeleteSikaDesignerEdrCode {
  type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_EDR_CODE;
  payload: string;
}

export interface SetSikaDesignerKVED {
  type: MapFilterActionsEnum.SET_SIKA_DESIGNER_KVED;
  payload: string;
}

export interface SetCleanSikaDesignerKVED {
  type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_KVED;
  payload: [];
}

export interface DeleteSikaDesignerKVED {
  type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_KVED;
  payload: string;
}

export interface DeleteSikaDesignerCategory {
  type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_CATEGORY;
  payload: string;
}
export interface DeleteSikaDesignerOrganization {
  type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_ORGANIZATION;
  payload: string;
}
export interface DeleteSikaDesignerOwnership {
  type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_OWNERSHIP;
  payload: string;
}
export interface SetSikaDesignerKeyword {
  type: MapFilterActionsEnum.SET_SIKA_DESIGNER_KEYWORD;
  payload: string;
}
export interface SetSikaDesignerCleanKeyword {
  type: MapFilterActionsEnum.SET_SIKA_DESIGNER_CLEAN_KEYWORD;
  payload: [];
}

export interface DeleteSikaDesignerKeyword {
  type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_KEYWORD;
  payload: string;
}
export type MapFiltersAction =
  | SetIsSuggestionsLoadingAction
  | SetSikaNumber0224Range
  | SetSikaNumber2122Range
  | SetSikaNumber1322Range
  | SetSikaDesignerFilterTypeFilter
  | SetCleanSikaDesignerFilterTypeFilter
  | SetSikaDesignerCCFilter
  | SetCleanSikaDesignerCCFilter
  | SetSikaDesignerAreaFilter
  | SetCleanSikaDesignerAreaFilter
  | SetDateAddedGTE
  | SetDateAddedLTE
  | SetSelectedDocList
  | SetSikaDesignerSuggestion
  | SetSikaDesignerActiveSuggestion
  | DeleteSikaDesignerActiveSuggestion
  | SetSikaDesignerRegion
  | DeleteSikaDesignerRegion
  | SetSikaDesignerEdrCode
  | DeleteSikaDesignerEdrCode
  | SetSikaDesignerCleanSuggestion
  | SetSikaDesignerCleanRegion
  | SetCleanSikaDesignerEdrCode
  | SetSikaDesignerKVED
  | SetCleanSikaDesignerKVED
  | DeleteSikaDesignerKVED
  | DeleteSikaDesignerFilterType
  | DeleteSikaDesignerCC
  | DeleteSikaDesignerArea
  | DeleteSikaDesignerCategory
  | DeleteSikaDesignerOrganization
  | DeleteSikaDesignerOwnership
  | SetIs21Plus
  | SetIsIncomeBigger
  | SetSikaDesignerCategoryFilter
  | SetCleanSikaDesignerCategoryFilter
  | SetSikaDesignerOrganizationFilter
  | SetCleanSikaDesignerOrganizationFilter
  | SetSikaDesignerOwnershipFilter
  | SetCleanSikaDesignerOwnershipFilter
  | SetSikaDesignerKeyword
  | SetSikaDesignerCleanKeyword
  | DeleteSikaDesignerKeyword;
