import {
  SearchResultItem,
  StyledSearchResultsItemTitle,
} from "../../../../share/searchResults";
import { formatSearchResultTitle } from "../../../../util/stringUtil";
import Np from "../../../../assets/svg/personIcon.svg";
import { useActions } from "../../../../hooks/useActions";
import useSearchParams from "../../../../hooks/useSearchParams";
import { CurrentPerson } from "../../../../models/CurrentPerson";

type Props = {
  key: number;
  mapObject: CurrentPerson;
};

const PersonsSearchResultItem = ({ key, mapObject }: Props) => {
  const { setCurrentMarker } = useActions();
  const { setSearchParam } = useSearchParams();

  const handleClickSearchResult = (mapObject: CurrentPerson) => {
    const hashId = mapObject.RECORD;
    setSearchParam("hash_id", hashId);
  };

  return (
    <SearchResultItem
      hashId={mapObject.hash}
      onMouseEnter={() =>
        setCurrentMarker({
          lon: +mapObject.lat,
          lat: +mapObject.lon,
        })
      }
      onMouseLeave={() => setCurrentMarker({ lon: 0, lat: 0 })}
      onClick={() => handleClickSearchResult(mapObject)}
    >
      <img src={Np} alt="" />
      <StyledSearchResultsItemTitle>
        {formatSearchResultTitle(mapObject.NAME)}
      </StyledSearchResultsItemTitle>
      <p>
        {mapObject.STAN} &middot;
        {" " + mapObject.registration_date_1 + " "}
      </p>
    </SearchResultItem>
  );
};

export default PersonsSearchResultItem;
