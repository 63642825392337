import { AuthActionCreators } from "./../../auth/action-creators";
import {
  MapActionsEnum,
  SetCurrentMarkerAction,
  SetFilterBarActiveAction,
  SetObjectDetailsActiveAction,
  SetOffsetAction,
  SetPageAction,
  SetProfileModalActive,
  SetSettingsActive,
  SetViewportAction,
  SetObjectsLoading,
} from "./types";
import { IViewport } from "../../../../models/Viewport";
import { ICurrentMarker } from "../../../../models/CurrentMarker";

export const MapInterfaceActionCreator = {
  setIsLoading: (payload: boolean): SetObjectsLoading => ({
    type: MapActionsEnum.SET_IS_MAP_INTERFACE_LOADING,
    payload,
  }),
  errorDispatch: (payload: string): any => {
    AuthActionCreators.errorDispatch(payload);
  },

  setIsFilterActive: (payload: boolean): SetFilterBarActiveAction => ({
    type: MapActionsEnum.SET_FILTER_BAR_ACTIVE,
    payload,
  }),

  setIsDetailsActive: (payload: boolean): SetObjectDetailsActiveAction => ({
    type: MapActionsEnum.SET_OBJECT_DETAILS_ACTIVE,
    payload,
  }),
  setProfileModal: (payload: boolean): SetProfileModalActive => ({
    type: MapActionsEnum.SET_PROFILE_MODAL_ACTIVE,
    payload,
  }),
  setSettingModal: (payload: boolean): SetSettingsActive => ({
    type: MapActionsEnum.SET_SETTINGS_ACTIVE,
    payload,
  }),
  setOffset: (payload: number): SetOffsetAction => ({
    type: MapActionsEnum.SET_OFFSET,
    payload,
  }),
  setPage: (payload: number): SetPageAction => ({
    type: MapActionsEnum.SET_PAGE,
    payload,
  }),

  setViewport: (viewport: IViewport): SetViewportAction => ({
    type: MapActionsEnum.SET_VIEWPORT,
    payload: viewport,
  }),
  setCurrentMarker: (
    currentMarker: ICurrentMarker
  ): SetCurrentMarkerAction => ({
    type: MapActionsEnum.SET_CURRENT_MARKER,
    payload: currentMarker,
  }),
  setIsPulloutDrawerOpen: (payload: boolean): any => ({
    type: MapActionsEnum.SET_IS_PULLOUT_DRAWER_OPEN,
    payload,
  }),
  setCurrentMapRef: (payload: any): any => ({
    type: MapActionsEnum.SET_CURRENT_MAP_REF,
    payload,
  }),
};
