import styles from "../../components/_maps/main/ObjectDetails/ObjectDetails.module.scss";

const DetailsTableRow = ({ name, value }) => {
  if (value) {
    return (
      <tr className={styles.tabRow}>
        <td>{name}:</td>
        <td>{value}</td>
      </tr>
    );
  }
  return null;
};

export default DetailsTableRow;
