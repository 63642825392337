import {
  DeleteActiveSuggestion,
  DeleteEdrCode,
  DeleteKeyword,
  DeleteNpId,
  DeleteRegion,
  DeleteStageFilter,
  FilterIdsEnum,
  MapFilterActionsEnum,
  SetActiveSuggestion,
  SetBuildFilter,
  SetCCFilter,
  SetCleanBuildFilter,
  SetCleanCCFilter,
  SetCleanConstructionFilter,
  SetCleanEdrCode,
  SetCleanFilter,
  SetCleanKeyword,
  SetCleanNpId,
  SetCleanRegion,
  SetCleanStageFilter,
  SetCleanSuggestion,
  SetConstructionFilter,
  SetEdrCode,
  SetFromDate,
  SetKeyword,
  SetNpId,
  SetRegion,
  SetSelectedDocList,
  SetStageFilter,
  SetSuggestion,
  SetIsSuggestionsLoadingAction,
  SetToDate,
  SetTypeFilter,
  ToggleFilterAction,
  SetSquareFilter,
  SetDkbsCode,
  SetCleanDkbsCode,
  DeleteDkbs,
} from "./types";
import { AppDispatch, RootState, store } from "../../../../index";
import { MapInterfaceActionCreator } from "../../mapInterface/action.creators";
import { mapService } from "../../../../api/MapService";
import { ISearchRequest, ISearchResponse } from "../../../../../models/Search";
import { AuthActionCreators } from "../../../auth/action-creators";
import { ISelectedDocList } from "../../../../../models/MapObject";
import { v4 as uuidv4 } from "uuid";
import {
  AvailableCC,
  suggestionTypes,
  maxSquare,
} from "../../../../../constants/constants";

export const MapFilterActionCreators = {
  setCleanAllFilters: () => (dispatch: AppDispatch) => {
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
    dispatch(MapFilterActionCreators.cleanTypeFilter([]));
    dispatch(MapFilterActionCreators.cleanCCFilter([]));
    dispatch(MapFilterActionCreators.cleanStageFilter([]));
    dispatch(MapFilterActionCreators.cleanConstructionFilter([]));
    dispatch(MapFilterActionCreators.cleanBuildFilter([]));
    dispatch(MapFilterActionCreators.setFromDate(""));
    dispatch(MapFilterActionCreators.setToDate(""));
    dispatch(MapFilterActionCreators.cleanSuggestions([]));
    dispatch(MapFilterActionCreators.cleanRegion([]));
    dispatch(MapFilterActionCreators.cleanEdrCode([]));
    dispatch(MapFilterActionCreators.cleanNpId([]));
    dispatch(MapFilterActionCreators.cleanKeyword([]));
    dispatch(MapFilterActionCreators.cleanSquareFilter());
    dispatch(
      MapFilterActionCreators.setSelectedDocList([{ id: uuidv4(), value: "1" }])
    );
  },

  setIsSuggestionsLoading: (
    payload: boolean
  ): SetIsSuggestionsLoadingAction => ({
    type: MapFilterActionsEnum.SET_SUGGESTION_LOADING,
    payload,
  }),

  applyFilter: () => (dispatch: AppDispatch) => {
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },

  toggleFilter: (payload: FilterIdsEnum): ToggleFilterAction => ({
    type: MapFilterActionsEnum.TOGGLE_FILTER_ACTION,
    payload,
  }),
  clearActiveFilter: (): ToggleFilterAction => ({
    type: MapFilterActionsEnum.TOGGLE_FILTER_ACTION,
    payload: "",
  }),
  selectTypeFilterOption: (payload: string): SetTypeFilter => ({
    type: MapFilterActionsEnum.SELECT_TYPE_FILTER,
    payload,
  }),
  cleanTypeFilter: (payload: []): SetCleanFilter => ({
    type: MapFilterActionsEnum.CLEAN_TYPE_FILTER,
    payload,
  }),
  typeFilterClean: () => (dispatch: AppDispatch) => {
    dispatch(MapFilterActionCreators.cleanTypeFilter([]));
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },
  toggleCCFilterObject: (payload: string): SetCCFilter => ({
    type: MapFilterActionsEnum.SELECT_CC_FILTER,
    payload,
  }),
  cleanCCFilter: (payload: []): SetCleanCCFilter => ({
    type: MapFilterActionsEnum.CLEAN_CC_FILTER,
    payload,
  }),
  typeCCClean: () => (dispatch: AppDispatch) => {
    dispatch(MapFilterActionCreators.cleanCCFilter([]));
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },
  setStageFilterObject: (payload: string[]): SetStageFilter => ({
    type: MapFilterActionsEnum.SET_STAGE_FILTER,
    payload,
  }),
  deleteStageFilterObject: (payload: string): DeleteStageFilter => ({
    type: MapFilterActionsEnum.DELETE_STAGE_FILTER,
    payload,
  }),
  setSelectedDocList: (payload: ISelectedDocList[]): SetSelectedDocList => ({
    type: MapFilterActionsEnum.SET_DOC_LIST,
    payload,
  }),
  cleanStageFilter: (payload: []): SetCleanStageFilter => ({
    type: MapFilterActionsEnum.CLEAN_STAGE_FILTER,
    payload,
  }),
  StageFilterClean: () => (dispatch: AppDispatch) => {
    dispatch(MapFilterActionCreators.cleanStageFilter([]));
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },
  toggleConstructionFilterObject: (payload: string): SetConstructionFilter => ({
    type: MapFilterActionsEnum.TOGGLE_CONSTRUCTION_FILTER,
    payload,
  }),
  cleanConstructionFilter: (payload: []): SetCleanConstructionFilter => ({
    type: MapFilterActionsEnum.CLEAN_CONSTRUCTION_FILTER,
    payload,
  }),
  ConstructionFilterClean: () => (dispatch: AppDispatch) => {
    dispatch(MapFilterActionCreators.cleanConstructionFilter([]));
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },
  toggleBuildFilterObject: (payload: string): SetBuildFilter => ({
    type: MapFilterActionsEnum.TOGGLE_BUILD_FILTER,
    payload,
  }),
  cleanBuildFilter: (payload: []): SetCleanBuildFilter => ({
    type: MapFilterActionsEnum.CLEAN_BUILD_FILTER,
    payload,
  }),
  BuildFilterClean: () => (dispatch: AppDispatch) => {
    dispatch(MapFilterActionCreators.cleanBuildFilter([]));
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },
  setFromDate: (payload: string): SetFromDate => ({
    type: MapFilterActionsEnum.SET_FROM_DATE,
    payload,
  }),
  setToDate: (payload: string): SetToDate => ({
    type: MapFilterActionsEnum.SET_TO_DATE,
    payload,
  }),
  setSuggestions: (payload: ISearchResponse[]): SetSuggestion => ({
    type: MapFilterActionsEnum.SET_SUGGESTION,
    payload: payload,
  }),
  getSuggestions: (data: ISearchRequest) => async (dispatch: AppDispatch) => {
    const mapObjectsInfo = (store.getState() as RootState).mapObjectsInfo;
    const countryCode = mapObjectsInfo
      ? mapObjectsInfo.config.countryCode
      : AvailableCC.ua;
    try {
      dispatch(MapFilterActionCreators.setIsSuggestionsLoading(true));
      const response = await mapService.fetchSuggestions(data, countryCode);
      if (response) {
        const suggestions = response.data;
        const activeSuggestions = (store.getState() as RootState).mapFilters
          .activeSuggestions;
        const suggestionsWithoutActiveSuggestions = suggestions.filter(
          (suggestion: ISearchResponse) =>
            !activeSuggestions.some(
              (activeSuggestion) => activeSuggestion.code === suggestion.code
            )
        );
        dispatch(
          MapFilterActionCreators.setSuggestions(
            suggestionsWithoutActiveSuggestions
          )
        );
      }
      dispatch(MapFilterActionCreators.setIsSuggestionsLoading(false));
    } catch (e: string | any) {
      dispatch(AuthActionCreators.errorDispatch(e?.response?.data?.detail));
    }
  },
  setActiveSuggestions: (payload: ISearchResponse): SetActiveSuggestion => ({
    type: MapFilterActionsEnum.SET_ACTIVE_SUGGESTION,
    payload,
  }),
  addActiveSuggestion:
    (suggestion: ISearchResponse) => (dispatch: AppDispatch) => {
      const activeSuggestions =
        (store.getState() as RootState).mapFilters.activeSuggestions || [];
      if (activeSuggestions.includes(suggestion)) {
        return;
      }
      if (suggestion.type === suggestionTypes.region) {
        dispatch(MapFilterActionCreators.setRegion(suggestion.code));
      }
      if (suggestion.type === suggestionTypes.np) {
        dispatch(MapFilterActionCreators.setNpId(suggestion.code));
      }
      if (suggestion.type === suggestionTypes.jp) {
        // MapFilterActionCreators.setEdrCode(suggestion.code);
        dispatch(MapFilterActionCreators.setEdrCode(suggestion.code));
      }
      if (suggestion.type === suggestionTypes.dkbs) {
        // MapFilterActionCreators.setDkbsCode(suggestion.code);
        dispatch(MapFilterActionCreators.setDkbsCode(suggestion.code));
      }

      // MapFilterActionCreators.setActiveSuggestions(suggestion);
      dispatch(MapFilterActionCreators.setActiveSuggestions(suggestion));
    },
  cleanSuggestions: (payload: []): SetCleanSuggestion => ({
    type: MapFilterActionsEnum.CLEAN_SUGGESTION,
    payload,
  }),
  deleteActiveSuggestion: (
    payload: ISearchResponse
  ): DeleteActiveSuggestion => ({
    type: MapFilterActionsEnum.DELETE_ACTIVE_SUGGESTION,
    payload,
  }),
  setRegion: (payload: string): SetRegion => ({
    type: MapFilterActionsEnum.SET_REGION,
    payload,
  }),
  cleanRegion: (payload: []): SetCleanRegion => ({
    type: MapFilterActionsEnum.CLEAN_REGION,
    payload,
  }),
  deleteRegion: (payload: string): DeleteRegion => ({
    type: MapFilterActionsEnum.DELETE_REGION,
    payload,
  }),
  setKeyword: (payload: string): SetKeyword => ({
    type: MapFilterActionsEnum.SET_KEYWORD,
    payload,
  }),
  cleanKeyword: (payload: []): SetCleanKeyword => ({
    type: MapFilterActionsEnum.CLEAN_KEYWORD,
    payload,
  }),

  setSquareFilter: (payload: number[]): SetSquareFilter => ({
    type: MapFilterActionsEnum.SET_SQUARE_FILTER,
    payload,
  }),
  cleanSquareFilter: () => (dispatch: AppDispatch) => {
    dispatch(MapFilterActionCreators.setSquareFilter([0, maxSquare]));
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },

  deleteKeyword: (payload: string): DeleteKeyword => ({
    type: MapFilterActionsEnum.DELETE_KEYWORD,
    payload,
  }),
  setEdrCode: (payload: string): SetEdrCode => ({
    type: MapFilterActionsEnum.SET_EDR_CODE,
    payload,
  }),
  setDkbsCode: (payload: string): SetDkbsCode => ({
    type: MapFilterActionsEnum.SET_DKBS_CODE,
    payload,
  }),
  cleanDkbsCode: (payload: []): SetCleanDkbsCode => ({
    type: MapFilterActionsEnum.CLEAN_DKBS_CODE,
    payload,
  }),
  cleanEdrCode: (payload: []): SetCleanEdrCode => ({
    type: MapFilterActionsEnum.CLEAN_EDR_CODE,
    payload,
  }),
  deleteEdrCode: (payload: string): DeleteEdrCode => ({
    type: MapFilterActionsEnum.DELETE_EDR_CODE,
    payload,
  }),
  setNpId: (payload: string): SetNpId => ({
    type: MapFilterActionsEnum.SET_NP_ID,
    payload,
  }),
  cleanNpId: (payload: []): SetCleanNpId => ({
    type: MapFilterActionsEnum.CLEAN_NP_ID,
    payload,
  }),
  deleteNpId: (payload: string): DeleteNpId => ({
    type: MapFilterActionsEnum.DELETE_NP_ID,
    payload,
  }),
  deleteDkbsCode: (payload: string): DeleteDkbs => ({
    type: MapFilterActionsEnum.DELETE_DKBS_CODE,
    payload,
  }),
};
