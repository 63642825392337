import React from "react";
import { Descriptions, Tag } from "antd";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { IMainFilterTemplateValue } from "../../../../types";
import { colors } from "../../../../constants";
import stringUtil from "../../../../util/stringUtil";

type KeyValueDisplayProps = {
  data: IMainFilterTemplateValue;
  isShown?: boolean;
};

const RobotoFonted = styled.div`
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0.0015em !important;
  * {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0.0015em !important;
  }
`;
const KeyValueDisplay: React.FC<KeyValueDisplayProps> = ({ data }) => {
  const { t: tFilterTemplate } = useTranslation("translation", {
    keyPrefix: "filterTemplate",
  });
  if (!data) return null;

  return (
    <RobotoFonted>
      <Descriptions column={1} size="small" bordered>
        <Descriptions.Item
          label={tFilterTemplate(
            "newsLettersAppliedForThisFilters",
            "Newsletters"
          )}
        >
          {data.emails && data.emails.length && data.period
            ? tFilterTemplate("Applied", "Applied")
            : tFilterTemplate("NotApplied", "Not applied")}
        </Descriptions.Item>

        {data.emails.length && (
          <Descriptions.Item label={tFilterTemplate("Emails", "Emails")}>
            {data.emails.map((email, index) => (
              <Tag key={index}>{email} </Tag>
            ))}
          </Descriptions.Item>
        )}
        {data.emails?.length && data.period && (
          <Descriptions.Item
            label={tFilterTemplate("Newsletter Period", "Newsletter Period")}
          >
            {data.period} {tFilterTemplate("days", "days")}
          </Descriptions.Item>
        )}
        {data.aimap_classifier?.length && (
          <Descriptions.Item label={"Тип об'єктів"}>
            {data.aimap_classifier.map((aimap, index) => (
              <Tag color={colors.darkD200} key={index}>
                {aimap}
              </Tag>
            ))}
          </Descriptions.Item>
        )}
        {data.consequence_class?.length && (
          <Descriptions.Item label={"Наслідки"}>
            {data.consequence_class.map((consequence, index) => (
              <Tag color={colors.darkD200} key={index}>
                {["1", "2", "3"].includes(consequence)
                  ? "CC" + consequence
                  : consequence}
              </Tag>
            ))}
          </Descriptions.Item>
        )}
        {data.construction_type?.length && (
          <Descriptions.Item label={"Тип будівництва"}>
            {data.construction_type.map((construction, index) => (
              <Tag color={colors.darkD200} key={index}>
                {construction}
              </Tag>
            ))}
          </Descriptions.Item>
        )}
        {data.region?.length && (
          <Descriptions.Item label={"Регіон"}>
            {data.region.map((region, index) => (
              <Tag color={colors.darkD300} key={index}>
                {region}
              </Tag>
            ))}
          </Descriptions.Item>
        )}
        {data.np_id?.length && (
          <Descriptions.Item label={"Фіз. особи"}>
            {data.np_id.map((np_id, index) => (
              <Tag color={colors.darkD300} key={index}>
                {np_id}
              </Tag>
            ))}
          </Descriptions.Item>
        )}
        {data.edrpou_code?.length && (
          <Descriptions.Item label={"Код ЄДРПОУ"}>
            {data.edrpou_code.map((code, index) => (
              <Tag color={colors.darkD300} key={index}>
                {code}
              </Tag>
            ))}
          </Descriptions.Item>
        )}
        {data.keyword?.length && (
          <Descriptions.Item label={"Пошукові слова"}>
            {data.keyword.map((keyword, index) => (
              <Tag color={colors.darkD300} key={index}>
                {keyword}
              </Tag>
            ))}
          </Descriptions.Item>
        )}
        {data.square_filter && (
          <Descriptions.Item label={"Площа"}>
            {data.square_filter[0]} - {data.square_filter[1]} м²
          </Descriptions.Item>
        )}
        {data.dk_018_2000?.length && (
          <Descriptions.Item label={"ДКБС код"}>
            {data.dk_018_2000.map((dk, index) => (
              <Tag color={colors.darkD300} key={index}>
                {dk}{" "}
              </Tag>
            ))}
          </Descriptions.Item>
        )}
        {data.stage_building?.length && (
          <Descriptions.Item label={"Етап будівництва"}>
            {data.stage_building.map((stage, index) => (
              <Tag color={colors.darkD200} key={index}>
                {stage}{" "}
              </Tag>
            ))}
          </Descriptions.Item>
        )}
        {data.stage_documentation?.length && (
          <Descriptions.Item label={"Етап документації"}>
            {data.stage_documentation.map((stage, index) => (
              <Tag color={colors.darkD200} key={index}>
                {stringUtil.sliceAndEllipsis(stage)}
              </Tag>
            ))}
          </Descriptions.Item>
        )}
      </Descriptions>
    </RobotoFonted>
  );
};

const StyledContainer = styled.div`
  display: grid;
  gap: 8px;
  margin-top: 8px;
`;

const SavedFiltersDetails: React.FC<KeyValueDisplayProps> = ({
  data,
  isShown,
}) => {
  return (
    <StyledContainer>
      {isShown && <KeyValueDisplay data={data} />}
    </StyledContainer>
  );
};

export default SavedFiltersDetails;
