import { uniq } from "lodash";
import {
  MapFilterActionsEnum,
  MapFiltersAction,
  ICompanyFilters,
} from "./types";
import { v4 as uuidv4 } from "uuid";

const initialState: ICompanyFilters = {
  isSuggestionsLoading: false,
  export__range: "",
  import__range: "",
  income__range: "",
  selectedDocList: [{ id: uuidv4(), value: "1" }],
  isStanFilterActive: false,
  isCompanyDateFilterActive: false,
  kved_is_main: false,

  kved: [],
  stanFilterArray: ["зареєстровано"],
  date_added__gte: "",
  date_added__lte: "",
  suggestions: [],
  activeSuggestions: [],
  region: [],
  edrpou: [],
};

export default function mapFiltersReducer(
  state = initialState,
  action: MapFiltersAction
) {
  switch (action.type) {
    case MapFilterActionsEnum.SET_SUGGESTION_LOADING:
      return { ...state, isSuggestionsLoading: action.payload };
    case MapFilterActionsEnum.SET_KVED_IS_MAIN:
      return { ...state, kved_is_main: action.payload };
    case MapFilterActionsEnum.TOGGLE_STAN_FILTER:
      if (state.stanFilterArray.includes(action.payload)) {
        return {
          ...state,
          stanFilterArray: state.stanFilterArray.filter(
            (item) => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          stanFilterArray: [...state.stanFilterArray, action.payload],
        };
      }

    case MapFilterActionsEnum.CLEAN_STAN_FILTER:
      return { ...state, stanFilterArray: [] };

    case MapFilterActionsEnum.SET_COMPANY_SUGGESTION:
      return { ...state, suggestions: [...action.payload] };

    case MapFilterActionsEnum.SET_COMPANY_ACTIVE_SUGGESTION:
      const suggestions = [...state.activeSuggestions, action.payload];
      const sudCodes = suggestions.map((item) => item.code);
      const uniqueSudCodes = uniq(sudCodes);
      const uniqSuggestions = uniqueSudCodes.map((item) =>
        suggestions.find((sud) => sud.code === item)
      );
      return {
        ...state,
        activeSuggestions: uniqSuggestions,
      };

    case MapFilterActionsEnum.CLEAN_COMPANY_SUGGESTION:
      return { ...state, activeSuggestions: [] };

    case MapFilterActionsEnum.DELETE_COMPANY_ACTIVE_SUGGESTION:
      return {
        ...state,
        activeSuggestions: state.activeSuggestions.filter(
          (item) => item !== action.payload
        ),
      };

    case MapFilterActionsEnum.SET_COMPANY_REGION:
      return { ...state, region: [...state.region, action.payload] };

    case MapFilterActionsEnum.CLEAN_COMPANY_REGION:
      return { ...state, region: [] };

    case MapFilterActionsEnum.DELETE_COMPANY_REGION:
      return {
        ...state,
        region: state.region.filter((item) => item !== action.payload),
      };
    case MapFilterActionsEnum.SET_DATE_ADDED_LTE:
      return { ...state, date_added__lte: action.payload };
    case MapFilterActionsEnum.SET_DATE_ADDED_GTE:
      return { ...state, date_added__gte: action.payload };
    case MapFilterActionsEnum.SET_EXPORT_RANGE:
      return { ...state, export__range: action.payload };
    case MapFilterActionsEnum.SET_INCOME_RANGE:
      return { ...state, income__range: action.payload };
    case MapFilterActionsEnum.SET_IMPORT_RANGE:
      return { ...state, import__range: action.payload };

    case MapFilterActionsEnum.SET_COMPANY_EDR_CODE:
      return { ...state, edrpou: [...state.edrpou, action.payload] };

    case MapFilterActionsEnum.CLEAN_COMPANY_EDR_CODE:
      return { ...state, edrpou: [] };

    case MapFilterActionsEnum.DELETE_COMPANY_EDR_CODE:
      return {
        ...state,
        edrpou: state.edrpou.filter((item) => item !== action.payload),
      };

    case MapFilterActionsEnum.SET_COMPANY_KVED:
      return { ...state, kved: [...state.kved, action.payload] };

    case MapFilterActionsEnum.CLEAN_COMPANY_KVED:
      return { ...state, kved: [] };

    case MapFilterActionsEnum.DELETE_COMPANY_KVED:
      return {
        ...state,
        kved: state.kved.filter((item) => item !== action.payload),
      };

    default:
      return state;
  }
}
