import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useTranslation } from "react-i18next";
import { apiUrl } from "../../constants/constants";

const Download = () => {
  const location = useLocation();
  const { email } = useTypedSelector((state) => state.auth.user);

  const { t } = useTranslation("translation", { keyPrefix: "downloadPage" });
  const STR = `${apiUrl}/send/objects` + location.search + "&mail=" + email;

  useEffect(() => {
    axios.get(STR, {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const onClose = () => {
      window.opener = null;
      window.open("/main", "_self");
      window.close();
    };
    setTimeout(() => {
      onClose();
    }, 3000);
    // eslint-disable-next-line
  }, []);

  return <h1>{t("title")}</h1>;
  // return <h1>You can close this window</h1>;
};

export default Download;
