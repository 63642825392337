import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { CustomCheckbox } from "../../../../../share/Input/Input";
import { useTranslation } from "react-i18next";
import FilterButtonWithModal from "../../../../../share/filters/FilterButtonWithModal";
import { statusFilterTranslationMap } from "../../../../../constants/filterTranslationMapping";
import useSearchParams from "../../../../../hooks/useSearchParams";
import { AvailableCC } from "../../../../../constants/constants";

const StanFilter = () => {
  const { stanFilterArray } = useTypedSelector((state) => state.companyFilters);
  const filterId = "companyStan";
  const { status } = useTypedSelector((state) => state.mapCompanyInfo.params);
  const { toggleStanFilterObject, typeStanClean } = useActions();

  const handleChange = (type: string) => {
    toggleStanFilterObject(type);
  };

  const handleClean = () => {
    typeStanClean();
  };

  const { t: tStatus } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.companies.statusFilter",
  });

  const { t: tOption } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.companies.statusFilter.values",
  });

  const filterButtonText = tStatus("title");
  const pinText =
    stanFilterArray.length > 0 && stanFilterArray.length.toString();

  const isUaRegion =
    useSearchParams().getSearchParams().region === AvailableCC.ua;

  return (
    <FilterButtonWithModal
      title={filterButtonText}
      pinText={pinText}
      filterId={filterId}
      onClear={handleClean}
      left={-216}
      width={512}
    >
      {status?.map((type: string) => {
        const option = isUaRegion
          ? tOption(statusFilterTranslationMap[type])
          : type;
        return (
          <CustomCheckbox
            value={type}
            key={type}
            checked={stanFilterArray.includes(type)}
            onChange={() => handleChange(type)}
          >
            {option.length > 45 ? option.slice(0, 45) + ".." : option}
          </CustomCheckbox>
        );
      })}
    </FilterButtonWithModal>
  );
};

export default StanFilter;
