import { httpClient, HttpClient } from "./HTTPService";
import { store } from "../index";
import { IMainFilterTemplateValue, ITemplate } from "../../types";

const token = store.getState().auth.token.access;

export type IReqTemplate = {
  account_id?: number | string;
  name: string;
  is_mailing: boolean;
  description: string;
  json?: Partial<IMainFilterTemplateValue>;
  value?: Partial<IMainFilterTemplateValue>;
};

export type IDeleteTemplate = {
  account_id: number | string;
  template_id: number | string;
};

class TemplatesService {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async getTemplates(accountId) {
    try {
      return this.httpClient.get(`templates/${accountId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  //getTemplateById
  public async getTemplateById(accountId: number, templateId: number) {
    return httpClient.get(`templates/${accountId}/${templateId}/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
  public async postTemplate(data: IReqTemplate) {
    return httpClient.post<ITemplate>(`templates/`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
  //update
  public async updateTemplate(data: IReqTemplate, templateId: string | number) {
    return httpClient.patch<ITemplate>(`templates/${templateId}/`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  //delete
  public async deleteTemplate(data: IDeleteTemplate) {
    return httpClient.delete(`templates/`, {
      data,
      headers: { Authorization: `Bearer ${token}` },
    });
  }
}

export const templatesService = new TemplatesService(httpClient);
