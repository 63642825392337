import { httpClient, HttpClient } from "./HTTPService";
import { store } from "../index";

const token = store.getState().auth.token.access;

export type IReq = any;
export interface IGoogleDriveResponse {
  message: string;
  link: string;
}

class FeedbackService {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async postFeedback(data: IReq) {
    return this.httpClient.post<any>(
      `feedback/`,
      { data: data },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  }

  public async postAttachmentsToGoogleDrive(files: File[]) {
    const formData = new FormData();
    // add files to formData.files
    files.forEach((file) => {
      formData.append("files", file);
    });

    return this.httpClient.post(`google-drive/`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export const feedbackService = new FeedbackService(httpClient);
