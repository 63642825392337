import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { CustomCheckbox } from "../../../../../share/Input/Input";
import { useTranslation } from "react-i18next";
import { CheckboxList } from "../../../../../share/filters";
import { FilterIdsEnum } from "../../../../../store/reducers/_maps/main/mapFilters/types";
import FilterButtonWithModal from "../../../../../share/filters/FilterButtonWithModal";
import { categoryFilterTranslationMap } from "../../../../../constants/filterTranslationMapping";
import useSearchParams from "../../../../../hooks/useSearchParams";
import { AvailableCC } from "../../../../../constants/constants";

const CategoryFilter = () => {
  const filterId: FilterIdsEnum = "usaidCategory";
  const { category_array } = useTypedSelector((state) => state.usaidMapFilters);
  const { category } = useTypedSelector((state) => state.mapUSAIDInfo.params);
  const { toggleUSAIDCategoryFilter, USAIDCategoryFilterClean, applyFilter } =
    useActions();

  const handleChange = (value: string) => {
    toggleUSAIDCategoryFilter(value);
  };

  const handleClean = () => {
    USAIDCategoryFilterClean();
  };

  const handleClick = () => {
    applyFilter();
  };

  const { t: tCategory } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.usaid.categoryFilter",
  });

  const { t: tOption } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.usaid.categoryFilter.values",
  });

  const typeFilterText = tCategory("title");
  const pinText = category_array.length > 0 && category_array.length;

  const isUaRegion =
    useSearchParams().getSearchParams().region === AvailableCC.ua;

  return (
    <FilterButtonWithModal
      title={typeFilterText}
      pinText={pinText}
      filterId={filterId}
      onClear={handleClean}
      left={-100}
      width={300}
    >
      <CheckboxList>
        {category?.length &&
          [...category].sort().map((value: string) => (
            <CustomCheckbox
              value={value}
              key={value}
              checked={category_array.includes(value)}
              onChange={() => handleChange(value)}
              onClick={() => handleClick()}
            >
              {isUaRegion
                ? tOption(categoryFilterTranslationMap[value])
                : value}
            </CustomCheckbox>
          ))}
      </CheckboxList>
    </FilterButtonWithModal>
  );
};

export default CategoryFilter;
