import { useMemo } from "react";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import { formatISO } from "date-fns";
import { useLazyEffect } from "../../../../hooks/useLazyEffect";

const usePersonsMapFilters = () => {
  const { subscribe } = useTypedSelector((state) => state.currentAccount);
  const { offset } = useTypedSelector((state) => state.mapInterface);
  const {
    date_added__gte,
    date_added__lte,
    activeSuggestions,
    stanFilterArray,
    region,
    hash,
    kved_is_main,
    kved,
  } = useTypedSelector((state) => state.personFilters);
  const { hash_ids } = useTypedSelector((state) => state.mapPersonInfo);

  const { getDefaultPersonResult } = useActions();

  const stanSearch = () =>
    stanFilterArray.length
      ? stanFilterArray
      : subscribe?.permissions?.consequence_class?.length
      ? subscribe?.permissions?.consequence_class
      : [];
  const regionSearch = () =>
    region.length ||
    (region.length && subscribe?.permissions?.region?.includes(region[0]))
      ? region
      : subscribe?.permissions?.region?.length
      ? subscribe?.permissions?.region
      : [];
  const hashSearch = () => (hash.length ? hash : []);
  const toDateSearch = () =>
    date_added__lte !== ""
      ? formatISO(
          new Date(date_added__lte).setDate(
            new Date(date_added__lte).getDate() + 1
          )
        )
      : "";
  const fromDateSearch = () =>
    date_added__gte !== ""
      ? formatISO(
          new Date(date_added__gte).setDate(
            new Date(date_added__gte).getDate() - 1
          )
        )
      : "";

  const filterByCodes = ["in", "RECORD", ...hash_ids];
  const filterByRegion = ["in", "region", ...regionSearch()];
  const filterByHash = ["in", "hash", ...hashSearch()];
  const filterByStan = ["in", "STAN", ...stanSearch()];
  const filterToDate = ["<=", "registration_date_1", toDateSearch()];
  const filterFromDate = [">=", "registration_date_1", fromDateSearch()];
  const filterLatGTE = [">=", "lat", 44.386389];
  const filterLatLTE = ["<=", "lat", 52.377778];
  const filterLonGTE = [">=", "lon", 22.138056];
  const filterLonLTE = ["<=", "lon", 40.223611];
  const filters = [
    filterByStan,
    filterByCodes,
    filterToDate,
    filterFromDate,
    filterLonLTE,
    filterLatGTE,
    filterLatLTE,
    filterByRegion,
    filterByHash,
    filterLonGTE,
  ].filter(
    (item) =>
      item.length >= 3 &&
      item[item.length - 1] !== "" &&
      item[item.length - 1] !== 0
  );
  if (hash.length !== 0 || kved.length !== 0) {
    filters.push(filterByCodes);
  }

  useLazyEffect(() => {
    getDefaultPersonResult();
  }, [
    offset,
    date_added__gte,
    date_added__lte,
    activeSuggestions,
    stanFilterArray,
    region,
    hash,
    kved_is_main,
    kved,
  ]);

  const mapFilter = useMemo(() => ["all", ...filters], [filters]);
  return mapFilter;
};

export default usePersonsMapFilters;
