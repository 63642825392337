import styled from "@emotion/styled";
import { colors, zIndexes } from "../../../../constants";

const PopupContentContainer = styled.div`
  position: relative;
  padding: 8px;
  padding-top: 32px;
  padding-bottom: 16px;
  z-index: ${zIndexes.detailsModal + 1};
  background: ${colors.darkD75};
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  max-height: 400px;
  display: grid;
  overflow-y: auto;
  h3 {
    padding: 8px;
    position: fixed;
    width: 100%;
    background-color: ${colors.darkD75};
    color: ${colors.darkD200};
    border-radius: 8px 8px 0 0;
    //styleName: Body 3/Regular 14;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }

  &:hover {
    z-index: 1001;
  }
`;

export default PopupContentContainer;
