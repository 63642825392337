import React from "react";
import LogoSvg from "../../assets/svg/LogoOrange.svg";
import styles from "./Logo.module.css";
import { RouteNames } from "../../routes";

const Logo = () => {
  return (
    <a
      href={RouteNames.AIMAP}
      target="_blank"
      rel="noreferrer"
      className={styles.logoWrap}
    >
      <img src={LogoSvg} alt="Logo" />
      <p className={styles.logoTitle}>aimap</p>
    </a>
  );
};

export default Logo;
