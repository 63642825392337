import { Tooltip } from "antd";
import { FC } from "react";
import CustomIcon from "../../../../share/CustomIcon/CustomIcon";
import styled from "@emotion/styled";
import { breakpoints, colors, zIndexes } from "../../../../constants";
import { ReactComponent as Filter } from "../../../../assets/svg/filter_nofill.svg";
import { useTranslation } from "react-i18next";
import LoginNotificationPopup from "../../../../share/NotificationPopInformation/LoginNotificationPopup/LoginNotificationPopup";
import useStoreSnippets from "../../../../hooks/useStoreSnippets";
import { useTipsContext } from "../../../../hooks/useTips";
import { datasets } from "../../../../constants/mapConstants";

const StyledButton = styled.button<{ isDisabled?: boolean; active?: boolean }>`
  display: flex;
  position: relative;

  align-items: center;
  text-align: center;
  width: auto;
  font-weight: 600;
  height: 40px;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  padding: 8px;
  background: ${(props) =>
    props.isDisabled
      ? colors.darkD75
      : props.active
      ? colors.darkD100
      : "none"};
  border: 1px solid ${colors.darkD100};
  border-color: ${(props) =>
    !props.isDisabled ? colors.darkD400 : colors.darkD100};
  border-radius: 4px;
  z-index: ${zIndexes.filters};
  pointer-events: ${(props) => (props.isDisabled ? "none" : "auto")};
  cursor: ${(props) => (!props.isDisabled ? "pointer" : "not-allowed")};

  @media screen and (max-width: ${breakpoints.medium + "px"}) {
    height: 32px;
    padding: 4px;
  }
`;

interface ISavedFiltersButton {
  onClick?: () => void;
  isActive?: boolean;
  isDisabled?: boolean;
}

const SavedFiltersButton: FC<ISavedFiltersButton> = ({
  onClick,
  isActive = false,
}) => {
  const isDisabledForDataset = useStoreSnippets().dataset !== datasets.objects;
  const { t: tFilter } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.filtersActions",
  });
  const tipId = "savedFilters";
  const { setLastOpenedTipId } = useTipsContext();

  const tooltip = tFilter("savedFilters");
  const { isGuestAccount } = useStoreSnippets();

  const handleClick = () => {
    if (isGuestAccount) {
      setLastOpenedTipId(tipId);
    }
    if (!isDisabledForDataset) {
      onClick && onClick();
    }
  };
  return (
    <>
      <LoginNotificationPopup
        id={tipId}
        rightPx={-494}
        bottomPx={-152}
        cornerTopPosition={16}
      />
      <Tooltip title={tooltip}>
        <StyledButton
          onClick={handleClick}
          active={isActive}
          isDisabled={isDisabledForDataset}
        >
          <CustomIcon
            icon={Filter}
            color={isDisabledForDataset ? colors.darkD200 : colors.darkD400}
          />
        </StyledButton>
      </Tooltip>
    </>
  );
};

export default SavedFiltersButton;
