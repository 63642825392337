import {
  CurrentUSAIDObject,
  ICurrentUSAID,
} from "../../../../../models/CurrentUSAIDObject";

interface MapStateParams {
  [key: string]: any;
}

export interface IMapUSAIDInfoState {
  isObjectsLoading: boolean;
  params: MapStateParams;
  hash_ids: [];
  usaid_company: CurrentUSAIDObject;
  count: number;
  usaid_dataset: CurrentUSAIDObject[];
  next: null | string;
}

export enum SikaMapActionsEnum {
  SET_IS_USAID_LOADING = "SET_IS_USAID_LOADING",
  SET_MAP_USAID_LIST = "SET_MAP_USAID_LIST",
  SET_MAP_USAID = "SET_MAP_USAID",
}

export interface SetObjectsLoading {
  type: SikaMapActionsEnum.SET_IS_USAID_LOADING;
  payload: boolean;
}

export interface SetMapUSAIDAction {
  type: SikaMapActionsEnum.SET_MAP_USAID;
  payload: ICurrentUSAID;
}

export interface SetMapUSAIDListAction {
  type: SikaMapActionsEnum.SET_MAP_USAID_LIST;
  payload: IMapUSAIDInfoState;
}

export type MapObjectAction =
  | SetObjectsLoading
  | SetMapUSAIDAction
  | SetMapUSAIDListAction;
