import React, { useState } from "react";
import styles from "../../../main/ObjectDetails/ObjectDetails.module.scss";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { CustomLoader } from "../../../../../share/Loader/Loader";
import HiddenText from "../../../../../assets/svg/Hidden Text.svg";
import ModalWithHeader from "../../../../../share/ModalWithHeader/ModalWithHeader";
import Attention from "../../../../../assets/svg/AttentionHand.svg";
import ArrowRight from "../../../../../assets/svg/arrowRight.svg";
import { format, parseISO } from "date-fns";
import uk from "date-fns/locale/uk";
import { RouteNames } from "../../../../../routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IPropertiesInfo {
  IS_PRIMARY?: string;
  CODE: string;
  NAME: string;
}

const Details = () => {
  const { person } = useTypedSelector((state) => state.mapPersonInfo);
  const { isLoading } = useTypedSelector((state) => state.auth);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  const { t: tDetails } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.objectDetails.details",
  });

  return (
    <div>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className={styles.detailsTab}>
          <h4 className={styles.tabTitle}>{tDetails("characteristic")}</h4>
          <table className={styles.tabTable}>
            <tbody>
              <tr className={styles.tabRow}>
                <td>{tDetails("dateCreated")}:</td>
                <td>
                  {person?.registration_date_1
                    ? format(
                        parseISO(person?.registration_date_1),
                        "dd-MM-yyyy",
                        { locale: uk }
                      )
                    : tDetails("notDefined")}
                </td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("address")}:</td>
                <td>
                  {person?.ADDRESS === "#####" ? (
                    <button
                      type="button"
                      className={styles.noRulesLink}
                      onClick={() => setModalVisible(true)}
                      data-tooltip={tDetails("showAll")}
                    >
                      <img src={HiddenText} alt="no rules" />
                    </button>
                  ) : (
                    person?.ADDRESS
                  )}
                </td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("status")}:</td>
                <td>{person?.STAN}</td>
              </tr>
            </tbody>
          </table>
          {person?.activities?.length > 0 && (
            <>
              <h4 className={styles.tabTitle}>{tDetails("activityType")}</h4>
              <table className={styles.tabTable}>
                <tbody>
                  {person.activities.map(
                    (prop: IPropertiesInfo, index: number) => (
                      <tr key={index} className={styles.tabRow}>
                        <td>
                          {prop.CODE}{" "}
                          {prop.IS_PRIMARY === "так" ? " - основний" : ""}
                        </td>
                        <td>
                          {prop?.NAME === "#####" ? (
                            <button
                              type="button"
                              className={styles.noRulesLink}
                              onClick={() => setModalVisible(true)}
                              data-tooltip={tDetails("showAll")}
                            >
                              <img src={HiddenText} alt="no rules" />
                            </button>
                          ) : (
                            prop.NAME
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </>
          )}
          <h4 className={styles.tabTitle}>{tDetails("description")}</h4>
          <div className={styles.tabRow}>
            <p className={styles.tabText}>{person?.NAME}</p>
          </div>
        </div>
      )}
      <ModalWithHeader
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        title={tDetails("dataNotAvailable")}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalInfoWrapper}>
            <img src={Attention} alt="attention" />
            <p className={styles.infoText}>{tDetails("warn")}</p>
          </div>
          <div className={styles.bodyWrapper}>
            <p className={styles.bodyText}>{tDetails("warn2")}</p>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <button
            onClick={() => navigate(RouteNames.SUBSCRIPTIONS)}
            className={styles.modalButton}
          >
            {tDetails("updateSubscriptionPlan")}
            <img src={ArrowRight} alt="next" />
          </button>
        </div>
      </ModalWithHeader>
    </div>
  );
};

export default Details;
