import { useRef, useState } from "react";
import { ITemplate } from "../../../../types";
import styled from "@emotion/styled";
import CustomButton from "../../../../share/Button/Button";
import CustomIcon from "../../../../share/CustomIcon/CustomIcon";
import { ReactComponent as EllipsisIcon } from "../../../../assets/svg/Button_menu_nobg.svg";
import useOutsideClick from "../../../../hooks/useClickOutside";
import { breakpoints, icons } from "../../../../constants";
import { useActions } from "../../../../hooks/useActions";
import SavedFiltersDetails from "./SavedFiltersDetails";
import useAppContext from "../../../../hooks/useAppContext";
import { useTranslation } from "react-i18next";
import useFormatDistanceToNowLocalized from "../../../../hooks/useDateAgoLocale";

type Props = {
  template: ITemplate;
};

const StyledName = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.002em;
  margin-bottom: 4px;
  span {
    color: var(--Dark-D300, #575f6e);
    font-weight: 500;
  }
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledDate = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0016em;
  span {
    color: var(--Dark-D300, #575f6e);
    font-weight: 500;
  }
  @media (max-width: ${breakpoints.small}px) {
    color: var(--Dark-D300, #575f6e);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.017px;
  }
`;

const StyledActions = styled.div`
  position: relative;
  cursor: pointer;
`;

const StyledActionsList = styled.div`
  list-style-type: none;
  padding: 4px 0;
  position: absolute;
  width: max-content;
  height: auto;
  top: 50%;
  right: 100%;
  transform: translate(0, -50%);
  background: var(--primary-white-color);
  box-shadow: 0 8px 12px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
`;

const StyledButtons = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 8px;
`;

const StyledActionButton = styled(CustomButton)`
  font-weight: 500;
  display: block;
`;

const TemplateListItem = ({ template }: Props) => {
  const { t: tList } = useTranslation("translation", {
    keyPrefix: "filterTemplate.list",
  });

  const { messageApi } = useAppContext();

  const formattedDate = useFormatDistanceToNowLocalized(
    template.date_updated ?? template.date_added
  );

  const [isActionShown, setIsActionShown] = useState<boolean>(false);
  const [isDetailsShown, setIsDetailsShown] = useState<boolean>(false);
  const dropdownActionsListRef = useRef<HTMLDivElement>(null);
  const { deleteTemplate, toggleFilter, setSelectedTemplate, applyTemplate } =
    useActions();
  const createdOrUpdatedText = tList("updated");

  useOutsideClick(dropdownActionsListRef, () => setIsActionShown(false));

  const toggleActionsVisible = () => {
    setIsActionShown((state) => !state);
  };

  function handleApplyTemplate(template: ITemplate) {
    toggleFilter("");
    applyTemplate(
      template,
      () => messageApi.success(tList("notification.applied"), 5),
      () => messageApi.error(tList("notification.error"), 5)
    );
  }

  function handleEditTemplate(template: ITemplate) {
    setIsActionShown(false);
    setSelectedTemplate(template);
    toggleFilter("addSavedFiltersModal");
  }

  function handleDeleteTemplate(id: string) {
    setIsActionShown(false);
    deleteTemplate(id);
    messageApi.success(tList("notification.deleted", { id }));
  }

  return (
    <>
      <StyledRow onDoubleClick={() => handleApplyTemplate(template)}>
        <div>
          <StyledName>
            <span>
              {tList("template")} #{template.id}:
            </span>{" "}
            {template.name}
          </StyledName>
          <StyledDate>
            <span>{createdOrUpdatedText}</span> {formattedDate}
          </StyledDate>
        </div>
        <StyledActions ref={dropdownActionsListRef}>
          <CustomIcon
            size={"32px"}
            icon={EllipsisIcon}
            onClick={toggleActionsVisible}
          />
          {isActionShown && (
            <StyledActionsList>
              <StyledActionButton
                type="text"
                size="small"
                onClick={() => handleEditTemplate(template)}
              >
                {tList("actions.edit")}
              </StyledActionButton>
              <StyledActionButton
                type="text"
                size="small"
                onClick={() => handleDeleteTemplate(template.id)}
              >
                {tList("actions.delete")}
              </StyledActionButton>
            </StyledActionsList>
          )}
        </StyledActions>
      </StyledRow>

      <StyledButtons>
        <CustomButton
          type="bordered"
          size="small"
          color="red"
          onClick={() => handleApplyTemplate(template)}
        >
          {tList("actions.apply")}
        </CustomButton>

        <CustomButton
          type="text"
          size="small"
          onClick={() => setIsDetailsShown(!isDetailsShown)}
          icon={isDetailsShown ? icons.ArrowUp : icons.ArrowDown}
        >
          {isDetailsShown ? tList("hideDetails") : tList("showDetails")}
        </CustomButton>
      </StyledButtons>
      <SavedFiltersDetails data={template.value} isShown={isDetailsShown} />
    </>
  );
};

export default TemplateListItem;
