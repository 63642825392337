import { useCallback, useEffect } from "react";
import ReactMapGL, {
  Layer,
  MapLayerMouseEvent,
  Source,
  ViewStateChangeEvent,
} from "react-map-gl";
import styles from "./USAIDMap.module.css";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import { useLazyEffect } from "../../../../hooks/useLazyEffect";
import JpBlue from "../../../../assets/svg/JpBlue.svg";
import NpGreen from "../../../../assets/svg/NpGreen.svg";

import CustomNavigationControl from "../../_mapComponents/CustomNavigationControl/CustomNavigationControl";
import CustomMarker from "../../_mapComponents/CustomMarker/CustomMarker";
import { mapboxAccessToken } from "../../../../constants/mapConstants";
import useSearchParams from "../../../../hooks/useSearchParams";
import useSelectedLanguage from "../../../../hooks/useSelectedLanguage";
import { changeMapboxLanguage } from "../../../../util/mapInterfaceUtil";
import useUSAIDFilters from "./useUSAIDFilters";
import MapWrapper from "../../../../share/mapComponents/MapWrapper";
import MapBadgeLoader from "../../_mapComponents/MapBadgeLoader";
import useMapObjects from "../../../../hooks/useMapObjects";
import PinnedSelectedMapObjectsList from "../../_mapComponents/PinnedSelectedMapObjectsList";
import CustomPopup from "../../_mapComponents/CustomPopup";
import CustomMapPopupItem from "../../_mapComponents/CustomPopup/CustomMapPopupItem";
import useMobileScreen from "../../../../hooks/useMobileScreen";

const USAIDMap = () => {
  const { isFilterBarActive, viewport, currentMarker, offset } =
    useTypedSelector((state) => state.mapInterface);
  const {
    activeSuggestions,
    region,
    edrpou,
    kved,
    economic_sector_array,
    activity_status_array,
    entity_type_array,
    category_array,
  } = useTypedSelector((state) => state.usaidMapFilters);
  const selectedMapObject = useTypedSelector(
    (state) => state.mapUSAIDInfo.usaid_company
  );
  const { usaidMapRef } = useTypedSelector((state) => state.mapObjectsInfo);

  const { setViewport, getDefaultUSAIDResult, setCurrentMapRef } = useActions();

  const mapFilter = useUSAIDFilters();

  const {
    currObjs,
    pinnedCurrObjs,
    setPinnedCurrObjs,
    markerClickHandler,
    markerHoverHandler,
  } = useMapObjects();

  useEffect(() => {
    setCurrentMapRef(usaidMapRef);
  }, [usaidMapRef]);

  const initialStyle: any = {
    id: "point",
    type: "circle",
    paint: {
      "circle-radius": 8,
      "circle-color": [
        "match",
        ["get", "entity_type"],
        "Юридична особа",
        "#3377FF",
        "Фізична особа",
        "#0EAD86",
        "#242731",
      ],
      "circle-stroke-width": 2,
      "circle-stroke-color": "#FFFFFF",
    },
  };

  useLazyEffect(() => {
    getDefaultUSAIDResult();
    // eslint-disable-next-line
  }, [
    offset,
    activeSuggestions,
    region,
    edrpou,
    kved,
    economic_sector_array,
    activity_status_array,
    entity_type_array,
    category_array,
  ]);

  useLazyEffect(
    () => {
      if (!selectedMapObject?.id) {
        getDefaultUSAIDResult();
      }
    },
    [viewport, selectedMapObject],
    700
  );

  useEffect(() => {
    // This function will be called every time `isFilterPanelVisible` changes.
    if (usaidMapRef.current) {
      // If the map instance is available, trigger the resize.
      usaidMapRef.current.resize();
    }
  }, [isFilterBarActive]); // Dependency array includes only the variable that triggers the resize.

  const { setSearchParam } = useSearchParams();
  const selectedLanguage = useSelectedLanguage();
  const handleMapLoad = useCallback(() => {
    const map = usaidMapRef.current?.getMap();
    if (map) {
      changeMapboxLanguage(map, selectedLanguage); // 'fr' for French, 'es' for Spanish, etc.
    }
  }, []);

  const getFeatures = (e: MapLayerMouseEvent) => {
    if (!e) return [];

    const layer = "usaid-aimap-dataset-layer";
    const features = usaidMapRef.current.queryRenderedFeatures(e.point, {
      layers: [layer],
      filter: mapFilter,
      validate: false,
    });
    return features;
  };

  const renderPopupItem = (currObj: any, index: number) => {
    return (
      <CustomMapPopupItem
        isSelected={
          selectedMapObject && currObj.properties.id === selectedMapObject?.id
        }
        key={index}
        onClick={() => {
          setSearchParam("hash_id", currObj.properties.id);
        }}
      >
        <div>
          {currObj.properties.entity_type === "Юридична особа" ? (
            <img src={JpBlue} alt="" />
          ) : (
            <img src={NpGreen} alt="" />
          )}
          {/* <img src={Jp} alt="" /> */}
          <h4 className={styles.titleStyle}>
            {" "}
            {currObj.properties.name.length > 37
              ? currObj.properties.name.slice(0, 30) + ".."
              : currObj.properties.name}
          </h4>
          <h4 className={styles.popUpClassifier}>
            {currObj.properties.activity_status_2022}
          </h4>
          <p className={styles.construction_type}>
            {currObj.properties.edrpou} &middot; {currObj.properties.category}
          </p>
        </div>
      </CustomMapPopupItem>
    );
  };

  const isMobile = useMobileScreen();
  return (
    <MapWrapper>
      {pinnedCurrObjs?.length > 0 && !selectedMapObject?.id && isMobile && (
        <PinnedSelectedMapObjectsList handleClose={() => setPinnedCurrObjs([])}>
          {pinnedCurrObjs.map((currObj, index) =>
            renderPopupItem(currObj, index)
          )}
        </PinnedSelectedMapObjectsList>
      )}
      <ReactMapGL
        {...viewport}
        mapboxAccessToken={mapboxAccessToken}
        ref={(instance) => (usaidMapRef.current = instance)}
        minZoom={5}
        maxZoom={15}
        maxPitch={1}
        dragRotate={false}
        mapStyle={"mapbox://styles/aimap/clktdrslc00sq01qyhi0mgpc9"}
        onLoad={handleMapLoad}
        onMove={(e: ViewStateChangeEvent) => {
          setViewport(e.viewState);
        }}
        onZoom={(e: ViewStateChangeEvent) => {
          setViewport(e.viewState);
        }}
        onMouseOver={(e) => markerHoverHandler(getFeatures(e))}
        onClick={(e) => markerClickHandler(getFeatures(e), "id")}
        onMouseMove={(e) => markerHoverHandler(getFeatures(e))}
      >
        <CustomNavigationControl />
        <MapBadgeLoader />

        <Source type="vector" url="mapbox://aimap.usaid_aimap_dataset_ts">
          <Layer
            filter={mapFilter}
            {...initialStyle}
            source-layer="usaid_aimap_dataset_layer"
          />
        </Source>
        {currentMarker && <CustomMarker marker={currentMarker} />}
        {currObjs?.length > 0 && (
          <CustomPopup
            latitude={+currObjs[0].properties.lat}
            longitude={+currObjs[0].properties.lon}
            itemsCount={currObjs.length}
          >
            {currObjs.map((currObj: GeoJSON.Feature, index: number) =>
              renderPopupItem(currObj, index)
            )}
          </CustomPopup>
        )}
      </ReactMapGL>
    </MapWrapper>
  );
};

export default USAIDMap;
