import USAIDMap from "../../../components/_maps/usaid/USAIDMap/USAIDMap";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useEffect, useRef } from "react";
import useSearchParams from "../../../hooks/useSearchParams";
import { RouteNames } from "../../../routes";
import { Navigate } from "react-router-dom";
import MapPage from "../../../share/MapPage/MapPage";
import Filters from "../../../components/_maps/usaid/USAIDMapFilters/Filters";
import DownloadResults from "../../../components/_maps/main/DownloadResults/DownloadResults";
import USAIDMapDetails from "../../../components/_maps/usaid/USAIDMapDetails/USAIDMapDetails";
import USAIDMapSearchResults from "../../../components/_maps/usaid/USAIDMapSearchResults/USAIDMapSearchResults";

const USAIDPage = () => {
  const { viewport } = useTypedSelector((state) => state.mapInterface);
  const {
    setUSAIDMapRef,
    getCurrentMapUSAID,
    setIsDetailsActive,
    setCurrentMarker,
  } = useActions();

  const { usaid_company: selectedObject } = useTypedSelector(
    (state) => state.mapUSAIDInfo
  );
  const usaid = useTypedSelector(
    (state) => state.currentAccount.subscribe?.permissions?.usaid
  );
  const { usaidMapRef } = useTypedSelector((state) => state.mapObjectsInfo);
  const { id: accountId } = useTypedSelector((state) => state.currentAccount);

  const initMapRef = useRef(null);

  const isAllowedDataset = usaid && usaid[0] === "1";

  useEffect(() => {
    if (!usaidMapRef) {
      setUSAIDMapRef(initMapRef);
    }
  }, [usaidMapRef]);

  const { getSearchParam } = useSearchParams();
  const hashId = getSearchParam("hash_id");

  useEffect(() => {
    if (hashId && isAllowedDataset) {
      getCurrentMapUSAID(+hashId, accountId);
    }
  }, [hashId]);

  useEffect(() => {
    if (selectedObject?.id) {
      setCurrentMarker({
        lat: +selectedObject.lat,
        lon: +selectedObject.lon,
      });
      setIsDetailsActive(true);
      usaidMapRef.current?.flyTo({
        center: [+selectedObject.lon, +selectedObject.lat],
        zoom: 15,
      });

      const latitude = +selectedObject.lat;
      const longitude = +selectedObject.lon;
      const zoom = viewport.zoom >= 12 ? viewport.zoom : 12;

      usaidMapRef.current?.flyTo({
        center: [longitude, latitude],
        duration: 2000,
        zoom,
      });
    }
  }, [selectedObject]);

  const count = useTypedSelector((state) => state.mapUSAIDInfo.count);

  if (!isAllowedDataset) {
    return <Navigate to={RouteNames.LOGIN} />;
  }

  return (
    <MapPage
      filter={<Filters />}
      SearchResults={USAIDMapSearchResults}
      downloadResults={<DownloadResults count={count} disabled />}
      objectDetails={<USAIDMapDetails />}
      map={<USAIDMap />}
      mapRef={usaidMapRef}
      setMapRef={setUSAIDMapRef}
    ></MapPage>
  );
};

export default USAIDPage;
