import styles from "../../main/Filters/Filters.module.scss";
import SearchFilter from "../../../SearchFilter/SearchFilter";
import DateFilter from "./DateFilter/DateFilter";
import { useActions } from "../../../../hooks/useActions";
import { CustomCheckbox } from "../../../../share/Input/Input";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import DatasetsDropdown from "../../_mapFilterComponents/DatasetsDropdown/DatasetsDropdown";
import RegionDropdown from "../../main/RegionDropdown/RegionDropdown";
import { useTranslation } from "react-i18next";
import StanFilter from "./StanFilter/StanFilter";
import SavedFiltersButton from "../../../templates/SavedFilters/SavedFiltersButton/SavedFiltersButton";
import ClearAllFiltersDesktopButton from "../../../../share/filters/ClearAllFiltersDesktopButton";
import MobileClearAllButton from "../../../../share/filters/MobileClearAllButton";
import AddMoreFiltersButton from "../../../templates/AddSavedFilters/AddMoreFiltersButton";
import { FilterGroup, SearchWrapper } from "../../../../share/filters";
import { useEffect } from "react";

const Filters = () => {
  const {
    setCleanAllPersonFilters,
    getDefaultPersonResult,
    setPersonKvedIsMain,
    setOffset,
    setPage,
  } = useActions();
  const { kved_is_main } = useTypedSelector((state) => state.personFilters);
  const currentAccountId = useTypedSelector(
    (state) => state.currentAccount?.id
  );
  const handleClick = () => {
    setOffset(0);
    setPage(1);
  };
  const handleBuildChange = () => {
    setPersonKvedIsMain(!kved_is_main);
    handleClick();
  };
  const handleClean = () => {
    setCleanAllPersonFilters();
    getDefaultPersonResult();
  };

  useEffect(() => {
    handleClean();
  }, [currentAccountId]);

  const { t: tPersons } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.persons",
  });

  return (
    <div
      className={`${styles.personsDatasetFilters} ${styles.filterMainWrapper}`}
    >
      <div className={styles.topContainer}>
        <div className={styles.regionsDatasetsWrapper}>
          <RegionDropdown />
          <DatasetsDropdown />
        </div>
        <div className={styles.topContainerButtons}>
          <SavedFiltersButton />
          <AddMoreFiltersButton />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <CustomCheckbox
          children={tPersons("mainKVEDsearch")}
          checked={kved_is_main}
          onChange={handleBuildChange}
          style={styles.checkboxLabel}
        ></CustomCheckbox>
      </div>
      <SearchWrapper>
        <SearchFilter />
      </SearchWrapper>

      <FilterGroup onClick={(event) => event.stopPropagation()}>
        <StanFilter />
        <DateFilter />
        <MobileClearAllButton onClick={handleClean} />
      </FilterGroup>
      <ClearAllFiltersDesktopButton onClick={handleClean} />
    </div>
  );
};

export default Filters;
