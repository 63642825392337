import React, {
  Dispatch,
  KeyboardEventHandler,
  SetStateAction,
  useState,
} from "react";
import CreatableSelect from "react-select/creatable";
import { MultiValue, OnChangeValue } from "react-select";
import "./CustomCreatableSelect.css";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { Option } from "../../pages/_auth/AddUserInAccount/AddingUserToAccount";

interface IProps {
  values: MultiValue<Option>;
  placeholder?: string;
  setValues: Dispatch<SetStateAction<MultiValue<Option>>>;
}

const components = {
  DropdownIndicator: null,
};

const EMAIL_REGEX = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;

const CustomCreatableSelect = ({ values, setValues, placeholder }: IProps) => {
  const { users, invites, subscribe } = useTypedSelector(
    (state) => state.currentAccount
  );
  const [inputValue, setInputValue] = useState("");

  const createNewValue = (label: string) => ({
    label,
    value: label,
  });

  const handleChange = (value: OnChangeValue<Option, true>) => {
    setValues(value);
  };

  const handleInputChange = (text: string) => {
    if (
      values.length !==
      subscribe?.permissions.max_users - (users?.length + invites?.length)
    ) {
      setInputValue(text);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue) return;
    switch (event.code) {
      case "Space":
      case "Tab":
      case "Enter":
        const newValues = createNewValue(inputValue);
        if (
          !values.some((el: Option) => el.label === inputValue) &&
          EMAIL_REGEX.test(inputValue)
        ) {
          setInputValue("");
          setValues([...values, newValues]);
        }
        event.preventDefault();
    }
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
    if (!inputValue) return;
    const newValues = createNewValue(inputValue);
    if (
      !values.some((el: Option) => el.label === inputValue) &&
      EMAIL_REGEX.test(inputValue)
    ) {
      setInputValue("");
      setValues([...values, newValues]);
    }
    event.preventDefault();
  };

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      value={values}
      placeholder={placeholder}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
    />
  );
};

export default CustomCreatableSelect;
