import { uniq } from "lodash";
import {
  MapFilterActionsEnum,
  MapFiltersAction,
  IPersonsFilters,
} from "./types";
import { v4 as uuidv4 } from "uuid";

const initialState: IPersonsFilters = {
  isSuggestionsLoading: false,
  selectedDocList: [{ id: uuidv4(), value: "1" }],
  kved_is_main: false,
  kved: [],
  stanFilterArray: ["зареєстровано"],
  date_added__gte: "",
  date_added__lte: "",
  suggestions: [],
  activeSuggestions: [],
  region: [],
  hash: [],
};

export default function mapFiltersReducer(
  state = initialState,
  action: MapFiltersAction
) {
  switch (action.type) {
    case MapFilterActionsEnum.SET_SUGGESTION_LOADING:
      return { ...state, isSuggestionsLoading: action.payload };

    case MapFilterActionsEnum.SET_PERSON_KVED_IS_MAIN:
      return { ...state, kved_is_main: action.payload };

    case MapFilterActionsEnum.TOGGLE_PERSON_STAN_FILTER:
      if (state.stanFilterArray.includes(action.payload as any)) {
        return {
          ...state,
          stanFilterArray: state.stanFilterArray.filter(
            (item) => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          stanFilterArray: [...state.stanFilterArray, action.payload],
        };
      }
    case MapFilterActionsEnum.CLEAN_PERSON_STAN_FILTER:
      return { ...state, stanFilterArray: [] };

    case MapFilterActionsEnum.SET_PERSON_SUGGESTION:
      return { ...state, suggestions: [...action.payload] };

    case MapFilterActionsEnum.SET_PERSON_ACTIVE_SUGGESTION:
      const suggestions = [...state.activeSuggestions, action.payload];
      const sudCodes = suggestions.map((item) => item.code);
      const uniqueSudCodes = uniq(sudCodes);
      const uniqSuggestions = uniqueSudCodes.map((item) =>
        suggestions.find((sud) => sud.code === item)
      );

      return {
        ...state,
        activeSuggestions: uniqSuggestions,
      };

    case MapFilterActionsEnum.CLEAN_PERSON_SUGGESTION:
      return { ...state, activeSuggestions: [] };

    case MapFilterActionsEnum.DELETE_PERSON_ACTIVE_SUGGESTION:
      return {
        ...state,
        activeSuggestions: state.activeSuggestions.filter(
          (item) => item !== action.payload
        ),
      };

    case MapFilterActionsEnum.SET_PERSON_REGION:
      return { ...state, region: [...state.region, action.payload] };

    case MapFilterActionsEnum.CLEAN_PERSON_REGION:
      return { ...state, region: [] };

    case MapFilterActionsEnum.DELETE_PERSON_REGION:
      return {
        ...state,
        region: state.region.filter((item) => item !== action.payload),
      };
    case MapFilterActionsEnum.SET_PERSON_DATE_ADDED_LTE:
      return { ...state, date_added__lte: action.payload };
    case MapFilterActionsEnum.SET_PERSON_DATE_ADDED_GTE:
      return { ...state, date_added__gte: action.payload };

    case MapFilterActionsEnum.SET_PERSON_HASH_CODE:
      return { ...state, hash: [...state.hash, action.payload] };

    case MapFilterActionsEnum.CLEAN_PERSON_HASH_CODE:
      return { ...state, hash: [] };

    case MapFilterActionsEnum.DELETE_PERSON_HASH_CODE:
      return {
        ...state,
        hash: state.hash.filter((item) => item !== action.payload),
      };

    case MapFilterActionsEnum.SET_PERSON_KVED:
      return { ...state, kved: [...state.kved, action.payload] };

    case MapFilterActionsEnum.CLEAN_PERSON_KVED:
      return { ...state, kved: [] };

    case MapFilterActionsEnum.DELETE_PERSON_KVED:
      return {
        ...state,
        kved: state.kved.filter((item) => item !== action.payload),
      };

    default:
      return state;
  }
}
