import { ReactNode, useEffect, useRef } from "react";
import Page from "../Page/Page";
import { breakpoints } from "../../constants";
import styled from "@emotion/styled";
import { MapConfig } from "../../store/reducers/_maps/main/map/types";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useMobileScreen from "../../hooks/useMobileScreen";
import { useActions } from "../../hooks/useActions";
import { useMapPageContext } from "./MapPageContext";

const StyledScreenWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  @media screen and (max-width: ${breakpoints.small + "px"}) {
    flex-direction: column-reverse;
  }
`;

const StyledPageContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;

  @media screen and (max-width: ${breakpoints.small + "px"}) {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }
`;

const StyledMapDetailsContainer = styled.div`
  @media screen and (max-width: ${breakpoints.small + "px"}) {
    position: relative;
    flex: 1;
  }
`;

const StyledFilterBar = styled.div`
  width: 560px;
  height: 100vh;
  background-color: var(--primary-white-color);
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset -1px -1px 1px #d7d9db;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 1px;
  z-index: 3;

  @media screen and (max-width: ${breakpoints.medium + "px"}) {
    width: 360px;
  }

  @media screen and (max-width: ${breakpoints.small + "px"}) {
    flex: 1;
    width: 100%;
    position: static;
  }
`;

interface IMapPage {
  filter: ReactNode | JSX.Element;
  SearchResults: React.FC<any>;
  downloadResults: ReactNode | JSX.Element;
  objectDetails: ReactNode | JSX.Element;
  map: ReactNode | JSX.Element;
  mapRef: any;

  setMapRef: any;
  config?: MapConfig;
}

const MapPage: React.FC<IMapPage> = ({
  filter,
  SearchResults,
  downloadResults,
  objectDetails,
  mapRef,
  setMapRef,
  map,
}) => {
  const { isDetailsActive, isFilterBarActive } = useTypedSelector(
    (state) => state.mapInterface
  );
  const initMapRef = useRef(null);
  const isMobile = useMobileScreen();
  const { setIsFilterActive } = useActions();
  useEffect(() => {
    if (!mapRef) {
      setMapRef(initMapRef);
    }
  }, [mapRef]);

  useEffect(() => {
    return () => {
      setIsFilterActive(true);
    };
  }, []);

  const { searchResultsRef } = useMapPageContext();

  if (!mapRef) {
    return null;
  }

  return (
    <Page hasPadding={false} contentScroll={false}>
      <StyledScreenWrapper>
        {isMobile ? (
          <StyledPageContainer>
            {filter}
            <StyledMapDetailsContainer ref={searchResultsRef}>
              {map}
              {objectDetails}
              {<SearchResults />}
            </StyledMapDetailsContainer>
          </StyledPageContainer>
        ) : (
          <StyledPageContainer>
            {isFilterBarActive && (
              <StyledFilterBar>
                {!isDetailsActive ? (
                  <>
                    {filter}
                    <SearchResults />
                    {downloadResults}
                  </>
                ) : (
                  objectDetails
                )}
              </StyledFilterBar>
            )}
            {map}
          </StyledPageContainer>
        )}
      </StyledScreenWrapper>
    </Page>
  );
};

export default MapPage;
