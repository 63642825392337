import React, { useCallback, useEffect } from "react";
import ReactMapGL, {
  Layer,
  MapLayerMouseEvent,
  ViewStateChangeEvent,
  Source,
} from "react-map-gl";
import styles from "./MainMap.module.scss";
import ImageFinder from "../../../../share/ImageFinder/ImageFinder";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import { useLazyEffect } from "../../../../hooks/useLazyEffect";
import { ISource } from "../../../../store/reducers/_maps/main/map/types";
import CustomMarker from "../../_mapComponents/CustomMarker/CustomMarker";
import CustomNavigationControl from "../../_mapComponents/CustomNavigationControl/CustomNavigationControl";
import useSearchParams from "../../../../hooks/useSearchParams";
import useSelectedLanguage from "../../../../hooks/useSelectedLanguage";
import { useMainMapFilter } from "./useMainMapFilter";
import util from "../../../../util";
import useMobileScreen from "../../../../hooks/useMobileScreen";
import {
  defaultViewport,
  mapboxAccessToken,
} from "../../../../constants/mapConstants";
import MapWrapper from "../../../../share/mapComponents/MapWrapper";
import PinnedSelectedMapObjectsList from "../../_mapComponents/PinnedSelectedMapObjectsList";
import CustomMapPopupItem from "../../_mapComponents/CustomPopup/CustomMapPopupItem";
import CustomPopup from "../../_mapComponents/CustomPopup";
import useMapObjects from "../../../../hooks/useMapObjects";
import MapBadgeLoader from "../../_mapComponents/MapBadgeLoader";

const MainMap: React.FC = () => {
  const { getSearchParam, setSearchParam } = useSearchParams();
  const hashId = getSearchParam("hash_id");
  const selectedLanguage = useSelectedLanguage();

  const { id } = useTypedSelector((state) => state.currentAccount);
  const {
    isDetailsActive,
    isFilterBarActive,
    viewport,
    currentMarker,
    offset,
  } = useTypedSelector((state) => state.mapInterface);
  const {
    typeFilterArray,
    ccFilterArray,
    constructionType,
    stageBuilding,
    stageDocumentation,
    region,
    from_date,
    to_date,
    activeSuggestions,
    keyword,
    squareFilter,
  } = useTypedSelector((state) => state.mapFilters);
  const { config, mapRef, selectedMapObject } = useTypedSelector(
    (state) => state.mapObjectsInfo
  );

  useEffect(() => {
    setCurrentMapRef(mapRef);
  }, [mapRef]);

  const { isLoading } = useTypedSelector((state) => state.mapInterface);
  const {
    getCurrentMapObject,
    setIsFilterActive,
    setIsDetailsActive,
    setViewport,
    setCurrentMarker,
    getDefaultResult,
    setCurrentMapRef,
  } = useActions();

  const {
    currObjs,
    pinnedCurrObjs,
    setPinnedCurrObjs,
    markerClickHandler,
    markerHoverHandler,
    selectedLayerProps,
  } = useMapObjects();

  const mapFilter = useMainMapFilter();

  useLazyEffect(() => {
    getDefaultResult();
  }, [
    offset,
    keyword,
    activeSuggestions,
    typeFilterArray,
    ccFilterArray,
    constructionType,
    stageDocumentation,
    stageBuilding,
    region,
    from_date,
    to_date,
    squareFilter,
  ]);

  useLazyEffect(
    () => {
      if (!isDetailsActive) {
        getDefaultResult();
      }
    },
    [viewport, selectedMapObject, region],
    700
  );

  useLazyEffect(() => {
    if (mapRef?.current) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      mapRef.current.getMap().on("data", (data: any) => {});
      if (!isDetailsActive) {
        getDefaultResult();

        setViewport(config?.viewport || defaultViewport);
      }
    }
  }, [config]);

  const getLayer = (zoom: number, sources: ISource[]): string[] => {
    if (sources.length === 1) {
      return config.sources?.map((source: ISource) => source.layer_features);
    } else {
      for (const source of sources) {
        if (
          source.minzoom &&
          source.maxzoom &&
          zoom >= source.minzoom &&
          zoom <= source.maxzoom
        ) {
          return [source.layer_features];
        }
      }
    }

    return config.sources?.map((source: ISource) => source.layer);
  };

  const isMobile = useMobileScreen();

  useEffect(() => {
    if (selectedMapObject?.lat && selectedMapObject?.lon) {
      setIsDetailsActive(true);
      setIsFilterActive(true);
      setCurrentMarker({
        lat: +selectedMapObject.lat,
        lon: +selectedMapObject.lon,
      });
      !isMobile &&
        mapRef.current?.flyTo({
          center: [+selectedMapObject.lon, +selectedMapObject.lat],
          zoom: 15,
        });
    }
  }, [selectedMapObject]);

  const handleMapLoad = useCallback(() => {
    const map = mapRef.current?.getMap();
    if (map) {
      util.mapInterfaceUtil.changeMapboxLanguage(map, selectedLanguage);
      util.mapInterfaceUtil.addPinIconToMapbox(map);
    }
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }
  }, [isFilterBarActive]);

  useEffect(() => {
    hashId && getCurrentMapObject(hashId, id);
  }, [hashId, id]);

  const commonMapProps = {
    width: "100%",
    height: "100vh",
    mapboxApiAccessToken: mapboxAccessToken,
    maxPitch: 1,
    dragRotate: false,
    onLoad: handleMapLoad,
  };

  const getFeatures = (e: MapLayerMouseEvent) => {
    if (!e) return [];
    if (!mapRef.current) return;
    return mapRef.current.queryRenderedFeatures(e.point, {
      layers: getLayer(viewport.zoom, config.sources),
      filter: mapFilter,
      validate: false,
    });
  };

  return (
    <MapWrapper>
      {/* <SearchField mapRef={mapRef} /> */}
      {pinnedCurrObjs?.length > 0 &&
        !selectedMapObject?.hash_id &&
        isMobile && (
          <PinnedSelectedMapObjectsList
            handleClose={() => setPinnedCurrObjs([])}
          >
            {pinnedCurrObjs.map((currObj, index) => (
              <CustomMapPopupItem
                isSelected={
                  currObj.properties.hash_id === selectedMapObject?.hash_id
                }
                key={index}
                onClick={() => {
                  setSearchParam("hash_id", currObj.properties.hash_id);
                }}
              >
                <ImageFinder object={currObj} />
                <h4 className={styles.titleStyle}>
                  {" "}
                  {currObj.properties.name.length > 37
                    ? currObj.properties.name.slice(0, 30) + ".."
                    : currObj.properties.name}
                </h4>
                <h4 className={styles.popUpClassifier}>
                  {currObj.properties.aimap_classifier}
                </h4>
                <p className={styles.construction_type}>
                  {currObj.properties.stage_documentation} &middot; CC{" "}
                  {currObj.properties.consequence_class}
                </p>
              </CustomMapPopupItem>
            ))}
          </PinnedSelectedMapObjectsList>
        )}
      <ReactMapGL
        {...viewport}
        {...commonMapProps}
        key={config?.mapStyle}
        mapboxAccessToken={mapboxAccessToken}
        ref={(instance) => (mapRef.current = instance)}
        minZoom={config?.viewport.minZoom || 5}
        maxZoom={15}
        maxPitch={1}
        dragRotate={false}
        mapStyle={config?.mapStyle}
        onLoad={handleMapLoad}
        onMouseOver={(e) => markerHoverHandler(getFeatures(e))}
        onClick={(e) => markerClickHandler(getFeatures(e))}
        onMouseMove={(e) => markerHoverHandler(getFeatures(e))}
        onMove={(e: ViewStateChangeEvent) => {
          setViewport(e.viewState);
        }}
      >
        {isLoading && <MapBadgeLoader />}
        <CustomNavigationControl />
        {config?.sources?.map((source: ISource) => (
          <Source key={source.layer} type="vector" url={source?.url}>
            <Layer
              filter={mapFilter}
              id={"point"}
              source-layer={source.layer}
              minzoom={source.minzoom || 5}
              maxzoom={source.maxzoom || 22}
              {...selectedLayerProps}
              type={"symbol"}
            />
          </Source>
        ))}
        {currentMarker && (
          <CustomMarker
            marker={currentMarker}
            onClick={() => {
              if (isMobile) {
                setIsDetailsActive(true);
              }
            }}
          />
        )}

        {currObjs?.length > 0 && (
          <CustomPopup
            latitude={+currObjs[0].properties.lat}
            longitude={+currObjs[0].properties.lon}
            itemsCount={currObjs.length}
          >
            {currObjs.map((currObj, index) => (
              <CustomMapPopupItem
                isSelected={
                  currObj.properties.hash_id === selectedMapObject?.hash_id
                }
                key={index}
                onClick={() => {
                  setSearchParam("hash_id", currObj.properties.hash_id);
                }}
              >
                <ImageFinder object={currObj} />
                <h4 className={styles.titleStyle}>
                  {" "}
                  {currObj.properties.name.length > 37
                    ? currObj.properties.name.slice(0, 30) + ".."
                    : currObj.properties.name}
                </h4>
                <h4 className={styles.popUpClassifier}>
                  {currObj.properties.aimap_classifier}
                </h4>
                <p className={styles.construction_type}>
                  {currObj.properties.stage_documentation} &middot; CC{" "}
                  {currObj.properties.consequence_class}
                </p>
              </CustomMapPopupItem>
            ))}
          </CustomPopup>
        )}
      </ReactMapGL>
    </MapWrapper>
  );
};

export default MainMap;
