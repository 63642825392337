import { FC } from "react";
import { MapObject } from "../../../../models/MapObject";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import MainSearchResultsItem from "./MainSearchResultsItem";
import SearchResultsResponsive from "../../../../share/searchResults/SearchResultsResponsive";

const SearchResults: FC = () => {
  const { mapObjects } = useTypedSelector((state) => state.mapObjectsInfo);
  const {
    refreshFeedForMainMapSearchResults,
    setCleanAllFilters,
    getDefaultResult,
  } = useActions();

  const handleClean = () => {
    setCleanAllFilters();
    getDefaultResult();
  };

  return (
    <SearchResultsResponsive
      count={mapObjects.count}
      handleLoadFeed={refreshFeedForMainMapSearchResults}
      onClearAllFilters={handleClean}
    >
      {mapObjects?.objects?.map((mapObject: MapObject, index: number) => {
        return (
          <MainSearchResultsItem
            key={mapObject.hash_id}
            mapObject={mapObject}
          />
        );
      })}
    </SearchResultsResponsive>
  );
};

export default SearchResults;
