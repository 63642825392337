import React, { useEffect, useState } from "react";
import Dropdown, { Option } from "../../../../share/Dropdown/Dropdown";
import { useTranslation } from "react-i18next";
import { defaultRegion, regions } from "../../../../constants/mapConstants";
import { IDatasetType, IRegion } from "../../../../models/map.interface";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import useSearchParams from "../../../../hooks/useSearchParams";
import { useActions } from "../../../../hooks/useActions";
import { AvailableCC } from "../../../../constants/constants";
import { getIsRegionAvailable } from "../../../../util/mapInterfaceUtil";
import { zIndexes } from "../../../../constants";
import { useTipsContext } from "../../../../hooks/useTips";

const RegionDropdown: React.FC = () => {
  const { setSearchParam, getSearchParam } = useSearchParams();
  const { getMapConfig, setCleanAllFilters, setPage, getDefaultResult } =
    useActions();

  const { t } = useTranslation("translation", { keyPrefix: "config.country" });

  const { config } = useTypedSelector((state) => state.mapObjectsInfo);
  let region = (getSearchParam("region") as IRegion) || defaultRegion;
  if (regions[region] === undefined) {
    region = defaultRegion;
  }
  const [selectedOption, setSelectedOption] = useState<Option>({
    label: t(region as string),
    value: region,
  });

  const options: Option[] = Object.values(regions).map((region: string) => ({
    label: t(region as string),
    value: region,
  }));

  const availableOptions = options.filter((option) => {
    const dataset = getSearchParam("dataset") as IDatasetType;
    const isRegionAvailable = getIsRegionAvailable(
      option.value as AvailableCC,
      dataset
    );
    return isRegionAvailable;
  });

  useEffect(() => {
    if (!config) {
      getMapConfig(AvailableCC.ua);
    }
  }, [config]);

  const resetFiltersAndResults = () => {
    setCleanAllFilters();
    getDefaultResult();
  };
  const { setLastOpenedTipId } = useTipsContext();

  const onSelect = (option: Option) => {
    resetFiltersAndResults();
    setSelectedOption(option);
    setPage(1);
    setSearchParam("region", option.value);
    getMapConfig(option.value as AvailableCC);
    setLastOpenedTipId("");
  };

  return (
    <Dropdown
      values={availableOptions}
      setValue={onSelect}
      value={selectedOption}
      mobileMinContent={true}
      modalZindex={zIndexes.datasetsModal}
    ></Dropdown>
  );
};

export default RegionDropdown;
