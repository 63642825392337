import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationUA from "./assets/translations/ua.json";
import translationFR from "./assets/translations/fr.json";
import translationNO from "./assets/translations/no.json";
import translationPL from "./assets/translations/pl.json";
import translationEN from "./assets/translations/en.json";
import { allowedLanguageArray } from "./constants/constants";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}
const resources = {
  ua: {
    translation: translationUA,
  },
  fr: {
    translation: translationFR,
  },
  no: {
    translation: translationNO,
  },
  pl: {
    translation: translationPL,
  },
  en: {
    translation: translationEN,
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: allowedLanguageArray,
    resources,
    returnNull: false,
    detection: {
      order: ["localStorage", "navigator"], // Check localStorage first, then browser language
      lookupLocalStorage: "selectedLanguage", // Your localStorage key for saved language
      caches: ["localStorage"], // To store the detected language for future visits
    },
  });

export default i18next;
