import styled from "@emotion/styled";
import { breakpoints, icons, zIndexes } from "../../../constants";
import { FC } from "react";
import CustomButton from "../../Button/Button";
import { useTranslation } from "react-i18next";
import { useActions } from "../../../hooks/useActions";
import useSearchParams from "../../../hooks/useSearchParams";

export const DetailsContainer = styled.div`
  @media screen and (max-width: ${breakpoints.small + "px"}) {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
`;
export const DetailsTabContent = styled.div`
  @media screen and (max-width: ${breakpoints.small + "px"}) {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    flex: 1 1 auto;
  }
`;
export const StyledDetailsActions = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  z-index: ${zIndexes.detailsModal};
  position: fixed;
  bottom: 0;
  background-color: white;
`;

type Props = {
  lat: number | string;
  lon: number | string;
};

export const ShowOnMapButton: FC<Props> = ({ lat = 0, lon = 0 }) => {
  const { t: tObjectDetails } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.objectDetails",
  });

  const {
    setIsDetailsActive,
    setViewport,
    setCurrentMarker,
    setIsPulloutDrawerOpen,
  } = useActions();
  const { removeQueryParam } = useSearchParams();

  return (
    <CustomButton
      width="auto"
      color={"red"}
      icon={icons.ArrowShortRight}
      onClick={() => {
        setIsPulloutDrawerOpen(false);

        setCurrentMarker({ lat: Number(lat), lon: Number(lon) });
        setIsDetailsActive(false);
        setViewport({
          latitude: Number(lat),
          longitude: Number(lon),
          zoom: 16,
        });
        removeQueryParam("hash_id");
      }}
    >
      {tObjectDetails("show_on_map")}
    </CustomButton>
  );
};
