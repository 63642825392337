import { PanInfo, useAnimation, useMotionValue } from "framer-motion";
import { useEffect, useRef, useState } from "react";

type Props = {
  onClose: () => void;
  isShown: boolean;
};

const dragEndExpandRatio = 0.3,
  animationTransitionTime = 0.3;

const useMobileMenuDrawer = ({ onClose, isShown }: Props) => {
  const animationShiftControls = useAnimation();
  const animationOpacityControls = useAnimation();
  const animationDisplayControls = useAnimation();

  const modalRef = useRef<HTMLDivElement | null>(null);
  const [modalHeight, setModalHeight] = useState<number>(0);

  useEffect(() => {
    if (modalRef?.current) {
      setModalHeight(modalRef.current.clientHeight);
    }
  }, [modalRef]);

  useEffect(() => {
    isShown ? handleModalOpen() : handleModalClose();
  }, [isShown]);

  const modalVerticalShift = useMotionValue("100%");

  const handleModalOverlayAppear = () => {
    return animationOpacityControls.start({
      opacity: 1,
      transition: { ease: "easeOut", duration: animationTransitionTime },
    });
  };

  const handleModalOverlayHide = () => {
    return animationOpacityControls.start({
      opacity: 0,
      transition: { ease: "easeOut", duration: animationTransitionTime },
    });
  };

  const handleModalAppear = () => {
    return animationShiftControls.start({
      y: 0,
      transition: { ease: "easeOut", duration: animationTransitionTime },
    });
  };

  const handleModalHide = () => {
    return animationShiftControls.start({
      y: "100%",
      transition: { ease: "easeOut", duration: animationTransitionTime },
    });
  };

  const handleModalClose = () => {
    Promise.all([handleModalOverlayHide(), handleModalHide()]).then(() => {
      onClose();
      handleModalDisplayHide();
    });
  };

  const handleModalOpen = () => {
    handleModalDisplayAppear().then(() => {
      handleModalOverlayAppear();
      handleModalAppear();
    });
  };

  const handleModalDisplayAppear = () => {
    return animationDisplayControls.start({
      display: "flex",
    });
  };
  const handleModalDisplayHide = () => {
    return animationDisplayControls.start({
      display: "none",
    });
  };

  const handleDragEnd = (
    _event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) => {
    if (info.offset.y > modalHeight * dragEndExpandRatio) {
      handleModalClose();
    } else {
      handleModalOpen();
    }
  };

  return {
    animationDisplayControls,
    animationOpacityControls,
    modalVerticalShift,
    animationShiftControls,
    modalHeight,
    modalRef,
    handleModalClose,
    handleDragEnd,
  };
};

export default useMobileMenuDrawer;
