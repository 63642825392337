import { FC } from "react";
import TelLink from "./textWrappers/TelLink";
import { DefaultWrapper, GreyTextWrapper } from "./textWrappers";

export const formatTypeToRegexNamesMap = {
  tel: ["телефон", "phone"],
  greyLink: ["Код ЄДРПОУ"],
};

type CellTypesEnum = "tel" | "greyLink" | "default";

export enum cellTypes {
  tel = "tel",
  greyLink = "greyLink",
}

type Props = {
  children: string;
  name?: string;
};

export function findCellTypeByName(name: string): CellTypesEnum {
  if (!name) return "default";
  for (const [formatType, DetailsNameRegexList] of Object.entries(
    formatTypeToRegexNamesMap
  )) {
    if (
      DetailsNameRegexList.some((regex) =>
        name.toLowerCase().includes(regex.toLowerCase())
      )
    ) {
      return formatType as CellTypesEnum;
    }
  }
  return "default";
}

const textWrappers = {
  tel: TelLink,
  greyLink: GreyTextWrapper,
  default: DefaultWrapper,
};

const DetailsCellFormattedText: FC<Props> = ({ children, name }) => {
  const selectedCellType: CellTypesEnum = findCellTypeByName(name);
  const SelectedTextWrapper = textWrappers[selectedCellType];
  return <SelectedTextWrapper>{children}</SelectedTextWrapper>;
};

export default DetailsCellFormattedText;
