import { AppDispatch, store } from "../..";
import { userService } from "../../api/UserService";
import { AuthResponse } from "../../../models/AuthResponse";
import { UserWithAccount } from "../../../models/IUser";
import dataPackage from "../../../../package.json";
import {
  AuthActionsEnum,
  SetAccessToken,
  SetAuthAction,
  SetErrorAction,
  SetIsLoadingAction,
  SetTokenAction,
  SetUserAction,
  SetAppVersion,
  SetIsWarned,
  SetLastRouteBeforeRedirect,
} from "./types";
import { IFormInputsFirst } from "../../../pages/_auth/Registration/RegistrationFirstStep/RegistrationForm";
import { IFormInputsThird } from "../../../pages/_auth/Registration/RegistrationThirdStep/RegistrationThirdStep";
import { httpClient } from "../../api/HTTPService";
import { AccountActionCreators } from "../accounts/action-creators";
import { IAccount } from "../../../models/Accounts";
import axios from "axios";
import { apiUrl } from "../../../constants/constants";
import { RouteNames } from "../../../routes";

export const AuthActionCreators = {
  setToken: (token: AuthResponse): SetTokenAction => ({
    type: AuthActionsEnum.SET_TOKEN,
    payload: token,
  }),
  setAccess: (token: string): SetAccessToken => ({
    type: AuthActionsEnum.SET_ACCESS,
    payload: token,
  }),
  setUser: (user: UserWithAccount): SetUserAction => ({
    type: AuthActionsEnum.SET_USER,
    payload: user,
  }),
  setIsAuth: (auth: boolean): SetAuthAction => ({
    type: AuthActionsEnum.SET_AUTH,
    payload: auth,
  }),
  setIsAuthLoading: (payload: boolean): SetIsLoadingAction => ({
    type: AuthActionsEnum.SET_IS_AUTH_LOADING,
    payload,
  }),
  setAppVersion: (payload: string): SetAppVersion => ({
    type: AuthActionsEnum.SET_APP_VERSION,
    payload,
  }),
  setError: (payload: string): SetErrorAction => ({
    type: AuthActionsEnum.SET_ERROR,
    payload,
  }),
  setIsWarned: (payload: boolean): SetIsWarned => ({
    type: AuthActionsEnum.SET_IS_WARNED,
    payload,
  }),
  setLastRouteBeforeRedirect: (
    payload: string
  ): SetLastRouteBeforeRedirect => ({
    type: AuthActionsEnum.LAST_ROUTE_BEFORE_REDIRECT,
    payload,
  }),
  errorDispatch: (e: string | any) => (dispatch: AppDispatch) => {
    if (store.getState().auth.error !== e?.response?.data?.detail) {
      dispatch(AuthActionCreators.setError(e?.response?.data?.detail));
      return setTimeout(() => {
        dispatch(AuthActionCreators.setError(""));
      }, 5000);
    }
  },
  login: (email: string, password: string) => async (dispatch: AppDispatch) => {
    dispatch(AuthActionCreators.setAppVersion(dataPackage.version));

    try {
      dispatch(AuthActionCreators.setIsAuthLoading(true));
      const response = await userService.authLogin(email, password);
      if (response) {
        localStorage.setItem("token", response.data.access);
        dispatch(AuthActionCreators.setToken(response.data));
        dispatch(AuthActionCreators.setUser(response.data.user));
        dispatch(AuthActionCreators.setIsAuth(true));
      }
      dispatch(AuthActionCreators.setIsAuthLoading(false));
    } catch (e: string | any) {
      dispatch(AuthActionCreators.errorDispatch(e));
    }
  },
  logout: () => (dispatch: AppDispatch) => {
    localStorage.removeItem("token");
    dispatch(AuthActionCreators.setToken({} as AuthResponse));
    dispatch(AuthActionCreators.setUser({} as UserWithAccount));
    dispatch(AccountActionCreators.setAccounts([]));
    dispatch(AccountActionCreators.setAccount({} as IAccount));
    dispatch(AuthActionCreators.setIsAuth(false));
    dispatch(AuthActionCreators.setIsWarned(false));
  },
  registrationFirstStep:
    (data: IFormInputsFirst) => async (dispatch: AppDispatch) => {
      try {
        dispatch(AuthActionCreators.setIsAuthLoading(true));
        await userService.authRegistrationFirstStep(data);
        dispatch(AuthActionCreators.setIsAuthLoading(false));
        return true;
      } catch (e: string | any) {
        dispatch(AuthActionCreators.errorDispatch(e));
      }
    },
  registrationSecondStep:
    (activation_code: string, email: string | undefined) =>
    async (dispatch: AppDispatch) => {
      try {
        dispatch(AuthActionCreators.setIsAuthLoading(true));
        const response = await userService.authRegistrationSecondStep(
          activation_code,
          email
        );
        if (response) {
          localStorage.setItem("token", response.data.access);
          dispatch(AuthActionCreators.setToken(response.data));
        }
        dispatch(AuthActionCreators.setIsAuthLoading(false));
        return true;
      } catch (e: string | any) {
        dispatch(AuthActionCreators.errorDispatch(e));
      }
    },
  registrationThirdStep:
    (data: IFormInputsThird) => async (dispatch: AppDispatch) => {
      try {
        dispatch(AuthActionCreators.setIsAuthLoading(true));
        const response = await userService.authRegistrationThirdStep(data);
        if (response) {
          dispatch(AuthActionCreators.setUser(response.data));
          dispatch(AuthActionCreators.setIsAuth(true));
        }
        dispatch(AuthActionCreators.setIsAuthLoading(false));
        return true;
      } catch (e: string | any) {
        dispatch(AuthActionCreators.errorDispatch(e));
      }
    },
  // https://accounts.google.com/o/oauth2/auth?client_id=1025970031274-9r7srgjtbqarokkpb6n44l6t154ipuge.apps.googleusercontent.com&redirect_uri=https://app.aimapa.com/google_reg&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile+openid+openid+email+profile&prompt=select_account&access_type=offline

  googleAuth: () => async (dispatch: AppDispatch) => {
    try {
      await axios
        .get(
          `${apiUrl}/auth/o/google-oauth2/?redirect_uri=http://localhost:3000${RouteNames.GOOGLE_AUTH}`
        )
        .then((resp) => (window.location.href = resp.data.authorization_url));
    } catch (e: string | any) {
      dispatch(AuthActionCreators.errorDispatch(e));
    }
  },
  googleLogin: (code: string | null) => async (dispatch: AppDispatch) => {
    dispatch(AuthActionCreators.setAppVersion(dataPackage.version));

    const decodedCode = code?.replace(/%2F/g, "/");
    try {
      const response = await axios.post(
        `${apiUrl}dj-rest-auth/google/`,
        // add code to body
        { code: decodedCode }
      );
      if (response) {
        dispatch(AuthActionCreators.setToken(response.data));
        localStorage.setItem("token", response.data.access);
        dispatch(AuthActionCreators.setUser(response.data.user));
        dispatch(AuthActionCreators.setIsAuth(true));
        return "success" + JSON.stringify(response.data);
      }
    } catch (e: string | any) {
      dispatch(AuthActionCreators.errorDispatch(e));
    }
  },

  passRecFirstStep:
    (email: string | undefined) => async (dispatch: AppDispatch) => {
      try {
        dispatch(AuthActionCreators.setIsAuthLoading(true));
        await userService.authPassRecFirstStep(email);
        dispatch(AuthActionCreators.setIsAuthLoading(false));
        return true;
      } catch (e: string | any) {
        dispatch(AuthActionCreators.errorDispatch(e));
      }
    },
  passRecSecondStep:
    (activation_code: number, email: string | undefined) =>
    async (dispatch: AppDispatch) => {
      try {
        dispatch(AuthActionCreators.setIsAuthLoading(true));
        await userService.authPassRecSecondStep(+activation_code, email);
        dispatch(AuthActionCreators.setIsAuthLoading(false));
        return true;
      } catch (e: string | any) {
        dispatch(AuthActionCreators.errorDispatch(e));
      }
    },
  passRecThirdStep:
    (
      activation_code: number,
      email: string | undefined,
      new_password: string,
      re_new_password: string
    ) =>
    async (dispatch: AppDispatch) => {
      dispatch(AuthActionCreators.errorDispatch(""));
      try {
        dispatch(AuthActionCreators.setIsAuthLoading(true));
        await userService.authPassRecThirdStep(
          activation_code,
          email,
          new_password,
          re_new_password
        );
        dispatch(AuthActionCreators.setIsAuthLoading(false));
        return true;
      } catch (e: string | any) {
        dispatch(AuthActionCreators.errorDispatch(e));
      }
    },
  checkAuth: () => async (dispatch: AppDispatch) => {
    if (store.getState().auth.appVersion !== dataPackage.version) {
      dispatch(AuthActionCreators.setAppVersion(dataPackage.version));
      dispatch(AuthActionCreators.logout());
    }

    dispatch(AuthActionCreators.setIsAuthLoading(true));
    try {
      const response = await httpClient.post<AuthResponse>(
        `${apiUrl}/auth/jwt/refresh/`,
        { refresh: store.getState().auth.token.refresh }
      );
      if (response) {
        localStorage.setItem("token", response.data.access);
        dispatch(AuthActionCreators.setAccess(response.data.access));
      }
    } catch (e: string | any) {
      dispatch(AuthActionCreators.errorDispatch(e?.response?.data?.detail));
    } finally {
      dispatch(AuthActionCreators.setIsAuthLoading(false));
    }
  },
};
