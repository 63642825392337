export function capitalize(str: string): string {
  if (str.length === 0) {
    return str;
  }

  const firstChar = str.charAt(0).toUpperCase();
  const restOfString = str.slice(1).toLowerCase();
  return firstChar + restOfString;
}

export function addEllipsis(str: string, sliceNumber: number): string {
  if (str.length <= sliceNumber) {
    return str;
  }

  return str.slice(0, sliceNumber) + "..";
}

export const formatSearchResultTitle = (title: string): string => {
  return addEllipsis(title, 42);
};

export function sliceAndEllipsis(str: string, sliceNumber = 42): string {
  if (str.length <= sliceNumber) {
    return str;
  }

  return str.slice(0, sliceNumber) + "..";
}

const stringUtil = {
  capitalize,
  addEllipsis,
  formatSearchResultTitle,
  sliceAndEllipsis,
};

export default stringUtil;
