import { IMapObjectsInfo } from "../../../../../models/MapObjectsInfo";
import { ICurrentObject } from "../../../../../models/CurrentObject";
import { AvailableCC } from "../../../../../constants/constants";
import { MapObject } from "../../../../../models/MapObject";
import { IViewport } from "../../../../../models/Viewport";
import { Layer } from "mapbox-gl";

interface Params {
  construction_type: string[];
  consequence_class: ConsequenceClass[];
  aimap_classifier: string[];
  stage_documentation: string[];
  stage_building: string[];
}
interface ConsequenceClass {
  name: string;
  value: string;
}

export interface MapObjectsResponse {
  next: string;
  previous: null;
  status: number;
  objects: MapObject[];
  params: Partial<Params>;
  count: number;
}

interface MapStateParams {
  [key: string]: any;
}

export interface IMapObjectsInfoState {
  config: MapConfig;
  isObjectsLoading: boolean;
  params: MapStateParams;
  hash_ids: [];
  selectedMapObject: Partial<MapObject> | null;
  // objects: Partial<MapObjectsResponse>;
  mapObjects: Partial<IMapObjectsInfo>;
  mapRef?: any;
  companyMapRef?: any;
  personMapRef?: any;
  usaidMapRef?: any;
  sikaMapRef?: any;
}

export interface MapConfig {
  countryCode: AvailableCC;
  countryName: string;
  mapStyle: string;
  sources: ISource[];
  viewport: IViewport;

  filters: {
    types: boolean;
    cc: boolean;
    date: boolean;
    other: boolean;
  };
}

export interface ISource {
  url: string;
  layer: string;
  layer_features: string;
  style?: Partial<Layer>;
  minzoom?: number;
  maxzoom?: number;
}

export enum MapActionsEnum {
  UPDATE_STATE = "UPDATE_STATE",
  SET_MAP_CONFIG = "SET_MAP_CONFIG",
  SET_IS_MAIN_LOADING = "SET_IS_MAIN_LOADING",
  SET_MAP_OBJECTS = "SET_MAP_OBJECTS",
  SET_MAP_OBJECT = "SET_MAP_OBJECT",
  SET_MAIN_MAP_GEOJSON_DATA = "SET_MAIN_MAP_GEOJSON_DATA",
  CLEAR_MAP_OBJECT = "CLEAR_MAP_OBJECT",
}

export interface SetMapConfig {
  type: MapActionsEnum.SET_MAP_CONFIG;
  payload: MapConfig;
}

// SetClearMapObject
export interface SetClearMapObjectAction {
  type: MapActionsEnum.CLEAR_MAP_OBJECT;
}

export interface SetObjectsLoading {
  type: MapActionsEnum.SET_IS_MAIN_LOADING;
  payload: boolean;
}

export interface SetMapObjectAction {
  type: MapActionsEnum.SET_MAP_OBJECT;
  payload: ICurrentObject;
}

export interface SetMapObjectsAction {
  type: MapActionsEnum.SET_MAP_OBJECTS;
  payload: IMapObjectsInfo;
}

export interface UpdateStateAction {
  type: MapActionsEnum.UPDATE_STATE;
  payload: Partial<IMapObjectsInfoState>;
}

export type MapObjectAction =
  | UpdateStateAction
  | SetObjectsLoading
  | SetMapObjectAction
  | SetMapObjectsAction
  | SetMapConfig
  | SetClearMapObjectAction;
