import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import DatePicker from "../../PersonDatePicker/DatePicker";
import { useTranslation } from "react-i18next";
import useIsFilterActive from "../../../../../hooks/useIsFilterActive";
import { FilterTitleWithInfo } from "../../../../../share/filters/FilterTitleWithInfo";
import FilterButtonWithModal from "../../../../../share/filters/FilterButtonWithModal";

const DateFilter = () => {
  const filterId = "personsPeriod";

  const isFilterActive = useIsFilterActive(filterId);
  const { date_added__lte, date_added__gte } = useTypedSelector(
    (state) => state.personFilters
  );
  const { setPersonDateAddedLTE, setPersonDateAddedGTE } = useActions();

  const handleClean = () => {
    setPersonDateAddedLTE("");
    setPersonDateAddedGTE("");
  };

  const { t: tDate } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.persons.dateFilter",
  });

  const title = tDate("title");
  const pinText = date_added__gte && date_added__lte && "In";

  return (
    <FilterButtonWithModal
      title={title}
      pinText={pinText}
      filterId={filterId}
      onClear={handleClean}
      left={-224}
      fullWidth
    >
      <FilterTitleWithInfo title={title} />
      <DatePicker isFilterActive={isFilterActive} />
    </FilterButtonWithModal>
  );
};

export default DateFilter;
