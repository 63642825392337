import React from "react";
import { Avatar } from "antd";
import ProfileImage from "../../../assets/images/Profile image.jpg";

const ProfileAvatar: React.FC = () => (
  <Avatar
    src={ProfileImage}
    shape="square"
    size={{ xs: 56, sm: 80, md: 120, lg: 240, xl: 240, xxl: 240 }}
  />
);

export default ProfileAvatar;
