import {
  CurrentPerson,
  ICurrentPerson,
} from "../../../../../models/CurrentPerson";

export interface MapStateParams {
  [key: string]: any;
}

export interface IMapPersonsInfoState {
  isObjectsLoading: boolean;
  params: MapStateParams;
  hash_ids: [];
  count: number;
  next: null | string;
  person: CurrentPerson;
  persons: CurrentPerson[];
}

export enum MapActionsEnum {
  SET_IS_PERSON_LOADING = "SET_IS_PERSON_LOADING",
  SET_MAP_PERSONS = "SET_MAP_PERSONS",
  SET_MAP_PERSON = "SET_MAP_PERSON",
}

export interface SetObjectsLoading {
  type: MapActionsEnum.SET_IS_PERSON_LOADING;
  payload: boolean;
}

export interface SetMapPersonAction {
  type: MapActionsEnum.SET_MAP_PERSON;
  payload: ICurrentPerson;
}

export interface SetMapPersonsAction {
  type: MapActionsEnum.SET_MAP_PERSONS;
  payload: IMapPersonsInfoState;
}

export type MapObjectAction =
  | SetObjectsLoading
  | SetMapPersonAction
  | SetMapPersonsAction;
