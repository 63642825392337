import { AccountActions, AccountActionsEnum } from "./types";
import {
  IAccount,
  IAccounts,
  IRole,
  ISubTemplate,
} from "../../../models/Accounts";

const initialAccountsState: IAccounts[] = [];

export function accountsReducer(
  state = initialAccountsState,
  action: AccountActions
) {
  switch (action.type) {
    case AccountActionsEnum.SET_ACCOUNTS:
      return action.payload;

    default:
      return state;
  }
}

const initialAccountState: IAccount = {} as IAccount;

export function accountReducer(
  state = initialAccountState,
  action: AccountActions
) {
  switch (action.type) {
    case AccountActionsEnum.SET_ACCOUNT:
      return action.payload;

    case AccountActionsEnum.SET_INVITED:
      return { ...state, invites: [...action.payload] };

    default:
      return state;
  }
}

export interface IAccountUtility {
  sub_type: string;
  template: ISubTemplate;
  roles: {
    results: IRole[];
  };
  usersInvitedSend: boolean;
}

const initialUtilityState: IAccountUtility = {
  sub_type: "",
  template: {} as ISubTemplate,
  roles: {
    results: [],
  },
  usersInvitedSend: false,
};

export function utilityAccountReducer(
  state: IAccountUtility = initialUtilityState,
  action: AccountActions
) {
  switch (action.type) {
    case AccountActionsEnum.SET_SUB_TYPE:
      return { ...state, sub_type: action.payload };

    case AccountActionsEnum.SET_SUBSCRIPTION_TEMPLATE:
      return { ...state, template: action.payload };

    case AccountActionsEnum.GET_ROLES:
      return { ...state, roles: action.payload };

    case AccountActionsEnum.SET_USERS_REQUESTED:
      return { ...state, usersInvitedSend: action.payload };

    default:
      return state;
  }
}
