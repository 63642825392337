import { httpClient, HttpClient } from "./HTTPService";
import { IReqSikaDesigners } from "../../models/SikaDesignerObject";
import { store } from "../index";
import { ISearchRequest, ISearchResponse } from "../../models/Search";
import { ICurrentSikaDesigner } from "../../models/CurrentSikaDesigner";
import qs from "qs";
import { IMapSikaDesignersInfoState } from "../reducers/_maps/sika/sikaDesigner/types";

const token = store.getState().auth.token.access;

class SikaDesignerService {
  constructor(private httpClient: HttpClient) {}

  public async fetchMapSikaDesigners(data: IReqSikaDesigners) {
    const res = httpClient.get<IMapSikaDesignersInfoState>(
      `sika_designers/` + data.account_id + "/",
      {
        params: data,
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false });
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res;
  }

  public async fetchMapSikaDesigner(RECORD: string, account_id: number) {
    if (RECORD) {
      return httpClient.get<ICurrentSikaDesigner>(
        `sika_designers/` + account_id + "/" + RECORD + "/",
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } else return undefined;
  }

  public async fetchSuggestions(data: ISearchRequest) {
    return httpClient.post<ISearchResponse[]>(
      `sika_designers/` + data["account_id"] + `/suggest/`,
      data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }
}

export const sikaDesignerService = new SikaDesignerService(httpClient);
