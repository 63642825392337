import { useMemo } from "react";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useLazyEffect } from "../../../../hooks/useLazyEffect";
import { useActions } from "../../../../hooks/useActions";

const useSikaDesignersFilters = () => {
  const { subscribe } = useTypedSelector((state) => state.currentAccount);
  const { offset } = useTypedSelector((state) => state.mapInterface);
  const {
    date_added__gte,
    date_added__lte,
    activeSuggestions,
    country_part_array,
    consequence_class_array,
    domain_array,
    role_array,
    entity_type_array,
    actual_projects__range,
    finished_projects_full__range,
    finished_projects_2021__range,
    aimap_classifier_all,
    region,
    edrpou_code,
    isIncomeBigger,
    sika_keyword,
    kved,
  } = useTypedSelector((state) => state.sikaDesignerFilters);

  const { hash_ids } = useTypedSelector((state) => state.mapSikaDesignerInfo);

  const { getDefaultSikaDesignerResult } = useActions();

  const nameFilters = [];
  for (const kword of aimap_classifier_all) {
    nameFilters.push(["in", kword, ["get", "aimap_classifiers"]]);
  }
  const consequenceClassFilters = [];
  for (const kword of consequence_class_array)
    consequenceClassFilters.push(["in", kword, ["get", "consequence_classes"]]);
  const domainFilters = [];
  for (const kword of domain_array)
    domainFilters.push(["in", kword, ["get", "domains"]]);
  const rolesFilters = [];
  for (const kword of role_array)
    rolesFilters.push(["in", kword, ["get", "roles"]]);
  const regionSearch = () =>
    region.length ||
    (region.length && subscribe?.permissions?.region?.includes(region[0]))
      ? region.map(function (x: string) {
          return x.replace(/ область/g, "");
        })
      : subscribe?.permissions?.region?.length
      ? subscribe?.permissions?.region
      : [];
  const edrpouSearch = () => (edrpou_code.length ? edrpou_code : []);
  const countryPartSearch = () =>
    country_part_array.length ? country_part_array : [];
  const sikaNumber0224Search = () =>
    actual_projects__range !== ""
      ? (actual_projects__range.split("__") as unknown as number[])
      : ["", ""];
  const sikaNumber1322Search = () =>
    finished_projects_full__range !== ""
      ? (finished_projects_full__range.split("__") as unknown as number[])
      : ["", ""];
  const sikaNumber2122Search = () =>
    finished_projects_2021__range !== ""
      ? (finished_projects_2021__range.split("__") as unknown as number[])
      : ["", ""];

  const filterByCodes = ["in", ["get", "edrpou_code"], ["literal", hash_ids]];
  const filterByRegion = ["in", ["get", "region"], ["literal", regionSearch()]];
  const filterByCountryPart = [
    "in",
    ["get", "country_part"],
    ["literal", countryPartSearch()],
  ];
  const filterByEdrpou = [
    "in",
    ["get", "edrpou_code"],
    ["literal", edrpouSearch()],
  ];
  const filterExportLTE = [
    "<=",
    ["get", "actual_projects"],
    +sikaNumber0224Search()[1],
  ];
  const filterExportGTE = [
    ">=",
    ["get", "actual_projects"],
    +sikaNumber0224Search()[0],
  ];
  const filterImportLTE = [
    "<=",
    ["get", "finished_projects_full"],
    +sikaNumber1322Search()[1],
  ];
  const filterImportGTE = [
    ">=",
    ["get", "finished_projects_full"],
    +sikaNumber1322Search()[0],
  ];
  const filterIncomeLTE = [
    "<=",
    ["get", "finished_projects_2021"],
    +sikaNumber2122Search()[1],
  ];
  const filterIncomeGTE = [
    ">=",
    ["get", "finished_projects_2021"],
    +sikaNumber2122Search()[0],
  ];
  const filterLatGTE = [">=", ["get", "lat"], 44.386389];
  const filterLatLTE = ["<=", ["get", "lat"], 52.377778];
  const filterLonGTE = [">=", ["get", "lon"], 22.138056];
  const filterLonLTE = ["<=", ["get", "lon"], 40.223611];
  const filterIncomeBigger = isIncomeBigger
    ? [">=", ["get", "income_2020"], 500]
    : [">=", ["get", "income_2020"], 0];
  const filters = [
    filterIncomeBigger,
    filterByCountryPart,
    filterExportLTE,
    filterExportGTE,
    filterImportLTE,
    filterImportGTE,
    filterLonLTE,
    filterLatGTE,
    filterLatLTE,
    filterByRegion,
    filterByEdrpou,
    filterIncomeLTE,
    filterIncomeGTE,
    filterLonGTE,
  ].filter(
    (item) =>
      (!Array.isArray(item[item.length - 1]) &&
        item.length >= 3 &&
        item[item.length - 1] !== "" &&
        item[item.length - 1] !== 0) ||
      (Array.isArray(item[item.length - 1]) &&
        (item[item.length - 1] as string[])[1].length !== 0)
  );
  if (nameFilters.length) {
    const all_filterByName = ["any", ...nameFilters];
    filters.push(all_filterByName);
  }
  if (consequenceClassFilters.length) {
    const all_filterByName = ["any", ...consequenceClassFilters];
    filters.push(all_filterByName);
  }
  if (domainFilters.length) {
    const all_filterByName = ["any", ...domainFilters];
    filters.push(all_filterByName);
  }

  if (rolesFilters.length) {
    const all_filterByName = ["any", ...rolesFilters];
    filters.push(all_filterByName);
  }
  if (
    (sika_keyword.length !== 0 || kved.length !== 0) &&
    hash_ids.length !== 0
  ) {
    filters.push(filterByCodes);
  }

  useLazyEffect(() => {
    getDefaultSikaDesignerResult();
    // eslint-disable-next-line
  }, [
    offset,
    date_added__gte,
    date_added__lte,
    activeSuggestions,
    actual_projects__range,
    finished_projects_full__range,
    finished_projects_2021__range,
    region,
    aimap_classifier_all,
    edrpou_code,
    isIncomeBigger,
    country_part_array,
    consequence_class_array,
    domain_array,
    role_array,
    entity_type_array,
    sika_keyword,
    kved,
  ]);

  const mapFilter = useMemo(() => ["all", ...filters], [filters]);
  return mapFilter;
};

export default useSikaDesignersFilters;
