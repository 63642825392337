import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { useTranslation } from "react-i18next";
import { FilterIdsEnum } from "../../../../../store/reducers/_maps/main/mapFilters/types";
import useSearchParams from "../../../../../hooks/useSearchParams";
import { regions } from "../../../../../constants/mapConstants";
import FilterButtonWithModal from "../../../../../share/filters/FilterButtonWithModal";
import DateFilterBody from "./DateFilterBody";

const DateFilter = () => {
  const filterId: FilterIdsEnum = "date";
  const { from_date, to_date } = useTypedSelector((state) => state.mapFilters);

  const { setFromDate, setToDate } = useActions();

  const selectedRegion = useSearchParams().getSearchParam("region");
  const handleClean = () => {
    setFromDate("");
    setToDate("");
  };

  const { t } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.aimap.periodFilter",
  });

  const title = t("title");
  const infoText = t("explanation");
  const pinText = from_date && to_date && "In";

  const leftMap = {
    [regions.no]: -120,
    [regions.fr]: -120,
    [regions.ua]: -240,
    [regions.pl]: -240,
    [regions.en]: -240,
  };

  return (
    <FilterButtonWithModal
      title={title}
      pinText={pinText}
      filterId={filterId}
      description={infoText}
      onClear={handleClean}
      left={leftMap[selectedRegion]}
    >
      <DateFilterBody />
    </FilterButtonWithModal>
  );
};

export default DateFilter;
