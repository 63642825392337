import React, { createContext, useContext, useState, useEffect } from "react";

type LogEntry = {
  timestamp: string;
  error: string[];
};

interface LoggerContextType {
  errorLogs: LogEntry[];
  addLog: (...args: any[]) => void;
}

const LoggerContext = createContext<LoggerContextType>({
  errorLogs: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addLog: () => {},
});

export const LoggerProvider: React.FC = ({ children }) => {
  const [errorLogs, setErrorLogs] = useState<LogEntry[]>([]);

  const addLog = (...args: any[]) => {
    const newLog: LogEntry = {
      timestamp: new Date().toISOString(),
      error: args.map((arg) =>
        arg instanceof Error ? arg.stack : arg.toString()
      ),
    };
    setErrorLogs((prevLogs) => {
      const updatedLogs = [...prevLogs, newLog];
      return updatedLogs.length > 30
        ? updatedLogs.slice(updatedLogs.length - 30)
        : updatedLogs;
    });
  };

  useEffect(() => {
    const originalConsoleError = console.error;
    let isLoggingError = false;

    console.error = (...args: any[]) => {
      if (!isLoggingError) {
        isLoggingError = true;
        addLog(...args); // Capture the error
        originalConsoleError(...args); // Log the error as usual
        isLoggingError = false;
      } else {
        originalConsoleError(...args); // Prevent recursion by falling back to the original method
      }
    };

    return () => {
      console.error = originalConsoleError; // Restore original console.error on cleanup
    };
  }, []);

  return (
    <LoggerContext.Provider value={{ errorLogs, addLog }}>
      {children}
    </LoggerContext.Provider>
  );
};

export const useLogger = () => useContext(LoggerContext);
