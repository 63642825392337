import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { useTranslation } from "react-i18next";
import { TypeFilterItemsList } from "../../../../../share/filters";
import TypeFilterItem from "../../../../../share/filters/TypeFilterItem";
import { FilterIdsEnum } from "../../../../../store/reducers/_maps/main/mapFilters/types";
import FilterButtonWithModal from "../../../../../share/filters/FilterButtonWithModal";

const EconomicSectorFilter = () => {
  const filterId: FilterIdsEnum = "usaidEconomicSector";
  const { economic_sector_array } = useTypedSelector(
    (state) => state.usaidMapFilters
  );
  const { economic_sector } = useTypedSelector(
    (state) => state.mapUSAIDInfo.params
  );

  const {
    toggleUSAIDEconomicSectorFilter,
    USAIDEconomicSectorFilterClean,
    applyFilter,
  } = useActions();

  const handleClick = (option: string) => {
    toggleUSAIDEconomicSectorFilter(option);
    applyFilter();
  };

  const { t: tES } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.usaid.economicSectorFilter",
  });

  const title = tES("title");
  const pinText =
    economic_sector_array.length > 0 && economic_sector_array.length;

  return (
    <FilterButtonWithModal
      title={title}
      filterId={filterId}
      pinText={pinText}
      onClear={USAIDEconomicSectorFilterClean}
    >
      <TypeFilterItemsList>
        {economic_sector?.length &&
          economic_sector
            ?.sort()
            .map((option: string) => (
              <TypeFilterItem
                option={option}
                isActive={economic_sector_array.includes(option)}
                onClick={() => handleClick(option)}
              />
            ))}
      </TypeFilterItemsList>
    </FilterButtonWithModal>
  );
};

export default EconomicSectorFilter;
