import { useEffect } from "react";
import AppRouter from "./AppRouter";
import { useActions } from "./hooks/useActions";
import "mapbox-gl/dist/mapbox-gl.css";
import { AppContextProvider } from "./contexts/appContext";
import { LoggerProvider } from "./contexts/LoggerContext";

import i18n from "./i18n";
import { allowedLanguageArray, AvailableCC } from "./constants/constants";
import useSelectedLanguage from "./hooks/useSelectedLanguage";
import { TipsProvider } from "./contexts/TipsContext";

const App = () => {
  const { checkAuth } = useActions();

  const currentLang = useSelectedLanguage();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      checkAuth();
    }

    if (
      !allowedLanguageArray.includes(currentLang) // some strange cases like uk-UA etc
    ) {
      localStorage.setItem("selectedLanguage", AvailableCC.ua); // than set localStorage data lang to ua
      i18n.changeLanguage(AvailableCC.ua); // also change app lang to ua
    }
  }, []);

  useEffect(() => {
    if (
      allowedLanguageArray.includes(currentLang) && // if lang format is ok
      currentLang !== localStorage.getItem("selectedLanguage") // but localStorage data differs from user selected lang
    ) {
      // localStorage.setItem("selectedLanguage", currentLang); // than set localStorage data lang to user selected lang
      i18n.changeLanguage(currentLang); // also change app lang to user selected lang
    }
  }, [currentLang]); // currentLang-dependent effect because currentUser is fetchable

  return (
    <LoggerProvider>
      <AppContextProvider>
        <TipsProvider>
          <AppRouter />
        </TipsProvider>
      </AppContextProvider>
    </LoggerProvider>
  );
};

export default App;
