import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, Select, notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  allowedLanguageArray,
  AvailableCC,
} from "../../../constants/constants";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { IUser } from "../../../models/Accounts";
import { IFetchable } from "../../../models/IFetchable";
import { useActions } from "../../../hooks/useActions";
import CustomButton from "../../../share/Button/Button";
import {
  FormContainer,
  FormSection,
} from "../../../share/formComponents/formComponents";
import useSelectedLanguage from "../../../hooks/useSelectedLanguage";
const { Option } = Select;

enum formNames {
  name = "name",
  full_name = "full_name",
  company_name = "company_name",
  position = "position",
  phone_number = "phone_number",
  email = "email",
  password = "password",
}

const ProfileForm: React.FC = () => {
  const [form] = Form.useForm();
  const { updateUser } = useActions();
  const [notificationApi, contextHolder] = notification.useNotification();

  const currentUser: Partial<IUser & IFetchable> = useTypedSelector(
    (store) => store.currentUser
  );

  const lang = useSelectedLanguage();

  const formInitialValues: Partial<IUser> = {
    full_name: currentUser.full_name,
    position: currentUser.position,
    company_name: currentUser.company_name,
    email: currentUser.email,
    phone_number: currentUser.phone_number,
    language: lang,
  };

  const { t: tBaseInfo, i18n } = useTranslation("translation", {
    keyPrefix: "profilePage.baseInfoForm",
  });
  const { t: tNotification } = useTranslation("translation", {
    keyPrefix: "profilePage.notification",
  });
  const { t: tLang } = useTranslation("translation", {
    keyPrefix: "config.language",
  });

  const [hasFormChanged, setHasFormChanged] = useState<boolean>(false);

  useEffect(() => {
    form.setFieldsValue({
      full_name: currentUser.full_name,
      position: currentUser.position,
      company_name: currentUser.company_name,
      email: currentUser.email,
      phone_number: currentUser.phone_number,
      language: lang,
    });
  }, [currentUser]);

  const handleChangeLanguage = (language: AvailableCC) => {
    i18n.changeLanguage(language);
  };

  const handleSubmit = async (formValues: Partial<IUser>) => {
    const language = allowedLanguageArray.includes(formValues.language)
      ? formValues.language
      : AvailableCC.ua;

    handleChangeLanguage(language as AvailableCC);
    updateUser({
      ...formValues,
      language: language,
    });
    notificationApi.success({
      message: tNotification("profileUpdateMessage"),
      description: tNotification("profileUpdateDescription"),
      placement: "bottomLeft",
      duration: 3,
    });
  };

  return (
    <FormContainer>
      {contextHolder}
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onValuesChange={() => {
          setHasFormChanged(true);
        }}
        initialValues={formInitialValues}
      >
        <FormSection
          headerText={tBaseInfo("baseInfoTitle")}
          subheaderText={tBaseInfo("baseInfoSubtitle")}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label={tBaseInfo("fullNameLabel")}
                name={formNames.full_name}
              >
                <Input placeholder={tBaseInfo("fullNameLabel") || ""} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} xs={24}>
              <Form.Item
                label={tBaseInfo("positionLabel")}
                name={formNames.position}
              >
                <Input placeholder={tBaseInfo("positionLabel") || ""} />
              </Form.Item>
            </Col>
            <Col span={12} xs={24}>
              <Form.Item
                label={tBaseInfo("companyLabel")}
                name={formNames.company_name}
              >
                <Input placeholder={tBaseInfo("companyLabel") || ""} />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
        <FormSection headerText={tBaseInfo("contactDataTitle")}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={tBaseInfo("emailLabel")} name={formNames.email}>
                <Input
                  disabled={true}
                  placeholder={tBaseInfo("emailLabel") || ""}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={tBaseInfo("phoneNumberLabel")}
                name={formNames.phone_number}
              >
                <Input placeholder={tBaseInfo("phoneNumberLabel") || ""} />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
        <FormSection headerText={tBaseInfo("appLanguageTitle")}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="language" label={tBaseInfo("languageLabel")}>
                <Select>
                  <Option value={AvailableCC.ua}>{tLang("ukrainian")}</Option>
                  <Option value={AvailableCC.en}>{tLang("english")}</Option>
                  <Option value={AvailableCC.fr}>{tLang("french")}</Option>
                  <Option value={AvailableCC.pl}>{tLang("polish")}</Option>
                  <Option value={AvailableCC.no}>{tLang("norwegian")}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
        <CustomButton
          size={"medium"}
          htmlType="submit"
          disabled={!hasFormChanged}
        >
          {tBaseInfo("submit")}
        </CustomButton>
      </Form>
    </FormContainer>
  );
};

export default ProfileForm;
