import styles from "../../main/Filters/Filters.module.scss";
import SearchFilter from "../../../SearchFilter/SearchFilter";
import { useActions } from "../../../../hooks/useActions";
import CategoryFilter from "./CategoryFilter/CategoryFilter";
import EconomicSectorFilter from "./EconomicSector/EconomicSectorFilter";
import EntityTypeFilter from "./EntityType/EntityTypeFilter";
import ActivityStatusFilter from "./ActivityStatus/ActivityStatusFilter";
import RegionDropdown from "../../main/RegionDropdown/RegionDropdown";
import DatasetsDropdown from "../../_mapFilterComponents/DatasetsDropdown/DatasetsDropdown";
import SavedFiltersButton from "../../../templates/SavedFilters/SavedFiltersButton/SavedFiltersButton";
import ClearAllFiltersDesktopButton from "../../../../share/filters/ClearAllFiltersDesktopButton";
import MobileClearAllButton from "../../../../share/filters/MobileClearAllButton";
import AddMoreFiltersButton from "../../../templates/AddSavedFilters/AddMoreFiltersButton";
import { FilterGroup } from "../../../../share/filters";

const Filters = () => {
  const { setCleanAllUSAIDFilters } = useActions();

  const handleClean = () => {
    setCleanAllUSAIDFilters();
  };

  return (
    <div className={styles.filterMainWrapper}>
      <div className={styles.topContainer}>
        <div className={styles.regionsDatasetsWrapper}>
          <RegionDropdown />
          <DatasetsDropdown />
        </div>
        {/* <div className={styles.topContainerTitle}>Карта USAID</div> */}
        <div className={styles.topContainerButtons}>
          <SavedFiltersButton />
          <AddMoreFiltersButton />
        </div>
      </div>
      <div className={styles.searchWrapper}>
        <SearchFilter />
      </div>
      <FilterGroup onClick={(event) => event.stopPropagation()}>
        <EconomicSectorFilter />
        <EntityTypeFilter />
        <CategoryFilter />
        <ActivityStatusFilter />
        <MobileClearAllButton onClick={handleClean} />
      </FilterGroup>

      <ClearAllFiltersDesktopButton onClick={handleClean} />
    </div>
  );
};

export default Filters;
