import mapInterfaceUtil from "./mapInterfaceUtil";
import dateUtil from "./dateUtil/dateUtil";
import filesUtil from "./filesUtil";
import stringUtil from "./stringUtil";
import storeUtil from "./storeUtil";
import { privateValue, unknownValue } from "../constants/constants";

function toSortedAlphabeticCallback(a: string, b: string) {
  return a.toString().toLocaleLowerCase() > b.toString().toLocaleLowerCase()
    ? 1
    : -1;
}

export function removeDuplicatesAndLeavePrivateText(
  item: {
    name: string;
    value: string;
  },
  index: number,
  self: {
    name: string;
    value: string;
  }[]
) {
  const { value } = item;

  if (value === unknownValue) {
    return true;
  }

  if (value === privateValue) {
    return true;
  }

  if (!value || value === "") {
    return false;
  }

  // filter out dublicates of same values
  return self.findIndex((el) => el.value === value) === index;
  // return self.findIndex((el) => el.value === value) === index;
}

export function removeDuplicatesAndLeavePrivateTextWithPropNames(
  item: {
    property_name: string;
    property_value: string;
  },
  index: number,
  self: {
    property_name: string;
    property_value: string;
  }[]
) {
  const { property_value } = item;
  if (property_value === unknownValue) {
    return true;
  }

  if (property_value === privateValue) {
    return true;
  }

  if (!property_value || property_value === "") {
    return false;
  }

  // filter out dublicates of same values
  return self.findIndex((el) => el.property_value === property_value) === index;
}

const util = {
  mapInterfaceUtil,
  toSortedAlphabeticCallback,
  removeDuplicatesAndLeavePrivateText,
  removeDuplicatesAndLeavePrivateTextWithPropNames,
  dateUtil,
  filesUtil,
  stringUtil,
  storeUtil,
};

export default util;
