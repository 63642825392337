import { useEffect, useRef, useState } from "react";
import styles from "./Dropdown.module.scss";
import ArrowUp from "../../assets/svg/ArrowUp.svg";
import ArrowDown from "../../assets/svg/ArrowDown.svg";
import Check from "../../assets/svg/Check.svg";
import useOutsideClick from "../../hooks/useClickOutside";
import classNames from "classnames";
import { zIndexes } from "../../constants";

export interface Option {
  readonly label: string;
  readonly value: string;
}

interface IProps {
  values: Option[];
  value: Option;
  setValue?: (value: Option) => void;
  title?: string;
  disabled?: boolean;
  mobileMinContent?: boolean;
  modalZindex?: number;
}

const Dropdown = ({
  values,
  value,
  setValue,
  mobileMinContent = false,
  modalZindex = zIndexes.modal,
  disabled = false,
}: IProps) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const dropdownElementRef = useRef<any>(null);
  const dropdownListRef = useRef<HTMLDivElement>(null);

  disabled = !setValue || disabled || values.length === 1;

  const selectItem = (item: Option) => {
    if (setValue) {
      setValue(item);
      setIsListOpen(false);
    }
  };
  const onToggleList = () => {
    setIsListOpen((v) => !v);
  };

  useOutsideClick(dropdownElementRef, () => setIsListOpen(false));

  const dropdownListShifter = () => {
    const paddingLimit = 16;
    const listElement = dropdownListRef.current;
    if (listElement) {
      const rect = listElement?.getBoundingClientRect();
      const rightPadding = window.innerWidth - (rect?.right || 0);
      const shift =
        rightPadding < paddingLimit ? rightPadding - paddingLimit : 0;
      dropdownListRef.current.style.left = `${shift}px`;
    }
  };

  useEffect(() => {
    dropdownListShifter();
  }, [isListOpen]);

  return (
    <div className={styles.ddWrapper} ref={dropdownElementRef}>
      <button
        disabled={disabled}
        type="button"
        className={classNames({
          [styles.ddHeader]: true,
          [styles.ddHeaderMin]: mobileMinContent,
        })}
        onClick={onToggleList}
      >
        <div className={styles.ddHeaderTitle}>{value.label}</div>
        <img
          className={styles.ddArrow}
          data-disabled={disabled}
          src={isListOpen ? ArrowUp : ArrowDown}
          alt=""
        />
      </button>
      <div>
        {isListOpen && (
          <div
            role="list"
            className={styles.ddList}
            ref={dropdownListRef}
            style={{
              zIndex: modalZindex,
            }}
          >
            {values.map((item) => (
              <button
                type="button"
                className={styles.ddListItem}
                key={item.value}
                onMouseDown={(e) => e.preventDefault()}
                onClick={(e) => {
                  selectItem(item);
                }}
              >
                <span>{item.label}</span>
                {item.value === value.value ? (
                  <img className={styles.ddArrow} src={Check} alt="" />
                ) : (
                  ""
                )}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
