import styles from "./Filters.module.scss";
import SearchFilter from "../../../SearchFilter/SearchFilter";
import ObjectTypeFilter from "./FilterTypes/FilterTypes";
import CCFilter from "./CCFilter/CCFilter";
import DateFilter from "./DateFilter/DateFilter";
import OtherFilters from "./OtherFilters/OtherFilters";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import DatasetsDropdown from "../../_mapFilterComponents/DatasetsDropdown/DatasetsDropdown";
import RegionDropdown from "../RegionDropdown/RegionDropdown";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../../../constants";
import ClearAllFiltersDesktopButton from "../../../../share/filters/ClearAllFiltersDesktopButton";
import MobileClearAllButton from "../../../../share/filters/MobileClearAllButton";

import SavedFilters from "../../../templates/SavedFilters";
import AddSavedFilters from "../../../templates/AddSavedFilters";
import { FilterGroup, SearchWrapper } from "../../../../share/filters";
import styled from "@emotion/styled";

export const FiltersWrapper = styled.div`
  display: grid;
  padding: 32px 24px 24px 24px;
  border-bottom: 1px solid var(--fourth-black-color);
  @media (max-width: ${breakpoints.medium}px) {
    padding: 16px 0 8px;
  }
  @media (max-width: ${breakpoints.small}px) {
    padding: 16px 0 8px;
  }
`;

const Filters = () => {
  const { config } = useTypedSelector((state) => state.mapObjectsInfo);

  const { setCleanAllFilters, getDefaultResult } = useActions();

  const handleClean = () => {
    setCleanAllFilters();
    getDefaultResult();
  };

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.medium}px)`,
  });
  return (
    <FiltersWrapper>
      <div className={styles.topContainer}>
        <div className={styles.regionsDatasetsWrapper}>
          <RegionDropdown />
          <DatasetsDropdown />
        </div>

        <div className={styles.topContainerButtons}>
          <SavedFilters isDisabled={false} />
          <AddSavedFilters />
        </div>
      </div>
      <SearchWrapper>
        <SearchFilter />
      </SearchWrapper>
      <FilterGroup onClick={(event) => event.stopPropagation()}>
        {config?.filters?.types ? <ObjectTypeFilter /> : ""}
        {config?.filters?.cc ? <CCFilter /> : ""}
        {config?.filters?.date ? <DateFilter /> : ""}
        {config?.filters?.other ? <OtherFilters /> : ""}
        <MobileClearAllButton onClick={handleClean} />
      </FilterGroup>

      {!isMobile && <ClearAllFiltersDesktopButton onClick={handleClean} />}
    </FiltersWrapper>
  );
};

export default Filters;
