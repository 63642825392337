import React, { useEffect } from "react";
import { CustomLoader } from "../../../share/Loader/Loader";
import { useActions } from "../../../hooks/useActions";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../../routes";

const RegisteredUser = () => {
  const { inviteForRegisteredUser } = useActions();
  const navigate = useNavigate();

  const parsedUrl = new URL(window.location.href);
  const uid = parsedUrl.searchParams.get("uid");
  const token = parsedUrl.searchParams.get("token");

  useEffect(() => {
    const result = inviteForRegisteredUser(uid, token);
    if (!!result) {
      navigate(RouteNames.LOGIN);
    }
    // eslint-disable-next-line
  }, [token, uid]);

  return <CustomLoader />;
};

export default RegisteredUser;
