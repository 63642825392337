const zIndexes = {
  header: 100,
  overlay: 200,

  filterPin: 18,
  filterBackdrop: 16,

  filters: 17,

  filtersModalMobile: 1006,

  modal: 103,
  modalBackdrop: 104,
  detailsModal: 107,

  navbar: 1000,

  notification: 400,
  loader: 500,
  searchInputWrapperMobile: 102,

  searchFilterContainer: 1001,
  searchFilterBackdrop: 1000,
  searchInputWrapper: 1003,
  suggestionWrapper: 1002,
  datasetsModal: 1005,

  filterModal: 1007,

  floatingTriggers: 1008,
  tooltips: 1009,
};

export default zIndexes;
