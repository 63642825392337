// noinspection TypeScriptUMDGlobal
import styled from "@emotion/styled";

type StyledButtonProps = ButtonProps & {
  buttonType?: "filled" | "bordered" | "text";
  isFlex?: boolean;
};

const StyledButton = styled.button<StyledButtonProps>`
  border-radius: 4px !important;
  text-align: center;
  font-family: Montserrat, Arial, sans-serif;
  box-shadow: none !important;
  cursor: pointer !important;
  outline: none;
  box-sizing: border-box;
  text-wrap: nowrap;

  display: ${(props) => (props.isFlex ? "flex" : "initial")};
  height: 40px;
  justify-content: center;
  align-items: center;
  /* gap:  icon ? 8px; */
  gap: ${(props) => (props.icon ? "8px" : "0")};
  align-self: stretch;
  width: ${(props) => (props.fullWidth ? "100%" : props.width)};

  /* color */

  --primary-color: ${(props) =>
    props.color === "dark"
      ? "var(--Dark-D400, #242731)"
      : "var(--Red-R400, #F46857)"};
  --primary-color-hover: ${(props) =>
    props.color === "dark"
      ? "var(--Dark-D300, #575F6E);"
      : "var(--Red-R300, #FB8261)"};
  --secondary-color-hover: ${(props) =>
    props.color === "dark"
      ? "var(--Dark-D75, #F5F5F7);"
      : "var(--Red-R300, #FB8261)"};
  --primary-color-disabled: var(--Dark-D200, #aeb1b5);
  --secondary-color-disabled: var(--Dark-D75, #f5f5f7);
  --tertiary-color-disabled: var(--Dark-D100, #e0e0e0);
  /* font */
  ${(props) =>
    props.size === "default" &&
    `font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.026px;`}
  ${(props) =>
    props.size === "medium" &&
    `font-size: 14px;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.017px;`}
  ${(props) =>
    props.size === "small" &&
    `
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px; /* 157.143% */
  `}
  /* size */
  ${(props) =>
    props.size === "medium" &&
    `
    height: 40px;
    padding: 0 24px;
  `}
  ${(props) =>
    props.size === "default" &&
    `
    height: 48px;
    padding: 0 32px;
  `}
    ${(props) =>
    props.size === "small" &&
    `
    height: 32px;
    padding: 0px 14px;
  `}
  /* button type */
  ${(props) =>
    props.buttonType === "filled" &&
    `
    background: var(--primary-color);
    color: var(--White-W500, #FFF);
    border: none;
  `}
  ${(props) =>
    props.buttonType === "bordered" &&
    `
    background: none;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
  `}
  ${(props) =>
    props.buttonType === "text" &&
    `
    color: var(--primary-color);
    background: none;
    border: none;
  `}
  /* hover */
   &:hover {
    ${(props) =>
      !props.disabled &&
      props.status !== "pressed" &&
      props.buttonType === "filled" &&
      `
      background: var(--primary-color-hover);
    `}
    ${(props) =>
      !props.disabled &&
      props.status !== "pressed" &&
      props.buttonType === "bordered" &&
      (props.color !== "red"
        ? `
      background: var(--secondary-color-hover);
    `
        : `
      border: 1px solid var(--secondary-color-hover);
    `)}
    ${(props) =>
      !props.disabled &&
      props.status !== "pressed" &&
      props.buttonType === "text" &&
      props.color !== "red" &&
      `
      background: var(--secondary-color-hover);
    `}
  }
  /* status */
  ${(props) =>
    props.status === "pressed" &&
    (props.color === "dark"
      ? `
      background: var(--tertiary-color-disabled);
      color: var(--primary-color);
    `
      : `
      background: var(--secondary-color-disabled);
      color: var(--primary-color);
    `)}
  /* active */
  &:active {
  }
  /* disabled */
  &:disabled {
    ${(props) =>
      props.buttonType === "filled" || props.buttonType === "bordered"
        ? `
      color: var(--primary-color-disabled);
      background: var(--secondary-color-disabled);
      border: 1px solid var(--tertiary-color-disabled);
    `
        : `
      color: var(--primary-color-disabled);
    `}
  }
`;

type ButtonProps = {
  onClick?: () => void;
  htmlType?: "button" | "submit" | "reset"; // mdn
  type?: "filled" | "bordered" | "text";
  disabled?: boolean;
  size?: "default" | "medium" | "small";
  color?: "dark" | "red";
  status?: "default" | "pressed";
  icon?: any;
  fullWidth?: boolean;
  width?: string;
};

const CustomButton: React.FC<ButtonProps> = ({
  children,
  size = "default",
  type = "filled",
  htmlType = "button",
  color = "dark",
  fullWidth = false,
  width = "min-content",
  icon,
  ...args
}) => {
  function renderIcon() {
    if (icon) {
      return <img src={icon} alt="btn_icon" />;
    }
    return null;
  }

  return (
    <StyledButton
      size={size}
      color={color}
      htmlType={htmlType}
      buttonType={type}
      fullWidth={fullWidth}
      isFlex={!!icon}
      width={width}
      {...args}
    >
      <span>{children}</span>
      {renderIcon()}
    </StyledButton>
  );
};

export default CustomButton;

export const GradientRedButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--primary-white-color);
  background: var(--third-red-color);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  padding: 0 24px;
  transition: scale 0.1s ease-out, opacity 0.3s ease-out,
    transform 0.3s ease-out, color 0.3s ease-out; // Added transition for background and color
  &:hover {
    color: var(--primary-white-color);
    opacity: 0.9;
  }
  &:active {
    transform: scale(0.98);
    opacity: 1;
  }
  // add animaition for hover
`;
