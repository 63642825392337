import { useState } from "react";
import useMobileScreen from "../useMobileScreen";
import useSearchParams from "../useSearchParams";
import geocodingClient from "../../constants/geocodingClient";
import { datasets } from "../../constants/mapConstants";
import { europeanCountries } from "../../constants/constants";
import { useActions } from "../useActions";
import { useTypedSelector } from "../useTypedSelector";

const useSearchFilter = () => {
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const [isSearchInFullView, setIsSearchInFullView] = useState<boolean>(false);
  const isMobile = useMobileScreen();
  const [searchText, setSearchText] = useState<string>("");
  const [isShown, setIsShown] = useState<boolean>(false);

  const { currentMapRef } = useTypedSelector((state) => state.mapInterface);

  const handleOpenSearchBox = () => {
    if (isMobile) {
      setIsSearchInFullView(true);
    }
  };

  const [geoSuggestions, setGeoSuggestions] = useState([]);

  const dataset = useSearchParams().getSearchParam("dataset");
  const fetchGeoSuggestions = async (query: string) => {
    if (!query) {
      setGeoSuggestions([]);
      return;
    }

    const response = await geocodingClient
      .forwardGeocode({
        query,
        countries:
          !dataset || dataset === datasets.objects ? europeanCountries : ["UA"], // Limit search to specific countries
        autocomplete: true,
        limit: 5,
      })
      .send();

    setGeoSuggestions(response.body.features);
  };

  const { setIsPulloutDrawerOpen } = useActions();

  const handleAddressSelect = (feature) => {
    if (!currentMapRef) return;
    const [lon, lat] = feature.center;
    currentMapRef.current.flyTo({
      center: [lon, lat],
      zoom: 14,
      transitionDuration: 500,
    });

    // setCurrentMarker({
    //   lat: lat,
    //   lon: lon,
    // });
    setSearchText(feature.place_name);
    setIsSearchInFullView(false);
    setIsShown(false);
    setIsPulloutDrawerOpen(false);
  };

  return {
    isSearchActive,
    isSearchInFullView,
    isMobile,
    searchText,
    isShown: isShown,
    setIsShown: setIsShown,
    setIsSearchActive,
    setIsSearchInFullView,
    setSearchText,
    handleOpenSearchBox,
    geoSuggestions,
    fetchGeoSuggestions,
    handleAddressSelect,
  };
};

export default useSearchFilter;
