import { IUser } from "../../../models/Accounts";
import { CurrentUserActions, CurrentUserActionsEnum } from "./types";
import { IFetchable } from "../../../models/IFetchable";

const initialState: IFetchable = {
  isLoading: false,
  error: undefined,
};

export type ICurrentUser = Partial<IUser & IFetchable>;

function currentUserReducer(
  state: ICurrentUser = initialState,
  action: CurrentUserActions
): Partial<IUser & IFetchable> {
  switch (action.type) {
    case CurrentUserActionsEnum.PATCH_USER:
      return { ...action.payload };
    case CurrentUserActionsEnum.SET_IS_USER_LOADING:
      return { ...state, isLoading: action.payload as boolean };
    case CurrentUserActionsEnum.SET_ERROR:
      return { ...state, error: action.payload as string, isLoading: false };
    case CurrentUserActionsEnum.SET_USER:
      return { ...action.payload };
    case CurrentUserActionsEnum.GET_USER:
      return {};
  }
  return state;
}

export default currentUserReducer;
