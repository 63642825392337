import { useState } from "react";
import Modal from "../../../share/Modal/Modal";
import reuseCss from "../../_maps/main/Filters/FiltersReuse.module.scss";

import styles from "../UserProfileBar.module.scss";
import { useTranslation } from "react-i18next";
import FiltersTab from "../ActiveFiltersTabs/FiltersTab";
import Templates from "../ActiveFiltersTabs/Templates";
import { icons } from "../../../constants";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";

type Props = {
  setActiveFiltersModal: any;
  activeFiltersModal: boolean;
};

const UserProfileBarFilterModal = ({
  setActiveFiltersModal,
  activeFiltersModal,
}: Props) => {
  const [activeTab, setActiveTab] = useState("filters");

  const { t: tFilterAction } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.filtersActions",
  });

  const { setCleanAllFilters, getDefaultResult } = useActions();
  const { count } = useTypedSelector(
    (state) => state.mapObjectsInfo.mapObjects
  );

  const handleClean = () => {
    setCleanAllFilters();
    getDefaultResult();
  };

  const { t: tDownloadResults } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.downloadResults",
  });

  const { t: tFilter } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.filtersActions",
  });

  const { t: filterTemplateForm } = useTranslation("translation", {
    keyPrefix: "filterTemplate.form",
  });

  return (
    <Modal
      modalVisible={activeFiltersModal}
      setModalVisible={setActiveFiltersModal}
    >
      <div className={styles.modalHeader}>
        <div
          className={`${styles.contentTabLink} ${
            activeTab === "filters" && styles.active
          }`}
          onClick={() => setActiveTab("filters")}
        >
          {tFilterAction("filters")}
        </div>

        <img
          onClick={() => setActiveFiltersModal(false)}
          src={icons.Close}
          alt="close"
        />
      </div>
      <div className={styles.modalAttention}>
        <img src={icons.Attention} alt="Attention" />
        <p>{filterTemplateForm("warning")}</p>
      </div>
      {activeTab === "filters" ? <FiltersTab /> : <Templates />}
      <div className={`${reuseCss.applyBtns} ${styles.width}`}>
        <p className={styles.result}>
          {tDownloadResults("result")}:{" "}
          <span>
            {count} {tDownloadResults("objects")}
          </span>
        </p>
        <button
          className={reuseCss.clearFiltersBtn}
          type="button"
          onClick={handleClean}
        >
          {tFilter("clearAllFiltersMobile", "Clear all")}
        </button>
      </div>
    </Modal>
  );
};

export default UserProfileBarFilterModal;
