import React, { createContext, useState } from "react";

export interface TipsContextProps {
  lastOpenedTipId: string;
  setLastOpenedTipId: React.Dispatch<React.SetStateAction<string>>;
}

export const TipsContext = createContext<TipsContextProps>({
  lastOpenedTipId: "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLastOpenedTipId: () => {},
});

export const TipsProvider: React.FC = ({ children }) => {
  const [lastOpenedTip, setLastOpenedTipId] = useState<string>("");

  return (
    <TipsContext.Provider
      value={{
        lastOpenedTipId: lastOpenedTip,
        setLastOpenedTipId,
      }}
    >
      {children}
    </TipsContext.Provider>
  );
};
