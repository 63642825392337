import styled from "@emotion/styled";
import { ReactNode } from "react";
import { colors } from "../../constants";
type TextProps = {
  color?: string;
  fontWeight?: string;
};
const StyledHeading = styled.div`
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: var(--secondary-black-color);
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const Title36 = styled(StyledHeading)`
  font-size: 36px;
  line-height: 42px;
  font-size: bold;
`;

export const Text16 = styled.p<TextProps>`
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || "normal"};
  font-size: 16px;
  line-height: 24px;
  /* color: ${colors.darkD400};
   */
  color: ${(props) => props.color || colors.darkD400};
`;

export const RedText = styled.span`
  color: ${colors.redR400};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const Heading2 = styled(StyledHeading)`
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
`;

export const Heading3 = styled(StyledHeading)`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  order: 0;
`;

export const ForbiddenTitle = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.0022em;
  color: var(--secondary-black-color);
  margin-bottom: 16px;
`;

export const ForbiddenText = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 1.555;
  color: var(--secondary-black-color);
  margin-bottom: 32px;
`;

type headingsTypesEnum = "h2" | "h3";

const headings: { [key in headingsTypesEnum]: any } = {
  h2: Heading2,
  h3: Heading3,
};

type Props = {
  type: headingsTypesEnum;
  children: ReactNode;
};

const Heading = ({ children, type }: Props) => {
  const StyledHeadingsByType = headings[type];
  return <StyledHeadingsByType>{children}</StyledHeadingsByType>;
};

export default Heading;
