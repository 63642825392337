import {
  EDRPOU,
  KVED,
  export_th_uah,
  ISelectedDocList,
  import_th_uah,
  annual_income_th_uah,
  region,
  registration_date_1,
} from "../../../../../models/CompanyObject";

import { ISearchResponse } from "../../../../../models/Search";

export interface ICompanyFilters {
  isSuggestionsLoading: boolean;
  isStanFilterActive: boolean;
  isCompanyDateFilterActive: boolean;
  kved_is_main: boolean;
  stanFilterArray: string[];
  selectedDocList: ISelectedDocList[];
  date_added__lte: registration_date_1;
  date_added__gte: registration_date_1;
  suggestions: ISearchResponse[];
  activeSuggestions: ISearchResponse[];
  edrpou: EDRPOU[];
  region: region[];
  kved: KVED[];
  export__range: export_th_uah;
  income__range: annual_income_th_uah;
  import__range: import_th_uah;
}
export enum MapFilterActionsEnum {
  SET_SUGGESTION_LOADING = "SET_SUGGESTION_LOADING",
  SET_EXPORT_RANGE = "SET_EXPORT_RANGE",
  SET_INCOME_RANGE = "SET_INCOME_RANGE",
  SET_KVED_IS_MAIN = "SET_KVED_IS_MAIN",
  SET_IMPORT_RANGE = "SET_IMPORT_RANGE",
  SET_COMPANY_DATE_FILTER_ACTIVE = "SET_COMPANY_DATE_FILTER_ACTIVE",
  SET_DATE_ADDED_LTE = "SET_DATE_ADDED_LTE",
  SET_DATE_ADDED_GTE = "SET_DATE_ADDED_GTE",
  TOGGLE_STAN_FILTER = "TOGGLE_STAN_FILTER",
  CLEAN_STAN_FILTER = "CLEAN_STAN_FILTER",
  SET_DOC_LIST = "SET_DOC_LIST",
  SET_COMPANY_SUGGESTION = "SET_COMPANY_SUGGESTION",
  SET_COMPANY_ACTIVE_SUGGESTION = "SET_COMPANY_ACTIVE_SUGGESTION",
  DELETE_COMPANY_ACTIVE_SUGGESTION = "DELETE_COMPANY_ACTIVE_SUGGESTION",
  CLEAN_COMPANY_SUGGESTION = "CLEAN_COMPANY_SUGGESTION",
  SET_COMPANY_REGION = "SET_COMPANY_REGION",
  CLEAN_COMPANY_REGION = "CLEAN_COMPANY_REGION",
  DELETE_COMPANY_REGION = "DELETE_COMPANY_REGION",
  SET_COMPANY_EDR_CODE = "SET_COMPANY_EDR_CODE",
  CLEAN_COMPANY_EDR_CODE = "CLEAN_COMPANY_EDR_CODE",
  DELETE_COMPANY_EDR_CODE = "DELETE_COMPANY_EDR_CODE",
  SET_COMPANY_KVED = "SET_COMPANY_KVED",
  CLEAN_COMPANY_KVED = "CLEAN_COMPANY_KVED",
  DELETE_COMPANY_KVED = "DELETE_COMPANY_KVED",
}

// SetSuggestionLoadingAction
export interface SetIsSuggestionsLoadingAction {
  type: MapFilterActionsEnum.SET_SUGGESTION_LOADING;
  payload: boolean;
}

export interface SetKvedIsMainAction {
  type: MapFilterActionsEnum.SET_KVED_IS_MAIN;
  payload: boolean;
}

export interface SetDateAddedLTE {
  type: MapFilterActionsEnum.SET_DATE_ADDED_LTE;
  payload: string;
}
export interface SetExportRange {
  type: MapFilterActionsEnum.SET_EXPORT_RANGE;
  payload: string;
}
export interface SetIncomeRange {
  type: MapFilterActionsEnum.SET_INCOME_RANGE;
  payload: string;
}
export interface SetImportRange {
  type: MapFilterActionsEnum.SET_IMPORT_RANGE;
  payload: string;
}
export interface SetDateAddedGTE {
  type: MapFilterActionsEnum.SET_DATE_ADDED_GTE;
  payload: string;
}

export interface SetStanFilter {
  type: MapFilterActionsEnum.TOGGLE_STAN_FILTER;
  payload: string;
}

export interface SetCleanStanFilter {
  type: MapFilterActionsEnum.CLEAN_STAN_FILTER;
  payload: [];
}

export interface SetSelectedDocList {
  type: MapFilterActionsEnum.SET_DOC_LIST;
  payload: ISelectedDocList[];
}

export interface SetCompanySuggestion {
  type: MapFilterActionsEnum.SET_COMPANY_SUGGESTION;
  payload: ISearchResponse[];
}

export interface SetCompanyActiveSuggestion {
  type: MapFilterActionsEnum.SET_COMPANY_ACTIVE_SUGGESTION;
  payload: ISearchResponse;
}

export interface DeleteCompanyActiveSuggestion {
  type: MapFilterActionsEnum.DELETE_COMPANY_ACTIVE_SUGGESTION;
  payload: ISearchResponse;
}

export interface SetCompanyCleanSuggestion {
  type: MapFilterActionsEnum.CLEAN_COMPANY_SUGGESTION;
  payload: [];
}

export interface SetCompanyRegion {
  type: MapFilterActionsEnum.SET_COMPANY_REGION;
  payload: string;
}

export interface SetCompanyCleanRegion {
  type: MapFilterActionsEnum.CLEAN_COMPANY_REGION;
  payload: [];
}

export interface DeleteCompanyRegion {
  type: MapFilterActionsEnum.DELETE_COMPANY_REGION;
  payload: string;
}

export interface SetCompanyEdrCode {
  type: MapFilterActionsEnum.SET_COMPANY_EDR_CODE;
  payload: string;
}

export interface SetCleanCompanyEdrCode {
  type: MapFilterActionsEnum.CLEAN_COMPANY_EDR_CODE;
  payload: [];
}

export interface DeleteCompanyEdrCode {
  type: MapFilterActionsEnum.DELETE_COMPANY_EDR_CODE;
  payload: string;
}

export interface SetCompanyKVED {
  type: MapFilterActionsEnum.SET_COMPANY_KVED;
  payload: string;
}

export interface SetCleanCompanyKVED {
  type: MapFilterActionsEnum.CLEAN_COMPANY_KVED;
  payload: [];
}

export interface DeleteCompanyKVED {
  type: MapFilterActionsEnum.DELETE_COMPANY_KVED;
  payload: string;
}

export type MapFiltersAction =
  | SetIsSuggestionsLoadingAction
  | SetExportRange
  | SetImportRange
  | SetStanFilter
  | SetCleanStanFilter
  | SetDateAddedGTE
  | SetDateAddedLTE
  | SetSelectedDocList
  | SetCompanySuggestion
  | SetCompanyActiveSuggestion
  | DeleteCompanyActiveSuggestion
  | SetCompanyRegion
  | DeleteCompanyRegion
  | SetCompanyEdrCode
  | DeleteCompanyEdrCode
  | SetCompanyCleanSuggestion
  | SetCompanyCleanRegion
  | SetCleanCompanyEdrCode
  | SetCompanyKVED
  | SetCleanCompanyKVED
  | DeleteCompanyKVED
  | SetKvedIsMainAction
  | SetIncomeRange;
