import React from "react";
import Navbar from "../../components/nav/Navbar/Navbar";
import styles from "../_maps/Main/Main.module.scss";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const Dashboard = () => {
  const { email } = useTypedSelector((state) => state.auth.user);

  return (
    <div className={styles.appWrapper}>
      <Navbar />
      <div className={styles.pageContainer}>
        {email === "a.borodiuk@galleon.com.ua" ? (
          <iframe
            width="100%"
            height="100%"
            src="https://app.powerbi.com/view?r=eyJrIjoiNmFmODYzY2ItNzNjOS00MWRhLWEwOWYtNzFlM2EwODhhMjE2IiwidCI6IjA4NjYyZGIxLTg0NmUtNDhiZC04ZWExLTJmZGU5MmQ2ZjVk"
            frameBorder="0"
            title="1"
            allowFullScreen={true}
          />
        ) : email === "v.malitsky@galleon.com.ua" ? (
          <iframe
            width="100%"
            height="100%"
            src="https://app.powerbi.com/view?r=eyJrIjoiNmFmODYzY2ItNzNjOS00MWRhLWEwOWYtNzFlM2EwODhhMjE2IiwidCI6IjA4NjYyZGIxLTg0NmUtNDhiZC04ZWExLTJmZGU5MmQ2ZjVk"
            frameBorder="0"
            title="2"
            allowFullScreen={true}
          />
        ) : (
          <iframe
            width="100%"
            height="100%"
            title="3"
            src="https://app.powerbi.com/view?r=eyJrIjoiMGY3MWVmYTEtMGMzOS00ZjdhLWI4N2MtMDllZDg2NjU2ZWI5IiwidCI6IjA4NjYyZGIxLTg0NmUtNDhiZC04ZWExLTJmZGU5MmQ2ZjVkZSIsImMiOjl9"
            frameBorder="0"
            allowFullScreen={true}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
