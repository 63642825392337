import React from "react";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { useActions } from "../../../../../hooks/useActions";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { FilterTitleWithInfo } from "../../../../../share/filters/FilterTitleWithInfo";
import {
  FilterSection,
  FilterSectionSeparatorLine,
  FilterSections,
} from "../../../../../share/filters";
import FilterButtonWithModal from "../../../../../share/filters/FilterButtonWithModal";
import RangeSlider from "../../../../../share/RangeSlider/RangeSlider";

function valuetext(value: number) {
  return `${value}°C`;
}
const ExportImportFilters = () => {
  const filterId = "companyExportImport";
  const { export__range, import__range, income__range } = useTypedSelector(
    (state) => state.companyFilters
  );
  const {
    setExportRange,
    setImportRange,
    setIncomeRange,
    setSelectedDocList,
    applyFilter,
  } = useActions();

  const maxExport = 1300000;
  const maxImport = 340000;
  const maxIncome = 1300000;
  const export_arr =
    export__range === "" ? [0, maxExport] : export__range.split("__");
  const [exportValue, setExportValue] = React.useState<number[]>([
    +export_arr[0],
    +export_arr[1],
  ]);
  const import_arr =
    import__range === "" ? [0, maxImport] : import__range.split("__");
  const [importValue, setImportValue] = React.useState<number[]>([
    +import_arr[0],
    +import_arr[1],
  ]);

  const income_arr =
    income__range === "" ? [0, maxIncome] : income__range.split("__");
  const [incomeValue, setIncomeValue] = React.useState<number[]>([
    +income_arr[0],
    +income_arr[1],
  ]);

  const handleExportChange = (newValue) => {
    setExportValue(newValue as number[]);
    if (newValue.length > 1) {
      if (newValue[0] === 0 && newValue[1] === 0) {
        setExportRange("");
      } else {
        setExportRange(
          ((newValue[0] as unknown as string) +
            "__" +
            newValue[1]) as unknown as string
        );
      }
    } else {
      if (newValue[0] === 0) {
        setExportRange("");
      } else {
        setExportRange(newValue[0] as unknown as string);
      }
    }
  };

  const handleIncomeChange = (newValue) => {
    setIncomeValue(newValue as number[]);
    if (newValue.length > 1) {
      if (newValue[0] === 0 && newValue[1] === 0) {
        setIncomeRange("");
      } else {
        setIncomeRange(
          ((newValue[0] as unknown as string) +
            "__" +
            newValue[1]) as unknown as string
        );
      }
    } else {
      if (newValue[0] === 0) {
        setIncomeRange("");
      } else {
        setIncomeRange(newValue[0] as unknown as string);
      }
    }
  };

  const handleImportChange = (newValue) => {
    setImportValue(newValue as number[]);
    if (newValue.length > 1) {
      if (newValue[0] === 0 && newValue[1] === 0) {
        setImportRange("");
      } else {
        setImportRange(
          ((newValue[0] as unknown as string) +
            "__" +
            newValue[1]) as unknown as string
        );
      }
    } else {
      if (newValue[0] === 0) {
        setImportRange("");
      } else {
        setImportRange(newValue[0] as unknown as string);
      }
    }
  };

  const handleClean = () => {
    setImportRange("");
    setExportRange("");
    setIncomeRange("");
    setExportValue([0, 0]);
    setImportValue([0, 0]);
    setIncomeValue([0, 0]);
    setSelectedDocList([{ id: uuidv4(), value: "1" }]);
    applyFilter();
  };

  const filterCount =
    (export__range === "" ? 0 : 1) +
    (import__range === "" ? 0 : 1) +
    (income__range === "" ? 0 : 1);

  const { t: tEI } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.companies.exportImportFilter",
  });

  const filterButtonText = tEI("title");
  const pinText = filterCount > 0 ? filterCount.toString() : undefined;

  return (
    <FilterButtonWithModal
      title={filterButtonText}
      pinText={pinText}
      filterId={filterId}
      onClear={handleClean}
      fullWidth={true}
    >
      <FilterSections>
        <FilterSection>
          <FilterTitleWithInfo title={tEI("annualIncome")} />
          <RangeSlider
            sManualInput={false}
            value={incomeValue}
            valueLabelDisplay="auto"
            onChange={handleIncomeChange}
            onApply={applyFilter}
            getAriaValueText={valuetext}
            max={maxIncome}
            min={0}
          />
        </FilterSection>
        <FilterSectionSeparatorLine />
        <FilterSection>
          <FilterTitleWithInfo title={tEI("export")} />
          <RangeSlider
            isManualInput={false}
            value={exportValue}
            valueLabelDisplay="auto"
            onChange={handleExportChange}
            onApply={applyFilter}
            getAriaValueText={valuetext}
            max={maxExport}
            min={0}
          />
        </FilterSection>
        <FilterSectionSeparatorLine />
        <FilterSection>
          <FilterTitleWithInfo title={tEI("import")} />
          <RangeSlider
            isManualInput={false}
            value={importValue}
            valueLabelDisplay="auto"
            onChange={handleImportChange}
            onApply={applyFilter}
            getAriaValueText={valuetext}
            max={maxImport}
            min={0}
          />
        </FilterSection>
      </FilterSections>
    </FilterButtonWithModal>
  );
};

export default ExportImportFilters;
