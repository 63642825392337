import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropdown, { Option } from "../../../../share/Dropdown/Dropdown";
import {
  availableDatasetsRegionMap,
  defaultDataset,
  defaultRegion,
} from "../../../../constants/mapConstants";
import { IRegion } from "../../../../models/map.interface";
import useSearchParams from "../../../../hooks/useSearchParams";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import { zIndexes } from "../../../../constants";
import { useTipsContext } from "../../../../hooks/useTips";

const DatasetsDropdown = () => {
  const { t } = useTranslation("translation", { keyPrefix: "config.datasets" });
  const { setSearchParam, getSearchParam } = useSearchParams();
  const { setPage, setCurrentMarker } = useActions();

  const { usaid, sika_designers } = useTypedSelector(
    (state) =>
      state.currentAccount.subscribe?.permissions ?? {
        usaid: [],
        sika_designers: [],
      }
  );

  const isDatasetAllowed = useCallback(
    (dataset: string) => {
      const isUsaidAllowed = usaid && usaid[0] === "1";
      const isSikaAllowed = sika_designers && sika_designers[0] === "1";
      if (dataset === "usaid") {
        return isUsaidAllowed;
      }
      if (dataset === "sika") {
        return isSikaAllowed;
      }
      return true;
    },
    [usaid, sika_designers]
  );

  const region = (getSearchParam("region") as IRegion) || defaultRegion;
  const dataset = getSearchParam("dataset") || (defaultDataset as IRegion);

  const availableDatasets =
    availableDatasetsRegionMap[region].filter(isDatasetAllowed);

  const defaultOption = {
    label: t(dataset as string),
    value: dataset,
  };

  const [selectedOption, setSelectedOption] = useState<Option>(defaultOption);

  const options: Option[] = Object.values(availableDatasets).map(
    (dataset: string) => ({
      label: t(dataset as string),
      value: dataset,
    })
  );

  const { setLastOpenedTipId } = useTipsContext();
  const onSelect = (option: Option) => {
    setSelectedOption(option);
    setPage(1);
    setCurrentMarker({ lat: 0, lon: 0 });
    setSearchParam("dataset", option.value);
    setLastOpenedTipId("");
  };

  return (
    <Dropdown
      values={options}
      setValue={onSelect}
      value={selectedOption}
      modalZindex={zIndexes.datasetsModal}
    ></Dropdown>
  );
};

export default DatasetsDropdown;
