import { FC } from "react";
import {
  FormContainer,
  FormSection,
} from "../../../../share/formComponents/formComponents";
import { Col, Form, Input, Row } from "antd";
import CustomButton from "../../../../share/Button/Button";
import { useTranslation } from "react-i18next";
import useMobileProfile from "../useMobileProfile";

enum formNames {
  name = "name",
  full_name = "full_name",
  company_name = "company_name",
  position = "position",
  phone_number = "phone_number",
  email = "email",
  password = "password",
}

const BasicInfoScreen: FC = () => {
  const { t: tBaseInfo } = useTranslation("translation", {
    keyPrefix: "profilePage.baseInfoForm",
  });

  const {
    contextHolder,
    form,
    handleSubmit,
    setHasFormChanged,
    formInitialValues,
    hasFormChanged,
  } = useMobileProfile();

  return (
    <FormContainer>
      {contextHolder}
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onValuesChange={() => {
          setHasFormChanged(true);
        }}
        initialValues={formInitialValues}
      >
        <FormSection
          headerText={tBaseInfo("baseInfoTitle")}
          subheaderText={tBaseInfo("baseInfoSubtitle")}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label={tBaseInfo("fullNameLabel")}
                name={formNames.full_name}
              >
                <Input placeholder={tBaseInfo("fullNameLabel")} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} xs={24}>
              <Form.Item
                label={tBaseInfo("positionLabel")}
                name={formNames.position}
              >
                <Input placeholder={tBaseInfo("positionLabel")} />
              </Form.Item>
            </Col>
            <Col span={12} xs={24}>
              <Form.Item
                label={tBaseInfo("companyLabel")}
                name={formNames.company_name}
              >
                <Input placeholder={tBaseInfo("companyLabel")} />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
        <CustomButton
          size={"medium"}
          htmlType="submit"
          disabled={!hasFormChanged}
        >
          {tBaseInfo("submit")}
        </CustomButton>
      </Form>
    </FormContainer>
  );
};

export default BasicInfoScreen;
