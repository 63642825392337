import styled from "@emotion/styled";
import useMobileScreen from "../../../../hooks/useMobileScreen";
import {
  MobileModal,
  MobileModalBody,
} from "../../../../share/MobileModal/MobileModal";
import { breakpoints, zIndexes } from "../../../../constants";
import TemplateList from "./TemplateList";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import { useEffect } from "react";
import { StyledBackDrop } from "../../../../share/SearchFilter/SearchFilterStyledComponents";
import { useTranslation } from "react-i18next";

type Props = {
  isShown: boolean;
  onClose: () => void;
};

const StyledTemplateListContainer = styled.div`
  z-index: ${zIndexes.filterModal};
  position: absolute;
  top: 100%;
  right: -58px;
  margin-top: 8px;
  padding: 24px;
  width: 512px;
  max-height: 534px;
  padding: 24px;
  border-radius: 8px;
  gap: 24px;
  background: #ffffff;
  border: 1px solid #091e4226;
  box-shadow: 0px 8px 12px 0px #091e4226;
  overflow: auto;

  @media screen and (max-width: ${breakpoints.medium + "px"}) {
    width: 327px;
    right: -46px;
  }
`;

const SavedFiltersSidebarModal = ({ isShown, onClose }: Props) => {
  const isMobile = useMobileScreen();
  const { templates } = useTypedSelector((state) => state.templates);

  const { id: currAccId } = useTypedSelector((state) => state.currentAccount);
  const { getTemplates } = useActions();
  useEffect(() => {
    if (currAccId) {
      getTemplates();
    }
  }, [isShown, currAccId]);

  const { t: tList } = useTranslation("translation", {
    keyPrefix: "filterTemplate.list",
  });

  return (
    <>
      {!isMobile
        ? isShown && (
            <>
              <StyledBackDrop onClick={onClose} />
              <StyledTemplateListContainer>
                <TemplateList templateList={templates} />
              </StyledTemplateListContainer>
            </>
          )
        : isShown && (
            <MobileModal
              applyBtnText={tList("actions.apply")}
              title={tList("title")}
              onClose={onClose}
              hasActions={templates.length > 0}
            >
              <MobileModalBody>
                <TemplateList templateList={templates} />
              </MobileModalBody>
            </MobileModal>
          )}
    </>
  );
};

export default SavedFiltersSidebarModal;
