import React from "react";
import styles from "./styles.module.scss";
import styled from "@emotion/styled";

type Props = {
  isActive: boolean;
  onClick: () => void;
  text: string;
};

export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px 0 24px;
  background-color: var(--sixth-black-color);
  border-bottom: 1px solid var(--fourth-black-color);
  gap: 24px;
`;

const Tab = ({ isActive, onClick, text }: Props) => {
  return (
    <div
      className={`${styles.contentTabLink} ${isActive && styles.active}`}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

export default Tab;
