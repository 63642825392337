import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../share/Logo/Logo";
import Close from "../../assets/svg/Close.svg";
import styles from "./ReusableAuth.module.scss";
import RegistrationStepLoader from "../../share/RegistrationStepLoader/RegistrationStepLoader";
import { RouteNames } from "../../routes";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../constants";

interface IProps {
  children: ReactNode;
  page: number;
}

const ReusableAuth = ({ children, page }: IProps) => {
  const navigate = useNavigate();

  const isTablet = useMediaQuery({
    query: `(max-width: ${breakpoints.medium}px)`,
  });

  const handleClick = () => {
    navigate(RouteNames.LOGIN);
  };

  return (
    <>
      <div className={styles.header}>
        <Logo />
        {!isTablet && <RegistrationStepLoader page={page} />}
        <button
          type="button"
          onClick={handleClick}
          className={page === 3 ? styles.hidden : styles.closeButtonSvg}
        >
          <img src={Close} alt="Close" />
        </button>
      </div>
      <>
        {isTablet && <RegistrationStepLoader page={page} />}
        {children}
      </>
    </>
  );
};

export default ReusableAuth;
