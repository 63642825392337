import {
  aimapClassifier,
  constructionType,
  edrpou_code,
  ISelectedDocList,
  np_id,
  region,
  stageBuilding,
  stageDocumentation,
} from "../../../../../models/MapObject";
import { ISearchResponse } from "../../../../../models/Search";

export type FilterIdsEnum =
  | "type"
  | "cc"
  | "date"
  | "other"
  | "companyExportImport"
  | "companyStan"
  | "companyPeriod"
  | "personsStan"
  | "personsPeriod"
  | "usaidEconomicSector"
  | "usaidEntityType"
  | "usaidCategory"
  | "usaidActivityStatus"
  | "sikaTypeFilter"
  | "sikaExportImport"
  | "sikaRegionFilter"
  | "sikaOtherFilters"
  | "addSavedFiltersModal"
  | "savedFiltersListModal"
  | "notifications"
  | "";

export interface IMapFilters {
  isSuggestionsLoading: boolean;
  typeFilterArray: aimapClassifier[];
  ccFilterArray: string[];
  stageDocumentation: stageDocumentation[];
  selectedDocList: ISelectedDocList[];
  constructionType: constructionType[];
  stageBuilding: stageBuilding[];
  from_date: string;
  to_date: string;
  suggestions: ISearchResponse[];
  activeSuggestions: ISearchResponse[];
  region: region[];
  edrpou_code: edrpou_code[];
  np_id: np_id[];
  dkbs_code: string[];
  keyword: string[];
  activeFilterId: FilterIdsEnum;
  squareFilter: number[];
}

export enum MapFilterActionsEnum {
  SET_SUGGESTION_LOADING = "SET_SUGGESTION_LOADING",
  TOGGLE_FILTER_ACTION = "TOGGLE_FILTER_ACTION",
  // SET_TYPE_FILTER_ACTIVE = "SET_TYPE_FILTER_ACTIVE",
  // SET_CC_FILTER_ACTIVE = "SET_CC_FILTER_ACTIVE",
  // SET_DATE_FILTER_ACTIVE = "SET_DATE_FILTER_ACTIVE",
  // SET_OTHER_FILTER_ACTIVE = "SET_OTHER_FILTER_ACTIVE",
  SELECT_TYPE_FILTER = "SELECT_TYPE_FILTER",
  CLEAN_TYPE_FILTER = "CLEAN_TYPE_FILTER",
  SELECT_CC_FILTER = "SELECT_CC_FILTER",
  CLEAN_CC_FILTER = "CLEAN_CC_FILTER",
  SET_STAGE_FILTER = "SET_STAGE_FILTER",
  DELETE_STAGE_FILTER = "DELETE_STAGE_FILTER",
  CLEAN_STAGE_FILTER = "CLEAN_STAGE_FILTER",
  SET_DOC_LIST = "SET_DOC_LIST",
  TOGGLE_CONSTRUCTION_FILTER = "TOGGLE_CONSTRUCTION_FILTER",
  CLEAN_CONSTRUCTION_FILTER = "CLEAN_CONSTRUCTION_FILTER",
  TOGGLE_BUILD_FILTER = "TOGGLE_BUILD_FILTER",
  CLEAN_BUILD_FILTER = "CLEAN_BUILD_FILTER",
  SET_FROM_DATE = "SET_FROM_DATE",
  SET_TO_DATE = "SET_TO_DATE",
  SET_SUGGESTION = "SET_SUGGESTION",
  SET_ACTIVE_SUGGESTION = "SET_ACTIVE_SUGGESTION",
  DELETE_ACTIVE_SUGGESTION = "DELETE_ACTIVE_SUGGESTION",
  CLEAN_SUGGESTION = "CLEAN_SUGGESTION",
  SET_REGION = "SET_REGION",
  CLEAN_REGION = "CLEAN_REGION",
  DELETE_REGION = "DELETE_REGION",
  SET_KEYWORD = "SET_KEYWORD",
  CLEAN_KEYWORD = "CLEAN_KEYWORD",
  DELETE_KEYWORD = "DELETE_KEYWORD",
  SET_EDR_CODE = "SET_EDR_CODE",
  CLEAN_EDR_CODE = "CLEAN_EDR_CODE",
  DELETE_EDR_CODE = "DELETE_EDR_CODE",
  SET_NP_ID = "SET_NP_ID",
  CLEAN_NP_ID = "CLEAN_NP_ID",
  CLEAN_DKBS_CODE = "CLEAN_DKBS_CODE",
  SET_DKBS_CODE = "SET_DKBS_CODE",
  DELETE_NP_ID = "DELETE_NP_ID",
  SET_SQUARE_FILTER = "SET_SQUARE_FILTER",
  DELETE_DKBS_CODE = "DELETE_DKBS_CODE",
}

// SetSuggestionLoadingAction
export interface SetIsSuggestionsLoadingAction {
  type: MapFilterActionsEnum.SET_SUGGESTION_LOADING;
  payload: boolean;
}

export interface ToggleFilterAction {
  type: MapFilterActionsEnum.TOGGLE_FILTER_ACTION;
  payload: string;
}

export interface SetTypeFilter {
  type: MapFilterActionsEnum.SELECT_TYPE_FILTER;
  payload: string;
}

export interface SetCleanFilter {
  type: MapFilterActionsEnum.CLEAN_TYPE_FILTER;
  payload: [];
}

export interface SetCCFilter {
  type: MapFilterActionsEnum.SELECT_CC_FILTER;
  payload: string;
}

export interface SetCleanCCFilter {
  type: MapFilterActionsEnum.CLEAN_CC_FILTER;
  payload: [];
}

export interface SetStageFilter {
  type: MapFilterActionsEnum.SET_STAGE_FILTER;
  payload: string[];
}

export interface DeleteStageFilter {
  type: MapFilterActionsEnum.DELETE_STAGE_FILTER;
  payload: string;
}

export interface SetCleanStageFilter {
  type: MapFilterActionsEnum.CLEAN_STAGE_FILTER;
  payload: [];
}

export interface SetConstructionFilter {
  type: MapFilterActionsEnum.TOGGLE_CONSTRUCTION_FILTER;
  payload: string;
}

export interface SetSelectedDocList {
  type: MapFilterActionsEnum.SET_DOC_LIST;
  payload: ISelectedDocList[];
}

export interface SetCleanConstructionFilter {
  type: MapFilterActionsEnum.CLEAN_CONSTRUCTION_FILTER;
  payload: [];
}

export interface SetBuildFilter {
  type: MapFilterActionsEnum.TOGGLE_BUILD_FILTER;
  payload: string;
}

export interface SetCleanBuildFilter {
  type: MapFilterActionsEnum.CLEAN_BUILD_FILTER;
  payload: [];
}

export interface SetFromDate {
  type: MapFilterActionsEnum.SET_FROM_DATE;
  payload: string;
}

export interface SetToDate {
  type: MapFilterActionsEnum.SET_TO_DATE;
  payload: string;
}

export interface SetSuggestion {
  type: MapFilterActionsEnum.SET_SUGGESTION;
  payload: ISearchResponse[];
}

export interface SetActiveSuggestion {
  type: MapFilterActionsEnum.SET_ACTIVE_SUGGESTION;
  payload: ISearchResponse;
}

export interface DeleteActiveSuggestion {
  type: MapFilterActionsEnum.DELETE_ACTIVE_SUGGESTION;
  payload: ISearchResponse;
}

export interface SetCleanSuggestion {
  type: MapFilterActionsEnum.CLEAN_SUGGESTION;
  payload: [];
}

export interface SetRegion {
  type: MapFilterActionsEnum.SET_REGION;
  payload: string;
}

export interface SetCleanRegion {
  type: MapFilterActionsEnum.CLEAN_REGION;
  payload: [];
}

export interface DeleteRegion {
  type: MapFilterActionsEnum.DELETE_REGION;
  payload: string;
}

export interface SetKeyword {
  type: MapFilterActionsEnum.SET_KEYWORD;
  payload: string;
}

export interface SetCleanKeyword {
  type: MapFilterActionsEnum.CLEAN_KEYWORD;
  payload: [];
}

export interface SetSquareFilter {
  type: MapFilterActionsEnum.SET_SQUARE_FILTER;
  payload: number[];
}
export interface DeleteKeyword {
  type: MapFilterActionsEnum.DELETE_KEYWORD;
  payload: string;
}

export interface SetEdrCode {
  type: MapFilterActionsEnum.SET_EDR_CODE;
  payload: string;
}

export interface SetCleanEdrCode {
  type: MapFilterActionsEnum.CLEAN_EDR_CODE;
  payload: [];
}

export interface DeleteEdrCode {
  type: MapFilterActionsEnum.DELETE_EDR_CODE;
  payload: string;
}

export interface SetNpId {
  type: MapFilterActionsEnum.SET_NP_ID;
  payload: string;
}

export interface SetCleanNpId {
  type: MapFilterActionsEnum.CLEAN_NP_ID;
  payload: [];
}

export interface DeleteNpId {
  type: MapFilterActionsEnum.DELETE_NP_ID;
  payload: string;
}

// DeleteDkbs
export interface DeleteDkbs {
  type: MapFilterActionsEnum.DELETE_DKBS_CODE;
  payload: string;
}

export interface SetDkbsCode {
  type: MapFilterActionsEnum.SET_DKBS_CODE;
  payload: string;
}

export interface SetCleanDkbsCode {
  type: MapFilterActionsEnum.CLEAN_DKBS_CODE;
  payload: [];
}

export type MapFiltersAction =
  | SetIsSuggestionsLoadingAction
  | SetTypeFilter
  | SetCleanCCFilter
  | SetCCFilter
  | SetCleanFilter
  | SetStageFilter
  | DeleteStageFilter
  | SetCleanStageFilter
  | SetSelectedDocList
  | SetConstructionFilter
  | SetCleanConstructionFilter
  | SetBuildFilter
  | SetCleanBuildFilter
  | SetFromDate
  | SetToDate
  | SetSuggestion
  | SetActiveSuggestion
  | DeleteActiveSuggestion
  | SetRegion
  | DeleteRegion
  | SetEdrCode
  | DeleteEdrCode
  | SetNpId
  | DeleteNpId
  | SetCleanSuggestion
  | SetCleanRegion
  | SetCleanEdrCode
  | SetCleanNpId
  | SetDkbsCode
  | SetCleanDkbsCode
  | SetKeyword
  | SetCleanKeyword
  | DeleteKeyword
  | ToggleFilterAction
  | SetSquareFilter
  | DeleteDkbs
  | ToggleFilterAction;
