import {
  CompaniesMapActionsEnum,
  MapObjectAction,
  IMapCompanyInfoState,
} from "./types";

const initialState: IMapCompanyInfoState = {
  isObjectsLoading: false,
  params: {},
  hash_ids: [],
  company: null,
  companies: [],
  companyMapRef: null,
  count: 0,
};

export default function companyReducer(
  state = initialState,
  action: MapObjectAction
) {
  switch (action.type) {
    case CompaniesMapActionsEnum.SET_IS_COMPANY_LOADING:
      return { ...state, isObjectsLoading: action.payload };

    case CompaniesMapActionsEnum.SET_MAP_COMPANY:
      return { ...state, ...action.payload };
    case CompaniesMapActionsEnum.SET_MAP_COMPANIES:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
