import { message, notification } from "antd";
import { createContext, useState } from "react";

type Notification = {
  args: any;
  timeStamp: number;
  type: string;
};

export type AppContextType = {
  isHamburgerMenuOpen: boolean;
  setIsHamburgerMenuOpen: any;
  toggleHamburgerMenu: () => void;
  messageContextHolder: any;
  messageApi: any;
  notificationApi: any;
  contextHolder: any;
  lastNotifications: Notification[];
  getNotificationsFromSessionStorage: () => Notification[];
  isNewNotifications: boolean;
  setIsNewNotifications: any;
};

export const AppContext = createContext<AppContextType | undefined>(undefined);
export const AppContextProvider = ({ children }) => {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] =
    useState<boolean>(false);
  const [isNewNotifications, setIsNewNotifications] = useState(false);
  // state for notification stack
  const [lastNotifications, setLastNotifications] = useState<Notification[]>(
    []
  );
  const [messageApi, messageContextHolder] = message.useMessage();

  const toggleHamburgerMenu = () => {
    setIsHamburgerMenuOpen((prevState) => !prevState);
  };
  const [antdNotificationApi, contextHolder] = notification.useNotification({
    maxCount: 1,
    placement: "bottomRight",
  });

  function getNotificationsFromSessionStorage(): Notification[] {
    return JSON.parse(sessionStorage.getItem("notifications") || "[]");
  }

  function addNotificationToStack(type, args) {
    setIsNewNotifications(true);
    setLastNotifications((prevState) => [
      ...prevState,
      { args, timeStamp: Date.now(), type },
    ]);
  }

  const notificationApi = {
    success(args, save = true) {
      if (save) addNotificationToStack("success", args);
      // add args to notification arr to save them
      antdNotificationApi.success(args);
    },
    error(args, save = true) {
      if (save) addNotificationToStack("error", args);
      // add args to notification arr to save them
      antdNotificationApi.error(args);
    },
    warning(args, save = true) {
      if (save) addNotificationToStack("warning", args);
      // add args to notification arr to save them
      antdNotificationApi.warning(args);
    },
    info(args, save = true) {
      if (save) addNotificationToStack("info", args);
      // add args to notification arr to save them
      antdNotificationApi.info(args);
    },
    open(args, save = true, overriddenArgs = {}) {
      if (save) addNotificationToStack("open", { ...args, ...overriddenArgs });
      // add args to notification arr to save them
      antdNotificationApi.open({ ...args });
    },
    destroy() {
      antdNotificationApi.destroy();
    },
  };

  return (
    <AppContext.Provider
      value={{
        isHamburgerMenuOpen,
        setIsHamburgerMenuOpen,
        toggleHamburgerMenu,
        messageContextHolder,
        messageApi,
        notificationApi,
        contextHolder,
        lastNotifications,
        getNotificationsFromSessionStorage,
        isNewNotifications,
        setIsNewNotifications,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
