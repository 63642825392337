import { AuthAction, AuthActionsEnum, AuthState } from "./types";
import { AuthResponse } from "../../../models/AuthResponse";
import { UserWithAccount } from "../../../models/IUser";

const initialState: AuthState = {
  isAuth: false,
  error: "",
  isLoading: false,
  isWarned: false,
  lastRouteBeforeRedirect: "",
  token: {} as AuthResponse,
  user: {} as UserWithAccount,
  appVersion: "0.1.0",
};

export default function authReducer(
  state = initialState,
  action: AuthAction
): AuthState {
  switch (action.type) {
    case AuthActionsEnum.SET_AUTH:
      return { ...state, isAuth: action.payload, isLoading: false };

    case AuthActionsEnum.SET_TOKEN:
      return { ...state, token: action.payload };

    case AuthActionsEnum.SET_ACCESS:
      return { ...state, token: { ...state.token, access: action.payload } };

    case AuthActionsEnum.SET_APP_VERSION:
      return { ...state, appVersion: action.payload };

    case AuthActionsEnum.SET_USER:
      return { ...state, user: action.payload };

    case AuthActionsEnum.SET_IS_AUTH_LOADING:
      return { ...state, isLoading: action.payload };

    case AuthActionsEnum.SET_ERROR:
      return { ...state, error: action.payload, isLoading: false };

    case AuthActionsEnum.SET_IS_WARNED:
      return { ...state, isWarned: action.payload };

    case AuthActionsEnum.LAST_ROUTE_BEFORE_REDIRECT:
      return { ...state, lastRouteBeforeRedirect: action.payload };

    default:
      return state;
  }
}
