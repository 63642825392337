import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import styled from "@emotion/styled";
import BadgeLoader from "../../../../share/loaders/BadgeLoader";
import { breakpoints } from "../../../../constants";

const StyledLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 148px;
  @media (max-width: ${breakpoints.small}) {
    margin-top: 42px;
  }
`;
const MapBadgeLoader = () => {
  const { isLoading } = useTypedSelector((state) => state.mapInterface);

  return isLoading ? (
    <StyledLoaderWrapper>
      <BadgeLoader />
    </StyledLoaderWrapper>
  ) : null;
};

export default MapBadgeLoader;
