import styled from "@emotion/styled";
import { zIndexes } from "../constants";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndexes.filterBackdrop};
  background-color: transparent;
`;

export default Backdrop;
