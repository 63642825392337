import React from "react";
import { FilterIdsEnum } from "../../../store/reducers/_maps/main/mapFilters/types";
import FilterModal from "../FilterModal";
import FilterButton from "../FilterButton/FilterButton";
import useIsFilterActive from "../../../hooks/useIsFilterActive";
import { useActions } from "../../../hooks/useActions";
import styled from "@emotion/styled";
import { breakpoints } from "../../../constants";
import useMobileScreen from "../../../hooks/useMobileScreen";

export const StyledFilterWrapper = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  @media screen and (max-width: ${breakpoints.small}) {
    padding: 8px 0;
  }
`;

type Props = {
  title: string;
  description?: string;
  filterId: FilterIdsEnum;
  pinText: string | number;
  onClear: () => void;
  children: React.ReactNode;
  left?: number;
  width?: number;
  fullWidth?: boolean;
};

const FilterButtonWithModal = ({
  title,
  description,
  filterId,
  pinText,
  onClear,
  children,
  left = 0,
  width,
  fullWidth = false,
}: Props) => {
  const isFilterActive = useIsFilterActive(filterId);
  const { toggleFilter } = useActions();
  const isTablet = useMobileScreen(breakpoints.medium);
  left = isTablet ? 0 : left;
  return (
    <StyledFilterWrapper fullWidth={fullWidth}>
      <FilterButton filterId={filterId} title={title} pinText={pinText}>
        {title}
      </FilterButton>
      {isFilterActive && (
        <FilterModal
          title={title}
          onClose={() => toggleFilter(filterId)}
          onClear={onClear}
          isScroll={true}
          left={left}
          width={width}
          description={description}
        >
          {children}
        </FilterModal>
      )}
    </StyledFilterWrapper>
  );
};

export default FilterButtonWithModal;
