// hooks/useMapFilters.ts
import { useMemo } from "react";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { formatISO } from "date-fns";
import useSearchParams from "../../../../hooks/useSearchParams";
import { regions } from "../../../../constants/mapConstants";

export const useMainMapFilter = () => {
  const selectedRegion = useSearchParams().getSearchParam("region");
  const isSquareFilterShown = selectedRegion === regions.ua;

  const { subscribe } = useTypedSelector((state) => state.currentAccount);
  const {
    typeFilterArray,
    ccFilterArray,
    constructionType,
    stageBuilding,
    stageDocumentation,
    region,
    from_date,
    to_date,
    edrpou_code,
    np_id,
    keyword,
    squareFilter,
  } = useTypedSelector((state) => state.mapFilters);
  const { hash_ids } = useTypedSelector((state) => state.mapObjectsInfo);
  const toDateSearch = () =>
    to_date !== ""
      ? formatISO(new Date(to_date).setDate(new Date(to_date).getDate() + 1))
      : "";
  const fromDateSearch = () =>
    from_date !== ""
      ? formatISO(
          new Date(from_date).setDate(new Date(from_date).getDate() - 1)
        )
      : "";

  const minFilter = squareFilter[0] ?? undefined;
  const maxFilter = squareFilter[1] ?? undefined;
  const regionSearch = () =>
    region.length ||
    (region.length && subscribe?.permissions?.region?.includes(region[0]))
      ? region
      : subscribe?.permissions?.region?.length
      ? subscribe?.permissions?.region
      : [];
  const documentSearch = () =>
    stageDocumentation.length
      ? stageDocumentation
      : subscribe?.permissions?.stage_documentation?.length
      ? subscribe?.permissions?.stage_documentation
      : [];
  const buildSearch = () =>
    stageBuilding.length
      ? stageBuilding
      : subscribe?.permissions?.stage_building?.length
      ? subscribe?.permissions?.stage_building
      : [];
  const constructionSearch = () =>
    constructionType.length
      ? constructionType
      : subscribe?.permissions?.construction_type?.length
      ? subscribe?.permissions?.construction_type
      : [];
  const ccSearch = () =>
    ccFilterArray.length
      ? ccFilterArray
      : subscribe?.permissions?.consequence_class?.length
      ? subscribe?.permissions?.consequence_class
      : [];
  const nameFilters = [];
  for (const kword of keyword) {
    nameFilters.push(["in", kword, ["get", "name"]]);
  }
  const filterByCodes = ["in", ["get", "hash_id"], ["literal", hash_ids]];
  const filterToDate = ["<=", ["get", "date_added"], toDateSearch()];
  const filterFromDate = [">=", ["get", "date_added"], fromDateSearch()];
  const filterByRegion = ["in", ["get", "region"], ["literal", regionSearch()]];
  const filterFromSquareMin =
    isSquareFilterShown && minFilter
      ? [">=", ["get", "object_square"], minFilter]
      : [];
  const filterTillSquareMax =
    isSquareFilterShown && maxFilter
      ? ["<=", ["get", "object_square"], maxFilter]
      : [];
  const filterByStageDoc = [
    "in",
    ["get", "stage_documentation"],
    ["literal", documentSearch()],
  ];
  const filterByStageBuild = [
    "in",
    ["get", "stage_building"],
    ["literal", buildSearch()],
  ];
  const filterByConsType = [
    "in",
    ["get", "construction_type"],
    ["literal", constructionSearch()],
  ];
  const filterByCC = [
    "in",
    ["get", "consequence_class"],
    ["literal", ccSearch()],
  ];
  const filterByType = [
    "in",
    ["get", "aimap_classifier"],
    ["literal", typeFilterArray],
  ];
  const filters = [
    filterByType,
    filterByCC,
    filterByConsType,
    filterByStageBuild,
    filterByStageDoc,
    filterByRegion,
    filterFromDate,
    filterToDate,
    // TODO: add square filter
    filterFromSquareMin,
    filterTillSquareMax,
  ].filter(
    (item) =>
      item.length >= 3 &&
      ((Array.isArray(item[item.length - 1][1]) &&
        item[item.length - 1][1].length > 0) ||
        (!Array.isArray(item[item.length - 1][1]) &&
          item[item.length - 1] !== ""))
  );
  if (edrpou_code.length !== 0 || np_id.length !== 0) {
    filters.push(filterByCodes);
  }

  if (nameFilters.length) {
    const all_filterByName = ["any", ...nameFilters];
    filters.push(all_filterByName);
  }
  const mapFilter = useMemo(() => ["all", ...filters], [filters]);

  return mapFilter;
};
