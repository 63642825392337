import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styles from "./Login.module.scss";
import Logo from "../../../share/Logo/Logo";
import CloseEye from "../../../assets/svg/closeEye.svg";
import OpenEye from "../../../assets/svg/openEye.svg";
import { CustomInput, inputTypes } from "../../../share/Input/Input";
import { useActions } from "../../../hooks/useActions";
import { RouteNames } from "../../../routes";
import { Button, message } from "antd";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { CustomLoader } from "../../../share/Loader/Loader";
import { useTranslation } from "react-i18next";
import AuthWithSocialNetworks from "../AuthWithSocialNetworks/AuthWithSocialNetworks";
import styled from "@emotion/styled";
import { colors } from "../../../constants";

interface IUser {
  email: string;
  password: string;
}

const StyledOrText = styled.p`
  color: ${colors.darkD400};
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.002em;
  margin-bottom: 16px;
`;

const Login = () => {
  const { t: tLogin } = useTranslation("translation", {
    keyPrefix: "loginPage",
  });

  const schema = yup.object<Record<keyof IUser, yup.AnySchema>>().shape({
    email: yup
      .string()
      .email(tLogin("email.valid"))
      .required(tLogin("email.required")),
    password: yup
      .string()
      .required(tLogin("password.required"))
      .min(8, tLogin("password.min"))
      .max(20, tLogin("password.max")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IUser>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const [inputPass, setInputPass] = useState("password");
  const { login, setError, setIsAuthLoading } = useActions();
  const { error, isLoading } = useTypedSelector((state) => state.auth);

  const [messageApi, contextHolder] = message.useMessage();

  const handleChangePassword = () => {
    inputPass === "password" ? setInputPass("text") : setInputPass("password");
  };

  const onSubmit: SubmitHandler<IUser> = async (data: IUser) => {
    await login(data.email, data.password);
  };

  useEffect(() => {
    setTimeout(() => {
      if (isLoading) {
        setIsAuthLoading(false);
        window.location.reload();
      }
    }, 5000);
  }, []);

  const handleClean = () => {
    localStorage.clear();
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    // clear redux store if isLoading more then 5 sec
    setTimeout(() => {
      if (isLoading) {
        window.location.reload();
        setIsAuthLoading(false);
      }
    }, 5000);
  };

  useEffect(() => {
    if (error) {
      messageApi.error(tLogin(error, "Incorrectly entered data"), 6);
    }
    return () => {
      setError("");
    };
  }, [error]);

  return (
    <div className={styles.formMainWrap}>
      {contextHolder}
      <div className={styles.header}>
        <Logo />
        <div className={styles.regWrap}>
          <p className={styles.regTitle}>{tLogin("noAccount")}</p>
          <NavLink to={RouteNames.REGISTRATION} className={styles.regLink}>
            {tLogin("signUpBtn")}
          </NavLink>
        </div>
      </div>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className={styles.formWrapper}>
          <h1 className={styles.title}>{tLogin("title")}</h1>
          <div className={styles.cashWrapper}>
            <p className={styles.cashText}>{tLogin("warn.main")}</p>
            <div>
              <span className={styles.cashText}>{tLogin("warn.please")},</span>
              <button
                type="button"
                onClick={handleClean}
                className={styles.cashButton}
              >
                {tLogin("warn.clearCache")}.
              </button>
            </div>
          </div>
          <form onSubmitCapture={handleSubmit(onSubmit)}>
            <div className={styles.formContainer}>
              <label className={styles.label} htmlFor="email">
                {tLogin("email.label")}
                <CustomInput
                  name="email"
                  type="email"
                  inputClassName={styles.input}
                  register={register("email")}
                  isError={!!errors.email && !!errors.email?.message}
                  errorText={errors.email?.message}
                  onInput={({ target }) => setValue("email", target.value)}
                />
              </label>
              <label className={styles.label} htmlFor="password">
                {tLogin("password.label")}
                <CustomInput
                  name="password"
                  type={inputPass as inputTypes}
                  inputClassName={styles.input}
                  register={register("password")}
                  isError={!!errors.password && !!errors.password?.message}
                  errorText={errors.password?.message}
                  onInput={({ target }) => setValue("password", target.value)}
                >
                  <button
                    type="button"
                    className={styles.eyesButton}
                    onClick={handleChangePassword}
                  >
                    {inputPass === "password" ? (
                      <img src={CloseEye} alt="CloseEye" />
                    ) : (
                      <img src={OpenEye} alt="OpenEye" />
                    )}
                  </button>
                </CustomInput>
              </label>
            </div>
            <div className={styles.authorizationBtnWrap}>
              <Button className={styles.submitButton} htmlType="submit">
                {tLogin("submitBtn")}
              </Button>
            </div>
          </form>
          <NavLink
            to={RouteNames.PASSWORD_RECOVER}
            className={styles.forgotButton}
          >
            {tLogin("passwordRecovery")}
          </NavLink>
          <div className={styles.altLoginWrap}>
            <StyledOrText>{tLogin("loginWith")}</StyledOrText>
            <AuthWithSocialNetworks />
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
