import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  ReactNode,
} from "react";
import styles from "./Input.module.scss";
import Unchecked from "../../assets/svg/CheckboxUncheck.svg";
import Checked from "../../assets/svg/Checkbox.svg";
import RadioActive from "../../assets/svg/Radio_active.svg";
import RadioEmpty from "../../assets/svg/Radio_empty.svg";
import Close from "../../assets/svg/Close_small.svg";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";

export type inputTypes =
  | "password"
  | "text"
  | "number"
  | "email"
  | "button"
  | "search";

interface IInputProps {
  name: string;
  type: inputTypes;
  inputClassName?: string;
  register?: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  isError?: boolean;
  errorText?: string | unknown;
  defaultValue?: string;
  children?: ReactNode;
  placeholder?: string;
  inputProps?: any;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onInput?: React.ChangeEventHandler<HTMLInputElement>;
  autoComplete?: string;
}

export const CustomInput = ({
  name,
  type,
  inputClassName = "",
  register,
  isError,
  errorText,
  defaultValue,
  children,
  placeholder,
  onChange,
  inputProps,
  onInput,
  autoComplete = "on",
}: IInputProps) => {
  return (
    <>
      <div className={styles.inputWrapper}>
        <input
          name={name}
          type={type}
          className={inputClassName}
          {...register}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={onChange}
          {...inputProps}
          onInput={onInput}
          autoComplete={autoComplete}
        />
        {children}
      </div>
      {isError && <span className={styles.errorMsg}>{errorText}</span>}
    </>
  );
};

interface ISelectProps {
  name: string;
  defaultValue?: string | number;
  register?: DetailedHTMLProps<
    InputHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >;
  children?: ReactNode;
  isError?: boolean;
  errorText?: string;
  classSelectName?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  value?: any;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
  placeholder?: string;
  disabled?: boolean;
}

export const CustomSelect = ({
  name,
  register,
  isError,
  errorText,
  defaultValue,
  children,
  classSelectName,
  onChange,
  value,
  onClick,
  placeholder,
  disabled,
}: ISelectProps) => {
  const location = useLocation();

  return (
    <>
      <div className={styles.customSelect}>
        <select
          disabled={disabled}
          placeholder={placeholder}
          name={name}
          defaultValue={defaultValue}
          className={classSelectName}
          value={value}
          onChange={onChange}
          {...register}
        >
          {children}
        </select>
        {location.pathname === "/main" ? (
          <img
            className={value === "1" ? styles.displayNone : styles.closeIcon}
            src={Close}
            alt="Close"
            onClick={onClick}
          />
        ) : null}
        <span className={styles.customArrow} />
      </div>
      {isError && <span className={styles.errorMsg}>{errorText}</span>}
    </>
  );
};

const StyledLabel = styled.label<{
  single?: boolean;
}>`
  background-color: ${(props) => (props.single ? "#f0f6ff" : "initial")};
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 11px;
  ${(props) =>
    props.single &&
    `
      border-radius: 4px;
      border: 1px solid #d9e4ff;
      `};
`;

interface ICheckboxProps {
  checked: boolean;
  children?: ReactNode;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value?: any;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  name?: string;
  register?: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  id?: string;
  style?: any;
  single?: boolean;
}

export const CustomCheckbox = ({
  children,
  checked,
  onChange,
  value,
  onClick,
  style = styles.checkboxLabel,
  single = false,
}: ICheckboxProps) => {
  return (
    <StyledLabel className={style} single={single}>
      <input
        onClick={onClick}
        value={value}
        onChange={onChange}
        checked={checked}
        className={styles.checkboxInputHide}
        type="checkbox"
      />
      <span className={styles.checkboxInput}>
        {checked ? (
          <img src={Checked} alt="Checkbox" />
        ) : (
          <img src={Unchecked} alt="Checkbox" />
        )}
      </span>
      <span className={styles.checkboxChildren}>{children}</span>
    </StyledLabel>
  );
};

export const CustomRadio = ({
  checked,
  onChange,
  name,
  id,
}: ICheckboxProps) => {
  return (
    <div className={styles.radioLabel}>
      <input
        type="radio"
        id={id}
        name={name}
        value={id}
        className={styles.checkboxInputHide}
        checked={checked}
        onChange={onChange}
      />
      <span className={styles.checkboxInput}>
        {checked ? (
          <img src={RadioActive} alt="Radio" />
        ) : (
          <img src={RadioEmpty} alt="Radio" />
        )}
      </span>
    </div>
  );
};
