import React from "react";
import PulloutDrawer from "../../../components/_maps/main/PulloutDrawer/PulloutDrawer";
import useMobileScreen from "../../../hooks/useMobileScreen";
import { useTranslation } from "react-i18next";
import { useMapPageContext } from "../../MapPage/MapPageContext";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { CustomLoader } from "../../Loader/Loader";
import { StyledSearchResults } from "..";
import NoResultsFound from "../NoResultsFound";
import useSearchParams from "../../../hooks/useSearchParams";

interface Props {
  count: number;
  children: React.ReactNode;
  handleLoadFeed: () => void;
  onClearAllFilters: () => void;
}

const SearchResultsResponsive = ({
  handleLoadFeed,
  onClearAllFilters,
  children,
  count,
}: Props) => {
  const isMobile = useMobileScreen();
  const { searchResultsRef } = useMapPageContext();

  const selectedDataset = useSearchParams().getSearchParam("dataset");

  const { t: tDataset } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.downloadResults.datasets",
  });

  const { isLoading } = useTypedSelector((state) => state.mapInterface);

  const pulloutDrawerHeaderText =
    count === 0 ? "" : `${count} ${tDataset(selectedDataset)}`;

  if (!isMobile && isLoading) {
    return (
      <StyledSearchResults>
        <CustomLoader />
      </StyledSearchResults>
    );
  }
  if (!isMobile && count === 0) {
    return (
      <StyledSearchResults>
        <NoResultsFound onClearFilters={onClearAllFilters} />
      </StyledSearchResults>
    );
  }
  return (
    <StyledSearchResults>
      {isMobile ? (
        <PulloutDrawer
          constraintsContainerRef={searchResultsRef}
          header={pulloutDrawerHeaderText}
          onScrollToBottom={handleLoadFeed}
          noResults={count === 0}
        >
          {count ? (
            children
          ) : (
            <NoResultsFound onClearFilters={onClearAllFilters} />
          )}
          {isLoading && <CustomLoader />}
        </PulloutDrawer>
      ) : (
        <>{isLoading ? <CustomLoader /> : children}</>
      )}
    </StyledSearchResults>
  );
};

export default SearchResultsResponsive;
