import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { useTranslation } from "react-i18next";
import { TypeFilterItemsList } from "../../../../../share/filters";
import FilterButtonWithModal from "../../../../../share/filters/FilterButtonWithModal";
import TypeFilterItem from "../../../../../share/filters/TypeFilterItem";
import util from "../../../../../util";
import { FilterIdsEnum } from "../../../../../store/reducers/_maps/main/mapFilters/types";

const FilterTypes = () => {
  const filterId: FilterIdsEnum = "sikaTypeFilter";
  const { aimap_classifier_all } = useTypedSelector(
    (state) => state.sikaDesignerFilters
  );
  const { aimap_classifier } = useTypedSelector(
    (state) => state.mapSikaDesignerInfo.params
  );
  const {
    toggleSikaDesignerFilterTypeFilterObject,
    typeSikaDesignerFilterTypeClean,
    applyFilter,
  } = useActions();

  const handleOptionClick = (option: string) => {
    toggleSikaDesignerFilterTypeFilterObject(option);
    applyFilter();
  };

  const { t: tType } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.sika.objectTypeFilter",
  });

  const title = tType("title");
  const pinText =
    aimap_classifier_all.length > 0 && aimap_classifier_all.length;

  return (
    <FilterButtonWithModal
      filterId={filterId}
      pinText={pinText}
      title={title}
      onClear={typeSikaDesignerFilterTypeClean}
      left={0}
    >
      <TypeFilterItemsList>
        {aimap_classifier?.length > 0 &&
          [...aimap_classifier]
            .sort(util.toSortedAlphabeticCallback)
            .map((option: string) => (
              <TypeFilterItem
                option={option}
                onClick={() => handleOptionClick(option)}
                isActive={aimap_classifier_all.includes(option)}
              />
            ))}
      </TypeFilterItemsList>
    </FilterButtonWithModal>
  );
};

export default FilterTypes;
