import {
  HSTicketPriority,
  HubspotActionsEnum,
  IFeedbackFormValues,
  ReportTopic,
  ReportType,
} from "./types";
import { AppDispatch } from "../..";
import {
  IGoogleDriveResponse,
  feedbackService,
} from "../../api/FeedbackService";
import moment from "moment";
import filesUtil from "../../../util/filesUtil";

const hsTicketPipeline = "2607737";
const hsTicketPipelineStage = "9079711";
export const reportTopics: Record<string, ReportTopic> = {
  "Account & Profile": "Account & Profile",
  "User Management": "User Management",
  "Objects Map": "Objects Map",
  "Filtering and Search": "Filtering and Search",
  "JP Map": "JP Map",
  "NP Map": "NP Map",
  "Objects Information": "Objects Information",
};
export const reportTypes: Record<string, ReportType> = {
  feature: "feature",
  bug: "bug",
};

interface HubSpotTicketPayload {
  properties: {
    subject: string;
    content: string;
    attachments_link?: string;
    hs_pipeline: "2607737";
    hs_pipeline_stage: "9079711";
    hs_ticket_priority?: HSTicketPriority;
    report_topic: ReportTopic;
    report_type: ReportType;
  };
}

const createHubSpotTicketPayload = async (
  values: IFeedbackFormValues,
  attachments_link: string
): Promise<HubSpotTicketPayload> => {
  return {
    properties: {
      hs_pipeline: hsTicketPipeline, // The pipeline ID
      hs_pipeline_stage: hsTicketPipelineStage, // The pipeline stage ID
      hs_ticket_priority:
        values.reportType === reportTypes.bug ? "HIGH" : undefined,
      subject:
        values.reportType.toLocaleUpperCase() +
        " - " +
        moment().format("DD/MM/YYYY HH:mm:ss"),
      content: values.description,
      report_topic: values.reportTopic,
      report_type: values.reportType,
      attachments_link: attachments_link,
    },
  };
};

export const FeedbackApiActionCreators = {
  setIsLoading: (payload: boolean) => ({
    type: HubspotActionsEnum.SET_IS_POSTING_FEEDBACK_LOADING,
    payload,
  }),
  setError: (payload: string) => ({
    type: HubspotActionsEnum.SET_ERROR,
    payload,
  }),
  postFeedback:
    (values: IFeedbackFormValues, callback: () => void) =>
    async (dispatch: AppDispatch) => {
      let attachments_link = "";
      dispatch(FeedbackApiActionCreators.setIsLoading(true));
      try {
        if (values.attachments?.length > 0) {
          const files = filesUtil.convertUploadFilesToFiles(values.attachments);
          const response = await feedbackService.postAttachmentsToGoogleDrive(
            files
          );
          attachments_link = (response.data as IGoogleDriveResponse).link;
        }
        const payload: HubSpotTicketPayload = await createHubSpotTicketPayload(
          values,
          attachments_link
        );
        feedbackService.postFeedback(payload);
        callback();
      } catch (e: any) {
        dispatch(FeedbackApiActionCreators.setError(e));
      }
      dispatch(FeedbackApiActionCreators.setIsLoading(false));
    },
};
