import { httpClient, HttpClient } from "./HTTPService";
import { IReqObjects } from "../../models/MapObject";
import { store } from "../index";
import { IMapObjectsInfo } from "../../models/MapObjectsInfo";
import { ICurrentObject } from "../../models/CurrentObject";
import { ISearchRequest, ISearchResponse } from "../../models/Search";
import qs from "qs";
import { AvailableCC } from "../../constants/constants";

const token = store.getState().auth.token.access;

class MapService {
  constructor(private httpClient: HttpClient) {}

  public async fetchMapObjects(data: IReqObjects, countryCode: AvailableCC) {
    return httpClient.get<IMapObjectsInfo>(
      `${countryCode}/objects/${data.account_id}/`,
      {
        params: data,
        paramsSerializer: (params) => qs.stringify(params, { indices: false }),
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  }

  public async fetchMapObject(
    hash_id: number | string,
    account_id: number,
    countryCode: AvailableCC
  ) {
    return httpClient.get<ICurrentObject>(
      `${countryCode}/objects/${account_id}/${hash_id ? hash_id + "/" : ""}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }

  public async fetchSuggestions(
    data: ISearchRequest,
    countryCode: AvailableCC
  ) {
    const { query, account_id } = data;
    return httpClient.post<ISearchResponse[]>(
      `${countryCode}/objects/${account_id}/suggest/`,
      { query },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }
}

export const mapService = new MapService(httpClient);
