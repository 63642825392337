import React from "react";
import useMobileScreen from "../../../hooks/useMobileScreen";
import { MobileModal, MobileModalBody } from "../../MobileModal/MobileModal";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { breakpoints, zIndexes } from "../../../constants";
import FilterDescription from "./FilterDescription";

const StyledDesktopFilterModal = styled.div<{ left?: number; width?: number }>`
  position: absolute;
  left: ${(props) => props.left}px;
  top: 58px;
  width: ${(props) => props.width}px;
  background: var(--primary-white-color);
  border: 1px solid var(--sixth-black-color);
  box-shadow: 0 8px 12px rgba(9, 30, 66, 0.15), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  z-index: ${zIndexes.filters + 1};
  background: white;
  @media screen and (max-width: ${breakpoints.medium}px) {
    top: 42px;
  }
`;

const StyledActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80px;
  padding: 16px 24px;
  border-top: 1px solid #e6e6e6;
`;

const StyledClearButton = styled.button`
  width: 170px;
  height: 48px;
  background-color: var(--primary-white-color);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
  border: none;
  margin-left: auto;
`;

const StyledBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndexes.filterBackdrop};
  background-color: transparent;
`;

const StyledFilterBody = styled.div<{
  isScroll?: boolean;
}>`
  padding: 24px;
  overflow-y: ${(props) => (props.isScroll ? "auto" : "initial")};
  height: fit-content;
  max-height: 578px;
  @media screen and (max-height: ${breakpoints.filter}px) {
    max-height: 300px;
  }
  @media screen and (max-width: ${breakpoints.small}px) {
    height: 100%;
    padding: 0;
    overflow: visible;
  }
`;

type Props = {
  children: React.ReactNode;
  title: string;
  onClose: () => void;
  onClear: () => void;
  description?: string;
  left?: number;
  width?: number;
  isScroll?: boolean;
};

const FilterModal = ({
  title,
  children,
  onClose,
  onClear,
  description: info = "",
  left = 0,
  width = 480,
  isScroll = false,
}: Props) => {
  const isMobile = useMobileScreen();
  const { t } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.aimap",
  });

  if (isMobile) {
    return (
      <MobileModal title={title} onClose={onClose} onClear={onClear}>
        {!!info && <FilterDescription text={info} />}
        <MobileModalBody>{children}</MobileModalBody>
      </MobileModal>
    );
  }
  return (
    <>
      <StyledBackDrop onClick={onClose} />
      <StyledDesktopFilterModal
        left={left}
        width={width}
        onClick={(event) => event.stopPropagation()}
      >
        {!!info && <FilterDescription text={info} />}
        <StyledFilterBody isScroll={isScroll}>{children}</StyledFilterBody>
        <StyledActionsWrapper>
          <StyledClearButton onClick={onClear}>{t("clear")}</StyledClearButton>
        </StyledActionsWrapper>
      </StyledDesktopFilterModal>
    </>
  );
};

export default FilterModal;
