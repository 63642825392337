import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import { FC, ReactNode } from "react";
import { animation, breakpoints } from "../../../constants";
import useMobileScreen from "../../../hooks/useMobileScreen";

const StyledObjectDetails = styled(motion.div)`
  width: 100%;
  background-color: var(--primary-white-color);
  position: relative;
  top: 0;
  z-index: 1010;
  overflow: auto;

  @media screen and (max-width: ${breakpoints.small + "px"}) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
`;

interface IAnimatedDetailsContainer {
  isShown: boolean;
  children: ReactNode | ReactNode[];
}

const AnimatedDetailsContainer: FC<IAnimatedDetailsContainer> = ({
  isShown,
  children,
}) => {
  const desktopVariants = {
    initial: undefined,
    animate: undefined,
    exit: undefined,
  };

  const mobileVariants = {
    initial: { x: "-50%", opacity: 0 },
    animate: { x: "0%", opacity: 1 },
    exit: { x: "-100%" },
  };

  const isMobile = useMobileScreen();

  return (
    <AnimatePresence>
      {isShown && (
        <StyledObjectDetails
          initial={isMobile ? mobileVariants.initial : desktopVariants.initial}
          animate={isMobile ? mobileVariants.animate : desktopVariants.animate}
          exit={isMobile ? mobileVariants.exit : desktopVariants.exit}
          transition={{ duration: animation.duration }}
        >
          {children}
        </StyledObjectDetails>
      )}
    </AnimatePresence>
  );
};

export default AnimatedDetailsContainer;
