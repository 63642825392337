import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { useTranslation } from "react-i18next";
import { FilterIdsEnum } from "../../../../../store/reducers/_maps/main/mapFilters/types";
import FilterButtonWithModal from "../../../../../share/filters/FilterButtonWithModal";
import FilterTypesBody from "./FilterTypesBody";

interface TypeFilterProps {
  filterId?: FilterIdsEnum;
}

const FilterTypes: React.FC<TypeFilterProps> = () => {
  const filterId: FilterIdsEnum = "type";

  const { typeFilterArray: includedFilterOptions } = useTypedSelector(
    (state) => state.mapFilters
  );

  const { typeFilterClean } = useActions();

  const { t } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.aimap.objectTypeFilter",
  });
  const title = t("title");
  const pinText =
    includedFilterOptions.length > 0 && includedFilterOptions.length;

  return (
    <FilterButtonWithModal
      title={title}
      filterId={filterId}
      onClear={typeFilterClean}
      pinText={pinText}
    >
      <FilterTypesBody />
    </FilterButtonWithModal>
  );
};

export default FilterTypes;
