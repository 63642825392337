import {
  hash,
  STAN,
  KVED,
  ISelectedDocList,
  region,
  registration_date_1,
} from "../../../../../models/PersonObejct";

import { ISearchResponse } from "../../../../../models/Search";

export interface IPersonsFilters {
  isSuggestionsLoading: boolean;
  kved_is_main: boolean;
  stanFilterArray: STAN[];
  selectedDocList: ISelectedDocList[];
  date_added__lte: registration_date_1;
  date_added__gte: registration_date_1;
  suggestions: ISearchResponse[];
  activeSuggestions: ISearchResponse[];
  hash: hash[];
  region: region[];
  kved: KVED[];
}
export enum MapFilterActionsEnum {
  SET_SUGGESTION_LOADING = "SET_SUGGESTION_LOADING",
  // SET_PERSON_STAN_FILTER_ACTIVE = "SET_PERSON_STAN_FILTER_ACTIVE",
  SET_PERSON_KVED_IS_MAIN = "SET_PERSON_KVED_IS_MAIN",
  // SET_PERSON_DATE_FILTER_ACTIVE = "SET_PERSON_DATE_FILTER_ACTIVE",
  SET_PERSON_DATE_ADDED_LTE = "SET_PERSON_DATE_ADDED_LTE",
  SET_PERSON_DATE_ADDED_GTE = "SET_DATE_PERSON_ADDED_GTE",
  TOGGLE_PERSON_STAN_FILTER = "TOGGLE_PERSON_STAN_FILTER",
  CLEAN_PERSON_STAN_FILTER = "CLEAN_PERSON_STAN_FILTER",
  SET_DOC_LIST = "SET_DOC_LIST",
  SET_PERSON_SUGGESTION = "SET_PERSON_SUGGESTION",
  SET_PERSON_ACTIVE_SUGGESTION = "SET_PERSON_ACTIVE_SUGGESTION",
  DELETE_PERSON_ACTIVE_SUGGESTION = "DELETE_PERSON_ACTIVE_SUGGESTION",
  CLEAN_PERSON_SUGGESTION = "CLEAN_PERSON_SUGGESTION",
  SET_PERSON_REGION = "SET_PERSON_REGION",
  CLEAN_PERSON_REGION = "CLEAN_PERSON_REGION",
  DELETE_PERSON_REGION = "DELETE_PERSON_REGION",
  SET_PERSON_HASH_CODE = "SET_PERSON_HASH_CODE",
  CLEAN_PERSON_HASH_CODE = "CLEAN_PERSON_HASH_CODE",
  DELETE_PERSON_HASH_CODE = "DELETE_PERSON_HASH_CODE",
  SET_PERSON_KVED = "SET_PERSON_KVED",
  CLEAN_PERSON_KVED = "CLEAN_PERSON_KVED",
  DELETE_PERSON_KVED = "DELETE_PERSON_KVED",
}

// SetSuggestionLoadingAction
export interface SetIsSuggestionsLoadingAction {
  type: MapFilterActionsEnum.SET_SUGGESTION_LOADING;
  payload: boolean;
}

export interface SetPersonKvedIsMainAction {
  type: MapFilterActionsEnum.SET_PERSON_KVED_IS_MAIN;
  payload: boolean;
}

export interface SetPersonDateAddedLTE {
  type: MapFilterActionsEnum.SET_PERSON_DATE_ADDED_LTE;
  payload: string;
}

export interface SetPersonDateAddedGTE {
  type: MapFilterActionsEnum.SET_PERSON_DATE_ADDED_GTE;
  payload: string;
}

export interface SetPersonStanFilter {
  type: MapFilterActionsEnum.TOGGLE_PERSON_STAN_FILTER;
  payload: string;
}

export interface SetPersonCleanStanFilter {
  type: MapFilterActionsEnum.CLEAN_PERSON_STAN_FILTER;
  payload: [];
}

export interface SetSelectedDocList {
  type: MapFilterActionsEnum.SET_DOC_LIST;
  payload: ISelectedDocList[];
}

export interface SetPersonSuggestion {
  type: MapFilterActionsEnum.SET_PERSON_SUGGESTION;
  payload: ISearchResponse[];
}

export interface SetPersonActiveSuggestion {
  type: MapFilterActionsEnum.SET_PERSON_ACTIVE_SUGGESTION;
  payload: ISearchResponse;
}

export interface DeletePersonActiveSuggestion {
  type: MapFilterActionsEnum.DELETE_PERSON_ACTIVE_SUGGESTION;
  payload: ISearchResponse;
}

export interface SetPersonCleanSuggestion {
  type: MapFilterActionsEnum.CLEAN_PERSON_SUGGESTION;
  payload: [];
}

export interface SetPersonRegion {
  type: MapFilterActionsEnum.SET_PERSON_REGION;
  payload: string;
}

export interface SetPersonCleanRegion {
  type: MapFilterActionsEnum.CLEAN_PERSON_REGION;
  payload: [];
}

export interface DeletePersonRegion {
  type: MapFilterActionsEnum.DELETE_PERSON_REGION;
  payload: string;
}

export interface SetPersonHashCode {
  type: MapFilterActionsEnum.SET_PERSON_HASH_CODE;
  payload: string;
}

export interface SetCleanPersonHashCode {
  type: MapFilterActionsEnum.CLEAN_PERSON_HASH_CODE;
  payload: [];
}

export interface DeletePersonHashCode {
  type: MapFilterActionsEnum.DELETE_PERSON_HASH_CODE;
  payload: string;
}

export interface SetPersonKVED {
  type: MapFilterActionsEnum.SET_PERSON_KVED;
  payload: string;
}

export interface SetCleanPersonKVED {
  type: MapFilterActionsEnum.CLEAN_PERSON_KVED;
  payload: [];
}

export interface DeletePersonKVED {
  type: MapFilterActionsEnum.DELETE_PERSON_KVED;
  payload: string;
}

export type MapFiltersAction =
  | SetIsSuggestionsLoadingAction
  | SetPersonStanFilter
  | SetPersonCleanStanFilter
  | SetPersonDateAddedGTE
  | SetPersonDateAddedLTE
  | SetSelectedDocList
  | SetPersonSuggestion
  | SetPersonActiveSuggestion
  | DeletePersonActiveSuggestion
  | SetPersonRegion
  | DeletePersonRegion
  | SetPersonHashCode
  | DeletePersonHashCode
  | SetPersonCleanSuggestion
  | SetPersonCleanRegion
  | SetCleanPersonHashCode
  | SetPersonKVED
  | SetCleanPersonKVED
  | DeletePersonKVED
  | SetPersonKvedIsMainAction;
