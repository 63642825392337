import { AppDispatch, RootState, store } from "../../../..";
import { ISelectedDocList } from "../../../../../models/MapObject";
import { ISearchRequest, ISearchResponse } from "../../../../../models/Search";
import { MapInterfaceActionCreator } from "../../mapInterface/action.creators";
import { MapFilterActionCreators } from "../../main/mapFilters/action-creators";
import {
  DeleteUSAIDActiveSuggestion,
  MapFilterActionsEnum,
  SetUSAIDActiveSuggestion,
  SetCleanUSAIDEdrCode,
  SetUSAIDCleanRegion,
  SetUSAIDCleanSuggestion,
  SetUSAIDEdrCode,
  SetUSAIDRegion,
  SetSelectedDocList,
  DeleteUSAIDRegion,
  SetUSAIDSuggestion,
  SetUSAIDKVED,
  SetCleanUSAIDKVED,
  DeleteUSAIDKVED,
  DeleteUSAIDEdrCode,
  SetUSAIDEconomicSector,
  SetUSAIDEntityType,
  SetUSAIDCategory,
  SetUSAIDActivityStatus,
  SetCleanUSAIDEconomicSector,
  SetCleanUSAIDEntityType,
  SetCleanUSAIDCategory,
  SetCleanUSAIDActivityStatus,
  SetIsSuggestionsLoadingAction,
} from "./types";
import { v4 as uuidv4 } from "uuid";
import { usaidService } from "../../../../api/USAIDService";
import { suggestionTypes } from "../../../../../constants/constants";

export const USAIDFilterActionCreators = {
  setCleanAllUSAIDFilters: () => (dispatch: AppDispatch) => {
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
    dispatch(USAIDFilterActionCreators.cleanUSAIDEconomicSectorFilter([]));
    dispatch(USAIDFilterActionCreators.cleanUSAIDEntityTypeFilter([]));
    dispatch(USAIDFilterActionCreators.cleanUSAIDCategoryFilter([]));
    dispatch(USAIDFilterActionCreators.cleanUSAIDActivityStatusFilter([]));
    dispatch(USAIDFilterActionCreators.cleanUSAIDSuggestions([]));
    dispatch(USAIDFilterActionCreators.cleanUSAIDRegion([]));
    dispatch(USAIDFilterActionCreators.cleanUSAIDKVED([]));
    dispatch(MapFilterActionCreators.cleanKeyword([]));
    dispatch(USAIDFilterActionCreators.cleanUSAIDEdrCode([]));
    dispatch(
      USAIDFilterActionCreators.setSelectedDocList([
        { id: uuidv4(), value: "1" },
      ])
    );
  },

  setIsSuggestionsLoading: (
    payload: boolean
  ): SetIsSuggestionsLoadingAction => ({
    type: MapFilterActionsEnum.SET_SUGGESTION_LOADING,
    payload,
  }),

  toggleUSAIDEconomicSectorFilter: (
    payload: string
  ): SetUSAIDEconomicSector => ({
    type: MapFilterActionsEnum.SET_USAID_ECONOMIC_SECTOR,
    payload: payload,
  }),
  cleanUSAIDEconomicSectorFilter: (
    payload: []
  ): SetCleanUSAIDEconomicSector => ({
    type: MapFilterActionsEnum.CLEAN_USAID_ECONOMIC_SECTOR,
    payload: payload,
  }),
  USAIDEconomicSectorFilterClean: () => (dispatch: AppDispatch) => {
    dispatch(USAIDFilterActionCreators.cleanUSAIDEconomicSectorFilter([]));
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },
  toggleUSAIDEntityTypeFilter: (payload: string): SetUSAIDEntityType => ({
    type: MapFilterActionsEnum.SET_USAID_ENTITY_TYPE,
    payload: payload,
  }),
  cleanUSAIDEntityTypeFilter: (payload: []): SetCleanUSAIDEntityType => ({
    type: MapFilterActionsEnum.CLEAN_USAID_ENTITY_TYPE,
    payload: payload,
  }),
  USAIDEntityTypeFilterClean: () => (dispatch: AppDispatch) => {
    dispatch(USAIDFilterActionCreators.cleanUSAIDEntityTypeFilter([]));
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },
  toggleUSAIDCategoryFilter: (payload: string): SetUSAIDCategory => ({
    type: MapFilterActionsEnum.SET_USAID_CATEGORY,
    payload: payload,
  }),
  cleanUSAIDCategoryFilter: (payload: []): SetCleanUSAIDCategory => ({
    type: MapFilterActionsEnum.CLEAN_USAID_CATEGORY,
    payload: payload,
  }),
  USAIDCategoryFilterClean: () => (dispatch: AppDispatch) => {
    dispatch(USAIDFilterActionCreators.cleanUSAIDCategoryFilter([]));
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },
  toggleUSAIDActivityStatusFilter: (
    payload: string
  ): SetUSAIDActivityStatus => ({
    type: MapFilterActionsEnum.SET_USAID_ACTIVITY_STATUS,
    payload: payload,
  }),
  cleanUSAIDActivityStatusFilter: (
    payload: []
  ): SetCleanUSAIDActivityStatus => ({
    type: MapFilterActionsEnum.CLEAN_USAID_ACTIVITY_STATUS,
    payload: payload,
  }),
  USAIDActivityStatusFilterClean: () => (dispatch: AppDispatch) => {
    dispatch(USAIDFilterActionCreators.cleanUSAIDActivityStatusFilter([]));
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },
  setSelectedDocList: (payload: ISelectedDocList[]): SetSelectedDocList => ({
    type: MapFilterActionsEnum.SET_DOC_LIST,
    payload,
  }),
  setUSAIDSuggestions: (payload: ISearchResponse[]): SetUSAIDSuggestion => ({
    type: MapFilterActionsEnum.SET_USAID_SUGGESTION,
    payload: payload,
  }),
  getUSAIDSuggestions:
    (data: ISearchRequest) => async (dispatch: AppDispatch) => {
      try {
        dispatch(MapFilterActionCreators.setIsSuggestionsLoading(true));
        const response = await usaidService.fetchSuggestions(data);
        if (response) {
          const suggestions = response.data;
          const activeSuggestions = (store.getState() as RootState).mapFilters
            .activeSuggestions;
          const suggestionsWithoutActiveSuggestions = suggestions.filter(
            (suggestion: ISearchResponse) =>
              !activeSuggestions.some(
                (activeSuggestion) => activeSuggestion.code === suggestion.code
              )
          );
          dispatch(
            USAIDFilterActionCreators.setUSAIDSuggestions(
              suggestionsWithoutActiveSuggestions
            )
          );
        }
        dispatch(MapFilterActionCreators.setIsSuggestionsLoading(false));
      } catch (e: string | any) {
        dispatch(
          MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
        );
      }
    },
  setUSAIDActiveSuggestions: (
    payload: ISearchResponse
  ): SetUSAIDActiveSuggestion => ({
    type: MapFilterActionsEnum.SET_USAID_ACTIVE_SUGGESTION,
    payload,
  }),
  cleanUSAIDSuggestions: (payload: []): SetUSAIDCleanSuggestion => ({
    type: MapFilterActionsEnum.CLEAN_USAID_SUGGESTION,
    payload,
  }),
  deleteUSAIDActiveSuggestion: (
    payload: ISearchResponse
  ): DeleteUSAIDActiveSuggestion => ({
    type: MapFilterActionsEnum.DELETE_USAID_ACTIVE_SUGGESTION,
    payload,
  }),
  setUSAIDRegion: (payload: string): SetUSAIDRegion => ({
    type: MapFilterActionsEnum.SET_USAID_REGION,
    payload,
  }),
  cleanUSAIDRegion: (payload: []): SetUSAIDCleanRegion => ({
    type: MapFilterActionsEnum.CLEAN_USAID_REGION,
    payload,
  }),
  deleteUSAIDRegion: (payload: string): DeleteUSAIDRegion => ({
    type: MapFilterActionsEnum.DELETE_USAID_REGION,
    payload,
  }),
  setUSAIDEdrCode: (payload: string): SetUSAIDEdrCode => ({
    type: MapFilterActionsEnum.SET_USAID_EDR_CODE,
    payload,
  }),
  cleanUSAIDEdrCode: (payload: []): SetCleanUSAIDEdrCode => ({
    type: MapFilterActionsEnum.CLEAN_USAID_EDR_CODE,
    payload,
  }),
  deleteUSAIDEdrCode: (payload: string): DeleteUSAIDEdrCode => ({
    type: MapFilterActionsEnum.DELETE_USAID_EDR_CODE,
    payload,
  }),
  setUSAIDKVED: (payload: string): SetUSAIDKVED => ({
    type: MapFilterActionsEnum.SET_USAID_KVED,
    payload,
  }),
  cleanUSAIDKVED: (payload: []): SetCleanUSAIDKVED => ({
    type: MapFilterActionsEnum.CLEAN_USAID_KVED,
    payload,
  }),
  deleteUSAIDKVED: (payload: string): DeleteUSAIDKVED => ({
    type: MapFilterActionsEnum.DELETE_USAID_KVED,
    payload,
  }),
  addUSaidActiveSuggestion:
    (suggestion: ISearchResponse) => (dispatch: AppDispatch) => {
      const activeSuggestions = (store.getState() as RootState).usaidMapFilters
        .activeSuggestions;

      if (activeSuggestions.includes(suggestion)) {
        return;
      }
      if (suggestion.type === suggestionTypes.region) {
        dispatch(USAIDFilterActionCreators.setUSAIDRegion(suggestion.code));
      }
      if (suggestion.type === suggestionTypes.kved) {
        dispatch(USAIDFilterActionCreators.setUSAIDKVED(suggestion.code));
      }
      if (suggestion.type === suggestionTypes.name) {
        dispatch(USAIDFilterActionCreators.setUSAIDEdrCode(suggestion.code));
      }
      dispatch(USAIDFilterActionCreators.setUSAIDActiveSuggestions(suggestion));
    },
};
