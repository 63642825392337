import React, { useEffect } from "react";
import { useActions } from "../../../hooks/useActions";
import { IAccount } from "../../../models/Accounts";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { RouteNames } from "../../../routes";
import { useNavigate } from "react-router-dom";
import styles from "./Accounts.module.scss";
import FilterArrow from "../../../assets/svg/FilterArrow.svg";
import Logo from "../../../assets/svg/LogoOrange.svg";
import ProfileAvatar from "../../../assets/images/Profile image.jpg";
import { CustomLoader } from "../../../share/Loader/Loader";
import { useTranslation } from "react-i18next";

const Accounts = () => {
  const navigate = useNavigate();
  const { getAccounts, logout, getCurrentAccount } = useActions();
  const { accounts } = useTypedSelector((state) => state);
  const { isLoading } = useTypedSelector((state) => state.auth);
  const { id } = useTypedSelector((state) => state.currentAccount);

  useEffect(() => {
    getAccounts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (accounts?.length === 1) {
      if (!id) {
        const result = getCurrentAccount(accounts[0].id);
        if (!!result) {
          navigate(RouteNames.AIMAP);
        }
      }
    }
    // eslint-disable-next-line
  }, [accounts]);

  const handleClick = (currentId: number) => {
    const result = getCurrentAccount(currentId);
    if (!!result) {
      navigate(RouteNames.AIMAP);
    }
  };

  const { t: tPage } = useTranslation("translation", {
    keyPrefix: "accountsPage",
  });

  const { t: tTable } = useTranslation("translation", {
    keyPrefix: "accountsPage.tableHeader",
  });

  return (
    <div className={styles.mainWrapper}>
      <h1 className={styles.title}>{tPage("title")}</h1>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <>
          <table className={styles.table}>
            <thead className={styles.tableHead}>
              <tr className={styles.tableHeadRow}>
                <th>
                  <img src={FilterArrow} alt="Filter arrow" />
                  {tTable("accountName")}
                </th>
                <th>{tTable("subscription")}</th>
                <th>{tTable("admin")}</th>
                <th>{tTable("participantCount")}</th>
              </tr>
            </thead>
            <tbody className={styles.tableBody}>
              {accounts?.map((account: IAccount) => {
                const userCount = account.users.length;
                return (
                  <tr
                    key={account.id}
                    onClick={() => handleClick(account.id)}
                    className={styles.tableBodyRow}
                  >
                    <th>
                      <div className={styles.accCellWrap}>
                        <div className={styles.imgContainer}>
                          <img src={Logo} alt="" />
                        </div>
                        <div className={styles.accDataWrap}>
                          <p className={styles.accName}>{account.name}</p>
                          <p className={styles.membersCount}>
                            {userCount} member{userCount > 1 && "s"}
                          </p>
                        </div>
                      </div>
                    </th>
                    <th>{account.subscribe.card_name}</th>
                    <th>
                      <div className={styles.profileImgContainer}>
                        <img src={ProfileAvatar} alt="" />
                      </div>
                      {
                        account.users.map((user) =>
                          user.role.name === "Admin"
                            ? user.user.full_name
                            : null
                        )[0]
                      }
                    </th>
                    <th>{userCount}</th>
                    {/*<th>*/}
                    {/*	<button type="button">*/}
                    {/*		<img src="" alt=""/>*/}
                    {/*	</button>*/}
                    {/*</th>*/}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className={styles.buttonsWrapper}>
            <button
              type="button"
              onClick={logout}
              className={styles.logoutButton}
            >
              {tPage("logoutBtn")}
            </button>
          </div>
        </>
      )}
      {/*<button type="button" className={styles.createAccountBtn}>*/}
      {/*	Створити аккаунт*/}
      {/*	<img src={WhitePlus} alt="plus"/>*/}
      {/*</button>*/}
    </div>
  );
};

export default Accounts;
