import { IDatasetType, IRegion } from "../models/map.interface";

export const mapboxAccessToken = process.env.REACT_APP_MAP_ACCESS_TOKEN;

export const regions: { [key in IRegion]: IRegion } = {
  ua: "ua",
  en: "en",
  pl: "pl",
  no: "no",
  fr: "fr",
};

type ICottages =
  | "Будинки житлові котеджного типу"
  | "Bâtiments du complexe agro-industriel"
  | "Domy mieszkalne typu chata"
  | "Bolighus av hyttetype"
  | "Cottage-type dwelling houses";
export type IAimapClassifier = ICottages;
export const aimapClassifierMap: Record<string, string[]> = {
  cottages: [
    "Будинки житлові котеджного типу",
    "Bâtiments du complexe agro-industriel",
    "Domy mieszkalne typu chata",
    "Bolighus av hyttetype",
    "Cottage-type dwelling houses",
    "Bâtiments résidentiels",
  ],
};
export const datasets: { [key in IDatasetType]: IDatasetType } = {
  objects: "objects",
  company: "company",
  person: "person",
  usaid: "usaid",
  sika: "sika",
};

export const defaultRegion: IRegion = regions.ua;
export const defaultDataset: IDatasetType = datasets.objects;

export const availableDatasetsRegionMap: Record<IRegion, IDatasetType[]> = {
  ua: [
    datasets.objects,
    datasets.company,
    datasets.person,
    datasets.usaid,
    datasets.sika,
  ],
  pl: [datasets.objects],
  fr: [datasets.objects],
  no: [datasets.objects],
  en: [datasets.objects],
};

export const defaultViewport = {
  latitude: 50.4501,
  longitude: 30.5234,
  zoom: 5,
};

export const clusterConfig = {
  fill: "#F46857",
  stroke: "#fff",
  sourceId: "objects-clusters-source-id",
  clusterMaxZoom: 9,
  clusterRadius: 50,
  zoomOffset: 1,
};

export const layerProps = {
  id: "point",
  type: "symbol",
  layout: {
    "icon-image": "pin-icon",
    "icon-size": 1,
  },
};

export const layerMobileProps = {
  id: "point",
  type: "symbol",
  layout: {
    "icon-image": "pin-icon-mobile",
    "icon-size": 1,
  },
};

export const limit = 20;
const mapConstants = {
  regions,
  datasets,
  defaultRegion,
  defaultDataset,
  availableDatasetsRegionMap,
  defaultViewport,
  clusterConfig,
  layerProps,
  layerMobileProps,
  limit,
  mapboxAccessToken,
};
export default mapConstants;
