import React, { useRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";
import { useActions } from "../../hooks/useActions";
import { formatISO } from "date-fns";
import useDateLocale from "../../hooks/useDateLocale";
import { useTranslation } from "react-i18next";
import { icons } from "../../constants";
import { useTypedSelector } from "../../hooks/useTypedSelector";
interface IProps {
  startDate: Date | null;
  setStartDate: (date: Date | null) => void;
  endDate: Date | null;
  setEndDate: (date: Date | null) => void;
  modalBodyRef?: React.MutableRefObject<HTMLDivElement | undefined>;
}

const CalendarButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <img
      className="CalendarIcon"
      src={icons.Calendar}
      alt="CalendarIcon"
      onClick={onClick}
    />
  );
};
const CustomDatePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  modalBodyRef,
}: IProps) => {
  const { lang, selectedLocale } = useDateLocale();

  registerLocale(lang, selectedLocale);
  const { setFromDate, setToDate } = useActions();

  const scrollToMyRef = () =>
    modalBodyRef?.current?.scrollTo({
      top: 465,
      behavior: "smooth",
    });

  const handleStartChange = (newDate: Date | null) => {
    setStartDate(newDate);
    if (newDate) {
      setFromDate(formatISO(newDate, { representation: "date" }));
      if (endDate && newDate > endDate) {
        setEndDate(newDate);
        setToDate(formatISO(newDate, { representation: "date" }));
      }
    }
    if (newDate === null) {
      setFromDate("");
    }
  };

  const handleEndChange = (newDate: Date | null) => {
    setEndDate(newDate);
    if (newDate) {
      setToDate(formatISO(newDate, { representation: "date" }));
      if (startDate && newDate < startDate) {
        setStartDate(newDate);
        setFromDate(formatISO(newDate, { representation: "date" }));
      }
    }
    if (newDate === null) {
      setToDate("");
    }
  };

  const { t: tPeriod } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.aimap.periodFilter",
  });

  const fromDateRef = useRef<any>(null);
  const toDateRef = useRef<any>(null);
  const openDatePicker = (datepickerRef: any) => {
    datepickerRef.current.setOpen(true);
  };

  const oldestDate = useTypedSelector(
    (state) => state.mapObjectsInfo.mapObjects.oldest_date || "2000-11-03"
  );

  const minDate = new Date(oldestDate);
  const maxDate = new Date();
  const minDateString = formatISO(minDate, {
    representation: "date",
  }).replaceAll("-", "/");
  const maxDateString = formatISO(maxDate, {
    representation: "date",
  }).replaceAll("-", "/");

  return (
    <div className="calendarContainer">
      <div className="customDatePickerWrapper">
        <p className="calendarLabel">{tPeriod("from")}</p>
        <DatePicker
          selected={startDate}
          onChange={(date: Date | null) => handleStartChange(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          shouldCloseOnSelect={true}
          showPopperArrow={false}
          closeOnScroll
          locale={selectedLocale}
          dateFormat="dd/MM/yyyy"
          placeholderText={minDateString}
          isClearable={!!startDate}
          focusSelectedMonth={true}
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          onCalendarOpen={scrollToMyRef}
          ref={fromDateRef}
          minDate={minDate}
          maxDate={maxDate}
        ></DatePicker>
        <CalendarButton onClick={() => openDatePicker(fromDateRef)} />
      </div>
      <div className="customDatePickerWrapper">
        <p className="calendarLabel">{tPeriod("to")}</p>
        <DatePicker
          selected={endDate}
          onChange={(date: Date | null) => handleEndChange(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
          showPopperArrow={false}
          closeOnScroll
          shouldCloseOnSelect
          locale={selectedLocale}
          dateFormat="dd/MM/yyyy"
          placeholderText={maxDateString}
          isClearable={!!endDate}
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          onCalendarOpen={scrollToMyRef}
          ref={toDateRef}
        />

        <CalendarButton onClick={() => openDatePicker(toDateRef)} />
      </div>
    </div>
  );
};

export default CustomDatePicker;
