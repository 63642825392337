const searchResultsOffset = 20;
const searchTextMinLength = 3;
const defaultActiveKeywordCount = 4;

const filtersConstants = {
  searchResultsOffset,
  searchTextMinLength,
  defaultActiveKeywordCount,
};

export default filtersConstants;
