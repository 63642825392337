import { USAIDObject } from "../../../../models/USAIDObject";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import JpBlue from "../../../../assets/svg/JpBlue.svg";
import NpGreen from "../../../../assets/svg/NpGreen.svg";
import SearchResultsResponsive from "../../../../share/searchResults/SearchResultsResponsive";
import GeneralSearchResultItem from "../../../../share/GeneralSearchResultItem/GeneralSearchResultItem";

const USAIDMapSearchResults = () => {
  const {
    setCleanAllUSAIDFilters,
    getDefaultUSAIDResult,
    refreshFeedForUSAIDSearchResults,
  } = useActions();
  const { usaid_dataset, count } = useTypedSelector(
    (state) => state.mapUSAIDInfo
  );

  const handleClean = () => {
    setCleanAllUSAIDFilters();
    getDefaultUSAIDResult();
  };

  return (
    <SearchResultsResponsive
      onClearAllFilters={handleClean}
      count={count}
      handleLoadFeed={refreshFeedForUSAIDSearchResults}
    >
      {usaid_dataset?.map((mapObject: USAIDObject) => {
        const imgSrc =
          mapObject.entity_type === "Юридична особа" ? JpBlue : NpGreen;
        const type = `${mapObject.category} ·`;
        const incomeText =
          mapObject && mapObject.income_2022_usd
            ? " " + (mapObject.income_2022_usd * 1000).toFixed(2) + " USD"
            : " Не визначено ";
        const paragraphs = [mapObject.edrpou, type + incomeText];
        return (
          <GeneralSearchResultItem
            key={mapObject.id}
            title={mapObject.name}
            lat={mapObject.lat}
            lon={mapObject.lon}
            hashId={mapObject.id}
            imgSrc={imgSrc}
            paragraphs={paragraphs}
          />
        );
      })}
    </SearchResultsResponsive>
  );
};

export default USAIDMapSearchResults;
