import Navbar from "../../components/nav/Navbar/Navbar";
import BreadcrumbNav from "../../components/nav/BreadcrumbNav/BreadcrumbNav";
import { Heading2 } from "../Heading/Heading";
import UserProfileBar from "../../components/UserProfileBar/UserProfileBar";
import styled from "@emotion/styled";
import { breakpoints } from "../../constants";
import React from "react";

const AppWrapper = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  @media screen and (max-width: ${breakpoints.small}px) {
    flex-direction: column-reverse;
  }
`;

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 32px;
  overflow-y: scroll;
  @media (max-width: ${breakpoints.small}px) {
    padding: 0px;
  }
`;

const StyledPageHeader = styled.div`
  display: flex;
  margin-bottom: 0px;
  @media (max-width: ${breakpoints.small}px) {
    border-bottom: 1px solid #e5e5e5;
  }
`;

const StyledPageTitle = styled(Heading2)`
  @media (max-width: ${breakpoints.small}px) {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
`;
const StyledBreadcrumbTitleWrapper = styled.div`
  display: grid;
  gap: 4px;
  @media (max-width: ${breakpoints.small}px) {
    gap: 0px;
  }
`;

const StyledBreadcrumbWrapper = styled.div`
  @media (max-width: ${breakpoints.small}px) {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
`;

const StyledPageContent = styled.div`
  margin-top: 48px;
  @media (max-width: ${breakpoints.small}px) {
    padding: 24px 16px;
    margin-top: 0;
  }
`;
interface IProps {
  title?: string;
  isBreadcrumb?: boolean;
  isNavbar?: boolean;
}

const SimplePage: React.FC<IProps> = ({ title = "", children }) => {
  return (
    <AppWrapper>
      <Navbar />
      <PageContainer>
        <StyledPageHeader>
          <StyledBreadcrumbTitleWrapper>
            <StyledBreadcrumbWrapper>
              <BreadcrumbNav />
            </StyledBreadcrumbWrapper>

            {title && <StyledPageTitle>{title}</StyledPageTitle>}
          </StyledBreadcrumbTitleWrapper>
          <UserProfileBar isWithFilterPopupButton={true} />
        </StyledPageHeader>
        <StyledPageContent>{children}</StyledPageContent>
      </PageContainer>
    </AppWrapper>
  );
};

export default SimplePage;
