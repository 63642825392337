import {
  MapActionsEnum,
  MapConfig,
  MapObjectAction,
  IMapObjectsInfoState,
} from "./types";
import {
  OBJECTS_MAP_CONFIG,
  AvailableCC,
} from "../../../../../constants/constants";

const initialState: IMapObjectsInfoState = {
  config: OBJECTS_MAP_CONFIG.get(
    (localStorage.getItem("map") as AvailableCC) || AvailableCC.ua
  ) as MapConfig,
  isObjectsLoading: false,
  params: {},
  hash_ids: [],
  selectedMapObject: null,
  mapObjects: {
    maxSquare: 0,
    params: {
      account_id: undefined,
      limit: 0,
      offset: 0,
    },
    count: 0,
  },
};

export default function mapReducer(
  state = initialState,
  action: MapObjectAction
) {
  switch (action.type) {
    case MapActionsEnum.UPDATE_STATE:
      return { ...state, ...action.payload };

    case MapActionsEnum.SET_MAP_CONFIG:
      return { ...state, config: action.payload };
    case MapActionsEnum.SET_IS_MAIN_LOADING:
      return { ...state, isObjectsLoading: action.payload };
    case MapActionsEnum.SET_MAP_OBJECTS:
      return { ...state, mapObjects: { ...action.payload } };
    case MapActionsEnum.SET_MAP_OBJECT:
      return { ...state, selectedMapObject: { ...action.payload } };
    case MapActionsEnum.CLEAR_MAP_OBJECT:
      return { ...state, selectedMapObject: null };
    default:
      return state;
  }
}
