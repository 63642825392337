// noinspection JSIgnoredPromiseFromCall

import { FC, ReactNode, RefObject, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { PanInfo, motion, useAnimation, useMotionValue } from "framer-motion";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";

const dragButtonHeight = 20,
  dragEndExpandRatio = 0.35;

const StyledPulloutDrawer = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  border-radius: ${(props) => (props.isOpen ? "0" : "16px 16px 0px 0px")};
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  background-color: #fff;
  z-index: 2;
  transition: all 0.5s;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const StyledPulloutDrawerBtn = styled.button`
  border-radius: 16px 16px 0 0;
  height: ${dragButtonHeight + "px"};
  position: relative;
  display: block;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 56px;
    height: 4px;
    background-color: #e0e0e0;
    border-radius: 2px;
  }
`;

const StyledPulloutDrawerHeader = styled.div`
  padding: 8px 8px 16px 8px;
  text-align: center;
  color: var(--Dark-D400, #242731);
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-decoration: none;
`;

const StyledPulloutDrawerHiddenContainer = styled.div`
  height: 100%;
  overflow: scroll;
`;

interface IPulloutDrawer {
  constraintsContainerRef: RefObject<HTMLDivElement | null> | undefined;
  header?: string | undefined | ReactNode;
  children?: ReactNode | ReactNode[] | undefined;
  onScrollToBottom?: () => void;
  noResults?: boolean;
}

const PulloutDrawer: FC<IPulloutDrawer> = ({
  constraintsContainerRef,
  header,
  onScrollToBottom,
  children,
  noResults = false,
}) => {
  const { isPulloutDrawerOpen, isDetailsActive } = useTypedSelector(
    (state) => state.mapInterface
  );

  const { setIsPulloutDrawerOpen } = useActions();

  const disabled = !!noResults;

  const handleScroll = (e: any) => {
    const searchResultContainerHeight = e.target.scrollHeight;
    const round0 =
      searchResultContainerHeight - e.target.scrollTop - e.target.clientHeight;
    if (Math.abs(round0) < 320) {
      onScrollToBottom && onScrollToBottom();
    }
  };

  const headerRef = useRef<HTMLDivElement | null>(null);
  const [dragHeaderHeight, setDragHeaderHeight] = useState<number>(0);

  const hiddenChildHeight = useMotionValue(dragHeaderHeight);
  const animationControls = useAnimation();

  useEffect(() => {
    if (headerRef?.current) {
      const currentRefHeight = headerRef.current?.clientHeight;
      setDragHeaderHeight(currentRefHeight);
      hiddenChildHeight.set(dragHeaderHeight);
      animationControls.start({ height: currentRefHeight });
    }
  }, [header]);

  const handleDrag = (
    _event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) => {
    const updatedHeight = hiddenChildHeight.get() + -1 * info.delta.y;

    if (constraintsContainerRef?.current) {
      if (
        updatedHeight > dragHeaderHeight && //min height
        updatedHeight <
          constraintsContainerRef.current.clientHeight - dragButtonHeight //max height
      ) {
        hiddenChildHeight.set(updatedHeight);
      }
    }
  };

  const animationTriggers = {
    DRAG: "drag",
    CLICK: "click",
  };

  useEffect(() => {
    if (isPulloutDrawerOpen) {
      animationControls.start({
        height:
          constraintsContainerRef.current?.clientHeight - dragButtonHeight,
        transition: { duration: 0 },
      });
    } else {
      animationControls.start({ height: dragHeaderHeight });
    }
  }, [isDetailsActive, isPulloutDrawerOpen]);

  useEffect(() => {
    if (isPulloutDrawerOpen) {
      animationControls.start({
        height:
          constraintsContainerRef.current?.clientHeight - dragButtonHeight,
        transition: { duration: 0 },
      });
    }
  }, []);

  const handlePulloutDrawerAnimation = (arg = animationTriggers.DRAG) => {
    if (constraintsContainerRef?.current) {
      const isPulloutDrawerExceedingExpandRatio =
        constraintsContainerRef.current.clientHeight * dragEndExpandRatio -
          hiddenChildHeight.get() >
        0;
      const switcher = arg === animationTriggers.DRAG; //if true works as expected, if false works vice versa
      if (isPulloutDrawerExceedingExpandRatio === switcher) {
        //drawer pulled less then dragEndExpandRatio defines
        animationControls.start({ height: dragHeaderHeight });
        setIsPulloutDrawerOpen(false);
      } else {
        //drawer pulled more then dragEndExpandRatio defines
        animationControls.start({
          height:
            constraintsContainerRef.current.clientHeight - dragButtonHeight,
        });
        setIsPulloutDrawerOpen(true);
      }
    }
  };

  const handleDragEnd = (
    _event: MouseEvent | TouchEvent | PointerEvent,
    _info: PanInfo
  ) => {
    handlePulloutDrawerAnimation(animationTriggers.DRAG);
  };

  const handlePulloutDrawerToggle = () => {
    if (disabled) {
      return;
    }
    handlePulloutDrawerAnimation(animationTriggers.CLICK);
  };

  return (
    <StyledPulloutDrawer isOpen={isPulloutDrawerOpen}>
      <div>
        <motion.div
          style={{
            height: dragButtonHeight,
          }}
          data-type="pullout-drawer-btn"
          drag="y"
          dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
          dragElastic={0}
          dragMomentum={false}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          onClick={handlePulloutDrawerToggle}
        >
          <StyledPulloutDrawerBtn />
        </motion.div>
      </div>
      <motion.div
        style={{
          height: noResults ? "300px" : hiddenChildHeight,
        }}
        data-type="pullout-drawer-hidden-container"
        animate={animationControls}
      >
        <div ref={headerRef}>
          {header && (
            <StyledPulloutDrawerHeader onClick={handlePulloutDrawerToggle}>
              {header}
            </StyledPulloutDrawerHeader>
          )}
        </div>
        {children && (
          <StyledPulloutDrawerHiddenContainer onScroll={handleScroll}>
            <div
              style={{
                overflowY: "initial",
              }}
            >
              {children}
            </div>
          </StyledPulloutDrawerHiddenContainer>
        )}
      </motion.div>
    </StyledPulloutDrawer>
  );
};

export default PulloutDrawer;
