import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import useMobileScreen from "../../../hooks/useMobileScreen";
import { breakpoints } from "../../../constants";

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  position: relative;
  left: 30%;
  font-weight: 600;
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
  background: var(--primary-white-color);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: scale 0.3s ease-out;
`;

type Props = {
  onClick: () => void;
};

const ClearAllFiltersDesktopButton = ({ onClick }: Props) => {
  const { t: tFilter } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.filtersActions",
  });
  const isMobile = useMobileScreen(breakpoints.medium);
  return (
    !isMobile && (
      <StyledButton type="button" onClick={onClick}>
        {tFilter("clearAllFilters")}
      </StyledButton>
    )
  );
};

export default ClearAllFiltersDesktopButton;
