import {
  SetMapUSAIDListAction,
  SetMapUSAIDAction,
  SetObjectsLoading,
  SikaMapActionsEnum,
  IMapUSAIDInfoState,
} from "./types";
import { ICurrentUSAID } from "../../../../../models/CurrentUSAIDObject";
import { AppDispatch, RootState, getStoreState, store } from "../../../..";
import { usaidService } from "../../../../api/USAIDService";
import { IReqUSAIDObjects } from "../../../../../models/USAIDObject";
import { limit } from "../../../../../constants/mapConstants";
import { MapInterfaceActionCreator } from "../../mapInterface/action.creators";

export const USAIDMapObjectActionCreators = {
  setObjectsLoading: (payload: boolean): SetObjectsLoading => ({
    type: SikaMapActionsEnum.SET_IS_USAID_LOADING,
    payload,
  }),

  setMapUSAID: (mapUSAID: ICurrentUSAID): SetMapUSAIDAction => ({
    type: SikaMapActionsEnum.SET_MAP_USAID,
    payload: mapUSAID,
  }),

  setMapUSAIDInfo: (
    mapUSAIDList: IMapUSAIDInfoState
  ): SetMapUSAIDListAction => ({
    type: SikaMapActionsEnum.SET_MAP_USAID_LIST,
    payload: mapUSAIDList,
  }),
  getDefaultUSAIDResult: () => async (dispatch: AppDispatch) => {
    const data: IReqUSAIDObjects = {
      account_id: store.getState().currentAccount.id,
      limit: 20,
      offset: store.getState().mapInterface.offset,
      edrpou: store.getState().usaidMapFilters.edrpou
        ? store.getState().usaidMapFilters.edrpou
        : undefined,
      region: store.getState().usaidMapFilters.region
        ? store.getState().usaidMapFilters.region
        : undefined,
      entity_type: store.getState().usaidMapFilters.entity_type_array
        ? store.getState().usaidMapFilters.entity_type_array
        : undefined,
      category: store.getState().usaidMapFilters.category_array
        ? store.getState().usaidMapFilters.category_array
        : undefined,
      economic_sector: store.getState().usaidMapFilters.economic_sector_array
        ? store.getState().usaidMapFilters.economic_sector_array
        : undefined,
      activity_status_2022: store.getState().usaidMapFilters
        .activity_status_2022
        ? store.getState().usaidMapFilters.activity_status_2022
        : undefined,
      lat: store.getState().mapInterface.viewport.latitude
        ? store.getState().mapInterface.viewport.latitude
        : undefined,
      lon: store.getState().mapInterface.viewport.longitude
        ? store.getState().mapInterface.viewport.longitude
        : undefined,
      kved: store.getState().usaidMapFilters.kved
        ? store.getState().usaidMapFilters.kved
        : undefined,
      lon_border__range: store.getState().usaidMapFilters.lon_border__range
        ? store.getState().usaidMapFilters.lon_border__range
        : undefined,
      lat_border__range: store.getState().usaidMapFilters.lat_border__range
        ? store.getState().usaidMapFilters.lat_border__range
        : undefined,
    };
    try {
      dispatch(MapInterfaceActionCreator.setIsLoading(true));
      const response = await usaidService.fetchMapUSAIDList(data);
      if (response) {
        dispatch(USAIDMapObjectActionCreators.setMapUSAIDInfo(response.data));
      }
      dispatch(MapInterfaceActionCreator.setIsLoading(false));
    } catch (e: string | any) {
      dispatch(
        MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
      );
    }
  },
  refreshFeedForUSAIDSearchResults: () => async (dispatch: AppDispatch) => {
    const prevData: IMapUSAIDInfoState = (store.getState() as RootState)
      .mapUSAIDInfo;
    const { isLoading } = (store.getState() as RootState).mapInterface;
    if (isLoading) return;
    if (prevData.next === null) return;
    const page = getStoreState().mapInterface.page;
    dispatch(MapInterfaceActionCreator.setPage(page + 1));

    const data: IReqUSAIDObjects = {
      account_id: store.getState().currentAccount.id,
      limit: limit,
      offset: page * limit,
      edrpou: store.getState().usaidMapFilters.edrpou
        ? store.getState().usaidMapFilters.edrpou
        : undefined,
      region: store.getState().usaidMapFilters.region
        ? store.getState().usaidMapFilters.region
        : undefined,
      entity_type: store.getState().usaidMapFilters.entity_type_array
        ? store.getState().usaidMapFilters.entity_type_array
        : undefined,
      category: store.getState().usaidMapFilters.category_array
        ? store.getState().usaidMapFilters.category_array
        : undefined,
      economic_sector: store.getState().usaidMapFilters.economic_sector_array
        ? store.getState().usaidMapFilters.economic_sector_array
        : undefined,
      activity_status_2022: store.getState().usaidMapFilters
        .activity_status_2022
        ? store.getState().usaidMapFilters.activity_status_2022
        : undefined,
      lat: store.getState().mapInterface.viewport.latitude
        ? store.getState().mapInterface.viewport.latitude
        : undefined,
      lon: store.getState().mapInterface.viewport.longitude
        ? store.getState().mapInterface.viewport.longitude
        : undefined,
      kved: store.getState().usaidMapFilters.kved
        ? store.getState().usaidMapFilters.kved
        : undefined,
      lon_border__range: store.getState().usaidMapFilters.lon_border__range
        ? store.getState().usaidMapFilters.lon_border__range
        : undefined,
      lat_border__range: store.getState().usaidMapFilters.lat_border__range
        ? store.getState().usaidMapFilters.lat_border__range
        : undefined,
    };
    try {
      dispatch(MapInterfaceActionCreator.setIsLoading(true));
      const response = await usaidService.fetchMapUSAIDList(data);
      if (response) {
        const nextData: IMapUSAIDInfoState = response.data;

        const composedData: IMapUSAIDInfoState = {
          ...prevData,
          ...nextData,
          usaid_dataset: [...prevData.usaid_dataset, ...nextData.usaid_dataset],
        };

        dispatch(USAIDMapObjectActionCreators.setMapUSAIDInfo(composedData));
      }
      dispatch(MapInterfaceActionCreator.setIsLoading(false));
    } catch (e: string | any) {
      dispatch(
        MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
      );
    }
  },
  getCurrentMapUSAID:
    (RECORD: number, account_id: number) => async (dispatch: AppDispatch) => {
      try {
        dispatch(MapInterfaceActionCreator.setIsLoading(true));

        const response = await usaidService.fetchMapUSAID(RECORD, account_id);
        if (response) {
          dispatch(USAIDMapObjectActionCreators.setMapUSAID(response.data));
        }
        dispatch(MapInterfaceActionCreator.setIsLoading(false));
      } catch (e: string | any) {
        dispatch(
          MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
        );
      }
    },
};
