import mapboxSdk, { SdkConfig } from "@mapbox/mapbox-sdk";
import mapboxGeocoding, {
  GeocodingService,
} from "@mapbox/mapbox-sdk/services/geocoding";
import { mapboxAccessToken } from "./mapConstants";

const mapboxToken: string = mapboxAccessToken; // Use your actual Mapbox access token
const baseClient: SdkConfig = mapboxSdk({ accessToken: mapboxToken });
const geocodingClient: GeocodingService = mapboxGeocoding(baseClient);

export default geocodingClient;
