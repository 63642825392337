import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import DatePicker from "../../CompanyDatePicker/DatePicker";
import { useTranslation } from "react-i18next";
import useIsFilterActive from "../../../../../hooks/useIsFilterActive";
import { FilterTitleWithInfo } from "../../../../../share/filters/FilterTitleWithInfo";
import FilterButtonWithModal from "../../../../../share/filters/FilterButtonWithModal";

const DateFilter = () => {
  const filterId = "companyPeriod";
  const { date_added__lte, date_added__gte } = useTypedSelector(
    (state) => state.companyFilters
  );
  const isFilterActive = useIsFilterActive(filterId);
  const { setDateAddedLTE, setDateAddedGTE } = useActions();

  const handleClean = () => {
    setDateAddedLTE("");
    setDateAddedGTE("");
  };

  const { t: tDate } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.companies.dateFilter",
  });

  const filterButtonText = tDate("title");
  const pinText = date_added__gte && date_added__lte && "In";

  return (
    <FilterButtonWithModal
      title={filterButtonText}
      pinText={pinText}
      filterId={filterId}
      onClear={handleClean}
      left={-280}
      fullWidth
    >
      <FilterTitleWithInfo title={tDate("title")} info={""} />
      <DatePicker isFilterActive={isFilterActive} />
    </FilterButtonWithModal>
  );
};

export default DateFilter;
