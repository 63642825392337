import React, { Dispatch, SetStateAction, useState } from "react";
import styles from "./AccountUsersRoleModal.module.scss";
import css from "../../../../styles.module.scss";
import { CustomSelect } from "../../../../share/Input/Input";
import { IRole } from "../../../../models/Accounts";
import Modal from "../../../../share/Modal/Modal";
import { useActions } from "../../../../hooks/useActions";
import Close from "../../../../assets/svg/CloseBlack.svg";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useTranslation } from "react-i18next";

const roleMapping = {
  User: "user",
  "Billing administrator": "billingAdmin",
  Administrator: "admin",
};

interface IProps {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  setShowMenuUsers: Dispatch<SetStateAction<boolean>>;
  user_id: number;
}

const AccountUsersRoleModal = ({
  modalVisible,
  setModalVisible,
  setShowMenuUsers,
  user_id,
}: IProps) => {
  const { updateCurrentUserRole } = useActions();
  const { roles } = useTypedSelector((state) => state.accountUtility);
  const [selectValue, setSelectValue] = useState("1");
  const { users } = useTypedSelector((state) => state.currentAccount);
  const currentUserName =
    users.find((user) => user.user.id === user_id)?.user.full_name || "";

  const handleUpdate = () => {
    if (selectValue === "1") return;
    updateCurrentUserRole(user_id, +selectValue);
    setShowMenuUsers(false);
    setModalVisible(false);
  };

  const { t: tModal } = useTranslation("translation", {
    keyPrefix: "accountUsersPage.roleModal",
  });

  const { t: tRole } = useTranslation("translation", {
    keyPrefix: "accountUsersPage.roleModal.role",
  });

  return (
    <Modal modalVisible={modalVisible} setModalVisible={setModalVisible}>
      <div className={styles.modalWrapper}>
        <div className={styles.modalHeader}>
          <h2 className={styles.title}>
            {tModal("title")} {currentUserName && `для ${currentUserName}`}
          </h2>
          <img onClick={() => setModalVisible(false)} src={Close} alt="close" />
        </div>
        <div className={styles.modalBody}>
          <label htmlFor="role_id" className={css.label}>
            {tModal("role.label")}
            <CustomSelect
              name="role_id"
              classSelectName={
                selectValue === "1"
                  ? `${css.select} ${css.disabledOption}`
                  : css.select
              }
              value={selectValue}
              onChange={(e) => setSelectValue(e.target.value)}
            >
              <option value="1" disabled>
                {tModal("role.defaultOption")}
              </option>
              {roles?.results?.map((role: IRole) => (
                <option key={role.id} value={role.id}>
                  {tRole(roleMapping[role.value])}
                </option>
              ))}
            </CustomSelect>
          </label>
        </div>
        <div className={styles.modalFooter}>
          <button
            onClick={handleUpdate}
            type="button"
            className={styles.submitButton}
          >
            {tModal("submitBtn")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AccountUsersRoleModal;
