import styled from "@emotion/styled";
import { FC, useState } from "react";
import { colors } from "../../constants";
import CustomButton from "../../share/Button/Button";
import CustomIcon from "../../share/CustomIcon/CustomIcon";
import { ReactComponent as CloseIcon } from "../../assets/svg/Close_small_nofill.svg";

const StyledBar = styled.div<{ isShown?: boolean }>`
  width: 100%;
  min-height: 48px;
  padding: 8px 16px 8px 24px;
  border-radius: 4px;
  background-color: ${colors.redR400};
  display: ${(props) => (props.isShown ? "flex" : "none")};
  align-items: center;
  flex-wrap: wrap;
`;

const StyledMessage = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0016em;
  color: ${colors.white};
  flex: auto;
`;

const StyledActionButton = styled(CustomButton)`
  color: ${colors.white};
  border-color: ${colors.white};
  min-height: 32px;
  height: 32px;
  padding: 0 16px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.0012em;
  text-align: center;
  margin-right: auto;

  &:hover {
    color: ${colors.redR400};
    background-color: ${colors.white};
  }
`;

const StyledCross = styled.button`
  border: none;
  background: none;
  outline: none;
  margin-left: 16px;
`;

const StyledTextPart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  gap: 16px;
`;

interface IPopupBar {
  message?: string;
  onBtnClick?: () => void;
  onClose?: () => void;
  btnText?: string;
}

const PopupBar: FC<IPopupBar> = ({ message, onBtnClick, onClose, btnText }) => {
  const [isShown, setIsShown] = useState<boolean>(true);

  const handleClose = () => {
    setIsShown(false);
    onClose && onClose();
  };

  return (
    <StyledBar isShown={isShown}>
      <StyledTextPart>
        {message && <StyledMessage>{message}</StyledMessage>}
        {btnText && onBtnClick && (
          <StyledActionButton onClick={onBtnClick} type="bordered">
            {btnText}
          </StyledActionButton>
        )}
      </StyledTextPart>
      <StyledCross onClick={handleClose}>
        <CustomIcon icon={CloseIcon} size={"24px"} color={colors.white} />
      </StyledCross>
    </StyledBar>
  );
};

export default PopupBar;
