import { useEffect, useState } from "react";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import Details from "./ActiveTabs/Details";
import Contacts from "./ActiveTabs/Contacts";
import ImageFinder from "../../../../share/ImageFinder/ImageFinder";
import { useTranslation } from "react-i18next";
import useSearchParams from "../../../../hooks/useSearchParams";
import Tab, { TabsWrapper } from "../../../Tab/Tab";
import AnimatedDetailsContainer from "../../../../share/ResultDetails/AnimatedDetailsContainer/AnimatedDetailsContainer";
import DetailsHeader from "../../../../share/ResultDetails/DetailsHeader/DetailsHeader";
import {
  DetailsContainer,
  DetailsTabContent,
  ShowOnMapButton,
  StyledDetailsActions,
} from "../../../../share/ResultDetails/DetailsScroll/DetailsScroll";
import useMobileScreen from "../../../../hooks/useMobileScreen";

const ObjectDetails = () => {
  const { selectedMapObject } = useTypedSelector(
    (state) => state.mapObjectsInfo
  );

  const { setIsDetailsActive, setCurrentMarker, clearSelectedMapObject } =
    useActions();
  const [activeTab, setActiveTab] = useState("details");

  const { t: tObjectDetails } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.objectDetails",
  });

  const { setSearchParam, getSearchParam, removeQueryParam } =
    useSearchParams();

  const handleCloseDetails = () => {
    setCurrentMarker({ lat: 0, lon: 0 });
    removeQueryParam("hash_id");
    setCurrentMarker({ lon: 0, lat: 0 });
    clearSelectedMapObject();
    setIsDetailsActive(false);
  };
  useEffect(() => {
    const hashId = getSearchParam("hash_id");
    if (!hashId && selectedMapObject?.hash_id) {
      setSearchParam("hash_id", selectedMapObject.hash_id);
    }
  }, []);

  const isMobile = useMobileScreen();

  const isDetailsActive = useTypedSelector(
    (state) => state.mapInterface.isDetailsActive
  );
  const title = selectedMapObject && selectedMapObject?.aimap_classifier;

  if (!selectedMapObject) return null;

  return (
    <AnimatedDetailsContainer isShown={isDetailsActive}>
      <DetailsHeader
        title={title}
        description={
          selectedMapObject &&
          (selectedMapObject?.formatted_address || selectedMapObject?.address)
        }
        closeDetails={handleCloseDetails}
        logo={<ImageFinder object={selectedMapObject} />}
      />
      <DetailsContainer>
        <TabsWrapper>
          <Tab
            isActive={activeTab === "details"}
            onClick={() => setActiveTab("details")}
            text={tObjectDetails("details.title")}
          />
          <Tab
            isActive={activeTab === "contacts"}
            onClick={() => setActiveTab("contacts")}
            text={tObjectDetails("contacts.title")}
          />
        </TabsWrapper>
        <DetailsTabContent>
          {activeTab === "details" ? (
            <Details key={selectedMapObject && selectedMapObject.name} />
          ) : (
            <Contacts key={selectedMapObject && selectedMapObject.name} />
          )}
        </DetailsTabContent>
        {isMobile && (
          <StyledDetailsActions>
            <ShowOnMapButton
              lat={selectedMapObject.lat}
              lon={selectedMapObject.lon}
            />
          </StyledDetailsActions>
        )}
      </DetailsContainer>
    </AnimatedDetailsContainer>
  );
};

export default ObjectDetails;
