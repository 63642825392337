import styles from "./OtherFilters.module.scss";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { useActions } from "../../../../../hooks/useActions";
import { CustomCheckbox } from "../../../../../share/Input/Input";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { FilterIdsEnum } from "../../../../../store/reducers/_maps/main/mapFilters/types";
import FilterButtonWithModal from "../../../../../share/filters/FilterButtonWithModal";
import {
  FilterSection,
  FilterSectionSeparatorLine,
  FilterSections,
  CheckboxList,
} from "../../../../../share/filters";
import { FilterTitleWithInfo } from "../../../../../share/filters/FilterTitleWithInfo";
import {
  ccFilterTranslationMap,
  objectCategoryTranslationMap,
  organizationTypeTranslationMap,
  ownershipTranslationMap,
} from "../../../../../constants/filterTranslationMapping";
import useSearchParams from "../../../../../hooks/useSearchParams";
import { AvailableCC } from "../../../../../constants/constants";
interface IItem {
  name: string;
  value: string;
}
const OtherFilters = () => {
  const filterId: FilterIdsEnum = "sikaOtherFilters";
  const {
    consequence_class_array,
    domain_array,
    role_array,
    entity_type_array,
    isIncomeBigger,
  } = useTypedSelector((state) => state.sikaDesignerFilters);
  const { consequence_class, domain, role, entity_type } = useTypedSelector(
    (state) => state.mapSikaDesignerInfo.params
  );

  const {
    toggleSikaDesignerCCFilterObject,
    typeSikaDesignerCCClean,
    setSelectedDocList,
    setOffset,
    setPage,
    setIsIncomeBigger,
    toggleSikaDesignerCategoryFilterObject,
    typeSikaDesignerCategoryClean,
    toggleSikaDesignerOrganizationFilterObject,
    typeSikaDesignerOrganizationClean,
    toggleSikaDesignerOwnershipFilterObject,
    typeSikaDesignerOwnershipClean,
  } = useActions();

  const handleClick = () => {
    setOffset(0);
    setPage(1);
  };
  const handleIncomeBigger = () => {
    setIsIncomeBigger(!isIncomeBigger);
    handleClick();
  };
  const handleCategoryChange = (type: string) => {
    toggleSikaDesignerCategoryFilterObject(type);
    handleClick();
  };
  const handleOrganizationChange = (type: string) => {
    toggleSikaDesignerOrganizationFilterObject(type);
    handleClick();
  };
  const handleOwnershipChange = (type: string) => {
    toggleSikaDesignerOwnershipFilterObject(type);
    handleClick();
  };

  const handleCCChange = (type: string) => {
    toggleSikaDesignerCCFilterObject(type);
    handleClick();
  };

  const handleClean = () => {
    typeSikaDesignerCCClean();
    typeSikaDesignerCategoryClean();
    typeSikaDesignerOrganizationClean();
    typeSikaDesignerOwnershipClean();
    setIsIncomeBigger(false);
    setSelectedDocList([{ id: uuidv4(), value: "1" }]);
  };

  const filterCount =
    (isIncomeBigger ? 1 : 0) +
    consequence_class_array.length +
    domain_array.length +
    role_array.length +
    entity_type_array.length;

  const { t: tOther } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.sika.otherFilter",
  });

  const { t: tCCoption } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.sika.otherFilter.ccFilterValues",
  });

  const { t: tCategoryOption } = useTranslation("translation", {
    keyPrefix:
      "mainPage.mapFilters.sika.otherFilter.objectCategoryFilterValues",
  });

  const { t: tTypeOption } = useTranslation("translation", {
    keyPrefix:
      "mainPage.mapFilters.sika.otherFilter.organizationTypeFilterValues",
  });

  const { t: tOwnershipOption } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.sika.otherFilter.ownershipFilterValues",
  });

  const title = tOther("title");
  const pinText = filterCount > 0 && filterCount;

  const isUaRegion =
    useSearchParams().getSearchParams().region === AvailableCC.ua;

  return (
    <FilterButtonWithModal
      filterId={filterId}
      pinText={pinText}
      title={title}
      onClear={handleClean}
      left={-400}
    >
      <FilterSections>
        <FilterSection>
          <CustomCheckbox
            single={true}
            children={tOther("revenue")}
            checked={isIncomeBigger}
            onChange={handleIncomeBigger}
            style={styles.checkboxLabel}
          ></CustomCheckbox>
        </FilterSection>

        <FilterSection>
          <FilterTitleWithInfo title={tOther("consequenceClass")} />
          <CheckboxList>
            {consequence_class &&
              [...consequence_class].sort().map((type: IItem) => {
                const option = isUaRegion
                  ? tCCoption(ccFilterTranslationMap[type.name])
                  : type.name;
                return (
                  <CustomCheckbox
                    value={type.name}
                    key={type.value}
                    checked={consequence_class_array.includes(type.value)}
                    onChange={() => handleCCChange(type.value)}
                  >
                    {option.length > 45 ? option.slice(0, 45) + ".." : option}
                  </CustomCheckbox>
                );
              })}
          </CheckboxList>
        </FilterSection>

        <FilterSectionSeparatorLine />
        <FilterSection>
          <FilterTitleWithInfo title={tOther("objectCategory")} />

          <CheckboxList inRow>
            {domain &&
              domain.map((type: string) => {
                const option = isUaRegion
                  ? tCategoryOption(objectCategoryTranslationMap[type])
                  : type;
                return (
                  <CustomCheckbox
                    value={type}
                    key={type}
                    checked={domain_array.includes(type)}
                    onChange={() => handleCategoryChange(type)}
                  >
                    {option.length > 45 ? option.slice(0, 45) + ".." : option}
                  </CustomCheckbox>
                );
              })}
          </CheckboxList>
        </FilterSection>
        <FilterSectionSeparatorLine />

        <FilterSection>
          <FilterTitleWithInfo title={tOther("organizationType")} />
          <CheckboxList inRow>
            {role &&
              role.map((type: string) => {
                const option = isUaRegion
                  ? tTypeOption(organizationTypeTranslationMap[type])
                  : type;
                return (
                  <CustomCheckbox
                    value={type}
                    key={type}
                    checked={role_array.includes(type)}
                    onChange={() => handleOrganizationChange(type)}
                  >
                    {option.length > 45 ? option.slice(0, 45) + ".." : option}
                  </CustomCheckbox>
                );
              })}
          </CheckboxList>
        </FilterSection>
        <FilterSectionSeparatorLine />

        <FilterSection>
          <FilterTitleWithInfo title={tOther("propertyType")} />
          <CheckboxList>
            {entity_type &&
              entity_type.map((type: string) => {
                const option = isUaRegion
                  ? tOwnershipOption(ownershipTranslationMap[type])
                  : type;
                return (
                  <CustomCheckbox
                    value={type}
                    key={type}
                    checked={entity_type_array.includes(type)}
                    onChange={() => handleOwnershipChange(type)}
                  >
                    {option.length > 45 ? option.slice(0, 45) + ".." : option}
                  </CustomCheckbox>
                );
              })}
          </CheckboxList>
        </FilterSection>
      </FilterSections>
    </FilterButtonWithModal>
  );
};

export default OtherFilters;
