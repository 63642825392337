import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { CurrentPerson } from "../../../../models/CurrentPerson";
import SearchResultsResponsive from "../../../../share/searchResults/SearchResultsResponsive";
import PersonsSearchResultItem from "./PersonsSearchResultItem";
const PersonSearchResults = () => {
  const {
    setCleanAllPersonFilters,
    getDefaultPersonResult,
    refreshFeedForPersonsSearchResults,
  } = useActions();
  const { persons, count } = useTypedSelector((state) => state.mapPersonInfo);

  const handleClean = () => {
    setCleanAllPersonFilters();
    getDefaultPersonResult();
  };

  return (
    <SearchResultsResponsive
      onClearAllFilters={handleClean}
      count={count}
      handleLoadFeed={refreshFeedForPersonsSearchResults}
    >
      {persons?.map((mapObject: CurrentPerson, index: number) => (
        <PersonsSearchResultItem key={index} mapObject={mapObject} />
      ))}
    </SearchResultsResponsive>
  );
};

export default PersonSearchResults;
