import {
  DeletePersonActiveSuggestion,
  DeletePersonHashCode,
  DeletePersonRegion,
  MapFilterActionsEnum,
  SetPersonActiveSuggestion,
  SetPersonStanFilter,
  SetPersonCleanStanFilter,
  SetCleanPersonHashCode,
  SetPersonCleanRegion,
  SetPersonCleanSuggestion,
  SetPersonHashCode,
  SetPersonRegion,
  SetSelectedDocList,
  SetPersonSuggestion,
  SetPersonDateAddedLTE,
  SetPersonDateAddedGTE,
  SetPersonKVED,
  SetCleanPersonKVED,
  DeletePersonKVED,
  SetPersonKvedIsMainAction,
  SetIsSuggestionsLoadingAction,
} from "./types";
import { AppDispatch, RootState, store } from "../../../../index";
import { MapInterfaceActionCreator } from "../../mapInterface/action.creators";
import { ISearchRequest, ISearchResponse } from "../../../../../models/Search";
import { ISelectedDocList } from "../../../../../models/MapObject";
import { v4 as uuidv4 } from "uuid";
import { personService } from "../../../../api/PersonService";
import { MapFilterActionCreators } from "../../main/mapFilters/action-creators";

export const PersonFilterActionCreators = {
  setCleanAllPersonFilters: () => (dispatch: AppDispatch) => {
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
    dispatch(PersonFilterActionCreators.cleanPersonSuggestions([]));
    dispatch(PersonFilterActionCreators.cleanPersonRegion([]));
    dispatch(PersonFilterActionCreators.cleanPersonKVED([]));
    dispatch(PersonFilterActionCreators.setPersonDateAddedLTE(""));
    dispatch(PersonFilterActionCreators.setPersonDateAddedGTE(""));
    dispatch(PersonFilterActionCreators.setPersonKvedIsMain(false));
    dispatch(MapFilterActionCreators.cleanKeyword([]));
    dispatch(PersonFilterActionCreators.cleanPersonHashCode([]));
    dispatch(
      PersonFilterActionCreators.setSelectedDocList([
        { id: uuidv4(), value: "1" },
      ])
    );
  },

  setIsSuggestionsLoading: (
    payload: boolean
  ): SetIsSuggestionsLoadingAction => ({
    type: MapFilterActionsEnum.SET_SUGGESTION_LOADING,
    payload,
  }),

  setPersonKvedIsMain: (payload: boolean): SetPersonKvedIsMainAction => ({
    type: MapFilterActionsEnum.SET_PERSON_KVED_IS_MAIN,
    payload,
  }),
  setPersonDateAddedLTE: (payload: string): SetPersonDateAddedLTE => ({
    type: MapFilterActionsEnum.SET_PERSON_DATE_ADDED_LTE,
    payload,
  }),

  setPersonDateAddedGTE: (payload: string): SetPersonDateAddedGTE => ({
    type: MapFilterActionsEnum.SET_PERSON_DATE_ADDED_GTE,
    payload,
  }),
  togglePersonStanFilterObject: (payload: string): SetPersonStanFilter => ({
    type: MapFilterActionsEnum.TOGGLE_PERSON_STAN_FILTER,
    payload,
  }),
  cleanPersonStanFilter: (payload: []): SetPersonCleanStanFilter => ({
    type: MapFilterActionsEnum.CLEAN_PERSON_STAN_FILTER,
    payload,
  }),
  typePersonStanClean: () => (dispatch: AppDispatch) => {
    dispatch(PersonFilterActionCreators.cleanPersonStanFilter([]));
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },
  setSelectedDocList: (payload: ISelectedDocList[]): SetSelectedDocList => ({
    type: MapFilterActionsEnum.SET_DOC_LIST,
    payload,
  }),
  setPersonSuggestions: (payload: ISearchResponse[]): SetPersonSuggestion => ({
    type: MapFilterActionsEnum.SET_PERSON_SUGGESTION,
    payload: payload,
  }),
  getPersonSuggestions:
    (data: ISearchRequest) => async (dispatch: AppDispatch) => {
      try {
        dispatch(MapFilterActionCreators.setIsSuggestionsLoading(true));
        const response = await personService.fetchSuggestions(data);
        if (response) {
          const suggestions = response.data;
          const activeSuggestions = (store.getState() as RootState).mapFilters
            .activeSuggestions;
          const suggestionsWithoutActiveSuggestions = suggestions.filter(
            (suggestion: ISearchResponse) =>
              !activeSuggestions.some(
                (activeSuggestion) => activeSuggestion.code === suggestion.code
              )
          );
          dispatch(
            PersonFilterActionCreators.setPersonSuggestions(
              suggestionsWithoutActiveSuggestions
            )
          );
        }
        dispatch(MapFilterActionCreators.setIsSuggestionsLoading(false));
      } catch (e: string | any) {
        dispatch(
          MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
        );
      }
    },
  setPersonActiveSuggestions: (
    payload: ISearchResponse
  ): SetPersonActiveSuggestion => ({
    type: MapFilterActionsEnum.SET_PERSON_ACTIVE_SUGGESTION,
    payload,
  }),
  cleanPersonSuggestions: (payload: []): SetPersonCleanSuggestion => ({
    type: MapFilterActionsEnum.CLEAN_PERSON_SUGGESTION,
    payload,
  }),
  deletePersonActiveSuggestion: (
    payload: ISearchResponse
  ): DeletePersonActiveSuggestion => ({
    type: MapFilterActionsEnum.DELETE_PERSON_ACTIVE_SUGGESTION,
    payload,
  }),
  setPersonRegion: (payload: string): SetPersonRegion => ({
    type: MapFilterActionsEnum.SET_PERSON_REGION,
    payload,
  }),
  cleanPersonRegion: (payload: []): SetPersonCleanRegion => ({
    type: MapFilterActionsEnum.CLEAN_PERSON_REGION,
    payload,
  }),
  deletePersonRegion: (payload: string): DeletePersonRegion => ({
    type: MapFilterActionsEnum.DELETE_PERSON_REGION,
    payload,
  }),
  setPersonHashCode: (payload: string): SetPersonHashCode => ({
    type: MapFilterActionsEnum.SET_PERSON_HASH_CODE,
    payload,
  }),
  cleanPersonHashCode: (payload: []): SetCleanPersonHashCode => ({
    type: MapFilterActionsEnum.CLEAN_PERSON_HASH_CODE,
    payload,
  }),
  deletePersonHashCode: (payload: string): DeletePersonHashCode => ({
    type: MapFilterActionsEnum.DELETE_PERSON_HASH_CODE,
    payload,
  }),
  setPersonKVED: (payload: string): SetPersonKVED => ({
    type: MapFilterActionsEnum.SET_PERSON_KVED,
    payload,
  }),
  cleanPersonKVED: (payload: []): SetCleanPersonKVED => ({
    type: MapFilterActionsEnum.CLEAN_PERSON_KVED,
    payload,
  }),
  deletePersonKVED: (payload: string): DeletePersonKVED => ({
    type: MapFilterActionsEnum.DELETE_PERSON_KVED,
    payload,
  }),
};
