import React, { useState } from "react";
import styles from "../ObjectDetails.module.scss";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { CustomLoader } from "../../../../../share/Loader/Loader";
import HiddenText from "../../../../../assets/svg/Hidden Text.svg";
import ModalWithHeader from "../../../../../share/ModalWithHeader/ModalWithHeader";
import Attention from "../../../../../assets/svg/AttentionHand.svg";
import ArrowRight from "../../../../../assets/svg/arrowRight.svg";
import { format, parseISO } from "date-fns";
import uk from "date-fns/locale/uk";
import { RouteNames } from "../../../../../routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { removeDuplicatesAndLeavePrivateTextWithPropNames } from "../../../../../util";
import { allowedCCvalues } from "../../../../../constants/constants";
import useStoreSnippets from "../../../../../hooks/useStoreSnippets";

interface IPropertiesInfo {
  id: number;
  aimap_id: string;
  property_name: string;
  property_value: string;
}

const Details = () => {
  const { selectedMapObject } = useTypedSelector(
    (state) => state.mapObjectsInfo
  );

  const { isLoading } = useTypedSelector((state) => state.auth);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  const { t: tDetails } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.objectDetails.details",
  });

  const { isGuestAccount, isFreeAccount } = useStoreSnippets();
  return (
    <div>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className={styles.detailsTab}>
          <h4 className={styles.tabTitle}>{tDetails("characteristic")}</h4>
          <table className={styles.tabTable}>
            <tbody>
              <tr className={styles.tabRow}>
                <td>{tDetails("dateCreated")}:</td>
                <td>
                  {/*TODO: why here uk - maybe we need to change locale*/}
                  {selectedMapObject?.date_added
                    ? format(
                        parseISO(selectedMapObject?.date_added),
                        "dd-MM-yyyy",
                        {
                          locale: uk,
                        }
                      )
                    : tDetails("notDefined")}
                </td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("address")}:</td>
                <td>
                  {selectedMapObject?.address === "#####" ? (
                    <button
                      type="button"
                      className={styles.noRulesLink}
                      onClick={() => setModalVisible(true)}
                      data-tooltip={tDetails("showAll")}
                    >
                      <img src={HiddenText} alt="no rules" />
                    </button>
                  ) : (
                    selectedMapObject?.address
                  )}
                </td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("constructionType")}:</td>
                <td>{selectedMapObject?.construction_type}</td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("consequenceClass")}:</td>
                <td>
                  {allowedCCvalues.includes(
                    selectedMapObject?.consequence_class
                  ) && "CC"}
                  {selectedMapObject?.consequence_class}
                </td>
              </tr>
              {selectedMapObject?.docs?.length > 0 && (
                <tr className={styles.tabRow}>
                  <td>{tDetails("permission")}:</td>
                  <td>
                    {selectedMapObject?.docs[0]?.reg_number?.toUpperCase()}️
                  </td>
                </tr>
              )}
              <tr className={styles.tabRow}>
                <td>{tDetails("documentationStage")}:</td>
                <td>{selectedMapObject?.stage_documentation}</td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("cadastralNumber")}:</td>
                <td>
                  {selectedMapObject?.kadastr
                    ? selectedMapObject.kadastr
                    : tDetails("notDefined")}
                </td>
              </tr>
            </tbody>
          </table>
          {selectedMapObject?.properties?.filter(
            removeDuplicatesAndLeavePrivateTextWithPropNames
          ).length > 0 && (
            <>
              <h4 className={styles.tabTitle}>{tDetails("properties")}</h4>
              <table className={styles.tabTable}>
                <tbody data-id="details-table">
                  {selectedMapObject?.properties
                    ?.filter(removeDuplicatesAndLeavePrivateTextWithPropNames)
                    ?.map((prop: IPropertiesInfo, index: number) => (
                      <tr key={index} className={styles.tabRow}>
                        <td>{prop.property_name}</td>
                        <td>
                          {prop?.property_value === "#####" ||
                          isFreeAccount ||
                          isGuestAccount ? (
                            <button
                              type="button"
                              className={styles.noRulesLink}
                              onClick={() => setModalVisible(true)}
                              data-tooltip={tDetails("showAll")}
                            >
                              <img src={HiddenText} alt="no rules" />
                            </button>
                          ) : (
                            prop.property_value
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
          <h4 className={styles.tabTitle}>{tDetails("description")}</h4>
          <div className={styles.tabRow}>
            <p className={styles.tabText}>{selectedMapObject?.name}</p>
          </div>
        </div>
      )}
      <ModalWithHeader
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        title={tDetails("dataNotAvailable")}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalInfoWrapper}>
            <img src={Attention} alt="attention" />
            <p className={styles.infoText}>{tDetails("warn")}</p>
          </div>
          <div className={styles.bodyWrapper}>
            <p className={styles.bodyText}>{tDetails("warn2")}</p>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <button
            onClick={() => navigate(RouteNames.SUBSCRIPTIONS)}
            className={styles.modalButton}
          >
            {tDetails("updateSubscriptionPlan")}
            <img src={ArrowRight} alt="next" />
          </button>
        </div>
      </ModalWithHeader>
    </div>
  );
};

export default Details;
