import { useEffect } from "react";
import { CustomLoader } from "../../../../share/Loader/Loader";
import { useActions } from "../../../../hooks/useActions";

const GoogleAuth = () => {
  const { googleLogin } = useActions();

  const parsedUrl = new URL(window.location.href);
  const state = parsedUrl.searchParams.get("state");
  const code = parsedUrl.searchParams.get("code");

  useEffect(() => {
    googleLogin(code);
    // eslint-disable-next-line
  }, [state, code]);

  return <CustomLoader />;
};

export default GoogleAuth;
