export enum TemplatesActionsEnum {
  SET_TEMPLATES = "SET_TEMPLATES",
  SET_IS_TEMPLATES_LOADING = "SET_IS_TEMPLATES_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_SELECTED_TEMPLATE = "SET_SELECTED_TEMPLATE",
  DELETE_TEMPLATE = "DELETE_TEMPLATE",
  UPDATE_TEMPLATE = "UPDATE_TEMPLATE",
  CREATE_TEMPLATE = "CREATE_TEMPLATE",
  APPLY_TEMPLATE = "APPLY_TEMPLATE",
}

export type ICreateTemplatePayload = {
  id: number | string;
  name: string;
  description: string;
  json: any;
};
