import styles from "./PasswordRecovery.module.scss";
import React, { useState } from "react";
import ReusableAuth from "../../../share/ReusableAuth/ReusableAuth";
import PassRecFirstPage from "./PassRecFirstPage/PassRecFirstPage";
import PassRecSecondPage from "./PassRecSecondPage/PassRecSecondPage";
import PassRecThirdPage from "./PassRecThirdPage/PassRecThirdPage";
import { useTranslation } from "react-i18next";

const resetPasswordData = {
  email: "",
  activation_code: 0,
};

const PasswordRecovery = () => {
  const [page, setPage] = useState<number>(1);
  const [resetPassword, setResetPassword] = useState(resetPasswordData);

  const { t } = useTranslation("translation", {
    keyPrefix: "passwordRecoveryPage",
  });

  return (
    <ReusableAuth page={page}>
      <div className={styles.passRecContainer}>
        <h1 className={styles.passRecTitle}>{t("title")}</h1>
        <p className={styles.passRecText}>{t("description")}</p>
        {page === 1 ? (
          <PassRecFirstPage
            setPage={setPage}
            setResetPassword={setResetPassword}
            resetPassword={resetPassword}
          />
        ) : page === 2 ? (
          <PassRecSecondPage
            setPage={setPage}
            setResetPassword={setResetPassword}
            resetPassword={resetPassword}
          />
        ) : (
          <PassRecThirdPage
            setPage={setPage}
            setResetPassword={setResetPassword}
            resetPassword={resetPassword}
          />
        )}
      </div>
    </ReusableAuth>
  );
};

export default PasswordRecovery;
