import { useState } from "react";
import styles from "../../../main/ObjectDetails/ObjectDetails.module.scss";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { IContact } from "../../../../../models/CurrentSikaDesigner";
import { CustomLoader } from "../../../../../share/Loader/Loader";
import Attention from "../../../../../assets/svg/AttentionHand.svg";
import ArrowRight from "../../../../../assets/svg/arrowRight.svg";
import ModalWithHeader from "../../../../../share/ModalWithHeader/ModalWithHeader";
import { RouteNames } from "../../../../../routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DetailsCellFormattedText from "../../../../../share/DetailsCellFormattedText/DetailsCellFormattedText";
import NoContactsSection from "../../../../../share/objectDetails/ContactsTab/NoContactsSection/NoContactsSection";
import useStoreSnippets from "../../../../../hooks/useStoreSnippets";
import LoginToViewMessage from "../../../../LoginToViewMessage";

const Contacts = () => {
  const { sika_designer } = useTypedSelector(
    (state) => state.mapSikaDesignerInfo
  );
  const { isLoading } = useTypedSelector((state) => state.mapInterface);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  const { t: tContacts } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.objectDetails.contacts",
  });
  const isNoContacts = sika_designer?.contacts?.length === 0;
  const { isGuestAccount } = useStoreSnippets();

  if (isLoading) return <CustomLoader />;

  if (isGuestAccount) {
    return (
      <div className={styles.detailsTab}>
        <LoginToViewMessage />
      </div>
    );
  }
  if (isNoContacts) {
    return <NoContactsSection />;
  }
  return (
    <>
      <div className={styles.detailsTab}>
        {sika_designer?.contacts?.map((contact: IContact, index: number) => (
          <div key={index}>
            <table key={index} className={styles.tabTable}>
              <tbody>
                <tr className={styles.tabRow}>
                  <td>{contact.type}:</td>
                  <td>
                    <DetailsCellFormattedText name={contact.type}>
                      {contact.value}
                    </DetailsCellFormattedText>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
      <ModalWithHeader
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        title={tContacts("dataNotAvailable")}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalInfoWrapper}>
            <img src={Attention} alt="attention" />
            <p className={styles.infoText}>{tContacts("warn")}</p>
          </div>
          <div className={styles.bodyWrapper}>
            <p className={styles.bodyText}>{tContacts("warn2")}</p>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <button
            onClick={() => navigate(RouteNames.SUBSCRIPTIONS)}
            className={styles.modalButton}
          >
            {tContacts("updateSubscriptionPlan")}
            <img src={ArrowRight} alt="next" />
          </button>
        </div>
      </ModalWithHeader>
    </>
  );
};

export default Contacts;
