import styles from "./Loader.module.scss";
import styled from "@emotion/styled";

export const Ellipsis = () => (
  <div className={styles.ldsEllipsis}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export const CustomLoader = () => {
  return (
    <div className={styles.loader}>
      <Ellipsis />
    </div>
  );
};

export const ButtonLoader = () => {
  return (
    <div className={styles.ldsRing}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

// container that absolute and centrilize loader in parent with no background
const StyledLoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

// just loader no divs around
export const Loader = () => (
  <StyledLoaderContainer>
    <div className={styles.ldsEllipsis}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </StyledLoaderContainer>
);
