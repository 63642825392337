import {
  CurrentSikaDesigner,
  ICurrentSikaDesigner,
} from "../../../../../models/CurrentSikaDesigner";

interface MapStateParams {
  [key: string]: any;
}
export interface IMapSikaDesignersInfoState {
  account_id?: number;
  isObjectsLoading: boolean;
  params: MapStateParams;
  hash_ids: [];
  sika_designer: CurrentSikaDesigner;
  count: number;
  sika_designers: CurrentSikaDesigner[];
  next: null | string;
}

export enum MapActionsEnum {
  SET_IS_SIKA_LOADING = "SET_IS_SIKA_LOADING",
  SET_MAP_SIKA_DESIGNERS = "SET_MAP_SIKA_DESIGNERS",
  SET_MAP_SIKA_DESIGNER = "SET_SELECTED_SIKA_DESIGNER",
}

export interface SetObjectsLoading {
  type: MapActionsEnum.SET_IS_SIKA_LOADING;
  payload: boolean;
}

export interface SetMapSikaDesignerAction {
  type: MapActionsEnum.SET_MAP_SIKA_DESIGNER;
  payload: ICurrentSikaDesigner;
}

export interface SetMapSikaDesignersAction {
  type: MapActionsEnum.SET_MAP_SIKA_DESIGNERS;
  payload: IMapSikaDesignersInfoState;
}

export type MapObjectAction =
  | SetObjectsLoading
  | SetMapSikaDesignerAction
  | SetMapSikaDesignersAction;
