import {
  SearchResultItem,
  StyledSearchResultsItemTitle,
} from "../../../../../share/searchResults";
import { useActions } from "../../../../../hooks/useActions";
import { MapObject } from "../../../../../models/MapObject";
import useSearchParams from "../../../../../hooks/useSearchParams";
import ImageFinder from "../../../../../share/ImageFinder/ImageFinder";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { formatSearchResultTitle } from "../../../../../util/stringUtil";

type Props = {
  mapObject: MapObject;
};

const MainSearchResultsItem: React.FC<Props> = ({ mapObject }) => {
  const { setCurrentMarker } = useActions();
  const { setSearchParam } = useSearchParams();
  const { subscribe } = useTypedSelector((state) => state.currentAccount);

  const handleClick = () => {
    const hashId = mapObject.hash_id || mapObject.aimap_id;
    setSearchParam("hash_id", hashId);
  };

  function renderObjectSquare() {
    if (subscribe?.permissions.non_available_field?.includes("object_square")) {
      return "";
    }
    if (
      mapObject.object_square &&
      mapObject.object_square !== "0" &&
      mapObject.object_square !== "null"
    ) {
      return "· " + mapObject.object_square + " м²";
    }
    return "";
  }

  return (
    <SearchResultItem
      hashId={mapObject.hash_id}
      onMouseEnter={() =>
        setCurrentMarker({
          lon: +mapObject.lon,
          lat: +mapObject.lat,
        })
      }
      onMouseLeave={() => setCurrentMarker({ lon: 0, lat: 0 })}
      onClick={handleClick}
    >
      <ImageFinder object={mapObject} />
      <StyledSearchResultsItemTitle>
        {formatSearchResultTitle(mapObject.name)}
      </StyledSearchResultsItemTitle>
      <p>{mapObject.aimap_classifier}</p>
      <p>
        {mapObject.construction_type} &middot; СС{" "}
        {(mapObject.consequence_class || mapObject.type) + " "}
        {renderObjectSquare()}
      </p>
    </SearchResultItem>
  );
};

export default MainSearchResultsItem;
