import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { CustomCheckbox } from "../../../../../share/Input/Input";
import { useTranslation } from "react-i18next";
import { CheckboxList } from "../../../../../share/filters";
import { FilterIdsEnum } from "../../../../../store/reducers/_maps/main/mapFilters/types";
import FilterButtonWithModal from "../../../../../share/filters/FilterButtonWithModal";
import { activityStatusFilterTranslationMap } from "../../../../../constants/filterTranslationMapping";
import useSearchParams from "../../../../../hooks/useSearchParams";
import { AvailableCC } from "../../../../../constants/constants";

const ActivityStatusFilter = () => {
  const filterId: FilterIdsEnum = "usaidActivityStatus";
  const { activity_status_array } = useTypedSelector(
    (state) => state.usaidMapFilters
  );
  const { activity_status_2022 } = useTypedSelector(
    (state) => state.mapUSAIDInfo.params
  );
  const {
    toggleUSAIDActivityStatusFilter,
    USAIDActivityStatusFilterClean,
    applyFilter,
  } = useActions();

  const handleChange = (value: string) => {
    toggleUSAIDActivityStatusFilter(value);
  };

  const handleClean = () => {
    USAIDActivityStatusFilterClean();
  };

  const handleClick = () => {
    applyFilter();
  };

  const { t: tStatus } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.usaid.statusFilter",
  });

  const { t: tOption } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.usaid.statusFilter.values",
  });

  const typeFilterText = tStatus("title");
  const pinText =
    activity_status_array.length > 0 && activity_status_array.length;

  const isUaRegion =
    useSearchParams().getSearchParams().region === AvailableCC.ua;

  return (
    <FilterButtonWithModal
      title={typeFilterText}
      pinText={pinText}
      filterId={filterId}
      onClear={handleClean}
      left={-316}
      width={400}
    >
      <CheckboxList>
        {activity_status_2022?.length &&
          [...activity_status_2022].sort().map((value: string) => (
            <CustomCheckbox
              value={value}
              key={value}
              checked={activity_status_array.includes(value)}
              onChange={() => handleChange(value)}
              onClick={() => handleClick()}
            >
              {isUaRegion
                ? tOption(activityStatusFilterTranslationMap[value])
                : value}
            </CustomCheckbox>
          ))}
      </CheckboxList>
    </FilterButtonWithModal>
  );
};

export default ActivityStatusFilter;
