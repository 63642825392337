import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useActions } from "../../../../hooks/useActions";
import { CustomInput, inputTypes } from "../../../../share/Input/Input";
import styles from "../Registration.module.scss";
import css from "../../../../styles.module.scss";
import CloseEye from "../../../../assets/svg/closeEye.svg";
import OpenEye from "../../../../assets/svg/openEye.svg";
import ArrowRight from "../../../../assets/svg/arrowRight.svg";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { ButtonLoader } from "../../../../share/Loader/Loader";
import ContractOffer from "../../../../components/ContractOffer/ContractOffer";
import { useTranslation } from "react-i18next";

export interface IFormInputsFirst {
  email: string;
  full_name: string;
  password: string;
  re_password: string;
}

interface IProps {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setUser: React.Dispatch<React.SetStateAction<IFormInputsFirst>>;
  user?: IFormInputsFirst;
}

const RegistrationForm: React.FC<IProps> = ({ setPage, setUser, user }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "registrationPage.RegistrationFirstStep",
  });

  const schema = yup
    .object<Record<keyof IFormInputsFirst, yup.AnySchema>>()
    .shape({
      full_name: yup.string().required(t("fullName.required")),
      email: yup
        .string()
        .email(t("email.validation"))
        .required(t("email.required")),
      password: yup
        .string()
        .required(t("enterPassword.required"))
        .min(8, t("enterPassword.min"))
        .max(20, t("enterPassword.max")),
      re_password: yup
        .string()
        .required(t("repeatPassword.required"))
        .oneOf([yup.ref("password")], t("repeatPassword.validation")),
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputsFirst>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const { error, isLoading } = useTypedSelector((state) => state.auth);
  const { registrationFirstStep } = useActions();
  const [inputPass, setInputPass] = useState("password");
  const [inputRePass, setInputRePass] = useState("password");
  const [contractOffer, setContractOffer] = useState(false);

  const onSubmit: SubmitHandler<IFormInputsFirst> = async (
    data: IFormInputsFirst
  ) => {
    setUser(data);
    const result = await registrationFirstStep(data);
    if (!!result) {
      setPage((prevValue: number) => prevValue + 1);
    }
  };

  const handleChangePassword = () => {
    inputPass === "password" ? setInputPass("text") : setInputPass("password");
  };

  const handleChangeRePassword = () => {
    inputRePass === "password"
      ? setInputRePass("text")
      : setInputRePass("password");
  };

  return (
    <form onSubmitCapture={handleSubmit(onSubmit)} className={styles.form}>
      <div className={styles.formWrapper}>
        <h3 className={styles.formTitle}>{t("title")}</h3>
        <label className={styles.label} htmlFor="name">
          {t("fullName.label")}
          <CustomInput
            name="name"
            type="text"
            inputClassName={styles.input}
            register={register("full_name")}
            isError={!!errors.full_name && !!errors.full_name?.message}
            errorText={errors.full_name?.message}
          />
        </label>
        <label className={styles.label} htmlFor="email">
          {t("email.label")}
          <CustomInput
            name="email"
            type="email"
            inputClassName={styles.input}
            register={register("email")}
            isError={!!errors.email && !!errors.email?.message}
            errorText={errors.email?.message}
            defaultValue={user?.email}
          />
        </label>
        <label className={styles.label} htmlFor="password">
          {t("enterPassword.label")}
          <CustomInput
            name="password"
            type={inputPass as inputTypes}
            inputClassName={styles.input}
            register={register("password")}
            isError={!!errors.password && !!errors.password?.message}
            errorText={errors.password?.message}
            autoComplete="new-password"
          >
            <button
              type="button"
              className={styles.eyesButton}
              onClick={handleChangePassword}
            >
              {inputPass === "password" ? (
                <img src={CloseEye} alt="CloseEye" />
              ) : (
                <img src={OpenEye} alt="OpenEye" />
              )}
            </button>
          </CustomInput>
        </label>
        <label className={styles.label} htmlFor="rePassword">
          {t("repeatPassword.label")}
          <CustomInput
            name="rePassword"
            type={inputRePass as inputTypes}
            inputClassName={styles.input}
            register={register("re_password")}
            isError={!!errors.re_password && !!errors.re_password?.message}
            errorText={errors.re_password?.message}
          >
            <button
              type="button"
              className={styles.eyesButton}
              onClick={handleChangeRePassword}
            >
              {inputRePass === "password" ? (
                <img src={CloseEye} alt="CloseEye" />
              ) : (
                <img src={OpenEye} alt="OpenEye" />
              )}
            </button>
          </CustomInput>
        </label>
        {error && <span className={styles.errorMsg}>{error}</span>}
      </div>
      <p className={css.contractOfferText}>
        {t("contractOfferText.firstPart")}{" "}
        <a
          href="https://aimap.aimarketing.info/uk/terms-of-service"
          className={css.contractOfferButton}
          target="_blank"
          rel="noreferrer"
        >
          {t("contractOfferText.secondPart")}
        </a>
        .
      </p>
      {contractOffer && (
        <ContractOffer
          contractOffer={contractOffer}
          setContractOffer={setContractOffer}
        />
      )}
      <div>
        <button
          className={styles.formSubmitButton}
          type="submit"
          disabled={isLoading}
        >
          {t("submitBtn")}
          {isLoading ? <ButtonLoader /> : <img src={ArrowRight} alt="next" />}
        </button>
      </div>
    </form>
  );
};

export default RegistrationForm;
