import { Progress } from "antd";
import React from "react";
import { colors } from "../../constants";

const SimulatedProgressBar = () => {
  const [progress, setProgress] = React.useState(1);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev < 90) {
          // return prev +
          return prev + Math.floor(Math.random() * 10);
        }
        clearInterval(interval);
        return prev;
      });
    }, 1200);
    return () => clearInterval(interval);
  }, []);

  return <Progress percent={progress} strokeColor={colors.redR400} />;
};

export default SimulatedProgressBar;
