import {
  IMapCompanyInfoState,
  CompaniesMapActionsEnum,
  SetMapCompaniesAction,
  SetMapCompanyAction,
} from "./types";
import { AppDispatch, RootState, store } from "../../../../index";
import { ICurrentCompany } from "../../../../../models/CurrentCompany";
import { IReqCompanies } from "../../../../../models/CompanyObject";
import { companyService } from "../../../../api/CompanyService";
import { MapInterfaceActionCreator } from "../../mapInterface/action.creators";
import { limit } from "../../../../../constants/mapConstants";

export const CompanyMapObjectActionCreators = {
  setMapCompany: (mapCompany: ICurrentCompany): SetMapCompanyAction => ({
    type: CompaniesMapActionsEnum.SET_MAP_COMPANY,
    payload: mapCompany,
  }),
  getCurrentMapCompany:
    (RECORD: string, account_id: number) => async (dispatch: AppDispatch) => {
      try {
        dispatch(MapInterfaceActionCreator.setIsLoading(true));

        const response = await companyService.fetchMapCompany(
          RECORD,
          account_id
        );
        if (response) {
          dispatch(CompanyMapObjectActionCreators.setMapCompany(response.data));
        }
        dispatch(MapInterfaceActionCreator.setIsLoading(false));
      } catch (e: string | any) {
        dispatch(
          MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
        );
      }
    },
  setMapCompaniesInfo: (
    mapCompanies: IMapCompanyInfoState
  ): SetMapCompaniesAction => ({
    type: CompaniesMapActionsEnum.SET_MAP_COMPANIES,
    payload: mapCompanies,
  }),

  getDefaultCompanyResult: () => async (dispatch: AppDispatch) => {
    const data: IReqCompanies = {
      account_id: store.getState().currentAccount.id,
      limit: 20,
      offset: store.getState().mapInterface.offset,
      lon: store.getState().mapInterface.viewport.longitude
        ? store.getState().mapInterface.viewport.longitude
        : undefined,
      lat: store.getState().mapInterface.viewport.latitude
        ? store.getState().mapInterface.viewport.latitude
        : undefined,
      status: (store.getState().companyFilters as any).stanFilterArray
        ? (store.getState().companyFilters as any).stanFilterArray
        : ([] as string[]),
      date_added__lte:
        store.getState().companyFilters &&
        store.getState().companyFilters.date_added__lte
          ? store.getState().companyFilters.date_added__lte
          : "",
      date_added__gte: (store.getState().companyFilters as any)?.date_added__gte
        ? (store.getState().companyFilters as any).date_added__gte
        : "",
      export__range: store.getState().companyFilters.export__range
        ? store.getState().companyFilters.export__range
        : "",
      import__range: store.getState().companyFilters.import__range
        ? store.getState().companyFilters.import__range
        : "",
      edrpou: store.getState().companyFilters.edrpou
        ? store.getState().companyFilters.edrpou
        : undefined,
      region: store.getState().companyFilters.region
        ? store.getState().companyFilters.region
        : undefined,
      kved: store.getState().companyFilters.kved
        ? store.getState().companyFilters.kved
        : undefined,
      kved_is_main:
        store.getState().companyFilters.kved &&
        store.getState().companyFilters.kved_is_main
          ? 1
          : undefined,
      income__range: store.getState().companyFilters.income__range
        ? store.getState().companyFilters.income__range
        : undefined,
    };
    try {
      dispatch(MapInterfaceActionCreator.setIsLoading(true));
      const response = await companyService.fetchMapCompanies(data);
      if (response) {
        dispatch(
          CompanyMapObjectActionCreators.setMapCompaniesInfo(response.data)
        );
      }
      dispatch(MapInterfaceActionCreator.setIsLoading(false));
    } catch (e: string | any) {
      dispatch(
        MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
      );
    }
  },

  refreshFeedForCompaniesSearchResults: () => async (dispatch: AppDispatch) => {
    const prevData: IMapCompanyInfoState = (store.getState() as RootState)
      .mapCompanyInfo;
    const { isLoading } = (store.getState() as RootState).mapInterface;

    if (isLoading) return;

    if (prevData.next === null) return;

    const page = (store.getState() as RootState).mapInterface.page;
    dispatch(MapInterfaceActionCreator.setPage(page + 1));

    const data: IReqCompanies = {
      account_id: store.getState().currentAccount.id,
      limit: limit,
      offset: page * limit,
      lon: store.getState().mapInterface.viewport.longitude
        ? store.getState().mapInterface.viewport.longitude
        : undefined,
      lat: store.getState().mapInterface.viewport.latitude
        ? store.getState().mapInterface.viewport.latitude
        : undefined,
      status: (store.getState().companyFilters as any).stanFilterArray
        ? (store.getState().companyFilters as any).stanFilterArray
        : ([] as string[]),
      date_added__lte:
        store.getState().companyFilters &&
        store.getState().companyFilters.date_added__lte
          ? store.getState().companyFilters.date_added__lte
          : "",
      date_added__gte: (store.getState().companyFilters as any)?.date_added__gte
        ? (store.getState().companyFilters as any).date_added__gte
        : "",
      export__range: store.getState().companyFilters.export__range
        ? store.getState().companyFilters.export__range
        : "",
      import__range: store.getState().companyFilters.import__range
        ? store.getState().companyFilters.import__range
        : "",
      edrpou: store.getState().companyFilters.edrpou
        ? store.getState().companyFilters.edrpou
        : undefined,
      region: store.getState().companyFilters.region
        ? store.getState().companyFilters.region
        : undefined,
      kved: store.getState().companyFilters.kved
        ? store.getState().companyFilters.kved
        : undefined,
      kved_is_main:
        store.getState().companyFilters.kved &&
        store.getState().companyFilters.kved_is_main
          ? 1
          : undefined,
      income__range: store.getState().companyFilters.income__range
        ? store.getState().companyFilters.income__range
        : undefined,
    };
    try {
      dispatch(MapInterfaceActionCreator.setIsLoading(true));
      const response = await companyService.fetchMapCompanies(data);
      if (response) {
        const nextData: IMapCompanyInfoState = response.data;

        const composedData: IMapCompanyInfoState = {
          ...prevData,
          ...nextData,
          companies: [...prevData.companies, ...nextData.companies],
        };

        dispatch(
          CompanyMapObjectActionCreators.setMapCompaniesInfo(composedData)
        );
      }
      dispatch(MapInterfaceActionCreator.setIsLoading(false));
    } catch (e: string | any) {
      dispatch(
        MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
      );
    }
  },
};
