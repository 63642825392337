import { MapConfig } from "../store/reducers/_maps/main/map/types";
import { CircleLayer } from "mapbox-gl";

export const isDevelopment = process.env.NODE_ENV !== "production";

export const currencies = {
  uah: "UAH",
  dollar: "USD",
};

export const allowedCCvalues = ["1", "2", "3"];

export enum AvailableCC {
  ua = "ua",
  pl = "pl",
  no = "no",
  fr = "fr",
  en = "en",
}

export const allowedLanguageArray = ["ua", "pl", "no", "fr", "en"];

export const privateValue = "#####";
export const unknownValue = "Не визначено";

export const mapPointStyle: Partial<CircleLayer> = {
  id: "point",
  type: "circle",
  paint: {
    "circle-radius": 5,
    "circle-color": "#242731",
    "circle-stroke-width": 2,
    "circle-stroke-color": "#FFFFFF",
  },
};

export const OBJECTS_MAP_CONFIG: Map<AvailableCC, MapConfig> = new Map([
  [
    AvailableCC.ua,
    {
      countryCode: AvailableCC.ua,
      countryName: "country.Ukraine",
      mapStyle: "mapbox://styles/aimap/clrueu8np004q01ph7syddpkf",
      viewport: { latitude: 50.4501, longitude: 30.5234, zoom: 5, minZoom: 6 },
      sources: [
        {
          url: "mapbox://aimap.ua_objects_ts",
          layer: "ua_objects_layer",
          layer_features: "ua-objects-layer",
          minzoom: 5,
          maxzoom: 22,
        },
        // {
        //   url: "mapbox://aimap.ua_objects_ts",
        //   layer: "ua_objects_layer",
        //   layer_features: "ua-objects-layer",
        //   minzoom: 9.8,
        //   maxzoom: 22,
        //   style: {
        //     ...mapPointStyle,
        //     id: "point2",
        //   },
        // },
      ],
      filters: {
        types: true,
        cc: true,
        date: true,
        other: true,
      },
    },
  ],
  [
    AvailableCC.pl,
    {
      countryCode: AvailableCC.pl,
      countryName: "country.Poland",
      mapStyle: "mapbox://styles/aimap/cl2xasd4100qx14ksse53zc5r",
      viewport: { latitude: 51.996, longitude: 20.606, zoom: 5, minZoom: 7 },
      sources: [
        {
          url: "mapbox://aimap.pl_permit_docs_ts",
          layer: "pl_permit_docs_layer",
          layer_features: "pl-permit-docs-layer",
        },
      ],
      filters: {
        types: true,
        cc: true,
        date: true,
        other: true,
      },
    },
  ],
  [
    AvailableCC.fr,
    {
      countryCode: AvailableCC.fr,
      countryName: "country.France",
      mapStyle: "mapbox://styles/aimap/cl5wgp8o2003914mmwtuxy9o1",
      viewport: {
        latitude: 46.73679133076939,
        longitude: 4.424830510732827,
        zoom: 5,
        minZoom: 7,
      },
      sources: [
        {
          url: "mapbox://aimap.fr_permit_docs_ts",
          layer: "fr_permit_docs_layer",
          layer_features: "fr-permit-docs-layer",
        },
      ],
      filters: {
        types: true,
        cc: false,
        date: true,
        other: true,
      },
    },
  ],
  [
    AvailableCC.no,
    {
      countryCode: AvailableCC.no,
      countryName: "country.Norway",
      mapStyle: "mapbox://styles/aimap/cl5gh627q001414my88tt951f",
      viewport: { latitude: 59.971491, longitude: 10.757933, zoom: 10 },
      sources: [
        {
          url: "mapbox://aimap.no_objects_ts_2",
          layer: "pl_permit_docs_layer",
          layer_features: "pl-permit-docs-layer",
        },
      ],
      filters: {
        types: true,
        cc: false,
        date: true,
        other: true,
      },
    },
  ],
  [
    AvailableCC.en,
    {
      countryCode: AvailableCC.en,
      countryName: "country.UK",
      mapStyle: "mapbox://styles/aimap/cl32s0qba000c14mru4pu3xja",
      viewport: { latitude: 50.4501, longitude: 30.5234, zoom: 5 },
      sources: [
        {
          url: "mapbox://aimap.en_objects_ts_2",
          layer: "en_permit_docs_layer",
          layer_features: "en-permit-docs-layer",
        },
      ],
      filters: {
        types: true,
        cc: true,
        date: true,
        other: true,
      },
    },
  ],
]);

export const suggestionTypes = {
  region: "Регіон",
  kved: "КВЕД",
  name: "Назва",
  np: "Фізична особа",
  jp: "Юридична особа",
  dkbs: "Код ДКБС",
};

export const apiUrl = process.env.REACT_APP_API_URL;

export const maxSquare = 246000;
export const europeanCountries = ["UA", "PL", "NO", "FR"];

// consts from subsctions:
// Моя карта2!
// Default card
// FREE
// Default card *
// Регіональна
// Професійна
// AIMVL
// Default subscribe
// Регіональний
// Professional

export const subscriptionTypes = {
  "Моя карта2!": "Моя карта2!",
  "Default card": "Default card",
  FREE: "FREE",
  "Default card *": "Default card *",
  Регіональна: "Регіональна",
  Професійна: "Професійна",
  AIMVL: "AIMVL",
  "Default subscribe": "Default subscribe",
  Регіональний: "Регіональний",
  Professional: "Professional",
};

// Професійна - 10к
// Регіональна - 1к
// У адмінських (AIMVL, моя карта2!) - 100к
// else 0
export const subscriptionTypeToMaxDownloadLimit = {
  [subscriptionTypes.FREE]: 0,
  [subscriptionTypes["Default card"]]: 0,
  [subscriptionTypes["Default card *"]]: 0,
  [subscriptionTypes["Default subscribe"]]: 0,
  [subscriptionTypes.Регіональна]: 1000,
  [subscriptionTypes.Регіональний]: 1000,
  [subscriptionTypes.Професійна]: 10000,
  [subscriptionTypes.Professional]: 10000,
  [subscriptionTypes.AIMVL]: 100000,
  [subscriptionTypes["Моя карта2!"]]: 100000,
};
