import {
  FormContainer,
  FormSection,
} from "../../../share/formComponents/formComponents";
import { Alert, Col, Form, Input, Row, notification } from "antd";
import CustomButton from "../../../share/Button/Button";
import { useState } from "react";
import { userService } from "../../../store/api/UserService";
import { useTranslation } from "react-i18next";

export interface IChangePassword {
  new_password: string;
  current_password: string;
}

enum ChangePasswordEnum {
  new_password = "new_password",
  current_password = "current_password",
}

const ChangePasswordForm = () => {
  const [hasFormChanged, setHasFormChanged] = useState<boolean>(false);
  const [form] = Form.useForm();

  const { t: tNotification } = useTranslation("translation", {
    keyPrefix: "profilePage.notification",
  });
  const { t: tPassword } = useTranslation("translation", {
    keyPrefix: "profilePage.passwordForm",
  });

  const [notificationApi, contextHolder] = notification.useNotification();
  const [errorMessage, setErrorMessage] = useState<string | false>("");
  const showSuccessMessage = () => {
    notificationApi.success({
      message: tNotification("passwordUpdateMessage"),
      description: tNotification("passwordUpdateDescription"),
      placement: "bottomLeft",
    });
  };

  const handleSubmit = async (formValues: IChangePassword) => {
    try {
      await userService.authChangePassword(formValues);
      setErrorMessage(false);
      showSuccessMessage();
    } catch (e: any) {
      setErrorMessage(e?.response?.data?.detail as string);
    }
  };

  return (
    <FormContainer>
      {contextHolder}
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onValuesChange={() => {
          setHasFormChanged(true);
        }}
      >
        <FormSection headerText={tPassword("title")}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name={ChangePasswordEnum.current_password}
                label={tPassword("currentPasswordLabel")}
                rules={[
                  {
                    required: true,
                    message: tPassword("currentPasswordMessage") || "",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={ChangePasswordEnum.new_password}
                label={tPassword("newPasswordLabel")}
                rules={[
                  {
                    required: true,
                    message: tPassword("newPasswordMessage") || "",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          {errorMessage && (
            <Alert message={errorMessage} type="error" showIcon />
          )}
        </FormSection>
        <CustomButton
          size={"medium"}
          htmlType="submit"
          type="filled"
          disabled={!hasFormChanged}
        >
          {tPassword("submit")}
        </CustomButton>
      </Form>
    </FormContainer>
  );
};

export default ChangePasswordForm;
