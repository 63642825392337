import SimplePage from "../../share/Page/SimplePage";
import { useTranslation } from "react-i18next";
import SupportNavigation from "./SupportNavigation";
import styled from "@emotion/styled";
import FeedbackForm from "../../components/FeedbackPage/FeedbackForm";
import {
  RedText,
  Text16,
  Title36,
} from "../../share/textComponents/textComponents";
import { breakpoints, colors } from "../../constants";
import { Popover } from "antd";
import { useState } from "react";
import { RouteNames } from "../../routes";
import { useNavigate } from "react-router-dom";
import LinkText from "../../components/LinkText/LinkText";

const StyledFeedbackPageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  gap: 20px;
  @media (max-width: ${breakpoints.small}px) {
    grid-template-columns: 1fr;
  }
`;

const StyledMainContainer = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  max-width: 800px;
`;

const StyledTitle36 = styled(Title36)`
  margin-bottom: 24px;
`;

const ParagraphsContainer = styled.div`
  display: grid;
  gap: 8px;
  margin-bottom: 32px;
`;

const StyledFormContainer = styled.div`
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${colors.darkD100};
  @media (max-width: ${breakpoints.small}px) {
    padding: 0;
    border: none;
  }
`;
const FeedbackPage = () => {
  const { t } = useTranslation();
  const header = t("feedbackPage.header");
  const paragraph1 = t("feedbackPage.paragraph1");

  const paragraph2 = t("feedbackPage.paragraph2", {
    contactUs: () => "<a> dd </a>",
    returnObjects: true,
  });

  const title = t("feedbackPage.title");
  const submitHeader = t("feedbackPage.feedbackSubmitted");
  const thanksForFeedback = t("feedbackPage.thanksForFeedback");

  const contactsPopoverContent = (
    <div>
      <p>
        {t("phone")}: <a href="tel:+3806601067595">+3806601067595</a>
      </p>
      <p>
        {t("email")}: <a href="mailto:support@aimapa.com">support@aimapa.com</a>
      </p>
    </div>
  );

  // const isSubmitted state

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleFeedbackSubmitSuccess = () => {
    setIsSubmitted(true);
  };
  const navigate = useNavigate();

  const handleGotoMain = () => {
    navigate(RouteNames.AIMAP);
  };
  return (
    <SimplePage title={title}>
      <StyledFeedbackPageGrid>
        <SupportNavigation />
        <StyledMainContainer>
          <StyledTitle36>{isSubmitted ? submitHeader : header}</StyledTitle36>
          <ParagraphsContainer>
            {isSubmitted ? (
              <Text16>{thanksForFeedback}</Text16>
            ) : (
              <>
                <Text16>{paragraph1}</Text16>
                <Text16>
                  {paragraph2},&ensp;
                  <Popover
                    content={contactsPopoverContent}
                    title="Contact Information"
                    trigger="click"
                  >
                    <RedText>{t("feedbackPage.contactUs")}</RedText>.
                  </Popover>
                </Text16>
              </>
            )}
          </ParagraphsContainer>

          {isSubmitted && (
            <LinkText onClick={handleGotoMain}>
              {t("feedbackPage.feedbackForm.goToMain")}
            </LinkText>
          )}
          {!isSubmitted && (
            <StyledFormContainer>
              <FeedbackForm
                isFormSubmitted={isSubmitted}
                setIsFormSubmitted={setIsSubmitted}
                onSuccess={handleFeedbackSubmitSuccess}
              />
            </StyledFormContainer>
          )}
        </StyledMainContainer>
      </StyledFeedbackPageGrid>
    </SimplePage>
  );
};

export default FeedbackPage;
