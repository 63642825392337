import { UploadFile } from "antd";

export enum HubspotActionsEnum {
  SET_IS_POSTING_FEEDBACK_LOADING = "SET_IS_POSTING_FEEDBACK_LOADING",
  SET_ERROR = "SET_HUBSPOT_ERROR",
}
export type ReportTopic =
  | "Account & Profile"
  | "User Management"
  | "Objects Map"
  | "Filtering and Search"
  | "JP Map"
  | "NP Map"
  | "Error page"
  | "Objects Information"; // ✔

export type ReportType = "bug" | "feature"; // ✔

export type HSTicketPriority = "HIGH" | "MEDIUM" | "LOW"; // ✔

// Define your form values
export type IFeedbackFormValues = {
  email: string;
  ticketName: string;
  reportType: ReportType;
  reportTopic: ReportTopic;
  description: string;
  attachments?: UploadFile[];
};
