import {
  edrpou,
  kved,
  entity_type,
  economic_sector,
  region,
  ISelectedDocList,
  activity_status,
  category,
} from "../../../../../models/USAIDObject";

import { ISearchResponse } from "../../../../../models/Search";

export interface MapUsaidFiltersState {
  isSuggestionsLoading: boolean;
  selectedDocList: ISelectedDocList[];
  suggestions: ISearchResponse[];
  activeSuggestions: ISearchResponse[];
  edrpou: edrpou[];
  region: region[];
  kved: kved[];
  entity_type_array: entity_type[];
  economic_sector_array: economic_sector[];
  activity_status_array: activity_status[];
  category_array: category[];
  lat_border__range: any;
  activity_status_2022: any;
  lon_border__range: any;
  dk_018_2000: string[];
}

export enum MapFilterActionsEnum {
  SET_SUGGESTION_LOADING = "SET_SUGGESTION_LOADING",
  SET_USAID_CATEGORY = "SET_USAID_CATEGORY",
  DELETE_USAID_CATEGORY = "DELETE_USAID_CATEGORY",
  CLEAN_USAID_CATEGORY = "CLEAN_USAID_CATEGORY",
  SET_DOC_LIST = "SET_DOC_LIST",
  SET_USAID_SUGGESTION = "SET_USAID_SUGGESTION",
  SET_USAID_ACTIVE_SUGGESTION = "SET_USAID_ACTIVE_SUGGESTION",
  DELETE_USAID_ACTIVE_SUGGESTION = "DELETE_USAID_ACTIVE_SUGGESTION",
  CLEAN_USAID_SUGGESTION = "CLEAN_USAID_SUGGESTION",
  SET_USAID_REGION = "SET_USAID_REGION",
  CLEAN_USAID_REGION = "CLEAN_USAID_REGION",
  DELETE_USAID_REGION = "DELETE_USAID_REGION",
  SET_USAID_EDR_CODE = "SET_USAID_EDR_CODE",
  CLEAN_USAID_EDR_CODE = "CLEAN_USAID_EDR_CODE",
  DELETE_USAID_EDR_CODE = "DELETE_USAID_EDR_CODE",
  SET_USAID_KVED = "SET_USAID_KVED",
  CLEAN_USAID_KVED = "CLEAN_USAID_KVED",
  DELETE_USAID_KVED = "DELETE_USAID_KVED",
  SET_USAID_ECONOMIC_SECTOR = "SET_USAID_ECONOMIC_SECTOR",
  CLEAN_USAID_ECONOMIC_SECTOR = "CLEAN_USAID_ECONOMIC_SECTOR",
  DELETE_USAID_ECONOMIC_SECTOR = "DELETE_USAID_ECONOMIC_SECTOR",
  DELETE_USAID_ACTIVITY_STATUS = "DELETE_USAID_ECONOMIC_SECTOR",
  SET_USAID_ACTIVITY_STATUS = "SET_USAID_ACTIVITY_STATUS",
  CLEAN_USAID_ACTIVITY_STATUS = "CLEAN_USAID_ACTIVITY_STATUS",
  DELETE_USAID_ENTITY_TYPE = "DELETE_USAID_ENTITY_TYPE",
  SET_USAID_ENTITY_TYPE = "SET_USAID_ENTITY_TYPE",
  CLEAN_USAID_ENTITY_TYPE = "CLEAN_USAID_ENTITY_TYPE",
}

// SetSuggestionLoadingAction
export interface SetIsSuggestionsLoadingAction {
  type: MapFilterActionsEnum.SET_SUGGESTION_LOADING;
  payload: boolean;
}

export interface SetSelectedDocList {
  type: MapFilterActionsEnum.SET_DOC_LIST;
  payload: ISelectedDocList[];
}

export interface SetUSAIDSuggestion {
  type: MapFilterActionsEnum.SET_USAID_SUGGESTION;
  payload: ISearchResponse[];
}

export interface SetUSAIDActiveSuggestion {
  type: MapFilterActionsEnum.SET_USAID_ACTIVE_SUGGESTION;
  payload: ISearchResponse;
}

export interface DeleteUSAIDActiveSuggestion {
  type: MapFilterActionsEnum.DELETE_USAID_ACTIVE_SUGGESTION;
  payload: ISearchResponse;
}

export interface SetUSAIDCleanSuggestion {
  type: MapFilterActionsEnum.CLEAN_USAID_SUGGESTION;
  payload: [];
}

export interface SetUSAIDRegion {
  type: MapFilterActionsEnum.SET_USAID_REGION;
  payload: string;
}

export interface SetUSAIDCleanRegion {
  type: MapFilterActionsEnum.CLEAN_USAID_REGION;
  payload: [];
}

export interface DeleteUSAIDRegion {
  type: MapFilterActionsEnum.DELETE_USAID_REGION;
  payload: string;
}

export interface SetUSAIDEdrCode {
  type: MapFilterActionsEnum.SET_USAID_EDR_CODE;
  payload: string;
}

export interface SetCleanUSAIDEdrCode {
  type: MapFilterActionsEnum.CLEAN_USAID_EDR_CODE;
  payload: [];
}

export interface DeleteUSAIDEdrCode {
  type: MapFilterActionsEnum.DELETE_USAID_EDR_CODE;
  payload: string;
}

export interface SetUSAIDKVED {
  type: MapFilterActionsEnum.SET_USAID_KVED;
  payload: string;
}

export interface SetCleanUSAIDKVED {
  type: MapFilterActionsEnum.CLEAN_USAID_KVED;
  payload: [];
}

export interface DeleteUSAIDKVED {
  type: MapFilterActionsEnum.DELETE_USAID_KVED;
  payload: string;
}

export interface SetUSAIDEconomicSector {
  type: MapFilterActionsEnum.SET_USAID_ECONOMIC_SECTOR;
  payload: string;
}

export interface SetCleanUSAIDEconomicSector {
  type: MapFilterActionsEnum.CLEAN_USAID_ECONOMIC_SECTOR;
  payload: [];
}

export interface DeleteUSAIDEconomicSector {
  type: MapFilterActionsEnum.DELETE_USAID_ECONOMIC_SECTOR;
  payload: string;
}

export interface SetUSAIDActivityStatus {
  type: MapFilterActionsEnum.SET_USAID_ACTIVITY_STATUS;
  payload: string;
}

export interface SetCleanUSAIDActivityStatus {
  type: MapFilterActionsEnum.CLEAN_USAID_ACTIVITY_STATUS;
  payload: [];
}

export interface DeleteUSAIDActivityStatus {
  type: MapFilterActionsEnum.DELETE_USAID_ACTIVITY_STATUS;
  payload: string;
}

export interface SetUSAIDEntityType {
  type: MapFilterActionsEnum.SET_USAID_ENTITY_TYPE;
  payload: string;
}

export interface SetCleanUSAIDEntityType {
  type: MapFilterActionsEnum.CLEAN_USAID_ENTITY_TYPE;
  payload: [];
}

export interface DeleteUSAIDEntityType {
  type: MapFilterActionsEnum.DELETE_USAID_ENTITY_TYPE;
  payload: string;
}

export interface SetUSAIDCategory {
  type: MapFilterActionsEnum.SET_USAID_CATEGORY;
  payload: string;
}

export interface SetCleanUSAIDCategory {
  type: MapFilterActionsEnum.CLEAN_USAID_CATEGORY;
  payload: [];
}

export type MapFiltersAction =
  | SetIsSuggestionsLoadingAction
  | SetSelectedDocList
  | SetUSAIDSuggestion
  | SetUSAIDActiveSuggestion
  | DeleteUSAIDActiveSuggestion
  | SetUSAIDCleanSuggestion
  | SetUSAIDRegion
  | SetUSAIDCleanRegion
  | DeleteUSAIDRegion
  | SetUSAIDEdrCode
  | SetCleanUSAIDEdrCode
  | DeleteUSAIDEdrCode
  | SetUSAIDKVED
  | SetCleanUSAIDKVED
  | DeleteUSAIDKVED
  | SetUSAIDEconomicSector
  | SetCleanUSAIDEconomicSector
  | DeleteUSAIDEconomicSector
  | SetUSAIDActivityStatus
  | SetCleanUSAIDActivityStatus
  | DeleteUSAIDActivityStatus
  | SetUSAIDEntityType
  | SetCleanUSAIDEntityType
  | DeleteUSAIDEntityType
  | SetUSAIDCategory
  | SetCleanUSAIDCategory;
