import { FC, useRef } from "react";
import SavedFiltersButton from "./SavedFiltersButton/SavedFiltersButton";
import useOutsideClick from "../../../hooks/useClickOutside";
import SavedFiltersModal from "./SavedFiltersModal";
import styled from "@emotion/styled";
import useIsFilterActive from "../../../hooks/useIsFilterActive";
import { FilterIdsEnum } from "../../../store/reducers/_maps/main/mapFilters/types";
import { useActions } from "../../../hooks/useActions";

const StyledSavedFilters = styled.div`
  position: relative;
`;

interface Props {
  isDisabled: boolean;
}
const SavedFilters: FC<Props> = ({ isDisabled = true }) => {
  const dropdownElementRef = useRef<HTMLDivElement>(null);
  const templateListModalId: FilterIdsEnum = "savedFiltersListModal";
  const isListShown = useIsFilterActive(templateListModalId);
  const { toggleFilter } = useActions();

  useOutsideClick(dropdownElementRef, () => toggleFilter(""));

  const onClose = () => {
    toggleFilter("");
  };

  const handleToggle = () => {
    toggleFilter(templateListModalId);
  };

  return (
    <StyledSavedFilters>
      <SavedFiltersButton
        isDisabled={isDisabled}
        isActive={isListShown}
        onClick={handleToggle}
      />
      <SavedFiltersModal isShown={isListShown} onClose={onClose} />
    </StyledSavedFilters>
  );
};

export default SavedFilters;
