import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { useTranslation } from "react-i18next";
import { FilterIdsEnum } from "../../../../../store/reducers/_maps/main/mapFilters/types";
import FilterButtonWithModal from "../../../../../share/filters/FilterButtonWithModal";
import CCFilterBody from "./CCFilterBody";
import useMobileScreen from "../../../../../hooks/useMobileScreen";
import { breakpoints } from "../../../../../constants";

interface Props {
  disabled?: boolean;
}
const CCFilter: React.FC<Props> = ({ disabled }) => {
  const filterId: FilterIdsEnum = "cc";

  const { ccFilterArray } = useTypedSelector((state) => state.mapFilters);

  const { typeCCClean } = useActions();

  const handleClean = () => {
    typeCCClean();
  };

  const isTablet = useMobileScreen(breakpoints.medium);
  const { t: tCC } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.aimap.consequencesFilter",
  });

  const title = tCC("title");
  const pinText =
    ccFilterArray?.length > 0 ? ccFilterArray.length.toString() : "";
  const infoText = tCC("explanation");

  return (
    <FilterButtonWithModal
      title={title}
      pinText={pinText}
      filterId={filterId}
      description={infoText}
      onClear={handleClean}
      width={400}
      left={isTablet ? 0 : -40}
    >
      <CCFilterBody />
    </FilterButtonWithModal>
  );
};

export default CCFilter;
