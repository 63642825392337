import styled from "@emotion/styled";
import React, { FC, ReactNode } from "react";
import { icons } from "../../constants";

const StyledMoreButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 24px;
`;

const StyledMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
`;

const StyledMoreButtonImg = styled.img`
  margin-left: 8px;
`;

interface IShowMoreButton {
  onClick?: () => void;
  opened?: boolean;
  disabled?: boolean;
  count?: number;
  children?: ReactNode | ReactNode[];
}

const ShowMoreButton: FC<IShowMoreButton> = ({
  onClick,
  opened = false,
  disabled = false,
  count = 0,
  children,
}) => {
  if (count === 0 || !children) {
    return null;
  }
  if (!children) {
    return null;
  }
  return (
    <StyledMoreButtonWrapper>
      <StyledMoreButton onClick={!!onClick && onClick}>
        {!!children ? children : "Show more"}

        {count > 0 && <span>&bull; {count}</span>}

        {disabled ? (
          <StyledMoreButtonImg src={icons.ArrowDownDisable} alt="ArrowDown" />
        ) : opened ? (
          <StyledMoreButtonImg src={icons.ArrowUp} alt="ArrowUp" />
        ) : (
          <StyledMoreButtonImg src={icons.ArrowDown} alt="ArrowDown" />
        )}
      </StyledMoreButton>
    </StyledMoreButtonWrapper>
  );
};

export default ShowMoreButton;
