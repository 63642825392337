import React from "react";
import { MobileMenuDrawer } from "../../../share/MobileMenuDrawer/MobileMenuDrawer";
import UserProfileModal from "../../UserProfileBar/UserProfileModal/UserProfileModal";
import useMobileScreen from "../../../hooks/useMobileScreen";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const MobileHamburgerMenu: React.FC<Props> = ({ isOpen, onClose }) => {
  const isMobile = useMobileScreen();
  if (!isMobile) {
    return null;
  }

  return (
    <>
      <MobileMenuDrawer isShown={isOpen} onClose={onClose}>
        <UserProfileModal />
      </MobileMenuDrawer>
    </>
  );
};

export default MobileHamburgerMenu;
