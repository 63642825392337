import React, { useEffect, useState } from "react";
import styles from "./RepeatButton.module.scss";
import { IFormInputsFirst } from "../../../pages/_auth/Registration/RegistrationFirstStep/RegistrationForm";
import ArrowRepeat from "../../../assets/svg/arrowRepeat.svg";
import ArrowRepeatDisable from "../../../assets/svg/arrowrRepeatDisable.svg";
import { useForm } from "react-hook-form";
import { useActions } from "../../../hooks/useActions";
import { IFormPassRec } from "../../../pages/_auth/PasswordRecovery/PassRecFirstPage/PassRecFirstPage";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProps {
  user?: IFormInputsFirst;
  resetPassword?: IFormPassRec;
}

const initialTimer = {
  initialMinute: 1,
  initialSeconds: 59,
};

const RepeatButton = ({ user, resetPassword }: IProps) => {
  const location = useLocation();
  const { handleSubmit } = useForm();
  const { registrationFirstStep, passRecFirstStep } = useActions();
  const [minutes, setMinutes] = useState(initialTimer.initialMinute);
  const [seconds, setSeconds] = useState(initialTimer.initialSeconds);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const registration = async (user: IFormInputsFirst) => {
    await registrationFirstStep(user);
    setMinutes(1);
    setSeconds(59);
  };

  const password = async (email: string | undefined) => {
    await passRecFirstStep(email);
    setMinutes(1);
    setSeconds(59);
  };

  const { t } = useTranslation("translation", {
    keyPrefix: "config",
  });

  return (
    <>
      {minutes === 0 && seconds === 0 ? (
        <div className={styles.handleRepeatWrapper}>
          {location.pathname === "/user_reg" ? (
            <form
              onSubmit={handleSubmit(() =>
                registration(user as IFormInputsFirst)
              )}
            >
              <button type="submit" className={styles.repeatButton}>
                <img src={ArrowRepeat} alt="Repeat" />
                <p className={styles.repeatButtonText}>{t("repeatBtn")}</p>
              </button>
            </form>
          ) : (
            <form onSubmit={handleSubmit(() => password(resetPassword?.email))}>
              <button type="submit" className={styles.repeatButton}>
                <img src={ArrowRepeat} alt="Repeat" />
                <p className={styles.repeatButtonText}>{t("repeatBtn")}</p>
              </button>
            </form>
          )}
        </div>
      ) : (
        <div className={styles.handleRepeatWrapper}>
          <button disabled className={styles.repeatButton}>
            <img src={ArrowRepeatDisable} alt="Repeat" />
            <span>
              {`0${minutes}`}:{seconds < 10 ? `0${seconds}` : seconds}
            </span>
            <p>{t("repeatBtn")}</p>
          </button>
        </div>
      )}
    </>
  );
};

export default RepeatButton;
