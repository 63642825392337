// FormComponent.jsx
import { Form, Select, Input, Button, message, Upload, Row, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadFile } from "antd";
import { useTranslation } from "react-i18next";
// Make sure to import axios if you're going to use it for HTTP requests
import { useActions } from "../../../hooks/useActions";
import { useEffect, useState } from "react";
import { store } from "../../../store";
import { useLogger } from "../../../contexts/LoggerContext";
import { IFeedbackFormValues } from "../../../store/reducers/feedback/types";
import {
  reportTopics,
  reportTypes,
} from "../../../store/reducers/feedback/action-creator";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import filesUtil from "../../../util/filesUtil";

const { Option } = Select;
const { TextArea } = Input;

interface OptionType {
  label: string;
  value: string;
}

interface FormValues {
  reportType: string;
  reportTopic: string;
  description: string;
  attachments: File[];
}

interface Props {
  onSuccess?: () => void;
  isFormSubmitted: boolean;
  setIsFormSubmitted: (value: boolean) => void;
}

const FeedbackForm: React.FC<Props> = ({ setIsFormSubmitted, onSuccess }) => {
  const [form] = Form.useForm<FormValues>();
  const { postFeedback } = useActions(); // Replace with your custom hook
  const { t } = useTranslation("translation", {
    keyPrefix: "feedbackPage.feedbackForm",
  });
  const [files, setFiles] = useState<UploadFile[]>([]);
  const { errorLogs } = useLogger();

  const reportTypeOptions: OptionType[] = [
    { label: t("technical"), value: reportTypes.bug },
    { label: t("recommendation"), value: reportTypes.feature },
  ];

  const isSubmitting = useTypedSelector((state) => state.feedback.isLoading);

  const reportTopicsOptions: OptionType[] = [
    { label: t("account"), value: reportTopics["Account & Profile"] },
    { label: t("userManagement"), value: reportTopics["User Management"] },
    { label: t("objectMap"), value: reportTopics["Objects Map"] },
    {
      label: t("filteringAndSearch"),
      value: reportTopics["Filtering and Search"],
    },
    { label: t("jpMap"), value: reportTopics["JP Map"] },
    { label: t("npMap"), value: reportTopics["NP Map"] },
    {
      label: t("objectsInformation"),
      value: reportTopics["Objects Information"],
    },
    // Add other options translated by i18n here
  ];
  function handleOnSuccess() {
    onSuccess && onSuccess();
    message.success(t("thanksForFeedback"));
    form.resetFields();
    setIsFormSubmitted(true);
  }
  const handleSubmit = async (values) => {
    const errorFile = filesUtil.writeStrToTxtFile(
      JSON.stringify(errorLogs),
      "errorLogs"
    );
    const reduxStoreFile = filesUtil.writeStrToTxtFile(
      JSON.stringify(store.getState()),
      "reduxStore"
    );
    const data: IFeedbackFormValues = {
      ...values,
      description:
        values.description +
        "\n\n" +
        "Error Logs:" +
        "\n" +
        JSON.stringify(errorLogs) +
        "\n\n" +
        "Redux Store:" +
        "\n" +
        JSON.stringify(store.getState()),
      attachments: [...files, errorFile, reduxStoreFile],
    };

    await postFeedback(data, handleOnSuccess);
    form.resetFields();
  };

  const uploadProps = {
    fileList: files,
    onRemove: (file) => {
      setFiles((prev) => prev.filter((v) => v.uid !== file.uid));
    },
    beforeUpload: (file) => {
      const isJpgOrPng = filesUtil.getIsFileAnImage(file);
      if (!isJpgOrPng) {
        message.error(t("onlyImages"));
        return false;
      }

      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error(t("image5MBLimit"));
        return false;
      }
      setFiles((prev) => [...prev, file]);
      return false;
    },
  };

  useEffect(() => {
    return () => {
      setFiles([]);
    };
  }, []);

  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    for (const item of items) {
      if (item.type.indexOf("image") === 0) {
        const blob = item.getAsFile();

        if (!blob || !filesUtil.getIsFileAnImage(blob)) {
          message.error(t("onlyImages"));
          break;
        } else {
          const newFile = new File([blob], blob.name || "pasted-image.png", {
            type: blob.type,
          });
          setFiles((prev) => [...prev, newFile as unknown as UploadFile]);
        }
      }
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Form
          form={form}
          onFinish={handleSubmit}
          style={{ maxWidth: "100%", width: "inherit" }}
          initialValues={{ remember: true }}
          layout="vertical"
        >
          <Form.Item
            name="reportType"
            label={t("reportTypeLabel")}
            rules={[{ required: true }]}
          >
            <Select placeholder={t("selectReportType")}>
              {reportTypeOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="reportTopic"
            label={t("topicLabel")}
            rules={[{ required: true }]}
          >
            <Select placeholder={t("selectTopic")}>
              {reportTopicsOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="description"
            label={t("description")}
            rules={[{ required: true }]}
          >
            <TextArea
              rows={4}
              placeholder={t("description")}
              onPaste={handlePaste}
            />
          </Form.Item>
          <Form.Item name="attachments" label={t("attachmentsLabel")}>
            <Upload.Dragger {...uploadProps} multiple>
              <p className="ant-upload-drag-icon">
                <UploadOutlined rev={null} />
              </p>
              <p className="ant-upload-text">{t("dragOrClickToUpload")}</p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item>
            <Button disabled={isSubmitting} type="primary" htmlType="submit">
              {t("submit")}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default FeedbackForm;
