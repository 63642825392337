import { useEffect, useState } from "react";
import styles from "../../../main/ObjectDetails/ObjectDetails.module.scss";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { CustomLoader } from "../../../../../share/Loader/Loader";
import HiddenText from "../../../../../assets/svg/Hidden Text.svg";
import ModalWithHeader from "../../../../../share/ModalWithHeader/ModalWithHeader";
import Attention from "../../../../../assets/svg/AttentionHand.svg";
import ArrowRight from "../../../../../assets/svg/arrowRight.svg";
import { format, parseISO } from "date-fns";
import { RouteNames } from "../../../../../routes";
import { useNavigate } from "react-router-dom";
import useSearchParams from "../../../../../hooks/useSearchParams";
import useDateLocale from "../../../../../hooks/useDateLocale";
import { useTranslation } from "react-i18next";
import DetailsTableRow from "../../../../../share/details/DetailsTableRow";

interface IPropertiesInfo {
  IS_PRIMARY?: string;
  CODE: string;
  NAME: string;
}

const Details = () => {
  const { company } = useTypedSelector((state) => state.mapCompanyInfo);
  const { isLoading } = useTypedSelector((state) => state.auth);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  const { setSearchParam, getSearchParam } = useSearchParams();
  useEffect(() => {
    const hashId = getSearchParam("hash_id");
    if (!hashId && company?.RECORD) {
      setSearchParam("hash_id", company.RECORD);
    }
  }, []);

  const { selectedLocale } = useDateLocale();

  const { t: tDetails } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.objectDetails.details",
  });

  return (
    <div>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className={styles.detailsTab}>
          <h4 className={styles.tabTitle}>{tDetails("characteristic")}</h4>
          <table className={styles.tabTable}>
            <tbody>
              <tr className={styles.tabRow}>
                <td>{tDetails("dateCreated")}:</td>
                <td>
                  {company?.registration_date_1
                    ? format(
                        parseISO(company?.registration_date_1),
                        "dd-MM-yyyy",
                        { locale: selectedLocale }
                      )
                    : tDetails("notDefined")}
                </td>
              </tr>
              {company?.ADDRESS && (
                <tr className={styles.tabRow}>
                  <td>{tDetails("address")}:</td>
                  <td>
                    {company?.ADDRESS === "#####" ? (
                      <button
                        type="button"
                        className={styles.noRulesLink}
                        onClick={() => setModalVisible(true)}
                        data-tooltip={tDetails("showAll")}
                      >
                        <img src={HiddenText} alt="no rules" />
                      </button>
                    ) : (
                      company?.ADDRESS
                    )}
                  </td>
                </tr>
              )}
              <DetailsTableRow
                name={tDetails("status")}
                value={company?.STAN}
              />
              <DetailsTableRow
                name={tDetails("edrpou")}
                value={company?.EDRPOU}
              />
              <DetailsTableRow
                name={tDetails("profit")}
                value={company?.annual_income_th_uah}
              />
              <DetailsTableRow
                name={tDetails("export")}
                value={company?.export_th_uah}
              />
              <DetailsTableRow
                name={tDetails("import")}
                value={company?.import_th_uah}
              />
            </tbody>
          </table>
          {company?.activities?.length > 0 && (
            <>
              <h4 className={styles.tabTitle}>{tDetails("activityType")}</h4>
              <table className={styles.tabTable}>
                <tbody>
                  {company.activities?.map(
                    (prop: IPropertiesInfo, index: number) => (
                      <tr key={index} className={styles.tabRow}>
                        <td>
                          {prop.CODE}{" "}
                          {prop.IS_PRIMARY === "так" ? " - основний" : ""}
                        </td>
                        <td>
                          {prop?.NAME === "#####" ? (
                            <button
                              type="button"
                              className={styles.noRulesLink}
                              onClick={() => setModalVisible(true)}
                              data-tooltip={tDetails("showAll")}
                            >
                              <img src={HiddenText} alt="no rules" />
                            </button>
                          ) : (
                            prop.NAME
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </>
          )}
          <h4 className={styles.tabTitle}>{tDetails("description")}</h4>
          <div className={styles.tabRow}>
            <p className={styles.tabText}>{company?.NAME}</p>
          </div>
        </div>
      )}
      <ModalWithHeader
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        title={tDetails("dataNotAvailable")}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalInfoWrapper}>
            <img src={Attention} alt="attention" />
            <p className={styles.infoText}>{tDetails("warn")}</p>
          </div>
          <div className={styles.bodyWrapper}>
            <p className={styles.bodyText}>{tDetails("warn2")}</p>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <button
            onClick={() => navigate(RouteNames.SUBSCRIPTIONS)}
            className={styles.modalButton}
          >
            {tDetails("updateSubscriptionPlan")}
            <img src={ArrowRight} alt="next" />
          </button>
        </div>
      </ModalWithHeader>
    </div>
  );
};

export default Details;
