import { useTranslation } from "react-i18next";
import { useTypedSelector } from "./useTypedSelector";
import { IUser } from "../models/Accounts";
import { IFetchable } from "../models/IFetchable";

const useSelectedLanguage = () => {
  const { i18n } = useTranslation();
  const currentUser: Partial<IUser & IFetchable> = useTypedSelector(
    (store) => store.currentUser
  );
  const selectedLanguage = currentUser.language || i18n.language;

  return selectedLanguage;
};

export default useSelectedLanguage;
