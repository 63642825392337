import {
  IMapSikaDesignersInfoState,
  MapActionsEnum,
  SetMapSikaDesignerAction,
  SetMapSikaDesignersAction,
  SetObjectsLoading,
} from "./types";
import { ICurrentSikaDesigner } from "../../../../../models/CurrentSikaDesigner";
import { sikaDesignerService } from "../../../../api/SikaDesignerService";
import { IReqSikaDesigners } from "../../../../../models/SikaDesignerObject";
import { AppDispatch, RootState, getStoreState, store } from "../../../..";
import { limit } from "../../../../../constants/mapConstants";
import { MapInterfaceActionCreator } from "../../mapInterface/action.creators";

export const SikaMapObjectActionCreators = {
  setObjectsLoading: (payload: boolean): SetObjectsLoading => ({
    type: MapActionsEnum.SET_IS_SIKA_LOADING,
    payload,
  }),
  setMapSikaDesigner: (
    mapSikaDesigner: ICurrentSikaDesigner
  ): SetMapSikaDesignerAction => ({
    type: MapActionsEnum.SET_MAP_SIKA_DESIGNER,
    payload: mapSikaDesigner,
  }),

  setMapSikaDesignersInfo: (
    mapSikaCompanies: IMapSikaDesignersInfoState
  ): SetMapSikaDesignersAction => ({
    type: MapActionsEnum.SET_MAP_SIKA_DESIGNERS,
    payload: mapSikaCompanies,
  }),
  getDefaultSikaDesignerResult: () => async (dispatch: AppDispatch) => {
    const data: IReqSikaDesigners = {
      account_id: store.getState().currentAccount.id,
      limit: 20,
      offset: store.getState().mapInterface.offset,
      lon: store.getState().mapInterface.viewport.longitude
        ? store.getState().mapInterface.viewport.longitude
        : undefined,
      lat: store.getState().mapInterface.viewport.latitude
        ? store.getState().mapInterface.viewport.latitude
        : undefined,
      // status: store.getState().sikaDesignerFilters.stanFilterArray? store.getState().sikaDesignerFilters.stanFilterArray: undefined,
      // date_added__lte: store.getState().sikaDesignerFilters.date_added__lte? store.getState().sikaDesignerFilters.date_added__lte: "",
      // date_added__gte: store.getState().sikaDesignerFilters.date_added__gte? store.getState().sikaDesignerFilters.date_added__gte: "",
      income_2020__gte: store.getState().sikaDesignerFilters.isIncomeBigger
        ? 500
        : undefined,
      finished_projects_2021__range: store.getState().sikaDesignerFilters
        .finished_projects_2021__range
        ? store.getState().sikaDesignerFilters.finished_projects_2021__range
        : "",
      actual_projects__range: store.getState().sikaDesignerFilters
        .actual_projects__range
        ? store.getState().sikaDesignerFilters.actual_projects__range
        : "",
      finished_projects_full__range: store.getState().sikaDesignerFilters
        .finished_projects_full__range
        ? store.getState().sikaDesignerFilters.finished_projects_full__range
        : undefined,
      edrpou_code: store.getState().sikaDesignerFilters.edrpou_code
        ? store.getState().sikaDesignerFilters.edrpou_code
        : undefined,
      region: store.getState().sikaDesignerFilters.region
        ? store.getState().sikaDesignerFilters.region
        : undefined,
      consequence_class: store.getState().sikaDesignerFilters
        .consequence_class_array
        ? store.getState().sikaDesignerFilters.consequence_class_array
        : undefined,
      aimap_classifier: store.getState().sikaDesignerFilters
        .aimap_classifier_all
        ? store.getState().sikaDesignerFilters.aimap_classifier_all
        : undefined,
      domain: store.getState().sikaDesignerFilters.domain_array
        ? store.getState().sikaDesignerFilters.domain_array
        : undefined,
      role: store.getState().sikaDesignerFilters.role_array
        ? store.getState().sikaDesignerFilters.role_array
        : undefined,
      entity_type: store.getState().sikaDesignerFilters.entity_type_array
        ? store.getState().sikaDesignerFilters.entity_type_array
        : undefined,
      country_part: store.getState().sikaDesignerFilters.country_part_array
        ? store.getState().sikaDesignerFilters.country_part_array
        : undefined,
      keyword: store.getState().sikaDesignerFilters.sika_keyword
        ? store.getState().sikaDesignerFilters.sika_keyword
        : undefined,
      dk_018_2000: store.getState().sikaDesignerFilters.kved
        ? store.getState().sikaDesignerFilters.kved
        : undefined,
      // kved: store.getState().sikaDesignerFilters.kved? (store.getState().sikaDesignerFilters.kved): undefined,
      // kved_is_main: store.getState().sikaDesignerFilters.kved && store.getState().sikaDesignerFilters.kved_is_main? 1: undefined,
    };
    try {
      dispatch(MapInterfaceActionCreator.setIsLoading(true));
      const response = await sikaDesignerService.fetchMapSikaDesigners(data);
      if (response) {
        dispatch(
          SikaMapObjectActionCreators.setMapSikaDesignersInfo(response.data)
        );
      }
      dispatch(MapInterfaceActionCreator.setIsLoading(false));
    } catch (e: string | any) {
      dispatch(
        MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
      );
      dispatch(MapInterfaceActionCreator.setIsLoading(false));
    }
  },
  refreshFeedForSikaDesignersSearchResults:
    () => async (dispatch: AppDispatch) => {
      const prevData: IMapSikaDesignersInfoState = (
        store.getState() as RootState
      ).mapSikaDesignerInfo;

      const { isLoading } = (store.getState() as RootState).mapInterface;
      // if loading return
      if (isLoading) return;

      if (prevData.next === null) return;

      const page = (getStoreState() as RootState).mapInterface.page;
      dispatch(MapInterfaceActionCreator.setPage(page + 1));

      const data: IReqSikaDesigners = {
        account_id: store.getState().currentAccount.id,
        limit: limit,
        offset: page * limit,
        lon: store.getState().mapInterface.viewport.longitude
          ? store.getState().mapInterface.viewport.longitude
          : undefined,
        lat: store.getState().mapInterface.viewport.latitude
          ? store.getState().mapInterface.viewport.latitude
          : undefined,
        // status: store.getState().sikaDesignerFilters.stanFilterArray? store.getState().sikaDesignerFilters.stanFilterArray: undefined,
        // date_added__lte: store.getState().sikaDesignerFilters.date_added__lte? store.getState().sikaDesignerFilters.date_added__lte: "",
        // date_added__gte: store.getState().sikaDesignerFilters.date_added__gte? store.getState().sikaDesignerFilters.date_added__gte: "",
        income_2020__gte: store.getState().sikaDesignerFilters.isIncomeBigger
          ? 500
          : undefined,
        finished_projects_2021__range: store.getState().sikaDesignerFilters
          .finished_projects_2021__range
          ? store.getState().sikaDesignerFilters.finished_projects_2021__range
          : "",
        actual_projects__range: store.getState().sikaDesignerFilters
          .actual_projects__range
          ? store.getState().sikaDesignerFilters.actual_projects__range
          : "",
        finished_projects_full__range: store.getState().sikaDesignerFilters
          .finished_projects_full__range
          ? store.getState().sikaDesignerFilters.finished_projects_full__range
          : undefined,
        edrpou_code: store.getState().sikaDesignerFilters.edrpou_code
          ? store.getState().sikaDesignerFilters.edrpou_code
          : undefined,
        region: store.getState().sikaDesignerFilters.region
          ? store.getState().sikaDesignerFilters.region
          : undefined,
        consequence_class: store.getState().sikaDesignerFilters
          .consequence_class_array
          ? store.getState().sikaDesignerFilters.consequence_class_array
          : undefined,
        aimap_classifier: store.getState().sikaDesignerFilters
          .aimap_classifier_all
          ? store.getState().sikaDesignerFilters.aimap_classifier_all
          : undefined,
        domain: store.getState().sikaDesignerFilters.domain_array
          ? store.getState().sikaDesignerFilters.domain_array
          : undefined,
        role: store.getState().sikaDesignerFilters.role_array
          ? store.getState().sikaDesignerFilters.role_array
          : undefined,
        entity_type: store.getState().sikaDesignerFilters.entity_type_array
          ? store.getState().sikaDesignerFilters.entity_type_array
          : undefined,
        country_part: store.getState().sikaDesignerFilters.country_part_array
          ? store.getState().sikaDesignerFilters.country_part_array
          : undefined,
        keyword: store.getState().sikaDesignerFilters.sika_keyword
          ? store.getState().sikaDesignerFilters.sika_keyword
          : undefined,
        dk_018_2000: store.getState().sikaDesignerFilters.kved
          ? store.getState().sikaDesignerFilters.kved
          : undefined,
        // kved: store.getState().sikaDesignerFilters.kved? (store.getState().sikaDesignerFilters.kved): undefined,
        // kved_is_main: store.getState().sikaDesignerFilters.kved && store.getState().sikaDesignerFilters.kved_is_main? 1: undefined,
      };
      try {
        dispatch(MapInterfaceActionCreator.setIsLoading(true));
        const response = await sikaDesignerService.fetchMapSikaDesigners(data);
        if (response) {
          const nextData: IMapSikaDesignersInfoState = response.data;

          const composedData: IMapSikaDesignersInfoState = {
            ...prevData,
            ...nextData,
            sika_designers: [
              ...prevData.sika_designers,
              ...nextData.sika_designers,
            ],
          };
          dispatch(
            SikaMapObjectActionCreators.setMapSikaDesignersInfo(composedData)
          );
        }
        dispatch(MapInterfaceActionCreator.setIsLoading(false));
      } catch (e: string | any) {
        dispatch(
          MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
        );
        dispatch(MapInterfaceActionCreator.setIsLoading(false));
      }
    },
  getCurrentMapSikaDesigner:
    (RECORD: string, account_id: number) => async (dispatch: AppDispatch) => {
      try {
        dispatch(MapInterfaceActionCreator.setIsLoading(true));

        const response = await sikaDesignerService.fetchMapSikaDesigner(
          RECORD,
          account_id
        );
        if (response) {
          dispatch(
            SikaMapObjectActionCreators.setMapSikaDesigner(response.data)
          );
        }
        dispatch(MapInterfaceActionCreator.setIsLoading(false));
      } catch (e: string | any) {
        dispatch(
          MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
        );
      }
    },
};
