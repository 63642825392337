import {
  DeleteSikaDesignerActiveSuggestion,
  DeleteSikaDesignerEdrCode,
  DeleteSikaDesignerRegion,
  MapFilterActionsEnum,
  SetSikaDesignerActiveSuggestion,
  SetCleanSikaDesignerFilterTypeFilter,
  SetSikaDesignerFilterTypeFilter,
  SetCleanSikaDesignerEdrCode,
  SetSikaDesignerCleanRegion,
  SetSikaDesignerCleanSuggestion,
  SetSikaDesignerEdrCode,
  SetSikaDesignerRegion,
  SetSelectedDocList,
  SetSikaDesignerSuggestion,
  SetDateAddedLTE,
  SetDateAddedGTE,
  SetSikaNumber1322Range,
  SetSikaNumber2122Range,
  SetSikaNumber0224Range,
  SetSikaDesignerKVED,
  SetCleanSikaDesignerKVED,
  DeleteSikaDesignerKVED,
  SetIsIncomeBigger,
  SetIs21Plus,
  SetSikaDesignerCCFilter,
  SetCleanSikaDesignerCCFilter,
  DeleteSikaDesignerFilterType,
  DeleteSikaDesignerCC,
  DeleteSikaDesignerArea,
  SetSikaDesignerAreaFilter,
  SetCleanSikaDesignerAreaFilter,
  SetSikaDesignerCategoryFilter,
  SetCleanSikaDesignerOrganizationFilter,
  SetCleanSikaDesignerCategoryFilter,
  SetSikaDesignerOrganizationFilter,
  SetSikaDesignerOwnershipFilter,
  SetCleanSikaDesignerOwnershipFilter,
  SetSikaDesignerKeyword,
  SetSikaDesignerCleanKeyword,
  DeleteSikaDesignerKeyword,
  SetIsSuggestionsLoadingAction,
} from "./types";
import { AppDispatch, RootState, store } from "../../../../index";
import { MapInterfaceActionCreator } from "../../mapInterface/action.creators";
import { ISearchRequest, ISearchResponse } from "../../../../../models/Search";
import { ISelectedDocList } from "../../../../../models/MapObject";
import { v4 as uuidv4 } from "uuid";
import { sikaDesignerService } from "../../../../api/SikaDesignerService";
import { suggestionTypes } from "../../../../../constants/constants";

export const SikaDesignerFilterActionCreators = {
  setCleanAllSikaDesignerFilters: () => (dispatch: AppDispatch) => {
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
    dispatch(SikaDesignerFilterActionCreators.cleanSikaDesignerSuggestions([]));
    dispatch(SikaDesignerFilterActionCreators.cleanSikaDesignerRegion([]));
    dispatch(SikaDesignerFilterActionCreators.cleanSikaDesignerKVED([]));
    dispatch(SikaDesignerFilterActionCreators.setDateAddedLTE(""));
    dispatch(SikaDesignerFilterActionCreators.setDateAddedGTE(""));
    dispatch(SikaDesignerFilterActionCreators.setSikaNumber0224Range(""));
    dispatch(SikaDesignerFilterActionCreators.setSikaNumber1322Range(""));
    dispatch(SikaDesignerFilterActionCreators.setSikaNumber2122Range(""));
    dispatch(SikaDesignerFilterActionCreators.setIsIncomeBigger(false));
    dispatch(SikaDesignerFilterActionCreators.setIs21Plus(false));
    dispatch(
      SikaDesignerFilterActionCreators.cleanSikaDesignerFilterTypeFilter([])
    );
    dispatch(SikaDesignerFilterActionCreators.cleanSikaDesignerCCFilter([]));

    dispatch(
      SikaDesignerFilterActionCreators.cleanSikaDesignerOrganizationFilter([])
    );
    dispatch(
      SikaDesignerFilterActionCreators.cleanSikaDesignerCategoryFilter([])
    );
    dispatch(
      SikaDesignerFilterActionCreators.cleanSikaDesignerOwnershipFilter([])
    );
    dispatch(SikaDesignerFilterActionCreators.cleanSikaDesignerAreaFilter([]));
    dispatch(SikaDesignerFilterActionCreators.cleanSikaDesignerKeyword([]));
    dispatch(SikaDesignerFilterActionCreators.cleanSikaDesignerEdrCode([]));
    dispatch(
      SikaDesignerFilterActionCreators.setSelectedDocList([
        { id: uuidv4(), value: "1" },
      ])
    );
  },

  setIsSuggestionsLoading: (
    payload: boolean
  ): SetIsSuggestionsLoadingAction => ({
    type: MapFilterActionsEnum.SET_SUGGESTION_LOADING,
    payload,
  }),

  setIsIncomeBigger: (payload: boolean): SetIsIncomeBigger => ({
    type: MapFilterActionsEnum.SET_IS_INCOME_BIGGER,
    payload,
  }),
  setIs21Plus: (payload: boolean): SetIs21Plus => ({
    type: MapFilterActionsEnum.SET_IS_21_PLUS,
    payload,
  }),
  setSikaNumber0224Range: (payload: string): SetSikaNumber0224Range => ({
    type: MapFilterActionsEnum.SET_SIKA_NUMBER_02_24_RANGE,
    payload,
  }),
  setSikaNumber1322Range: (payload: string): SetSikaNumber1322Range => ({
    type: MapFilterActionsEnum.SET_SIKA_NUMBER_13_22_RANGE,
    payload,
  }),
  setSikaNumber2122Range: (payload: string): SetSikaNumber2122Range => ({
    type: MapFilterActionsEnum.SET_SIKA_NUMBER_21_22__RANGE,
    payload,
  }),
  setDateAddedLTE: (payload: string): SetDateAddedLTE => ({
    type: MapFilterActionsEnum.SET_DATE_ADDED_LTE,
    payload,
  }),

  setDateAddedGTE: (payload: string): SetDateAddedGTE => ({
    type: MapFilterActionsEnum.SET_DATE_ADDED_GTE,
    payload,
  }),
  toggleSikaDesignerFilterTypeFilterObject: (
    payload: string
  ): SetSikaDesignerFilterTypeFilter => ({
    type: MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_FILTER_TYPE_FILTER,
    payload,
  }),
  cleanSikaDesignerFilterTypeFilter: (
    payload: []
  ): SetCleanSikaDesignerFilterTypeFilter => ({
    type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_FILTER_TYPE_FILTER,
    payload,
  }),
  typeSikaDesignerFilterTypeClean: () => (dispatch: AppDispatch) => {
    dispatch(
      SikaDesignerFilterActionCreators.cleanSikaDesignerFilterTypeFilter([])
    );
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },

  toggleSikaDesignerCCFilterObject: (
    payload: string
  ): SetSikaDesignerCCFilter => ({
    type: MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_CC_FILTER,
    payload,
  }),
  cleanSikaDesignerCCFilter: (payload: []): SetCleanSikaDesignerCCFilter => ({
    type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_CC_FILTER,
    payload,
  }),
  typeSikaDesignerCCClean: () => (dispatch: AppDispatch) => {
    dispatch(SikaDesignerFilterActionCreators.cleanSikaDesignerCCFilter([]));
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },

  toggleSikaDesignerCategoryFilterObject: (
    payload: string
  ): SetSikaDesignerCategoryFilter => ({
    type: MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_CATEGORY_FILTER,
    payload,
  }),
  cleanSikaDesignerCategoryFilter: (
    payload: []
  ): SetCleanSikaDesignerCategoryFilter => ({
    type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_CATEGORY_FILTER,
    payload,
  }),
  typeSikaDesignerCategoryClean: () => (dispatch: AppDispatch) => {
    dispatch(
      SikaDesignerFilterActionCreators.cleanSikaDesignerCategoryFilter([])
    );
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },

  toggleSikaDesignerOrganizationFilterObject: (
    payload: string
  ): SetSikaDesignerOrganizationFilter => ({
    type: MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_ORGANIZATION_FILTER,
    payload,
  }),
  cleanSikaDesignerOrganizationFilter: (
    payload: []
  ): SetCleanSikaDesignerOrganizationFilter => ({
    type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_ORGANIZATION_FILTER,
    payload,
  }),
  typeSikaDesignerOrganizationClean: () => (dispatch: AppDispatch) => {
    dispatch(
      SikaDesignerFilterActionCreators.cleanSikaDesignerOrganizationFilter([])
    );
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },

  toggleSikaDesignerOwnershipFilterObject: (
    payload: string
  ): SetSikaDesignerOwnershipFilter => ({
    type: MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_OWNERSHIP_FILTER,
    payload,
  }),
  cleanSikaDesignerOwnershipFilter: (
    payload: []
  ): SetCleanSikaDesignerOwnershipFilter => ({
    type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_OWNERSHIP_FILTER,
    payload,
  }),
  typeSikaDesignerOwnershipClean: () => (dispatch: AppDispatch) => {
    dispatch(
      SikaDesignerFilterActionCreators.cleanSikaDesignerOwnershipFilter([])
    );
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },

  toggleSikaDesignerAreaFilterObject: (
    payload: string
  ): SetSikaDesignerAreaFilter => ({
    type: MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_AREA_FILTER,
    payload,
  }),
  cleanSikaDesignerAreaFilter: (
    payload: []
  ): SetCleanSikaDesignerAreaFilter => ({
    type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_AREA_FILTER,
    payload,
  }),
  typeSikaDesignerAreaClean: () => (dispatch: AppDispatch) => {
    dispatch(SikaDesignerFilterActionCreators.cleanSikaDesignerAreaFilter([]));
    dispatch(MapInterfaceActionCreator.setPage(1));
    dispatch(MapInterfaceActionCreator.setOffset(0));
  },

  setSelectedDocList: (payload: ISelectedDocList[]): SetSelectedDocList => ({
    type: MapFilterActionsEnum.SET_DOC_LIST,
    payload,
  }),
  setSikaDesignerSuggestions: (
    payload: ISearchResponse[]
  ): SetSikaDesignerSuggestion => ({
    type: MapFilterActionsEnum.SET_SIKA_DESIGNER_SUGGESTION,
    payload: payload,
  }),

  addSikaActiveSuggestion:
    (suggestion: ISearchResponse) => (dispatch: AppDispatch) => {
      const activeSuggestions = (store.getState() as RootState).personFilters
        .activeSuggestions;
      if (activeSuggestions.includes(suggestion)) {
        return;
      }
      if (suggestion.type === suggestionTypes.region) {
        dispatch(
          SikaDesignerFilterActionCreators.setSikaDesignerRegion(
            suggestion.code
          )
        );
      }
      if (suggestion.type === suggestionTypes.dkbs) {
        dispatch(
          SikaDesignerFilterActionCreators.setSikaDesignerKVED(suggestion.code)
        );
      }
      if (suggestion.type === suggestionTypes.np) {
        dispatch(
          SikaDesignerFilterActionCreators.setSikaDesignerEdrCode(
            suggestion.code
          )
        );
      }
      dispatch(
        SikaDesignerFilterActionCreators.setSikaDesignerActiveSuggestions(
          suggestion
        )
      );
    },
  getSikaDesignerSuggestions:
    (data: ISearchRequest) => async (dispatch: AppDispatch) => {
      try {
        dispatch(
          SikaDesignerFilterActionCreators.setIsSuggestionsLoading(true)
        );
        const response = await sikaDesignerService.fetchSuggestions(data);
        if (response) {
          const suggestions = response.data;
          const activeSuggestions = (store.getState() as RootState).mapFilters
            .activeSuggestions;
          const suggestionsWithoutActiveSuggestions = suggestions.filter(
            (suggestion: ISearchResponse) =>
              !activeSuggestions.some(
                (activeSuggestion) => activeSuggestion.code === suggestion.code
              )
          );
          dispatch(
            SikaDesignerFilterActionCreators.setSikaDesignerSuggestions(
              suggestionsWithoutActiveSuggestions
            )
          );
        }
        dispatch(
          SikaDesignerFilterActionCreators.setIsSuggestionsLoading(false)
        );
      } catch (e: string | any) {
        dispatch(MapInterfaceActionCreator.setIsLoading(false));
        dispatch(
          MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
        );
      }
    },
  setSikaDesignerActiveSuggestions: (
    payload: ISearchResponse
  ): SetSikaDesignerActiveSuggestion => ({
    type: MapFilterActionsEnum.SET_SIKA_DESIGNER_ACTIVE_SUGGESTION,
    payload,
  }),
  cleanSikaDesignerSuggestions: (
    payload: []
  ): SetSikaDesignerCleanSuggestion => ({
    type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_SUGGESTION,
    payload,
  }),
  deleteSikaDesignerActiveSuggestion: (
    payload: ISearchResponse
  ): DeleteSikaDesignerActiveSuggestion => ({
    type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_ACTIVE_SUGGESTION,
    payload,
  }),
  setSikaDesignerRegion: (payload: string): SetSikaDesignerRegion => ({
    type: MapFilterActionsEnum.SET_SIKA_DESIGNER_REGION,
    payload,
  }),
  cleanSikaDesignerRegion: (payload: []): SetSikaDesignerCleanRegion => ({
    type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_REGION,
    payload,
  }),
  deleteSikaDesignerRegion: (payload: string): DeleteSikaDesignerRegion => ({
    type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_REGION,
    payload,
  }),
  setSikaDesignerEdrCode: (payload: string): SetSikaDesignerEdrCode => ({
    type: MapFilterActionsEnum.SET_SIKA_DESIGNER_EDR_CODE,
    payload,
  }),
  cleanSikaDesignerEdrCode: (payload: []): SetCleanSikaDesignerEdrCode => ({
    type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_EDR_CODE,
    payload,
  }),
  deleteSikaDesignerEdrCode: (payload: string): DeleteSikaDesignerEdrCode => ({
    type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_EDR_CODE,
    payload,
  }),
  setSikaDesignerKVED: (payload: string): SetSikaDesignerKVED => ({
    type: MapFilterActionsEnum.SET_SIKA_DESIGNER_KVED,
    payload,
  }),
  cleanSikaDesignerKVED: (payload: []): SetCleanSikaDesignerKVED => ({
    type: MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_KVED,
    payload,
  }),
  deleteSikaDesignerKVED: (payload: string): DeleteSikaDesignerKVED => ({
    type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_KVED,
    payload,
  }),
  deleteSikaDesignerFilterType: (
    payload: string
  ): DeleteSikaDesignerFilterType => ({
    type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_FILTER_TYPE,
    payload,
  }),
  deleteSikaDesignerCC: (payload: string): DeleteSikaDesignerCC => ({
    type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_CC,
    payload,
  }),
  deleteSikaDesignerArea: (payload: string): DeleteSikaDesignerArea => ({
    type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_AREA,
    payload,
  }),
  setSikaDesignerKeyword: (payload: string): SetSikaDesignerKeyword => ({
    type: MapFilterActionsEnum.SET_SIKA_DESIGNER_KEYWORD,
    payload,
  }),
  cleanSikaDesignerKeyword: (payload: []): SetSikaDesignerCleanKeyword => ({
    type: MapFilterActionsEnum.SET_SIKA_DESIGNER_CLEAN_KEYWORD,
    payload,
  }),
  deleteSikaDesignerKeyword: (payload: string): DeleteSikaDesignerKeyword => ({
    type: MapFilterActionsEnum.DELETE_SIKA_DESIGNER_KEYWORD,
    payload,
  }),
};
