import React from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../Button/Button";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import useSearchParams from "../../../../hooks/useSearchParams";
import {
  reportTopics,
  reportTypes,
} from "../../../../store/reducers/feedback/action-creator";
import {
  ReportTopic,
  ReportType,
} from "../../../../store/reducers/feedback/types";
import { message } from "antd";
import styled from "@emotion/styled";
import {
  ForbiddenText,
  ForbiddenTitle,
} from "../../../textComponents/textComponents";
import { useActions } from "../../../../hooks/useActions";
import {
  IAimapClassifier,
  aimapClassifierMap,
  datasets,
  regions,
} from "../../../../constants/mapConstants";

type Props = {
  aimapClassifierType?: IAimapClassifier;
};
const NoContactsSection: React.FC<Props> = ({ aimapClassifierType = "" }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "noContactsSection",
  });
  const { email, id } = useTypedSelector((state) => state.currentUser);
  const { region, dataset, hash_id } = useSearchParams().getSearchParams();
  const { postFeedback } = useActions(); // Replace with your custom hook

  function handleClickReportNoContacts() {
    const data = {
      email,
      reportType: reportTypes.bug as ReportType,
      reportTopic: reportTopics["Objects Information"] as ReportTopic,
      description: `It is Contacts report from user_id: ${id} , email: ${email} : No contacts for hash_id: ${hash_id} in region: ${region} and dataset: ${dataset} `,
      ticketName: `It is Contacts report from user_id: ${id} , email: ${email} : No contacts for hash_id: ${hash_id} in region: ${region} and dataset: ${dataset} `,
    };
    postFeedback(data, () => {
      message.success(t("feedbackSent"));
    });
  }

  const StyledContainer = styled.div`
    padding: 32px 24px;
    margin-bottom: 96px;
    position: relative;
    &::after {
      content: "";
      width: 91.5%;
      height: 1px;
      background-color: var(--fifth-black-color);
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
  `;

  if (
    dataset === datasets.objects &&
    aimapClassifierType &&
    aimapClassifierMap.cottages.includes(aimapClassifierType)
  ) {
    return (
      <StyledContainer>
        <ForbiddenTitle>{t("contactsNotFound")}</ForbiddenTitle>
        <ForbiddenText>{t("contactsForCottagesAreHidden")}</ForbiddenText>
        <CustomButton type="bordered" onClick={handleClickReportNoContacts}>
          {t("askAnyway")}
        </CustomButton>
      </StyledContainer>
    );
  }
  if (region !== regions.ua) {
    return (
      <StyledContainer>
        <ForbiddenTitle>{t("contactsNotFound")}</ForbiddenTitle>
        <ForbiddenText>{t("contactsForThisRegionAreHidden")}</ForbiddenText>
        <CustomButton type="bordered" onClick={handleClickReportNoContacts}>
          {t("askAnyway")}
        </CustomButton>
      </StyledContainer>
    );
  }
  return (
    <StyledContainer>
      <ForbiddenTitle>{t("contactsNotFound")}</ForbiddenTitle>
      <ForbiddenText>{t("contactsNotFoundPromise")}</ForbiddenText>
      <CustomButton type="bordered" onClick={handleClickReportNoContacts}>
        {t("reportNoContacts")}
      </CustomButton>
    </StyledContainer>
  );
};

export default NoContactsSection;
