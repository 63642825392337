import { Checkbox, Col, Form, Input, Row, message } from "antd";
import { useEffect, useState } from "react";
import { ITemplate } from "../../../../types";
import CustomButton from "../../../../share/Button/Button";
import useMobileScreen from "../../../../hooks/useMobileScreen";
import styled from "@emotion/styled";
import { breakpoints, colors, zIndexes } from "../../../../constants";
import CustomIcon from "../../../../share/CustomIcon/CustomIcon";
import { ReactComponent as Cross } from "../../../../assets/svg/Close_small_nofill.svg";
import FilterDescription from "../../../../share/filters/FilterModal/FilterDescription";
import {
  MobileModal,
  MobileModalBody,
} from "../../../../share/MobileModal/MobileModal";
import CustomSelectPeriod from "./CustomSelectPeriod";
import CustomSelectEmail from "./CustomSelectEmail";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import { IReqTemplate } from "../../../../store/api/TemplatesService";
import { getIsMailingAccessible } from "./util/util";
import Backdrop from "../../../../share/Backdrop";
import { useTranslation } from "react-i18next";
import useStoreSnippets from "../../../../hooks/useStoreSnippets";
import SubscribeNotificationPopup from "../../../../share/NotificationPopInformation/SubscribeNotificationPopup/SubscribeNotificationPopup";
import { useTipsContext } from "../../../../hooks/useTips";

const StyledFormWrap = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  z-index: ${zIndexes.filterModal};
  margin-top: 8px;
  width: 448px;
  max-height: 636px;
  box-shadow: 0px 8px 12px 0px #091e4226;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.medium + "px"}) {
    width: 327px;
    max-height: 536px;
  }
`;
const StyledTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.002em;
`;

// const StyledSubtitle = styled.p`
//   font-size: 14px;
//   font-weight: 500;
//   line-height: 18px;
//   letter-spacing: 0.017px;
//   color: #575f6e;
// `;

const StyledHeader = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
`;

const StyledFooter = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e6e6e6;

  @media screen and (max-width: ${breakpoints.small}px) {
    padding: 16px 0 0;
  }
`;

const StyledFieldsWrap = styled.div`
  position: relative;
  padding: 24px 24px 0;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-width: ${breakpoints.small}px) {
    padding: 0;
  }
`;

type Props = {
  isShown: boolean;
  onClose: () => void;
  template?: ITemplate | null;
  createTemplate?: (template: any) => void;
  updateTemplate?: (template: any) => void;
  getTemplate?: (id: string) => void;
};

type IFormFields = {
  name: string;
  comment: string;
  period: string;
  emails: string[];
};

function formatIFormFieldsToIReqTemplate(
  formFields: IFormFields,
  isMailingApproved: boolean
): IReqTemplate {
  return {
    name: formFields.name,
    description: formFields.comment,
    is_mailing: isMailingApproved,
    json: {
      period: isMailingApproved ? formFields.period : "",
      emails: isMailingApproved ? formFields.emails : [],
    },
  };
}

const TemplateForm = ({ isShown, onClose }: Props) => {
  const { t: tTemplate } = useTranslation("translation", {
    keyPrefix: "filterTemplate",
  });

  const { t: tForm } = useTranslation("translation", {
    keyPrefix: "filterTemplate.form",
  });

  const { t: tNotification } = useTranslation("translation", {
    keyPrefix: "notifications",
  });

  const tipId = "emailingTip";
  const { setLastOpenedTipId } = useTipsContext();

  const isMobile = useMobileScreen();
  const [form] = Form.useForm();
  const [hasFormChanged, setHasFormChanged] = useState<boolean>(false);
  const [isMailingEnabledByUser, setIsMailingEnabledByUser] =
    useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { selectedTemplate } = useTypedSelector((store) => store.templates);
  const { createTemplate, updateTemplate, setSelectedTemplate } = useActions();

  const editMode = !!selectedTemplate;
  const submitText = editMode ? tTemplate("update") : tTemplate("create");

  const formInitialValues: IFormFields = {
    name: "",
    comment: "",
    period: "7",
    emails: [],
  };

  const currentAccount = useTypedSelector((store) => store.currentAccount);

  const isMailingAccessable = getIsMailingAccessible(currentAccount);

  const validateEmailSelect = (rule: any, value: number[]) => {
    if (!isMailingEnabledByUser) {
      return Promise.resolve();
    }
    if (!value || value.length === 0) {
      return Promise.reject(tForm("emailReceivers.warning"));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (!selectedTemplate) {
      form.resetFields();
      setIsMailingEnabledByUser(false);
    } else {
      form.setFieldsValue({
        name: selectedTemplate.name,
        comment: selectedTemplate?.description || "",
        period: selectedTemplate?.value?.period || "7",
        emails: selectedTemplate?.value?.emails || [],
      });
      setIsMailingEnabledByUser(!!selectedTemplate.is_mailing);
    }
  }, [selectedTemplate]);

  function handleSubmit() {
    const formValues = form.getFieldsValue();
    if (isMailingEnabledByUser && formValues.emails.length === 0) {
      messageApi.error(tForm("emailReceivers.warning"));
      return;
    }
    const template = formatIFormFieldsToIReqTemplate(
      formValues,
      isMailingEnabledByUser
    );
    if (editMode) {
      updateTemplate(selectedTemplate.id, template, () => {
        messageApi.success(tForm("notifications.templateUpdated"));
      });
    } else {
      createTemplate(template, () => {
        messageApi.success(tForm("notifications.templateCreated"));
      });
    }
    form.resetFields();
    setHasFormChanged(false);
    // setSelectedTemplate(null);
    // toggleFilter("savedFiltersListModal");
  }

  const handleToggleNewsletters = () => {
    setIsMailingEnabledByUser((state) => !state);
  };

  const handleEmailSelect = (value: string[]) => {
    form.setFieldsValue({ emails: value });
  };

  const handlePeriodSelect = (value) => {
    form.setFieldsValue({ period: value });
    setHasFormChanged(true);
  };

  const { isFreeAccount } = useStoreSnippets();
  const templateForm = (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      onValuesChange={() => {
        setHasFormChanged(true);
      }}
      initialValues={formInitialValues}
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <StyledFieldsWrap>
        {contextHolder}
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label={tForm("name.label")}
              name={"name"}
              rules={[{ required: true }]}
            >
              <Input placeholder={tForm("name.placeholder")} />
            </Form.Item>
            <Form.Item label={tForm("comment.label")} name={"comment"}>
              <Input placeholder={tForm("comment.placeholder")} />
            </Form.Item>
            <div
              onClick={() => {
                if (isFreeAccount) {
                  setLastOpenedTipId(tipId);
                }
              }}
            >
              <Form.Item
                label={`${tForm("newsLetter.label")}${
                  !isMailingAccessable
                    ? tForm("newsLetter.subscriptionOnly")
                    : ""
                }`}
                name={"newsletter"}
              >
                <Checkbox
                  disabled={!isMailingAccessable}
                  checked={isMailingEnabledByUser}
                  onChange={handleToggleNewsletters}
                >
                  {tForm("newsLetter.placeholder")}
                </Checkbox>
              </Form.Item>
            </div>

            <Form.Item
              rules={[{ validator: validateEmailSelect }]}
              label={tForm("emailReceivers.label")}
              name={"emails"}
            >
              <CustomSelectEmail
                isDisabled={!isMailingEnabledByUser || !isMailingAccessable}
                onEmailSelect={handleEmailSelect}
                value={form.getFieldValue("emails")}
              />
            </Form.Item>
            <Form.Item label={tForm("mailingPeriod.label")} name={"period"}>
              <CustomSelectPeriod
                isDisabled={!isMailingEnabledByUser || !isMailingAccessable}
                onPeriodSelect={handlePeriodSelect}
                value={form.getFieldValue("period")}
              />
            </Form.Item>
          </Col>
        </Row>
      </StyledFieldsWrap>
      {!isMobile && (
        <StyledFooter>
          <CustomButton
            size={"medium"}
            htmlType="submit"
            disabled={!hasFormChanged}
          >
            {submitText}
          </CustomButton>
        </StyledFooter>
      )}
    </Form>
  );

  const handleClose = () => {
    form.resetFields();
    setSelectedTemplate(null);
    onClose();
  };

  const title = editMode ? tForm("title.edit") : tForm("title.create");

  const { appliedFiltersCountForMain } = useStoreSnippets();

  return (
    <>
      <SubscribeNotificationPopup
        id={tipId}
        bottomPx={-600}
        rightPx={-436}
        cornerTopPosition={16}
        message={tNotification(
          "subscribeToEnableMailingForNewObjectForThisSavedFilters"
        )}
      />
      {!isMobile
        ? isShown && (
            <>
              <Backdrop onClick={handleClose} />
              <StyledFormWrap>
                <StyledHeader>
                  <StyledTitle>
                    {title}{" "}
                    {appliedFiltersCountForMain
                      ? `(${appliedFiltersCountForMain})`
                      : " "}
                  </StyledTitle>
                  <CustomIcon
                    onClick={handleClose}
                    icon={Cross}
                    color={colors.darkD300}
                  />
                </StyledHeader>
                <FilterDescription text={tForm("warning") + "."} />
                {templateForm}
              </StyledFormWrap>
            </>
          )
        : isShown && (
            <MobileModal
              isApplyBtnDisabled={!hasFormChanged}
              applyBtnText={submitText}
              onApply={handleSubmit}
              onClose={handleClose}
              title={title}
            >
              <MobileModalBody>{templateForm}</MobileModalBody>
            </MobileModal>
          )}
    </>
  );
};

export default TemplateForm;
