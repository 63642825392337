import React from "react";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { useActions } from "../../../../../hooks/useActions";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import FilterButtonWithModal from "../../../../../share/filters/FilterButtonWithModal";
import {
  FilterSection,
  FilterSectionSeparatorLine,
  FilterSections,
} from "../../../../../share/filters";
import { FilterTitleWithInfo } from "../../../../../share/filters/FilterTitleWithInfo";
import { FilterIdsEnum } from "../../../../../store/reducers/_maps/main/mapFilters/types";
import RangeSlider from "../../../../../share/RangeSlider/RangeSlider";

function valuetext(value: number) {
  return `${value}°C`;
}
const ExportImportFilters = () => {
  const filterId: FilterIdsEnum = "sikaExportImport";

  const {
    actual_projects__range,
    finished_projects_2021__range,
    finished_projects_full__range,
  } = useTypedSelector((state) => state.sikaDesignerFilters);
  const {
    setSikaNumber0224Range,
    setSikaNumber1322Range,
    setSikaNumber2122Range,
    setSelectedDocList,
    applyFilter,
  } = useActions();
  const export_arr =
    actual_projects__range === "" ? [0, 0] : actual_projects__range.split("__");
  const [value, setValue] = React.useState<number[]>([
    +export_arr[0],
    +export_arr[1],
  ]);
  const import_arr =
    finished_projects_2021__range === ""
      ? [0, 0]
      : finished_projects_2021__range.split("__");
  const [value2, setValue2] = React.useState<number[]>([
    +import_arr[0],
    +import_arr[1],
  ]);

  const income_arr =
    finished_projects_full__range === ""
      ? [0, 0]
      : finished_projects_full__range.split("__");
  const [value3, setValue3] = React.useState<number[]>([
    +income_arr[0],
    +income_arr[1],
  ]);
  const handleSikaNumber0224Change = (
    event: Event,
    newValue: number | number[]
  ) => {
    setValue(newValue as number[]);
    setTimeout(function () {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    }, 500);
    const num_array = newValue as number[];
    if (num_array.length > 1) {
      if (num_array[0] === 0 && num_array[1] === 0) {
        setSikaNumber0224Range("");
      } else {
        setSikaNumber0224Range(
          ((num_array[0] as unknown as string) +
            "__" +
            num_array[1]) as unknown as string
        );
      }
    } else {
      if (num_array[0] === 0) {
        setSikaNumber0224Range("");
      } else {
        setSikaNumber0224Range(num_array[0] as unknown as string);
      }
    }
    handleClick();
  };
  const handleSikaNumber1322Change = (
    event: Event,
    newValue: number | number[]
  ) => {
    setValue3(newValue as number[]);

    const num_array = newValue as number[];
    if (num_array.length > 1) {
      if (num_array[0] === 0 && num_array[1] === 0) {
        setSikaNumber1322Range("");
      } else {
        setSikaNumber1322Range(
          ((num_array[0] as unknown as string) +
            "__" +
            num_array[1]) as unknown as string
        );
      }
    } else {
      if (num_array[0] === 0) {
        setSikaNumber1322Range("");
      } else {
        setSikaNumber1322Range(num_array[0] as unknown as string);
      }
    }
    handleClick();
  };
  const handleSikaNumber2122Change = (
    event: Event,
    newValue: number | number[]
  ) => {
    setValue2(newValue as number[]);
    setTimeout(function () {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    }, 500);
    const num_array = newValue as number[];

    if (num_array.length > 1) {
      if (num_array[0] === 0 && num_array[1] === 0) {
        setSikaNumber2122Range("");
      } else {
        setSikaNumber2122Range(
          ((num_array[0] as unknown as string) +
            "__" +
            num_array[1]) as unknown as string
        );
      }
    } else {
      if (num_array[0] === 0) {
        setSikaNumber2122Range("");
      } else {
        setSikaNumber2122Range(num_array[0] as unknown as string);
      }
    }
    handleClick();
  };
  const handleClick = () => {
    applyFilter();
  };

  const handleClean = () => {
    setSikaNumber2122Range("");
    setSikaNumber1322Range("");
    setSikaNumber0224Range("");
    setValue([0, 0]);
    setValue2([0, 0]);
    setValue3([0, 0]);
    setSelectedDocList([{ id: uuidv4(), value: "1" }]);
    handleClick();
  };

  const filterCount =
    (actual_projects__range === "" ? 0 : 1) +
    (finished_projects_full__range === "" ? 0 : 1) +
    (finished_projects_2021__range === "" ? 0 : 1);

  const { t: tEI } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.sika.exportImportFilter",
  });

  const title = tEI("title");
  const pinText = filterCount > 0 ? filterCount.toString() : undefined;

  return (
    <FilterButtonWithModal
      filterId={filterId}
      pinText={pinText}
      title={title}
      onClear={handleClean}
      left={-132}
    >
      <FilterSections>
        <FilterSections>
          <FilterSection>
            <FilterTitleWithInfo
              title={tEI("readyObjectsBefore2022")}
              maxWidth={"80%"}
            />
            <RangeSlider
              isManualInput={false}
              value={value3}
              getAriaLabel={() => "Temperature range"}
              valueLabelDisplay="auto"
              onChange={handleSikaNumber1322Change}
              onApply={handleSikaNumber1322Change}
              getAriaValueText={valuetext}
              max={8000}
              min={0}
            />
          </FilterSection>
          <FilterSectionSeparatorLine />
          <FilterSection>
            <FilterTitleWithInfo
              maxWidth={"80%"}
              title={tEI("readyObjects2021")}
            />

            <RangeSlider
              isManualInput={false}
              value={value}
              getAriaLabel={() => "Temperature range"}
              valueLabelDisplay="auto"
              onChange={handleSikaNumber0224Change}
              onApply={handleSikaNumber0224Change}
              getAriaValueText={valuetext}
              max={500}
              min={0}
            />
          </FilterSection>
          <FilterSectionSeparatorLine />
          <FilterSection>
            <FilterTitleWithInfo
              title={tEI("readyObjects2021")}
              maxWidth={"80%"}
            />
            <RangeSlider
              isManualInput={false}
              value={value2}
              getAriaLabel={() => "Temperature range"}
              valueLabelDisplay="auto"
              onChange={handleSikaNumber2122Change}
              onApply={handleSikaNumber2122Change}
              getAriaValueText={valuetext}
              max={500}
              min={0}
            />
          </FilterSection>
        </FilterSections>
      </FilterSections>
    </FilterButtonWithModal>
  );
};

export default ExportImportFilters;
