import React from "react";
import useMobileScreen from "../../../hooks/useMobileScreen";
import CustomButton from "../../Button/Button";
import { useTranslation } from "react-i18next";
import { breakpoints } from "../../../constants";

type Props = {
  onClick: () => void;
};

const MobileClearAllButton = ({ onClick: handleClean }: Props) => {
  const isMobile = useMobileScreen(breakpoints.medium);
  const { t: tFilter } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.filtersActions",
  });

  return isMobile ? (
    <CustomButton onClick={handleClean} size="small" type="filled" color="red">
      {tFilter("clearAllFiltersMobile", "Clear all")}
    </CustomButton>
  ) : null;
};

export default MobileClearAllButton;
