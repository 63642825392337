import { IToken } from "../../../models/AuthResponse";
import { UserWithAccount } from "../../../models/IUser";

export interface AuthState {
  isAuth: boolean;
  isLoading: boolean;
  error: string;
  token: IToken;
  user: UserWithAccount;
  appVersion: string;
  isWarned: boolean;
  lastRouteBeforeRedirect: string;
}

export enum AuthActionsEnum {
  SET_AUTH = "SET_AUTH",
  SET_ERROR = "SET_ERROR",
  SET_TOKEN = "SET_TOKEN",
  SET_IS_AUTH_LOADING = "SET_IS_AUTH_LOADING",
  SET_USER = "SET_USER",
  SET_ACCESS = "SET_ACCESS",
  SET_APP_VERSION = "SET_APP_VERSION",
  SET_IS_WARNED = "SET_IS_WARNED",
  LAST_ROUTE_BEFORE_REDIRECT = "LAST_ROUTE_BEFORE_REDIRECT",
}

export interface SetAuthAction {
  type: AuthActionsEnum.SET_AUTH;
  payload: boolean;
}

export interface SetErrorAction {
  type: AuthActionsEnum.SET_ERROR;
  payload: string;
}

export interface SetTokenAction {
  type: AuthActionsEnum.SET_TOKEN;
  payload: IToken;
}

export interface SetAccessToken {
  type: AuthActionsEnum.SET_ACCESS;
  payload: string;
}

export interface SetAppVersion {
  type: AuthActionsEnum.SET_APP_VERSION;
  payload: string;
}

export interface SetUserAction {
  type: AuthActionsEnum.SET_USER;
  payload: UserWithAccount;
}

export interface SetIsLoadingAction {
  type: AuthActionsEnum.SET_IS_AUTH_LOADING;
  payload: boolean;
}

export interface SetIsWarned {
  type: AuthActionsEnum.SET_IS_WARNED;
  payload: boolean;
}

export interface SetLastRouteBeforeRedirect {
  type: AuthActionsEnum.LAST_ROUTE_BEFORE_REDIRECT;
  payload: string;
}

export type AuthAction =
  | SetAuthAction
  | SetErrorAction
  | SetIsLoadingAction
  | SetUserAction
  | SetTokenAction
  | SetAccessToken
  | SetIsWarned
  | SetLastRouteBeforeRedirect
  | SetAppVersion;
