import styles from "./AuthWithSocialNetworks.module.css";
import Google from "../../../assets/svg/Google.svg";
import styled from "@emotion/styled";
import { colors } from "../../../constants";
import { Text16 } from "../../../share/textComponents/textComponents";

const StyledAuthButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledAuthButton = styled.button`
  min-width: 160px;
  flex: 1;
  height: 50px;
  border: 1px solid ${colors.darkD100};
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.0016em;
  color: var(--secondary-black-color);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 4px ${colors.darkD100};
  }
`;

const AuthWithSocialNetworks = () => {
  const googleAuthRedirectUrl = process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI;
  function handleGoogleAuth() {
    window.location.replace(googleAuthRedirectUrl);
  }

  return (
    <StyledAuthButtonsWrapper>
      <StyledAuthButton onClick={handleGoogleAuth} type="button">
        <img src={Google} alt="Google" className={styles.authButtonSvg} />
        <Text16 fontWeight="600">Google</Text16>
      </StyledAuthButton>
      {/* <button type="button" className={styles.authButton} disabled={true}>
        <img src={Facebook} alt="Facebook" className={styles.authButtonSvg} />
        Facebook
      </button>
      <button type="button" className={styles.authButton} disabled={true}>
        <img src={Linkedin} alt="Linkedin" className={styles.authButtonSvg} />
        Linkedin
      </button> */}
    </StyledAuthButtonsWrapper>
  );
};

export default AuthWithSocialNetworks;
