import styled from "@emotion/styled";
import { breakpoints } from "../../../constants";
import ImageFinder from "../../ImageFinder/ImageFinder";
import { useTranslation } from "react-i18next";
import { objectFilterTranslationMap } from "../../../constants/filterTranslationMapping";
import { AvailableCC } from "../../../constants/constants";
import useSearchParams from "../../../hooks/useSearchParams";

const StyledTypeFilterItem = styled.div<{ isActive: boolean }>`
  width: 208px;
  min-height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  margin-bottom: 1px;
  border-radius: 6px;
  gap: 4px;
  cursor: pointer;
  background: ${(props) =>
    props.isActive ? "var(--fifth-black-color)" : "var(--primary-white-color)"};

  @media (max-width: ${breakpoints.small}px) {
    width: 100%;
  }

  img {
    border: ${(props) =>
      props.isActive && "1px solid var(--secondary-red-color)"};
  }

  &:hover {
    background-color: ${(props) =>
      props.isActive ? "var(--fifth-black-color)" : "var(--sixth-black-color)"};
    img {
      background: var(--primary-white-color);
    }
  }
`;

const StyledTypeItemName = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: var(--secondary-black-color);
`;

interface Props {
  option: string;
  isActive: boolean;
  onClick: () => void;
}

const TypeFilterItem = ({ option, isActive, onClick }: Props) => {
  const isUaRegion =
    useSearchParams().getSearchParams().region === AvailableCC.ua;

  const { t: tOption } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.aimap.objectTypeFilter.values",
  });

  return (
    <StyledTypeFilterItem key={option} isActive={isActive} onClick={onClick}>
      <ImageFinder object={option} />
      <StyledTypeItemName>
        {isUaRegion ? tOption(objectFilterTranslationMap[option]) : option}
      </StyledTypeItemName>
    </StyledTypeFilterItem>
  );
};

export default TypeFilterItem;
