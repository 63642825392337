import { AppDispatch, RootState, store } from "../..";
import { TemplatesActionsEnum } from "./types";
import {
  IDeleteTemplate,
  IReqTemplate,
  templatesService,
} from "../../api/TemplatesService";
import { IMainFilterTemplateValue, ITemplate } from "../../../types";
import { MapFilterActionCreators } from "../_maps/main/mapFilters/action-creators";
import { MainMapObjectActionCreators } from "../_maps/main/map/action-creators";

export const TemplatesActionCreators = {
  applyTemplate:
    (template: ITemplate, callback: () => void, errorCallback: any) =>
    async (dispatch: AppDispatch) => {
      try {
        const savedFilters = template.value;

        await dispatch(MapFilterActionCreators.setCleanAllFilters());

        await Promise.all(
          savedFilters?.region?.map((region) =>
            dispatch(MapFilterActionCreators.setRegion(region))
          )
        );

        if (savedFilters?.np_id?.length > 0) {
          await Promise.all(
            savedFilters?.np_id?.map((np_id: string) =>
              dispatch(MapFilterActionCreators.setNpId(np_id))
            )
          );
        }
        //erdpou_code
        await Promise.all(
          savedFilters?.edrpou_code?.map((code) =>
            dispatch(MapFilterActionCreators.setEdrCode(code))
          )
        );

        //keyword
        await Promise.all(
          savedFilters?.keyword?.map((keyword) =>
            dispatch(MapFilterActionCreators.setKeyword(keyword))
          )
        );

        //dk_018_2000
        await Promise.all(
          savedFilters?.dk_018_2000?.map((dk) =>
            dispatch(MapFilterActionCreators.setDkbsCode(dk))
          )
        );

        //activeSuggestions
        await Promise.all(
          savedFilters?.activeSuggestions?.map((activeSuggestion) =>
            dispatch(
              MapFilterActionCreators.setActiveSuggestions(activeSuggestion)
            )
          )
        );
        //square_filter
        await dispatch(
          MapFilterActionCreators.setSquareFilter(savedFilters.square_filter)
        );

        await Promise.all(
          savedFilters?.aimap_classifier?.map((type) =>
            dispatch(MapFilterActionCreators.selectTypeFilterOption(type))
          )
        );

        await Promise.all(
          savedFilters?.consequence_class?.map((cc) =>
            dispatch(MapFilterActionCreators.toggleCCFilterObject(cc))
          )
        );

        const stageDocumentationList = (store.getState() as RootState)
          .mapObjectsInfo?.mapObjects?.params;

        if (stageDocumentationList && savedFilters.stage_documentation) {
          dispatch(
            MapFilterActionCreators.setStageFilterObject([
              ...savedFilters.stage_documentation,
            ])
          );

          dispatch(
            MapFilterActionCreators.setSelectedDocList(
              savedFilters.stage_documentation_select_options
            )
          );
        }

        await Promise.all(
          savedFilters?.construction_type?.map((construction) =>
            dispatch(
              MapFilterActionCreators.toggleConstructionFilterObject(
                construction
              )
            )
          )
        );

        await Promise.all(
          savedFilters?.stage_building?.map((stage) =>
            dispatch(MapFilterActionCreators.toggleBuildFilterObject(stage))
          )
        );

        await dispatch(MainMapObjectActionCreators.getDefaultResult());
        callback();
      } catch (e: string | any) {
        errorCallback();
      }
    },

  getTemplates: () => async (dispatch: AppDispatch) => {
    try {
      dispatch(TemplatesActionCreators.setIsLoading(true));
      const accountId = store.getState().currentAccount.id;
      const response = await templatesService.getTemplates(accountId);

      dispatch(TemplatesActionCreators.setTemplates(response.data));
      dispatch(TemplatesActionCreators.setIsLoading(false));
    } catch (e: string | any) {
      dispatch(TemplatesActionCreators.setError(e?.response?.data?.detail));
    }
  },
  setTemplates: (payload: any) => ({
    type: TemplatesActionsEnum.SET_TEMPLATES,
    payload,
  }),
  setIsLoading: (payload: boolean) => ({
    type: TemplatesActionsEnum.SET_IS_TEMPLATES_LOADING,
    payload,
  }),
  setError: (payload: string) => ({
    type: TemplatesActionsEnum.SET_ERROR,
    payload,
  }),
  setSelectedTemplate: (payload: ITemplate | null) => ({
    type: TemplatesActionsEnum.SET_SELECTED_TEMPLATE,
    payload,
  }),
  deleteTemplate:
    (templateId: string | number) => async (dispatch: AppDispatch) => {
      try {
        dispatch(TemplatesActionCreators.setIsLoading(true));
        const reqData: IDeleteTemplate = {
          account_id: store.getState().currentAccount.id,
          template_id: templateId,
        };
        await templatesService.deleteTemplate(reqData);
        const newTemplates = store
          .getState()
          .templates.templates.filter(
            (template: any) => template.id !== templateId
          );

        dispatch(TemplatesActionCreators.setTemplates(newTemplates));
        dispatch(TemplatesActionCreators.setIsLoading(false));
      } catch (e: string | any) {
        dispatch(TemplatesActionCreators.setError(e?.response?.data?.detail));
      }
    },
  updateTemplate:
    (
      templateId: string | number,
      templateData: IReqTemplate,
      callback?: () => void
    ) =>
    async (dispatch: AppDispatch) => {
      try {
        dispatch(TemplatesActionCreators.setIsLoading(true));
        const accountId = store.getState().currentAccount.id;

        const selectedTemplate = store.getState().templates.selectedTemplate;

        await templatesService.updateTemplate(
          {
            ...templateData,
            value: { ...selectedTemplate.value, ...templateData.json },
            account_id: accountId,
          },
          templateId
        );

        // getTemplates();

        dispatch(TemplatesActionCreators.getTemplates());
        dispatch(TemplatesActionCreators.setSelectedTemplate(null));
        dispatch(MapFilterActionCreators.toggleFilter("savedFiltersListModal"));
        dispatch(TemplatesActionCreators.setIsLoading(false));
        //  toggleFilter("savedFiltersListModal");

        // const updatedTemplateFields = response.data;
        // const templates = store.getState().templates.templates;
        // const updatedTemplates = templates.map((template: any) => {
        //   if (template.id === updatedTemplateFields.id) {
        //     return {
        //       ...template,
        //       ...updatedTemplateFields,
        //       json: {
        //         ...template.json,
        //         ...updatedTemplateFields.value,
        //       },
        //     };
        //   }
        //   return template;
        // });
        // dispatch(TemplatesActionCreators.setTemplates(updatedTemplates));
        if (callback) callback();
        dispatch(TemplatesActionCreators.setIsLoading(false));
      } catch (e: string | any) {
        dispatch(TemplatesActionCreators.setError(e?.response?.data?.detail));
      }
    },
  createTemplate:
    (data: IReqTemplate, callback?: () => void) =>
    async (dispatch: AppDispatch) => {
      try {
        dispatch(TemplatesActionCreators.setIsLoading(true));

        const mapFilters = (store.getState() as RootState).mapFilters;
        const appliedFilters: Partial<IMainFilterTemplateValue> = {
          region: mapFilters.region,
          stage_building: mapFilters.stageBuilding,
          aimap_classifier: mapFilters.typeFilterArray,
          consequence_class: mapFilters.ccFilterArray,
          construction_type: mapFilters.constructionType,
          stage_documentation: mapFilters.stageDocumentation,
          stage_documentation_select_options: mapFilters.selectedDocList,
          keyword: mapFilters.keyword,
          edrpou_code: mapFilters.edrpou_code,
          activeSuggestions: mapFilters.activeSuggestions,
          dk_018_2000: mapFilters.dkbs_code,
          np_id: mapFilters.np_id,
          square_filter: mapFilters.squareFilter,
        };
        const response = await templatesService.postTemplate({
          account_id: store.getState().currentAccount.id,
          name: data.name,
          description: data.description,
          is_mailing: data.is_mailing,
          json: {
            ...data.json,
            ...appliedFilters,
          },
        });
        const newTemplate = response.data;

        const templates = (store.getState() as RootState).templates.templates;
        const updatedTemplates = [...templates, newTemplate];
        dispatch(TemplatesActionCreators.setTemplates(updatedTemplates));
        dispatch(TemplatesActionCreators.setSelectedTemplate(null));
        dispatch(MapFilterActionCreators.toggleFilter("savedFiltersListModal"));
        if (callback) callback();
        dispatch(TemplatesActionCreators.setIsLoading(false));
      } catch (e: string | any) {
        dispatch(TemplatesActionCreators.setError(e?.response?.data?.detail));
      }
    },
};
