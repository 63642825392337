import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { useActions } from "../../../../../hooks/useActions";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { regions } from "../../../../../constants/mapConstants";
import useSearchParams from "../../../../../hooks/useSearchParams";
import FilterButtonWithModal from "../../../../../share/filters/FilterButtonWithModal";
import OtherFiltersBody from "./OtherFiltersBody";
import { getIsSquareFilterApplied } from "../../../../../util/storeUtil";
import useMobileScreen from "../../../../../hooks/useMobileScreen";
import { breakpoints } from "../../../../../constants";

const OtherFilters = () => {
  const { constructionType, stageBuilding, stageDocumentation, squareFilter } =
    useTypedSelector((state) => state.mapFilters);

  const filterId = "other";
  const selectedRegion = useSearchParams().getSearchParam("region");
  const {
    StageFilterClean,
    BuildFilterClean,
    ConstructionFilterClean,
    setSelectedDocList,
    cleanSquareFilter,
  } = useActions();

  const handleClean = () => {
    BuildFilterClean();
    ConstructionFilterClean();
    StageFilterClean();
    setSelectedDocList([{ id: uuidv4(), value: "1" }]);
    cleanSquareFilter();
  };

  const isSquareFilterApplied = getIsSquareFilterApplied(squareFilter);

  const otherFiltersCount =
    constructionType.length +
    stageBuilding.length +
    stageDocumentation.length +
    Number(isSquareFilterApplied);

  const { t } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.aimap.otherFilter",
  });

  const isTablet = useMobileScreen(breakpoints.medium);

  const title = t("title");
  const pinText = otherFiltersCount > 0 && otherFiltersCount;

  const leftMap = {
    [regions.no]: -240,
    [regions.fr]: -240,
    [regions.ua]: -372,
    [regions.pl]: -372,
    [regions.en]: -372,
  };

  return (
    <FilterButtonWithModal
      title={title}
      pinText={pinText}
      filterId={filterId}
      onClear={handleClean}
      left={isTablet ? 0 : leftMap[selectedRegion]}
      width={512}
    >
      <OtherFiltersBody />
    </FilterButtonWithModal>
  );
};

export default OtherFilters;
