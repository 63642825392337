import {
  MapActionsEnum,
  MapObjectAction,
  IMapSikaDesignersInfoState,
} from "./types";

const initialState: IMapSikaDesignersInfoState = {
  isObjectsLoading: false,
  params: {},
  hash_ids: [],
  sika_designer: null,
  count: 0,
  sika_designers: [],
  next: null,
};

export default function sikaDesignerReducer(
  state = initialState,
  action: MapObjectAction
) {
  switch (action.type) {
    case MapActionsEnum.SET_IS_SIKA_LOADING:
      return { ...state, isObjectsLoading: action.payload };
    case MapActionsEnum.SET_MAP_SIKA_DESIGNER:
      return { ...state, ...action.payload };
    case MapActionsEnum.SET_MAP_SIKA_DESIGNERS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
