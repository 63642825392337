import { Popup } from "react-map-gl";
import PopupContentContainer from "./PopupContentContainer";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

const hoverPopupOffset = 4;

type Props = {
  children: React.ReactNode;
  latitude: number;
  longitude: number;
  itemsCount: number;
};

const CustomPopup: React.FC<Props> = ({
  children,
  latitude,
  longitude,
  itemsCount,
}) => {
  const popupItemsList = children;

  const { t } = useTranslation("translation", { keyPrefix: "mapUI" });

  return (
    <Popup
      latitude={latitude}
      longitude={longitude}
      closeButton={false}
      offset={hoverPopupOffset}
      className={styles.popup}
    >
      <PopupContentContainer>
        <h3>
          {t("found")}: {itemsCount}
        </h3>
        {popupItemsList}
      </PopupContentContainer>
    </Popup>
  );
};

export default CustomPopup;
