import styled from "@emotion/styled";
import { Ellipsis } from "../../Loader/Loader";
import { breakpoints } from "../../../constants";

const StyledBadge = styled.div`
  position: relative;
  // this should be container styles like pillow for loader
  width: 80px;
  height: 32px;
  border-radius: 20px;
  background-color: var(--primary-white-color);
  border: 1px solid var(--fifth-black-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.2);
  div {
    transform: scale(0.5);
  }

  z-index: 2;
  @media (max-width: ${breakpoints.small}px) {
    transform: scale(0.8);
  }
`;

const BadgeLoader = () => {
  return (
    <StyledBadge>
      <Ellipsis />
    </StyledBadge>
  );
};

export default BadgeLoader;
