import { DownloadActions, DownloadActionsEnum, DownloadState } from "./types";

const initialState: DownloadState = {
  error: "",
  isDownloading: false,
  url: "",
  isDownloadingFinished: false,
};

function downloadsReducer(
  state = initialState,
  action: DownloadActions
): DownloadState {
  switch (action.type) {
    case DownloadActionsEnum.SET_IS_DOWNLOADING:
      return { ...state, isDownloading: action.payload };
    case DownloadActionsEnum.SET_DOWNLOAD_ERROR:
      return { ...state, error: action.payload, isDownloading: false };
    case DownloadActionsEnum.SET_IS_DOWNLOADING_FINISHED:
      return { ...state, isDownloadingFinished: action.payload };
    case DownloadActionsEnum.SET_DOWNLOADING_URL:
      return { ...state, url: action.payload };

    default:
      return state;
  }
}

export default downloadsReducer;
