import styled from "@emotion/styled";
import { breakpoints, colors } from "../../constants";
import { FC } from "react";
import ProfileAvatar from "../../assets/images/Profile image.jpg";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useFormatDistanceToNowLocalized from "../../hooks/useDateAgoLocale";
import { useMediaQuery } from "react-responsive";
import {
  MobileModal,
  MobileModalBody,
} from "../../share/MobileModal/MobileModal";
import { useTranslation } from "react-i18next";

const StyledWrap = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 375px;
  padding: 24px;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid ${colors.darkD100};

  @media screen and (max-width: ${breakpoints.large}px) {
    min-width: auto;
  }

  @media screen and (max-width: ${breakpoints.small}px) {
    padding: 0;
    border: none;
    border-radius: 0;
    width: 100%;
  }
`;
const StyledAvatar = styled.img`
  height: 80px;
  width: 80px;
  border-radius: 2.67px;
  object-fit: cover;
  float: left;
  margin-right: 16px;
`;
const StyledName = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.0022em;
  color: ${colors.darkD400};
`;
const StyledPosition = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.0012em;
  color: ${colors.darkD300};
`;

const StyledCategory = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${colors.darkD100};
  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`;
const StyledCategoryTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.002em;
  color: ${colors.darkD400};
`;
const StyledInfoName = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.0012em;
  color: ${colors.darkD300};
`;
const StyledInfoValue = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;
const StyledButton = styled(StyledInfoValue)`
  border: none;
  outline: none;
  background: none;
`;

interface IUserDetails {
  handleOpen: (user_id: number) => void;
  userId: number;
  onClose: () => void;
}

const UserDetails: FC<IUserDetails> = ({ handleOpen, userId, onClose }) => {
  const { users, invites } = useTypedSelector((state) => state.currentAccount);

  const user =
    users.find((user) => user.user.id === userId) ||
    invites.find((user) => user.id === userId) ||
    users[0];

  const formattedDate = useFormatDistanceToNowLocalized(
    user.user?.last_login || undefined
  );

  const isTablet = useMediaQuery({
    query: `(max-width: ${breakpoints.medium}px)`,
  });

  const { t: tUser } = useTranslation("translation", {
    keyPrefix: "accountUsersPage",
  });

  const detailsBody = (
    <StyledWrap>
      <div>
        <StyledAvatar alt="" src={ProfileAvatar} />
        <StyledName>{user.user?.full_name || "--"}</StyledName>
        <StyledPosition>{user.role?.name || "--"}</StyledPosition>
      </div>
      <StyledCategory>
        <StyledCategoryTitle>{tUser("basicInformation")}</StyledCategoryTitle>
        <div>
          <StyledInfoName>{tUser("emailAddress")}</StyledInfoName>
          <StyledInfoValue>{user.user?.email || "--"}</StyledInfoValue>
        </div>
        <div>
          <StyledInfoName>{tUser("position")}</StyledInfoName>
          <StyledInfoValue>{user.user?.position || "--"}</StyledInfoValue>
        </div>
      </StyledCategory>
      <StyledCategory>
        <StyledCategoryTitle>{tUser("activity")}</StyledCategoryTitle>
        <div>
          <StyledInfoName>{tUser("lastActivityText")}</StyledInfoName>
          <StyledInfoValue>
            {formattedDate ? formattedDate : "--"}
          </StyledInfoValue>
        </div>
        <div>
          <StyledInfoName>{tUser("statusText")}</StyledInfoName>
          <StyledInfoValue>{tUser("accAccessText")}</StyledInfoValue>
        </div>
        <div>
          <StyledInfoName>{tUser("actionsText")}</StyledInfoName>
          <StyledButton onClick={() => handleOpen(userId)} as={"button"}>
            {tUser("changeRoleText")}
          </StyledButton>
        </div>
      </StyledCategory>
    </StyledWrap>
  );

  return (
    <>
      {!isTablet ? (
        detailsBody
      ) : (
        <MobileModal
          title={tUser("userDataText")}
          onClose={onClose}
          hasActions={false}
        >
          <MobileModalBody>{detailsBody}</MobileModalBody>
        </MobileModal>
      )}
    </>
  );
};

export default UserDetails;
