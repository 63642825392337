import { IAccountUtility } from "./reducers/accounts";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from "./reducers";
import { compose } from "redux";
import { isDevelopment } from "../constants/constants";
import { AuthState } from "./reducers/auth/types";
import { IMapObjectsInfoState } from "./reducers/_maps/main/map/types";
import { IMapCompanyInfoState } from "./reducers/_maps/company/company/types";
import { IAccount } from "../models/Accounts";
import { IMapInterface } from "./reducers/_maps/mapInterface/types";
import { ICurrentUser } from "./reducers/currentUser";
import { IMapFilters } from "./reducers/_maps/main/mapFilters/types";
import { ICompanyFilters } from "./reducers/_maps/company/companyFilters/types";
import { IPersonsFilters } from "./reducers/_maps/persons/personFilters/types";
import { IMapSikaDesignersInfoState } from "./reducers/_maps/sika/sikaDesigner/types";
import { IMapPersonsInfoState } from "./reducers/_maps/persons/person/types";
import { IMapUSAIDInfoState } from "./reducers/_maps/usaid/usaidMap/types";
import { MapSikaFiltersState } from "./reducers/_maps/sika/sikaDesignersFilter/types";
import { MapUsaidFiltersState } from "./reducers/_maps/usaid/usaidMapFilters/types";
import { ITemplatesState } from "./reducers/templates";
import { DownloadState } from "./reducers/download/types";
import { IFeedbackState } from "./reducers/feedback";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["currentAccount", "accounts", "auth", "isWarned", "appVersion"],
};

const reducersMap = {
  auth: reducers.auth,
  accounts: reducers.accountsReducer,
  currentUser: reducers.currentUserReducer,
  currentAccount: reducers.accountReducer,
  accountUtility: reducers.utilityAccountReducer,
  mapInterface: reducers.mapInterface,

  mapFilters: reducers.mapFilters,
  companyFilters: reducers.companyFilters,
  personFilters: reducers.personFilters,
  sikaDesignerFilters: reducers.sikaDesignerFilters,
  usaidMapFilters: reducers.usaidMapFilters,

  mapObjectsInfo: reducers.map,
  mapCompanyInfo: reducers.company,
  mapPersonInfo: reducers.person,
  mapSikaDesignerInfo: reducers.sikaDesigner,
  mapUSAIDInfo: reducers.usaid,
  templates: reducers.templates,
  downloads: reducers.downloads,
  feedback: reducers.feedback,
};

const rootReducer = combineReducers(reducersMap);

const composeEnhancers = isDevelopment
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : false;

const persistedReducer = persistReducer(authPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REHYDRATE, REGISTER],
    }).concat(thunk),
  devTools: isDevelopment ? composeEnhancers : false,
});

export const persistor = persistStore(store);

export type RootState = {
  auth: AuthState;
  accounts: IAccount[];
  currentUser: ICurrentUser;
  currentAccount: IAccount;
  accountUtility: IAccountUtility;
  mapInterface: IMapInterface;

  mapFilters: IMapFilters;
  companyFilters: ICompanyFilters;
  personFilters: IPersonsFilters;
  sikaDesignerFilters: MapSikaFiltersState;
  usaidMapFilters: MapUsaidFiltersState;

  mapObjectsInfo: IMapObjectsInfoState;
  mapCompanyInfo: IMapCompanyInfoState;
  mapPersonInfo: IMapPersonsInfoState;
  mapSikaDesignerInfo: IMapSikaDesignersInfoState;
  mapUSAIDInfo: IMapUSAIDInfoState;
  templates: ITemplatesState;
  downloads: DownloadState;
  feedback: IFeedbackState;
};
export type AppDispatch = typeof store.dispatch;

export const getStoreState = store.getState as () => RootState;
