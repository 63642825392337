import React, { useEffect, useState } from "react";
import styles from "./AddingUserToAccount.module.css";
import css from "../../../styles.module.scss";
import { CustomSelect } from "../../../share/Input/Input";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../../routes";
import CustomCreatableSelect from "../../../share/CreatebleSelect/CustomCreatableSelect";
import { useActions } from "../../../hooks/useActions";
import { IRole } from "../../../models/Accounts";
import { MultiValue } from "react-select";
import { ButtonLoader } from "../../../share/Loader/Loader";
import Close from "../../../assets/svg/Close.svg";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../store";
import { IAccountUtility } from "../../../store/reducers/accounts";

export interface Option {
  readonly label: string;
  readonly value: string;
}

const AddingUserToAccount = () => {
  const { error, isLoading } = useTypedSelector((state) => state.auth);
  const { roles } = useTypedSelector(
    (state: RootState) => state.accountUtility as IAccountUtility
  );
  const { users, invites, subscribe } = useTypedSelector(
    (state) => state.currentAccount
  );
  const { inviteUserToAccount, getRoles } = useActions();
  const navigate = useNavigate();
  const [values, setValues] = useState<MultiValue<Option>>([]);
  const [selectValue, setSelectValue] = useState("1");

  useEffect(() => {
    getRoles();
    // eslint-disable-next-line
  }, []);

  const onSubmit = async () => {
    if (selectValue === "1" || values.length === 0) return;
    const userEmails = values.map((el: Option) => el.value);
    const result = await inviteUserToAccount(+selectValue, userEmails);
    if (!!result) {
      navigate(RouteNames.ACCOUNT_USERS);
    }
  };

  const { t } = useTranslation("translation", {
    keyPrefix: "addUserPage",
  });

  return (
    <div className={styles.mainWrapper}>
      <img
        onClick={() => navigate(RouteNames.ACCOUNT_USERS)}
        className={styles.close}
        src={Close}
        alt="goBack"
      />
      <h1 className={styles.mainTitle}>{t("title")}</h1>
      <div className={styles.formContainer}>
        <label htmlFor="email" className={styles.label}>
          {t("email.label")}
          <CustomCreatableSelect
            values={values}
            setValues={setValues}
            placeholder={t("email.placeholder")}
          />
          {values.length + users.length + invites.length ===
            subscribe?.permissions.max_users && (
            <p className={styles.errorMsg}>{t("email.limitExceeded")}</p>
          )}
        </label>
        <label htmlFor="role" className={css.label}>
          {t("role.label")}
          <CustomSelect
            name="role"
            classSelectName={
              selectValue === "1"
                ? `${css.select} ${css.disabledOption}`
                : css.select
            }
            value={selectValue}
            onChange={(e) => setSelectValue(e.target.value)}
          >
            <option value="1" disabled>
              {t("role.defaultOption")}
            </option>
            {roles.results?.map((role: IRole) => (
              <option key={role.id} value={role.id}>
                {role.value}
              </option>
            ))}
          </CustomSelect>
        </label>
        {error && <span className={styles.errorMsg}>{error}</span>}
      </div>
      <div className={styles.buttonsContainer}>
        <button
          onClick={() => navigate(RouteNames.ACCOUNT_USERS)}
          type="button"
          className={styles.cancelButton}
        >
          {t("cancelBtn")}
        </button>
        <button
          onClick={onSubmit}
          type="button"
          className={styles.submitButton}
          disabled={isLoading}
        >
          {t("submitBtn")}
          {isLoading ? <ButtonLoader /> : null}
        </button>
      </div>
    </div>
  );
};

export default AddingUserToAccount;
