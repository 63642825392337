import {
  CurrentCompany,
  ICurrentCompany,
} from "../../../../../models/CurrentCompany";

interface MapStateParams {
  [key: string]: any;
}

export type IMapCompanyInfoState = Partial<{
  isObjectsLoading: boolean;
  params: MapStateParams;
  hash_ids: [];
  company: CurrentCompany;
  companies: CurrentCompany[];
  companyMapRef: any;
  count: number;
  next: null | string;
}>;

export enum CompaniesMapActionsEnum {
  SET_MAP_COMPANIES = "SET_MAP_COMPANIES",
  SET_MAP_COMPANY = "SET_MAP_COMPANY",
  SET_IS_COMPANY_LOADING = "SET_IS_COMPANY_LOADING",
}

export interface SetMapCompanyAction {
  type: CompaniesMapActionsEnum.SET_MAP_COMPANY;
  payload: ICurrentCompany;
}

export interface SetMapCompaniesAction {
  type: CompaniesMapActionsEnum.SET_MAP_COMPANIES;
  payload: IMapCompanyInfoState;
}

export interface SetIsMapLoadingAction {
  type: CompaniesMapActionsEnum.SET_IS_COMPANY_LOADING;
  payload: boolean;
}

export type MapObjectAction =
  | SetMapCompaniesAction
  | SetMapCompanyAction
  | SetIsMapLoadingAction;
