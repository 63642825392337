import styled from "@emotion/styled";

const TelLink = ({ children }: { children: string }) => (
  <StyledLink href={`tel:${children}`}>{children}</StyledLink>
);

const StyledLink = styled.a`
  text-decoration: none;
  color: var(--primary-blue-color);
  &:hover {
    text-decoration: underline;
  }
`;

export default TelLink;
