import { useMemo } from "react";
import { useLazyEffect } from "../../../../hooks/useLazyEffect";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";

const useUSAIDFilters = () => {
  const { subscribe } = useTypedSelector((state) => state.currentAccount);
  const { offset } = useTypedSelector((state) => state.mapInterface);
  const {
    activeSuggestions,
    region,
    edrpou,
    kved,
    economic_sector_array,
    activity_status_array,
    entity_type_array,
    category_array,
  } = useTypedSelector((state) => state.usaidMapFilters);
  const { hash_ids } = useTypedSelector((state) => state.mapUSAIDInfo);

  const { getDefaultUSAIDResult } = useActions();
  const regionSearch = () =>
    region.length ||
    (region.length && subscribe?.permissions?.region?.includes(region[0]))
      ? region
      : subscribe?.permissions?.region?.length
      ? subscribe?.permissions?.region
      : [];
  const edrpouSearch = () => (edrpou.length ? edrpou : []);

  const filterByCodes = ["in", "id", ...hash_ids];
  const filterByRegion = ["in", "region", ...regionSearch()];
  const filterByEdrpou = ["in", "edrpou", ...edrpouSearch()];
  const filterByEconomicSector = [
    "in",
    "economic_sector",
    ...economic_sector_array,
  ];
  const filterByCategory = ["in", "category", ...category_array];
  const filterByEntityType = ["in", "entity_type", ...entity_type_array];
  const filterByActivityStatus = [
    "in",
    "activity_status_2022",
    ...activity_status_array,
  ];
  const filterLatGTE = [">=", "lat", 44.386389];
  const filterLatLTE = ["<=", "lat", 52.377778];
  const filterLonGTE = [">=", "lon", 22.138056];
  const filterLonLTE = ["<=", "lon", 40.223611];
  const filters = [
    filterByEntityType,
    filterByActivityStatus,
    filterByCategory,
    filterByEconomicSector,
    filterByCodes,
    filterLonLTE,
    filterLatGTE,
    filterLatLTE,
    filterByRegion,
    filterByEdrpou,
    filterLonGTE,
  ].filter(
    (item) =>
      item.length >= 3 &&
      item[item.length - 1] !== "" &&
      item[item.length - 1] !== 0
  );
  if (edrpou.length !== 0 || kved.length !== 0) {
    filters.push(filterByCodes);
  }

  useLazyEffect(() => {
    getDefaultUSAIDResult();
    // eslint-disable-next-line
  }, [
    offset,
    activeSuggestions,
    region,
    edrpou,
    kved,
    economic_sector_array,
    activity_status_array,
    entity_type_array,
    category_array,
  ]);

  const mapFilter = useMemo(() => ["all", ...filters], [filters]);

  return mapFilter;
};

export default useUSAIDFilters;
