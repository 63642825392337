import { useEffect, useState } from "react";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import Details from "./ActiveTabs/Details";
import Contacts from "./ActiveTabs/Contacts";
import Np from "../../../../assets/svg/personIcon.svg";
import useSearchParams from "../../../../hooks/useSearchParams";
import { useTranslation } from "react-i18next";
import { ICurrentPerson } from "../../../../models/CurrentPerson";
import Tab, { TabsWrapper } from "../../../Tab/Tab";
import AnimatedDetailsContainer from "../../../../share/ResultDetails/AnimatedDetailsContainer/AnimatedDetailsContainer";
import DetailsHeader from "../../../../share/ResultDetails/DetailsHeader/DetailsHeader";
import {
  DetailsContainer,
  DetailsTabContent,
  ShowOnMapButton,
  StyledDetailsActions,
} from "../../../../share/ResultDetails/DetailsScroll/DetailsScroll";
import useMobileScreen from "../../../../hooks/useMobileScreen";
const PersonDetails = () => {
  const { person } = useTypedSelector((state) => state.mapPersonInfo);
  const { isDetailsActive } = useTypedSelector((state) => state.mapInterface);
  const { setIsDetailsActive, setCurrentMarker, setMapPerson } = useActions();
  const [activeTab, setActiveTab] = useState("details");
  const { removeQueryParam } = useSearchParams();

  const handleCloseDetails = () => {
    setIsDetailsActive(!isDetailsActive);
    setCurrentMarker({ lat: 0, lon: 0 });
    removeQueryParam("hash_id");
    setMapPerson({ person: {} } as ICurrentPerson);
  };

  const { setSearchParam, getSearchParam } = useSearchParams();
  useEffect(() => {
    const hashId = getSearchParam("hash_id");
    if (!hashId && person?.RECORD) {
      setSearchParam("hash_id", person.RECORD);
    }
  }, []);

  const { t: tDetails } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.objectDetails",
  });
  const title = person?.NAME;

  const isMobile = useMobileScreen();

  if (!person) return null;

  return (
    <AnimatedDetailsContainer isShown={isDetailsActive}>
      <DetailsHeader
        title={title}
        description={person && person?.ADDRESS}
        logo={<img src={Np} alt="" />}
        closeDetails={handleCloseDetails}
      />
      <DetailsContainer>
        <TabsWrapper>
          <Tab
            isActive={activeTab === "details"}
            onClick={() => setActiveTab("details")}
            text={tDetails("details.title")}
          />

          <Tab
            isActive={activeTab === "contacts"}
            onClick={() => setActiveTab("contacts")}
            text={tDetails("contacts.title")}
          />
        </TabsWrapper>
        <DetailsTabContent>
          {activeTab === "details" ? (
            <Details key={person && person.NAME} />
          ) : (
            <Contacts key={person && person.NAME} />
          )}
        </DetailsTabContent>
        {isMobile && (
          <StyledDetailsActions>
            <ShowOnMapButton lat={person.lon} lon={person.lat} />
          </StyledDetailsActions>
        )}
      </DetailsContainer>
    </AnimatedDetailsContainer>
  );
};

export default PersonDetails;
