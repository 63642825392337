import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import PersonsMap from "../../../components/_maps/persons/PersonsMap/PersonsMap";
import MapPage from "../../../share/MapPage/MapPage";
import Filters from "../../../components/_maps/persons/PersonFilters/Filters";
import PersonSearchResults from "../../../components/_maps/persons/PersonSearchResults/PersonSearchResults";
import DownloadResults from "../../../components/_maps/main/DownloadResults/DownloadResults";
import PersonDetails from "../../../components/_maps/persons/PersonDetails/PersonDetails";

const Persons = () => {
  const { personMapRef } = useTypedSelector((state) => state.mapObjectsInfo);
  const { setPersonMapRef } = useActions();
  const count = useTypedSelector((state) => state.mapPersonInfo.count);

  return (
    <MapPage
      filter={<Filters />}
      SearchResults={PersonSearchResults}
      downloadResults={<DownloadResults count={count} disabled />}
      objectDetails={<PersonDetails />}
      map={<PersonsMap />}
      mapRef={personMapRef}
      setMapRef={setPersonMapRef}
    ></MapPage>
  );
};

export default Persons;
