import styled from "@emotion/styled";
import { FC, ReactNode } from "react";
import { Close } from "../../../constants/icons";
import ShareIcon from "../../../assets/svg/share_outline.svg";
import CopyAndShareCurrentPageBlock from "../../SharingBlock/SharingBlock";
import { Popover } from "antd";

const StyledHeader = styled.div`
  background-color: var(--sixth-black-color);
  padding: 32px 24px 24px;
  border-bottom: 1px solid var(--fifth-black-color);
`;

const StyledHeaderRow = styled.div<{ isFlex?: boolean }>`
  display: ${(props) => (props.isFlex ? "flex" : "block")};
  justify-content: flex-end;
  min-height: 32px;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledTitle = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.0022em;
  color: var(--secondary-black-color);
  display: inline-block;
`;

const StyledTitleWrap = styled.div`
  margin-bottom: 16px;
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const StyledDescription = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  color: var(--third-black-color);
`;

const StyledButton = styled.button`
  width: 32px;
  height: 32px;
  background: var(--primary-white-color);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin-left: auto;
`;

const StyledShareButton = styled.button`
  display: inline-block;
  outline: none;
  border: none;
  background: none;

  & > img {
    display: block;
  }
`;

interface IDetailsHeader {
  title?: string;
  description?: string;
  closeDetails: () => void;
  logo?: JSX.Element | ReactNode;
}

const DetailsHeader: FC<IDetailsHeader> = ({
  title = "",
  description = "",
  closeDetails,
  logo = null,
}) => {
  return (
    <>
      <StyledHeader>
        <StyledHeaderRow isFlex={true}>
          {!!logo && logo}
          <StyledButton onClick={closeDetails}>
            <img src={Close} alt="close details" />
          </StyledButton>
        </StyledHeaderRow>
        <StyledHeaderRow>
          <StyledTitleWrap>
            {title && <StyledTitle>{title}</StyledTitle>}
            <Popover content={<CopyAndShareCurrentPageBlock />}>
              <StyledShareButton>
                <img src={ShareIcon} alt="share" />
              </StyledShareButton>
            </Popover>
          </StyledTitleWrap>
          {description && <StyledDescription>{description}</StyledDescription>}
        </StyledHeaderRow>
      </StyledHeader>
    </>
  );
};

export default DetailsHeader;
