import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { allActionCreators } from "../store/reducers/action-creators";
import { useMemo } from "react";

export const useActions = () => {
  const dispatch = useDispatch();
  return useMemo(
    () => bindActionCreators(allActionCreators, dispatch),
    [dispatch]
  );
};
