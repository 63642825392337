import { styled } from "@mui/material/styles";
import { Slider } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../Button/Button";
import { useEffect, useState } from "react";
import { breakpoints } from "../../constants";

const RangeSlider = styled(Slider)({
  color: "#FB8261",
  height: 8,
  margin: "6px 46px",
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 56,
    height: 56,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#F46A57",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const StyledWrapper = styled("div")`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledInputField = styled("input")`
  background: var(--primary-white-color);
  border: 1px solid var(--fifth-black-color);
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  height: 34px;
  box-shadow: none;
  font-family: "Roboto";
  padding: 4px 11px;
  flex: 1;
`;

const StyledForm = styled("form")`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin: 0 46px 16px;

  @media (max-width: ${breakpoints.small}px) {
    margin: auto;
    width: initial;
    gap: 4px;
    display: grid;
    grid-auto-columns: auto;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const FlexRangeSliderAlt = (props) => {
  const { min, max, value, onChange, onApply, isManualInput } = props;

  const appliedMin = value[0];
  const appliedMax = value[1];
  const [manualMin, setManualMin] = useState(appliedMin);
  const [manualMax, setManualMax] = useState(appliedMax);

  const applyNewRangeValues = (newRangeValue) => {
    onChange(newRangeValue);
    onApply(newRangeValue);
  };

  const handleSquareFilterChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    onChange(newValue);
  };

  function handleSquareFilterChangeCommitted(
    event: Event,
    newValue: number | number[]
  ) {
    onApply(newValue);
  }

  const handleMinInput = (event) => {
    const newMin = parseInt(event.target.value);
    setManualMin(newMin);
  };

  const handleMaxInput = (event) => {
    const newMax = parseInt(event.target.value);
    setManualMax(newMax);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    applyNewRangeValues([manualMin, manualMax]);
  };

  const { t } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.mobileFiltersActions",
  });

  const calcButtonType =
    appliedMin === manualMax && appliedMax === manualMax ? "dark" : "red";

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    if (manualMax < manualMin) {
      setIsButtonDisabled(true);
    } else if (manualMin > manualMax) {
    } else {
      setIsButtonDisabled(false);
    }
  }, [manualMax, manualMin]);

  useEffect(() => {
    setManualMin(appliedMin);
    setManualMax(appliedMax);
  }, [appliedMax, appliedMin]);

  return (
    <StyledWrapper>
      {isManualInput && (
        <StyledForm onSubmit={handleSubmit}>
          <StyledInputField
            type={"number"}
            min={min}
            max={max}
            placeholder={min}
            value={manualMin}
            onChange={handleMinInput}
          />
          <StyledInputField
            value={manualMax}
            type={"number"}
            min={min}
            max={max}
            placeholder={max}
            onChange={handleMaxInput}
          />
          <CustomButton
            size="small"
            disabled={isButtonDisabled}
            color={calcButtonType}
            htmlType="submit"
          >
            {t("applyFilter")}
          </CustomButton>
        </StyledForm>
      )}
      <RangeSlider
        {...props}
        valueLabelDisplay={isManualInput ? "off" : "auto"}
        onChange={handleSquareFilterChange}
        onChangeCommitted={handleSquareFilterChangeCommitted}
      />
    </StyledWrapper>
  );
};

export default FlexRangeSliderAlt;
