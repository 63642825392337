import React, { useEffect, useState } from "react";
import styles from "./DownloadResults.module.scss";

import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import Attention from "../../../../assets/svg/AttentionHand.svg";
import ModalWithHeader from "../../../../share/ModalWithHeader/ModalWithHeader";
import { useTranslation } from "react-i18next";
import useSearchParams from "../../../../hooks/useSearchParams";
import { regions } from "../../../../constants/mapConstants";
import SimulatedProgressBar from "../../../../share/SimulatedProgressBar";

import ArrowRightDisable from "../../../../assets/svg/lilArrowRightDisable.svg";
import { colors, icons } from "../../../../constants";
import moment from "moment";
import useAppContext from "../../../../hooks/useAppContext";
import useStoreSnippets from "../../../../hooks/useStoreSnippets";
import SubscribeNotificationPopup from "../../../../share/NotificationPopInformation/SubscribeNotificationPopup/SubscribeNotificationPopup";
import { useTipsContext } from "../../../../hooks/useTips";
import { subscriptionTypeToMaxDownloadLimit } from "../../../../constants/constants";
import { Text16 } from "../../../../share/textComponents/textComponents";
import styled from "@emotion/styled";

const StyledDownloadsLimitText = styled(Text16)`
  margin-top: -16px;
  font-weight: 400;
  color: ${colors.darkD200};
`;
interface Props {
  count: number;
  disabled?: boolean;
}

const DownloadResults: React.FC<Props> = ({ disabled = false, count = 0 }) => {
  const { offset, page } = useTypedSelector((state) => state.mapInterface);
  const { id: currAccId, subscribe } = useTypedSelector(
    (state) => state.currentAccount
  );
  const {
    setOffset,
    setPage,
    backgroundDownloadObjects,
    setLastDownloadingUrl,
    getCurrentAccount,
  } = useActions();
  const [modalVisible, setModalVisible] = useState(false);
  const maxAvailableCountToDownload = subscribe?.permissions?.download || 0;
  const selectedRegion = useSearchParams().getSearchParam("region");
  const selectedDataset = useSearchParams().getSearchParam("dataset");
  const downloadBtnRef = React.createRef<HTMLButtonElement>();

  const tipId = "loginToDownloadResults";
  const { setLastOpenedTipId } = useTipsContext();

  const { isGuestAccount, isFreeAccount, subscriptionName } =
    useStoreSnippets();
  const isDisabledForRegion = [
    regions.en as string,
    regions.no as string,
  ].includes(selectedRegion);
  const { url, isDownloading, error } = useTypedSelector(
    (state) => state.downloads
  );

  const isDownloadDisabled =
    isDownloading || count === 0 || isDisabledForRegion;
  const pageCount = Math.ceil(count / 20);

  const { t: tFilter } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.downloadResults",
  });

  const { t: tDataset } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.downloadResults.datasets",
  });

  // subscribeToDownloadResults
  const { t: tNotifications } = useTranslation("translation", {
    keyPrefix: "notifications",
  });

  const showRegisterNotificationPopup = () => {
    setLastOpenedTipId(tipId);
  };

  const downloadButtonText = isDownloading
    ? tFilter("dataDownloading") + "..."
    : tFilter("downloadData");
  const handleDecrement = () => {
    setPage(page - 1);
    setOffset(offset - 20);
  };

  const handleIncrement = () => {
    setPage(page + 1);
    setOffset(offset + 20);
  };

  useEffect(() => {
    return () => {
      setLastDownloadingUrl(null);
    };
  }, []);

  const { notificationApi } = useAppContext();

  const openIsDownloadingNotification = async () => {
    notificationApi.open(
      {
        placement: "top",
        message: tFilter("dataDownloading") + "...",
        description: (
          <div>
            <SimulatedProgressBar />
            <p>{tFilter("youWillBeNotifiedWhenDownloadingWillBeFinished")}.</p>
          </div>
        ),
        duration: 0,
      },
      true,
      {
        description: tFilter("youWillBeNotifiedWhenDownloadingWillBeFinished"),
      }
    );
  };

  const openSuccessfullyDownloadedNotification = (url) => {
    const simpleTimeAndDate = moment().format("MMMM, D, YYYY, HH-mm-ss");
    const filename = "Aimap filtered data " + simpleTimeAndDate;
    const urlsFromLocalStorage = sessionStorage.getItem("recentDownloads");
    const urls = urlsFromLocalStorage ? JSON.parse(urlsFromLocalStorage) : [];
    urls.push({
      url,
      filename,
    });
    sessionStorage.setItem("recentDownloads", JSON.stringify(urls));

    notificationApi.success({
      placement: "top",
      message: tFilter("yourDataIsReady"),
      description: (
        <>
          <a href={url} download={filename}>
            <span>{tFilter("clickHereToDownload")}</span>
          </a>
        </>
      ),
      duration: 10,
    });
  };

  useEffect(() => {
    if (url) {
      openSuccessfullyDownloadedNotification(url);
      //getCurrentAccount() to update downloaded number from backend;
      getCurrentAccount(currAccId);
    }
  }, [url]);

  useEffect(() => {
    if (error) {
      notificationApi.error({
        placement: "top",
        message: tFilter("error"),
        description: tFilter("errorDescription"),
        duration: 10,
      });
    }
  }, [error]);

  // useEffect(() => {
  //   if (isDownloading) {

  //   }
  // }, [isDownloading]);

  const handleDownload = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    backgroundDownloadObjects(selectedRegion as any);
    openIsDownloadingNotification();
    setModalVisible(false);
    await new Promise((resolve) => setTimeout(resolve, 500));
  };

  const DownloadModal = () => (
    <ModalWithHeader
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      title={
        maxAvailableCountToDownload === 0
          ? tFilter("unableToDownloadData")
          : maxAvailableCountToDownload > 0 &&
            maxAvailableCountToDownload > count
          ? tFilter("dataDownloading", "Data loading")
          : tFilter("limitExceeded")
      }
    >
      {maxAvailableCountToDownload === 0 && (
        <>
          <div>
            <div className={styles.modalInfoWrapper}>
              <img src={Attention} alt="attention" />
              <p className={styles.infoText}>{tFilter("packageUpdateLimit")}</p>
            </div>
            <div className={styles.bodyWrapper}>
              <p className={styles.bodyText}>{tFilter("planUpdateWarn")}</p>
            </div>
          </div>
          <div className={styles.modalFooter}>
            <a
              href="https://aimap.aimarketing.info/uk/buy"
              target="_blank"
              rel="noreferrer"
              className={styles.modalButton}
            >
              {tFilter("updateSubscriptionPlan")}
              <img src={icons.ArrowNext} alt="next" />
            </a>
          </div>
        </>
      )}
      {maxAvailableCountToDownload > 0 &&
        maxAvailableCountToDownload > count && (
          <>
            <div className={styles.modalAcceptWrapper}>
              <p className={styles.modalAcceptText}>
                {tFilter("downloadQuestion.youSure")}{" "}
                <span>
                  {count} {tFilter("downloadQuestion.objects")}
                </span>
                ? {tFilter("downloadQuestion.limitChange")}.
              </p>
            </div>
            <div className={styles.modalFooter}>
              <button
                onClick={(e) => handleDownload(e)}
                className={
                  isDownloadDisabled || maxAvailableCountToDownload === 0
                    ? `${styles.downloadBtn} ${styles.disable}`
                    : styles.downloadBtn
                }
                disabled={
                  isDownloadDisabled || maxAvailableCountToDownload === 0
                }
              >
                {downloadButtonText}
              </button>
            </div>
          </>
        )}
      {count > maxAvailableCountToDownload &&
        maxAvailableCountToDownload !== 0 && (
          <>
            <div className={styles.modalAcceptWrapper}>
              <p className={styles.modalAcceptText}>
                {tFilter("downloadLimitExceed.firstPart")}{" "}
                <span>
                  {maxAvailableCountToDownload}{" "}
                  {tFilter("downloadLimitExceed.lastPart")}
                </span>
                .
                <br />
                <br />
                {tFilter("updateSubscriptionPlanToExceed")}
              </p>
            </div>
            <div className={styles.thirdModalFooter}>
              <a
                href="https://aimap.aimarketing.info/uk/buy"
                target="_blank"
                rel="noreferrer"
                className={styles.modalButton}
              >
                {tFilter("updateSubscriptionPlan")}
              </a>
              <button
                className={`${styles.downloadBtn} ${styles.disable}`}
                disabled
              >
                <span>{downloadButtonText}</span>
              </button>
            </div>
          </>
        )}
    </ModalWithHeader>
  );

  const CountOfResults = () => (
    <div className={styles.label}>
      <span>{tFilter("result")}: </span>
      <span>
        {count} {tDataset(selectedDataset)}
      </span>
    </div>
  );

  const PrevNextButtons = () => (
    <div className={styles.pagesButtons}>
      <button
        className={styles.arrowBtn}
        onClick={handleDecrement}
        disabled={page <= 1}
      >
        {page <= 1 ? (
          <img
            src={icons.ArrowLeftDisable}
            alt="pagination prev"
            className="arrowImg"
          />
        ) : (
          <img
            src={icons.lilArrowLeft}
            alt="pagination prev"
            className="arrowImg"
          />
        )}
      </button>
      <button
        className={styles.arrowBtn}
        onClick={handleIncrement}
        disabled={page >= pageCount}
      >
        {page >= pageCount ? (
          <img
            src={ArrowRightDisable}
            alt="pagination next"
            className="arrowImg"
          />
        ) : (
          <img
            src={icons.lilArrowRight}
            alt="pagination next"
            className="arrowImg"
          />
        )}
      </button>
    </div>
  );

  const handleClickDownloadBtn = () => {
    if (isGuestAccount || isFreeAccount) {
      showRegisterNotificationPopup();
    } else {
      setModalVisible(true);
    }
  };

  return (
    <>
      <div className={styles.DownloadResults}>
        <div className={styles.pagination}>
          <CountOfResults />
          {count > 20 && (
            <div className={styles.pagesNum}>
              <div className={styles.currentPage}>
                {page} / {pageCount}
              </div>
              <PrevNextButtons />
            </div>
          )}
        </div>
        <SubscribeNotificationPopup
          message={tNotifications("subscribeToDownloadResults")}
          bottomPx={20}
          rightPx={-408}
          cornerTopPosition={200}
          id={tipId}
        />
        {!isDisabledForRegion && (
          <button
            onClick={handleClickDownloadBtn}
            className={
              isDownloadDisabled
                ? `${styles.downloadBtn} ${styles.disable}`
                : styles.downloadBtn
            }
            disabled={isDownloadDisabled}
            ref={downloadBtnRef}
          >
            {downloadButtonText}
            {!isDownloading && (
              <img
                className={styles.downloadIcon}
                src={icons.Download}
                alt="Download"
              />
            )}
          </button>
        )}
        {maxAvailableCountToDownload > 0 && !isDownloadDisabled && (
          <StyledDownloadsLimitText>
            {tFilter("downloadLimit", "Download limit")}:{" "}
            {maxAvailableCountToDownload} /{" "}
            {subscriptionTypeToMaxDownloadLimit[subscriptionName]}{" "}
          </StyledDownloadsLimitText>
        )}
      </div>

      <DownloadModal />
    </>
  );
};

export default DownloadResults;
