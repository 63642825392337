import { FC } from "react";
import { ITemplate } from "../../../../types";
import styled from "@emotion/styled";
import TemplateListItem from "./TemplateListItem";
import { Empty } from "antd";
import CustomButton from "../../../../share/Button/Button";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { CustomLoader } from "../../../../share/Loader/Loader";
import { useActions } from "../../../../hooks/useActions";
import { colors } from "../../../../constants";
import { useTranslation } from "react-i18next";

function sortByLastUpdated(a: ITemplate, b: ITemplate) {
  return new Date(b.date_updated).getTime() - new Date(a.date_added).getTime();
}

const StyledTemplateList = styled.ul`
  list-style-type: none;
`;
const StyledTemplateItem = styled.li`
  padding: 24px 0;
  border-bottom: 1px solid #e0e0e0;
  &:first-of-type {
    padding-top: 0;
  }
`;

const StyledNoResultText = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${colors.darkD300};
`;

interface ITemplateList {
  templateList: ITemplate[];
}

const TemplateList: FC<ITemplateList> = ({ templateList }) => {
  const { isLoading } = useTypedSelector((state) => state.templates);
  const { toggleFilter } = useActions();

  function handleCreateTemplate() {
    toggleFilter("addSavedFiltersModal");
  }

  const { t: tList } = useTranslation("translation", {
    keyPrefix: "filterTemplate.list",
  });
  const { t: tFilterTemplate } = useTranslation("translation", {
    keyPrefix: "filterTemplate",
  });

  if (isLoading) return <CustomLoader />;
  if (!templateList.length)
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <StyledNoResultText>{tList("noItems")}</StyledNoResultText>
        }
      >
        <CustomButton type="bordered" onClick={handleCreateTemplate}>
          {tFilterTemplate("createNow")}
        </CustomButton>
      </Empty>
    );
  return (
    <StyledTemplateList>
      {[...templateList].sort(sortByLastUpdated).map((template) => (
        <StyledTemplateItem key={template.id}>
          <TemplateListItem key={template.id} template={template} />
        </StyledTemplateItem>
      ))}
    </StyledTemplateList>
  );
};

export default TemplateList;
