import styled from "@emotion/styled";
import { FC } from "react";
import CustomIcon from "../../../share/CustomIcon/CustomIcon";
import { ReactComponent as AngleLeft } from "../../../assets/svg/AngleLeft_nofill.svg";
import { colors } from "../../../constants";
import CustomButton from "../../../share/Button/Button";
import MainScreen from "./MainScreen/MainScreen";
import useMobileProfile from "./useMobileProfile";
import BasicInfoScreen from "./BasicInfoScreen/BasicInfoScreen";
import ContactInfoScreen from "./ContactInfoScreen/ContactInfoScreen";
import LanguageScreen from "./LanguageScreen/LanguageScreen";
import { useTranslation } from "react-i18next";

const StyledScreen = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  inset: 0 0 0 0;
`;

const StyledContentContainer = styled.div<{ isSecondaryScreen?: boolean }>`
  padding: 24px 16px 0;
  flex: 1;
  background: #fefefe;
  overflow: auto;
  ${(props) => props.isSecondaryScreen && `padding-bottom: 24px;`}
`;

const StyledPrevPageContainer = styled.div`
  padding: 8px 16px;
`;
const StyledHeaderContainer = styled.div`
  padding: 8px 16px;
  border-bottom: 1px solid #e0e0e0;
`;

const StyleButtonContainer = styled.div`
  padding: 24px 16px;
  flex: 0;
  & > * {
    width: 100%;
  }
`;

const StyledScreenTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 30px; /* 125% */
`;
const StyledPreviousPageButton = styled.button`
  color: ${colors.darkD300};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
  background: none;
  border: none;
  outline: none;
`;

const MobileProfile: FC = () => {
  const {
    currentScreen,
    switchScreen,
    isMainScreen,
    handlePreviousButtonClick,
  } = useMobileProfile();

  const screenMap = {
    main: <MainScreen switchScreen={switchScreen} />,
    basic: <BasicInfoScreen />,
    contact: <ContactInfoScreen />,
    language: <LanguageScreen />,
  };

  const { t } = useTranslation("translation", { keyPrefix: "profilePage" });

  return (
    <StyledScreen>
      <StyledPrevPageContainer>
        <StyledPreviousPageButton onClick={handlePreviousButtonClick}>
          <CustomIcon icon={AngleLeft} color={colors.darkD300} />
          {t("previousPage")}
        </StyledPreviousPageButton>
      </StyledPrevPageContainer>
      <StyledHeaderContainer>
        <StyledScreenTitle>{t("title")}</StyledScreenTitle>
      </StyledHeaderContainer>
      <StyledContentContainer isSecondaryScreen={!isMainScreen}>
        {screenMap[currentScreen]}
      </StyledContentContainer>
      {isMainScreen && (
        <StyleButtonContainer>
          <CustomButton
            color={"dark"}
            type={"bordered"}
            size={"medium"}
            fullWidth={true}
            onClick={handlePreviousButtonClick}
          >
            {t("previousPage")}
          </CustomButton>
        </StyleButtonContainer>
      )}
    </StyledScreen>
  );
};

export default MobileProfile;
