import React, { FC, useEffect, useState } from "react";
import {
  CheckboxList,
  FilterBody,
  FilterSection,
  FilterSections,
} from "../../../../../share/filters";
import StageDocumentationFilter from "./StageDocumentationFilter/StageDocumentationFilter";
import { FilterTitleWithInfo } from "../../../../../share/filters/FilterTitleWithInfo";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { useActions } from "../../../../../hooks/useActions";
import { useTranslation } from "react-i18next";
import { CustomCheckbox } from "../../../../../share/Input/Input";
import util from "../../../../../util";
import styled from "@emotion/styled";
import ShowMoreButton from "../../../../../share/ShowMoreButton/ShowMoreButton";
import RangeSlider from "../../../../../share/RangeSlider/RangeSlider";
import { AvailableCC, maxSquare } from "../../../../../constants/constants";
import {
  stageFilterTranslationMap,
  typeFilterTranslationMap,
} from "../../../../../constants/filterTranslationMapping";
import useStoreSnippets from "../../../../../hooks/useStoreSnippets";
import SubscribeNotificationPopup from "../../../../../share/NotificationPopInformation/SubscribeNotificationPopup/SubscribeNotificationPopup";
import { regions } from "../../../../../constants/mapConstants";
import useSearchParams from "../../../../../hooks/useSearchParams";
import DisabledDiv from "../../../../../share/DisabledDiv/DisabledDiv";
import { useTipsContext } from "../../../../../hooks/useTips";

const StyledFilterCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  &:not(:first-child) {
    flex-wrap: wrap;
  }
  & + * {
    margin-top: 16px;
  }
`;

interface IOtherFiltersBody {
  hasShowMore?: boolean;
}

const OtherFiltersBody: FC<IOtherFiltersBody> = ({ hasShowMore = false }) => {
  const { constructionType, stageBuilding, squareFilter } = useTypedSelector(
    (state) => state.mapFilters
  );
  const { stage_building, construction_type } = useTypedSelector(
    (state) => state.mapObjectsInfo.mapObjects.params
  );

  const [squareRange, setSquareRange] = useState<number[]>([0, maxSquare]);

  const { isGuestAccount, isFreeAccount } = useStoreSnippets();
  useEffect(() => {
    if (squareFilter.length > 0) {
      setSquareFilter(squareFilter);
      setSquareRange(squareFilter as number[]);
    } else {
      setSquareFilter([0, maxSquare]);
      setSquareRange([0, maxSquare]);
    }
  }, []);

  useEffect(() => {
    if (squareFilter.length > 0) {
      setSquareRange(squareFilter as number[]);
    } else {
      setSquareRange([0, maxSquare]);
    }
  }, [squareFilter]);

  const {
    toggleBuildFilterObject,
    toggleConstructionFilterObject,
    setSquareFilter,
    applyFilter,
  } = useActions();

  const handleBuildChange = (type: string) => {
    toggleBuildFilterObject(type);
    applyFilter();
  };

  const handleConstructionChange = (type: string) => {
    toggleConstructionFilterObject(type);
    applyFilter();
  };

  const handleSquareFilterChange = (newValue: number | number[]) => {
    if (isGuestAccount || isFreeAccount) {
      setLastOpenedTipId(tipId);
    } else setSquareRange(newValue as number[]);
  };

  const handleSquareFilterApply = (newValue) => {
    setSquareFilter(newValue as number[]);
    applyFilter();
  };

  const { t } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.aimap.otherFilter",
  });

  const { t: tMapFilters } = useTranslation("translation", {
    keyPrefix: "mapUI.filters",
  });

  const { t: tTypeFilterOption } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.aimap.otherFilter.typeFilterValues",
  });

  const { t: tStageFilterOption } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.aimap.otherFilter.stageFilterValues",
  });

  const { t: tNotification } = useTranslation("translation", {
    keyPrefix: "notifications",
  });

  const noFilterOptions = tMapFilters("noFilterOptions", "no filter options");

  const [constructFilterLimit, setConstructFilterLimit] = useState<number>(
    hasShowMore ? 3 : construction_type.length
  );

  const handleChangeConstructFilterLimit = () => {
    if (constructFilterLimit === 3)
      setConstructFilterLimit(construction_type.length);
    if (constructFilterLimit !== 3) setConstructFilterLimit(3);
  };

  const selectedRegion = useSearchParams().getSearchParam("region");
  const isSquareFilterShown = selectedRegion === regions.ua;

  function valuetext(value: number) {
    return `${value}m2`;
  }

  const isUaRegion =
    useSearchParams().getSearchParams().region === AvailableCC.ua;

  const showSubscribeNotification = () => {
    if (isGuestAccount || isFreeAccount) {
      setLastOpenedTipId(tipId);
    }
  };
  const tipId = "subscribeToFilterBySquare";
  const { setLastOpenedTipId } = useTipsContext();
  return (
    <FilterBody>
      <SubscribeNotificationPopup
        id={tipId}
        bottomPx={524}
        rightPx={-356}
        message={tNotification("subscribeToFilterBySquare")}
      />
      <FilterSections>
        {isSquareFilterShown && (
          <DisabledDiv
            isDisabled={isGuestAccount || isFreeAccount}
            onClick={showSubscribeNotification}
          >
            <FilterSection>
              <FilterTitleWithInfo
                title={t("objectSquare", "Object square, m2")}
              />
              <RangeSlider
                isManualInput={true}
                onChange={handleSquareFilterChange}
                onApply={handleSquareFilterApply}
                value={squareRange}
                getAriaLabel={() => "Temperature range"}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                max={maxSquare}
                min={0}
              />
            </FilterSection>
          </DisabledDiv>
        )}
        <StageDocumentationFilter />
        <FilterSection>
          <FilterTitleWithInfo title={t("constructionType")} />
          <StyledFilterCheckboxWrapper>
            {construction_type?.length ? (
              construction_type
                .slice(0, constructFilterLimit)
                .sort(util.toSortedAlphabeticCallback)
                .map((type: string) => {
                  const optionName = isUaRegion
                    ? tTypeFilterOption(typeFilterTranslationMap[type])
                    : type;
                  return (
                    <CustomCheckbox
                      value={type}
                      key={type}
                      checked={constructionType.includes(type)}
                      onChange={() => handleConstructionChange(type)}
                    >
                      {optionName.length > 45
                        ? optionName.slice(0, 45) + ".."
                        : optionName}
                    </CustomCheckbox>
                  );
                })
            ) : (
              <div>{noFilterOptions}</div>
            )}
          </StyledFilterCheckboxWrapper>
          {hasShowMore && (
            <ShowMoreButton
              onClick={handleChangeConstructFilterLimit}
              opened={constructFilterLimit > 3}
            >
              {t("showAllConstructionTypes")}
            </ShowMoreButton>
          )}
        </FilterSection>
        {stage_building && (
          <FilterSection>
            <FilterTitleWithInfo title={t("constructionStage")} />
            <CheckboxList inRow>
              {stage_building?.length ? (
                stage_building
                  .sort(util.toSortedAlphabeticCallback)
                  .map((type: string) => (
                    <CustomCheckbox
                      value={type}
                      key={type}
                      checked={stageBuilding.includes(type)}
                      onChange={() => handleBuildChange(type)}
                    >
                      {isUaRegion
                        ? tStageFilterOption(stageFilterTranslationMap[type])
                        : type}
                    </CustomCheckbox>
                  ))
              ) : (
                <div>{noFilterOptions}</div>
              )}
            </CheckboxList>
          </FilterSection>
        )}
      </FilterSections>
    </FilterBody>
  );
};

export default OtherFiltersBody;
