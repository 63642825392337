import Navbar from "../../components/nav/Navbar/Navbar";
import BreadcrumbNav from "../../components/nav/BreadcrumbNav/BreadcrumbNav";
import Heading from "../Heading/Heading";
import UserProfileBar from "../../components/UserProfileBar/UserProfileBar";
import styled from "@emotion/styled";
import { breakpoints } from "../../constants";
import React from "react";

const AppWrapper = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  @media screen and (max-width: ${breakpoints.small}px) {
    flex-direction: column-reverse;
  }
`;

const PageContainer = styled.div<{
  hasPadding: boolean;
  isScrollable: boolean;
}>`
  width: 100%;
  height: 100%;
  position: relative;
  padding: ${(props) => (props.hasPadding ? "32px" : "0")};
  overflow-y: ${(props) => (props.isScrollable ? "scroll" : "auto")};
`;

const StyledPageHeader = styled.div<{ isEmpty: boolean }>`
  display: flex;
  margin-bottom: ${(props) => (props.isEmpty ? "0" : "48px")};
`;

const StyledBreadcrumbWrapper = styled.div`
  display: grid;
  gap: 4px;
`;

interface IProps {
  pageHeaderText?: string;
  isBreadcrumb?: boolean;
  isNavbar?: boolean;
  hasPadding?: boolean;
  contentScroll?: boolean;
}

const Page: React.FC<IProps> = ({
  pageHeaderText = "",
  isBreadcrumb = false,
  hasPadding = true,
  contentScroll = true,
  children,
}) => {
  return (
    <AppWrapper>
      <Navbar />
      <PageContainer hasPadding={hasPadding} isScrollable={contentScroll}>
        <StyledPageHeader isEmpty={!isBreadcrumb && !pageHeaderText}>
          <StyledBreadcrumbWrapper>
            {isBreadcrumb && <BreadcrumbNav />}
            {pageHeaderText && <Heading type="h2">{pageHeaderText}</Heading>}
          </StyledBreadcrumbWrapper>
          <UserProfileBar isWithFilterPopupButton={true} />
        </StyledPageHeader>
        {children}
      </PageContainer>
    </AppWrapper>
  );
};

export default Page;
