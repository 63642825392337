import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import LoginButton from "../buttons/LoginButton";

const GuestAccountMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0;
  gap: 16px;
`;

const LoginToViewMessage: React.FC<{ text?: string }> = ({ text }) => {
  const { t: tMainPage } = useTranslation("translation", {
    keyPrefix: "mainPage",
  });
  return (
    <GuestAccountMessageWrapper>
      {text ? text : tMainPage("authToViewContacts")}
      <LoginButton />
    </GuestAccountMessageWrapper>
  );
};

export default LoginToViewMessage;
