import { useState } from "react";
import styles from "./SubscribeManagement.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { RouteNames } from "../../../routes";
import Info from "../../../assets/svg/infoCircle.svg";
import ButtonMenu from "../../../assets/svg/Button_menu.svg";
import AttentionHand from "../../../assets/svg/AttentionHand.svg";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { format, parseISO } from "date-fns";
import { useActions } from "../../../hooks/useActions";
import { ClickAwayListener } from "@mui/base";
import { useTranslation } from "react-i18next";
import { currencies } from "../../../constants/constants";
import useDateLocale from "../../../hooks/useDateLocale";
import useMobileScreen from "../../../hooks/useMobileScreen";

const SubscribeManagement = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const { subscribe, users } = useTypedSelector(
    (state) => state.currentAccount
  );
  const { unSubscribe } = useActions();

  const { t: tSubManage } = useTranslation("translation", {
    keyPrefix: "paymentsPage.tabList.subManage",
  });
  const { t: tSubscription } = useTranslation("translation", {
    keyPrefix: "paymentsPage.tabList.subManage.subscription",
  });
  const { t: tPayment } = useTranslation("translation", {
    keyPrefix: "paymentsPage.tabList.subManage.payment",
  });

  const setStopSubscription = () => {
    setShowMenu(false);
    unSubscribe();
  };

  const { selectedLocale } = useDateLocale();

  const isMobile = useMobileScreen();

  const suspended = (
    <div className={styles.pausedSubInfo}>
      <img src={AttentionHand} alt="" />
      <p>
        {tSubscription("suspended")}{" "}
        {format(parseISO(subscribe?.expiration_date), "dd.MM.yyyy", {
          locale: selectedLocale,
        })}
      </p>
    </div>
  );

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.mainWrapperColumn}>
        <div className={styles.infoWrapper}>
          <img src={Info} alt="info" />
          <p>{tSubManage("description")}</p>
          <NavLink to={RouteNames.ACCOUNTS}>{tSubManage("accounts")}</NavLink>
        </div>
        <div className={styles.actualSubscribesWrapper}>
          <h3 className={styles.subsTitle}>
            {tSubManage("activeSubscribesTitle")}
          </h3>
          <div className={styles.subWrapper}>
            <div className={styles.subHeader}>
              <h4 className={styles.subName}>AIMap Objects</h4>
              {!isMobile && subscribe?.status !== "paused" && suspended}
              <button
                type="button"
                onClick={() => setShowMenu(!showMenu)}
                className={
                  subscribe?.card_name === "FREE" ||
                  subscribe?.status === "paused"
                    ? styles.display
                    : styles.showMoreButton
                }
              >
                <img src={ButtonMenu} alt="menu" />
              </button>
              {showMenu && (
                <ClickAwayListener onClickAway={() => setShowMenu(false)}>
                  <div className={styles.menu}>
                    <button onClick={setStopSubscription} type="button">
                      {tSubscription("unsubscribe")}
                    </button>
                  </div>
                </ClickAwayListener>
              )}
            </div>
            {isMobile && subscribe?.status !== "paused" && suspended}
            <div className={styles.subBody}>
              <div className={styles.subCategory}>
                <p className={styles.subCategoryName}>
                  {tSubscription("usersLabel")}:
                </p>
                <p className={styles.subCategoryDesc}>
                  {users?.length}/{subscribe?.permissions?.max_users}{" "}
                  {tSubscription("usersLabel")}
                </p>
              </div>
              <div className={styles.subCategory}>
                <p className={styles.subCategoryName}>
                  {tSubscription("nextPaymentDate")}:
                </p>
                <p className={styles.subCategoryDesc}>
                  {subscribe?.card_name === "FREE"
                    ? "-"
                    : format(
                        parseISO(subscribe?.expiration_date),
                        "dd MMMM yyyy",
                        { locale: selectedLocale }
                      )}
                </p>
              </div>
              <div className={styles.subCategory}>
                <p className={styles.subCategoryName}>
                  {tSubscription("estimatedCost")}:
                </p>
                <p className={styles.subCategoryDesc}>
                  {subscribe?.annual_cost
                    ? `${subscribe?.annual_cost} ${currencies.dollar}`
                    : tSubscription("free")}
                </p>
              </div>
            </div>
            <div className={styles.subFooter}>
              <button
                onClick={() => navigate(RouteNames.SUBSCRIPTIONS)}
                className={styles.planName}
              >
                {subscribe?.card_name} | <span>{tSubscription("change")}</span>
              </button>
              <button
                onClick={() => navigate(RouteNames.ACCOUNT_USERS)}
                className={styles.userManagement}
              >
                {tSubscription("userManagement")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.secondaryWrapperColumn}>
        <div className={styles.paymentContainer} style={{ display: "none" }}>
          <h5>{tPayment("method")}</h5>
          <p>{tPayment("description")}</p>
          <button>{tPayment("annualPayment")}</button>
        </div>
        <div className={styles.sumPayment}>
          <h5 className={styles.sumPaymentTitle}>
            {tPayment("paymentAmount")}:
          </h5>
          <p className={styles.subDate}>
            {subscribe.card_name === "FREE"
              ? "-"
              : format(parseISO(subscribe?.create_date), "dd MMMM yyyy", {
                  locale: selectedLocale,
                }) +
                "  -  " +
                format(parseISO(subscribe?.expiration_date), "dd MMMM yyyy", {
                  locale: selectedLocale,
                })}
          </p>
          <div className={styles.paymentPrices}>
            <div>
              <p>AIMap Objects</p>
              <span>{subscribe?.card_name}</span>
            </div>
            <p>
              {subscribe?.annual_cost} {currencies.dollar}
            </p>
          </div>
          <div className={styles.sumResult}>
            <p>{tPayment("total")}:</p>
            <p>
              {subscribe?.annual_cost
                ? `${subscribe?.annual_cost} ${currencies.dollar}`
                : tPayment("free")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribeManagement;
