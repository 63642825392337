import React from "react";
import { Table, Button } from "antd";
import SimplePage from "../../share/Page/SimplePage";
import { useTranslation } from "react-i18next";

interface IDownload {
  url: string;
  filename: string;
}

const RecentDownloads: React.FC = () => {
  const urlsFromSessionStorage = sessionStorage.getItem("recentDownloads");
  const urls: IDownload[] = urlsFromSessionStorage
    ? JSON.parse(urlsFromSessionStorage)
    : [];

  const { t } = useTranslation();
  const title = t("recentDownloadsPage.title");
  const downloadText = t("recentDownloadsPage.download");

  const handleDownload = (url: string, filename: string) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const columns = [
    {
      title: t("recentDownloadsPage.filename"),
      dataIndex: "filename",
      key: "filename",
    },
    {
      title: t("recentDownloadsPage.actions"),
      key: "action",
      render: (text: string, record: IDownload) => (
        <Button onClick={() => handleDownload(record.url, record.filename)}>
          {downloadText}
        </Button>
      ),
    },
  ];

  return (
    <SimplePage title={title}>
      <Table columns={columns} dataSource={urls} />
    </SimplePage>
  );
};

export default RecentDownloads;
