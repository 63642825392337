import { formatDistanceToNow } from "date-fns";

export const formatDateToAgo = (timestamp) => {
  const date = new Date(timestamp);

  return formatDistanceToNow(date, { addSuffix: true });
};

const dateUtil = {
  formatDateToAgo,
};
export default dateUtil;
