import React, { ReactNode, useEffect } from "react";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { CustomLoader } from "../../share/Loader/Loader";
import i18n from "../../i18n";
import FeedbackModal from "../FeedbackModal";

interface IProps {
  children: ReactNode;
}

const PrivateRoute: React.FC<IProps> = ({ children }) => {
  const { getUser, getAccounts, getCurrentAccount } = useActions();
  const currentUser = useTypedSelector((store) => store.currentUser);
  const { accounts } = useTypedSelector((state) => state);
  const { id } = useTypedSelector((state) => state.currentAccount);

  const isUserDataLoading = currentUser.isLoading || !currentUser.id;
  useEffect(() => {
    if (!currentUser.id) {
      getUser();
    }
    i18n.changeLanguage(currentUser.language);
  }, []);

  useEffect(() => {
    getAccounts();
  }, []);

  useEffect(() => {
    if (accounts?.length) {
      if (!id) {
        getCurrentAccount(accounts[0].id);
      }
    }
  }, [accounts]);

  return (
    <>
      <FeedbackModal />
      {isUserDataLoading ? <CustomLoader /> : <>{children}</>}
    </>
  );
};

export default PrivateRoute;
