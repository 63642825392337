import { Select } from "antd";
import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";

interface ICustomSelectPeriod {
  onPeriodSelect: (value: string) => void;
  isDisabled?: boolean;
  value: string;
}

enum Periods {
  week = "7",
  month = "30",
  quarter = "90",
}

const CustomSelectPeriod: FC<ICustomSelectPeriod> = ({
  onPeriodSelect,
  isDisabled = false,
  value,
}) => {
  const dropdownContainerRef = useRef<HTMLDivElement>(null);

  const { t: tSelect } = useTranslation("translation", {
    keyPrefix: "filterTemplate.form.mailingPeriod",
  });

  const { t: tPeriod } = useTranslation("translation", {
    keyPrefix: "filterTemplate.form.period",
  });

  return (
    <div ref={dropdownContainerRef}>
      <Select
        disabled={isDisabled}
        defaultValue={Periods.week}
        onChange={onPeriodSelect}
        placeholder={tSelect("placeholder")}
        getPopupContainer={() => dropdownContainerRef.current}
        value={value}
        options={[
          {
            value: Periods.week,
            label: tPeriod("week"),
          },
          {
            value: Periods.month,
            label: tPeriod("month"),
          },
          {
            value: Periods.quarter,
            label: tPeriod("quarter"),
          },
        ]}
      />
    </div>
  );
};

export default CustomSelectPeriod;
