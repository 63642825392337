import styled from "@emotion/styled";
import { ButtonHTMLAttributes, FC } from "react";
import { breakpoints, zIndexes } from "../../../constants";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import useIsFilterActive from "../../../hooks/useIsFilterActive";
import { FilterIdsEnum } from "../../../store/reducers/_maps/main/mapFilters/types";
import { useActions } from "../../../hooks/useActions";

const StyledButton = styled.button<{ isActive: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  width: 100%;
  height: 48px;
  box-sizing: border-box;

  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-black-color, #242731);

  border-radius: 4px;
  border-width: 0;
  cursor: pointer;
  background: none;
  z-index: ${zIndexes.filters};

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    border-radius: 4px;
    border: ${(props) =>
      props.isActive
        ? "2px solid var(--secondary-black-color)"
        : "1px solid var(--third-black-color)"};
  }

  &:hover {
    background: var(--sixth-black-color);
  }

  &:hover::after {
    border: 2px solid var(--secondary-black-color);
  }

  ${(props) =>
    props.disabled &&
    `
      cursor: not-allowed;
    `}

  ${(props) =>
    props.isActive &&
    `
      background: var(--sixth-black-color);
    `};

  @media (max-width: ${breakpoints.medium}px) {
    outline: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    border-radius: 4px;
    white-space: nowrap;

    display: flex;
    height: 32px;
    padding: 0 14px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    box-sizing: border-box;
    background: none;

    ${(props) =>
      props.isActive &&
      `
      background: var(--fifth-black-color);
    `}

    &:hover {
      background: ${(props) =>
        props.isActive ? "var(--fifth-black-color)" : "none"};
    }
    &::after {
      border: 1px solid var(--secondary-black-color);
    }
    &:hover::after {
      border: 1px solid var(--secondary-black-color);
    }
  }
`;

const StyledPin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--primary-white-color);
  width: 19px;
  height: 18px;
  background: linear-gradient(109.8deg, #f78d61 0%, #ed6044 100%);
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.571;
  position: absolute;
  z-index: ${zIndexes.filterPin};
  top: -7px;
  right: -7px;
  @media (max-width: ${breakpoints.medium}px) {
    top: -8px;
  }
`;

interface IFilterButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  pinText?: string | number;
  filterId?: FilterIdsEnum;
  isActive?: boolean;
}

const FilterButton: FC<IFilterButton> = ({
  filterId,
  pinText = "",
  children,
  ...rest
}) => {
  const { isLoading } = useTypedSelector((state) => state.mapInterface);

  const { toggleFilter } = useActions();
  const isActive = useIsFilterActive(filterId);

  const handleToggleFilter = () => {
    toggleFilter(filterId);
  };

  return (
    <StyledButton
      key={children?.toString()}
      isActive={isActive}
      disabled={isLoading}
      {...rest}
      onClick={handleToggleFilter}
    >
      <StyledPin
        style={{
          display: pinText ? "flex" : "none",
        }}
      >
        {pinText}
      </StyledPin>
      {children}
    </StyledButton>
  );
};

export default FilterButton;
