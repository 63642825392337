import { useCallback, useEffect } from "react";
import ReactMapGL, { Layer, Source, ViewStateChangeEvent } from "react-map-gl";
import styles from "./SikaDesignersMap.module.css";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import { useLazyEffect } from "../../../../hooks/useLazyEffect";
import Jp from "../../../../assets/svg/jpIcon.svg";
import CustomMarker from "../../_mapComponents/CustomMarker/CustomMarker";
import CustomNavigationControl from "../../_mapComponents/CustomNavigationControl/CustomNavigationControl";
import { mapboxAccessToken } from "../../../../constants/mapConstants";
import useSelectedLanguage from "../../../../hooks/useSelectedLanguage";
import useSearchParams from "../../../../hooks/useSearchParams";
import useSikaDesignersFilters from "./useSikaDesignersFilters";
import util from "../../../../util";
import useMobileScreen from "../../../../hooks/useMobileScreen";
import MapWrapper from "../../../../share/mapComponents/MapWrapper";
import MapBadgeLoader from "../../_mapComponents/MapBadgeLoader";
import CustomMapPopupItem from "../../_mapComponents/CustomPopup/CustomMapPopupItem";
import PinnedSelectedMapObjectsList from "../../_mapComponents/PinnedSelectedMapObjectsList";
import useMapObjects from "../../../../hooks/useMapObjects";
import CustomPopup from "../../_mapComponents/CustomPopup";

const SikaDesignersMap = () => {
  const { id } = useTypedSelector((state) => state.currentAccount);
  const { isFilterBarActive, viewport, currentMarker } = useTypedSelector(
    (state) => state.mapInterface
  );

  const { sikaMapRef: mapRef } = useTypedSelector(
    (state) => state.mapObjectsInfo
  );

  const { sika_designer: selectedMapObject } = useTypedSelector(
    (state) => state.mapSikaDesignerInfo
  );
  const { isDetailsActive } = useTypedSelector((state) => state.mapInterface);
  const {
    setIsFilterActive,
    setIsDetailsActive,
    setViewport,
    setCurrentMarker,
    getCurrentMapSikaDesigner,
    getDefaultSikaDesignerResult,
    setCurrentMapRef,
  } = useActions();

  const { setSearchParam, getSearchParam } = useSearchParams();
  const hashId = getSearchParam("hash_id");

  useEffect(() => {
    hashId && getCurrentMapSikaDesigner(hashId, id);
  }, [hashId]);

  useEffect(() => {
    setCurrentMapRef(mapRef);
  }, [mapRef]);

  const {
    currObjs,
    pinnedCurrObjs,
    setPinnedCurrObjs,
    markerClickHandler,
    markerHoverHandler,
    selectedLayerProps,
  } = useMapObjects();

  useLazyEffect(
    () => {
      if (!isDetailsActive) {
        getDefaultSikaDesignerResult();
      }
    },
    [viewport, selectedMapObject],
    700
  );

  useEffect(() => {
    if (selectedMapObject?.lat && selectedMapObject?.lon) {
      setIsDetailsActive(true);
      setIsFilterActive(true);
      setCurrentMarker({
        lat: +selectedMapObject.lat,
        lon: +selectedMapObject.lon,
      });
      mapRef.current?.flyTo({
        center: [+selectedMapObject.lon, +selectedMapObject.lat],
        zoom: 10,
      });
    }
  }, [selectedMapObject]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }
  }, [isFilterBarActive]);

  const mapFilter = useSikaDesignersFilters();

  const getFeatures = (e: any) => {
    if (!e) return [];

    const layer = "sika-designers-report-layer";
    return mapRef.current.queryRenderedFeatures(e.point, {
      layers: [layer],
      filter: mapFilter,
      validate: false,
    });
  };

  const selectedLanguage = useSelectedLanguage();
  const handleMapLoad = useCallback(() => {
    const map = mapRef.current?.getMap();
    if (map) {
      util.mapInterfaceUtil.changeMapboxLanguage(map, selectedLanguage);
      util.mapInterfaceUtil.addPinIconToMapbox(map);
    }
  }, []);
  const isMobile = useMobileScreen();

  function renderPopupItem(currObj: GeoJSON.Feature, index: number) {
    return (
      <CustomMapPopupItem
        key={index}
        isSelected={
          currObj.properties.edrpou_code === selectedMapObject?.edrpou_code
        }
        onClick={() => {
          setSearchParam("hash_id", currObj.properties.edrpou_code);
        }}
      >
        <div>
          <img src={Jp} alt="" />
          <h4 className={styles.titleStyle}>
            {" "}
            {currObj.properties.short_name.length > 37
              ? currObj.properties.short_name.slice(0, 30) + ".."
              : currObj.properties.short_name}
          </h4>
          <p className={styles.construction_type}>
            <strong>ЄДРПОУ: </strong>
            {currObj.properties.edrpou_code}{" "}
          </p>
          <p className={styles.construction_type}>
            <strong>Категорія: </strong>
            {currObj.properties.domains}{" "}
          </p>
          <p className={styles.construction_type}>
            <strong>Адреса: </strong>
            {currObj.properties.address}{" "}
          </p>
        </div>
      </CustomMapPopupItem>
    );
  }
  return (
    <MapWrapper>
      {pinnedCurrObjs?.length > 0 &&
        !selectedMapObject?.edrpou_code &&
        isMobile && (
          <PinnedSelectedMapObjectsList
            handleClose={() => setPinnedCurrObjs([])}
          >
            {pinnedCurrObjs.map((currObj, index) =>
              renderPopupItem(currObj, index)
            )}
          </PinnedSelectedMapObjectsList>
        )}
      <ReactMapGL
        {...viewport}
        mapboxAccessToken={mapboxAccessToken}
        ref={(instance) => (mapRef.current = instance)}
        minZoom={5}
        maxZoom={24}
        maxPitch={1}
        dragRotate={false}
        mapStyle={"mapbox://styles/aimap/cle6wbqd2005h01qm41h8z5xb"}
        onLoad={handleMapLoad}
        onMove={(e: ViewStateChangeEvent) => {
          setViewport(e.viewState);
        }}
        onZoom={(e: ViewStateChangeEvent) => {
          setViewport(e.viewState);
        }}
        onMouseOver={(e) => markerHoverHandler(getFeatures(e))}
        onClick={(e) => markerClickHandler(getFeatures(e), "edrpou_code")}
        onMouseMove={(e) => markerHoverHandler(getFeatures(e))}
      >
        <CustomNavigationControl />
        <MapBadgeLoader />
        <Source type="vector" url="mapbox://aimap.sika_designers_report_ts">
          <Layer
            id={"layer1"}
            filter={mapFilter}
            source-layer="sika_designers_report_layer"
            minzoom={0}
            maxzoom={24}
            {...selectedLayerProps}
            type="symbol"
          />
        </Source>
        <CustomMarker marker={currentMarker} />
        {currObjs?.length > 0 && (
          <CustomPopup
            latitude={+currObjs[0].properties.lat}
            longitude={+currObjs[0].properties.lon}
            itemsCount={currObjs.length}
          >
            {currObjs.map((currObj: GeoJSON.Feature, index: number) =>
              renderPopupItem(currObj, index)
            )}
          </CustomPopup>
        )}
      </ReactMapGL>
    </MapWrapper>
  );
};

export default SikaDesignersMap;
