import React from "react";
import styled from "@emotion/styled";
import { colors, icons } from "../../constants";
import { Text16 } from "../../share/textComponents/textComponents";

const StyledLinkText = styled(Text16)`
  color: ${colors.redR400};
  cursor: pointer;
  display: flex;
  gap: 4px;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

interface Props {
  onClick?: () => void;
  noIcon?: boolean;
}

const LinkText: React.FC<Props> = ({ noIcon = false, children, onClick }) => {
  return (
    <StyledLinkText onClick={onClick}>
      <span>{children}</span>
      {!noIcon && <img src={icons.RedRightArrow} alt="" />}
    </StyledLinkText>
  );
};
export default LinkText;
