import ReactDOM from "react-dom/client"; // Update the import to use /client
import "./index.scss";
import "./i18n";
import App from "./App";

import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { ConfigProvider } from "antd";

const rootElement = document.getElementById("root"); // Get the root element
const root = ReactDOM.createRoot(rootElement); // Create a root instance

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#242731",
          },
        }}
      >
        <App />
      </ConfigProvider>
    </PersistGate>
  </Provider>
);
