import { HubspotActionsEnum } from "./types";

export interface IFeedbackState {
  isLoading: boolean;
  error: string | undefined;
}

const initialState: IFeedbackState = {
  isLoading: false,
  error: undefined,
};

export default function templatesReducer(state = initialState, action: any) {
  switch (action.type) {
    case HubspotActionsEnum.SET_IS_POSTING_FEEDBACK_LOADING:
      return { ...state, isLoading: action.payload };
    case HubspotActionsEnum.SET_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
