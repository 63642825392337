import { ITemplate } from "./../../../types";
import { TemplatesActionsEnum } from "./types";

export interface ITemplatesState {
  isLoading: boolean;
  error: string | undefined;
  templates: ITemplate[];
  selectedTemplate: ITemplate | null;
}

const initialState: ITemplatesState = {
  isLoading: false,
  error: undefined,
  templates: [],
  selectedTemplate: null,
};

export default function templatesReducer(state = initialState, action: any) {
  switch (action.type) {
    case TemplatesActionsEnum.SET_TEMPLATES:
      return { ...state, templates: action.payload };
    case TemplatesActionsEnum.SET_IS_TEMPLATES_LOADING:
      return { ...state, isLoading: action.payload };
    case TemplatesActionsEnum.SET_ERROR:
      return { ...state, error: action.payload };
    case TemplatesActionsEnum.SET_SELECTED_TEMPLATE:
      return { ...state, selectedTemplate: action.payload };
    case TemplatesActionsEnum.CREATE_TEMPLATE:
      return {
        ...state,
        templates: [...state.templates, action.payload],
      };
    case TemplatesActionsEnum.DELETE_TEMPLATE:
      return {
        ...state,
        templates: state.templates.filter(
          (template: ITemplate) => template.id !== action.payload
        ),
      };
    case TemplatesActionsEnum.UPDATE_TEMPLATE:
      return {
        ...state,
        templates: state.templates.map((template: ITemplate) =>
          template.id === action.payload.id ? action.payload : template
        ),
      };
    default:
      return state;
  }
}
