import { useTypedSelector } from "../../../hooks/useTypedSelector";
import Filters from "../../../components/_maps/main/Filters/Filters";
import SearchResults from "../../../components/_maps/main/SearchResults/SearchResults";
import ObjectDetails from "../../../components/_maps/main/ObjectDetails/ObjectDetails";
import MapPage from "../../../share/MapPage/MapPage";
import DownloadResults from "../../../components/_maps/main/DownloadResults/DownloadResults";
import { useActions } from "../../../hooks/useActions";
import MainMap from "../../../components/_maps/main/MainMap/MainMap";

const Main = () => {
  const { mapRef, config } = useTypedSelector((state) => state.mapObjectsInfo);
  const { setMapRef } = useActions();

  const resultCount = useTypedSelector(
    (state) => state.mapObjectsInfo.mapObjects.count
  );

  const filter = <Filters />,
    downloadResults = <DownloadResults count={resultCount} />,
    objectDetails = <ObjectDetails />,
    map = <MainMap />;

  return (
    <MapPage
      filter={filter}
      SearchResults={SearchResults}
      downloadResults={downloadResults}
      objectDetails={objectDetails}
      map={map}
      mapRef={mapRef}
      setMapRef={setMapRef}
      config={config}
    />
  );
};

export default Main;
