import React from "react";
import { formatISO, parseISO } from "date-fns";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import styles from "./DatePicker.module.css";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "../../../CustomDatePicker";
import useStoreSnippets from "../../../../hooks/useStoreSnippets";
import LoginNotificationPopup from "../../../../share/NotificationPopInformation/LoginNotificationPopup/LoginNotificationPopup";
import { useTipsContext } from "../../../../hooks/useTips";

interface IProps {
  showTemplates?: boolean;
  modalBodyRef?: React.MutableRefObject<HTMLDivElement | undefined>;
  isFilterActive: boolean;
}

const DatePicker = ({
  showTemplates,
  modalBodyRef,
  isFilterActive,
}: IProps) => {
  const tipId = "loginToUseDateFilterMain";
  const setLastOpenedTipId = useTipsContext().setLastOpenedTipId;

  const { date_added__gte, date_added__lte } = useTypedSelector(
    (state) => state.companyFilters
  );
  const { setDateAddedGTE, setDateAddedLTE, setPage, setOffset } = useActions();

  const curr = new Date();
  const currLast = new Date();

  const { t: tNotification } = useTranslation("translation", {
    keyPrefix: "notifications",
  });
  const { isGuestAccount } = useStoreSnippets();

  const setStartDate = (date: Date | null) => {
    if (!date) {
      setDateAddedGTE("");
      return;
    }
    setDateAddedGTE(formatISO(date, { representation: "date" }));
  };

  const setEndDate = (date: Date | null) => {
    if (!date) {
      setDateAddedLTE("");
      return;
    }
    setDateAddedLTE(formatISO(date, { representation: "date" }));
  };

  const setCurrentWeek = () => {
    const first = curr.getDate() - curr.getDay();
    const firstDay = new Date(curr.setDate(first + 1));
    const lastDay = new Date(currLast.setDate(first + 7));
    setStartDate(firstDay);
    setEndDate(lastDay);
    setDateAddedGTE(formatISO(firstDay, { representation: "date" }));
    setDateAddedLTE(formatISO(lastDay, { representation: "date" }));
    setOffset(0);
    setPage(1);
  };

  const setPrevWeek = () => {
    const first = curr.getDate() - curr.getDay() - 7;
    const firstDay = new Date(curr.setDate(first + 1));
    const lastDay = new Date(currLast.setDate(first + 7));
    setStartDate(firstDay);
    setEndDate(lastDay);
    setDateAddedGTE(formatISO(firstDay, { representation: "date" }));
    setDateAddedLTE(formatISO(lastDay, { representation: "date" }));
    setOffset(0);
    setPage(1);
  };

  const setCurrentMonth = () => {
    const firstDayOfMonth = new Date(curr.getFullYear(), curr.getMonth(), 1);
    const lastDayOfMonth = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);
    setStartDate(firstDayOfMonth);
    setEndDate(lastDayOfMonth);
    setDateAddedGTE(formatISO(firstDayOfMonth, { representation: "date" }));
    setDateAddedLTE(formatISO(lastDayOfMonth, { representation: "date" }));
    setOffset(0);
    setPage(1);
  };

  const setPrevMonth = () => {
    const firstDayOfMonth = new Date(
      curr.getFullYear(),
      curr.getMonth() - 1,
      1
    );
    const lastDayOfMonth = new Date(curr.getFullYear(), curr.getMonth(), 0);
    setStartDate(firstDayOfMonth);
    setEndDate(lastDayOfMonth);
    setDateAddedGTE(formatISO(firstDayOfMonth, { representation: "date" }));
    setDateAddedLTE(formatISO(lastDayOfMonth, { representation: "date" }));
    setOffset(0);
    setPage(1);
  };

  const { t: tDate } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.companies.dateFilter",
  });

  return (
    <>
      <LoginNotificationPopup
        id={tipId}
        message={tNotification("loginToUseDateFilter")}
        rightPx={-456}
        bottomPx={400}
        cornerTopPosition={100}
      />
      <div
        onClick={isGuestAccount ? () => setLastOpenedTipId(tipId) : undefined}
      >
        <div className={isGuestAccount ? styles.datePickerWrapperDisabled : ""}>
          <div
            className={!isFilterActive ? styles.filterOnMap : styles.formGroup}
          >
            <div className={styles.datePickerWrapper}>
              <CustomDatePicker
                startDate={date_added__gte ? parseISO(date_added__gte) : null}
                endDate={date_added__lte ? parseISO(date_added__lte) : null}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                modalBodyRef={modalBodyRef}
              />
            </div>
            {!isFilterActive && showTemplates ? (
              <div className={styles.calendarFilters}>
                <button
                  onClick={setCurrentWeek}
                  className={styles.dateFilterButton}
                  type="button"
                >
                  {tDate("currentWeek")}
                </button>
                <button
                  onClick={setPrevWeek}
                  className={styles.dateFilterButton}
                  type="button"
                >
                  {tDate("previousWeek")}
                </button>
                <button
                  onClick={setCurrentMonth}
                  className={styles.dateFilterButton}
                  type="button"
                >
                  {tDate("currentMonth")}
                </button>
                <button
                  onClick={setPrevMonth}
                  className={styles.dateFilterButton}
                  type="button"
                >
                  {tDate("previousMonth")}
                </button>
              </div>
            ) : isFilterActive ? (
              <div className={styles.calendarFilters}>
                <button
                  onClick={setCurrentWeek}
                  className={styles.dateFilterButton}
                  type="button"
                >
                  {tDate("currentWeek")}
                </button>
                <button
                  onClick={setPrevWeek}
                  className={styles.dateFilterButton}
                  type="button"
                >
                  {tDate("previousWeek")}
                </button>
                <button
                  onClick={setCurrentMonth}
                  className={styles.dateFilterButton}
                  type="button"
                >
                  {tDate("currentMonth")}
                </button>
                <button
                  onClick={setPrevMonth}
                  className={styles.dateFilterButton}
                  type="button"
                >
                  {tDate("previousMonth")}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default DatePicker;
