import styled from "@emotion/styled";
import { colors } from "../../constants";

const StyledTagContainer = styled.span<{ isIcon: boolean }>`
  height: 32px;
  padding: ${({ isIcon }) => (isIcon ? "2px 12px 2px 6px" : "2px 12px")};
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background-color: ${colors.redR400};
  width: min-content;
`;

const StyledText = styled.span`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.0012em;
  color: ${colors.white};
`;

const IconText = styled.span`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.002em;
  text-align: center;
`;

interface Props {
  text: string;
  iconText?: string;
}
const Tag: React.FC<Props> = ({ text, iconText }) => {
  return (
    <StyledTagContainer isIcon={!!iconText}>
      {iconText && <IconText>{iconText}</IconText>}
      <StyledText>{text}</StyledText>
    </StyledTagContainer>
  );
};
export default Tag;
