// hooks/useSearch.js
import { datasets } from "../../constants/mapConstants";
import { IDatasetType } from "../../models/map.interface";
import { useActions } from "../useActions";
import { useTypedSelector } from "../useTypedSelector";
import { ISearchResponse } from "../../models/Search";

export const useActionsByDataset = (
  dataset: IDatasetType = datasets.objects
) => {
  const datasetFiltersNames = {
    [datasets.objects]: "mapFilters",
    [datasets.company]: "companyFilters",
    [datasets.person]: "personFilters",
    [datasets.sika]: "sikaDesignerFilters",
    [datasets.usaid]: "usaidMapFilters",
  };
  const selectedDatasetFilterName = datasetFiltersNames[dataset];
  const selectedDatasetFilters = useTypedSelector(
    (state) => state[selectedDatasetFilterName]
  );
  const { activeSuggestions, suggestions } = selectedDatasetFilters;

  //main
  const {
    getSuggestions,
    setSuggestions,
    setActiveSuggestions,
    addActiveSuggestion,
    deleteActiveSuggestion,
    deleteRegion,
    deleteEdrCode,
    deleteNpId,
    deleteKeyword,
    deleteDkbsCode,
  } = useActions();

  //companyActions
  const {
    getCompanySuggestions,
    setCompanySuggestions,
    setCompanyActiveSuggestions,
    deleteCompanyActiveSuggestion,
    deleteCompanyKVED,
    deleteCompanyRegion,
    addCompanyActiveSuggestion,
    deleteCompanyEdrCode,
  } = useActions();

  //personActions

  const {
    getPersonSuggestions,
    setPersonSuggestions,
    setPersonActiveSuggestions,
    deletePersonActiveSuggestion,
    deletePersonKVED,
    deletePersonRegion,
    deletePersonHashCode,
    addPersonsActiveSuggestion,
  } = useActions();

  //sika
  const {
    getSikaDesignerSuggestions,
    setSikaDesignerSuggestions,
    setSikaDesignerActiveSuggestions,
    deleteSikaDesignerActiveSuggestion,
    deleteSikaDesignerKVED,
    deleteSikaDesignerRegion,
    deleteSikaDesignerEdrCode,
    deleteSikaDesignerKeyword,
    addSikaActiveSuggestion,
  } = useActions();
  //usaid
  const {
    getUSAIDSuggestions,
    setUSAIDSuggestions,
    setUSAIDActiveSuggestions,
    deleteUSAIDActiveSuggestion,
    deleteUSAIDKVED,
    deleteUSAIDRegion,
    deleteUSAIDEdrCode,
    addUSaidActiveSuggestion,
  } = useActions();

  const handleDeleteActiveSuggestionsForMain = (
    suggestion: ISearchResponse
  ) => {
    deleteActiveSuggestion(suggestion);
    deleteRegion(suggestion.code);
    deleteEdrCode(suggestion.code);
    deleteNpId(suggestion.code);
    deleteKeyword(suggestion.code);
    deleteDkbsCode(suggestion.code);
  };

  const handleDeleteActiveSuggestionsForCompany = (
    suggestion: ISearchResponse
  ) => {
    deleteCompanyActiveSuggestion(suggestion);
    deleteCompanyRegion(suggestion.code);
    deleteCompanyEdrCode(suggestion.code);
    deleteCompanyKVED(suggestion.code);
  };

  const handleDeleteActiveSuggestionsForPersons = (
    suggestion: ISearchResponse
  ) => {
    deletePersonActiveSuggestion(suggestion);
    deletePersonRegion(suggestion.code);
    deletePersonHashCode(suggestion.code);
    deletePersonKVED(suggestion.code);
  };

  const handleDeleteActiveSuggestionsForSika = (
    suggestion: ISearchResponse
  ) => {
    deleteSikaDesignerActiveSuggestion(suggestion);
    deleteSikaDesignerRegion(suggestion.code);
    deleteSikaDesignerEdrCode(suggestion.code);
    deleteSikaDesignerKVED(suggestion.code);
    deleteSikaDesignerKeyword(suggestion.code);
  };

  const handleDeleteActiveSuggestionsForUSaid = (
    suggestion: ISearchResponse
  ) => {
    deleteUSAIDActiveSuggestion(suggestion);
    deleteUSAIDRegion(suggestion.code);
    deleteUSAIDEdrCode(suggestion.code);
    deleteUSAIDKVED(suggestion.code);
  };

  const config: Record<string, any> = {
    [datasets.objects]: {
      getSuggestions,
      setSuggestions,
      setActiveSuggestions,
      handleDeleteActiveSuggestion: handleDeleteActiveSuggestionsForMain,
      addActiveSuggestion: addActiveSuggestion,
    },
    [datasets.company]: {
      getSuggestions: getCompanySuggestions,
      setSuggestions: setCompanySuggestions,
      setActiveSuggestions: setCompanyActiveSuggestions,
      handleDeleteActiveSuggestion: handleDeleteActiveSuggestionsForCompany,
      addActiveSuggestion: addCompanyActiveSuggestion,
    },
    [datasets.person]: {
      getSuggestions: getPersonSuggestions,
      setSuggestions: setPersonSuggestions,
      setActiveSuggestions: setPersonActiveSuggestions,
      handleDeleteActiveSuggestion: handleDeleteActiveSuggestionsForPersons,
      addActiveSuggestion: addPersonsActiveSuggestion,
    },

    [datasets.sika]: {
      getSuggestions: getSikaDesignerSuggestions,
      setSuggestions: setSikaDesignerSuggestions,
      setActiveSuggestions: setSikaDesignerActiveSuggestions,
      handleDeleteActiveSuggestion: handleDeleteActiveSuggestionsForSika,
      addActiveSuggestion: addSikaActiveSuggestion,
    },
    [datasets.usaid]: {
      getSuggestions: getUSAIDSuggestions,
      setSuggestions: setUSAIDSuggestions,
      setActiveSuggestions: setUSAIDActiveSuggestions,
      handleDeleteActiveSuggestion: handleDeleteActiveSuggestionsForUSaid,
      addActiveSuggestion: addUSaidActiveSuggestion,
    },
  };

  return {
    getSuggestions: config[dataset].getSuggestions,
    setSuggestions: config[dataset].setSuggestions,
    setActiveSuggestions: config[dataset].setActiveSuggestions,
    deleteActiveSuggestion: config[dataset].deleteActiveSuggestion,
    handleDeleteActiveSuggestion: config[dataset].handleDeleteActiveSuggestion,
    addActiveSuggestion: config[dataset].addActiveSuggestion,
    activeSuggestions,
    suggestions,
  };
};

export default useActionsByDataset;
