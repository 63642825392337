import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { ISearchResponse } from "../../models/Search";
import { CustomLoader } from "../../share/Loader/Loader";
import { useTranslation } from "react-i18next";
import CustomIcon from "../../share/CustomIcon/CustomIcon";
import { ReactComponent as CloseIcon } from "../../assets/svg/Close_small_nofill.svg";
import { ReactComponent as ArrowLeft } from "../../assets/svg/arrowLeft_nofill.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/search_nofill.svg";
import styled from "@emotion/styled";
import {
  StyledActiveSuggestion,
  StyledActiveSuggestionCount,
  StyledActiveSuggestionIconWrapper,
  StyledActiveSuggestionWrapper,
  StyledActiveSuggestionTextWrapper,
  StyledActiveSuggestionBtn,
  StyledBackDrop,
  StyledSearchContainer,
  StyledSearchInput,
  StyledSearchInputWrapper,
  StyledSuggestion,
  StyledSuggestionCode,
  StyledSuggestionContainer,
  StyledSuggestionImg,
  StyledSuggestionName,
  StyledSuggestionWrapper,
} from "../../share/SearchFilter/SearchFilterStyledComponents";
import useSearchFilter from "../../hooks/search/useSearchFilter";
import filtersConstants from "../../constants/filters";
import useStoreSnippets from "../../hooks/useStoreSnippets";
import { suggestionTypes } from "../../constants/constants";
import { colors, filters } from "../../constants";
import { useContext, useState } from "react";
import { getSuggestionImage } from "../../util/mapInterfaceUtil";
import {
  filterSuggestions,
  sortCallbackPutsRegionToBottom,
} from "../templates/AddSavedFilters/TemplateFormModal/util/util";
import useSearchParams from "../../hooks/useSearchParams";
import useActionsByDataset from "../../hooks/search/useActionsByDataset";
import SubscribeNotificationPopup from "../../share/NotificationPopInformation/SubscribeNotificationPopup/SubscribeNotificationPopup";
import { TipsContext } from "../../contexts/TipsContext";

const SearchFilter = () => {
  const tipId = "subscribeToUseSearch";
  const [isMoreShown, setIsMoreShown] = useState<boolean>(false);
  const { setLastOpenedTipId } = useContext(TipsContext);
  const { t: tSearch } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.aimap.searchFilter",
  });

  const { t: tNotifications } = useTranslation("translation", {
    keyPrefix: "notifications",
  });
  const { id } = useTypedSelector((state) => state.currentAccount);
  const isLoading = useTypedSelector(
    (state) => state.mapFilters.isSuggestionsLoading
  );

  const { isGuestAccount, isFreeAccount } = useStoreSnippets();

  function showSubscribeNotificationToUseFunctional(e) {
    e.stopPropagation();
    if (isGuestAccount || isFreeAccount) {
      setLastOpenedTipId(tipId);
    }
  }

  const { isSearchDisabled } = useStoreSnippets();

  const dataset = useSearchParams().getDataset();

  const {
    getSuggestions,
    setSuggestions,
    setActiveSuggestions,
    handleDeleteActiveSuggestion,
    addActiveSuggestion,
    activeSuggestions,
    suggestions,
  } = useActionsByDataset(dataset);

  const {
    isSearchActive,
    isSearchInFullView,
    searchText,
    isShown,
    isMobile,
    setIsShown,
    setIsSearchActive,
    setIsSearchInFullView,
    setSearchText,
    geoSuggestions,
    fetchGeoSuggestions,
    handleAddressSelect,
  } = useSearchFilter();

  const { setKeyword, setPage, setOffset, setIsPulloutDrawerOpen } =
    useActions();

  function fetchSuggestions(text) {
    const data = {
      query: text,
      account_id: id,
    };
    getSuggestions(data);
  }

  const handleSuggestionClose = () => {
    !isMobile &&
      setTimeout(() => {
        setSuggestions([]);
      }, 500);
  };

  const handleClearSearch = () => {
    setSuggestions([]);
    setSearchText("");
  };

  const handleChange = (text: string) => {
    if (!isSearchDisabled) {
      // Fetching suggestions from APIs
      fetchGeoSuggestions(text);
      fetchSuggestions(text);

      // Condition to proceed with filtering and updating state
      if (text.length) {
        const regex = new RegExp(text, "gi");
        const matches = filterSuggestions(suggestions, regex);
        if (text.length >= filtersConstants.searchTextMinLength) {
          setSuggestions(matches);
        }
      }
      setSearchText(text);
    }
  };

  const handleSuggestionClick = (suggestion: ISearchResponse) => {
    addActiveSuggestion(suggestion);
    setPage(1);
    setOffset(0);
    handleClearSearch();
    setIsShown(false);
    setIsSearchInFullView(false);
    setIsPulloutDrawerOpen(true);
  };

  const handleKeywordClick = (keyword: string) => {
    setKeyword(keyword);
    setActiveSuggestions({
      type: tSearch("keyWord"),
      code: keyword,
      name: keyword,
    } as ISearchResponse);
    setPage(1);
    setOffset(0);
    handleClearSearch();
    setIsSearchInFullView(false);
    setIsPulloutDrawerOpen(false);
  };

  const handleDelete = handleDeleteActiveSuggestion;

  const handleShowMore = () => {
    setIsMoreShown((state) => !state);
  };

  const StyledIconWrap = styled.div<{ position: "left" | "right" }>`
    cursor: pointer;
    ${(props) =>
      props.position === "left" ? "margin-right: 10px" : "margin-left: 10px"};
  `;

  return (
    <StyledSearchContainer isShown={isSearchInFullView}>
      <SubscribeNotificationPopup
        id={tipId}
        bottomPx={-180}
        rightPx={-432}
        cornerTopPosition={32}
        message={tNotifications("subscribeToUseSearch")}
      />

      <StyledSearchInputWrapper
        isDisabled={isSearchDisabled}
        onClick={showSubscribeNotificationToUseFunctional}
      >
        <StyledIconWrap position="left">
          {isSearchInFullView && isMobile ? (
            <CustomIcon
              onClick={() => {
                setIsSearchInFullView(false);
              }}
              icon={ArrowLeft}
              size="24px"
              color={"#f46857"}
            />
          ) : (
            <CustomIcon
              icon={SearchIcon}
              size={"24px"}
              color={isSearchActive ? "#575F6E" : "#AEB1B5"}
            />
          )}
        </StyledIconWrap>
        <StyledSearchInput
          value={searchText}
          onChange={(event) => handleChange(event.target.value)}
          onKeyDown={(event: any) => {
            if (!isSearchDisabled) {
              const keyCode = event.key;
              if (keyCode === "Enter" && searchText.length > 2) {
                handleKeywordClick(searchText);
              }
            }
          }}
          type="text"
          placeholder={tSearch("placeholder")}
          onFocus={() => {
            if (!isSearchDisabled) {
              setIsSearchActive(true);
              fetchGeoSuggestions(searchText);
            }
          }}
          onClick={() => {
            if (!isSearchDisabled) {
              fetchGeoSuggestions(searchText);
              setIsShown(true);
              setIsSearchInFullView(true);
            }
          }}
          tabIndex={0}
          onBlur={() => {
            handleSuggestionClose();
          }}
          isDisabled={isSearchDisabled}
        />

        {activeSuggestions.length > 0 && !isSearchInFullView && isMobile && (
          <StyledActiveSuggestionCount>
            {activeSuggestions.length}
          </StyledActiveSuggestionCount>
        )}
        {((!isMobile && searchText !== "") ||
          (isMobile && isSearchInFullView)) && (
          <StyledIconWrap position="right">
            <CustomIcon
              onClick={handleClearSearch}
              icon={CloseIcon}
              color={"#242731"}
            />
          </StyledIconWrap>
        )}
      </StyledSearchInputWrapper>

      <StyledSuggestionContainer isShown={isSearchInFullView}>
        {activeSuggestions.length > 0 && (
          <StyledActiveSuggestionWrapper isShown={isSearchInFullView}>
            {activeSuggestions
              ?.slice(
                0,
                isMoreShown
                  ? activeSuggestions.length
                  : filters.defaultActiveKeywordCount
              )
              .map((suggest: ISearchResponse, index: number) => (
                <StyledActiveSuggestion key={index}>
                  <StyledActiveSuggestionTextWrapper>
                    {suggest.type === suggestionTypes.region
                      ? suggest.name
                      : `${suggest.name} | ${suggest.type}`}
                  </StyledActiveSuggestionTextWrapper>
                  <StyledActiveSuggestionIconWrapper>
                    <CustomIcon
                      onClick={() => handleDelete(suggest)}
                      icon={CloseIcon}
                      color={"#242731"}
                    />
                  </StyledActiveSuggestionIconWrapper>
                </StyledActiveSuggestion>
              ))}
            {activeSuggestions.length > filters.defaultActiveKeywordCount && (
              <StyledActiveSuggestionBtn onClick={handleShowMore}>
                <StyledActiveSuggestionTextWrapper color={colors.redR400}>
                  {isMoreShown
                    ? tSearch("hide")
                    : `${tSearch("showMore")} +${
                        activeSuggestions.length -
                        filters.defaultActiveKeywordCount
                      }`}
                </StyledActiveSuggestionTextWrapper>
              </StyledActiveSuggestionBtn>
            )}
          </StyledActiveSuggestionWrapper>
        )}

        {isShown &&
          searchText.length >= filtersConstants.searchTextMinLength && (
            <StyledSuggestionWrapper>
              <StyledBackDrop
                onClick={() => {
                  setIsShown(false);
                }}
              />
              {isLoading ? (
                <CustomLoader />
              ) : (
                <>
                  {searchText.length >=
                    filtersConstants.searchTextMinLength && (
                    <StyledSuggestion
                      tabIndex={0}
                      onClick={() => handleKeywordClick(searchText)}
                    >
                      <StyledSuggestionName>{searchText}</StyledSuggestionName>
                      <StyledSuggestionCode>
                        {tSearch("keyWordSearch")}
                      </StyledSuggestionCode>
                    </StyledSuggestion>
                  )}

                  {suggestions
                    .sort(sortCallbackPutsRegionToBottom)
                    .map((suggestion: ISearchResponse, index: number) => (
                      <StyledSuggestion
                        tabIndex={0}
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSuggestionClick(suggestion);
                          }
                        }}
                      >
                        <StyledSuggestionImg
                          src={getSuggestionImage(suggestion.type)}
                          alt={tSearch("type")}
                        />
                        <StyledSuggestionName>
                          {suggestion?.name}
                        </StyledSuggestionName>
                        <StyledSuggestionCode>
                          {suggestion.type === suggestionTypes.jp
                            ? suggestion.code
                            : suggestion.type}
                        </StyledSuggestionCode>
                      </StyledSuggestion>
                    ))}
                  {geoSuggestions.map((suggestion: any, index: number) => (
                    <StyledSuggestion
                      tabIndex={0}
                      key={suggestion.id}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleAddressSelect(suggestion);
                          setSearchText(suggestion.place_name);
                        }
                      }}
                      onClick={() => {
                        handleAddressSelect(suggestion);
                      }}
                    >
                      <StyledSuggestionImg
                        src={getSuggestionImage(suggestionTypes.region)}
                        alt={tSearch("type")}
                      />
                      <StyledSuggestionName>
                        {suggestion.place_name}
                      </StyledSuggestionName>
                      <StyledSuggestionCode></StyledSuggestionCode>
                    </StyledSuggestion>
                  ))}
                </>
              )}
            </StyledSuggestionWrapper>
          )}
      </StyledSuggestionContainer>
    </StyledSearchContainer>
  );
};

export default SearchFilter;
