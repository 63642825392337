import React, { ReactNode } from "react";
import FeedbackModal from "../FeedbackModal";

interface IProps {
  children: ReactNode;
}

const PublicRoute: React.FC<IProps> = ({ children }) => {
  return (
    <>
      <FeedbackModal />
      {children}
    </>
  );
};

export default PublicRoute;
