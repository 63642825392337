import styled from "@emotion/styled";
import { colors } from "../../../../constants";
import { FC } from "react";
import ProfileAvatar from "../../ProfileAvatar/ProfileAvatar";
import CustomIcon from "../../../../share/CustomIcon/CustomIcon";
import { ReactComponent as AngleRight } from "../../../../assets/svg/AngleRight_nofill.svg";
import { CurrentScreen } from "../useMobileProfile";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { IUser } from "../../../../models/Accounts";
import { IFetchable } from "../../../../models/IFetchable";

const StyledPersonWrap = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  align-items: center;
`;
const StyledPersonName = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: 0.036px;
`;
const StyledPersonRole = styled.p`
  color: ${colors.darkD300};
  font-size: 12px;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;

const StyledSection = styled.section`
  padding: 20px 0;
  border-bottom: 1px solid ${colors.darkD100};

  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    padding-bottom: 0;
    border: none;
  }
`;

const StyledSectionList = styled.ul`
  list-style-type: none;
`;
const StyledSectionItem = styled.li`
  padding: 8px;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledSectionButton = styled.button`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  background: none;
  border: none;
  outline: none;
  color: ${colors.darkD400};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const arrow = <CustomIcon icon={AngleRight} color={colors.darkD200} />;

interface IMainScreen {
  switchScreen?: (arg: CurrentScreen) => void;
}

const MainScreen: FC<IMainScreen> = ({ switchScreen }) => {
  const { t: tBaseInfo } = useTranslation("translation", {
    keyPrefix: "profilePage.baseInfoForm",
  });

  const currentUser: Partial<IUser & IFetchable> = useTypedSelector(
    (store) => store.currentUser
  );

  return (
    <>
      <StyledPersonWrap>
        <ProfileAvatar />
        <div>
          <StyledPersonName>{currentUser.full_name}</StyledPersonName>
          <StyledPersonRole>{currentUser.position}</StyledPersonRole>
        </div>
      </StyledPersonWrap>
      <StyledSection>
        <StyledSectionList>
          <StyledSectionItem>
            <StyledSectionButton
              onClick={() => switchScreen(CurrentScreen.basic)}
            >
              {tBaseInfo("baseInfoTitle")}
              {arrow}
            </StyledSectionButton>
          </StyledSectionItem>
          <StyledSectionItem>
            <StyledSectionButton
              onClick={() => switchScreen(CurrentScreen.contact)}
            >
              {tBaseInfo("contactDataTitle")}
              {arrow}
            </StyledSectionButton>
          </StyledSectionItem>
          <StyledSectionItem>
            <StyledSectionButton
              onClick={() => switchScreen(CurrentScreen.language)}
            >
              {tBaseInfo("appLanguageTitle")}
              {arrow}
            </StyledSectionButton>
          </StyledSectionItem>
        </StyledSectionList>
      </StyledSection>
    </>
  );
};

export default MainScreen;
