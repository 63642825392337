import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { breakpoints } from "../../../constants";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  @media screen and (max-width: ${breakpoints.small + "px"}) {
    padding: 24px 16px 32px 16px;
  }
`;

const StyledTitle = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.0022em;
  color: var(--secondary-black-color);
  margin-bottom: 16px;
  @media screen and (max-width: ${breakpoints.small + "px"}) {
    color: var(--Primary-Dark-blue, #242731);
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
    letter-spacing: 0.036px;
    margin-bottom: 8px;
  }
`;

const StyledButton = styled.button`
  padding: 8px 16px;
  height: 40px;
  background: var(--primary-white-color);
  border: 1px solid var(--third-black-color);
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.285;
  color: var(--secondary-black-color);
`;

const StyledText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 1.555;
  color: var(--secondary-black-color);
  margin-bottom: 32px;
  @media screen and (max-width: ${breakpoints.small + "px"}) {
    color: var(--Primary-Dark-blue, #242731);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-bottom: 32px;
  }
`;

type Props = {
  onClearFilters: () => void;
};

const NoResultsFound: React.FC<Props> = ({ onClearFilters }) => {
  const { t: tFilter } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.searchResults",
  });
  return (
    <StyledContainer>
      <StyledTitle> {tFilter("nothingFound")}</StyledTitle>
      <StyledText>{tFilter("advice")}</StyledText>
      <StyledButton onClick={onClearFilters} type="button">
        {tFilter("clearAllFilters")}
      </StyledButton>
    </StyledContainer>
  );
};

export default NoResultsFound;
