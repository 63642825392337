import React, { useState } from "react";
import styles from "../SikaDesignerDetails.module.css";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { CustomLoader } from "../../../../../share/Loader/Loader";
import Attention from "../../../../../assets/svg/AttentionHand.svg";
import ArrowRight from "../../../../../assets/svg/arrowRight.svg";
import ModalWithHeader from "../../../../../share/ModalWithHeader/ModalWithHeader";
import { RouteNames } from "../../../../../routes";
import { useNavigate } from "react-router-dom";
import { CurrentObject } from "../../../../../models/CurrentObject";
import { useTranslation } from "react-i18next";

const Objects = () => {
  const { sika_designer } = useTypedSelector(
    (state) => state.mapSikaDesignerInfo
  );
  const { isLoading } = useTypedSelector((state) => state.mapInterface);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const showMoreDocuments = () => {
    setLimit(limit + 10);
  };

  const { t: tObjects } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.objectDetails.projects",
  });

  return (
    <>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className={styles.detailsTab}>
          {sika_designer?.object_list
            ?.slice(0, limit)
            .map((contact: CurrentObject, index: number) => (
              <div className={styles.objectCard} key={index}>
                <table key={index} className={styles.tabObjectTable}>
                  <tbody>
                    <tr className={styles.tabObjectRow}>
                      <td className={styles.boldP}>
                        {tObjects("aimapCategory")}:
                      </td>
                      <td>{contact.aimap_classifier_new}</td>
                    </tr>
                    <tr className={styles.tabObjectRow}>
                      <td className={styles.boldP}>{tObjects("address")}:</td>
                      <td>{contact.address}</td>
                    </tr>
                    <tr className={styles.tabObjectRow}>
                      <td className={styles.boldP}>
                        {tObjects("description")}:
                      </td>
                      <td>
                        {contact.description
                          ? contact.description
                          : contact.name}
                      </td>
                    </tr>
                    <tr className={styles.tabObjectRow}>
                      <td className={styles.boldP}>{tObjects("status")}:</td>
                      <td>{contact.stage_building}</td>
                    </tr>
                    <tr className={styles.tabObjectRow}>
                      <td className={styles.boldP}>
                        {tObjects("documentationStage")}:
                      </td>
                      <td>{contact.stage_documentation}</td>
                    </tr>
                    <tr className={styles.tabObjectRow}>
                      <td className={styles.boldP}>
                        {tObjects("creationDate")}:
                      </td>
                      <td>{contact.date_added}</td>
                    </tr>
                    <tr className={styles.tabObjectRow}>
                      <td className={styles.boldP}>
                        {tObjects("constructionType")}:
                      </td>
                      <td>{contact.construction_type}</td>
                    </tr>
                    <tr className={styles.tabObjectRow}>
                      <td className={styles.boldP}>
                        {tObjects("consequenceClass")}:
                      </td>
                      <td>{contact.consequence_class}</td>
                    </tr>
                    <tr className={styles.tabObjectRow}>
                      <td className={styles.boldP}>
                        {tObjects("floorsCount")}:
                      </td>
                      <td>{contact.floors_number}</td>
                    </tr>
                    <tr className={styles.tabObjectRow}>
                      <td className={styles.boldP}>{tObjects("area")}:</td>
                      <td>{contact.object_square}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
          <button
            className={
              limit >= sika_designer?.object_list?.length
                ? `${styles.typeFilterButton} ${styles.disabled}`
                : styles.typeFilterButton
            }
            onClick={showMoreDocuments}
          >
            {tObjects("showMore")}
          </button>
        </div>
      )}
      <ModalWithHeader
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        title={tObjects("dataNotAvailable")}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalInfoWrapper}>
            <img src={Attention} alt="attention" />
            <p className={styles.infoText}>{tObjects("warn")}</p>
          </div>
          <div className={styles.bodyWrapper}>
            <p className={styles.bodyText}>{tObjects("warn2")}</p>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <button
            onClick={() => navigate(RouteNames.SUBSCRIPTIONS)}
            className={styles.modalButton}
          >
            {tObjects("updateSubscriptionPlan")}
            <img src={ArrowRight} alt="next" />
          </button>
        </div>
      </ModalWithHeader>
    </>
  );
};

export default Objects;
