import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import Jp from "../../../../assets/svg/jpIcon.svg";
import { CurrentSikaDesigner } from "../../../../models/CurrentSikaDesigner";
import SearchResultsResponsive from "../../../../share/searchResults/SearchResultsResponsive";
import GeneralSearchResultItem from "../../../../share/GeneralSearchResultItem/GeneralSearchResultItem";
export function numberWithSpaces(x: number) {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}
const SikaDesignersSearchResults = () => {
  const {
    setCleanAllSikaDesignerFilters,
    getDefaultSikaDesignerResult,
    refreshFeedForSikaDesignersSearchResults,
  } = useActions();
  const { sika_designers, count } = useTypedSelector(
    (state) => state.mapSikaDesignerInfo
  );

  const handleClean = () => {
    setCleanAllSikaDesignerFilters();
    getDefaultSikaDesignerResult();
  };

  return (
    <SearchResultsResponsive
      onClearAllFilters={handleClean}
      count={count}
      handleLoadFeed={refreshFeedForSikaDesignersSearchResults}
    >
      {sika_designers?.map((mapObject: CurrentSikaDesigner) => {
        return (
          <GeneralSearchResultItem
            key={mapObject.id}
            title={mapObject.short_name}
            lat={mapObject.lat}
            lon={mapObject.lon}
            hashId={mapObject.edrpou_code}
            imgSrc={Jp}
            paragraphs={[
              mapObject.edrpou_code,
              "Дохід за 2020 рік, тис. грн: " +
                (mapObject.income_2020
                  ? numberWithSpaces(mapObject.income_2020)
                  : "не визначено"),
              "Кількість завершених проектів 2013-2022: " +
                mapObject.finished_projects_full,
            ]}
          />
        );
      })}
    </SearchResultsResponsive>
  );
};

export default SikaDesignersSearchResults;
