import { maxSquare } from "../constants/constants";
import { IAccount } from "../models/Accounts";
import { IMapFilters } from "../store/reducers/_maps/main/mapFilters/types";

export const getIsFreeAccount = (currentAccount: Partial<IAccount>) => {
  if (!currentAccount.id) return false;
  return currentAccount.subscribe.card_name === "FREE";
};

export const getIsGuestAccount = (currentAccount: Partial<IAccount>) => {
  if (!currentAccount.id) {
    return true;
  } else return false;
};

export const getIsSquareFilterApplied = (squareFilter: number[]) => {
  return JSON.stringify(squareFilter) !== JSON.stringify([0, maxSquare]);
};

const getMainAppliedFiltersCount = (filters: IMapFilters) => {
  const {
    typeFilterArray,
    ccFilterArray,
    constructionType,
    stageBuilding,
    stageDocumentation,
    region,
    squareFilter,
  } = filters;
  return (
    typeFilterArray.length +
    ccFilterArray.length +
    constructionType.length +
    stageBuilding.length +
    stageDocumentation.length +
    region.length +
    filters.dkbs_code.length +
    filters.np_id.length +
    filters.edrpou_code.length +
    filters.keyword.length +
    Number(getIsSquareFilterApplied(squareFilter))
  );
};

export const getIsAdminAccount = (currentAccount: Partial<IAccount>) => {
  const isAdmin =
    currentAccount.role.value === "Administrator" ||
    currentAccount.role.value === "Admin" ||
    currentAccount.role.value === "Адміністратор";
  return isAdmin;
};

const storeUtil = {
  isFreeAccount: getIsFreeAccount,
  isGuestAccount: getIsGuestAccount,
  getMainAppliedFiltersCount,
  isAdminAccount: getIsAdminAccount,
};

export default storeUtil;
