import styled from "@emotion/styled";
import { breakpoints, icons } from "../../../constants";

const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  height: 84px;
  padding: 24px;
  background: #f0f6ff;
  img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.285;
    letter-spacing: 0.0012em;
    color: var(--secondary-black-color);
  }
  @media screen and (max-width: ${breakpoints.small}px) {
    padding: 16px 24px;
    height: auto;
    align-items: flex-start;
    .info {
      height: auto;
    }
    span {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

type Props = {
  text: string;
  icon?: string;
};

export const FilterDescription: React.FC<Props> = ({
  text,
  icon = icons.ExclamationPoint,
}) => {
  return (
    <StyledInfo>
      <img src={icon} alt="info" />
      <span>{text}</span>
    </StyledInfo>
  );
};

export default FilterDescription;
