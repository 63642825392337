import { httpClient } from "./HTTPService";
import { store } from "../index";
import { AuthResponse } from "../../models/AuthResponse";
import { IUserRegFirstStep, UserWithAccount } from "../../models/IUser";
import { IFormInputsFirst } from "../../pages/_auth/Registration/RegistrationFirstStep/RegistrationForm";
import { IFormInputsThird } from "../../pages/_auth/Registration/RegistrationThirdStep/RegistrationThirdStep";
import {
  IAccount,
  IReqInviteUsers,
  IRole,
  ISubTemplate,
  IUser,
} from "../../models/Accounts";
import { IReqSubscription, IRespSubscription } from "../../models/Subscription";
import { UnregUserForm } from "../../components/_componentsForInvitedUsers/UnregisteredUser/UnregisteredUser";
import { IChangePassword } from "../../components/Profile/ChangePasswordForm/ChangePasswordForm";

const token = store.getState().auth.token.access;

class UserService {
  public async authLogin(email: string, password: string) {
    return httpClient.post<AuthResponse>("jwt/create/", { email, password });
  }

  public async authRegistrationFirstStep(data: IFormInputsFirst) {
    return httpClient.post<IUserRegFirstStep>("auth/users/", data);
  }

  public async authRegistrationSecondStep(
    activation_code: string,
    email: string | undefined
  ) {
    return httpClient.post<AuthResponse>("custom_auth/activation/ ", {
      activation_code,
      email,
    });
  }

  public async authRegistrationThirdStep(data: IFormInputsThird) {
    return httpClient.post<UserWithAccount>(
      "users/registration_details/",
      data
    );
  }

  public async subscribeTemplate(sub_type: string) {
    return httpClient.get<ISubTemplate>("subscribe/template_info/", {
      data: { sub_type },
      params: { sub_type },
    });
  }

  public async setSubscribe(data: IReqSubscription) {
    return httpClient.post<IRespSubscription>("liqpay_data/", data);
  }

  public async setUnsubscribe(id: number) {
    return httpClient.post<IAccount>("unsubscribe/", { account_id: id });
  }

  public async authPassRecFirstStep(email: string | undefined) {
    return httpClient.post("custom_auth/reset_password/", { email });
  }

  public async authPassRecSecondStep(
    activation_code: number,
    email: string | undefined
  ) {
    return httpClient.post("check_password_reset/", { activation_code, email });
  }

  public async authPassRecThirdStep(
    activation_code: number,
    email: string | undefined,
    new_password: string,
    re_new_password: string
  ) {
    return httpClient.post("custom_auth/reset_password_confirm/", {
      activation_code,
      email,
      new_password,
      re_new_password,
    });
  }

  public async authChangePassword(data: IChangePassword) {
    return httpClient.post<IChangePassword>("auth/users/set_password/", data);
  }

  public async fetchAccounts() {
    return httpClient.get<IAccount[]>("accounts/", {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  public async fetchCurrentAccounts(id: number) {
    return httpClient.get<IAccount>(`accounts/${id}/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  public async getRoles() {
    return httpClient.get<IRole[]>("roles/");
  }

  public async inviteUsers(data: IReqInviteUsers) {
    return httpClient.post("invite/", data);
  }

  public async inviteRegisteredUser(uid: string | null, token: string | null) {
    return httpClient.post("invite/activate/", { uid, token });
  }

  public async inviteUnregisteredUser(
    uid: string | null,
    token: string | null,
    data: UnregUserForm
  ) {
    return httpClient.post<AuthResponse>("invite/activate_unregistered/", {
      uid,
      token,
      ...data,
    });
  }

  public async deleteAccountUser(account_id: number, user_id: number) {
    return httpClient.delete(
      `user-account-role/delete/${account_id}/${user_id}/`
    );
  }

  public async updateUserRole(
    account_id: number,
    user_id: number,
    role_id: number
  ) {
    return httpClient.patch("user-account-role/", {
      account_id,
      user_id,
      role_id,
    });
  }

  public async getUser(): Promise<any> {
    return httpClient.get("users/");
  }

  public async updateUser(user_id: number, data: Partial<IUser>) {
    return httpClient.patch(`users/${user_id}/`, { ...data });
  }
}

export const userService = new UserService();
