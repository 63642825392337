import React, { Dispatch, ReactNode, SetStateAction } from "react";
import styles from "./Modal.module.css";

interface IProps {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
}

const Modal = ({ modalVisible, setModalVisible, children }: IProps) => {
  return (
    <div
      className={
        modalVisible ? `${styles.overlay} ${styles.active}` : styles.overlay
      }
      onClick={() => setModalVisible(false)}
    >
      <div
        className={
          modalVisible ? `${styles.modal} ${styles.active}` : styles.modal
        }
        onClick={(event) => event.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
