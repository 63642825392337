import {
  DependencyList,
  EffectCallback,
  useCallback,
  useEffect,
  useRef,
} from "react";
import _ from "lodash";

export const useLazyEffect = (
  effect: EffectCallback,
  deps: DependencyList = [],
  wait = 300
) => {
  const cleanUp = useRef<void | (() => void)>();
  const effectRef = useRef<EffectCallback>();
  // eslint-disable-next-line
  effectRef.current = useCallback(effect, deps);
  // eslint-disable-next-line
  const lazyEffect = useCallback(
    _.debounce(() => {
      cleanUp.current = effectRef.current?.();
    }, wait),
    []
  );
  // eslint-disable-next-line
  useEffect(lazyEffect, deps);
  useEffect(() => {
    return () =>
      cleanUp.current instanceof Function ? cleanUp.current() : undefined;
  }, []);
};
