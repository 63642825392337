import { uniq } from "lodash";
import {
  MapFilterActionsEnum,
  MapFiltersAction,
  MapSikaFiltersState,
} from "./types";
import { v4 as uuidv4 } from "uuid";

const initialState: MapSikaFiltersState = {
  isSuggestionsLoading: false,
  finished_projects_full__range: "",
  actual_projects__range: "",
  finished_projects_2021__range: "",
  selectedDocList: [{ id: uuidv4(), value: "1" }],
  is21plus: false,
  isIncomeBigger: false,
  kved: [],
  aimap_classifier_all: [],
  country_part_array: [],
  date_added__gte: "",
  date_added__lte: "",
  suggestions: [],
  activeSuggestions: [],
  consequence_class_array: [],
  domain_array: [],
  role_array: [],
  entity_type_array: [],
  region: [],
  edrpou_code: [],
  sika_keyword: [],
};

export default function mapFiltersReducer(
  state = initialState,
  action: MapFiltersAction
) {
  switch (action.type) {
    case MapFilterActionsEnum.SET_SUGGESTION_LOADING:
      return { ...state, isSuggestionsLoading: action.payload };

    case MapFilterActionsEnum.SET_SIKA_DESIGNER_KEYWORD:
      return {
        ...state,
        sika_keyword: uniq([...state.sika_keyword, action.payload]),
      };

    case MapFilterActionsEnum.SET_SIKA_DESIGNER_CLEAN_KEYWORD:
      return { ...state, sika_keyword: [] };
    case MapFilterActionsEnum.DELETE_SIKA_DESIGNER_KEYWORD:
      return {
        ...state,
        sika_keyword: state.sika_keyword.filter(
          (item) => item !== action.payload
        ),
      };
    case MapFilterActionsEnum.SET_IS_INCOME_BIGGER:
      return { ...state, isIncomeBigger: action.payload };
    case MapFilterActionsEnum.SET_IS_21_PLUS:
      return { ...state, is21plus: action.payload };
    case MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_FILTER_TYPE_FILTER:
      if (state.aimap_classifier_all.includes(action.payload)) {
        return {
          ...state,
          aimap_classifier_all: state.aimap_classifier_all.filter(
            (item) => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          aimap_classifier_all: [...state.aimap_classifier_all, action.payload],
        };
      }
    case MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_CC_FILTER:
      if (state.consequence_class_array.includes(action.payload)) {
        return {
          ...state,
          consequence_class_array: state.consequence_class_array.filter(
            (item) => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          consequence_class_array: [
            ...state.consequence_class_array,
            action.payload,
          ],
        };
      }

    case MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_CATEGORY_FILTER:
      if (state.domain_array.includes(action.payload)) {
        return {
          ...state,
          domain_array: state.domain_array.filter(
            (item) => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          domain_array: [...state.domain_array, action.payload],
        };
      }
    case MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_ORGANIZATION_FILTER:
      if (state.role_array.includes(action.payload)) {
        return {
          ...state,
          role_array: state.role_array.filter(
            (item) => item !== action.payload
          ),
        };
      } else {
        return { ...state, role_array: [...state.role_array, action.payload] };
      }
    case MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_OWNERSHIP_FILTER:
      if (state.entity_type_array.includes(action.payload)) {
        return {
          ...state,
          entity_type_array: state.entity_type_array.filter(
            (item) => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          entity_type_array: [...state.entity_type_array, action.payload],
        };
      }
    case MapFilterActionsEnum.TOGGLE_SIKA_DESIGNER_AREA_FILTER:
      if (state.country_part_array.includes(action.payload)) {
        return {
          ...state,
          country_part_array: state.country_part_array.filter(
            (item) => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          country_part_array: [...state.country_part_array, action.payload],
        };
      }
    case MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_FILTER_TYPE_FILTER:
      return { ...state, aimap_classifier_all: [] };
    case MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_CC_FILTER:
      return { ...state, consequence_class_array: [] };
    case MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_CATEGORY_FILTER:
      return { ...state, domain_array: [] };
    case MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_ORGANIZATION_FILTER:
      return { ...state, role_array: [] };
    case MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_OWNERSHIP_FILTER:
      return { ...state, entity_type_array: [] };
    case MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_AREA_FILTER:
      return { ...state, country_part_array: [] };
    case MapFilterActionsEnum.SET_SIKA_DESIGNER_SUGGESTION:
      return { ...state, suggestions: [...action.payload] };

    case MapFilterActionsEnum.SET_SIKA_DESIGNER_ACTIVE_SUGGESTION:
      const suggestions = [...state.activeSuggestions, action.payload];
      const sudCodes = suggestions.map((item) => item.code);
      const uniqueSudCodes = uniq(sudCodes);
      const uniqSuggestions = uniqueSudCodes.map((item) =>
        suggestions.find((sud) => sud.code === item)
      );

      return {
        ...state,
        activeSuggestions: uniqSuggestions,
      };

    case MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_SUGGESTION:
      return { ...state, activeSuggestions: [] };

    case MapFilterActionsEnum.DELETE_SIKA_DESIGNER_ACTIVE_SUGGESTION:
      return {
        ...state,
        activeSuggestions: state.activeSuggestions.filter(
          (item) => item !== action.payload
        ),
      };

    case MapFilterActionsEnum.SET_SIKA_DESIGNER_REGION:
      return { ...state, region: [...state.region, action.payload] };

    case MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_REGION:
      return { ...state, region: [] };

    case MapFilterActionsEnum.DELETE_SIKA_DESIGNER_REGION:
      return {
        ...state,
        region: state.region.filter((item) => item !== action.payload),
      };
    case MapFilterActionsEnum.SET_DATE_ADDED_LTE:
      return { ...state, date_added__lte: action.payload };
    case MapFilterActionsEnum.SET_DATE_ADDED_GTE:
      return { ...state, date_added__gte: action.payload };
    case MapFilterActionsEnum.SET_SIKA_NUMBER_21_22__RANGE:
      return { ...state, finished_projects_2021__range: action.payload };
    case MapFilterActionsEnum.SET_SIKA_NUMBER_02_24_RANGE:
      return { ...state, actual_projects__range: action.payload };
    case MapFilterActionsEnum.SET_SIKA_NUMBER_13_22_RANGE:
      return { ...state, finished_projects_full__range: action.payload };

    case MapFilterActionsEnum.SET_SIKA_DESIGNER_EDR_CODE:
      return { ...state, edrpou_code: [...state.edrpou_code, action.payload] };

    case MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_EDR_CODE:
      return { ...state, edrpou_code: [] };

    case MapFilterActionsEnum.DELETE_SIKA_DESIGNER_EDR_CODE:
      return {
        ...state,
        edrpou_code: state.edrpou_code.filter(
          (item) => item !== action.payload
        ),
      };

    case MapFilterActionsEnum.DELETE_SIKA_DESIGNER_FILTER_TYPE:
      return {
        ...state,
        aimap_classifier_all: state.aimap_classifier_all.filter(
          (item) => item !== action.payload
        ),
      };

    case MapFilterActionsEnum.DELETE_SIKA_DESIGNER_CC:
      return {
        ...state,
        consequence_class_array: state.consequence_class_array.filter(
          (item) => item !== action.payload
        ),
      };

    case MapFilterActionsEnum.DELETE_SIKA_DESIGNER_CATEGORY:
      return {
        ...state,
        domain_array: state.domain_array.filter(
          (item) => item !== action.payload
        ),
      };

    case MapFilterActionsEnum.DELETE_SIKA_DESIGNER_ORGANIZATION:
      return {
        ...state,
        role_array: state.role_array.filter((item) => item !== action.payload),
      };

    case MapFilterActionsEnum.DELETE_SIKA_DESIGNER_OWNERSHIP:
      return {
        ...state,
        entity_type_array: state.entity_type_array.filter(
          (item) => item !== action.payload
        ),
      };

    case MapFilterActionsEnum.DELETE_SIKA_DESIGNER_AREA:
      return {
        ...state,
        country_part_array: state.country_part_array.filter(
          (item) => item !== action.payload
        ),
      };

    case MapFilterActionsEnum.SET_SIKA_DESIGNER_KVED:
      return { ...state, kved: [...state.kved, action.payload] };

    case MapFilterActionsEnum.CLEAN_SIKA_DESIGNER_KVED:
      return { ...state, kved: [] };

    case MapFilterActionsEnum.DELETE_SIKA_DESIGNER_KVED:
      return {
        ...state,
        kved: state.kved.filter((item) => item !== action.payload),
      };

    default:
      return state;
  }
}
