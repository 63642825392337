import { datasets, defaultRegion } from "../../constants/mapConstants";
import useSearchParams from "../../hooks/useSearchParams";
import { IDatasetType } from "../../models/map.interface";
import ErrorPage404 from "../ErrorPage404/ErrorPage404";
import Companies from "../_maps/Companies/Companies";
import Main from "../_maps/Main/Main";
import Persons from "../_maps/Persons/Persons";
import SikaDesigners from "../_maps/SikaDesigners/SikaDesigners";
import USAIDPage from "../_maps/USAIDPage/USAIDPage";
import { useEffect } from "react";
import { useActions } from "../../hooks/useActions";
import { AvailableCC } from "../../constants/constants";
import { MapPageProvider } from "../../share/MapPage/MapPageContext";
import useAppContext from "../../hooks/useAppContext";

const MainV2 = () => {
  const { messageContextHolder, contextHolder } = useAppContext();
  const { getSearchParams, setSearchParam } = useSearchParams();

  const { getMapConfig } = useActions();

  const lastUrlBeforeLogin = localStorage.getItem("lastUrlBeforeLogin");

  useEffect(() => {
    if (lastUrlBeforeLogin) {
      localStorage.removeItem("lastUrlBeforeLogin");
      window.location.href = lastUrlBeforeLogin;
    }
  }, []);

  const { dataset, region } = getSearchParams();
  useEffect(() => {
    if (!region) {
      setSearchParam("region", defaultRegion);
      getMapConfig(defaultRegion as AvailableCC);
    }
    if (!dataset) {
      setSearchParam("dataset", datasets.objects);
    }
    getMapConfig(region as AvailableCC);
  }, [dataset, region]);

  const selectedDataset = dataset || (datasets.objects as IDatasetType);

  const datasetComponents = {
    [datasets.objects]: <Main />,
    [datasets.company]: <Companies />,
    [datasets.person]: <Persons />,
    [datasets.usaid]: <USAIDPage />,
    [datasets.sika]: <SikaDesigners />,
  };

  if (lastUrlBeforeLogin) return null;
  return (
    <MapPageProvider>
      {messageContextHolder}
      {contextHolder}
      {datasetComponents[selectedDataset] || <ErrorPage404 />}
    </MapPageProvider>
  );
};

export default MainV2;
