import React from "react";
import styles from "./RegistrationStepLoader.module.scss";

interface IProps {
  page: number;
}

const RegistrationStepLoader: React.FC<IProps> = ({ page }) => {
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.firstBall}></div>
      <span
        className={page === 1 ? styles.firstDash : styles.secondDash}
      ></span>
      <div className={page === 1 ? styles.secondBall : styles.firstBall}></div>
      <span
        className={
          page === 1
            ? styles.firstDash
            : page === 2
            ? styles.firstDash
            : styles.secondDash
        }
      ></span>
      <div
        className={
          page === 1
            ? styles.secondBall
            : page === 2
            ? styles.secondBall
            : styles.firstBall
        }
      ></div>
    </div>
  );
};

export default RegistrationStepLoader;
