import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../constants";

const useMobileScreen = (breakpoint?: number) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoint || breakpoints.small}px)`,
  });

  return isMobile;
};

export default useMobileScreen;
