import { useCallback, useEffect } from "react";
import ReactMapGL, {
  Layer,
  Source,
  MapLayerMouseEvent,
  ViewStateChangeEvent,
} from "react-map-gl";
import styles from "./PersonsMap.module.css";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import { useLazyEffect } from "../../../../hooks/useLazyEffect";
import Np from "../../../../assets/svg/personIcon.svg";

import CustomMarker from "../../_mapComponents/CustomMarker/CustomMarker";
import CustomNavigationControl from "../../_mapComponents/CustomNavigationControl/CustomNavigationControl";
import useSelectedLanguage from "../../../../hooks/useSelectedLanguage";
import useSearchParams from "../../../../hooks/useSearchParams";
import usePersonsMapFilters from "./usePersonsMapFilters";
import util from "../../../../util";
import { mapboxAccessToken } from "../../../../constants/mapConstants";
import MapWrapper from "../../../../share/mapComponents/MapWrapper";
import useMobileScreen from "../../../../hooks/useMobileScreen";
import MapBadgeLoader from "../../_mapComponents/MapBadgeLoader";
import useMapObjects from "../../../../hooks/useMapObjects";
import CustomMapPopupItem from "../../_mapComponents/CustomPopup/CustomMapPopupItem";
import CustomPopup from "../../_mapComponents/CustomPopup";
import PinnedSelectedMapObjectsList from "../../_mapComponents/PinnedSelectedMapObjectsList";

const PersonsMap = () => {
  const { getSearchParam, setSearchParam } = useSearchParams();

  const hashId = getSearchParam("hash_id");

  const { id } = useTypedSelector((state) => state.currentAccount);
  const { isFilterBarActive, viewport, currentMarker } = useTypedSelector(
    (state) => state.mapInterface
  );
  const { personMapRef: mapRef } = useTypedSelector(
    (state) => state.mapObjectsInfo
  );

  const { person: selectedMapObject } = useTypedSelector(
    (state) => state.mapPersonInfo
  );
  const {
    getCurrentMapPerson,
    setIsFilterActive,
    setIsDetailsActive,
    setViewport,
    setCurrentMarker,
    getDefaultPersonResult,
    setCurrentMapRef,
  } = useActions();

  const {
    currObjs,
    pinnedCurrObjs,
    setPinnedCurrObjs,
    markerClickHandler,
    markerHoverHandler,
    selectedLayerProps,
  } = useMapObjects();

  useEffect(() => {
    setCurrentMapRef(mapRef);
  }, [mapRef]);

  useEffect(() => {
    if (selectedMapObject?.lat && selectedMapObject?.lon) {
      setIsDetailsActive(true);
      setIsFilterActive(true);
      mapRef.current?.flyTo({
        center: [+selectedMapObject.lat, +selectedMapObject.lon],
        zoom: viewport.zoom >= 12 ? viewport.zoom : 12,
      });
      setCurrentMarker({
        lat: +selectedMapObject.lon,
        lon: +selectedMapObject.lat,
      });
    }
  }, [selectedMapObject]);

  useEffect(() => {
    hashId && getCurrentMapPerson(hashId, id);
  }, [hashId, id]);

  const mapFilter = usePersonsMapFilters();

  useLazyEffect(
    () => {
      if (!selectedMapObject?.RECORD) {
        getDefaultPersonResult();
      }
    },
    [viewport, selectedMapObject],
    700
  );

  const getFeatures = (e: MapLayerMouseEvent) => {
    if (!e) return [];

    const layer =
      viewport.zoom >= 9.8 ? "np-register-layer4" : "np-register-layer7";
    const features = mapRef.current
      ? mapRef.current.queryRenderedFeatures(e.point, {
          layers: [layer],
          filter: mapFilter,
          validate: false,
        })
      : mapRef.current;
    return features;
  };

  const selectedLanguage = useSelectedLanguage();
  const handleMapLoad = useCallback(() => {
    const map = mapRef.current?.getMap();
    if (map) {
      util.mapInterfaceUtil.changeMapboxLanguage(map, selectedLanguage);
      util.mapInterfaceUtil.addPinIconToMapbox(map);
    }
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }
  }, [isFilterBarActive]);

  const isMobile = useMobileScreen();

  const renderPopupItem = (currObj: GeoJSON.Feature, index: number) => {
    return (
      <CustomMapPopupItem
        isSelected={
          selectedMapObject &&
          currObj.properties.RECORD === selectedMapObject?.RECORD
        }
        key={index}
        onClick={() => {
          setSearchParam("hash_id", currObj.properties.RECORD);
        }}
      >
        <div>
          <img src={Np} alt="" />
          <h4 className={styles.titleStyle}>
            {" "}
            {currObj.properties.NAME.length > 37
              ? currObj.properties.NAME.slice(0, 30) + ".."
              : currObj.properties.NAME}
          </h4>
          <h4 className={styles.popUpClassifier}>{currObj.properties.STAN}</h4>
          <p className={styles.construction_type}>
            &middot; {currObj.properties.registration_date_1}
          </p>
        </div>
      </CustomMapPopupItem>
    );
  };

  return (
    <MapWrapper>
      {pinnedCurrObjs?.length > 0 && !selectedMapObject?.RECORD && isMobile && (
        <PinnedSelectedMapObjectsList handleClose={() => setPinnedCurrObjs([])}>
          {pinnedCurrObjs.map((currObj, index) =>
            renderPopupItem(currObj, index)
          )}
        </PinnedSelectedMapObjectsList>
      )}
      <ReactMapGL
        {...viewport}
        mapboxAccessToken={mapboxAccessToken}
        ref={(instance) => (mapRef.current = instance)}
        minZoom={6}
        maxZoom={9.6} // TODO: then dot are invisible
        maxPitch={1}
        dragRotate={false}
        mapStyle={"mapbox://styles/aimap/cld0btpqm004t14p2gne4uyfz"}
        onLoad={handleMapLoad}
        onMove={(e: ViewStateChangeEvent) => {
          setViewport(e.viewState);
        }}
        onZoom={(e: ViewStateChangeEvent) => {
          setViewport(e.viewState);
        }}
        onMouseOver={(e) => markerHoverHandler(getFeatures(e))}
        onClick={(e) => markerClickHandler(getFeatures(e), "RECORD")}
        onMouseMove={(e) => markerHoverHandler(getFeatures(e))}
      >
        <CustomNavigationControl />
        <MapBadgeLoader />
        <Source type="vector" url="mapbox://aimap.np_register_ts7">
          <Layer
            id="layer1"
            filter={mapFilter}
            source-layer="np_register_layer7"
            minzoom={5}
            maxzoom={9.8}
            {...selectedLayerProps}
            type="symbol"
          />
        </Source>
        <Source type="vector" url="mapbox://aimap.np_register_ts4">
          <Layer
            id="layer2"
            filter={mapFilter}
            source-layer="np_register_layer4"
            minzoom={9.8}
            maxzoom={22}
            {...selectedLayerProps}
            type="symbol"
          />
        </Source>
        <CustomMarker marker={currentMarker} />
        {currObjs.length && (
          <CustomPopup
            latitude={+currObjs[0].properties.lat}
            longitude={+currObjs[0].properties.lon}
            itemsCount={currObjs.length}
          >
            {currObjs.map((currObj, index) => renderPopupItem(currObj, index))}
          </CustomPopup>
        )}
      </ReactMapGL>
    </MapWrapper>
  );
};

export default PersonsMap;
