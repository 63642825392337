import React, { useState } from "react";
import styles from "../UserProfileBar.module.scss";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import AccountIcon from "../../../assets/images/AccountIcon.png";
import ArrowRight from "../../../assets/svg/lilArrowRight.svg";
import { IAccount } from "../../../models/Accounts";
import { ClickAwayListener } from "@mui/base";
import { RouteNames } from "../../../routes";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { breakpoints, colors } from "../../../constants";
import classNames from "classnames";
import useAppContext from "../../../hooks/useAppContext";
import useSearchParams from "../../../hooks/useSearchParams";
import useStoreSnippets from "../../../hooks/useStoreSnippets";
import LoginToViewMessage from "../../LoginToViewMessage";

type TabVariantType = "default" | "orange";

interface TabProps {
  variant?: TabVariantType;
  isActive?: boolean;
}

const Tab = styled.div<TabProps>`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 16px 12px 12px;
  border-left: 4px solid
    ${(props) => (props.isActive ? colors.redR400 : "transparent")};
  font-weight: ${(props) => (props.isActive ? "600" : "500")};
  ${(props) => {
    return props.variant === "orange"
      ? css`
          color: var(--secondary-red-color);
        `
      : css`
          color: var(--secondary-black-color);
        `;
  }}
  &:hover {
    background: var(--sixth-black-color);
    cursor: pointer;
  }
`;

const StyledTitle = styled.h4`
  margin-top: 16px;
  margin-bottom: 8px;
  padding-left: 12px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

const UserProfileModal = () => {
  const { logout, getCurrentAccount, setProfileModal } = useActions();
  const {
    name,
    users,
    id: selectedAccountId,
  } = useTypedSelector((state) => state.currentAccount);

  const { accounts } = useTypedSelector((state) => state);

  const { isProfileModalActive } = useTypedSelector(
    (state) => state.mapInterface
  );

  const { isSignIn } = useStoreSnippets();

  const { t } = useTranslation("translation", { keyPrefix: "userProfileBar" });
  const { t: tMain } = useTranslation("translation", { keyPrefix: "mainPage" });
  const [showAccounts, setShowAccounts] = useState(false);
  const navigate = useNavigate();
  const { setIsHamburgerMenuOpen } = useAppContext();
  const currentRoute = useLocation().pathname;
  const { getCurrentMapObject } = useActions();
  const { setCleanAllFilters } = useActions();

  const handleLogout = () => {
    logout();
    setProfileModal(false);
    const currentUrl = window.location.href;
    localStorage.setItem("lastUrlBeforeLogin", currentUrl);
    navigate(RouteNames.LOGIN);
  };

  const { getSearchParam } = useSearchParams();
  const handleSetAccount = (account: IAccount) => {
    getCurrentAccount(account.id);
    setProfileModal(false);
    // clear filters.
    const hashId = getSearchParam("hash_id");
    getCurrentMapObject(hashId, account.id);
    setCleanAllFilters();
  };

  const handleNavigateTo = (route: string) => {
    navigate(route);
    setIsHamburgerMenuOpen(false);
  };

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.small}px)`,
  });

  const accountsList = (
    <div className={styles.accountsWrapper}>
      {accounts?.map((account: IAccount) => (
        <div
          key={account.id}
          className={classNames({
            [styles.profileModalHeader]: true,
            [styles.active]: account.id === selectedAccountId,
          })}
          onClick={() => handleSetAccount(account)}
          onMouseEnter={() => setShowAccounts(true)}
          onMouseLeave={() => setShowAccounts(false)}
        >
          <img src={AccountIcon} alt="AccountIcon" />
          <div className={styles.textWrapper}>
            <p className={styles.accountName}>{account.name}</p>
            <p className={styles.membersCount}>
              {account.users?.length} {t("members")}
            </p>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <ClickAwayListener onClickAway={() => setProfileModal(false)}>
      <div
        className={
          isProfileModalActive
            ? `${styles.profileModal} ${styles.active}`
            : styles.profileModal
        }
      >
        {!isMobile && (
          <div
            className={styles.profileModalHeader}
            onMouseEnter={() => !isMobile && setShowAccounts(true)}
          >
            <img src={AccountIcon} alt="AccountIcon" />
            <div className={styles.textWrapper}>
              <p className={styles.accountName}>{name}</p>
              <p className={styles.membersCount}>
                {users?.length} {t("members")}
              </p>
            </div>
            <img src={ArrowRight} alt="show more" />
          </div>
        )}
        {isMobile && isSignIn && (
          <>
            <StyledTitle>Accounts</StyledTitle>
            {accountsList}
          </>
        )}
        {isSignIn && (
          <>
            {isMobile && <StyledTitle>Pages</StyledTitle>}
            <div className={styles.profileLinks}>
              <Tab
                isActive={currentRoute === RouteNames.PROFILE}
                onClick={() => {
                  setShowAccounts(false);
                  handleNavigateTo(
                    isSignIn ? RouteNames.PROFILE : RouteNames.LOGIN
                  );
                  setProfileModal(false);
                }}
              >
                {t("profile")}
              </Tab>
              <Tab
                isActive={currentRoute === RouteNames.PAYMENTS}
                onClick={() => {
                  setShowAccounts(false);
                  handleNavigateTo(RouteNames.PAYMENTS);
                  setProfileModal(false);
                }}
              >
                {t("payments")}
              </Tab>
              <Tab
                isActive={currentRoute === RouteNames.RECENT_DOWNLOADS}
                onClick={() => {
                  setShowAccounts(false);
                  handleNavigateTo(RouteNames.RECENT_DOWNLOADS);
                  setProfileModal(false);
                }}
              >
                {t("recent_downloads", "Recent downloads")}
              </Tab>
            </div>
            <Tab
              variant={"orange"}
              onClick={() => {
                setShowAccounts(false);
                handleLogout();
              }}
            >
              {t("logout")}
            </Tab>
          </>
        )}
        {!isSignIn && <LoginToViewMessage text={tMain("authToView")} />}
        {showAccounts && !isMobile && accountsList}
      </div>
    </ClickAwayListener>
  );
};

export default UserProfileModal;
