import {
  SearchResultItem,
  StyledSearchResultsItemTitle,
} from "../searchResults";
import { formatSearchResultTitle } from "../../util/stringUtil";
import { useActions } from "../../hooks/useActions";
import useSearchParams from "../../hooks/useSearchParams";

type Props = {
  key: number;
  title: string;
  lat: number | string;
  lon: number | string;
  hashId: string | number;
  imgSrc: string;
  paragraphs: string[];
};

const GeneralSearchResultItem = ({
  key,
  title,
  lat,
  lon,
  hashId,
  imgSrc,
  paragraphs,
}: Props) => {
  const { setCurrentMarker } = useActions();
  const { setSearchParam } = useSearchParams();

  const handleClickSearchResult = () => {
    setSearchParam("hash_id", hashId);
  };

  return (
    <SearchResultItem
      hashId={hashId}
      key={key}
      onMouseEnter={() =>
        setCurrentMarker({
          lon: lat,
          lat: lon,
        })
      }
      onMouseLeave={() => setCurrentMarker({ lon: 0, lat: 0 })}
      onClick={handleClickSearchResult}
    >
      <img src={imgSrc} alt="" />
      <StyledSearchResultsItemTitle>
        {formatSearchResultTitle(title)}
      </StyledSearchResultsItemTitle>
      {paragraphs.map((subtitle, index) => (
        <p key={index}>{subtitle}</p>
      ))}
    </SearchResultItem>
  );
};

export default GeneralSearchResultItem;
