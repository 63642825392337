import styled from "@emotion/styled";
import { breakpoints, zIndexes } from "../../constants";
import useMobileScreen from "../../hooks/useMobileScreen";
import { StyledBackDrop } from "../SearchFilter/SearchFilterStyledComponents";
import { MobileModal, MobileModalBody } from "../MobileModal/MobileModal";

type Props = {
  isShown: boolean;
  onClose: () => void;
  children: React.ReactNode;
  modalWidth?: number;
};

const StyledContentContainer = styled.div<{ modalWidth: number }>`
  z-index: ${zIndexes.filterModal};
  position: absolute;
  top: 100%;
  right: -58px;
  margin-top: 8px;
  padding: 24px;
  /* width: 512px; */
  width: ${(props) => props.modalWidth + "px"};
  max-height: 534px;
  padding: 24px;
  border-radius: 8px;
  gap: 24px;
  background: #ffffff;
  border: 1px solid #091e4226;
  box-shadow: 0px 8px 12px 0px #091e4226;
  overflow: auto;

  @media screen and (max-width: ${breakpoints.medium + "px"}) {
    width: 327px;
    right: -46px;
  }
`;

const Modal = ({ isShown, onClose, children, modalWidth = 512 }: Props) => {
  const isMobile = useMobileScreen();

  return (
    <>
      {!isMobile
        ? isShown && (
            <>
              <StyledBackDrop onClick={onClose} />
              <StyledContentContainer modalWidth={modalWidth}>
                {children}
              </StyledContentContainer>
            </>
          )
        : isShown && (
            <MobileModal title={""} onClose={onClose} hasActions={false}>
              <MobileModalBody>{children}</MobileModalBody>
            </MobileModal>
          )}
    </>
  );
};

export default Modal;
