import React, { Dispatch, SetStateAction, useState } from "react";
import { CustomInput } from "../../../../share/Input/Input";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import styles from "../PasswordRecovery.module.scss";
import { ButtonLoader } from "../../../../share/Loader/Loader";
import { useActions } from "../../../../hooks/useActions";
import { useTranslation } from "react-i18next";

export interface IProps {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  resetPassword: IFormPassRec;
  setResetPassword: Dispatch<
    SetStateAction<{ email: string; activation_code: number }>
  >;
}

export interface IFormPassRec {
  email: string;
  activation_code: number;
}

const PassRecFirstPage = ({
  setPage,
  resetPassword,
  setResetPassword,
}: IProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "passwordRecoveryPage.PassRecFirstPage",
  });

  const schema = yup.object<Record<keyof IFormPassRec, yup.AnySchema>>().shape({
    email: yup.string().email(t("email.valid")).required(t("email.required")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const { error, isLoading } = useTypedSelector((state) => state.auth);
  const { passRecFirstStep } = useActions();
  const [disable, setDisable] = useState(false);

  const onSubmit: SubmitHandler<IFormPassRec> = async (data: IFormPassRec) => {
    setDisable(true);
    const result = await passRecFirstStep(data.email);
    if (!!result) {
      setPage((prevValue: number) => prevValue + 1);
    }
    setResetPassword({
      email: data.email,
      activation_code: data.activation_code,
    });
    setTimeout(() => {
      setDisable(false);
    }, 5000);
  };

  return (
    <form
      onSubmitCapture={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
      className={styles.passRecForm}
    >
      <div className={styles.passRecWrapper}>
        <label htmlFor="email" className={styles.passRecLabel}>
          {t("email.label")}
          <CustomInput
            name="email"
            type="email"
            inputClassName={styles.passRecInput}
            register={register("email")}
            isError={!!errors.email && !!errors.email?.message}
            errorText={errors.email?.message}
            defaultValue={resetPassword?.email}
          />
        </label>
        {error && <span className={styles.errorMsg}>{error}</span>}
      </div>
      <button type="submit" className={styles.passRecButton} disabled={disable}>
        {t("submitBtn")}
        {isLoading ? <ButtonLoader /> : null}
      </button>
    </form>
  );
};

export default PassRecFirstPage;
