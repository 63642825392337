import { IViewport } from "../../../../models/Viewport";
import { ICurrentMarker } from "../../../../models/CurrentMarker";

export interface IMapInterface {
  isLoading: boolean;
  isFilterBarActive: boolean;
  isDetailsActive: boolean;
  isProfileModalActive: boolean;
  isSettingsActive: boolean;
  offset: number;
  page: number;
  viewport: IViewport;
  currentMarker: ICurrentMarker;
  isPulloutDrawerOpen: boolean;
  currentMapRef: any;
}

export enum MapActionsEnum {
  SET_IS_MAP_INTERFACE_LOADING = "SET_IS_MAP_INTERFACE_LOADING",
  SET_VIEWPORT = "SET_VIEWPORT",
  SET_FILTER_BAR_ACTIVE = "SET_FILTER_BAR_ACTIVE",
  SET_OBJECT_DETAILS_ACTIVE = "SET_OBJECT_DETAILS_ACTIVE",
  SET_PROFILE_MODAL_ACTIVE = "SET_PROFILE_MODAL_ACTIVE",
  SET_SETTINGS_ACTIVE = "SET_SETTINGS_ACTIVE",
  SET_OFFSET = "SET_OFFSET",
  SET_PAGE = "SET_PAGE",
  SET_CURRENT_MARKER = "SET_CURRENT_MARKER",
  UPDATE_MAP_INTERFACE = "UPDATE_MAP_INTERFACE",
  SET_IS_PULLOUT_DRAWER_OPEN = "SET_IS_PULLOUT_DRAWER_OPEN",
  SET_CURRENT_MAP_REF = "SET_CURRENT_MAP_REF",
}

export interface SetCurrentMapRef {
  type: MapActionsEnum.SET_CURRENT_MAP_REF;
  payload: any;
}

export interface SetIsPulloutDrawerOpen {
  type: MapActionsEnum.SET_IS_PULLOUT_DRAWER_OPEN;
  payload: boolean;
}

export interface SetObjectsLoading {
  type: MapActionsEnum.SET_IS_MAP_INTERFACE_LOADING;
  payload: boolean;
}

export interface SetFilterBarActiveAction {
  type: MapActionsEnum.SET_FILTER_BAR_ACTIVE;
  payload: boolean;
}

export interface SetObjectDetailsActiveAction {
  type: MapActionsEnum.SET_OBJECT_DETAILS_ACTIVE;
  payload: boolean;
}

export interface SetProfileModalActive {
  type: MapActionsEnum.SET_PROFILE_MODAL_ACTIVE;
  payload: boolean;
}

export interface SetSettingsActive {
  type: MapActionsEnum.SET_SETTINGS_ACTIVE;
  payload: boolean;
}

export interface SetOffsetAction {
  type: MapActionsEnum.SET_OFFSET;
  payload: number;
}

export interface SetPageAction {
  type: MapActionsEnum.SET_PAGE;
  payload: number;
}

export interface SetViewportAction {
  type: MapActionsEnum.SET_VIEWPORT;
  payload: IViewport;
}

export interface SetCurrentMarkerAction {
  type: MapActionsEnum.SET_CURRENT_MARKER;
  payload: ICurrentMarker | null;
}

export interface IUpdateMapInterfaceAction {
  type: MapActionsEnum.UPDATE_MAP_INTERFACE;
  payload: ICurrentMarker;
}

export type MapInterfaceAction =
  | SetObjectsLoading
  | SetFilterBarActiveAction
  | SetObjectDetailsActiveAction
  | SetProfileModalActive
  | SetSettingsActive
  | SetOffsetAction
  | SetPageAction
  | SetViewportAction
  | SetCurrentMarkerAction
  | IUpdateMapInterfaceAction
  | SetIsPulloutDrawerOpen
  | SetCurrentMapRef;
