import React from "react";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CustomInput, CustomSelect } from "../../../../share/Input/Input";
import styles from "../Registration.module.scss";
import { useActions } from "../../../../hooks/useActions";
import { Button } from "antd";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { ButtonLoader } from "../../../../share/Loader/Loader";
import { useTranslation } from "react-i18next";
import { RouteNames } from "../../../../routes";
import { useNavigate } from "react-router-dom";

export interface IFormInputsThird {
  company_name: string;
  position: string;
  employees_number: number;
  phone_number: string;
  language?: string;
}

const RegistrationThirdStep = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "registrationPage.RegistrationThirdStep",
  });

  const schema = yup
    .object<Record<keyof IFormInputsThird, yup.AnySchema>>()
    .shape({
      company_name: yup.string().required(t("companyName.required")),
      phone_number: yup.string(),
      position: yup.string().required(t("position.required")),
      employees_number: yup.number().required(t("employeeCount.required")),
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const { error, isLoading } = useTypedSelector((state) => state.auth);
  const { registrationThirdStep } = useActions();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<IFormInputsThird> = (
    data: IFormInputsThird
  ) => {
    registrationThirdStep({
      ...data,
      language: currentLanguage,
    });
    navigate(RouteNames.SUBSCRIPTIONS);
  };

  return (
    <form
      onSubmitCapture={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
      className={styles.thirdForm}
    >
      <div className={styles.thirdFormWrapper}>
        <h3 className={styles.formTitle}>{t("title")}</h3>
        <label className={styles.label} htmlFor="company_name">
          {t("companyName.label")}
          <CustomInput
            name="company_name"
            type="text"
            inputClassName={styles.input}
            register={register("company_name")}
            isError={!!errors.company_name && !!errors.company_name?.message}
            errorText={errors.company_name?.message}
          />
        </label>
        <label className={styles.label} htmlFor="phone">
          {t("phoneNumber.label")}
          <Controller
            name="phone_number"
            control={control}
            rules={{
              required: true,
              minLength: 14,
              maxLength: 14,
              validate: (value) => isValidPhoneNumber(value),
            }}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                value={value}
                onChange={onChange}
                defaultCountry="UA"
                international
                withCountryCallingCode
                smartCaret
                id="phone_number"
                limitMaxLength
                className={styles.input}
              />
            )}
          />
          {!!errors.phone_number && !!errors.phone_number?.message && (
            <span className={styles.errorMsg}>
              {errors.phone_number?.message}
            </span>
          )}
        </label>
        <label className={styles.label} htmlFor="position">
          {t("position.label")}
          <CustomInput
            name="position"
            type="text"
            inputClassName={styles.input}
            register={register("position")}
            isError={!!errors.position && !!errors.position?.message}
            errorText={errors.position?.message}
          />
        </label>
        <label className={styles.label} htmlFor="employees_number">
          {t("employeeCount.label")}
          <CustomSelect
            defaultValue={5 - 10}
            register={register("employees_number")}
            name="employees_number"
            isError={
              !!errors.employees_number && !!errors.employees_number?.message
            }
            errorText={errors.employees_number?.message as string}
            classSelectName={styles.select}
          >
            <option value={5 - 10}>5 - 10</option>
            <option value={10 - 20}>10 - 20</option>
            <option value={30 - 200}>30 +</option>
          </CustomSelect>
        </label>
        {error && <span className={styles.errorMsg}>{error}</span>}
      </div>
      <div>
        <Button
          className={styles.thirdFormSubmitButton}
          htmlType="submit"
          disabled={isLoading}
        >
          {t("submitBtn")}
          {isLoading ? <ButtonLoader /> : null}
        </Button>
      </div>
    </form>
  );
};

export default RegistrationThirdStep;
