import { formatDistanceToNow } from "date-fns";
import useDateLocale from "./useDateLocale";

const useFormatDistanceToNowLocalized = (timestamp: any) => {
  const { selectedLocale } = useDateLocale();

  if (timestamp === undefined) {
    return;
  }

  const date = new Date(timestamp);

  return formatDistanceToNow(date, { addSuffix: true, locale: selectedLocale });
};

export default useFormatDistanceToNowLocalized;
