import styled from "@emotion/styled";
import { ReactNode } from "react";
import CustomIcon from "../CustomIcon/CustomIcon";
import { ReactComponent as CloseIcon } from "../../assets/svg/Close_small_nofill.svg";
import { motion } from "framer-motion";
import useMobileMenuDrawer from "./useMobileMenuDrawer";
import { zIndexes } from "../../constants";

const StyledMobileMenuDrawerContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 60px;
  left: 0;
  padding-top: 24px;
  z-index: ${zIndexes.modal};
  display: flex;
  flex-direction: column-reverse;
`;

const StyledMobileMenuDrawer = styled.div`
  border-radius: 16px 16px 0px 0px;
  background: var(--White-W500, #fff);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: ${zIndexes.modal};
`;

const StyledMobileMenuDrawerHeader = styled.div`
  padding: 8px 16px;
  position: relative;
`;

const StyledCloseButton = styled.button`
  height: 32px;
  width: 32px;
  padding: 4px;
  border: none;
  outline: none;
  background: none;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translate(0, -50%);
`;

const StyledTitle = styled.h3`
  padding: 0 36px;
  color: var(--Dark-D400, #242731);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.026px;
  text-align: center;
`;

interface MobileMenuDrawerProps {
  onClose: () => void;
  children: ReactNode | ReactNode[];
  isShown: boolean;
}

const MobileMenuDrawer: React.FC<MobileMenuDrawerProps> = ({
  children,
  onClose,
  isShown,
}) => {
  const {
    animationDisplayControls,
    animationOpacityControls,
    animationShiftControls,
    modalVerticalShift,
    modalHeight,
    modalRef,
    handleModalClose,
    handleDragEnd,
  } = useMobileMenuDrawer({ onClose, isShown });

  return (
    <>
      <StyledMobileMenuDrawerContainer animate={animationDisplayControls}>
        <motion.div
          style={{
            position: "absolute",
            inset: "0, 0, 0, 0",
            opacity: 0,
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(38, 45, 51, 0.5)",
          }}
          onClick={handleModalClose}
          animate={animationOpacityControls}
        />
        <motion.div
          drag="y"
          style={{
            zIndex: zIndexes.modal,
            height: "100%",
            y: modalVerticalShift,
          }}
          animate={animationShiftControls}
          onDragEnd={handleDragEnd}
          dragConstraints={{ top: 0, left: 0, right: 0, bottom: modalHeight }}
          dragElastic={0}
          dragMomentum={false}
        >
          <StyledMobileMenuDrawer ref={modalRef}>
            <StyledMobileMenuDrawerHeader>
              <StyledCloseButton>
                <CustomIcon
                  icon={CloseIcon}
                  onClick={handleModalClose}
                  color={"#242731"}
                  size="100%"
                ></CustomIcon>
              </StyledCloseButton>
              <StyledTitle>Menu</StyledTitle>
            </StyledMobileMenuDrawerHeader>
            {children}
          </StyledMobileMenuDrawer>
        </motion.div>
      </StyledMobileMenuDrawerContainer>
    </>
  );
};

export { MobileMenuDrawer };
