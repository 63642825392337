import React, { useState } from "react";
import styles from "../../../main/ObjectDetails/ObjectDetails.module.scss";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { CustomLoader } from "../../../../../share/Loader/Loader";
import ModalWithHeader from "../../../../../share/ModalWithHeader/ModalWithHeader";
import HiddenText from "../../../../../assets/svg/ArrowDown.svg";
import Attention from "../../../../../assets/svg/AttentionHand.svg";
import ArrowRight from "../../../../../assets/svg/arrowRight.svg";

import { RouteNames } from "../../../../../routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DetailsCellFormattedText from "../../../../../share/DetailsCellFormattedText/DetailsCellFormattedText";

const Details = () => {
  const { usaid_company } = useTypedSelector((state) => state.mapUSAIDInfo);
  const { isLoading } = useTypedSelector((state) => state.auth);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  const { t: tDetails } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.objectDetails.details",
  });

  return (
    <div>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className={styles.detailsTab}>
          <h4 className={styles.tabTitle}>{tDetails("characteristic")}</h4>
          <table className={styles.tabTable}>
            <tbody>
              <tr className={styles.tabRow}>
                <td>{tDetails("category")}:</td>
                <td>{usaid_company?.category}</td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("address")}:</td>
                <td>
                  {usaid_company?.address === "#####" ? (
                    <button
                      type="button"
                      className={styles.noRulesLink}
                      onClick={() => setModalVisible(true)}
                      data-tooltip={tDetails("showAll")}
                    >
                      <img src={HiddenText} alt="no rules" />
                    </button>
                  ) : (
                    usaid_company?.address
                  )}
                </td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("businessOperations")}:</td>
                <td>
                  {usaid_company?.activity_status_2022
                    ? usaid_company?.activity_status_2022
                    : tDetails("notDefined")}
                </td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("activityType")}:</td>
                <td>{usaid_company?.economic_sector}</td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("kved")}:</td>
                <td>{usaid_company?.kved}</td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("activityDescription")}:</td>
                <td>{usaid_company?.kved_description}</td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("director")}:</td>
                <td>{usaid_company?.ceo}</td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("phoneNumber")}:</td>
                <td>
                  {usaid_company?.phone_number ? (
                    <DetailsCellFormattedText name={tDetails("phoneNumber")}>
                      {usaid_company?.phone_number}
                    </DetailsCellFormattedText>
                  ) : (
                    tDetails("notDefined")
                  )}
                </td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("2022employees")}:</td>
                <td>
                  {usaid_company?.staff_2022 && usaid_company?.staff_2022 > 0
                    ? usaid_company?.staff_2022
                    : tDetails("notDefined")}
                </td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("deltaEmployees")}:</td>
                <td>
                  {usaid_company?.staff_2022 && usaid_company?.staff_2022 > 0
                    ? usaid_company?.staff_delta
                    : tDetails("notDefined")}
                </td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("profit2020")} $:</td>
                <td>
                  {usaid_company?.income_2020_usd
                    ? (usaid_company?.income_2020_usd * 1000).toFixed(2)
                    : tDetails("notDefined")}
                </td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("profit2021")} $:</td>
                <td>
                  {usaid_company?.income_2021_usd
                    ? (usaid_company?.income_2021_usd * 1000).toFixed(2) +
                      " USD"
                    : tDetails("notDefined")}
                </td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("profit2022")} $:</td>
                <td>
                  {usaid_company?.income_2022_usd
                    ? (usaid_company?.income_2022_usd * 1000).toFixed(2) +
                      " USD"
                    : tDetails("notDefined")}
                </td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("export2021")} $:</td>
                <td>
                  {usaid_company?.export_2021_usd
                    ? (usaid_company?.export_2021_usd * 1000).toFixed(2) +
                      " USD"
                    : tDetails("notDefined")}
                </td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("export2022")} $:</td>
                <td>
                  {usaid_company?.export_2022_usd
                    ? (usaid_company?.export_2022_usd * 1000).toFixed(2) +
                      " USD"
                    : tDetails("notDefined")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <ModalWithHeader
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        title={tDetails("dataNotAvailable")}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalInfoWrapper}>
            <img src={Attention} alt="attention" />
            <p className={styles.infoText}>{tDetails("warn")}</p>
          </div>
          <div className={styles.bodyWrapper}>
            <p className={styles.bodyText}>{tDetails("warn2")}</p>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <button
            onClick={() => navigate(RouteNames.SUBSCRIPTIONS)}
            className={styles.modalButton}
          >
            {tDetails("updateSubscriptionPlan")}
            <img src={ArrowRight} alt="next" />
          </button>
        </div>
      </ModalWithHeader>
    </div>
  );
};

export default Details;
