import React from "react";
import RegistrationForm, { IFormInputsFirst } from "./RegistrationForm";

interface IProps {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setUser: React.Dispatch<React.SetStateAction<IFormInputsFirst>>;
  user?: IFormInputsFirst;
}

const RegistrationFirstStep: React.FC<IProps> = ({ setPage, setUser }) => {
  return (
    <>
      {/*<AuthWithSocialNetworks/>*/}
      <RegistrationForm setPage={setPage} setUser={setUser} />
    </>
  );
};

export default RegistrationFirstStep;
