import React, { useState } from "react";
import styles from "../ObjectDetails.module.scss";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { IRoles } from "../../../../../models/CurrentObject";
import { CustomLoader } from "../../../../../share/Loader/Loader";
import HiddenText from "../../../../../assets/svg/Hidden Text.svg";
import Attention from "../../../../../assets/svg/AttentionHand.svg";
import ArrowRight from "../../../../../assets/svg/arrowRight.svg";
import ModalWithHeader from "../../../../../share/ModalWithHeader/ModalWithHeader";
import { RouteNames } from "../../../../../routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DetailsCellFormattedText from "../../../../../share/DetailsCellFormattedText/DetailsCellFormattedText";
import { removeDuplicatesAndLeavePrivateText } from "../../../../../util";
import useStoreSnippets from "../../../../../hooks/useStoreSnippets";
import LoginToViewMessage from "../../../../LoginToViewMessage";
import NoContactsSection from "../../../../../share/objectDetails/ContactsTab/NoContactsSection/NoContactsSection";
import { IAimapClassifier } from "../../../../../constants/mapConstants";

const Contacts = () => {
  const { selectedMapObject } = useTypedSelector(
    (state) => state.mapObjectsInfo
  );
  const { isLoading } = useTypedSelector((state) => state.mapInterface);

  const { t: tContacts } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.objectDetails.contacts",
  });

  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  const { isGuestAccount } = useStoreSnippets();

  if (isGuestAccount) {
    return (
      <div className={styles.detailsTab}>
        <LoginToViewMessage />
      </div>
    );
  }
  const isNoContacts = selectedMapObject?.roles.length === 0;
  if (isLoading) return <CustomLoader />;
  if (isNoContacts) {
    return (
      <NoContactsSection
        aimapClassifierType={
          selectedMapObject?.aimap_classifier as IAimapClassifier
        }
      />
    );
  }
  return (
    <>
      <div className={styles.detailsTab}>
        {selectedMapObject?.roles?.includes("Forbidden") ? (
          <div>
            <h2 className={styles.forbiddenTitle}>
              {tContacts("dataNotAvailable")}
            </h2>
            <p className={styles.forbiddenText}>{tContacts("warn2")}</p>
            <button
              onClick={() => navigate(RouteNames.SUBSCRIPTIONS)}
              className={styles.modalButton}
            >
              {tContacts("updateSubscriptionPlan")}
              <img src={ArrowRight} alt="next" />
            </button>
          </div>
        ) : (
          selectedMapObject?.roles?.map((user: IRoles, index: number) => (
            <div key={index}>
              <h4 className={styles.tabTitle}>{user.role_name}</h4>
              {user?.JP?.map((el, index) => (
                <table
                  key={index}
                  className={styles.tabTable}
                  data-id="contacts_table"
                >
                  <tbody>
                    {el
                      ?.filter(removeDuplicatesAndLeavePrivateText)
                      .map((item, index) => (
                        <tr key={index} className={styles.tabRow}>
                          <td>{item.name}</td>
                          <td>
                            {item.value === "#####" ? (
                              <button
                                type="button"
                                className={styles.noRulesLink}
                                onClick={() => setModalVisible(true)}
                                data-tooltip={tContacts(
                                  "updateSubscriptionPlan"
                                )}
                              >
                                <img src={HiddenText} alt="no rules" />
                              </button>
                            ) : (
                              <DetailsCellFormattedText name={item.name}>
                                {item.value}
                              </DetailsCellFormattedText>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ))}
              {user.NP &&
                user.NP.map((item, index: number) => (
                  <table key={index} className={styles.tabTable}>
                    <tbody>
                      <tr className={styles.tabRow}>
                        <td>{tContacts("name")}:</td>
                        <td>
                          {item.fio === "#####" ? (
                            <button
                              type="button"
                              className={styles.noRulesLink}
                              onClick={() => setModalVisible(true)}
                              data-tooltip={tContacts("showAll")}
                            >
                              <img src={HiddenText} alt="no rules" />
                            </button>
                          ) : (
                            item.fio
                          )}
                        </td>
                      </tr>
                      {!!item.sert && (
                        <tr className={styles.tabRow} data-id="sertificate">
                          <td>{tContacts("certificate")}:</td>
                          <td>
                            {item.sert === "#####" ? (
                              <button
                                type="button"
                                className={styles.noRulesLink}
                                onClick={() => setModalVisible(true)}
                                data-tooltip={tContacts("showAll")}
                              >
                                <img src={HiddenText} alt="no rules" />
                              </button>
                            ) : (
                              item.sert
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                ))}
            </div>
          ))
        )}
      </div>
      <ModalWithHeader
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        title={tContacts("dataNotAvailable")}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalInfoWrapper}>
            <img src={Attention} alt="attention" />
            <p className={styles.infoText}>{tContacts("warn")}</p>
          </div>
          <div className={styles.bodyWrapper}>
            <p className={styles.bodyText}>{tContacts("warn2")}</p>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <button
            onClick={() => navigate(RouteNames.SUBSCRIPTIONS)}
            className={styles.modalButton}
          >
            {tContacts("updateSubscriptionPlan")}
            <img src={ArrowRight} alt="next" />
          </button>
        </div>
      </ModalWithHeader>
    </>
  );
};

export default Contacts;
