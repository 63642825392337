import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes";
import { GradientRedButton } from "../../share/Button/Button";
import { useTranslation } from "react-i18next";
import { useActions } from "../../hooks/useActions";
import useSearchParams from "../../hooks/useSearchParams";

type Props = {
  onClick?: () => void;
};

const LoginButton = ({ onClick }: Props) => {
  const navigate = useNavigate();

  const { t: tMainPage } = useTranslation("translation", {
    keyPrefix: "mainPage",
  });
  const { setProfileModal, setCurrentMarker, setIsDetailsActive } =
    useActions();

  const { removeQueryParam } = useSearchParams();

  const saveLastUrlBeforeGoingToLogin = () => {
    const currentUrl = window.location.href;
    localStorage.setItem("lastUrlBeforeLogin", currentUrl);
  };

  const clearOnLogin = () => {
    removeQueryParam("hash_id");
    setCurrentMarker({ lon: 0, lat: 0 });
    setIsDetailsActive(false);
  };

  const handleClickLoginButton = () => {
    saveLastUrlBeforeGoingToLogin();
    clearOnLogin();
    if (onClick) {
      onClick();
    } else {
      navigate(RouteNames.LOGIN);
      setProfileModal(false);
    }
  };
  return (
    <GradientRedButton onClick={handleClickLoginButton}>
      {tMainPage("login")}
    </GradientRedButton>
  );
};

export default LoginButton;
