import { httpClient } from "./HTTPService";
import { IReqCompanies } from "../../models/CompanyObject";
import { store } from "../index";
import { ISearchRequest, ISearchResponse } from "../../models/Search";
import { ICurrentCompany } from "../../models/CurrentCompany";
import qs from "qs";
import { IMapCompanyInfoState } from "../reducers/_maps/company/company/types";

const token = store.getState().auth.token.access;

class CompanyService {
  public async fetchMapCompanies(data: IReqCompanies) {
    return httpClient.get<IMapCompanyInfoState>(
      `companies/` + data.account_id + "/",
      {
        params: data,
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false });
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  }

  public async fetchMapCompany(RECORD: string, account_id: number) {
    if (RECORD) {
      return httpClient.get<ICurrentCompany>(
        `companies/` + account_id + "/" + RECORD + "/",
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } else return undefined;
  }

  public async fetchSuggestions(data: ISearchRequest) {
    return httpClient.post<ISearchResponse[]>(
      `companies/` + data["account_id"] + `/suggest/`,
      data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }
}

export const companyService = new CompanyService();
