import {
  IAccount,
  InvitedUsers,
  IRole,
  ISubTemplate,
} from "../../../models/Accounts";

export enum AccountActionsEnum {
  SET_ACCOUNTS = "SET_ACCOUNTS",
  SET_ACCOUNT = "SET_ACCOUNT",
  SET_SUBSCRIPTION_TEMPLATE = "SET_SUBSCRIPTION_TEMPLATE",
  SET_SUB_TYPE = "SET_SUB_TYPE",
  GET_ROLES = "GET_ROLES",
  SET_INVITED = "SET_INVITED",
  SET_USERS_REQUESTED = "SET_USERS_REQUESTED",
}

export interface SetAccountsAction {
  type: AccountActionsEnum.SET_ACCOUNTS;
  payload: IAccount[];
}

export interface SetAccountAction {
  type: AccountActionsEnum.SET_ACCOUNT;
  payload: IAccount;
}

export interface SetSubTemplate {
  type: AccountActionsEnum.SET_SUBSCRIPTION_TEMPLATE;
  payload: ISubTemplate;
}

export interface SetSubType {
  type: AccountActionsEnum.SET_SUB_TYPE;
  payload: string;
}

export interface GetRoles {
  type: AccountActionsEnum.GET_ROLES;
  payload: IRole[];
}

export interface SetInvited {
  type: AccountActionsEnum.SET_INVITED;
  payload: InvitedUsers[];
}

export interface SetUsersRequested {
  type: AccountActionsEnum.SET_USERS_REQUESTED;
  payload: boolean;
}

export type AccountActions =
  | SetAccountsAction
  | SetAccountAction
  | SetSubType
  | SetSubTemplate
  | GetRoles
  | SetInvited
  | SetUsersRequested;
