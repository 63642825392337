import React, { useState } from "react";
import styles from "../../../main/ObjectDetails/ObjectDetails.module.scss";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { IContact } from "../../../../../models/CurrentCompany";
import { CustomLoader } from "../../../../../share/Loader/Loader";
import HiddenText from "../../../../../assets/svg/Hidden Text.svg";
import Attention from "../../../../../assets/svg/AttentionHand.svg";
import ArrowRight from "../../../../../assets/svg/arrowRight.svg";
import ModalWithHeader from "../../../../../share/ModalWithHeader/ModalWithHeader";
import { RouteNames } from "../../../../../routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DetailsCellFormattedText from "../../../../../share/DetailsCellFormattedText/DetailsCellFormattedText";
import LoginToViewMessage from "../../../../LoginToViewMessage";
import useStoreSnippets from "../../../../../hooks/useStoreSnippets";
import NoContactsSection from "../../../../../share/objectDetails/ContactsTab/NoContactsSection/NoContactsSection";

const Contacts = () => {
  const { company } = useTypedSelector((state) => state.mapCompanyInfo);
  const { isLoading } = useTypedSelector((state) => state.mapInterface);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  const { t: tDContacts } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.objectDetails.contacts",
  });

  const { isGuestAccount } = useStoreSnippets();
  const isNoContacts = company?.contacts?.length === 0;

  if (isGuestAccount) {
    return (
      <div className={styles.detailsTab}>
        <LoginToViewMessage />
      </div>
    );
  }
  if (isLoading) return <CustomLoader />;
  if (isNoContacts) {
    return <NoContactsSection />;
  }
  return (
    <>
      <div className={styles.detailsTab}>
        {company?.contacts?.map((contact: IContact, index: number) => (
          <div key={index}>
            <table key={index} className={styles.tabTable}>
              <tbody>
                <tr className={styles.tabRow}>
                  <td>{contact.type}:</td>
                  <td>
                    {contact?.value === "#####" ? (
                      <button
                        type="button"
                        className={styles.noRulesLink}
                        onClick={() => setModalVisible(true)}
                        data-tooltip={tDContacts("showAll")}
                      >
                        <img src={HiddenText} alt="no rules" />
                      </button>
                    ) : (
                      <DetailsCellFormattedText name={contact.type}>
                        {contact.value}
                      </DetailsCellFormattedText>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
      <ModalWithHeader
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        title={tDContacts("dataNotAvailable")}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalInfoWrapper}>
            <img src={Attention} alt="attention" />
            <p className={styles.infoText}>{tDContacts("warn")}</p>
          </div>
          <div className={styles.bodyWrapper}>
            <p className={styles.bodyText}>{tDContacts("warn2")}</p>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <button
            onClick={() => navigate(RouteNames.SUBSCRIPTIONS)}
            className={styles.modalButton}
          >
            {tDContacts("updateSubscriptionPlan")}
            <img src={ArrowRight} alt="next" />
          </button>
        </div>
      </ModalWithHeader>
    </>
  );
};

export default Contacts;
