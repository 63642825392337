import styled from "@emotion/styled";
import { breakpoints } from "../../constants";
import useSearchParams from "../../hooks/useSearchParams";
import { addObjectToSeen, isObjectSeen } from "../../util/mapInterfaceUtil";
import { IDatasetType } from "../../models/map.interface";

export const StyledSearchResults = styled.div`
  padding-bottom: 32px;
  direction: ltr;
  overflow: auto;
  height: inherit;
  overflow-x: hidden;
  @media screen and (max-width: ${breakpoints.small + "px"}) {
    height: 100%;
    padding: 16px;
    border-top: 1px solid var(--fifth-black-color);
  }
`;

export const StyledSearchResultItem = styled.div<{ seen?: boolean }>`
  box-shadow: inset -1px -1px 1px #d7d9db;
  background-color: var(--primary-white-color);
  padding: 16px 24px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
  background-color: ${(props) =>
    props.seen ? "var(--sixth-black-color)" : "var(--primary-white-color)"};
  &:after {
    content: "";
    width: 90%;
    height: 1px;
    background-color: #e6e6e6;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: width 0.3s ease;
  }
  &:hover {
    background-color: var(--sixth-black-color);
    &:after {
      width: 100%;
    }
  }
  p {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: var(--third-black-color);
  }

  @media screen and (max-width: ${breakpoints.small + "px"}) {
    padding: 4px 4px 12px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 8px;
    box-shadow: none;
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
      padding-bottom: 0;
    }
    &:after {
      display: none;
    }
    img {
      float: left;
      margin-right: 4px;
    }
    p {
      color: var(--Dark-D300, #575f6e);
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      display: inline-block;
    }
  }
`;

export const SearchResultItem = ({ children, hashId, onClick, ...props }) => {
  const dataset = useSearchParams().getSearchParam(
    "dataset"
  ) as unknown as IDatasetType;
  const seen = isObjectSeen(hashId, dataset);

  return (
    <StyledSearchResultItem
      {...props}
      seen={seen}
      onClick={() => {
        onClick();
        addObjectToSeen(hashId, dataset);
      }}
    >
      {children}
    </StyledSearchResultItem>
  );
};

export const StyledSearchResultsItemTitle = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.444;
  letter-spacing: 0.002em;
  color: var(--secondary-black-color);
  margin-top: 10px;
  margin-bottom: 4px;
  @media screen and (max-width: ${breakpoints.small + "px"}) {
    color: var(--Dark-D400, #242731);
    font-size: 14px;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.017px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
  }
`;
