import React, { useState } from "react";
import styles from "../PasswordRecovery.module.scss";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { CustomInput } from "../../../../share/Input/Input";
import { ButtonLoader } from "../../../../share/Loader/Loader";
import { IProps } from "../PassRecFirstPage/PassRecFirstPage";
import ArrowRight from "../../../../assets/svg/arrowRight.svg";
import RepeatButton from "../../../../components/_auth/RepeatButton/RepeatButton";
import { useTranslation } from "react-i18next";

interface IPassRecSecond {
  activation_code: number;
}

const PassRecSecondPage = ({
  setPage,
  resetPassword,
  setResetPassword,
}: IProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "passwordRecoveryPage.PassRecSecondPage",
  });

  const schema = yup
    .object<Record<keyof IPassRecSecond, yup.AnySchema>>()
    .shape({
      activation_code: yup
        .string()
        .required(t("activationCode.required"))
        .min(4, t("activationCode.min"))
        .max(4),
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { error, isLoading } = useTypedSelector((state) => state.auth);
  const { passRecSecondStep } = useActions();
  const [disable, setDisable] = useState(false);

  const onSubmit: SubmitHandler<IPassRecSecond> = async (
    data: IPassRecSecond
  ) => {
    setDisable(true);
    const result = await passRecSecondStep(
      data.activation_code,
      resetPassword?.email
    );
    if (!!result) {
      setPage((prevValue: number) => prevValue + 1);
    }
    setResetPassword({
      email: resetPassword?.email,
      activation_code: data.activation_code,
    });
    setTimeout(() => {
      setDisable(false);
    }, 5000);
  };

  return (
    <>
      <div className={styles.editContainer}>
        <div className={styles.editEmail}>
          {resetPassword?.email}
          <span className={styles.editText}>{t("email.notApproved")}</span>
        </div>
      </div>
      <div className={styles.PassRecSecondPageWrapper}>
        <form
          onSubmitCapture={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
        >
          <div className={styles.PassRecSecondPageFormInnerWrap}>
            <div className={styles.PassRecSecondPageContainer}>
              <label className={styles.PassRecLabel} htmlFor="activation_code">
                {t("activationCode.label")}
              </label>
              <CustomInput
                name="activation_code"
                type="password"
                inputClassName={`${styles.passRecInput} ${styles.inputWidth}`}
                register={register("activation_code")}
                isError={
                  !!errors.activation_code && !!errors.activation_code?.message
                }
                errorText={errors.activation_code?.message}
                inputProps={{ maxLength: 4 }}
              />
              {error && (
                <span className={styles.errorMsg}>
                  {t("activationCode.min")}
                </span>
              )}
            </div>
            <RepeatButton resetPassword={resetPassword} />
          </div>
          <div>
            <button
              className={styles.passRecButton}
              type="submit"
              disabled={disable}
            >
              {t("submitBtn")}
              {isLoading ? (
                <ButtonLoader />
              ) : (
                <img src={ArrowRight} alt="next" />
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PassRecSecondPage;
