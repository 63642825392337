import {
  SearchResultItem,
  StyledSearchResultsItemTitle,
} from "../../../../../share/searchResults";
import Jp from "../../../../../assets/svg/jpIcon.svg";
import { useActions } from "../../../../../hooks/useActions";
import { CurrentCompany } from "../../../../../models/CurrentCompany";
import { formatSearchResultTitle } from "../../../../../util/stringUtil";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";

type Props = {
  key: number;
  mapObject: CurrentCompany;
};

const CompanySearchResultItem = ({ key, mapObject }: Props) => {
  const { setCurrentMarker, getCurrentMapCompany } = useActions();
  const { id } = useTypedSelector((state) => state.currentAccount);

  const handleClickSearchResult = (mapObject: CurrentCompany) => {
    const hashId = mapObject.RECORD;
    getCurrentMapCompany(hashId, id);
  };

  const title = formatSearchResultTitle(mapObject.SHORT_NAME || mapObject.NAME);
  return (
    <SearchResultItem
      hashId={mapObject.RECORD}
      key={key}
      onMouseEnter={() =>
        setCurrentMarker({
          lon: +mapObject.lat,
          lat: +mapObject.lon,
        })
      }
      onMouseLeave={() => setCurrentMarker({ lon: 0, lat: 0 })}
      onClick={() => handleClickSearchResult(mapObject)}
    >
      <img src={Jp} alt="" />
      <StyledSearchResultsItemTitle>{title}</StyledSearchResultsItemTitle>
      <p>{mapObject.EDRPOU}</p>
      <p>
        {mapObject.STAN} &middot;
        {" " + mapObject.registration_date_1 + " "}
      </p>
    </SearchResultItem>
  );
};

export default CompanySearchResultItem;
