import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { CustomCheckbox } from "../../../../../share/Input/Input";
import { FilterTitleWithInfo } from "../../../../../share/filters/FilterTitleWithInfo";
import FilterButtonWithModal from "../../../../../share/filters/FilterButtonWithModal";

const StanFilter = () => {
  const { stanFilterArray } = useTypedSelector((state) => state.personFilters);

  const filterId = "personsStan";

  const { status } = useTypedSelector((state) => state.mapPersonInfo.params);
  const { togglePersonStanFilterObject, typePersonStanClean } = useActions();

  const handleChange = (type: string) => {
    togglePersonStanFilterObject(type);
  };

  const handleClean = () => {
    typePersonStanClean();
  };

  const typeFilterText = "Статус ФОПа";
  const pinText = stanFilterArray.length > 0 && stanFilterArray.length;

  return (
    <FilterButtonWithModal
      title={typeFilterText}
      pinText={pinText}
      filterId={filterId}
      onClear={handleClean}
      fullWidth
    >
      <FilterTitleWithInfo title={typeFilterText} info={""} />
      {status?.map((type: string) => (
        <CustomCheckbox
          value={type}
          key={type}
          checked={stanFilterArray.includes(type)}
          onChange={() => handleChange(type)}
        >
          {type.length > 45 ? type.slice(0, 45) + ".." : type}
        </CustomCheckbox>
      ))}
    </FilterButtonWithModal>
  );
};

export default StanFilter;
