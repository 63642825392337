import moment from "moment";
import { IAccount } from "../../../../../models/Accounts";
import {
  getIsFreeAccount,
  getIsAdminAccount,
} from "../../../../../util/storeUtil";
import { ISearchResponse } from "../../../../../models/Search";
import { suggestionTypes } from "../../../../../constants/constants";

const isDateExpired = (date: string) => {
  return moment(date).isBefore(moment());
};

export const getIsMailingAccessible = (currentAccount: Partial<IAccount>) => {
  const isFreeAccount = getIsFreeAccount(currentAccount);
  if (!currentAccount.id) return false;
  const isSubscriptionExpired = isDateExpired(
    currentAccount.subscribe.expiration_date
  );
  const isPro = !isFreeAccount && !isSubscriptionExpired;
  const isAdmin = getIsAdminAccount(currentAccount);
  return isPro && isAdmin;
};

export const sortCallbackPutsRegionToBottom = (
  a: ISearchResponse,
  b: ISearchResponse
) => {
  if (a.type === suggestionTypes.region) {
    return 1;
  }
  if (b.type === suggestionTypes.region) {
    return -1;
  }
  return 0;
};

export const filterSuggestions = (
  suggestions: ISearchResponse[],
  regex: RegExp
): ISearchResponse[] => {
  return suggestions.filter((suggestion: ISearchResponse) => {
    // Refactored to simplify condition checks
    if (
      suggestion.type === suggestionTypes.region ||
      suggestion.type === suggestionTypes.np ||
      suggestion.type === suggestionTypes.kved
    ) {
      return suggestion.name.match(regex);
    }
    if (suggestion.type === suggestionTypes.jp) {
      return String(suggestion.code).match(regex);
    }
    if (suggestion.type === suggestionTypes.dkbs) {
      return suggestion.code.match(regex);
    }
    return suggestion.type.match(regex);
  });
};
