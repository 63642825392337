import React from "react";
import Authorisation from "./pages/_auth/Authorisation/Authorisation";
import RegistrationMain from "./pages/_auth/Registration/RegistrationMain";
import Accounts from "./pages/_auth/Accounts/Accounts";
import DownloadPowerBI from "./pages/DownloadPowerBI/DownloadPowerBI";
import Dashboard from "./pages/Dashboard/Dashboard";
import PasswordRecovery from "./pages/_auth/PasswordRecovery/PasswordRecovery";
import ErrorPage404 from "./pages/ErrorPage404/ErrorPage404";
import Subscriptions from "./pages/SubscriptionsPage/Subscriptions";
import SubscriptionTypePage from "./pages/SubscriptionTypePage/SubscriptionTypePage";
import GoogleAuth from "./components/_auth/AuthWithSocialNetworks/GoogleAuth/GoogleAuth";
import AccountUsers from "./pages/_auth/AccountUsers/AccountUsers";
import AddingUserToAccount from "./pages/_auth/AddUserInAccount/AddingUserToAccount";
import Payments from "./pages/Payments/Payments";
import RegisteredUser from "./components/_componentsForInvitedUsers/RegisteredUser/RegisteredUser";
import UnregisteredUser from "./components/_componentsForInvitedUsers/UnregisteredUser/UnregisteredUser";
import Profile from "./pages/Profile/Profile";
import MainV2 from "./pages/main-v2";
import RecentDownloads from "./pages/RecentDownloads";
import FeedbackPage from "./pages/FeedbackPage";

interface IRoute {
  path: string;
  component: React.ComponentType;
  exact?: boolean;
}

export enum RouteNames {
  ERROR_PAGE = "/error",
  LOGIN = "/login",
  GOOGLE_AUTH = "/google_reg",
  REGISTRATION = "/user_reg",
  SUBSCRIPTIONS = "/subscriptions",
  SUBSCRIPTION_TYPE = "/subscription_type",
  PAYMENTS = "/payments",
  PASSWORD_RECOVER = "/pass_rec",
  ACCOUNTS = "/accounts",
  ACCOUNT_USERS = "/account_users",
  ADDING_USER_TO_ACCOUNT = "/adding_user",
  INVITE_REG_USER = "/invitation",
  INVITE_UNREG_USER = "/unregistered_invitation",
  AIMAP = "/main",
  COMPANIES = "/companies",
  SIKA_COMPANIES = "/sika_designers",
  PERSONS = "/persons",
  USAID_MAP = "/usaid_map",
  DASHBOARD = "/dashboard",
  DOWNLOAD = "/download",
  DOWNLOAD_POWER_BI = "/downloadpowerbi",
  PROFILE = "/profile",
  RECENT_DOWNLOADS = "/recent_downloads",
  FEEDBACK_PAGE = "/feedback",
}

export const publicRoutes: IRoute[] = [
  {
    path: RouteNames.FEEDBACK_PAGE,
    exact: true,
    component: FeedbackPage,
  },
  {
    path: RouteNames.LOGIN,
    exact: true,
    component: Authorisation,
  },
  {
    path: RouteNames.REGISTRATION,
    exact: true,
    component: RegistrationMain,
  },
  {
    path: RouteNames.PASSWORD_RECOVER,
    exact: true,
    component: PasswordRecovery,
  },
  {
    path: RouteNames.ERROR_PAGE,
    component: ErrorPage404,
  },
  {
    path: RouteNames.GOOGLE_AUTH,
    exact: true,
    component: GoogleAuth,
  },
  {
    path: RouteNames.INVITE_REG_USER,
    exact: true,
    component: RegisteredUser,
  },
  {
    path: RouteNames.INVITE_UNREG_USER,
    exact: true,
    component: UnregisteredUser,
  },

  {
    path: RouteNames.AIMAP,
    exact: true,
    component: MainV2,
  },
];

export const privateRoutes: IRoute[] = [
  {
    path: RouteNames.FEEDBACK_PAGE,
    exact: true,
    component: FeedbackPage,
  },
  {
    path: RouteNames.ACCOUNTS,
    exact: true,
    component: Accounts,
  },
  {
    path: RouteNames.AIMAP,
    exact: true,
    component: MainV2,
  },
  {
    path: RouteNames.DASHBOARD,
    exact: true,
    component: Dashboard,
  },
  {
    path: RouteNames.DOWNLOAD_POWER_BI,
    exact: true,
    component: DownloadPowerBI,
  },
  {
    path: RouteNames.ACCOUNT_USERS,
    exact: true,
    component: AccountUsers,
  },
  {
    path: RouteNames.ADDING_USER_TO_ACCOUNT,
    exact: true,
    component: AddingUserToAccount,
  },
  {
    path: RouteNames.PAYMENTS,
    exact: true,
    component: Payments,
  },
  {
    path: RouteNames.INVITE_REG_USER,
    exact: true,
    component: RegisteredUser,
  },
  {
    path: RouteNames.SUBSCRIPTIONS,
    exact: true,
    component: Subscriptions,
  },
  {
    path: RouteNames.SUBSCRIPTION_TYPE,
    exact: true,
    component: SubscriptionTypePage,
  },
  {
    path: RouteNames.PROFILE,
    exact: true,
    component: Profile,
  },
  {
    path: RouteNames.RECENT_DOWNLOADS,
    exact: true,
    component: RecentDownloads,
  },
];
