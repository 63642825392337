import styled from "@emotion/styled";
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TelegramIcon,
  ViberShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  ViberIcon,
  WhatsappIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FC, useState } from "react";
import { breakpoints, colors } from "../../constants";
import { useTranslation } from "react-i18next";
import { Text16 } from "../textComponents/textComponents";

const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 16px;
  height: 100%;
  width: 100%;
  flex: 1;
  flex-wrap: wrap;
  padding: 16px 4px;
  @media screen and (max-width: ${breakpoints.small}px) {
    padding: 0;
  }
`;

const StyledIcons = styled.div`
  display: flex;
  gap: 8px;
`;
const StyledItem = styled.li`
  cursor: pointer;
  display: block;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledButtonInnerWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;
const StyledDescriptionText = styled.p`
  font-size: 13px;
  line-height: 16px;
  padding-bottom: 4px;
`;
const StyledCopyText = styled.p`
  font-size: 16px;
  line-height: 26px;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  white-space: nowrap;
  max-width: 280px;
`;

interface Props {
  currentUrl?: string;
}

const CopyAndShareCurrentPageBlock: FC<Props> = ({
  currentUrl = window.location.href,
}) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2e3);
  };
  const { t: tCopyText } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.objectDetails.details.copyText",
  });

  const { t: tDetails } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.objectDetails.details",
  });

  const StyledTitle = styled(Text16)`
    color: ${colors.darkD200};
    padding-bottom: 0px;
    @media screen and (max-width: ${breakpoints.small}px) {
      padding-bottom: 16px;
    }
  `;

  const iconsSize = "36px";
  return (
    <>
      <StyledTitle>{tDetails("share")}:</StyledTitle>
      <StyledList>
        <StyledIcons>
          <StyledItem>
            <TelegramShareButton url={currentUrl}>
              <StyledButtonInnerWrap>
                <TelegramIcon size={iconsSize} />
              </StyledButtonInnerWrap>
            </TelegramShareButton>
          </StyledItem>
          <StyledItem>
            <FacebookShareButton url={currentUrl}>
              <StyledButtonInnerWrap>
                <FacebookIcon size={iconsSize} />
              </StyledButtonInnerWrap>
            </FacebookShareButton>
          </StyledItem>
          <StyledItem>
            <ViberShareButton url={currentUrl}>
              <StyledButtonInnerWrap>
                <ViberIcon size={iconsSize} />
              </StyledButtonInnerWrap>
            </ViberShareButton>
          </StyledItem>
          <StyledItem>
            <WhatsappShareButton url={currentUrl}>
              <StyledButtonInnerWrap>
                <WhatsappIcon size={iconsSize} />
              </StyledButtonInnerWrap>
            </WhatsappShareButton>
          </StyledItem>
          <StyledItem>
            <EmailShareButton url={currentUrl}>
              <StyledButtonInnerWrap>
                <EmailIcon size={iconsSize} />
              </StyledButtonInnerWrap>
            </EmailShareButton>
          </StyledItem>
        </StyledIcons>

        <StyledItem>
          <CopyToClipboard onCopy={handleCopy} text={currentUrl}>
            <div>
              <StyledDescriptionText>
                {isCopied ? tCopyText("done") : tCopyText("click")}
              </StyledDescriptionText>
              <StyledCopyText>{currentUrl}</StyledCopyText>
            </div>
          </CopyToClipboard>
        </StyledItem>
      </StyledList>
    </>
  );
};

export default CopyAndShareCurrentPageBlock;
