import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { breakpoints } from "../../../constants";
import { RouteNames } from "../../../routes";
import NotificationPopup from "../NotificationPopup";

type Props = {
  id: string;
  message?: string;
  bottomPx?: number;
  rightPx?: number;
  cornerTopPosition?: number;
};
const StyledContainer = styled.div<Partial<Props>>`
  position: absolute;
  right: ${(props) => props.rightPx}px;
  bottom: ${(props) => props.bottomPx}px;
  width: 400px;
  @media (max-width: ${breakpoints.medium}px) {
    width: 100%;
    right: -350px;
  }
`;

const LoginNotificationPopup = ({
  id,
  bottomPx = 0,
  rightPx = -416,
  cornerTopPosition = 100,
  message,
}: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "notifications.login",
  });
  message = message || t("message");

  const navigate = useNavigate();
  const handleUpdateAccount = () => {
    navigate(RouteNames.LOGIN);
  };

  return (
    <StyledContainer bottomPx={bottomPx} rightPx={rightPx} tabIndex={0}>
      <NotificationPopup
        id={id}
        title={t("title")}
        message={message}
        buttonText={t("buttonText")}
        onButtonClick={handleUpdateAccount}
        cornerTopPosition={cornerTopPosition}
        tagText={t("tagText")}
      />
    </StyledContainer>
  );
};

export default LoginNotificationPopup;
