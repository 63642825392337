import { Link, useLocation } from "react-router-dom";
import { Breadcrumb } from "antd";
import { RouteNames } from "../../../routes";
import AngleRight from "../../../assets/svg/AngleRight.svg";
import { useTranslation } from "react-i18next";

const BreadcrumbNav = () => {
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter((x) => x);

  const { t } = useTranslation("translation", {
    keyPrefix: "config.breadcrumbs",
  });

  return (
    <Breadcrumb separator={<img src={AngleRight} alt=">" />}>
      <Breadcrumb.Item>
        <Link className="text-color-black-3" to={RouteNames.AIMAP}>
          {t("main")}
        </Link>
      </Breadcrumb.Item>

      {pathNames.map((pathName, index, arr) => {
        const isLast = index === arr.length - 1;
        const url = `/${pathNames.slice(0, index + 1).join("/")}`;
        return (
          <Breadcrumb.Item key={url}>
            <Link
              className={isLast ? "text-color-black-2" : "text-color-black-3"}
              to={url}
            >
              {t(pathName)}
            </Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default BreadcrumbNav;
