import React from "react";
import { Marker, MarkerProps } from "react-map-gl";
import Mark from "../../../../assets/svg/Marker.svg";
import { ICurrentMarker } from "../../../../models/CurrentMarker";
import useMobileScreen from "../../../../hooks/useMobileScreen";

interface Props {
  marker: ICurrentMarker;
  onClick?: MarkerProps["onClick"];
}

const CustomMarker: React.FC<Props> = ({ marker, onClick }) => {
  const isMobile = useMobileScreen();

  if (!marker) return null;
  const { lon: longitude, lat: latitude } = marker;
  if (typeof longitude !== "number" || typeof latitude !== "number")
    return null;
  const offset: mapboxgl.PointLike = isMobile ? [-0, -36] : [0, -30];
  return (
    <Marker
      offset={offset}
      longitude={longitude}
      latitude={latitude}
      onClick={onClick}
    >
      <img src={Mark} alt="Mark" />
    </Marker>
  );
};

export default CustomMarker;
