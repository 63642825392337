import React from "react";
import Heading from "../Heading/Heading";
import Subheading from "../Subheading/Subheading";
import styled from "@emotion/styled";
import "../../index.scss";
import { breakpoints } from "../../constants";
interface IFormSectionProps {
  headerText: string | null;
  subheaderText?: string | null;
}

const StyledFormHeaderWrapper = styled.div`
  display: grid;
  gap: 4px;
  margin-bottom: 24px;

  @media screen and (max-width: ${breakpoints.small + "px"}) {
    display: none;
  }
`;

const StyledFormSection = styled.div`
  display: grid;
  margin-bottom: 24px;
`;

export const FormSection: React.FC<IFormSectionProps> = ({
  headerText,
  subheaderText,
  children,
}) => {
  return (
    <StyledFormSection>
      <StyledFormHeaderWrapper>
        <Heading type={"h3"}>{headerText}</Heading>
        {subheaderText && <Subheading type={"h3"}>{subheaderText}</Subheading>}
      </StyledFormHeaderWrapper>
      {children}
    </StyledFormSection>
  );
};

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  max-width: 1004px;
  border: 1px solid var(--fifth-black-color);
  border-radius: 8px;

  @media screen and (max-width: ${breakpoints.small + "px"}) {
    padding: 0;
    border: none;
    border-radius: 0;
  }
`;
