import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import SikaDesignersMap from "../../../components/_maps/sika/SikaDesignersMap/SikaDesignersMap";
import { Navigate } from "react-router-dom";
import { RouteNames } from "../../../routes";
import MapPage from "../../../share/MapPage/MapPage";
import Filters from "../../../components/_maps/sika/SikaDesignerFilters/Filters";
import SikaDesignersSearchResults from "../../../components/_maps/sika/SikaDesignersSearchResults/SikaDesignersSearchResults";
import DownloadResults from "../../../components/_maps/main/DownloadResults/DownloadResults";
import SikaDesignerDetails from "../../../components/_maps/sika/SikaDesignerDetails/SikaDesignerDetails";

const SikaDesigners = () => {
  const { sikaMapRef } = useTypedSelector((state) => state.mapObjectsInfo);
  const { setSikaMapRef } = useActions();
  const count = useTypedSelector((state) => state.mapSikaDesignerInfo.count);

  const sika_designers = useTypedSelector(
    (state) => state.currentAccount.subscribe?.permissions?.sika_designers
  );

  const isAllowedDataset = sika_designers && sika_designers[0] === "1";
  if (!isAllowedDataset) {
    return <Navigate to={RouteNames.LOGIN} />;
  }

  return (
    <MapPage
      filter={<Filters />}
      SearchResults={SikaDesignersSearchResults}
      downloadResults={<DownloadResults count={count} disabled />}
      objectDetails={<SikaDesignerDetails />}
      map={<SikaDesignersMap />}
      mapRef={sikaMapRef}
      setMapRef={setSikaMapRef}
    ></MapPage>
  );
};

export default SikaDesigners;
