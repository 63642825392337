import styled from "@emotion/styled";
import { breakpoints } from "../../constants";
import { useTranslation } from "react-i18next";

export const CheckboxList = styled.div<{ inRow?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.inRow ? "row" : "column")};
  flex-wrap: ${(props) => (props.inRow ? "wrap" : "initial")};
`;

export const TypeFilterItemsList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 0px;
`;

export const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 23px;
  border-bottom: 1px solid var(--fifth-black-color);
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: red;
    z-index: 1;
  }
`;

export const FilterSections = styled.div`
  display: grid;
  row-gap: 24px;
  @media screen and (max-width: ${breakpoints.small}px) {
    row-gap: 24px;
  }
`;

export const FilterSectionSeparatorLine = styled.div`
  border-bottom: 1px solid #e6e6e6;
`;

export const FilterGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 512px;
  height: 48px;
  margin-bottom: 24px;
  background: var(--primary-white-color);
  @media (max-width: ${breakpoints.medium}px) {
    margin-bottom: 0;
    gap: 12px;
    width: auto;
    height: auto;
    padding: 0 16px;
    justify-content: start;
    flex-wrap: wrap;
  }
  @media (max-width: ${breakpoints.small}px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-top: 8px;
  }
`;

export const SearchWrapper = styled.div`
  width: 512px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
  gap: 8px;
  @media (max-width: ${breakpoints.medium}px) {
    width: 100%;
    margin-bottom: 8px;
    padding: 0 16px;
  }
`;

export const StyledFilterBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

//FilterBody component
export const FilterBody: React.FC<{ count?: number }> = ({
  count = 1,
  children,
}) => {
  const tMapFilters = useTranslation("translation", {
    keyPrefix: "mapUI.filters",
  }).t;

  return (
    <StyledFilterBody>
      {count > 0 ? (
        children
      ) : (
        <div>{tMapFilters("noFilterOptions", "no filter options")}</div>
      )}
    </StyledFilterBody>
  );
};
