import { FC } from "react";
import styled from "@emotion/styled";
import useIsFilterActive from "../../hooks/useIsFilterActive";
import { useActions } from "../../hooks/useActions";
import { FilterIdsEnum } from "../../store/reducers/_maps/main/mapFilters/types";
import Modal from "./Modal";
const StyledContainer = styled.div`
  position: relative;
`;

type Props = {
  triggerReactNode: React.ReactNode;
  children: React.ReactNode;
  modalId: FilterIdsEnum;
  modalWidth?: number;
  onClick?: () => void;
};

const ButtonModal: FC<Props> = ({
  triggerReactNode,
  modalId,
  children,
  modalWidth,
  onClick,
}) => {
  const isListShown = useIsFilterActive(modalId);
  const { toggleFilter } = useActions();

  const onClose = () => {
    toggleFilter("");
  };

  const handleToggle = () => {
    onClick && onClick();

    toggleFilter(modalId);
  };

  return (
    <StyledContainer>
      <div onClick={handleToggle}>{triggerReactNode}</div>
      <Modal isShown={isListShown} onClose={onClose} modalWidth={modalWidth}>
        {children}
      </Modal>
    </StyledContainer>
  );
};

export default ButtonModal;
