import { uniq } from "lodash";
import { MapFilterActionsEnum, MapFiltersAction, IMapFilters } from "./types";
import { v4 as uuidv4 } from "uuid";

const initialState: IMapFilters = {
  isSuggestionsLoading: false,
  typeFilterArray: [],
  ccFilterArray: [],
  stageDocumentation: [],
  selectedDocList: [{ id: uuidv4(), value: "1" }],
  constructionType: [],
  stageBuilding: [],
  from_date: "",
  to_date: "",
  suggestions: [],
  activeSuggestions: [],
  region: [],
  edrpou_code: [],
  np_id: [],
  keyword: [],
  dkbs_code: [],
  activeFilterId: "",
  squareFilter: [],
};

export default function mapFiltersReducer(
  state = initialState,
  action: MapFiltersAction
) {
  switch (action.type) {
    case MapFilterActionsEnum.SET_SUGGESTION_LOADING:
      return { ...state, isSuggestionsLoading: action.payload };

    case MapFilterActionsEnum.TOGGLE_FILTER_ACTION:
      return {
        ...state,
        activeFilterId:
          state.activeFilterId === action.payload ? "" : action.payload,
      };

    case MapFilterActionsEnum.SELECT_TYPE_FILTER:
      if (state.typeFilterArray.includes(action.payload)) {
        return {
          ...state,
          typeFilterArray: state.typeFilterArray.filter(
            (item) => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          typeFilterArray: [...state.typeFilterArray, action.payload],
        };
      }

    case MapFilterActionsEnum.CLEAN_TYPE_FILTER:
      return { ...state, typeFilterArray: [] };

    case MapFilterActionsEnum.SELECT_CC_FILTER:
      if (state.ccFilterArray.includes(action.payload)) {
        return {
          ...state,
          ccFilterArray: state.ccFilterArray.filter(
            (item) => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          ccFilterArray: [...state.ccFilterArray, action.payload],
        };
      }

    case MapFilterActionsEnum.CLEAN_CC_FILTER:
      return { ...state, ccFilterArray: [] };

    case MapFilterActionsEnum.SET_STAGE_FILTER:
      return { ...state, stageDocumentation: [...action.payload] };

    case MapFilterActionsEnum.DELETE_STAGE_FILTER:
      return {
        ...state,
        stageDocumentation: state.stageDocumentation.filter(
          (item) => item !== (action.payload as any)
        ),
      };

    case MapFilterActionsEnum.SET_DOC_LIST:
      return { ...state, selectedDocList: [...action.payload] };

    case MapFilterActionsEnum.CLEAN_STAGE_FILTER:
      return { ...state, stageDocumentation: [] };

    case MapFilterActionsEnum.TOGGLE_CONSTRUCTION_FILTER:
      if (state.constructionType.includes(action.payload)) {
        return {
          ...state,
          constructionType: state.constructionType.filter(
            (item) => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          constructionType: [...state.constructionType, action.payload],
        };
      }

    case MapFilterActionsEnum.CLEAN_CONSTRUCTION_FILTER:
      return { ...state, constructionType: [] };

    case MapFilterActionsEnum.TOGGLE_BUILD_FILTER:
      if (state.stageBuilding.includes(action.payload)) {
        return {
          ...state,
          stageBuilding: state.stageBuilding.filter(
            (item) => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          stageBuilding: [...state.stageBuilding, action.payload],
        };
      }

    case MapFilterActionsEnum.CLEAN_BUILD_FILTER:
      return { ...state, stageBuilding: [] };

    case MapFilterActionsEnum.SET_FROM_DATE:
      return { ...state, from_date: action.payload };

    case MapFilterActionsEnum.SET_TO_DATE:
      return { ...state, to_date: action.payload };

    case MapFilterActionsEnum.SET_SUGGESTION:
      return { ...state, suggestions: [...action.payload] };

    case MapFilterActionsEnum.SET_ACTIVE_SUGGESTION:
      const suggestions = [...state.activeSuggestions, action.payload];
      const sudCodes = suggestions.map((item) => item.code);
      const uniqueSudCodes = uniq(sudCodes);
      const uniqSuggestions = uniqueSudCodes.map((item) =>
        suggestions.find((sud) => sud.code === item)
      );

      return {
        ...state,
        activeSuggestions: uniqSuggestions,
      };

    case MapFilterActionsEnum.SET_SQUARE_FILTER:
      return { ...state, squareFilter: action.payload };
    case MapFilterActionsEnum.CLEAN_SUGGESTION:
      return { ...state, activeSuggestions: [] };

    case MapFilterActionsEnum.DELETE_ACTIVE_SUGGESTION:
      return {
        ...state,
        activeSuggestions: state.activeSuggestions.filter(
          (item) => item !== action.payload
        ),
      };

    case MapFilterActionsEnum.SET_REGION:
      return { ...state, region: [...state.region, action.payload] };

    case MapFilterActionsEnum.CLEAN_REGION:
      return { ...state, region: [] };

    case MapFilterActionsEnum.DELETE_REGION:
      return {
        ...state,
        region: state.region.filter((item) => item !== action.payload),
      };

    case MapFilterActionsEnum.SET_KEYWORD:
      return { ...state, keyword: uniq([...state.keyword, action.payload]) };

    case MapFilterActionsEnum.CLEAN_KEYWORD:
      return { ...state, keyword: [] };

    case MapFilterActionsEnum.DELETE_KEYWORD:
      return {
        ...state,
        keyword: state.keyword.filter((item) => item !== action.payload),
      };

    case MapFilterActionsEnum.SET_EDR_CODE:
      return { ...state, edrpou_code: [...state.edrpou_code, action.payload] };

    case MapFilterActionsEnum.CLEAN_EDR_CODE:
      return { ...state, edrpou_code: [] };

    case MapFilterActionsEnum.DELETE_EDR_CODE:
      return {
        ...state,
        edrpou_code: state.edrpou_code.filter(
          (item) => item !== action.payload
        ),
      };

    case MapFilterActionsEnum.SET_NP_ID:
      return { ...state, np_id: [...state.np_id, action.payload] };

    case MapFilterActionsEnum.CLEAN_NP_ID:
      return { ...state, np_id: [] };

    case MapFilterActionsEnum.CLEAN_DKBS_CODE:
      return { ...state, dkbs_code: [] };

    case MapFilterActionsEnum.SET_DKBS_CODE:
      return { ...state, dkbs_code: [...state.dkbs_code, action.payload] };
    case MapFilterActionsEnum.DELETE_DKBS_CODE:
      return {
        ...state,
        dkbs_code: state.dkbs_code.filter((item) => item !== action.payload),
      };

    case MapFilterActionsEnum.DELETE_NP_ID:
      return {
        ...state,
        np_id: state.np_id.filter((item) => item !== action.payload),
      };

    default:
      return state;
  }
}
