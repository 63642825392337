import { useMemo, useState } from "react";
import useMobileScreen from "./useMobileScreen";
import useSearchParams from "./useSearchParams";
import { layerMobileProps, layerProps } from "../constants/mapConstants";
import { useTypedSelector } from "./useTypedSelector";

const useMapObjects = () => {
  const { viewport } = useTypedSelector((state) => state.mapInterface);
  const [currObjs, setCurrObjs] = useState<GeoJSON.Feature[] | []>([]);
  const [pinnedCurrObjs, setPinnedCurrObjs] = useState<GeoJSON.Feature[] | []>(
    []
  );
  const isMobile = useMobileScreen();
  const { setSearchParam } = useSearchParams();

  const markerHoverHandler = (features) => {
    if (!isMobile) {
      setCurrObjs(features);
    }
  };

  const markerClickHandler = (
    features: GeoJSON.Feature[] | [],
    hashIdKey = "hash_id"
  ) => {
    if (!isMobile) {
      if (currObjs.length === 1) {
        setSearchParam("hash_id", currObjs[0].properties[hashIdKey]);
      }
    } else {
      if (features?.length) {
        if (features.length === 1) {
          setSearchParam("hash_id", features[0].properties[hashIdKey]);
        } else {
          setPinnedCurrObjs(features);
        }
      } else {
        setPinnedCurrObjs([]);
      }
    }
  };

  const selectedLayerProps = useMemo(() => {
    if (isMobile) {
      return layerMobileProps;
    } else {
      if (viewport.zoom > 12) {
        return layerMobileProps;
      } else {
        return layerProps;
      }
    }
  }, [isMobile, viewport.zoom]);

  return {
    currObjs,
    setCurrObjs,
    pinnedCurrObjs,
    setPinnedCurrObjs,
    markerClickHandler,
    markerHoverHandler,
    selectedLayerProps,
  };
};

export default useMapObjects;
