import { Form, FormInstance, notification } from "antd";
import {
  Dispatch,
  JSXElementConstructor,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { IFetchable } from "../../../models/IFetchable";
import { IUser } from "../../../models/Accounts";
import useSelectedLanguage from "../../../hooks/useSelectedLanguage";
import { AvailableCC } from "../../../constants/constants";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";

export enum CurrentScreen {
  main = "main",
  basic = "basic",
  contact = "contact",
  language = "language",
}

interface IUseMobileProfile {
  currentScreen: CurrentScreen;
  switchScreen: (arg: CurrentScreen) => void;
  isMainScreen: boolean;
  handlePreviousButtonClick: () => void;
  form: FormInstance<any>;
  updateUser: any;
  contextHolder: ReactElement<any, string | JSXElementConstructor<any>>;
  handleSubmit: (formValues: Partial<IUser>) => Promise<void>;
  hasFormChanged: boolean;
  setHasFormChanged: Dispatch<SetStateAction<boolean>>;
  formInitialValues: Partial<IUser>;
}

const useMobileProfile = (): IUseMobileProfile => {
  const [currentScreen, setCurrentScreen] = useState<CurrentScreen>(
    CurrentScreen.main
  );

  const isMainScreen = currentScreen === CurrentScreen.main;

  const switchScreen = (screen: CurrentScreen) => {
    setCurrentScreen(screen);
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handlePreviousButtonClick = () => {
    currentScreen === CurrentScreen.main
      ? handleGoBack()
      : switchScreen(CurrentScreen.main);
  };

  const [form] = Form.useForm();
  const { updateUser } = useActions();
  const [notificationApi, contextHolder] = notification.useNotification();

  const currentUser: Partial<IUser & IFetchable> = useTypedSelector(
    (store) => store.currentUser
  );

  const lang = useSelectedLanguage();

  const formInitialValues: Partial<IUser> = {
    full_name: currentUser.full_name,
    position: currentUser.position,
    company_name: currentUser.company_name,
    email: currentUser.email,
    phone_number: currentUser.phone_number,
    language: lang,
  };

  const [hasFormChanged, setHasFormChanged] = useState<boolean>(false);

  useEffect(() => {
    form.setFieldsValue({
      full_name: currentUser.full_name,
      position: currentUser.position,
      company_name: currentUser.company_name,
      email: currentUser.email,
      phone_number: currentUser.phone_number,
      language: lang,
    });
  }, [currentUser]);

  const handleChangeLanguage = (language: AvailableCC) => {
    i18n.changeLanguage(language);
  };

  const { t: tNotification } = useTranslation("translation", {
    keyPrefix: "profilePage.notification",
  });

  const handleSubmit = async (formValues: Partial<IUser>) => {
    handleChangeLanguage(formValues.language as AvailableCC);
    updateUser({ ...formValues });
    notificationApi.success({
      message: tNotification("profileUpdateMessage"),
      description: tNotification("profileUpdateDescription"),
      placement: "bottomLeft",
      duration: 3,
    });
  };

  return {
    currentScreen,
    switchScreen,
    isMainScreen,
    handlePreviousButtonClick,
    form,
    updateUser,
    contextHolder,
    handleSubmit,
    hasFormChanged,
    setHasFormChanged,
    formInitialValues,
  };
};

export default useMobileProfile;
