import styled from "@emotion/styled";

const StyledDisabledDiv = styled.div<{ isDisabled: boolean }>`
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "all")};
  /* opacity: ${({ isDisabled }) => (isDisabled ? "0.5" : "1")}; */
`;

const DisabledDiv = ({ onClick, isDisabled, children }) => {
  return (
    <div onClick={onClick}>
      <StyledDisabledDiv isDisabled={isDisabled}>{children}</StyledDisabledDiv>
    </div>
  );
};

export default DisabledDiv;
