import { httpClient, HttpClient } from "./HTTPService";
import { IReqPersons } from "../../models/PersonObejct";
import { store } from "../index";
import { ISearchRequest, ISearchResponse } from "../../models/Search";
import { ICurrentPerson } from "../../models/CurrentPerson";
import qs from "qs";
import { IMapPersonsInfoState } from "../reducers/_maps/persons/person/types";

const token = store.getState().auth.token.access;

class PersonService {
  constructor(private httpClient: HttpClient) {}

  public async fetchMapPersons(data: IReqPersons) {
    return httpClient.get<IMapPersonsInfoState>(
      `persons/` + data.account_id + "/",
      {
        params: data,
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false });
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  }

  public async fetchMapPerson(RECORD: string, account_id: number) {
    if (RECORD) {
      return httpClient.get<ICurrentPerson>(
        `persons/` + account_id + "/" + RECORD + "/",
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } else return undefined;
  }

  public async fetchSuggestions(data: ISearchRequest) {
    return httpClient.post<ISearchResponse[]>(
      `persons/` + data["account_id"] + `/suggest/`,
      data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }
}

export const personService = new PersonService(httpClient);
