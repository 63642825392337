import { FC } from "react";
import { CheckboxList, FilterBody } from "../../../../../share/filters";
import { IConsequenceClass } from "../../../../../models/MapObject";
import { CustomCheckbox } from "../../../../../share/Input/Input";
import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import util from "../../../../../util";
import { useTranslation } from "react-i18next";
import { ccFilterTranslationMap } from "../../../../../constants/filterTranslationMapping";
import useSearchParams from "../../../../../hooks/useSearchParams";
import { AvailableCC } from "../../../../../constants/constants";

interface IItem {
  name: string;
  value: string;
}

const CCFilterBody: FC = () => {
  const { ccFilterArray } = useTypedSelector((state) => state.mapFilters);
  const { toggleCCFilterObject, applyFilter } = useActions();
  const handleChange = (type: IItem) => {
    toggleCCFilterObject(type.value);
  };
  const { consequence_class } = useTypedSelector(
    (state) => state.mapObjectsInfo.mapObjects.params
  );

  const items = consequence_class
    ?.filter((a) => !!a.name)
    ?.sort((a, b) => util.toSortedAlphabeticCallback(a.name, b.name));

  const { t: tOption } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.aimap.consequencesFilter.values",
  });

  const isUaRegion =
    useSearchParams().getSearchParams().region === AvailableCC.ua;

  return (
    <FilterBody count={items?.length}>
      <CheckboxList>
        {items
          ?.sort((a, b) => a.order - b.order)
          .map((type: IConsequenceClass) => {
            const option = isUaRegion
              ? ccFilterTranslationMap[type.name]
                ? tOption(ccFilterTranslationMap[type.name], {
                    defaultValue: type.name,
                  })
                : type.name
              : type.name;
            return (
              <CustomCheckbox
                value={type}
                key={type.name}
                checked={ccFilterArray.includes(type.value)}
                onChange={() => handleChange(type)}
                onClick={applyFilter}
              >
                {option}
              </CustomCheckbox>
            );
          })}
      </CheckboxList>
    </FilterBody>
  );
};

export default CCFilterBody;
