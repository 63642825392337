import CompaniesMap from "../../../components/_maps/company/CompaniesMap/CompaniesMap";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import MapPage from "../../../share/MapPage/MapPage";
import Filters from "../../../components/_maps/company/CompanyFilters/Filters";
import DownloadResults from "../../../components/_maps/main/DownloadResults/DownloadResults";
import CompanyDetails from "../../../components/_maps/company/CompanyDetails/CompanyDetails";
import CompanySearchResults from "../../../components/_maps/company/CompanySearchResults/CompanySearchResults";

const Companies = () => {
  const { companyMapRef } = useTypedSelector((state) => state.mapObjectsInfo);
  const { setCompanyMapRef } = useActions();
  const count = useTypedSelector((state) => state.mapCompanyInfo.count);

  return (
    <MapPage
      filter={<Filters />}
      SearchResults={CompanySearchResults}
      downloadResults={<DownloadResults count={count} disabled />}
      objectDetails={<CompanyDetails />}
      map={<CompaniesMap />}
      mapRef={companyMapRef}
      setMapRef={setCompanyMapRef}
    ></MapPage>
  );
};

export default Companies;
