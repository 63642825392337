import React from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styles from "../Registration.module.scss";
import { CustomInput } from "../../../../share/Input/Input";
import { useActions } from "../../../../hooks/useActions";
import EditPencil from "../../../../assets/svg/EditPencil.svg";
import { Button, Form } from "antd";
import ArrowRight from "../../../../assets/svg/arrowRight.svg";
import { IFormInputsFirst } from "../RegistrationFirstStep/RegistrationForm";
import { ButtonLoader } from "../../../../share/Loader/Loader";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import RepeatButton from "../../../../components/_auth/RepeatButton/RepeatButton";
import Info from "../../../../assets/svg/infoCircle.svg";
import { useTranslation } from "react-i18next";

export interface IFormInputsSecond {
  activation_code: string;
}

interface IProps {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  user?: IFormInputsFirst;
}

const RegistrationSecondStep: React.FC<IProps> = ({ setPage, user }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "registrationPage.RegistrationSecondStep",
  });

  const schema = yup
    .object<Record<keyof IFormInputsSecond, yup.AnySchema>>()
    .shape({
      activation_code: yup
        .string()
        .required(t("activationCode.required"))
        .min(4, t("activationCode.min"))
        .max(4),
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const { error, isLoading } = useTypedSelector((state) => state.auth);
  const { registrationSecondStep } = useActions();

  const editButton = () => {
    setPage((prevValue: number) => prevValue - 1);
  };

  const onSubmit: SubmitHandler<IFormInputsSecond> = async (
    data: IFormInputsSecond
  ) => {
    const result = await registrationSecondStep(
      data.activation_code,
      user?.email
    );
    if (!!result) {
      setPage((prevValue: number) => prevValue + 1);
    }
  };

  return (
    <>
      <div className={styles.infoContainer}>
        <img src={Info} alt="info" />
        <p className={styles.infoContainerText}>{t("warning")}</p>
      </div>
      {user?.email ? (
        <div className={styles.editButtonWrapper}>
          <div className={styles.editButtonContainer}>
            {user.email}
            <span className={styles.editValidationText}>
              {t("emailNotVerified")}
            </span>
          </div>
          <button
            type="button"
            onClick={editButton}
            className={styles.editButton}
          >
            <img src={EditPencil} alt="edit" width="20px" height="20px" />
          </button>
        </div>
      ) : (
        <div className={styles.editButtonWrapper}>
          <button
            type="button"
            onClick={editButton}
            className={styles.editButton}
          >
            <img src={EditPencil} alt="edit" width="20px" height="20px" />
          </button>
        </div>
      )}
      <div className={styles.formsWrapperDF}>
        <Form
          onSubmitCapture={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
        >
          <Form.Item className={styles.secondFormWrapper}>
            <Form.Item
              label={t("activationCode.label")}
              className={styles.label}
              htmlFor="activation_code"
            >
              <div className={styles.secondFormInnerWrapper}>
                <div className={styles.secondFormInputWrapper}>
                  <CustomInput
                    name="activation_code"
                    type="text"
                    inputClassName={`${styles.input} ${styles.inputWidth}`}
                    register={register("activation_code")}
                    isError={
                      !!errors.activation_code &&
                      !!errors.activation_code?.message
                    }
                    errorText={errors.activation_code?.message}
                    inputProps={{ maxLength: 4 }}
                  />
                </div>
                <RepeatButton user={user} />
              </div>
            </Form.Item>
            {error && <span className={styles.errorMsg}>{error}</span>}
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.formSubmitButton}
              htmlType="submit"
              disabled={isLoading}
            >
              {t("submitBtn")}
              {isLoading ? (
                <ButtonLoader />
              ) : (
                <img src={ArrowRight} alt="next" />
              )}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default RegistrationSecondStep;
