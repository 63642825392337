import React, { FC, useState } from "react";
import DatePicker from "../../DatePicker/DatePicker";
import ShowMoreButton from "../../../../../share/ShowMoreButton/ShowMoreButton";
import { FilterBody } from "../../../../../share/filters";

interface IDateFilterBody {
  hasShowMore?: boolean;
}

const DateFilterBody: FC<IDateFilterBody> = ({ hasShowMore = false }) => {
  const [isTemplateListShown, setIsTemplateListShown] = useState<boolean>(
    !hasShowMore
  );
  const toggleOpenTemplates = () => {
    setIsTemplateListShown((state) => !state);
  };

  return (
    <FilterBody>
      <DatePicker showTemplates={isTemplateListShown} />
      {hasShowMore && (
        <ShowMoreButton
          onClick={toggleOpenTemplates}
          opened={isTemplateListShown}
          disabled={false}
        >
          Шаблони періодів
        </ShowMoreButton>
      )}
    </FilterBody>
  );
};

export default DateFilterBody;
