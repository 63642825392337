import { FC, useState } from "react";
import { FilterBody, TypeFilterItemsList } from "../../../../../share/filters";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import TypeFilterItem from "../../../../../share/filters/TypeFilterItem";
import util from "../../../../../util";
import { useActions } from "../../../../../hooks/useActions";
import ShowMoreButton from "../../../../../share/ShowMoreButton/ShowMoreButton";

interface IFilterTypesBody {
  hasShowMore?: boolean;
}

const FilterTypesBody: FC<IFilterTypesBody> = ({ hasShowMore = false }) => {
  const { typeFilterArray: includedFilterOptions } = useTypedSelector(
    (state) => state.mapFilters
  );

  const { aimap_classifier: filterItems } = useTypedSelector(
    (state) => state.mapObjectsInfo.mapObjects.params
  );

  const { selectTypeFilterOption, applyFilter } = useActions();

  const handleOptionClick = (option: string) => {
    selectTypeFilterOption(option);
    applyFilter();
  };

  const [typesFilterLimit, setTypesFilterLimit] = useState<number>(
    hasShowMore ? 4 : filterItems.length
  );

  const handleChangeTypesFilterLimit = () => {
    if (typesFilterLimit === 4) setTypesFilterLimit(filterItems.length);
    if (typesFilterLimit !== 4) setTypesFilterLimit(4);
  };

  return (
    <FilterBody count={filterItems?.length || 0}>
      <TypeFilterItemsList>
        {filterItems
          ?.slice(0, typesFilterLimit)
          ?.sort(util.toSortedAlphabeticCallback)
          .map((option: string) => (
            <TypeFilterItem
              key={option}
              option={option}
              isActive={includedFilterOptions.includes(option)}
              onClick={() => handleOptionClick(option)}
            />
          ))}
      </TypeFilterItemsList>
      {hasShowMore && filterItems?.length > 4 && (
        <ShowMoreButton
          onClick={handleChangeTypesFilterLimit}
          opened={typesFilterLimit > 4}
          disabled={filterItems?.length < 4}
        >
          Усі типи об'єкту
        </ShowMoreButton>
      )}
    </FilterBody>
  );
};

export default FilterTypesBody;
