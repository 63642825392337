import { useNavigate, useLocation } from "react-router-dom";
import { datasets, regions } from "../constants/mapConstants";
import { IDatasetType } from "../models/map.interface";

const useSearchParams = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const removeQueryParam = (name: string) => {
    const searchParams = new URLSearchParams(search);
    searchParams.delete(name);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const setSearchParam = (key: string, value: string | number) => {
    const searchParams = new URLSearchParams(search);
    searchParams.set(key, value.toString());
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const getSearchParam = (paramKey: string) => {
    if (!paramKey) {
      return null;
    }
    const searchParams = new URLSearchParams(search);
    return searchParams.get(paramKey);
  };

  const getSearchParams = (): { [key: string]: string | null } => {
    const searchParams = new URLSearchParams(search);
    const params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  };

  const getDataset = (): IDatasetType => {
    const searchParams = new URLSearchParams(search);
    return (searchParams.get("dataset") as IDatasetType) || datasets.objects;
  };

  const getRegion = () => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get("region") || regions.ua;
  };

  return {
    setSearchParam,
    getSearchParam,
    getSearchParams,
    removeQueryParam,
    getDataset,
    getRegion,
  };
};

export default useSearchParams;
