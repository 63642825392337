import {
  IMapPersonsInfoState,
  MapActionsEnum,
  SetMapPersonAction,
  SetMapPersonsAction,
  SetObjectsLoading,
} from "./types";
import { ICurrentPerson } from "../../../../../models/CurrentPerson";
import { AppDispatch, RootState, getStoreState, store } from "../../../..";
import { IReqPersons } from "../../../../../models/PersonObejct";
import { personService } from "../../../../api/PersonService";
import { MapInterfaceActionCreator } from "../../mapInterface/action.creators";
import { limit } from "../../../../../constants/mapConstants";
import { ISearchResponse } from "../../../../../models/Search";
import { PersonFilterActionCreators } from "../personFilters/action-creators";

export const PersonsMapObjectActionCreators = {
  setObjectsLoading: (payload: boolean): SetObjectsLoading => ({
    type: MapActionsEnum.SET_IS_PERSON_LOADING,
    payload,
  }),

  setMapPerson: (mapPerson: ICurrentPerson): SetMapPersonAction => ({
    type: MapActionsEnum.SET_MAP_PERSON,
    payload: mapPerson,
  }),

  setMapPersonsInfo: (
    mapPersons: IMapPersonsInfoState
  ): SetMapPersonsAction => ({
    type: MapActionsEnum.SET_MAP_PERSONS,
    payload: mapPersons,
  }),

  getCurrentMapPerson:
    (RECORD: string, account_id: number) => async (dispatch: AppDispatch) => {
      try {
        dispatch(MapInterfaceActionCreator.setIsLoading(true));

        const response = await personService.fetchMapPerson(RECORD, account_id);
        if (response) {
          dispatch(PersonsMapObjectActionCreators.setMapPerson(response.data));
        }
        dispatch(MapInterfaceActionCreator.setIsLoading(false));
      } catch (e: string | any) {
        dispatch(
          MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
        );
      }
    },

  refreshFeedForPersonsSearchResults: () => async (dispatch: AppDispatch) => {
    const { isLoading } = (store.getState() as RootState).mapInterface;

    // if loading return
    const prevMapObjectsInfo: IMapPersonsInfoState =
      getStoreState().mapPersonInfo;
    if (isLoading) return;

    if (prevMapObjectsInfo.next === null) return;

    const page = (getStoreState() as RootState).mapInterface.page;
    dispatch(MapInterfaceActionCreator.setPage(page + 1));

    const data: IReqPersons = {
      account_id: store.getState().currentAccount.id,
      limit: limit,
      offset: page * limit,
      lon: store.getState().mapInterface.viewport.longitude
        ? store.getState().mapInterface.viewport.longitude
        : undefined,
      lat: store.getState().mapInterface.viewport.latitude
        ? store.getState().mapInterface.viewport.latitude
        : undefined,
      status: store.getState().personFilters.stanFilterArray
        ? store.getState().personFilters.stanFilterArray
        : undefined,
      date_added__lte: store.getState().personFilters.date_added__lte
        ? store.getState().personFilters.date_added__lte
        : "",
      date_added__gte: store.getState().personFilters.date_added__gte
        ? store.getState().personFilters.date_added__gte
        : "",

      hash: store.getState().personFilters.hash
        ? store.getState().personFilters.hash
        : undefined,
      region: store.getState().personFilters.region
        ? store.getState().personFilters.region
        : undefined,
      kved: store.getState().personFilters.kved
        ? store.getState().personFilters.kved
        : undefined,
      kved_is_main:
        store.getState().personFilters.kved &&
        store.getState().personFilters.kved_is_main
          ? 1
          : undefined,
      // lon_border__range: "44.386389__52.377778",
      // lat_border__range: "22.138056__40.223611"
    };

    try {
      dispatch(MapInterfaceActionCreator.setIsLoading(true));
      const response = await personService.fetchMapPersons(data);

      if (response) {
        const nextPersons: IMapPersonsInfoState = response.data;
        const resultPersons: IMapPersonsInfoState = {
          ...prevMapObjectsInfo,
          ...nextPersons,
          persons: [...prevMapObjectsInfo.persons, ...nextPersons.persons],
        };

        dispatch(
          PersonsMapObjectActionCreators.setMapPersonsInfo(resultPersons)
        );
      }
      dispatch(MapInterfaceActionCreator.setIsLoading(false));
    } catch (e: string | any) {
      dispatch(
        MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
      );
    }
  },

  addPersonsActiveSuggestion:
    (suggestion: ISearchResponse) => (dispatch: AppDispatch) => {
      // const { suggestions, activeSuggestions } = useTypedSelector(
      //   (state) => state.personFilters
      // );
      const activeSuggestions =
        store.getState().personFilters.activeSuggestions;
      if (activeSuggestions.includes(suggestion)) {
        return;
      }
      if (suggestion.type === "Регіон") {
        // setPersonRegion(suggestion.code);
        dispatch(PersonFilterActionCreators.setPersonRegion(suggestion.code));
      }
      if (suggestion.type === "КВЕД") {
        // setPersonKVED(suggestion.code);
        dispatch(PersonFilterActionCreators.setPersonKVED(suggestion.code));
      }
      if (suggestion.type === "Фізична особа") {
        // setPersonHashCode(suggestion.code);
        dispatch(PersonFilterActionCreators.setPersonHashCode(suggestion.code));
      }
      // setPersonActiveSuggestions(suggestion);
      dispatch(
        PersonFilterActionCreators.setPersonActiveSuggestions(suggestion)
      );
    },

  getDefaultPersonResult: () => async (dispatch: AppDispatch) => {
    const data: IReqPersons = {
      account_id: store.getState().currentAccount.id,
      limit: 20,
      offset: store.getState().mapInterface.offset,
      lon: store.getState().mapInterface.viewport.longitude
        ? store.getState().mapInterface.viewport.longitude
        : undefined,
      lat: store.getState().mapInterface.viewport.latitude
        ? store.getState().mapInterface.viewport.latitude
        : undefined,
      status: store.getState().personFilters.stanFilterArray
        ? store.getState().personFilters.stanFilterArray
        : undefined,
      date_added__lte: store.getState().personFilters.date_added__lte
        ? store.getState().personFilters.date_added__lte
        : "",
      date_added__gte: store.getState().personFilters.date_added__gte
        ? store.getState().personFilters.date_added__gte
        : "",

      hash: store.getState().personFilters.hash
        ? store.getState().personFilters.hash
        : undefined,
      region: store.getState().personFilters.region
        ? store.getState().personFilters.region
        : undefined,
      kved: store.getState().personFilters.kved
        ? store.getState().personFilters.kved
        : undefined,
      kved_is_main:
        store.getState().personFilters.kved &&
        store.getState().personFilters.kved_is_main
          ? 1
          : undefined,
      // lon_border__range: "44.386389__52.377778",
      // lat_border__range: "22.138056__40.223611"
    };
    try {
      dispatch(MapInterfaceActionCreator.setIsLoading(true));
      const response = await personService.fetchMapPersons(data);
      if (response) {
        dispatch(
          PersonsMapObjectActionCreators.setMapPersonsInfo(response.data)
        );
      }
      dispatch(MapInterfaceActionCreator.setIsLoading(false));
    } catch (e: string | any) {
      dispatch(
        MapInterfaceActionCreator.errorDispatch(e?.response?.data?.detail)
      );
    }
  },
};
