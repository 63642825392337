import { useState } from "react";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import Details from "./ActiveTabs/Details";
import Contacts from "./ActiveTabs/Contacts";
import Jp from "../../../../assets/svg/jpIcon.svg";
import useSearchParams from "../../../../hooks/useSearchParams";
import { useTranslation } from "react-i18next";
import { ICurrentCompany } from "../../../../models/CurrentCompany";
import Tab, { TabsWrapper } from "../../../Tab/Tab";
import AnimatedDetailsContainer from "../../../../share/ResultDetails/AnimatedDetailsContainer/AnimatedDetailsContainer";
import DetailsHeader from "../../../../share/ResultDetails/DetailsHeader/DetailsHeader";
import {
  DetailsContainer,
  DetailsTabContent,
  ShowOnMapButton,
  StyledDetailsActions,
} from "../../../../share/ResultDetails/DetailsScroll/DetailsScroll";
import useMobileScreen from "../../../../hooks/useMobileScreen";
const CompanyDetails = () => {
  const { company } = useTypedSelector((state) => state.mapCompanyInfo);
  const { isDetailsActive } = useTypedSelector((state) => state.mapInterface);

  const { setIsDetailsActive, setCurrentMarker, setMapCompany } = useActions();
  const [activeTab, setActiveTab] = useState("details");
  const { removeQueryParam } = useSearchParams();
  const handleCloseDetails = () => {
    setIsDetailsActive(!isDetailsActive);
    setCurrentMarker({ lat: 0, lon: 0 });
    removeQueryParam("hash_id");
    // getCurrentMapCompany("", id);
    setMapCompany({ company: null } as ICurrentCompany);
  };

  const title =
    company && company?.SHORT_NAME ? company?.SHORT_NAME : company?.NAME;

  const { t: tDetails } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.objectDetails",
  });

  const isMobile = useMobileScreen();

  if (!company) return null;

  return (
    <AnimatedDetailsContainer isShown={isDetailsActive}>
      <DetailsHeader
        title={title}
        description={company && company?.ADDRESS}
        closeDetails={handleCloseDetails}
        logo={<img src={Jp} alt="" />}
      />
      <DetailsContainer>
        <TabsWrapper>
          <Tab
            isActive={activeTab === "details"}
            onClick={() => setActiveTab("details")}
            text={tDetails("details.title")}
          />
          <Tab
            isActive={activeTab === "contacts"}
            onClick={() => setActiveTab("contacts")}
            text={tDetails("contacts.title")}
          />
        </TabsWrapper>
        <DetailsTabContent>
          {activeTab === "details" ? (
            <Details key={company && company.NAME} />
          ) : (
            <Contacts key={company && company.NAME} />
          )}
        </DetailsTabContent>
        {isMobile && (
          <StyledDetailsActions>
            <ShowOnMapButton lat={company?.lon} lon={company?.lat} />
          </StyledDetailsActions>
        )}
      </DetailsContainer>
    </AnimatedDetailsContainer>
  );
};

export default CompanyDetails;
