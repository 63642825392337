import { useMemo } from "react";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { formatISO } from "date-fns";
import { useLazyEffect } from "../../../../hooks/useLazyEffect";
import { useActions } from "../../../../hooks/useActions";

const useCompaniesFilters = () => {
  const { subscribe } = useTypedSelector((state) => state.currentAccount);
  const { offset } = useTypedSelector((state) => state.mapInterface);

  const {
    date_added__gte,
    date_added__lte,
    export__range,
    import__range,
    income__range,
    region,
    edrpou,
    kved,
    kved_is_main,
    activeSuggestions,
    stanFilterArray,
  } = useTypedSelector((state) => state.companyFilters);

  const { hash_ids } = useTypedSelector((state) => state.mapCompanyInfo);

  const { getDefaultCompanyResult } = useActions();

  useLazyEffect(() => {
    getDefaultCompanyResult();
    // eslint-disable-next-line
  }, [
    offset,
    date_added__gte,
    date_added__lte,
    activeSuggestions,
    stanFilterArray,
    export__range,
    import__range,
    income__range,
    region,
    edrpou,
    kved_is_main,
    kved,
  ]);

  const stanSearch = () => ["зареєстровано"];
  const regionSearch = () =>
    region.length ||
    (region.length && subscribe?.permissions?.region?.includes(region[0]))
      ? region
      : subscribe?.permissions?.region?.length
      ? subscribe?.permissions?.region
      : [];
  const edrpouSearch = () => (edrpou.length ? edrpou : []);
  const exportSearch = () =>
    export__range !== ""
      ? (export__range.split("__") as unknown as number[])
      : ["", ""];
  const importSearch = () =>
    import__range !== ""
      ? (import__range.split("__") as unknown as number[])
      : ["", ""];
  const incomeSearch = () =>
    income__range !== ""
      ? (income__range.split("__") as unknown as number[])
      : ["", ""];
  const toDateSearch = () =>
    date_added__lte !== ""
      ? formatISO(
          new Date(date_added__lte).setDate(
            new Date(date_added__lte).getDate() + 1
          )
        )
      : "";
  const fromDateSearch = () =>
    date_added__gte !== ""
      ? formatISO(
          new Date(date_added__gte).setDate(
            new Date(date_added__gte).getDate() - 1
          )
        )
      : "";

  const filterByCodes = ["in", "RECORD", ...hash_ids];
  const filterByRegion = ["in", "region", ...regionSearch()];
  const filterByEdrpou = ["in", "EDRPOU", ...edrpouSearch()];
  const filterByStan = ["in", "STAN", ...stanSearch()];
  const filterExportLTE = ["<=", "export_th_uah", +exportSearch()[1]];
  const filterExportGTE = [">=", "export_th_uah", +exportSearch()[0]];
  const filterImportLTE = ["<=", "import_th_uah", +importSearch()[1]];
  const filterImportGTE = [">=", "import_th_uah", +importSearch()[0]];
  const filterIncomeLTE = ["<=", "annual_income_th_uah", +incomeSearch()[1]];
  const filterIncomeGTE = [">=", "annual_income_th_uah", +incomeSearch()[0]];
  const filterToDate = ["<=", "registration_date_1", toDateSearch()];
  const filterFromDate = [">=", "registration_date_1", fromDateSearch()];
  const filterLatGTE = [">=", "lat", 44.386389];
  const filterLatLTE = ["<=", "lat", 52.377778];
  const filterLonGTE = [">=", "lon", 22.138056];
  const filterLonLTE = ["<=", "lon", 40.223611];
  const filters = [
    filterByStan,
    filterByCodes,
    filterToDate,
    filterFromDate,
    filterExportLTE,
    filterExportGTE,
    filterImportLTE,
    filterImportGTE,
    filterLonLTE,
    filterLatGTE,
    filterLatLTE,
    filterByRegion,
    filterByEdrpou,
    filterIncomeLTE,
    filterIncomeGTE,
    filterLonGTE,
  ].filter(
    (item) =>
      item.length >= 3 &&
      item[item.length - 1] !== "" &&
      item[item.length - 1] !== 0
  );
  if (edrpou.length !== 0 || kved.length !== 0) {
    filters.push(filterByCodes);
  }
  const mapFilter = useMemo(() => ["all", ...filters], [filters]);
  return mapFilter;
};

export default useCompaniesFilters;
