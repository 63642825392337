import auth from "./auth";
import downloads from "./download";
import map from "./_maps/main/map";
import company from "./_maps/company/company";
import person from "./_maps/persons/person";
import sikaDesigner from "./_maps/sika/sikaDesigner";
import {
  accountReducer,
  accountsReducer,
  utilityAccountReducer,
} from "./accounts";
import mapInterface from "./_maps/mapInterface";
import mapFilters from "./_maps/main/mapFilters";
import companyFilters from "./_maps/company/companyFilters";
import personFilters from "./_maps/persons/personFilters";
import sikaDesignerFilters from "./_maps/sika/sikaDesignersFilter";
import currentUserReducer from "./currentUser";
import usaidMapFilters from "./_maps/usaid/usaidMapFilters";
import usaid from "./_maps/usaid/usaidMap";
import templates from "./templates";
import feedback from "./feedback";

const reducers = {
  auth,
  accountsReducer,
  currentUserReducer,
  accountReducer,
  utilityAccountReducer,
  map,
  company,
  mapInterface,
  mapFilters,
  companyFilters,
  person,
  personFilters,
  usaidMapFilters,
  sikaDesignerFilters,
  sikaDesigner,
  usaid,
  templates,
  downloads,
  feedback,
};

export default reducers;
