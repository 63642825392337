import styled from "@emotion/styled";
import { ReactNode } from "react";

const StyledHeading3 = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.0012em;
  color: var(--secondary-black-color);
  flex: none;
  order: 1;
  flex-grow: 0;
`;

type headingsTypesEnum = "h3";

const subheadings: { [key in headingsTypesEnum]: any } = {
  h3: StyledHeading3,
};

type Props = {
  type: headingsTypesEnum;
  children: ReactNode;
};

const Subheading = ({ children, type = "h3" }: Props) => {
  const StyledSubheadingsByType = subheadings[type];
  return <StyledSubheadingsByType>{children}</StyledSubheadingsByType>;
};

export default Subheading;
