import React, { useRef } from "react";
import styles from "./FiltersTab.module.css";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import FilterTypesBody from "../../_maps/main/Filters/FilterTypes/FilterTypesBody";
import CCFilterBody from "../../_maps/main/Filters/CCFilter/CCFilterBody";
import DateFilterBody from "../../_maps/main/Filters/DateFilter/DateFilterBody";
import OtherFiltersBody from "../../_maps/main/Filters/OtherFilters/OtherFiltersBody";
import { FilterTitleWithInfo } from "../../../share/filters/FilterTitleWithInfo";
import { useTranslation } from "react-i18next";

const FiltersTab = () => {
  const { config } = useTypedSelector((state) => state.mapObjectsInfo);
  const modalBodyRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const { t } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.aimap",
  });
  const objectTypeFilterTitle = t("objectTypeFilter.title");

  return (
    <div className={styles.modalBody} ref={modalBodyRef}>
      {config?.filters?.types && (
        <div className={styles.filterWrapper}>
          <FilterTitleWithInfo title={objectTypeFilterTitle} />
          <FilterTypesBody hasShowMore={true} />
        </div>
      )}

      {config?.filters?.cc && (
        <div className={styles.filterWrapper}>
          <FilterTitleWithInfo title={t("consequencesFilter.title")} />
          <CCFilterBody />
        </div>
      )}

      {config?.filters?.date && (
        <div className={styles.filterWrapper}>
          <FilterTitleWithInfo title={t("periodFilter.title")} />
          <DateFilterBody hasShowMore={true} />
        </div>
      )}

      {config?.filters?.other && (
        <div className={styles.filterWrapper}>
          <OtherFiltersBody hasShowMore={true} />
        </div>
      )}
    </div>
  );
};

export default FiltersTab;
