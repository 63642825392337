import { useState } from "react";
import styles from "../../../main/ObjectDetails/ObjectDetails.module.scss";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { CustomLoader } from "../../../../../share/Loader/Loader";
import HiddenText from "../../../../../assets/svg/Hidden Text.svg";
import ModalWithHeader from "../../../../../share/ModalWithHeader/ModalWithHeader";
import Attention from "../../../../../assets/svg/AttentionHand.svg";
import ArrowRight from "../../../../../assets/svg/arrowRight.svg";
import { format, parseISO } from "date-fns";
import { RouteNames } from "../../../../../routes";
import { useNavigate } from "react-router-dom";
import { IContact } from "../../../../../models/CurrentSikaDesigner";
import { numberWithSpaces } from "../../SikaDesignersSearchResults/SikaDesignersSearchResults";
import { useTranslation } from "react-i18next";
import useDateLocale from "../../../../../hooks/useDateLocale";
import TelLink from "../../../../../share/DetailsCellFormattedText/textWrappers/TelLink";
interface IPropertiesInfo {
  IS_PRIMARY?: string;
  CODE: string;
  NAME: string;
}

interface IObject {
  region: string;
}

const Details = () => {
  const { sika_designer } = useTypedSelector(
    (state) => state.mapSikaDesignerInfo
  );
  const { isLoading } = useTypedSelector((state) => state.auth);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  const { t: tDetails } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.objectDetails.details",
  });

  const { selectedLocale } = useDateLocale();

  return (
    <div>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className={styles.detailsTab}>
          <h4 className={styles.tabTitle}>{tDetails("characteristic")}</h4>
          <table className={styles.tabTable}>
            <tbody>
              <tr className={styles.tabRow}>
                <td>{tDetails("name")}:</td>
                <td>
                  {sika_designer && sika_designer?.short_name
                    ? sika_designer?.short_name
                    : sika_designer?.full_name}
                </td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("regNumber")}:</td>
                <td>{sika_designer?.edrpou_code}</td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("profit2020hrvn")}:</td>
                <td>
                  {sika_designer?.income_2020
                    ? numberWithSpaces(sika_designer.income_2020)
                    : tDetails("noData")}
                </td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("profit2021hrvn")}:</td>
                {/*<td>{sika_designer?.income_2021}</td>*/}
                <td>{tDetails("awaitingData")}</td>
              </tr>
              {sika_designer && sika_designer.director_name ? (
                <tr className={styles.tabRow}>
                  <td>{tDetails("director")}:</td>
                  <td>{sika_designer?.director_name}</td>
                </tr>
              ) : null}

              {sika_designer &&
              sika_designer.contacts?.find((obj: IContact) => {
                return obj.type === "Телефон директора";
              }) ? (
                <tr className={styles.tabRow}>
                  <td>{tDetails("directorPhoneNumber")}:</td>
                  <td>
                    <TelLink>
                      {
                        sika_designer.contacts?.find((obj: IContact) => {
                          return obj.type === "Телефон директора";
                        }).value
                      }
                    </TelLink>
                  </td>
                </tr>
              ) : null}
              {sika_designer && sika_designer.booker_name ? (
                <tr className={styles.tabRow}>
                  <td>{tDetails("accountant")}:</td>
                  <td>{sika_designer?.booker_name}</td>
                </tr>
              ) : null}
              {sika_designer &&
              sika_designer.contacts?.find((obj: IContact) => {
                return obj.type === "Телефон бухгалтера";
              }) ? (
                <tr className={styles.tabRow}>
                  <td>{tDetails("accountantPhoneNumber")}:</td>

                  <td>
                    <TelLink>
                      {
                        sika_designer.contacts?.find((obj: IContact) => {
                          return obj.type === "Телефон бухгалтера";
                        }).value
                      }
                    </TelLink>
                  </td>
                </tr>
              ) : null}

              <tr className={styles.tabRow}>
                <td>{tDetails("address")}:</td>
                <td>
                  {sika_designer?.address === "#####" ? (
                    <button
                      type="button"
                      className={styles.noRulesLink}
                      onClick={() => setModalVisible(true)}
                      data-tooltip="Відкрити всі дані"
                    >
                      <img src={HiddenText} alt="no rules" />
                    </button>
                  ) : (
                    sika_designer?.address
                  )}
                </td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("region")}:</td>
                <td>{sika_designer?.country_part}</td>
              </tr>
              <tr className={styles.tabRow}>
                <td>{tDetails("creationDate")}:</td>
                <td>
                  {sika_designer?.reg_date
                    ? format(parseISO(sika_designer?.reg_date), "dd-MM-yyyy", {
                        locale: selectedLocale,
                      })
                    : tDetails("notDefined")}
                </td>
              </tr>
            </tbody>
          </table>

          <h4 className={styles.tabTitle}>{tDetails("description")}</h4>
          <div className={styles.tabRow}>
            <p className={styles.tabText}>{sika_designer?.full_name}</p>
          </div>
          <br />
          <table className={styles.tabTable}>
            <tbody>
              <tr className={styles.tabRow}>
                <td>
                  {" "}
                  <strong>{tDetails("projectCount")}:</strong>
                </td>
              </tr>
              {sika_designer && sika_designer.finished_projects_full ? (
                <tr className={styles.tabRow}>
                  <td>{tDetails("completed20132022")}:</td>
                  <td>{sika_designer?.finished_projects_full}</td>
                </tr>
              ) : null}
              {sika_designer && sika_designer.actual_projects ? (
                <tr className={styles.tabRow}>
                  <td>{tDetails("notCompleted2022")}:</td>
                  <td> {sika_designer?.actual_projects}</td>
                </tr>
              ) : (
                <tr className={styles.tabRow}>
                  <td>{tDetails("notCompleted2022")}:</td>
                  <td>0</td>
                </tr>
              )}
              {sika_designer && sika_designer.finished_projects_2021 ? (
                <tr className={styles.tabRow}>
                  <td>{tDetails("completed20212022")}:</td>
                  <td> {sika_designer?.finished_projects_2021}</td>
                </tr>
              ) : (
                <tr className={styles.tabRow}>
                  <td>{tDetails("completed20212022")}:</td>
                  <td>0</td>
                </tr>
              )}
              <tr className={styles.tabRow}>
                <td>{tDetails("objectRegions")}</td>
                <td>
                  {sika_designer?.object_list?.reduce(
                    (sum: string, current: IObject) =>
                      sum.includes(current.region) ||
                      current.region === "Невизначено"
                        ? sum
                        : sum + current.region + "; ",
                    ""
                  )}
                </td>
              </tr>
              <br />
              {sika_designer && sika_designer.domain ? (
                <tr className={styles.tabRow}>
                  <td>{tDetails("category")}:</td>
                  <td>{sika_designer?.domain.join("; ")}</td>
                </tr>
              ) : null}
              {sika_designer && sika_designer.aimap_classifier ? (
                <tr className={styles.tabRow}>
                  <td>{tDetails("constructionType")}:</td>
                  <td>{sika_designer?.aimap_classifier.join("; ")}</td>
                </tr>
              ) : null}
              {sika_designer && sika_designer.consequence_class ? (
                <tr className={styles.tabRow}>
                  <td>{tDetails("consequenceClass")}</td>
                  <td>{sika_designer?.consequence_class.join(", ")}</td>
                </tr>
              ) : null}
            </tbody>
          </table>

          {sika_designer?.kved_code?.length > 0 && (
            <>
              <h4 className={styles.tabTitle}>{tDetails("activityType")}</h4>
              <table className={styles.tabTable}>
                <tbody>
                  {sika_designer.kved_code?.map(
                    (prop: IPropertiesInfo, index: number) => (
                      <tr key={index} className={styles.tabRow}>
                        <td>
                          {prop.CODE}{" "}
                          {prop.IS_PRIMARY === "так" ? " - основний" : ""}
                        </td>
                        <td>
                          {prop?.NAME === "#####" ? (
                            <button
                              type="button"
                              className={styles.noRulesLink}
                              onClick={() => setModalVisible(true)}
                              data-tooltip={tDetails("showAll")}
                            >
                              <img src={HiddenText} alt="no rules" />
                            </button>
                          ) : (
                            prop.NAME
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>
      )}
      <ModalWithHeader
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        title={tDetails("dataNotAvailable")}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalInfoWrapper}>
            <img src={Attention} alt="attention" />
            <p className={styles.infoText}>{tDetails("warn")}</p>
          </div>
          <div className={styles.bodyWrapper}>
            <p className={styles.bodyText}>{tDetails("warn2")}</p>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <button
            onClick={() => navigate(RouteNames.SUBSCRIPTIONS)}
            className={styles.modalButton}
          >
            {tDetails("updateSubscriptionPlan")}
            <img src={ArrowRight} alt="next" />
          </button>
        </div>
      </ModalWithHeader>
    </div>
  );
};

export default Details;
