import React, { createContext, useContext, useRef } from "react";

const MapPageContext = createContext({
  searchResultsRef: null,
});

export const MapPageProvider = ({ children }) => {
  const searchResultsRef = useRef<HTMLDivElement | null>(null);
  return (
    <MapPageContext.Provider value={{ searchResultsRef }}>
      {children}
    </MapPageContext.Provider>
  );
};

export const useMapPageContext = () => {
  return useContext(MapPageContext);
};

export default MapPageContext;
