import styled from "@emotion/styled";
import { breakpoints, colors, zIndexes } from "../../../constants";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import CustomIcon from "../../../share/CustomIcon/CustomIcon";
import { ReactComponent as Plus } from "../../../assets/svg/plus_nobg.svg";
import LoginNotificationPopup from "../../../share/NotificationPopInformation/LoginNotificationPopup/LoginNotificationPopup";
import useStoreSnippets from "../../../hooks/useStoreSnippets";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import SubscribeNotificationPopup from "../../../share/NotificationPopInformation/SubscribeNotificationPopup/SubscribeNotificationPopup";
import { useTipsContext } from "../../../hooks/useTips";
import { datasets } from "../../../constants/mapConstants";

const StyledButton = styled.button<{ isDisabled?: boolean; active?: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  text-align: center;
  width: auto;
  font-weight: 600;
  height: 40px;
  font-size: 14px;
  line-height: 1.285;
  letter-spacing: 0.0012em;
  padding: 8px;
  background: ${(props) =>
    props.isDisabled
      ? colors.darkD75
      : props.active
      ? colors.darkD100
      : "none"};
  border: 1px solid ${colors.darkD100};
  border-color: ${(props) =>
    !props.isDisabled ? colors.darkD400 : colors.darkD100};
  border-radius: 4px;
  cursor: ${(props) => (!props.isDisabled ? "pointer" : "not-allowed")};
  z-index: ${zIndexes.filters};
  pointer-events: ${(props) => (props.isDisabled ? "none" : "auto")};

  @media screen and (max-width: ${breakpoints.medium + "px"}) {
    height: 32px;
    padding: 4px;
  }
`;

interface IAddMoreFiltersButton {
  onClick?: () => void;
  isActive?: boolean;
  isDisabled?: boolean;
}

const AddMoreFiltersButton: FC<IAddMoreFiltersButton> = ({
  onClick,
  isActive = false,
}) => {
  const isDisabledForDataset = useStoreSnippets().dataset !== datasets.objects;
  const loginTipId = "loginToUseAddMoreFilters";
  const subscribeTipId = "subscribeToAddMoreTemplates";

  const { t: tFilter } = useTranslation("translation", {
    keyPrefix: "mainPage.mapFilters.filtersActions",
  });

  const { t: tNotification } = useTranslation("translation", {
    keyPrefix: "notifications",
  });
  const { setLastOpenedTipId } = useTipsContext();
  const tooltip = tFilter("AddMoreFilters", "Зберегти фільтри");

  const { templates } = useTypedSelector((state) => state.templates);

  const isMoreThenThreeTemplatesOnUser: boolean = templates.length >= 3;
  const { isGuestAccount, isFreeAccount } = useStoreSnippets();

  function handleClick() {
    if (isDisabledForDataset) {
      return;
    }
    if (isGuestAccount) {
      setLastOpenedTipId(loginTipId);
      return;
    }
    if (isMoreThenThreeTemplatesOnUser && isFreeAccount) {
      setLastOpenedTipId(subscribeTipId);
      return;
    }
    onClick && onClick();
  }
  return (
    <>
      <LoginNotificationPopup
        id={loginTipId}
        bottomPx={-152}
        rightPx={-436}
        cornerTopPosition={16}
      />
      <SubscribeNotificationPopup
        id={subscribeTipId}
        message={tNotification("subscribeToAddMoreTemplates")}
        bottomPx={-152}
        rightPx={-436}
        cornerTopPosition={16}
      />
      <Tooltip title={tooltip}>
        <StyledButton
          onClick={handleClick}
          active={isActive}
          isDisabled={isDisabledForDataset}
        >
          <CustomIcon
            icon={Plus}
            color={isDisabledForDataset ? colors.darkD200 : colors.darkD400}
          />
        </StyledButton>
      </Tooltip>
    </>
  );
};

export default AddMoreFiltersButton;
