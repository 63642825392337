import React, { useState } from "react";
import styles from "./UnregisteredUser.module.css";
import { useActions } from "../../../hooks/useActions";
import { CustomInput, inputTypes } from "../../../share/Input/Input";
import CloseEye from "../../../assets/svg/closeEye.svg";
import OpenEye from "../../../assets/svg/openEye.svg";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { ButtonLoader } from "../../../share/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../../routes";
import css from "../../../styles.module.scss";
import ContractOffer from "../../ContractOffer/ContractOffer";
import { useTranslation } from "react-i18next";

export interface UnregUserForm {
  full_name: string;
  position: string;
  password: string;
}

const UnregisteredUser = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "unregisteredUserPage",
  });

  const schema = yup
    .object<Record<keyof UnregUserForm, yup.AnySchema>>()
    .shape({
      full_name: yup.string().required(t("name.required")),
      position: yup.string().required(t("role.required")),
      password: yup
        .string()
        .required(t("password.required"))
        .min(8, t("password.min"))
        .max(20, t("password.max")),
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UnregUserForm>({
    resolver: yupResolver(schema),
  });
  const { inviteForUnregisteredUser } = useActions();
  const { error, isLoading } = useTypedSelector((state) => state.auth);
  const navigate = useNavigate();
  const [inputPass, setInputPass] = useState("password");
  const [contractOffer, setContractOffer] = useState(false);

  const handleChangePassword = () => {
    inputPass === "password" ? setInputPass("text") : setInputPass("password");
  };

  const parsedUrl = new URL(window.location.href);
  const uid = parsedUrl.searchParams.get("uid");
  const token = parsedUrl.searchParams.get("token");

  const onSubmit: SubmitHandler<UnregUserForm> = async (
    data: UnregUserForm
  ) => {
    const result = await inviteForUnregisteredUser(uid, token, data);
    if (!!result) {
      navigate(RouteNames.ACCOUNTS);
    }
  };

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.regContainer}>
        <h1>{t("title")}</h1>
        <p className={styles.regContainerText}>{t("description")}</p>
        <form onSubmitCapture={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.formWrapper}>
            <h3 className={styles.formTitle}>{t("contactData")}</h3>
            <label className={styles.label} htmlFor="name">
              {t("name.label")}
              <CustomInput
                name="name"
                type="text"
                inputClassName={styles.input}
                register={register("full_name")}
                isError={!!errors.full_name && !!errors.full_name?.message}
                errorText={errors.full_name?.message}
              />
            </label>
            <label className={styles.label} htmlFor="position">
              {t("role.label")}
              <CustomInput
                name="position"
                type="text"
                inputClassName={styles.input}
                register={register("position")}
                isError={!!errors.position && !!errors.position?.message}
                errorText={errors.position?.message}
              />
            </label>
            <label className={styles.label} htmlFor="password">
              {t("password.label")}
              <CustomInput
                name="password"
                type={inputPass as inputTypes}
                inputClassName={styles.input}
                register={register("password")}
                isError={!!errors.password && !!errors.password?.message}
                errorText={errors.password?.message}
              >
                <button
                  type="button"
                  className={styles.eyesButton}
                  onClick={handleChangePassword}
                >
                  {inputPass === "password" ? (
                    <img src={CloseEye} alt="CloseEye" />
                  ) : (
                    <img src={OpenEye} alt="OpenEye" />
                  )}
                </button>
              </CustomInput>
            </label>
            {error && <span className={styles.errorMsg}>{error}</span>}
          </div>
          <p className={css.contractOfferText}>
            {t("offerConditions.first")}{" "}
            <a
              href="https://aimap.aimarketing.info/uk/terms-of-service"
              className={css.contractOfferButton}
              target="_blank"
              rel="noreferrer"
            >
              {t("offerConditions.last")}
            </a>
            .
          </p>
          {contractOffer && (
            <ContractOffer
              contractOffer={contractOffer}
              setContractOffer={setContractOffer}
            />
          )}
          <div>
            <button
              className={styles.thirdFormSubmitButton}
              type="submit"
              disabled={isLoading}
            >
              {t("submitBtn")}
              {isLoading ? <ButtonLoader /> : null}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UnregisteredUser;
