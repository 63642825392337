const objectFilterTranslationMap = {
  "Транспортна інфраструктура": "transportInfrastructure",
  "Будинки житлові багатоквартирні": "residentialBuildingsApartments",
  "Будинки житлові котеджного типу": "residentialBuildingsCottages",
  "Гаражі, стоянки, СТО": "garagesParkingServiceStations",
  Квартири: "apartments",
  "Освітні заклади": "educationalInstitutions",
  "Кінотеатри, зали, музеї": "cinemasHallsMuseums",
  "Будівлі АПК": "agriculturalBuildings",
  "Торгові центри, магазини": "shoppingCentersStores",
  "Інженерні мережі": "engineeringNetworks",
  "Промислові будівлі": "industrialBuildings",
  Інше: "other",
  "Ресторани кафе, їдальні": "restaurantsCafesDining",
  "Адміністративні будівлі": "administrativeBuildings",
  "Спортивні споруди": "sportsFacilities",
  Лікарні: "hospitals",
  "Готелі, пансіонати, гуртожитки": "hotelsResortsDormitories",
  "Електромережі та електростанції": "powerGridsPowerStations",
  Освітлення: "lighting",
  "Зв'язок": "communication",
  невизначено: "undefined",
  IT: "it",
  Будівництво: "construction",
  "Будівництво / Архітектура": "constructionArchitecture",
  "Виробництво меду": "honeyProduction",
  "Вирощування овочів та фруктів": "vegetableFruitGrowing",
  "Науково дослідна діяльність для промислового виробництва":
    "scientificResearchIndustrialProduction",
  "Нові джерела енергії та біопаливо": "newEnergySourcesBiofuels",
  "Промислове виробництво": "industrialProduction",
  "Промислове виробництво / Будматеріали":
    "industrialProductionBuildingMaterials",
  "Ремонт електроніки": "electronicsRepair",
};

const ccFilterTranslationMap = {
  невизначено: "undefined",
  "Котеджі, дачі, малоповерхові будівлі (СС1)":
    "cottagesSummerhousesLowRiseBuildings",
  "Середні та крупні будівлі (СС2)": "mediumAndLargeBuildings",
  "Стратегічні та небезпечні будівлі (СС3)": "strategicAndHazardousBuildings",
  "не застосовується": "notApplicable",
};

const documentationStageFilterTranslationMap = {
  "видача сертифіката про прийняття в експлуатацію закінчених будівництвом об’єктів":
    "certificateOfCompletionForConstructedObjects",
  "реєстрація декларації про готовність до експлуатації об’єкта за амністією":
    "registrationOfReadinessDeclarationForOperationByAmnesty",
  "декларація про готовність об'єкта до експлуатації":
    "declarationOfObjectReadinessForOperation",
  "повідомлення про початок будівельних робіт":
    "notificationOfCommencementOfConstructionWorks",
  "видача дозволу на виконання будівельних робіт": "buildingPermitIssuance",
  "повідомлення про початок виконання підготовчих робіт":
    "notificationOfCommencementOfPreparatoryWorks",
  "декларація про готовність об'єкта (індивідуальних)":
    "declarationOfObjectReadinessIndividual",
  "декларація про готовність об'єкта (господарських)":
    "declarationOfObjectReadinessEconomic",
  "реєстрація декларації про готовність до експлуатації об’єкта":
    "registrationOfReadinessDeclarationForOperation",
  "декларація про початок підготовчих робіт":
    "declarationOfCommencementOfPreparatoryWorks",
  "декларація про готовність об'єкта (громадських)":
    "declarationOfObjectReadinessPublic",
  "повідомлення про початок виконання будівельних робіт":
    "notificationOfCommencementOfWorks",
  "технічна помилка в декларації про готовність об'єкта":
    "technicalErrorInObjectReadinessDeclaration",
  невизначено: "undefined",
  "повернення декларації про готовність об'єкта":
    "returnOfObjectReadinessDeclaration",
  "відмова у реєстрації декларації про готовність об'єкта до експлуатації":
    "refusalToRegisterObjectReadinessDeclarationForOperation",
  "відмова у наданні дозволу": "refusalToIssuePermit",
  "зміна даних у повідомленні про початок будівельних робіт":
    "changeOfDataInNotificationOfCommencementOfConstructionWorks",
  "повернення на доопрацювання декларації про початок будівельних робіт":
    "returnForRevisionOfDeclarationOfCommencementOfConstructionWorks",
  "відмова у видачі сертифікату": "refusalToIssueCertificate",
  "декларація про готовність до експлуатації об’єкта":
    "declarationOfReadinessForOperation",
  "декларація про готовність за амністією": "declarationOfReadinessByAmnesty",
  "дозвіл на будівництво": "buildingPermit",
  "повідомлення про початок підготовчих робіт":
    "notificationOfCommencementOfPreparatoryWorks",
  "декларація про готовність до експлуатації за рішенням суду":
    "declarationOfReadinessForOperationByCourtDecision",
  сертифікат: "certificate",
  "декларація про початок будівельних робіт":
    "declarationOfCommencementOfConstructionWorks",
  "зміна даних у декларації про початок будівельних робіт":
    "changeOfDataInDeclarationOfCommencementOfConstructionWorks",
  "повідомлення про зміну даних в дозволі на будівельні роботи":
    "notificationOfChangeOfDataInBuildingPermit",
  "дозвіл на будівельні роботи": "buildingPermitForConstructionWorks",
  "зміна даних у декларації про початок підготовчих робіт":
    "changeOfDataInDeclarationOfCommencementOfPreparatoryWorks",
  "зміна даних у повідомленні про початок підготовчих робіт":
    "changeOfDataInNotificationOfCommencementOfPreparatoryWorks",
  "зміна даних у дозволі на виконання будівельних робіт":
    "changeOfDataInBuildingPermit",
  "реєстрація декларації про готовність до експлуатації об’єкта, за рішенням суду":
    "registrationOfReadinessDeclarationForOperationByCourtDecision",
  "внесення змін до повідомлення про початок виконання будівельних робіт":
    "changeOfDataInNotificationOfCommencementOfWorks",
  "внесення змін до декларації про початок будівельних робіт":
    "changeOfDataInDeclarationOfCommencementOfConstructionWorks",
  "дозвільний документ": "permitDocument",
};

const typeFilterTranslationMap = {
  реконструкція: "reconstruction",
  "нове будівництво": "newConstruction",
  "капітальний ремонт": "majorRepair",
  реставрація: "restoration",
  "реставрація без зміни зовнішніх геометричних розмірів":
    "restorationWithoutChangingExternalDimensions",
  "існуюча забудова": "existingBuilding",
  "реконструкція без зміни зовнішніх геометричних розмірів":
    "reconstructionWithoutChangingExternalDimensions",
};

const stageFilterTranslationMap = {
  дозволено: "allowed",
  завершено: "completed",
};

const entityTypeFilterTranslationMap = {
  "Фізична особа": "individual",
  "Юридична особа": "legalEntity",
};

const statusFilterTranslationMap = {
  припинено: "stopped",
  зареєстровано: "registered",
  "в стані припинення": "inSuspension",
  скасовано: "canceled",
  "порушено справу про банкрутство": "bankruptcyCaseInitiated",
  "порушено справу про банкрутство (санація)":
    "bankruptcyCaseInitiatedRehabilitation",
  "зареєстровано, свідоцтво про державну реєстрацію недійсне":
    "registeredStateRegistrationCertificateInvalid",
};

const categoryFilterTranslationMap = {
  Малі: "small",
  Мікро: "micro",
  "Середні і Великі": "mediumAndLarge",
  "Фізична особа": "individual",
};

const activityStatusFilterTranslationMap = {
  Активні: "active",
  "Не активні": "inactive",
  "Не проводять основну діяльність": "notEngagedInMainActivity",
};

const regionFilterTranslationMap = {
  Північ: "north",
  Захід: "west",
  Центр: "center",
  Південь: "south",
  Схід: "east",
};

const objectCategoryTranslationMap = {
  "Соціальна сфера": "socialSphere",
  "Мережі і комунікації": "networksAndCommunications",
  Транспорт: "transport",
  "Житлові комплекси": "residentialComplexes",
  "Державне управління": "governmentAdministration",
  "Торгівля і послуги": "tradeAndServices",
  "Промислові будівлі": "industrialBuildings",
  Логістика: "logistics",
  Лікарні: "hospitals",
  "Будівлі АПК": "agriculturalBuildings",
  "Спортивні споруди": "sportsFacilities",
  Інше: "other",
  Готелі: "hotels",
  Приватні: "private",
};

const organizationTypeTranslationMap = {
  розробник: "developer",
  проектувальник: "designer",
};

const ownershipTranslationMap = {
  "Юридична особа": "legalEntity",
};

export {
  objectFilterTranslationMap,
  ccFilterTranslationMap,
  stageFilterTranslationMap,
  typeFilterTranslationMap,
  documentationStageFilterTranslationMap,
  statusFilterTranslationMap,
  entityTypeFilterTranslationMap,
  categoryFilterTranslationMap,
  activityStatusFilterTranslationMap,
  regionFilterTranslationMap,
  objectCategoryTranslationMap,
  organizationTypeTranslationMap,
  ownershipTranslationMap,
};
