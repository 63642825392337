import styles from "./Subscriptions.module.scss";
import css from "../../styles.module.scss";
import ArrowLeft from "../../assets/svg/arrowLeft.svg";
import ClosePage from "../../assets/svg/Close.svg";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useTranslation } from "react-i18next";

const Subscriptions = () => {
  const navigate = useNavigate();
  const { card_name } = useTypedSelector(
    (state) => state.currentAccount?.subscribe
  );

  const getSubscriptionInfo = (sub_type: string) => {
    navigate(RouteNames.SUBSCRIPTION_TYPE + `?sub_type=${sub_type}`);
  };

  const { t: tSubscription } = useTranslation("translation", {
    keyPrefix: "subscriptionPage",
  });
  return (
    <div className={styles.mainSubWrap}>
      <button
        onClick={() => navigate(RouteNames.PAYMENTS)}
        type="button"
        className={styles.closeButtonSvg}
      >
        <img src={ClosePage} alt="Close" />
      </button>
      <div className={styles.subHeader}>
        <h1 className={styles.subTitle}>{tSubscription("subTitle")}</h1>
        <p className={styles.subDescry}>{tSubscription("subDescription")}</p>
      </div>
      <div className={styles.subsContainer}>
        <div
          className={
            card_name === "Регіональна" ? css.display : styles.subInfoWrapper
          }
        >
          <div className={styles.subInfoHeader}>
            <h3 className={styles.subInfoTitle}>
              {tSubscription("regional.subInfoTitle")}
            </h3>
          </div>
          <div className={styles.priceWrapper}>
            <h5 className={styles.priceTitle}>
              {tSubscription("regional.priceTitle")}
            </h5>
            <p className={styles.subInfoDecry}>
              {tSubscription("regional.subInfoDecry")}
            </p>
          </div>
          <button
            id="regionalSubProfButton"
            onClick={() => getSubscriptionInfo("reg")}
            type="button"
            className={styles.subProfButton}
          >
            {tSubscription("regional.subProfButton")}
          </button>
          <span className={styles.subBenefits}>
            <img src={ArrowLeft} alt="" />
            {tSubscription("regional.subBenefitsTitle")}
          </span>
          <ul className={styles.subBenefitsList}>
            <li>{tSubscription("regional.subBenefits.userLimit")}</li>
            <li>{tSubscription("regional.subBenefits.downloadLimit")}</li>
            <li>{tSubscription("regional.subBenefits.relatedInfo")}</li>
            <li>{tSubscription("regional.subBenefits.contactData")}</li>
            <li>{tSubscription("regional.subBenefits.excelExport")}</li>
            <li>{tSubscription("regional.subBenefits.fullAccessRegion")}</li>
            <li>{tSubscription("regional.subBenefits.universalSearch")}</li>
          </ul>
        </div>
        <div
          className={
            card_name === "Професійна" ? css.display : styles.subInfoWrapper
          }
        >
          <div className={styles.subInfoHeader}>
            <h3 className={styles.subInfoTitle}>
              {tSubscription("prof.subInfoTitle")}
            </h3>
          </div>
          <div className={styles.priceWrapper}>
            <h5 className={styles.priceTitle}>
              {tSubscription("prof.priceTitle")}
            </h5>
            <p className={styles.subInfoDecry}>
              {tSubscription("prof.subInfoDecry")}
            </p>
          </div>
          <button
            onClick={() => getSubscriptionInfo("prof")}
            type="button"
            className={styles.subProfButton}
          >
            {tSubscription("prof.subProfButton")}
          </button>
          <span className={styles.subBenefits}>
            <img src={ArrowLeft} alt="" />
            {tSubscription("prof.subBenefitsTitle")}
          </span>
          <ul className={styles.subBenefitsList}>
            <li>{tSubscription("prof.subBenefits.userLimit")}</li>
            <li>{tSubscription("prof.subBenefits.downloadLimit")}</li>
            <li>{tSubscription("prof.subBenefits.relatedInfo")}</li>
          </ul>
        </div>
        <div className={styles.subInfoWrapper}>
          <div className={styles.subInfoHeaderLast}>
            <h3 className={styles.subInfoTitle}>
              {tSubscription("exclusive.subInfoTitle")}
            </h3>
          </div>
          <div className={styles.priceWrapper}>
            <p className={styles.subInfoDecry}>
              {tSubscription("exclusive.subInfoDecry")}
            </p>
          </div>
          <a
            href="https://aimap.aimarketing.info/uk/contacts"
            target="_blank"
            rel="noreferrer"
            className={styles.contactsLink}
          >
            {tSubscription("exclusive.contactsLink")}
          </a>
          <span className={styles.subBenefits}>
            <img src={ArrowLeft} alt="" />
            {tSubscription("exclusive.subBenefitsTitle")}
          </span>
          <ul className={styles.subBenefitsList}>
            <li>{tSubscription("exclusive.subBenefits.noLimits")}</li>
            <li>{tSubscription("exclusive.subBenefits.systemIntegration")}</li>
            <li>{tSubscription("exclusive.subBenefits.apiAccess")}</li>
            <li>{tSubscription("exclusive.subBenefits.salesTraining")}</li>
            <li>{tSubscription("exclusive.subBenefits.customAdaptation")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;
