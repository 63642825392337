import styles from "../../main/Filters/Filters.module.scss";
import ExportImportFilters from "./ExportImportFilters/ExportImportFilters";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import FilterTypes from "./FilterTypes/FilterTypes";
import AreaFilter from "./AreaFilter/AreaFilter";
import OtherFilters from "./OtherFilters/OtherFilters";
import DatasetsDropdown from "../../_mapFilterComponents/DatasetsDropdown/DatasetsDropdown";
import RegionDropdown from "../../main/RegionDropdown/RegionDropdown";
import SavedFiltersButton from "../../../templates/SavedFilters/SavedFiltersButton/SavedFiltersButton";
import MobileClearAllButton from "../../../../share/filters/MobileClearAllButton";
import ClearAllFiltersDesktopButton from "../../../../share/filters/ClearAllFiltersDesktopButton";
import AddMoreFiltersButton from "../../../templates/AddSavedFilters/AddMoreFiltersButton";
import { FilterGroup } from "../../../../share/filters";
import SearchFilter from "../../../SearchFilter/SearchFilter";

const Filters = () => {
  const {
    setCleanAllSikaDesignerFilters,
    getDefaultSikaDesignerResult,
    setIsIncomeBigger,
    setIs21Plus,
    setOffset,
    setPage,
  } = useActions();
  const { is21plus, isIncomeBigger } = useTypedSelector(
    (state) => state.sikaDesignerFilters
  );
  const handleClick = () => {
    setOffset(0);
    setPage(1);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handle21PlusChange = () => {
    setIs21Plus(!is21plus);
    handleClick();
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleIncomeBiggerChange = () => {
    setIsIncomeBigger(!isIncomeBigger);
    handleClick();
  };
  const handleClean = () => {
    setCleanAllSikaDesignerFilters();
    getDefaultSikaDesignerResult();
  };

  return (
    <div className={styles.filterMainWrapper}>
      <div className={styles.topContainer}>
        <div className={styles.regionsDatasetsWrapper}>
          <RegionDropdown />
          <DatasetsDropdown />
        </div>
        <div className={styles.topContainerButtons}>
          <SavedFiltersButton />
          <AddMoreFiltersButton />
        </div>
      </div>

      <div className={styles.searchWrapper}>
        <SearchFilter />
      </div>

      <FilterGroup onClick={(event) => event.stopPropagation()}>
        <FilterTypes />
        <ExportImportFilters />
        <AreaFilter />
        <OtherFilters />
        <MobileClearAllButton onClick={handleClean} />
      </FilterGroup>

      <ClearAllFiltersDesktopButton onClick={handleClean} />
    </div>
  );
};

export default Filters;
