import {
  IIconClassRecord,
  iconClassMapperByRegion,
} from "../../constants/iconClassMapperByRegion/iconClassMapperByRegion";
import Other from "../../assets/svg/objectTypes/Other.svg";
import styles from "./ImageFinder.module.scss";
import useSearchParams from "../../hooks/useSearchParams";
import { AvailableCC } from "../../constants/constants";

const ImageFinder = ({ object }: any) => {
  const { getSearchParam } = useSearchParams();
  const region = (getSearchParam("region") as AvailableCC) || AvailableCC.ua;
  const initialFilterOptions = iconClassMapperByRegion[region];
  const imageOnMap = initialFilterOptions.find(
    (type: IIconClassRecord) => type.name === object?.aimap_classifier
  );
  const imageOnSearch = initialFilterOptions.find(
    (type: IIconClassRecord) =>
      type.name === object?.properties?.aimap_classifier
  );
  const imageOnTypeFilter = initialFilterOptions.find(
    (type: IIconClassRecord) => type.name === object
  );

  return (
    <>
      {imageOnMap ? (
        <img
          src={imageOnMap.icon}
          alt={imageOnMap.name}
          width="40px"
          height="40px"
        />
      ) : imageOnSearch ? (
        <img
          src={imageOnSearch?.icon}
          alt={imageOnSearch?.name}
          width="40px"
          height="40px"
        />
      ) : imageOnTypeFilter ? (
        <img
          src={imageOnTypeFilter?.icon}
          className={styles.icon}
          alt={imageOnTypeFilter?.name}
          width="40px"
          height="40px"
        />
      ) : (
        <img src={Other} width="40px" height="40px" alt="" />
      )}
    </>
  );
};

export default ImageFinder;
