import { useCallback, useEffect } from "react";
import ReactMapGL, {
  Layer,
  MapLayerMouseEvent,
  Source,
  ViewStateChangeEvent,
} from "react-map-gl";
import styles from "./CompaniesMap.module.scss";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import { useLazyEffect } from "../../../../hooks/useLazyEffect";
import Jp from "../../../../assets/svg/jpIcon.svg";
import CustomMarker from "../../_mapComponents/CustomMarker/CustomMarker";
import CustomNavigationControl from "../../_mapComponents/CustomNavigationControl/CustomNavigationControl";
import useSearchParams from "../../../../hooks/useSearchParams";
import useSelectedLanguage from "../../../../hooks/useSelectedLanguage";
import useCompaniesFilters from "./useCompaniesFilters";
import util from "../../../../util";
import { IMapCompanyInfoState } from "../../../../store/reducers/_maps/company/company/types";
import {
  defaultViewport,
  mapboxAccessToken,
} from "../../../../constants/mapConstants";
import MapWrapper from "../../../../share/mapComponents/MapWrapper";
import useMobileScreen from "../../../../hooks/useMobileScreen";
import MapBadgeLoader from "../../_mapComponents/MapBadgeLoader";
import PinnedSelectedMapObjectsList from "../../_mapComponents/PinnedSelectedMapObjectsList";
import CustomMapPopupItem from "../../_mapComponents/CustomPopup/CustomMapPopupItem";
import CustomPopup from "../../_mapComponents/CustomPopup";
import useMapObjects from "../../../../hooks/useMapObjects";

const CompaniesMap = () => {
  const { getSearchParam, setSearchParam } = useSearchParams();
  const hashId = getSearchParam("hash_id");

  const selectedLanguage = useSelectedLanguage();
  const { id } = useTypedSelector((state) => state.currentAccount);
  const { isFilterBarActive, viewport, currentMarker } = useTypedSelector(
    (state) => state.mapInterface
  );
  const { companyMapRef } = useTypedSelector((state) => state.mapObjectsInfo);

  const {
    getCurrentMapCompany,
    setIsFilterActive,
    setIsDetailsActive,
    setViewport,
    setCurrentMarker,
    getDefaultCompanyResult,
    setCurrentMapRef,
  } = useActions();

  useEffect(() => {
    setViewport(defaultViewport);
  }, []);

  useEffect(() => {
    setCurrentMapRef(companyMapRef);

    return () => {
      setCurrentMapRef(null);
    };
  }, [companyMapRef]);

  const {
    currObjs,
    pinnedCurrObjs,
    setPinnedCurrObjs,
    markerClickHandler,
    markerHoverHandler,
    selectedLayerProps,
  } = useMapObjects();

  // const [currObj, setCurrObj] = useState<GeoJSON.Feature>(null);
  const mapFilter = useCompaniesFilters();

  // const { object } = useTypedSelector((state) => state.mapCompanyInfo);
  const { company: selectedMapObject } = useTypedSelector(
    (state) => state.mapCompanyInfo as IMapCompanyInfoState
  );
  // ??? TODO: check if this is needed
  useLazyEffect(
    () => {
      if (!selectedMapObject) {
        getDefaultCompanyResult();
      }
    },
    [viewport, selectedMapObject],
    700
  );

  useEffect(() => {
    if (selectedMapObject?.lat && selectedMapObject?.lon) {
      setIsDetailsActive(true);
      setIsFilterActive(true);
      companyMapRef.current?.flyTo({
        center: [+selectedMapObject.lat, +selectedMapObject.lon],
        zoom: 10,
      });
      setCurrentMarker({
        lat: +selectedMapObject.lon,
        lon: +selectedMapObject.lat,
      });
    }
  }, [selectedMapObject]);

  useEffect(() => {
    if (!!hashId) {
      getCurrentMapCompany(hashId, id);
    } else {
      setCurrentMarker({
        lat: 0,
        lon: 0,
      });
    }
  }, []);

  useEffect(() => {
    hashId && getCurrentMapCompany(hashId, id);
  }, [hashId, id]);

  const getFeatures = (e: MapLayerMouseEvent) => {
    if (!e) return [];

    if (!companyMapRef.current) {
      return [];
    }
    const layer =
      viewport.zoom >= 9.8 ? "jp-register-layer" : "jp-register-layer8";
    return companyMapRef.current.queryRenderedFeatures(e.point, {
      layers: [layer],
      filter: mapFilter,
      validate: false,
    });
  };

  useEffect(() => {
    // This function will be called every time `isFilterPanelVisible` changes.
    if (companyMapRef?.current) {
      // If the map instance is available, trigger the resize.
      companyMapRef.current.resize();
    }
  }, [isFilterBarActive]); // Dependency array includes only the variable that triggers the resize.

  const handleMapLoad = useCallback(() => {
    const map = companyMapRef.current?.getMap();
    if (map) {
      util.mapInterfaceUtil.changeMapboxLanguage(map, selectedLanguage);
      util.mapInterfaceUtil.addPinIconToMapbox(map);
    }
  }, []);

  const isMobile = useMobileScreen();

  const renderPopupItem = (currObj: GeoJSON.Feature, index: number) => (
    <CustomMapPopupItem
      isSelected={
        selectedMapObject &&
        currObj.properties.RECORD === selectedMapObject?.RECORD
      }
      key={index}
      onClick={() => {
        setSearchParam("hash_id", currObj.properties.RECORD);
      }}
    >
      <>
        <img src={Jp} alt="" />
        <h4 className={styles.titleStyle}>
          {" "}
          {currObj.properties.NAME.length > 37
            ? currObj.properties.NAME.slice(0, 30) + ".."
            : currObj.properties.NAME}
        </h4>
        <h4 className={styles.popUpClassifier}>{currObj.properties.STAN}</h4>
        <p className={styles.construction_type}>
          {currObj.properties.EDRPOU} &middot; CC{" "}
          {currObj.properties.registration_date_1}
        </p>
      </>
    </CustomMapPopupItem>
  );

  return (
    <MapWrapper>
      {pinnedCurrObjs?.length > 0 && !selectedMapObject?.RECORD && isMobile && (
        <PinnedSelectedMapObjectsList handleClose={() => setPinnedCurrObjs([])}>
          {pinnedCurrObjs.map((currObj, index) =>
            renderPopupItem(currObj, index)
          )}
        </PinnedSelectedMapObjectsList>
      )}
      <ReactMapGL
        {...viewport}
        mapboxAccessToken={mapboxAccessToken}
        // ignore next line
        // eslint-disable-next-line
        ref={(instance) => (companyMapRef.current = instance)}
        minZoom={6}
        maxZoom={15}
        maxPitch={1}
        dragRotate={false}
        mapStyle={"mapbox://styles/aimap/clcad3pwq001a14niei1q701i"}
        onLoad={handleMapLoad}
        onMove={(e: ViewStateChangeEvent) => {
          setViewport(e.viewState);
        }}
        onZoom={(e: ViewStateChangeEvent) => {
          setViewport(e.viewState);
        }}
        onMouseOver={(e) => markerHoverHandler(getFeatures(e))}
        onClick={(e) => markerClickHandler(getFeatures(e), "RECORD")}
        onMouseMove={(e) => markerHoverHandler(getFeatures(e))}
      >
        {currObjs?.length > 0 && (
          <CustomPopup
            latitude={+currObjs[0].properties.lat}
            longitude={+currObjs[0].properties.lon}
            itemsCount={currObjs.length}
          >
            {currObjs.map((currObj, index) => renderPopupItem(currObj, index))}
          </CustomPopup>
        )}
        <MapBadgeLoader />
        <CustomNavigationControl />
        <Source type="vector" url="mapbox://aimap.jp_register_ts8">
          <Layer
            id="point1"
            filter={mapFilter}
            source-layer="jp_register_layer8"
            minzoom={5}
            maxzoom={9.8}
            {...selectedLayerProps}
            type="symbol"
          />
        </Source>
        <Source type="vector" url="mapbox://aimap.jp_register_ts">
          <Layer
            id="point2"
            filter={mapFilter}
            source-layer="jp_register_layer"
            minzoom={9.8}
            maxzoom={22}
            {...selectedLayerProps}
            type="symbol"
          />
        </Source>
        <CustomMarker marker={currentMarker} />
      </ReactMapGL>
    </MapWrapper>
  );
};

export default CompaniesMap;
