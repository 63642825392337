import styled from "@emotion/styled";
import ProfileForm from "../../components/Profile/ProfileForm/ProfileForm";
import ProfileAvatar from "../../components/Profile/ProfileAvatar/ProfileAvatar";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { CustomLoader } from "../../share/Loader/Loader";
import { useEffect } from "react";
import { useActions } from "../../hooks/useActions";
import Page from "../../share/Page/Page";
import ChangePasswordForm from "../../components/Profile/ChangePasswordForm/ChangePasswordForm";
import { useTranslation } from "react-i18next";
import MobileProfile from "../../components/Profile/MobileProfile/MobileProfile";
import useMobileScreen from "../../hooks/useMobileScreen";

const StyledProfileFormsWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 8fr;
  gap: 32px;
`;

const StyledFormList = styled.div`
  display: grid;
  gap: 32px;
`;

export default function Profile() {
  const { getUser } = useActions();
  const currentUser = useTypedSelector((store) => store.currentUser);
  const isLoading = currentUser.isLoading || !currentUser.id;
  useEffect(() => {
    if (!currentUser.id) {
      getUser();
    }
  }, []);

  const { t } = useTranslation("translation", { keyPrefix: "profilePage" });

  const isMobile = useMobileScreen();

  return isMobile ? (
    <MobileProfile />
  ) : (
    <Page pageHeaderText={t("title")} isBreadcrumb={true}>
      <>
        {isLoading ? (
          <CustomLoader />
        ) : (
          <StyledProfileFormsWrapper>
            <ProfileAvatar />
            <StyledFormList>
              <ProfileForm />
              <ChangePasswordForm />
            </StyledFormList>
          </StyledProfileFormsWrapper>
        )}
      </>
    </Page>
  );
}
