import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { CurrentCompany } from "../../../../models/CurrentCompany";
import SearchResultsResponsive from "../../../../share/searchResults/SearchResultsResponsive";
import CompanySearchResultItem from "./CompanySearchResultItem";

const CompanySearchResults = () => {
  const {
    setCleanAllCompanyFilters,
    getDefaultCompanyResult,
    refreshFeedForCompaniesSearchResults,
  } = useActions();

  const { companies, count } = useTypedSelector(
    (state) => state.mapCompanyInfo
  );

  const handleClean = () => {
    setCleanAllCompanyFilters();
    getDefaultCompanyResult();
  };

  return (
    <SearchResultsResponsive
      count={count}
      handleLoadFeed={refreshFeedForCompaniesSearchResults}
      onClearAllFilters={handleClean}
    >
      {companies?.map((mapObject: CurrentCompany, index: number) => {
        return <CompanySearchResultItem key={index} mapObject={mapObject} />;
      })}
    </SearchResultsResponsive>
  );
};

export default CompanySearchResults;
