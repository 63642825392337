export interface DownloadState {
  isDownloading: boolean;
  error: string;
  isDownloadingFinished: boolean;
  url: string;
}

export enum DownloadActionsEnum {
  SET_DOWNLOAD_ERROR = "SET_DOWNLOAD_ERROR",
  SET_IS_DOWNLOADING = "SET_IS_DOWNLOADING",
  SET_IS_DOWNLOADING_FINISHED = "SET_IS_DOWNLOADING_FINISHED",
  SET_DOWNLOADING_URL = "SET_DOWNLOADING_URL",
}

export interface SetErrorAction {
  type: DownloadActionsEnum.SET_DOWNLOAD_ERROR;
  payload: string;
}

export interface SetIsDownloadingAction {
  type: DownloadActionsEnum.SET_IS_DOWNLOADING;
  payload: boolean;
}

// SetIsDownloadingFinished
export interface SetIsDownloadingFinished {
  type: DownloadActionsEnum.SET_IS_DOWNLOADING_FINISHED;
  payload: boolean;
}

// SetDownloadingUrl
export interface SetDownloadingUrl {
  type: DownloadActionsEnum.SET_DOWNLOADING_URL;
  payload: string;
}

export type DownloadActions =
  | SetErrorAction
  | SetIsDownloadingAction
  | SetIsDownloadingFinished
  | SetDownloadingUrl;
