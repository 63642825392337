import React, { useEffect, useState } from "react";
import styles from "./SubscriptionTypePage.module.scss";
import ArrowDown from "../../assets/svg/ArrowDown.svg";
import ArrowUp from "../../assets/svg/ArrowUp.svg";
import { ButtonLoader } from "../../share/Loader/Loader";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes";
import { CustomRadio, CustomSelect } from "../../share/Input/Input";
import { useActions } from "../../hooks/useActions";
import { regions } from "../../models/Accounts";
import css from "../../styles.module.scss";
import { useTranslation } from "react-i18next";
import useSearchParams from "../../hooks/useSearchParams";
import { icons } from "../../constants";
import classNames from "classnames";

const SubscriptionTypePage = () => {
  const { isLoading, error } = useTypedSelector((state) => state.auth);
  const { sub_type, template } = useTypedSelector(
    (state) => state.accountUtility
  );
  const { setSubscribe, setSubscribeTemplate } = useActions();
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [selectedInput, setSelectedInput] = useState("year");
  const [selectValue, setSelectValue] = useState("1");

  const { getSearchParam } = useSearchParams();

  useEffect(() => {
    const sub_type = getSearchParam("sub_type");
    if (!sub_type) {
      navigate(RouteNames.SUBSCRIPTIONS);
    } else {
      const result = setSubscribeTemplate(sub_type);
      if (!result) {
        navigate(RouteNames.SUBSCRIPTIONS);
      }
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedInput(e.target.id);
  };

  const onSubmit = async () => {
    if (selectValue === "1" && sub_type === "reg") return;
    await setSubscribe(selectedInput, selectValue);
  };

  const { t: tPage } = useTranslation("translation", {
    keyPrefix: "subscribePage",
  });
  const { t: tForm } = useTranslation("translation", {
    keyPrefix: "subscribePage.form",
  });
  const { t: tInfoBlock } = useTranslation("translation", {
    keyPrefix: "subscribePage.infoBlock",
  });

  return (
    <div className={styles.subMainWrapper}>
      <div className={styles.header}>
        <h1>{tPage("title")}</h1>
        <p>{tPage("description")}</p>
      </div>
      <div className={styles.bodyWrapper}>
        <div className={styles.formSection}>
          <div className={styles.formWrapper}>
            <h3 className={styles.formTitle}>{tForm("title")}</h3>
            <div className={styles.formContainer}>
              <p className={styles.formDescry}>{tForm("billingCycle.title")}</p>
              <div className={styles.radioContainer}>
                <label htmlFor="year" className={styles.radioLabel}>
                  <CustomRadio
                    id="year"
                    name="year"
                    value="year"
                    checked={selectedInput === "year"}
                    onChange={(e) => handleChange(e)}
                  />
                  {tForm("billingCycle.options.annual.value")}{" "}
                  <span>({tForm("billingCycle.options.annual.comment")})</span>
                </label>
                <label htmlFor="month" className={styles.radioLabel}>
                  <CustomRadio
                    id="month"
                    name="month"
                    value="month"
                    checked={selectedInput === "month"}
                    onChange={(e) => handleChange(e)}
                  />
                  {tForm("billingCycle.options.monthly.value")}
                </label>
              </div>
              {sub_type === "reg" && (
                <label
                  htmlFor={tForm("region")}
                  className={classNames(css.label, styles.regionWrapper)}
                >
                  {tForm("region")}
                  <CustomSelect
                    name={tForm("region")}
                    classSelectName={
                      selectValue === "1"
                        ? `${css.select} ${css.disabledOption}`
                        : css.select
                    }
                    value={selectValue}
                    onChange={(e) => setSelectValue(e.target.value)}
                  >
                    <option value="1" disabled>
                      {tForm("chooseRegion")}
                    </option>
                    {template?.region?.map((region: regions) => (
                      <option
                        className={styles.option}
                        key={region.name}
                        value={region.value}
                      >
                        {region.name}
                      </option>
                    ))}
                  </CustomSelect>
                </label>
              )}
            </div>
            {!showMore ? (
              <>
                <div className={styles.priceWrapper}>
                  <p className={styles.priceText}>{tForm("paymentAmount")}</p>
                  <p className={styles.priceCount}>
                    {selectedInput === "year"
                      ? template.annual
                      : template.monthly}{" "}
                    $
                  </p>
                </div>
                <div className={styles.showMoreWrapper}>
                  <button
                    className={styles.showMoreButton}
                    type="button"
                    onClick={() => setShowMore(true)}
                  >
                    {tForm("priceInfo.more")}
                    <img src={ArrowDown} alt="ArrowDown" />
                  </button>
                </div>
              </>
            ) : (
              <div className={styles.payContainer}>
                <div className={styles.payDescWrapper}>
                  <p>{tForm("priceInfo.participants.value")}</p>
                  <p>
                    {template.max_users}
                    <span>{tForm("priceInfo.participants.comment")}</span>
                  </p>
                </div>
                <div className={styles.payDescWrapper}>
                  <p>{tForm("priceInfo.downloads.value")}</p>
                  <p>{template.download}</p>
                </div>
                <div className={styles.payDescWrapper}>
                  <p>{tForm("priceInfo.availableRegions.value")}</p>
                  <p>
                    {tForm(
                      `priceInfo.availableRegions.${template.region_count}`,
                      template.region_count
                    )}
                    <span>{tForm("priceInfo.availableRegions.comments")}</span>
                  </p>
                </div>
                <div className={styles.showLessWrapper}>
                  <button
                    className={styles.showMoreButton}
                    type="button"
                    onClick={() => setShowMore(false)}
                  >
                    {tForm("priceInfo.less")}
                    <img src={ArrowUp} alt="ArrowUp" />
                  </button>
                </div>
                <div className={styles.priceWrapper}>
                  <p className={styles.priceText}>{tForm("paymentAmount")}</p>
                  <p className={styles.priceCount}>
                    {selectedInput === "year"
                      ? template.annual
                      : template.monthly}{" "}
                    $
                  </p>
                </div>
              </div>
            )}
            {error && <span className={styles.errorMsg}>{error}</span>}
          </div>
          <button
            onClick={onSubmit}
            className={styles.formSubmitButton}
            type="button"
            disabled={isLoading}
          >
            {tPage("submitBtn")}
            {isLoading ? (
              <ButtonLoader />
            ) : (
              <img src={icons.ArrowRight} alt="next" />
            )}
          </button>
        </div>
        <div className={styles.subInfoWrapper}>
          <div className={styles.headerContainer}>
            <h5 className={styles.infoHeader}>{tInfoBlock("title")}</h5>
            <button
              onClick={() => navigate(RouteNames.SUBSCRIPTIONS)}
              type="button"
              className={styles.goBackButton}
            >
              {tInfoBlock("changeSubscriptionType")}
            </button>
          </div>
          <div className={styles.infoContainer}>
            <h6 className={styles.subName}>
              {sub_type === "prof"
                ? tInfoBlock("professionalSubscriptionType")
                : tInfoBlock("regionSubscriptionType")}
            </h6>
            <p className={styles.subDecr}>{tInfoBlock("description")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionTypePage;
