import styled from "@emotion/styled";
import { ReactNode } from "react";

const StyledHeading = styled.div`
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: var(--secondary-black-color);
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const Heading2 = styled(StyledHeading)`
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
`;

export const Heading3 = styled(StyledHeading)`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  order: 0;
`;

type headingsTypesEnum = "h2" | "h3";

const headings: { [key in headingsTypesEnum]: any } = {
  h2: Heading2,
  h3: Heading3,
};

type Props = {
  type: headingsTypesEnum;
  children: ReactNode;
};

const Heading = ({ children, type }: Props) => {
  const StyledHeadingsByType = headings[type];
  return <StyledHeadingsByType>{children}</StyledHeadingsByType>;
};

export default Heading;
