import { httpClient, HttpClient } from "./HTTPService";
import { store } from "../index";
import { ISearchRequest, ISearchResponse } from "../../models/Search";
import qs from "qs";
import { IReqUSAIDObjects } from "../../models/USAIDObject";
import { ICurrentUSAID } from "../../models/CurrentUSAIDObject";
import { IMapUSAIDInfoState } from "../reducers/_maps/usaid/usaidMap/types";

const token = store.getState().auth.token.access;

class USAIDService {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async fetchMapUSAIDList(data: IReqUSAIDObjects) {
    try {
      return this.httpClient.get<IMapUSAIDInfoState>(
        `usaid/${data.account_id}/`,
        {
          params: data,
          paramsSerializer: (params) => {
            return qs.stringify(params, { indices: false });
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async fetchMapUSAID(RECORD: number, account_id: number) {
    if (RECORD) {
      return this.httpClient.get<ICurrentUSAID>(
        `usaid/${account_id}/${RECORD}/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } else return undefined;
  }

  public async fetchSuggestions(data: ISearchRequest) {
    return this.httpClient.post<ISearchResponse[]>(
      `usaid/${data["account_id"]}/suggest/`,
      data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }
}

export const usaidService = new USAIDService(httpClient);
